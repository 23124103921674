import React, { useState} from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {Box} from "@mui/material";
import Header from "../components/header/header";
import AdminSidebar from "../components/siderbar/adminSidebar";
import {useSelector} from "react-redux";
import Loader from "../components/loader/loader";
import {scrollCSS} from "../App";

const PrivateRoutes = () => {
    const [sidebarOpen,setSidebarOpen] = useState(true)

    const authStore = useSelector(state => state.authStore)
    const {user,logged,loading} = authStore

    const {pathname} =useLocation();

    if (loading){
        return  <Loader fullSize/>
    }

    if ((logged && user?.role_id === 1) || (logged && user?.role_id === 2 && pathname.includes('/admin/configuration/clinic/'))){
        if (pathname === '/'){
            return <Navigate to={"/admin/configuration/roles"} />
        }
        return (
            <Box sx={{display:'flex'}}>
                <AdminSidebar sidebarOpen={sidebarOpen}/>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        overflowX:'scroll',
                        ...scrollCSS,
                        "&::-webkit-scrollbar ": {
                            width: "5px",
                            display:'none'
                        },
                    }}
                >
                    <Header admin={true} setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen}/>
                    <Box sx={{
                        backgroundColor:'#EDF2F7',
                        height:"calc(100vh - 69px)",
                        padding:{sm:'30px', xs:'5px'},
                    }}>
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        )
    }
    if (logged && user?.role_id === 2){
        return <Navigate to={"/crm/dashboard"} />
    }
    if (logged && user?.role_id === 3){
        return <Navigate to={"/user/my-account"} />
    }
    if (logged && user?.role_id === 4){
        return <Navigate to={"/crm/dashboard"} />
    }

    return <Navigate to={"/login"} />
};

export default PrivateRoutes;