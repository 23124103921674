import React, {useState} from 'react';
import PublicHeader from "../../components/public-header";
import PublicLayout from "../../layout/PublicLayout";
import {Formik} from "formik";
import FormInput from "../../components/form/formInput";
import FormButton from "../../components/form/formButton";
import * as Yup from "yup";
import { resendEmail} from "../../store/auth";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";
import {useNavigate} from "react-router";
import Loader from "../../components/loader/loader";

const ResendOtp = () => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValues = {
        email: "",
    }; 
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email is Required"),
    });

    const onSubmit=async (data, {setSubmitting}) => {

        setLoading(true)
        let res =await dispatch(resendEmail(data))
        if (res?.payload?.success){
            toast.success(res?.payload?.message, {duration:3000})
            navigate('/verification')
        }
        if (res?.payload?.success === false){
            toast.error(res?.payload?.errors, {duration:3000})

        }
        setLoading(false)
            setSubmitting(false);
    }

    return (
        <PublicLayout>
            {loading ? <Loader fullSize/> : ''}
            <div className={'register-card'}>
                <PublicHeader
                    heroText={"Enter your registered email"}
                    heroSubText={"Send"}
                    to={"/verification"}
                />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({values,isSubmitting, handleSubmit,errors, handleChange, touched}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <FormInput
                                    name={"email"}
                                    label={"Email"}
                                    placeholder={'Type your e-mail'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                                <FormButton
                                    disable={isSubmitting}
                                    text={'Send'}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </div>
        </PublicLayout>

    );
};

export default ResendOtp;