import React, {useState} from 'react';
import AppointmentGallery from "./appointmentGallery";
import AddGallery from "./addGallery";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import AppointmentMilestones from "./appointmentMilestones";


const galleryTab =[
    {id:0,title:'Add New Photo'},
    {id:1,title:'Photo Gallery'},
    {id:2,title:'Milestone'},
]
const GalleryLayout = ({appointmentDetails}) => {
    const [active, setActive] = useState(0)


    const handleChange = (event, newValue) => {
        setActive(newValue)
    }

    console.log(appointmentDetails,'appointmentDetails')
    return (
        <Box sx={{background:"#fff",minHeight:'100px',  borderRadius:'8px',}}>
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
                width: '100%',
                p:'8px',
            }}>
                <Tabs
                    value={active}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                        ".MuiTabs-indicator": {
                            background: '#000000'
                        },
                        ".Mui-selected": {
                            color: '#000!important',
                            fontWeight: '600'
                        }
                    }}
                >
                    { galleryTab.map((item, index) => (
                        <Tab
                            sx={{
                                fontSize:'20px',
                                color: '#000',
                                textTransform: "capitalize",
                                fontFamily:active === item?.id ? "helvetica-lt-std-bold" : 'helvetica-lt-std-roman',
                            }}
                            label={item?.title}
                            key={item?.id}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box
                sx={{
                    borderRadius:'8px',
                    mt:'8px',
                    minHeight:'300px',
                }}
            >
                {active === 0 && <AddGallery setActive={setActive}  appointmentDetails={appointmentDetails}/>}
                {active === 1 && <AppointmentGallery />}
                {active === 2 && <AppointmentMilestones/>}
            </Box>
        </Box>
    );
};

export default GalleryLayout;