import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "./style";
import batch from "../../../assets/icon/batch.png";
import DateRange from "../../../components/date-range/date-range";
import { useDispatch, useSelector } from "react-redux";
import { getProviderStaff } from "../../../store/crm/staff";
import { getAllStaffAttendance } from "../../../store/crm/attendance";
import moment from "moment";
import { useEffect } from "react";
import Loader from "../../../components/loader/loader";
import { getAttendanceReport } from "../../../store/crm/reports";
import { useFormik } from "formik";
import * as Yup from "yup";
import Waveloading from "../../loading/Waveloading";

function createData(staffID, name, dateFrom, dateTo, totlaHours) {
  return { staffID, name, dateFrom, dateTo, totlaHours };
}

export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginTop: "7px",
  marginRight: "5px",
  marginLeft: "5px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

export const selectStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginTop: "7px",
  marginRight: "5px",
  marginLeft: "5px",
  height: "44px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

const Attendance = () => {

  const [dataFetched, setDataFetched] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [staff, setStaff] = React.useState("");
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {    
    setStaff(event.target.value);
  };
  const crmStaffStore = useSelector(state => state?.crmStaffStore)
  const {crmStaffs=[],} = crmStaffStore
  const reportsStore = useSelector(state => state?.reportsStore)
    const {attendanceReport = []} = reportsStore

  const handleStaffChange = (event) => {
    setSelectedStaff(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().required("End Date is required"),
  });
     
  useEffect(() => {
    dispatch(getProviderStaff());
  }, []);

const formik = useFormik({
  initialValues: {
    start_date: "",
    end_date: "",
  
  },
  validationSchema: validationSchema,
  onSubmit: async (values) => {
    const payload = {
      start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : null,
      end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null,
      staff_id:selectedStaff,
    };

    try {
      setLoading(true);
     await dispatch(getAttendanceReport(payload))
      setLoading(false);
      setDataFetched(true);
    } catch (error) {
      console.error(error);
    }
  },
});

  return (
    <>
<Box sx={{ ml: 0, p: "0 !important" }}>
        <Grid
          container
          sx={{
            alignItems: { md: "start", xs: "center" },
            justifyContent: "space-between",
            mt: 3,
            flexWrap: "wrap",
          }}   
        >
            <form onSubmit={formik.handleSubmit}>
          <Grid
            item
            lg={12}
            xs={12}
            sx={{ display: "flex", flexWrap: "wrap", alignItems: "end" }}
          >
     <FormControl
                  sx={{
                    minWidth: {xs: "100%", sm: "220px"},
                    mt: {xs: 2, sm: 1}, 
                    position: "relative"
                  }}
              >
  <FormLabel>Select Date</FormLabel>

  <DateRange
     setStartDate={(date) => formik.setFieldValue('start_date', date)}
     startDate={formik.values.start_date}
     setEndDate={(date) => formik.setFieldValue('end_date', date)}
     endDate={formik.values.end_date}
     onBlur={formik.handleBlur} 
   />
    {(formik.touched.start_date || formik.touched.end_date) &&
      (!formik.values.start_date || !formik.values.end_date) ? (
        <div
          style={{
            color: "red",
            position: "absolute", // Position the error message absolutely
            bottom: "-20px", // Adjust this value as needed for proper alignment
          }}
        >
          Date field is required
        </div>
      ) : null}

    </FormControl>
    
  
            <FormControl
              sx={{
                minWidth: { xs: "100%", sm: "220px" },   
                mt: { xs: 2, sm: 1 },
              }}
            >
              <FormLabel>Staff Name</FormLabel>
              <Select   
  value={selectedStaff}
  onChange={handleStaffChange}
  displayEmpty
                sx={selectStyle}
              >
               {crmStaffs.map((staff) => (
          <MenuItem key={staff.id} value={staff.id}>   
            {staff.name}  
          </MenuItem>  
        ))}  
                </Select>
            </FormControl>

<Box sx={{  ml: 1 ,mb:"3px", mt:{sm:0, xs:1} }}>
              <Button variant="contained" color="primary" type="submit">
                Fetch Report
              </Button>
</Box>   
          </Grid>
          </form>
        </Grid>

{loading ? (
<Waveloading />
        ) :   dataFetched ? (
<>
<TableContainer
          component={Paper}
          sx={{ mt: 5, boxShadow: "none", maxHeight: "650px" }}
        >
          <Table sx={{}} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow sx={{}}>
                <TableCell
                  align="left"
                  sx={{
                    fontWeight: "700",
                    borderBottom: 0,
                  }}
                >
                  Staf ID
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Staff Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Date From
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Date To
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Total Working Hours
                </TableCell>
              </TableRow>
            </TableHead>
            {attendanceReport?.data?.length > 0 ? (  
            <TableBody>
               {attendanceReport?.data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className={classes.odd}
                >
                  <TableCell
                    component="th"
                    scope="row"   
                  >
                     {row?.staff_id}
                  </TableCell>

                  <TableCell
                    component="th"
                    scope="row"
                    
                  >
                    {row?.staff_name}
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="initial"
                      
                    >
                    {row?.start_date}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" color="initial">
                    {row?.end_date}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" color="initial">
                      {row.total_working_hours}
                    </Typography>
                  </TableCell>
                </TableRow>
                 ))}
                 </TableBody>
             ) : ( 
                 <TableBody>
                     <TableRow sx={{height: "300px", textAlign: "center"}}>
                         <TableCell
                             colSpan={6}     
                             align={"center"}
                             sx={{   
                                 fontWeight: "bold",
                                 borderBottom: "none",
                                 fontSize: "20px",
                             }}
                         >
                             No Data
                         </TableCell>
                     </TableRow>
                 </TableBody>
             )}
          </Table>
  </TableContainer>
</>
    ) : ""}    

</Box>
    </>
    
  );
};

export default Attendance;
