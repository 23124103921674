import React from 'react';
import {Box, Breadcrumbs, Tooltip, Link as MuiLink, Paper, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import ArrowBack from "../../assets/icon/chevron-back-outline.png";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const CustomBreadcrumbs = ({back,backLabel,name}) => {
    const backUrl = localStorage.getItem('url')

    const clearLocalStorage = () => {
      localStorage.removeItem('url')
    }
    return (
        <Box  xs={12} sx={{display: 'flex', alignItems: 'center'}}>
            <Tooltip title="Go Back">
            <Paper
                component={Link}  
                to={backUrl || back}  
                onClick={clearLocalStorage}
                sx={{
                    width: 'max-content',
                    p: '5px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "center",
                    cursor: 'pointer',
                    mr: '20px',
                }}
                elevation={3}
            >
                <img src={ArrowBack} alt={'close'}/>
            </Paper>
            </Tooltip>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small"/>}
                aria-label="breadcrumb"
            >
                <MuiLink onClick={clearLocalStorage} component={Link} to={'/'} underline="hover" color="inherit">
                    Home 
                </MuiLink>,
                <MuiLink   
                    component={Link}
                    onClick={clearLocalStorage}
                    to={back}
                    underline="hover"                                             
                    color="inherit"
                >
                    {backLabel}
                </MuiLink>,
                {name && <Typography
                    key="3"
                    color='#00000099'
                    sx={{
                        fontFamily: 'helvetica-lt-std-bold',
                       
                        textTransform:'capitalize',
                    }}
                >
                    {name}
                </Typography>}
            </Breadcrumbs>
        </Box>
    );
}

export default CustomBreadcrumbs;