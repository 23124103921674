import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import Box from "@mui/material/Box";
import { Button, Grid, Typography } from "@mui/material";
import TimelineContent from "@mui/lab/TimelineContent";
import useStyles from "./style";
import { MySwal, getBorderColor, successConfirmation } from "../../services/utills";
import moment from "moment";
import { useNavigate } from "react-router";
import { resendInvoice } from "../../store/crm/pos";
import { useDispatch } from "react-redux";
import {
    mySwalButton,
    ribbonwrapper,
  } from "../card-appointment/CardAppointment";
import CustomButtoncolor from "../custom-button/customColorButton";
import Waveloading from "../../pages/loading/Waveloading";

const Invoices = ({ appointmentInvoices }) => {
    const classes = useStyles();     
    const [loading, setLoading] = useState(false);

    console.log("invoice data ", appointmentInvoices)   
    const navigate = useNavigate()
    const dispatch = useDispatch();  
    const handleAppointmentClick = (url) => {
        navigate(url)
    }
    const handleResendInvoice = async (id) => {
        let confirm = await MySwal.fire({
          icon: "question",
          title: "Resend Invoice",
          showConfirmButton: false,
          html: (
            <Box sx={{ mb: 4 }}>
              <Typography sx={{}}>
                Are you sure? You want to Resend Invoice of this appointment .
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Button
                  sx={{ ...mySwalButton }}
                  onClick={() => MySwal.clickConfirm()}
                >
                  Yes
                </Button>
                <Button sx={{ ...mySwalButton }} onClick={() => MySwal.close()}> 
                  Cancel
                </Button>
              </Box>
            </Box>
          ),
        });
        if (!!confirm?.isConfirmed) {
          setLoading(true)
          let res = await dispatch(resendInvoice({booking_id:id}))
          if (res?.payload?.success === "true") {
            await successConfirmation('Invoice Resend Successfully')
          }
          setLoading(false)
        }
      };
    return ( 
        <Box>
            {appointmentInvoices ?
                <Timeline 
                    sx={{
                        p: "0",
                        my: "0",
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,  
                        },
                    }}  
                    position="right"
                >
                    {/* {appointmentInvoices?.length > 0 &&
                        appointmentInvoices.map((invoice, index) => ( */}
                            {/* <TimelineItem > */}
                                {/* <TimelineSeparator sx={{ display:{xs:'none',sm:'flex'}, }}> */}
                                    {/* <TimelineConnector
                                        sx={{
                                            background: "#2F80ED",
                                            width: "16px",
                                            borderRadius: "10px",
                                            border: "6px solid #fff",
                                            maxHeight: "50px",
                                            mb: "-10px",
                                            // borderTop: index === 0 ? "6px solid #fff" : "0",
                                            // borderTopLeftRadius: index === 0 ? '10px' : "0",
                                            // borderTopRightRadius: index === 0 ? '10px' : "0",
                                        }}
                                    /> */}
                               
            
                                <TimelineContent sx={{ pl: {xs:"0px",sm:"32px"}, pb: {xs:"8px",sm:"32px"}, pt: 0, pr: 0 }}>
                                    <Box
                                        sx={{ 
                                            p: {xs:"12px",sm:"24px"},
                                            background: "#fff",
                                            height: "100%",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <Box style={{display:"flex",alignItems:"center"}}>
                                            <Box > 

                                          
                                        <Typography
                                                         variant="body2"
                                                        color="initial"
                                                        sx={{
                                                            color: "#56CCF2",
                                                            fontSize: '16px',
                                                            fontFamily: 'helvetica-lt-std-bold'
                                                        }}
                                                    >
                                                       {appointmentInvoices?.provider_name}
                                                    </Typography>
                                                    </Box>
                                                    {appointmentInvoices?.payment_status=== "Paid" && (
                        <div style={{marginLeft:"40px" , marginBottom: "2%" }}>
                          <CustomButtoncolor
                              handleClick={() => handleResendInvoice(appointmentInvoices?.booking_id)}
                              mainColor="#56CCF2"
                              size="large"
                              val="Resend Invoice"
                              style={{ width: "100%", borderRadius: "6px!important" }} // Note: Using !important is generally not recommended
                          />
                        </div>   
                    )}
                                        </Box>
                                        <Box style={{display:"flex",alignItems:"right",marginTop:"1px"}}>


                                            <Typography
                                                variant="body2"
                                                color="initial"
                                                sx={{
                                                    color: "#000",
                                                    fontSize: '18px',
                                                    fontFamily: 'helvetica-lt-std-bold'
                                                }}
                                            >
                                                Reference Clinic:
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="initial"
                                                sx={{
                                                    ml: 1,
                                                    color: "#56CCF2",
                                                    fontSize: '16px',
                                                    fontFamily: 'helvetica-lt-std-bold'
                                                }}
                                            >
                                                {appointmentInvoices?.reference_clinic_name}
                                            </Typography>
                                        </Box>

                                        {loading ? <Waveloading fullSize /> : ""}
                                        <Grid container sx={{ display: "flex" }}>
                                            <Grid item xs={12} xl={3.4}>
                                                <Box>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ color: "#000", textDecoration:'underline' }}
                                                    >
                                                        Invoice Date:
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        color="initial"
                                                        sx={{ mt: 1, fontFamily: "helvetica-lt-std-bold" }}
                                                    >
                                                        {moment(appointmentInvoices?.booking_date).format("dddd MMMM D, YYYY")}

                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        color="initial"
                                                        sx={{ fontFamily: "helvetica-lt-std-bold" }}
                                                    >
                                                        {appointmentInvoices?.start_time} -{" "}
                                                        {appointmentInvoices?.end_time}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} xl={8} sx={{ borderBottom: "1px solid #C4C4C4" }}>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ display:'flex',justifyContent:{xs:'space-between',xl:'normal'},flexWrap:"nowrap",border:'1px solid red' }}>
                                            <Grid lg={6} item >
                                                <Box 
                                                sx={{ display: "flex", mt: 2,cursor: 'pointer', }}
                                                     onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointmentInvoices?.booking_id}`)}

                                                >
                                                    <Typography variant="body2" color="initial">
                                                         Provider id: 
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"                             
                                                        color="initial"
                                                        style={{ color: '#2F80ED' }}
   
                                                        sx={{ ml: 1 }}
                                                    >  
                                                        # {appointmentInvoices?.provider_id}
                                                    </Typography>
                                                </Box> 
                                                <Box sx={{ display: "flex", mt: 2 }}>
                                                    <Typography variant="body2" color="initial">
                                                        Date :
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial" 
                                                        sx={{ ml: 1 }}
                                                    >
                                                        {appointmentInvoices?.date}
                                                    </Typography> 
                                                </Box>
                                            </Grid>
                                            <Grid lg={6} item className={classes.invoicesBottom}>
                                               

                                                <Box sx={{ display: "flex", mt: 1 }}>
                                                    <Typography variant="body2" color="initial">
                                                    status:
                                                    </Typography> 
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ ml: 1 }}
                                                    >
                                                         {appointmentInvoices?.status}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", mt: 2 }}> 
                                                    <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                                                        Amount:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}
                                                    >
                                                        ${appointmentInvoices?.amount}
                                                    </Typography>
                                                </Box>                             
                                               
                                            </Grid>

                                        </Grid>
                                    </Box>
                                </TimelineContent>
                            {/* </TimelineItem> */}
                        {/* ))
                    } */}
                </Timeline> :
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontFamily: "helvetica-lt-std-bold",
                    }}
                >
                    No Invoices
                </Typography>
            }
        </Box>
    );
}

export default Invoices;
