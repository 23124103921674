import React, { useState } from "react";
import PublicLayout from "../../layout/PublicLayout";
import "../login/styles.css";
import PublicHeader from "../../components/public-header";
import PublicFooter from "../../components/public-footer";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import FormButton from "../../components/form/formButton";
import FormInput from "../../components/form/formInput";
import FormPasswordInput from "../../components/form/formPasswordInput";
import {crmLoginUser, loginUser} from "../../store/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import Loader from "../../components/loader/loader";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";
import Box from "@mui/material/Box";
import {successConfirmation} from "../../services/utills";
import Waveloading from "../loading/Waveloading";

const CrmLogin = ({loginOpen,setOpenLogin}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
    social_login: 0,
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .min(8, "Must be at least 8 characters")
      .required("Required"),
  });

  const onSubmit = async (data, { setSubmitting }) => {
    setLoading(true);
    let res = await dispatch(crmLoginUser(data));
    setLoading(false);

    if (res?.payload?.success && res?.payload?.data?.id) {
      localStorage.setItem("token", res?.payload?.data?.token);
      localStorage.setItem("timeZone", res?.payload?.data?.time_zone);
      let timeZone = localStorage.getItem("timeZone");

      if (loginOpen){
        await successConfirmation('Success','Logged Successfully')
        setOpenLogin(false)
      }else {
        navigate("/admin/configuration/roles");
        toast.success("Logged Successfully", { duration: 3000 });
      }
    } else if (res?.payload?.data?.success === false) {
      toast.error(res?.payload?.data?.status, { duration: 3000 });
      if (res?.payload?.data?.data?.is_email_verified === false) {
         
        navigate("/verification");
      }
    }
  };

  return (
    <PublicLayout>
      {loading ? (
        <Waveloading />
      ) : (
        <div className={"login-card"}>
          <PublicHeader
              setOpenLogin={setOpenLogin}
              heroText={"Welcome back!"}
              to={"/register"}
          />
                      <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={true}
          >
            {({
              values,
              isSubmitting,
              handleSubmit,
              errors,
              handleChange,
              touched,
            }) => {
              return (
                <>
                  <form onSubmit={handleSubmit}>
                    <FormInput
                      name={"email"}
                      label={"Email"}
                      placeholder={"Type your e-mail"}
                      errors={errors}
                      touched={touched}
                      isAuto={true}
                      values={values}
                      handleChange={handleChange}
                    />
                    <FormPasswordInput
                      name={"password"}
                      isAuto={true}
                      label={"Password"}      
                      placeholder={"Type your password"}
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                    />
                    {!loginOpen && <Box className={"forget-box"} sx={{justifyContent: 'space-between', alignItems: 'center'}}>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox/>} label="Remember Me"/>
                      </FormGroup>
                      <Link to={"/forget-password"} style={{ fontFamily:'helvetica-lt-std-roman' }}>Forget Password?</Link>
                    </Box>
                    } 
                    <FormButton
                      disable={loading || isSubmitting}
                      text={loading ? "Signing In..." : "Sign In"}
                    />
                  </form>
                </>
              );
            }}
          </Formik>
          {!loginOpen && <PublicFooter
              heading={"Don't have an account?"}
              toText={"Register"}
              to={"/register"}
          />}
        </div>
      )}
    </PublicLayout>
  );
};

export default CrmLogin;
