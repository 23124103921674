import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function LinkModal({ open, onClose, linkUrl }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    const handleMessage = (event) => {
        // Check the origin if needed for security
        // if (event.origin !== 'http://expected-origin.com') return;

        if (event.data?.type === 'closeModal') {
          onClose();
        }
    };

    window.addEventListener('message', handleMessage);

    return () => {
        window.removeEventListener('message', handleMessage);
    };
}, [onClose]);


  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen} 
      fullWidth={true} 
      maxWidth="xl" 
    >
      <DialogContent>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            top: 13,
            right: {
                xs: 46, // For small screens and down
                lg: 110, // For screens wider than small breakpoint and up
              },
          }}
        >
          <CloseIcon style={{color:"red"}} />
        </IconButton>
        {/* Adjust the height as needed */}
        <iframe src={linkUrl} width="100%" height="100%" style={{ minHeight: '1000px' }} frameBorder="0"></iframe>
      </DialogContent>
    </Dialog>
  );
}

export default LinkModal;
