import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {
    customConfirmation,
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    formatExpiryDate, MySwal, successConfirmation
} from "../../../../../services/utills";
import Payment from "payment";
import {Button, FormHelperText, Grid, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import toast from "react-hot-toast";
import Loader from "../../../../../components/loader/loader";


const CardPayment = ({paymentMethod,handleSquare,setLoading,handleBrainTree,totalDue,userCards,useCard,setUseCard}) => {
    const [card, setCard] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [methodLoading, setMethodLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const hasRun = useRef(false);

    const validationSchema = Yup.object({
        number: Yup.string().required('Card number is required'),
        cvv: Yup.string().required('CVC is required'),
        amount: Yup.string().required('Amount is required'),
        expirationDate: Yup.string()
            .required('Expiry date is required')
            .test(
                'is-expiration-date-valid',
                'The expiration date should be later than the current date',
                (value) => {
                    if (!value) {
                        return false;
                    }

                    const [month, year] = value.split('/').map(str => parseInt(str, 10));

                    // Check the date validity and whether the expiry date is later than the current date
                    if (isNaN(month) || isNaN(year) || !moment([year, month - 1]).isValid()) {
                        return false;
                    }

                    const currentDate = moment().startOf('month');
                    const expirationDate = moment([year, month - 1]);

                    return expirationDate.isSameOrAfter(currentDate)
                }),
    });


    const confirmation = async () => {
        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? you want to use this card?
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        return !!confirm?.isConfirmed
    }
    const {
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            number: '',
            expirationDate: '',
            cvv: '',
            amount: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {

            if (await confirmation()) {
                handleBrainTree(data, paymentMethod)
            }
        },
    });

    const initializeCard = async (payments) => {
        const card = await payments.card();

        await card.attach('#card-container');

        return card;
    };
    const tokenize = async (method) => {
        const tokenResult = await method.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
            }

            throw new Error(errorMessage);
        }
    };

    useEffect(() => {
        if (!hasRun.current) {
            const initializeSquarePayment = async (method) => {
                if (!window.Square) {
                    toast.error("Square is not loaded. Reload it or Contact with provider", {duration: 5000})

                    setPaymentError("Square is not loaded. Reload it or Contact with provider")
                    return
                    // throw new Error('Square.js failed to load properly');
                }

                let payments;
                try {
                    payments = window.Square.payments(method?.application_key, method?.merchant_id);
                } catch {
                    toast.error("Square Credential are invalid", {duration: 5000})
                    setPaymentError('Square Credential are invalid');
                    return;
                }

                let card;
                try {
                    card = await initializeCard(payments);
                    setCard(card);
                } catch (e) {
                    toast.error("Square Credential are invalid", {duration: 5000})

                    setPaymentError("Square Credential are invalid.")
                    console.error('Initializing Card failed', e);
                }
            };

            (async () => {
                const cardContainer = document.getElementById('card-container');
                setMethodLoading(true)
                if (cardContainer && !cardContainer.hasChildNodes()) {
                    if (paymentMethod?.id === 4) {
                        await initializeSquarePayment(paymentMethod);
                    }
                }
                setMethodLoading(false)

            })()
            hasRun.current = true; // Mark as run
        }
    }, []);

    const handlePaymentMethodSubmission = async (event) => {
        event.preventDefault();

        if (amount > 0) {
            if (await confirmation()) {
                setLoading(true)
                try {
                    event.target.disabled = true;
                    const tokenID = await tokenize(card)

                    if (tokenID) {
                        setLoading(false)
                        handleSquare(tokenID, amount, paymentMethod)
                    }

                } catch (e) {
                    event.target.disabled = false;

                    setPaymentError('FAILURE');
                    console.error(e.message, "message");
                }
                setLoading(false)
            }
        }
    };

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
    
        if (name === 'number') {
            let res = formatCreditCardNumber(value, Payment);
            setFieldValue('number', res);
        } else if (name === 'expirationDate') {
            let res = formatExpiryDate(value);
            setFieldValue('expirationDate', res);
        } else if (name === 'cvv') {
            let res = formatCVC(value, values?.number, Payment);
            setFieldValue('cvv', res);
        } else if (name === 'amount') {
            // Allow only valid numbers (including decimal numbers)
            if (!isNaN(value) && /^(\d+)?([.]?\d{0,2})?$/.test(value)) {
                setFieldValue('amount', value);
            }
        }
    };
    
    
    useEffect(()=>{
        if (!amount){
            setAmount(totalDue)
        }
        if (!values?.amount){
            setFieldValue('amount',totalDue);
        }
    },[totalDue])

    return (
        <form onSubmit={async (e)=> {
            if(paymentMethod?.payment_gateway === "BrainTree") {
                handleSubmit(e)
            }else {
                await handlePaymentMethodSubmission(e)
            }
        }}>
            <Grid container>
                <Grid item xs={12} sx={{mt: "16px"}}>
                    {methodLoading ? <Box sx={{mb:'24px'}}>Loading...</Box> :""}
                    <Box id="card-container"></Box>
                    {!methodLoading && paymentMethod?.id === 4 &&
                        <TextField
                            fullWidth
                            value={amount}
                            onChange={(e)=>{
                                const {value} = e.target
                                if (!isNaN(value) && /^(\d+)?([.]?\d{0,2})?$/.test(value)) {
                                    setAmount( value);
                                
                                } else if (value === '') {
                                    setAmount(value);
                                }
                            }}
                            variant="outlined"
                            label="Amount"
                            sx={{mb: "24px"}}
                        />
                    }
                </Grid>

                {
                    paymentMethod?.payment_gateway === "BrainTree" &&
                    <Grid item xs={12} sx={{ mb: '24px'}}>
                        <Box>
                            <TextField
                                value={values.number}
                                onChange={handleInputChange}
                                label="Card Number"
                                name="number"
                                variant="outlined"
                                placeholder='0000 0000 0000 0000'
                                sx={{width: '100%'}}
                            />
                            {errors.number && touched.number && <FormHelperText
                                sx={{color: '#CB3838', width: 'max-content', mx: 0}}>{errors.number}</FormHelperText>}
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "space-between", my: '24px'}}>
                            <Box sx={{width:'45%'}}>
                                <TextField
                                    fullWidth
                                    value={values?.expirationDate}
                                    onChange={handleInputChange}
                                    name="expirationDate"
                                    variant="outlined"
                                    placeholder='MM/YYYY'
                                    inputProps={{maxLength: '7'}}
                                    label="Expiry Date"
                                    sx={{marginRight: "10px"}}
                                />
                                {errors.expirationDate && touched.expirationDate &&
                                    <FormHelperText sx={{color: '#CB3838', width: 'max-content', mx: 0}}>
                                        {errors.expirationDate}
                                    </FormHelperText>
                                }
                            </Box>
                            <Box sx={{width:'45%'}}>
                                <TextField
                                    fullWidth
                                    value={values?.cvv}
                                    onChange={handleInputChange}
                                    name="cvv"
                                    variant="outlined"
                                    label="CVC"
                                    sx={{marginRight: "10px"}}
                                />
                                {errors.cvv && touched.cvv &&
                                    <FormHelperText sx={{color: '#CB3838', width: 'max-content', mx: 0}}>
                                        {errors.cvv}
                                    </FormHelperText>
                                }
                            </Box>
                        </Box>
                        <Box>
                            <TextField
                            type="text"
                                fullWidth
                                value={values?.amount}
                                onChange={handleInputChange}
                                name="amount"
                                variant="outlined"
                                // label="error"
                                sx={{marginRight: "10px"}}
                            />
                            {errors.amount && touched.amount &&
                                <FormHelperText sx={{color: '#CB3838', width: 'max-content', mx: 0}}>
                                    {errors.amount}
                                </FormHelperText>
                            }
                        </Box>
                        {/* <Typography>amount</Typography> */}
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant={"contained"}
                        type={"submit"}
                        sx={{backgroundColor: '#006AFF', height: '48px'}}
                    >
                        Confirm
                    </Button>
                    { userCards?.length > 0 && <Typography
                        onClick={() => setUseCard(true)}
                        sx={{
                            color: '#006AFF',
                            mt: '16px',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        Use Existing Card
                    </Typography>}
                </Grid>
            </Grid>
        </form>
    );
}

export default CardPayment;