import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../../lib/apiHeader";
import apis from "../../../../lib/apis/apis";
import {getClinicByID} from "../../index";

export const storeClinicRadius = createAsyncThunk('storeClinicRadius', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.clinics_radius, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {
            console.log( response?.data?.errors)

        } else if (response?.data?.success === "true") {
            await dispatch(getClinicByID(data?.provider_id))
            toast.success(` Radius Updated Successfully`, {duration: 3000})
        }

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})


export const clinicRadiusSlice = createSlice({
    name: "clinicRadiusSlice",
    initialState: {
        servicesTypes: [],
    },
    reducers: {},

});

export default clinicRadiusSlice.reducer;
