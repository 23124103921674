import React, {useEffect, useState,useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getAllActiveClinicsLocations} from "../../../store/user/default-location";
import {MySwal, convertTimeToMinutes, getEndTime, getTimeSlotsArray} from "../../../services/utills";
import toast from "react-hot-toast";
import {getBooingHolidays, getBookingPlans, getPlanSchedules} from "../../../store/booking-widget";
import Box from "@mui/material/Box";
import Loader from "../../../components/loader/loader";
import Slide from "react-reveal/Slide";

import {
    AppBar,
    Autocomplete, Avatar,
    Button,
    Checkbox,
    Container,
    FormControl,
    Grid, IconButton,
    MenuItem,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {inputStyle} from "../../../components/form/formInput";
import BookingHeader from "../bookingHeader";
import SimpleInput from "../../../components/form/simpleInput";
import {escapeRegExp} from "../../../components/table";
import moment from "moment";
import PlanBookingCalender from "./planBookingCalender";
import {useTheme} from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from "@mui/material/Paper";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import PlanBookingContact from "./planBookingContact";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Waveloading from '../../loading/Waveloading';

const style ={
    color: "#006AFF",
    fontWeight:'600',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginBottom:'16px'
}
const showDate = (arr,val) => {
    let filtered = arr.filter((item)=>item?.stepId === val)
    if(filtered){
      return   moment(filtered[0]?.date).format("dddd, MMMM D, YYYY")
    }else {
        return ''
    }

}

// medical-aesthetic-plan
const PlanBookingWidget = () => {
    const [active, setActive] = useState(0)
    const [selectedPlan, setSelectedPlan] = useState(null)
    const [planServices, setPlanServices] = useState(null)
    const [bookingPlanList, setBookingPlanList] = useState([])
    const [search, setSearch] = useState('')
    const [servicesSelectedDate,setServicesSelectedDate] = useState([])
    const [selectedSlot, setSelectedSlot] = useState(null);


    const [moreInfo, setMoreInfo] = useState([])
    const [onHover, setOnHover] = useState(false)
    const [loading, setLoading] = useState(false)
    const [provider, setProvider] = useState(null)
    const [providerBookingSettings, setProviderBookingSettings] = useState(null)
    const [locationOpen, setLocationOpen] = useState(false)

    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = selectedPlan?.no_of_bookings ?  parseInt(selectedPlan?.no_of_bookings): 0;

const ref=useRef()
    const theme = useTheme();
    const dispatch = useDispatch()
    const {plan_slug,location_id} = useParams()

    const allBookingPlans = useSelector(state => state?.bookingWidgetStore?.bookingPlans)
    const planBookingSlots = useSelector(state => state?.bookingWidgetStore?.planBookingSlots)
    const userDefaultLocationStore = useSelector(state => state?.userDefaultLocationStore?.activeClinics)
    const authStore = useSelector(state => state?.authStore)


    const {user} = authStore
    useEffect(()=>{
        if (allBookingPlans?.length){
            setBookingPlanList(allBookingPlans)
        }else {
            setBookingPlanList([])
        }
    },[allBookingPlans])

    useEffect(() => {
        (async () => {
                setLoading(true)
               let res = await dispatch(getAllActiveClinicsLocations())
                if (res?.payload?.success === 'true') {
                    if (location_id) {
                        const allClinics = res?.payload?.data
                        let selectedClinic = allClinics?.filter(item=> item?.id === parseInt(location_id))
                        if (selectedClinic?.length){
                            selectedClinic = selectedClinic[0]

                            if (selectedClinic?.booking_setting) {
                                setProviderBookingSettings(selectedClinic?.booking_setting)
                            } else {
                                setProviderBookingSettings(null)
                            }
                            setProvider(selectedClinic)

                            let finalData = {
                                plan_slug:plan_slug,
                                provider_id:selectedClinic?.id,
                            }
                            await dispatch(getBookingPlans(finalData))

                            setLoading(false)

                            let finalHolidayData = {
                                date: moment(new Date()).format("YYYY-MM-DD"),
                                provider_id: selectedClinic?.id
                            }
                            let res =await dispatch(getBooingHolidays(finalHolidayData))


                            setActive(0)
                        }
                    }else {
                        setLocationOpen(true)
                    }
                }
                setLoading(false)
        })()

    }, [location_id])



    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleNextClick = () => {
        if (active === 0 && selectedPlan?.id) {
            setActive(1)
        } else if (active === 1) {
            setActive(2)
        }
    }
    const handleBackClick = () => {
        if (active === 1) {
            setActive(0)
        } else if (active === 2) {
            setActive(1)
        }
    }
    const handleEdit = (val) => {
        setActive(val)
    }

    const isTimeSelected = (array ,stepId) => {
        return array?.some((item) => item?.stepId === stepId && item?.date)
    }
    const handleSelect = (val) => {
        if (!onHover) {
            if(selectedPlan?.id === val?.id){
                setSelectedPlan(null)
                setPlanServices(null)
            }else {
                setSelectedPlan(val)
                setPlanServices(val?.service)
            }
        }
    }


    const handleMoreInfo = (val) => {
        if (moreInfo?.length) {
            if (moreInfo?.some((item) => item === val)) {
                let finalRes = moreInfo.filter((item) => item !== val)
                setMoreInfo(finalRes)
            } else {
                setMoreInfo([...moreInfo, val])
            }

        } else {
            let finalArray = []
            finalArray.push(val)
            setMoreInfo(finalArray)
        }
    }


    const handleTimeSelect = (slotsArray,startTime,stepId) => {

        setSelectedSlot(startTime);

        let filteredDate = servicesSelectedDate.filter((item)=>item?.stepId !== stepId)



        let endTime = getEndTime(startTime,selectedPlan?.duration || 0)
        let getArraySlots = getTimeSlotsArray(startTime,endTime,slotsArray)
        let lastIndex = getArraySlots[getArraySlots?.length - 1]


        if (planBookingSlots?.slots.includes(lastIndex)){



            let data ={}
             servicesSelectedDate.forEach((item)=>{
                 if (item?.stepId === stepId){
                     data['stepId'] = item?.stepId
                     data['date'] = item?.date
                     data['start_time'] =startTime
                     data['end_time'] = endTime
                     data['slots'] = getArraySlots
                 }
             })


            setServicesSelectedDate([data,...filteredDate])


        }else {
            let data ={}
            servicesSelectedDate.forEach((item)=>{
                if (item?.stepId === stepId){
                    data['stepId'] = item?.stepId
                    data['date'] = item?.date
                }
            })

            MySwal.fire({
                icon: 'error',
                title: 'Booking ending time exceed slots time.',
                showConfirmButton: true,
                timer: 5000,
            });
            // toast.error("Booking ending time exceed slots time",{duration:5000})
            setServicesSelectedDate([data,...filteredDate])
        }

    }


    const getMonthHolidays = async (date,serviceId) => {
        // handleDateReset(serviceId)
        setLoading(true)
        let finalData = {
            date: moment(date).format("YYYY-MM-DD"),
            provider_id: provider?.id
        }
        let res =await dispatch(getBooingHolidays(finalData))
        setLoading(false)
    }


    const handleReset =(val)=>{
        setSelectedPlan(null)
        setPlanServices(null)
        setServicesSelectedDate([])
        setActiveStep(0)


        if (val === "reset"){
            setActive(0)
        }
    }

    const handleSaveLocation = async (clinic,settings) => {
        if (provider?.id){
            setLocationOpen(false)
            setActive(0)
            handleReset()
        }


        if (providerBookingSettings?.booking_process === 1 || settings?.booking_process === 1) {
            setLoading(true)
            let finalData = {
                plan_slug:plan_slug,
                provider_id:provider?.id  || clinic?.id,
            }

            await dispatch(getBookingPlans(finalData))

            setLoading(false)
            await getMonthHolidays(new Date())
            handleDateReset()
        }
    }


    const handleSelectDate = async (date,stepId) => {
        setLoading(true)
        if (date){
            let finalDate = [
                {
                    stepId:stepId,
                    date:date,
                }
            ]
            if (servicesSelectedDate?.length > 0){
               let filterDate =  servicesSelectedDate.filter((item)=>item?.stepId !== stepId)
                setServicesSelectedDate([...filterDate,...finalDate])
            }else {
                setServicesSelectedDate([...finalDate])
            }


        }

        let servicesIds =[]
        planServices.forEach((item)=>{
            servicesIds.push(item?.id)
        })

        let finaData ={
            date:date ,
            provider_id:provider?.id,
            service_ids:servicesIds,
        }

        setTimeout(() => {
            ref.current?.scrollIntoView({ behavior: 'smooth' });
           }, 1500);

        let  res = await dispatch(getPlanSchedules(finaData))
        if (res?.payload?.success === "true"){
          
        }
        setLoading(false)
    }

    const handleDateReset = (stepId) => {

        if (stepId){
            let filtered = servicesSelectedDate.filter((item)=>item?.stepId !== stepId)
            setServicesSelectedDate(filtered)
        }else {
            setServicesSelectedDate([])
            setActiveStep(0)
        }
    }


    const getSelectedDate = (stepId) => {

        let filtered = servicesSelectedDate.filter((item)=>item?.stepId === stepId)

        if (filtered?.length > 0 ){
            let obj = filtered[0]
            let day = moment(obj?.date).format('DD')
            let month = moment(obj?.date).format('MM')
            return {
                day: parseInt(day),
                month: parseInt(month)
            }
        }else {
            return {day:0,month: 13}
        }
    }

    const validateDate = () => {

        if (servicesSelectedDate?.length && servicesSelectedDate?.length){


           return servicesSelectedDate[0]?.end_time && servicesSelectedDate[0]?.start_time && servicesSelectedDate[0]?.date ? true : false

        }else {
            return false
        }
    }
    const isExistSlots = (stepId,time) => {
        if (servicesSelectedDate?.length){
           let filtered =  servicesSelectedDate.filter((item)=>item?.stepId === stepId)
            if (filtered?.length > 0 && filtered[0]?.slots?.length > 0){
                return !!filtered[0]?.slots.includes(time)
            }else {
                return false
            }
        }else {
            return false
        }
    }


    const handleLocationClick=(newValue)=>{
        if (newValue?.booking_setting) {
            setProviderBookingSettings(newValue?.booking_setting)
        } else {
            setProviderBookingSettings(null)
        }
        setProvider(newValue)
    }


    return (
        active === 3 ? <Box>
                Not found
            </Box> :
            <Box>
                {loading ? <Waveloading fullSize/> : ''}
                {/**************************  location Modal ***************************/}
                {locationOpen && <Box
                    sx={{
                        position: 'absolute',
                        width:'100%',
                        height:'100vh',
                        overflowY:'auto',
                    }}
                >
                    <Slide top>
                    <Grid container sx={{p: '48px 20px'}}>
                        <Grid item xs={12} md={10} lg={7} sx={{mx:'auto'}}>
                            <Typography
                                variant={'h3'}
                                sx={{fontWeight: 'bold', textAlign: 'center', fontSize: '22px', mb: '16px'}}
                            >
                                Choose Your Default Spa Location
                            </Typography>
                            <List sx={{width: '100%', bgcolor: 'background.paper',mt:'10px'}}>
                                {userDefaultLocationStore?.length > 0 && userDefaultLocationStore?.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            mt:'10px',
                                            background: item?.id === provider?.id ? '#09ABF1' :'#fff',
                                            p:0,
                                            borderRadius:'5px',
                                            color: item?.id === provider?.id ? '#fff!important' :'#000'
                                        }}
                                        secondaryAction={
                                            <IconButton
                                                sx={{".Mui-checked":{
                                                        color: item?.id === provider?.id ? '#fff' :'#000'
                                                    }}}
                                                onClick={()=>handleLocationClick(item)} edge="end">
                                                <Checkbox
                                                    checked={item?.id === provider?.id}
                                                    disableRipple
                                                />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemButton role={undefined} onClick={()=>handleLocationClick(item)} dense>
                                            <ListItemAvatar>
                                                <Avatar alt={item?.name}/>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item?.name}
                                                secondary={
                                                    item?.address?.address ? <React.Fragment>
                                                        <Box as={'span'} sx={{display:'block'}}>
                                                            <Typography
                                                                as={'span'}
                                                                sx={{
                                                                    display:'block',
                                                                    color: item?.id === provider?.id ? '#fff' :'#626262'
                                                                }}
                                                                variant="body2"
                                                            >
                                                                {item?.address?.address}
                                                            </Typography>
                                                            <Typography
                                                                as={'span'}
                                                                sx={{
                                                                    display:'block',
                                                                    color: item?.id === provider?.id ? '#fff' :'#626262'
                                                                }}
                                                                variant="body2"
                                                            >
                                                                {item?.address?.state},{item?.address?.postal_code}
                                                            </Typography>
                                                        </Box>
                                                    </React.Fragment> : null
                                                }
                                            />

                                        </ListItemButton>
                                    </ListItem>
                                ))
                                }
                            </List>
                            <Button
                                fullWidth
                                onClick={handleSaveLocation}
                                variant={"contained"}
                                sx={{backgroundColor: '#006AFF', mt: '32px', height: '40px'}}
                            >
                                Done
                            </Button>
                        </Grid>
                    </Grid>
                    </Slide>
                </Box>}
                {/************************** Ending of location Modal ***************************/}

                {providerBookingSettings?.booking_process === 1 && !locationOpen ?
                    <Box>
                        <BookingHeader setLocationOpen={setLocationOpen} provider={provider}/>
                        <Container sx={{pt: '120px', pb: '100px', maxWidth: {md: "md", lg: 'lg', xl: 'xl'}}}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item xs={4} lg={3} sx={{display: {xs: 'none', md: 'block'}}}>
                                    <Box>
                                        <Typography sx={active === 0 ? style : {}}>
                                            Select Plan.
                                            {
                                                active !== 0 && (
                                                    <Typography
                                                        sx={{
                                                            ...style,
                                                            fontSize: '14px',
                                                            textDecoration: 'unset',
                                                            ml: "5px"
                                                        }}
                                                        as={'span'}
                                                        onClick={() => handleEdit(0)}
                                                    >
                                                        Edit
                                                    </Typography>
                                                )
                                            }
                                        </Typography>
                                        {(selectedPlan?.id) ?
                                            <Box  sx={{
                                                    background: '#f4ede3',
                                                    padding: " 20px",
                                                    mt: '16px',
                                                    borderRadius: "4px"
                                                }}>
                                                    <Typography>
                                                        {selectedPlan?.title}
                                                        {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                                    </Typography>
                                                    <Typography sx={{color: '#636363'}}>
                                                        { user?.is_membered === 1 ?  "$"+selectedPlan?.member_price :"$"+selectedPlan?.regular_price}
                                                        {/*· {convertTimeToMinutes(selectedPlan?.time_duration)} minutes*/}
                                                    </Typography>
                                                <Box sx={{display: "flex",flexWrap:'wrap'}}>
                                                    {selectedPlan?.service?.length > 0 && selectedPlan?.service.map((service, i) => (
                                                        <Typography key={i} sx={{
                                                            fontWeight: '400',
                                                            fontSize: '16px',
                                                            color: 'black'
                                                        }}>
                                                             {service?.name}{i !== selectedPlan?.service?.length - 1 && ", "}
                                                        </Typography>
                                                    ))}
                                                </Box>
                                                </Box>
                                             : ''}
                                    </Box>
                                    <Box sx={{mt: '24px'}}>
                                        <Typography sx={active === 1 ? style : {mt: '16px'}}>
                                            Select date and time
                                            {
                                                active > 1 && (
                                                    <Typography
                                                        sx={{
                                                            ...style,
                                                            fontSize: '14px',
                                                            textDecoration: 'unset',
                                                            ml: "5px"
                                                        }}
                                                        as={'span'}
                                                        onClick={() => handleEdit(1)}
                                                    > 
                                                        Edit
                                                    </Typography>
                                                )
                                            }
                                        </Typography>
                                        {(servicesSelectedDate?.length > 0  || active === 1) ?
                                            servicesSelectedDate.map((item,index)=>(
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        background: '#f4ede3',
                                                        padding: " 20px", 
                                                        mt: '16px',
                                                        borderRadius: "4px",
                                                        cursor:'pointer',
                                                    }}

                                                    onClick={()=>{
                                                        handleEdit(1)
                                                        setActiveStep(index)
                                                    }}  
                                                > 
                                                    <Typography>
                                                        {moment(item?.date).format("dddd, MMMM D, YYYY") }
                                                    </Typography>
                                                    {item?.start_time && <Typography sx={{color: '#636363'}}>
                                                        {item?.start_time} – {item?.end_time}
                                                    </Typography>}
                                                </Box>
                                            ))
                                            : ''}
                                    </Box>
                                    <Box sx={{mt: '24px'}}>
                                        <Typography sx={active === 2 ? style : {}}>Enter your details</Typography>
                                    </Box>
                                </Grid>
                                {active === 0 && <Grid item xs={12} md={7.5} lg={8} xl={8.5}>
                                    <Typography
                                        variant={'h3'}
                                        sx={{fontWeight: 'bold', mb: '24px', fontSize: '22px', color: 'black'}}
                                    >
                                        Select Plan
                                    </Typography>
                                    <Grid container>
                                        {allBookingPlans?.length > 0 && <Grid item xs={12} sx={{mb:"24px"}}>
                                            <FormControl fullWidth sx={{}}>
                                                <SimpleInput
                                                    search={search}
                                                    setSearch={setSearch}
                                                    handleChange={(val)=>{
                                                        setSearch(val)
                                                        if (val!=='' || val?.length){
                                                            const searchRegex = new RegExp(escapeRegExp(search), 'i')
                                                            let filteredRows

                                                            filteredRows = allBookingPlans.filter(row => {
                                                                return Object.keys(row).some(field => {
                                                                    return row[field] !== null && searchRegex.test(row[field].toString())
                                                                })
                                                            })

                                                            if (filteredRows?.length> 0){
                                                                setBookingPlanList(filteredRows)
                                                            }else {
                                                                setBookingPlanList([])
                                                            }
                                                        }else {
                                                            setBookingPlanList(allBookingPlans) 
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>}
                                        {bookingPlanList?.length > 0 ? bookingPlanList.map((item, index) => (
                                            <Grid
                                                key={index}
                                                item
                                                xs={12}
                                                sx={{
                                                    border: `1px solid ${selectedPlan?.id === item?.id ? "#d2bea2" : "#d9d9d9"}`,
                                                    pr: "32px",
                                                    borderRadius: '4px',
                                                    cursor: 'pointer',
                                                    display: "flex",
                                                    mb: '24px',
                                                    justifyContent: 'space-between',
                                                    "&:hover": {
                                                        border: '1px solid #E6DAD1'
                                                    }
                                                }}
                                                onClick={() => handleSelect(item)}
                                            >
                                                <Box sx={{maxWidth: '80%',display:'flex'}}>
                                                    <Avatar src={item?.image} variant="square" sx={{height:'100%',width:'135px',borderBottomLeftRadius:'4px',borderTopLeftRadius:'4px'}}/>
                                                    <Box sx={{ py: "24px", pl: "32px"}}>

                                                    <Typography sx={{
                                                        fontSize: '16px',
                                                        mb: '8px',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        {item?.title}
                                                        {/*" ($" + `${providerBookingSettings?.booking_mini_amount} Partial Payment Required to be applied towards treatment)`}*/}
                                                    </Typography>
                                                    <Box>
                                                        <Typography>
                                                            {/*{convertTimeToMinutes(item?.time_duration)} minutes*/}
                                                            { user?.is_membered === 1 ?  "$"+item?.member_price :"$"+item?.regular_price}
                                                            · {" "}
                                                            <Typography
                                                                as={'span'}
                                                                sx={style}
                                                                onMouseEnter={() => {
                                                                    setOnHover(true)
                                                                }}
                                                                onMouseLeave={() => {
                                                                    setOnHover(false)
                                                                }}
                                                                onClick={() => onHover && handleMoreInfo(item?.id)}
                                                            >
                                                                More info
                                                            </Typography>
                                                        </Typography>
                                                        {moreInfo.includes(item?.id) &&
                                                            <Typography sx={{color: '#666666', fontSize: '14px'}}>
                                                                {item?.description}
                                                            </Typography>
                                                        }
                                                        <Box sx={{display: "flex",flexWrap:'wrap'}}>
                                                            {item?.service?.length > 0 && item?.service.map((service, i) => (
                                                                <Typography key={i} sx={{
                                                                    fontWeight: '400',
                                                                    fontSize: '16px',
                                                                    color: 'black'
                                                                }}>
                                                                    {i !== 0 && ", "} {service?.name}
                                                                </Typography>
                                                            ))}
                                                        </Box>
                                                    </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{pt: "24px"}}>
                                                    <Checkbox style={{color:"#c3af93"}} checked={selectedPlan?.id === item?.id}/>
                                                </Box>
                                            </Grid>  
                                        )) : <Grid item xs={12} sx={{mt:'30px'}}>
                                            <Typography sx={{textAlign:'center'}}>Plans Not found</Typography>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                                }
                                {active === 1  && <Grid item xs={12} md={7.5} lg={8} xl={8.5}>

                                    <Paper
                                        square
                                        elevation={0}
                                        sx={{
                                            pl: 2,
                                            bgcolor: 'background.default',
                                        }}
                                    >
                                        <Box sx={{my: '24px',display:"flex",justifyContent:'space-between'}}>
                                            <Typography
                                                variant={'h3'}
                                                sx={{fontWeight: 'bold' ,fontSize: '22px', color: 'black'}}
                                            >
                                                Select your preferred date and time
                                            </Typography>
                                            <Typography
                                                variant={'h3'}
                                                sx={{fontWeight: 'bold' ,fontSize: '22px', color: 'black'}}
                                            >
                                                Session :  {activeStep + 1} / {maxSteps}
                                            </Typography>
                                        </Box>

                                        <PlanBookingCalender   
                                            selectedDate={getSelectedDate(activeStep)}
                                            handleSelectDate={(date)=>handleSelectDate(date,activeStep)}
                                            getMonthHolidays={(date)=>getMonthHolidays(date,activeStep)}
                                        />
                                        {isTimeSelected(servicesSelectedDate,activeStep) && <Typography
                                            variant={'h3'}
                                            sx={{
                                                fontWeight: 'bold',
                                                mt: '24px',
                                                mb: "8px",
                                                fontSize: '18px',
                                                color: 'black'
                                            }}
                                        >
                                            Available on {showDate(servicesSelectedDate,activeStep)}
                                        </Typography>}
                                        <Box ref={ref}>
                                        {(planBookingSlots?.success && !loading && isTimeSelected(servicesSelectedDate,activeStep)) && <Box>
                                            <Typography sx={{
                                                mt: '16px',
                                                mb: "8px",
                                                fontSize: '16px',
                                                fontWeight: "bold"   
                                            }}>
                                                Morning
                                            </Typography>
                                            <Box>
                                                {planBookingSlots?.morningTimeSlots?.length > 0 ?
                                                    planBookingSlots?.morningTimeSlots.map((item, index) => (
                                                        <Button
                                                        key={index}
                                                        variant={"outlined"}
                                                        sx={{
                                                            color: "#000",
                                                            backgroundColor: item === selectedSlot ? "#fff" : "#E6DAD1",
                                                            border: "1px solid #E6DAD1",
                                                            fontWeight: "bold",
                                                            minWidth: {
                                                                xs: "110px", 
                                                                sm: "140px", 
                                                            },
                                                            mr: "10px", 
                                                            mb: "10px",
                                                            "&:hover": { 
                                                                border: "1px solid #E6DAD1",
                                                                color: "#000",
                                                                backgroundColor: item === selectedSlot ? "#fff" : "#E6DAD1",
                                                            },
                                                        }}
                                                        onClick={() => handleTimeSelect(planBookingSlots?.slots, item, activeStep)}
                                                    >
                                                        {item === selectedSlot ? "✔ " : ""}{item}
                                                    </Button>
                                                    
                                                    
                                                    ))
                                                    : <Button
                                                        disabled
                                                        variant={"contained"}
                                                        sx={{
                                                            backgroundColor: '#fff',
                                                            color: '#006AFF',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        All Booked
                                                    </Button>
                                                }
                                            </Box>
                                            <Typography sx={{
                                                mt: '16px',
                                                mb: "8px",
                                                fontSize: '16px',
                                                fontWeight: "bold"
                                            }}>Afternoon</Typography>
                                            <Box>
                                                {planBookingSlots?.afternoonTimeSlots?.length > 0 ?
                                                    planBookingSlots?.afternoonTimeSlots.map((item, index) => (
                                                        <Button
                                                        key={index}
                                                        variant={"outlined"}
                                                        sx={{
                                                            color: "#000",
                                                            backgroundColor: item === selectedSlot ? "#fff" : "#E6DAD1",
                                                            border: "1px solid #E6DAD1",
                                                            fontWeight: "bold",
                                                            minWidth: {
                                                                xs: "110px", 
                                                                sm: "140px", 
                                                            },
                                                            mr: "10px", 
                                                            mb: "10px",
                                                            "&:hover": { 
                                                                border: "1px solid #E6DAD1",
                                                                color: "#000",
                                                                backgroundColor: item === selectedSlot ? "#fff" : "#E6DAD1",
                                                            },
                                                        }}
                                                        onClick={() => handleTimeSelect(planBookingSlots?.slots, item, activeStep)}
                                                    >
                                                        {item === selectedSlot ? "✔ " : ""}{item}
                                                    </Button>
                                                    ))
                                                    : <Button
                                                        disabled
                                                        variant={"contained"}
                                                        sx={{
                                                            backgroundColor: '#fff',
                                                            color: '#006AFF',
                                                            fontWeight: 'bold',
                                                        }}     
                                                    >
                                                        All Booked 
                                                    </Button>
                                                }
                                            </Box>
                                            <Typography sx={{
                                                mt: '16px',
                                                mb: "8px",
                                                fontSize: '16px',
                                                fontWeight: "bold"
                                            }}>Evening</Typography>
                                            <Box>
                                                {planBookingSlots?.eveningTimeSlots?.length > 0 ?
                                                    planBookingSlots?.eveningTimeSlots.map((item, index) => (
                                                        <Button
                                                        key={index}
                                                        variant={"outlined"}
                                                        sx={{
                                                            color: "#000",
                                                            backgroundColor: item === selectedSlot ? "#fff" : "#E6DAD1",
                                                            border: "1px solid #E6DAD1",
                                                            fontWeight: "bold",
                                                            minWidth: {
                                                                xs: "110px", 
                                                                sm: "140px", 
                                                            },
                                                            mr: "10px", 
                                                            mb: "10px",
                                                            "&:hover": { 
                                                                border: "1px solid #E6DAD1",
                                                                color: "#000",
                                                                backgroundColor: item === selectedSlot ? "#fff" : "#E6DAD1",
                                                            },
                                                        }}
                                                        onClick={() => handleTimeSelect(planBookingSlots?.slots, item, activeStep)}
                                                    >
                                                        {item === selectedSlot ? "✔ " : ""}{item}
                                                    </Button>
                                                    ))
                                                    : <Button
                                                        disabled
                                                        variant={"contained"}
                                                        sx={{
                                                            backgroundColor: '#fff',
                                                            color: '#006AFF',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        All Booked
                                                    </Button>
                                                }
                                            </Box>
                                        </Box>}
</Box>
                                    </Paper>
                                    {maxSteps > 1 && <MobileStepper
                                        variant="text"
                                        steps={maxSteps}
                                        position="static"
                                        activeStep={activeStep}   
                                        nextButton={   
                                            <Button
                                                size="small"
                                                variant={'contained'}
                                                onClick={handleNext}
                                                disabled={activeStep === maxSteps - 1}
                                                sx={{
                                                    backgroundColor: "#E6DAD1",
                                                    color: "#000",
                                                    fontWeight: "bold",   
                                                    borderColor: "#E6DAD1",  
                                                    "&:hover": { backgroundColor: "#E6DAD1" },
                                                  }}
                                            >  
                                                <Box sx={{mt:'2px'}}>Next</Box>
                                                {theme.direction === 'rtl' ? (
                                                    <KeyboardArrowLeft/>    
                                                ) : (
                                                    <KeyboardArrowRight/>
                                                )}
                                            </Button>  
                                        }  
                                        backButton={  
                                            <Button
                                                size="small"
                                                variant={'contained'}
                                                onClick={handleBack}
                                                disabled={activeStep === 0}
                                                sx={{   
                                                    mt:'2px',
                                                   backgroundColor: "#E6DAD1",
                                                   color: "#000",
                                                   fontWeight: "bold",   
                                                   borderColor: "#E6DAD1",
                                                   "&:hover": { backgroundColor: "#E6DAD1" },
                                                 }}
                                            >
                                                {theme.direction === 'rtl' ? (
                                                    <KeyboardArrowRight/>
                                                ) : (
                                                    <KeyboardArrowLeft/>
                                                )}     
   
                                            <Box>Back</Box>   
                                              
                                            </Button>
                                        }
                                    />}
                                </Grid>
                                }
                                {active === 2 && <Grid item xs={12} md={7.5} lg={8} xl={8.5}>
                                    <PlanBookingContact
                                        handleReset={handleReset}
                                        providerBookingSettings={providerBookingSettings}
                                        provider={provider}
                                        selectedPlan={selectedPlan}
                                        servicesSelectedDate={servicesSelectedDate}

                                    />
                                    {active === 2 &&
                                        <Typography sx={{color: '#666666', mt: '100px', fontSize: '14px'}}>
                                            © 2023 Square, Inc.
                                            <Typography
                                                as={"span"}
                                                sx={{
                                                    color: "#006AFF",
                                                    fontWeight: "bold",
                                                    mx: "3px",
                                                    cursor: 'pointer',
                                                    "&:hover": {textDecoration: "underline"}
                                                }}
                                            >
                                                Security
                                            </Typography>
                                        </Typography>
                                    }
                                </Grid>
                                }
                            </Grid>
                        </Container>
                        {active !== 2 &&
                            <AppBar position="fixed" sx={{
                                top: 'auto',
                                minHeight: '69px',
                                bottom: "0",
                                backgroundColor: '#fff',
                                boxShadow: 'none',
                                borderTop: '1px solid #E5E5E5'
                            }}>
                                <Toolbar sx={{
                                    justifyContent: active === 0 ? 'flex-end' : 'space-between',
                                    minHeight: '69px!important',
                                    px: '48px!important'
                                }}>
                                    {active === 1 && <Button
                                        onClick={() => handleBackClick()}
                                        variant={"outlined"}
                                        sx={{
                                            backgroundColor: "#E6DAD1",
                                            color: "#000",
                                            fontWeight: "bold",   
                                            borderColor: "#E6DAD1",
                                            "&:hover": { backgroundColor: "#E6DAD1" },
                                          }}
                                    >
                                        Back
                                    </Button>
                                    }
                                    {(active === 0 || (active === 1 && validateDate())) &&
                                        <Button
                                            disabled={!selectedPlan?.id}
                                            onClick={() => handleNextClick()}
                                            variant={"contained"}
                                            sx={{
                                                backgroundColor: "#E6DAD1",
                                                color: "#000",
                                                fontWeight: "bold",
                                                borderColor: "#E6DAD1",
                                                "&:hover": { backgroundColor: "#E6DAD1" },
                                              }}
                                        >
                                            Continue
                                        </Button>}
                                </Toolbar>
                            </AppBar>
                        }
                    </Box>
                    : <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: "center",
                        justifyContent: 'center',
                        height: '100vh'
                    }}>
                        {!locationOpen && provider?.name && <Box>
                            <Typography variant={"h4"}>
                                Currently {provider?.name} is not booking any appointment
                            </Typography>
                            <Button
                                fullWidth
                                onClick={() => setLocationOpen(true)}
                                variant={"contained"}
                                sx={{
                                    backgroundColor: '#006AFF',
                                    mt: "24px",
                                    maxWidth: '300px',
                                    mx: 'auto',
                                    display: 'block'
                                }}
                            >
                                Select Default / Spa Location
                            </Button>
                        </Box>
                        }
                    </Box>
                }
            </Box>
    )
};
export default PlanBookingWidget;