import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import WalletIcon from '@mui/icons-material/Wallet';
import { Drawer, Grid, Link as MuiLink, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import CustomButtoncolor from "../../../../components/custom-button/customColorButton";
import AppointmentDetails from "./appointmentDetails";
import AppointmentHistory from "./appointmentHistory/appointmentHistory";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentDetails } from "../../../../store/crm/appointment";
import Loader from "../../../../components/loader/loader";
import ribbon from "../../../../assets/images/ribbon.svg";
import redRibbon from "../../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../../assets/images/yellow-badg.svg";
import AppointmentNotes from "./appointment-notes/appointmentNotes";
import AddNotes from "./appointment-notes/addNotes";
import Attachments from "./appointment-attachment/attachments";
import AddAttachment from "./appointment-attachment/addAttachment";
import AppointmentReminder from "./appointment-reminders/appointmentReminder";
import AddReminder from "./appointment-reminders/addReminder";
import AppointmentStaffStatus from "../AppointmentStaffStatus/appointmentStaffStatus";
import { handleEditReschedule } from "../../../../components/card-appointment/CardAppointment";
import AppointmentReschedule from "../../../../components/card-appointment/AppointmentReschedule";
import { scrollCSS } from "../../../../App";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/breadcrumbs";
import GalleryLayout from "./appointmentGallery/GalleryLayout";
import TreatmentFormButton from "../../../../components/card-appointment/treatmentForm";
import AppointmentSubmittedForm from "./appointment-submitted-form/appointmentSubmittedForm";
import Waveloading from "../../../loading/Waveloading";
import AppointmentDrawer from "../../../../components/siderbar/AppointmentDrawer";
import CustomBread from "../../../../components/breadcrumbs/BreadCrumbscustom";


const tabData = [
  { id: 0, title: "Details", url: "/crm/appointment/details/" },
  { id: 1, title: "Booking History", url: "/crm/appointment/history/" },
  { id: 2, title: "Notes", url: "/crm/customer-relationship-management/customer/notes/" },
  { id: 3, title: "Attachments", url: "/crm/appointment/attachments/" },
  { id: 5, title: "Gallery", url: "/crm/appointment/gallery/" },
  { id: 4, title: "Reminders", url: "/crm/appointment/reminders/" },
  { id: 6, title: "Submitted Form", url: "/crm/appointment/submitted-forms/" },
];
const AppointmentDetailsLayout = () => {
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);  


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { appointment_id } = useParams();

  const crmAppointmentStore = useSelector(
    (state) => state?.crmAppointmentStore

  );
  const { user_id } = useParams();
 
  const { appointmentDetails = [] } = crmAppointmentStore;
  console.log("user_id", appointmentDetails)
  useEffect(() => {
    if (pathname === `/crm/appointment/details/${appointment_id}`) {
      setActive(0);
    } else if (pathname === `/crm/appointment/history/${appointment_id}`) {
      setActive(1);
    } else if (pathname === `/crm/customer-relationship-management/customer/notes/${appointmentDetails?.customer?.id}`) {
      setActive(2);
    } else if (pathname === `/crm/appointment/attachments/${appointment_id}`) {
      setActive(3);
    } else if (pathname === `/crm/appointment/gallery/${appointment_id}`) {
      setActive(5);
    } else if (pathname === `/crm/appointment/reminders/${appointment_id}`) {
      setActive(4);
    } else if (
      pathname === `/crm/appointment/submitted-forms/${appointment_id}`
    ) {
      setActive(6);
    }
  }, [pathname]);

  useEffect(() => {
    if (appointment_id) {
      (async () => {
        setLoading(true);
        await dispatch(getAppointmentDetails(appointment_id));
        setLoading(false);
      })();
    }
  }, [appointment_id]);
  const handleShowParent = () => {
    setIsVisible(true);
  };

  const redirect = (to) => {
    navigate(to);
  };
  const id =appointmentDetails?.customer?.id

  const drawer = () => (
    <Box
      sx={{
        maxHeight: "100vh",
        minHeight: "50vh",
        overflowY: "auto",
        background: "#fff",
        width: "250px",
        p: "0",
        borderRadius: "7px",
        ...scrollCSS,
        display: { md: "block", xs: "none", sm: "none" },
      }}
    >
      <List
        sx={{
          width: "100%",
          p: "0",
          ".MuiListItemIcon-root ": {
            minWidth: "37px",
          },
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {tabData?.map((item, index) => (
          <ListItemButton
            key={index}
            onClick={() => {
              if (item?.url === "/crm/customer-relationship-management/customer/notes/") {
                redirect(item?.url + id);
              } else {
                redirect(item?.url +appointment_id);
              }
            }}
            
            sx={{
              borderBottom: "1px solid #E8E8E8",
              py: "16px",
              pl: "24px",
              span: {
                fontFamily: pathname.includes(item?.url)
                  ? "helvetica-lt-std-bold"
                  : "helvetica-lt-std-roman",
                fontSize: "19px",
              },
              "&:hover": {
                span: {
                  fontFamily: "helvetica-lt-std-bold",
                }, 
              },
            }}
          >
            {<ListItemText primary={item?.title} />}
          </ListItemButton>
        ))}
      </List>
    </Box>
  );  

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
          }} 
        >
          {/* <CustomBreadcrumbs
            back={"/crm/appointments"}
            backLabel={"Appointment"}
            name={appointmentDetails?.customer?.name || null}
          /> */}  
  
          <CustomBread  crmCustomerDetails={appointmentDetails}/>
        </Grid>
      </Grid>
      <Grid container>
        {loading ? <Waveloading fullSize /> : ""}
        <Grid
          item
          xs={12}
          sx={{  
            mb: "32px",
            mt: "32px",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#000000",
                fontSize: "25px",
                fontWeight: "700",
                fontFamily: "helvetica-lt-std-bold",
                textTransform: "capitalize",
              }}
            >
              {appointmentDetails?.customer?.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "8ppx",
              }}
            >
              {appointmentDetails?.customer?.customer_batch && (
                  appointmentDetails?.customer?.customer_batch !== "New User" ? (
                <> 
                  {appointmentDetails?.customer?.customer_batch.includes(
                    "Expire/Cancel"
                  ) ? (
                    <>
                      <img
                        style={{ marginTop: "4px" }}
                        src={redRibbon}
                        alt={"member"}
                      />
                      <Typography style={{ color: "#FF0000" }}>
                        {appointmentDetails?.customer?.customer_batch}
                      </Typography>
                    </>
                  ) : (
                    <>
                      {appointmentDetails?.customer?.customer_batch.includes(
                        "Declined"
                      ) ? (
                        <>
                          <img
                            style={{ marginTop: "4px" }}
                            src={yellowRibbon}
                            alt={"member"}
                          />
                          <Typography style={{ color: "rgb(220, 217, 10)" }}>
                            {appointmentDetails?.customer?.customer_batch}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <img
                            style={{ marginTop: "4px" }}
                            src={ribbon}
                            alt={"member"}
                          />
                          <Typography style={{ color: "#219653" }}>
                            {appointmentDetails?.customer?.customer_batch}
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Typography style={{ color: "#09ABF1" }}>
                    {appointmentDetails?.customer?.customer_batch}
                  </Typography>
                </>
                  ))}
            </Box>
            <div>
              <MuiLink href="#" color="inherit">
                Member ID {appointmentDetails?.customer?.id}
              </MuiLink>
            </div>
          </Box>
          <Box
            sx={{
              background: "#FFFFFF",
              borderRadius: "7px",
              maxHeight: { xs: "auto", md: "65px" },
              alignItems: "center",
              justifyContent: "space-between",
              flexGrow: "1",
              ml: { xs: "0px", sm: "24px" },
              my: { xs: "15px", sm: "0px" },
              py: { xs: 2, sm: 0 },
              display: "flex",
              // flexDirection:{xs:'column',sm:'row'}
              // display:{xs:'none',md:'flex'},
            }}
          >
            <Box
              sx={{
                pl: "20px",
                display: "flex",
                gap: "30px",
                flexDirection: { xs: "column", sm: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#4F4F4F",
                    fontWeight: "400",
                  }}
                >
                 <h4><WalletIcon/></h4>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  ${appointmentDetails?.customer?.wallet_point}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#4F4F4F",
                    fontWeight: "400",
                  }}
                >
                <h4><PhoneIcon/></h4>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  {appointmentDetails?.customer?.phone}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#4F4F4F",
                    fontWeight: "400",
                  }}
                >
                <h4><EmailIcon/></h4>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  {appointmentDetails?.customer?.email}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                border: `1px solid   ${
                  appointmentDetails?.status_color || "black"
                }`,
                padding: "5px",
                height: "100%",
                alignItems: "center",
                textAlign: "center",
                display: { xs: "none", md: "block" },
                width: "68px",
                borderRadius: "7px",
              }}
            >
              <Box
                sx={{
                  height: "max-content",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: appointmentDetails?.status_color || "#000000",
                    fontWeight: "400",
                  }}
                >
                  Booking No
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    lineHeight: "18px",
                    color: appointmentDetails?.status_color || "#000",
                  }}
                >
                  {appointmentDetails.id}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <div style={{ display: isVisible ? 'block' : 'none'}}>

  
      <Grid container
        sx={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          flexWrap: { xs: "wrap", md: "nowrap" },
          mb: appointmentDetails?.status === "Completed" ? "20px" : "34px",
        }}
      >
        {appointmentDetails?.status !== "Canceled" &&
          appointmentDetails?.status !== "Completed" &&
          appointmentDetails?.date &&
      <Grid item lg={1.5} xl={1.2} sm={3.6} md={3.3} xs={5.8}>
            <AppointmentStaffStatus fwidth={'100%'} isFetch appointment={appointmentDetails} />
      </Grid>
         }


{(appointmentDetails?.status === "Pending" ||
          appointmentDetails?.status === "Confirmed") &&
          appointmentDetails?.date &&
      <Grid item lg={1.4} xl={1} sm={3.6} md={3.3} xs={5.8}>
            <AppointmentStaffStatus
            style={{ width:'98%' }}
              isFetch
              label={"Check-In"}
              value={"CheckIn"}
              appointment={appointmentDetails}
              fwidth={'100%'}  
            />
      </Grid>
         }

{appointmentDetails?.status === "CheckIn" &&
          appointmentDetails?.date &&
      <Grid item lg={1.4} xl={1} sm={3.6} md={3.3} xs={5.8}>
            <AppointmentStaffStatus
            style={{ width:'98%' }}
              label={"Check-Out"}
              value={"CheckOut"}
              mainColor={"#219653"}
              appointment={appointmentDetails}
              fwidth={'100%'}
            />
      </Grid>
         }

{appointmentDetails?.status !== "Void" &&
          appointmentDetails?.status !== "Canceled" &&
          appointmentDetails?.status !== "Completed" &&
      <Grid item lg={1.4} xl={1} sm={3.6} md={3.3} xs={5.8}>
            <AppointmentReschedule fwidth={'100%'} appointment={appointmentDetails} />
      </Grid>
         }

{appointmentDetails?.status !== "Void" &&
          appointmentDetails?.status !== "Canceled" &&
          appointmentDetails?.status !== "Completed" &&
          <Grid item xl={1.5} lg={3.5} sm={3.8} md={2.8} xs={5.8}>
            <CustomButtoncolor
              mainColor={"#000080"}
              handleClick={() =>
                handleEditReschedule(navigate, appointmentDetails)
              }
              fwidth={'100%'}
              style={{ borderRadius: "4px", padding: "10px 15px",}}
              val={"Edit Appointment"}
            />
      </Grid>
         }

{appointmentDetails?.status !== "Void" &&
          appointmentDetails?.status !== "Canceled" &&
          appointmentDetails?.status !== "Completed" &&
          appointmentDetails?.date &&
      <Grid item lg={1.4} xl={1} sm={3.6} md={3.3} xs={5.8}>
            <AppointmentStaffStatus
            
              label={"Cancel/Refund"}
              isFetch
              value={"Canceled"}
              mainColor={"#EB5757"}
              appointment={appointmentDetails}
              fwidth={'100%'}
              
            />
      </Grid>
         }
         
         
         <Grid item lg={1.5} xl={1.2} sm={3.6} md={3.3} xs={5.8}>
         <TreatmentFormButton fwidth={'100%'} data={appointmentDetails} />
         
         
         </Grid>


        

        
      </Grid>
      </div> */} 
      <Box sx={{ display:'flex',justifyContent:'end' }}>
      <AppointmentDrawer
          tabData={tabData}
          appointment_id={appointment_id}
          pathname={pathname}
          redirect={redirect}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: { xs: "0", sm: "20px" },
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Box>{drawer()}</Box>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={() => setMobileOpen(!mobileOpen)}
            sx={{
              display: { xs: "none", lg: "block" },
            }}
          >
            {drawer()}
          </Drawer>
        </Box>
        <Grid container>
          {active === 0 && (
            <Grid
              item
              xs={12}
              sx={{
                background: "#FFFFFF",
                borderRadius: "4px",
                p: { sm: "32px", xs: "12px" },
              }}
            >
              <AppointmentDetails appointmentDetails={appointmentDetails} />
            </Grid>
          )}
          {active === 1 && (
            <Grid
              item
              xs={12}
              sx={{
                background: "#FFFFFF",
                borderRadius: "4px",
                p: { xs: "8px", sm: "32px" },
              }}
            >
              <AppointmentHistory onShowParent={handleShowParent}appointmentDetails={appointmentDetails} />
            </Grid>
          )}
          {active === 2 && (
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{ background: "#EDF2F7" }}>
                <Grid item sm={6} xs={12} sx={{ background: "#EDF2F7" }}>
                  <AppointmentNotes appointmentDetails={appointmentDetails} />
                </Grid>
                <Grid item sm={6} xs={12} sx={{ background: "#EDF2F7" }}>
                  <AddNotes appointmentDetails={appointmentDetails} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {active === 3 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12} sx={{ background: "#EDF2F7" }}>
                  <Attachments appointmentDetails={appointmentDetails} />
                </Grid>
                <Grid item sm={6} xs={12} sx={{ background: "#EDF2F7" }}>
                  <AddAttachment appointmentDetails={appointmentDetails} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {active === 5 && (
            <Grid item xs={12}>
              <GalleryLayout appointmentDetails={appointmentDetails} />
            </Grid>
          )}
          {active === 4 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12} sx={{ background: "#EDF2F7" }}>
                  <AppointmentReminder
                    appointmentDetails={appointmentDetails}
                  />
                </Grid>
                <Grid item sm={6} xs={12} sx={{ background: "#EDF2F7" }}>
                  <AddReminder appointmentDetails={appointmentDetails} />
                </Grid>
              </Grid>
            </Grid>
          )}
          {active === 6 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ background: "#EDF2F7" }}>
                  <AppointmentSubmittedForm
                    customerID={appointmentDetails?.customer?.id}
                    providerId={appointmentDetails?.provider?.id}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default AppointmentDetailsLayout;
