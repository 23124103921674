// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `bodY {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, #1976D2, #fff);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/loading/loading.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;EAErB;EACA;IACE,UAAU;IACV,aAAa;IACb,iDAAiD;IACjD,YAAY;IACZ,kCAAkC;IAClC,mBAAmB;EACrB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;EACF","sourcesContent":["bodY {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  .center {\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n   \n  }\n  .wave {\n    width: 5px;\n    height: 100px;\n    background: linear-gradient(45deg, #1976D2, #fff);\n    margin: 10px;\n    animation: wave 1s linear infinite;\n    border-radius: 20px;\n  }\n  .wave:nth-child(2) {\n    animation-delay: 0.1s;\n  }\n  .wave:nth-child(3) {\n    animation-delay: 0.2s;\n  }\n  .wave:nth-child(4) {\n    animation-delay: 0.3s;\n  }\n  .wave:nth-child(5) {\n    animation-delay: 0.4s;\n  }\n  .wave:nth-child(6) {\n    animation-delay: 0.5s;\n  }\n  .wave:nth-child(7) {\n    animation-delay: 0.6s;\n  }\n  .wave:nth-child(8) {\n    animation-delay: 0.7s;\n  }\n  .wave:nth-child(9) {\n    animation-delay: 0.8s;\n  }\n  .wave:nth-child(10) {\n    animation-delay: 0.9s;\n  }\n  \n  @keyframes wave {\n    0% {\n      transform: scale(0);\n    }\n    50% {\n      transform: scale(1);\n    }\n    100% {\n      transform: scale(0);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
