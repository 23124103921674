import React, {useEffect, useState} from 'react';
import backImg from '../../assets/icon/arrow-back.svg';
import './styles.scss'
import {useNavigate} from "react-router";

const PublicHeader = ({heroText,heroSubText,to,setOpenLogin}) => {
    const navigate = useNavigate();
    const extractSubdomain = () => {
        const hostname = window.location.hostname;
        const hostnameParts = hostname.split('.');
        return hostnameParts.length > 2 ? hostnameParts[0] : null;
    };
    const [logo, setLogo] = useState(null);
    const subdomain = extractSubdomain();
    useEffect(() => {
        if (subdomain === "zoey") {
            import('../../assets/images/logo.jpg').then(logo => {
                setLogo(logo.default);
            });
        } else {
            import('../../assets/logo/3d-logo.png').then(logo => {
                setLogo(logo.default);
            });
        }
    }, [subdomain]);
    const handleGoBack=()=>{
        if(setOpenLogin){
            setOpenLogin(false)
        }else {
            navigate(to)
        }
    }
    return (
       <div>
           <div className={"pb-header"}>
               {subdomain == "app" &&
               <div className={'cursor'} onClick={handleGoBack}>
                   <img src={backImg} alt={'back-arrow'}/>
               </div>
               }
               <div className={'pb-logo-box'}>
                   <img src={logo} alt={'logo'}/>
               </div>
           </div>
           <div className={"pb-hero-box"}>
               <h3 style={{ fontFamily:'helvetica-lt-std-bold' }}>{heroText}</h3>
               {subdomain == "app" &&
               <p style={{ fontFamily:'helvetica-lt-std-light' }}>{heroSubText}</p>
               }
           </div>
       </div>
    );
};

export default PublicHeader;