import React from 'react';
import {FormControl, FormHelperText, FormLabel, MenuItem, Select} from "@mui/material";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const FromDropdown = ({label,values,errors,touched,name,handleChange,options}) => {
    return (
        <FormControl fullWidth sx={{mb:'16px'}}>
            <FormLabel focused={false} sx={{fontSize:"16px",color:'#27272e',letterSpacing:'.3px'}}>{label}</FormLabel>
            <Select
                name={name}
                value={values[name]}
                onChange={handleChange}
                size={'small'}
                sx={{
                    background: "#EDF2F7",
                    height:"44px",
                    ".MuiOutlinedInput-notchedOutline": {
                        borderWidth: "0!important"
                    },
                }}
                MenuProps={MenuProps}
            >
                {options && options.map((item,index)=>(
                    <MenuItem key={index} value={item?.id}>{item?.name || item?.event_name}</MenuItem>
                ))}
            </Select>
            {errors[name] && touched[name] && <FormHelperText sx={{color: '#CB3838',width:"max-content",mx:0}}>{errors[name]}</FormHelperText>}
        </FormControl>
    );
};

export default FromDropdown;