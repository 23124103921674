import React from 'react';
import { ThemeProvider,createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: 'helvetica-lt-std-roman',
    },
});


const Theme = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
};

export default Theme;