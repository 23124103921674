import React, {useEffect, useRef, useState} from 'react';
import {Button, FormHelperText, Grid, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {formatCreditCardNumber, formatCVC, formatExpirationDate} from "../../services/utills";
import Payment from "payment";
import * as Yup from "yup";
import {useFormik} from "formik";
import {storePublicUserCreateCard, storeUserCreateCard} from "../../store/user/user-cards";
import {useDispatch} from "react-redux";
import {registerUser} from "../../store/auth";
import {findGuestUser} from "../../store/booking-widget";
import moment from "moment";

const PayByCard = ({ selectedMethods, handleBookAppointment, provider, user, registerData, refundPolicy, setLoading }) => {
    const [card, setCard] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [methodLoading, setMethodLoading] = useState(false);
    const hasRun = useRef(false);

    const dispatch = useDispatch();

    const validationSchema = Yup.object({
        number: Yup.string().required('Card number is required'),
        cvv: Yup.string().required('CVC is required'),
        expirationDate: Yup.string().required('Expiry date is required')
    });  

    const {
        values,
        handleSubmit,
        setFieldTouched,
        setFieldError,
        setFieldValue,
        resetForm,
        touched,
        errors,
        isValid
    } = useFormik({
        initialValues: {
            number: '',
            expirationDate: '',
            cvv: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            if (!values.number || !values.expirationDate || !values.cvv) {
                if (!values.number) {
                    setFieldTouched('number', true);
                    setFieldError('number', 'Card number is required');
                }
                if (!values.expirationDate) {
                    setFieldTouched('expirationDate', true);
                    setFieldError('expirationDate', 'Expiry date is required');
                }
                if (!values.cvv) {
                    setFieldTouched('cvv', true);
                    setFieldError('cvv', 'CVC is required');
                }
                return;
            }

            setLoading(true);
            try {
                let token = '';

                if (user?.id) {
                    token = localStorage.getItem('token');
                } else {
                    let findGuest = await dispatch(findGuestUser({ email: registerData?.email }));

                    if (findGuest?.payload?.success === 'true') {
                        token = findGuest?.payload?.data?.token;
                    } else {
                        let res = await dispatch(registerUser({ ...registerData, default_provider_id: provider?.id }));
                        let findGuestNew = await dispatch(findGuestUser({ email: registerData?.email }));
                        token = findGuestNew?.payload?.data?.token;
                    }
                }

                let res = await dispatch(storePublicUserCreateCard({ ...data, provider_id: provider?.id, token: token }));

                if (res?.payload.success === 'true') {
                    await handleBookAppointment(token, res?.payload?.data?.token);

                    resetForm();
                }
                if (res?.payload?.errors) {
                    setLoading(false);
                    let errors = res?.payload?.errors;

                    if (errors?.number) {
                        await setFieldTouched('number', true);
                        setFieldError('number', "Invalid Card Number");
                    }
                    if (errors?.expirationDate) {
                        await setFieldTouched('expirationDate', true);
                        setFieldError('expirationDate', "Invalid expiry date");
                    }
                    if (errors?.cvv) {
                        await setFieldTouched('cvv', true);
                        setFieldError('cvv', "Invalid CVC");
                    }
                }
            } catch (e) {
                setLoading(false);
            }
        },
    });

    
    const initializeCard = async (payments) => {
        const card = await payments.card();
        await card.attach('#card-container');
        return card;
    };

    const tokenize = async (paymentMethod) => {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
            }

            throw new Error(errorMessage);
        }
    };
    useEffect(() => {
        if (!hasRun.current) {
            const initializeSquarePayment = async (method) => {
                if (!window.Square) {
                    setPaymentError("Square is not loaded. Reload it or contact the provider.");
                    return;
                }
    
                let payments;
                try {
                    payments = window.Square.payments(method?.application_key, method?.merchant_id);
                } catch {
                    setPaymentError('Square credentials are invalid.');
                    return;
                }
    
                let card;
                try {
                    card = await initializeCard(payments);
                    setCard(card);
                } catch (e) {
                    setPaymentError("Square credentials are invalid.");
                    console.error('Initializing Card failed', e);
                }
            };
    
            (async () => {
                const cardContainer = document.getElementById('card-container');
                setMethodLoading(true);
                if (cardContainer && !cardContainer.hasChildNodes()) {
                    let res = selectedMethods.filter((item) => item?.payment_gateway === "Square");
    
                    if (res?.length) {
                        await initializeSquarePayment(res[0]);
                    }
                }
                setMethodLoading(false);
            })();
            hasRun.current = true;
        }
    }, [selectedMethods]);
    
    const handlePaymentMethodSubmission = async (event) => {
        event.preventDefault();
    
        try {
            event.target.disabled = true;
            setLoading(true);
            let token = '';
    
            // Check if card details are valid before making API calls
            const tokenID = await tokenize(card);
            if (!tokenID) {
                setPaymentError('Invalid card details. Please enter a valid card.');
                event.target.disabled = false;
                setLoading(false);
                return; // Exit the function if the card details are invalid
            }
    
            if (user?.id) {
                token = localStorage.getItem('token');
            } else {
                let findGuest = await dispatch(findGuestUser({ email: registerData?.email }));
    
                if (findGuest?.payload?.success === 'true') {
                    token = findGuest?.payload?.data?.token;
                } else {
                    let res = await dispatch(registerUser({ ...registerData, default_provider_id: provider?.id }));
                    let findGuestNew = await dispatch(findGuestUser({ email: registerData?.email }));
                    token = findGuestNew?.payload?.data?.token;
                }
            }
    
            // Proceed with booking appointment if card details are valid
            await handleBookAppointment(token, tokenID);
            resetForm();
    
        } catch (e) {
            setLoading(false);
            event.target.disabled = false;
            setPaymentError('FAILURE');   
            console.error(e.message);       
        }
    };
    
  
    

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        if (name === 'number') {
            let res = formatCreditCardNumber(value, Payment);
            setFieldValue('number', res);
        } else if (name === 'expirationDate') {
            let res = formatExpirationDate(value);
            setFieldValue('expirationDate', res);
        } else if (name === 'cvv') {
            let res = formatCVC(value, values?.number, Payment);
            setFieldValue('cvv', res);
        }
    }; 

    return (
        <form onSubmit={async (e) => {
            if (selectedMethods.some((item) => item?.payment_gateway === "BrainTree")) {
                handleSubmit(e);
            } else {
                await handlePaymentMethodSubmission(e);
            }  
        }}>
            <Grid container>
                <Grid item xs={12} sx={{ mt: "16px" }}>
                    {methodLoading ? <Box>Loading...</Box> : ""}
                    <Box id="card-container"></Box>
                </Grid>  

                {selectedMethods.some((item) => item?.payment_gateway === "BrainTree") &&
                    <Grid item xs={12} sx={{ mb: '24px' }}>
                        <Box>
                            <TextField
                                value={values.number}
                                onChange={handleInputChange}
                                label="Card Number"
                                name="number"
                                variant="outlined"
                                placeholder='0000 0000 0000 0000'
                                sx={{ width: '100%' }}
                            />
                            {errors.number && touched.number && <FormHelperText
                                sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>{errors.number}</FormHelperText>}
                        </Box>
                        <Box style={{ display: "flex", justifyContent: "space-between", marginTop: '30px' }}>
                            <Box sx={{ width: '45%' }}>
                                <TextField
                                    fullWidth
                                    value={values?.expirationDate}
                                    onChange={handleInputChange}
                                    name="expirationDate"
                                    variant="outlined"
                                    placeholder='MM/YY'
                                    inputProps={{ maxLength: '5' }}
                                    label="Expiry Date"
                                    sx={{ marginRight: "10px" }}
                                />
                                {errors.expirationDate && touched.expirationDate &&
                                    <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
                                        {errors.expirationDate}
                                    </FormHelperText>
                                }
                            </Box>
                            <Box sx={{ width: '45%' }}>
                                <TextField
                                    fullWidth
                                    value={values?.cvv}
                                    onChange={handleInputChange}
                                    name="cvv"
                                    variant="outlined"
                                    label="CVC"
                                    sx={{ marginRight: "10px" }}
                                />
                                {errors.cvv && touched.cvv &&
                                    <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
                                        {errors.cvv}
                                    </FormHelperText>
                                }
                            </Box>
                        </Box>
                    </Grid>  
                }
                {refundPolicy()}  
                <Grid item xs={12}>
                    <Button  
                        fullWidth
                        variant="contained"
                        sx={{ backgroundColor: "#00C569" }}
                        type='submit'
                        disabled={methodLoading || !isValid}
                    >
                        Book Appointment 
                    </Button>
                </Grid>
            </Grid>
        </form>  
    );
};

export default PayByCard;

   {/*<FormControl fullWidth sx={{mt:'16px'}}>*/}
                {/*    <TextField*/}
                {/*        // fullWidth*/}
                {/*        variant="outlined"*/}
                {/*        name={"amount"}*/}
                {/*        sx={bookingInput}*/}
                {/*        placeholder={"Enter Amount"}*/}
                {/*        // value={description}*/}
                {/*        // onChange={(e)=>setDescription(e.taregt.value)}*/}
                {/*    />*/}
                {/*</FormControl>*/}  