import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import CloseIcon from '@mui/icons-material/Close';
import CustomButtoncolor from '../../../../components/custom-button/customColorButton';
import {useDispatch, useSelector} from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SquareCard from "./squareCard";
import BraintreeCard from "./braintreeCard";
import * as Yup from "yup";
import moment from "moment/moment";
import {useFormik} from "formik";
import { toast } from 'react-hot-toast';
import {crmCreateCard, defaultUserCard, deleteUserCard, getAllExistingUserCards} from "../../../../store/crm/appointment/book-appointment";
import Loader from "../../../../components/loader/loader";
import visa from "../../../../assets/icon/visa.png";
import successIcon from "../../../../assets/icon/checkmark-circle.png";
import {Close} from "@mui/icons-material";
import {MySwal, successConfirmation} from "../../../../services/utills";
import {
    createCrmCustomerSubscription,
    getAllCRMCustomer,  
    getUserActiveSubscription,
    updateCrmCustomerSubscription
} from "../../../../store/crm/crm";
import ListItemText from "@mui/material/ListItemText";
import Waveloading from '../../../loading/Waveloading';
import WarningModal from '../../../../components/modal/WarningModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 550px)': {
      width: "95%",
    },
    gap:"5px"
  };
  const wrapper={
    alignItems:"center",
  }
  const heading={
    fontSize:"18px",
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'400',
    textAlign:"center",
    width:'100%'

  }
export const selectInput= {
   
    "& .MuiInputBase-root.MuiFilledInput-root.MuiSelect-root:after": {
      borderBottom: "none"
    },
    "& .MuiInputBase-root.MuiFilledInput-root.MuiSelect-root:before": {
      borderBottom: "none"
    }
  }
 
  export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}
export const btnwrappe ={
  marginTop:"5px",
  display:"flex",
  justifyContent:"flex-end",
  gap:"10px"
  
}

export const cardValidationSchema = Yup.object({
    number: Yup.string().required('Card number is required'),
    cvv: Yup.string().required('CVC is required'),
    expirationDate: Yup.string()
        .required('Expiry date is required')
        .test(
            'is-expiration-date-valid',
            'The expiration date should be later than the current date',
            (value) => {
                if (!value) {
                    return false;
                }

                const [month, year] = value.split('/').map(str => parseInt(str, 10));

                // Check the date validity and whether the expiry date is later than the current date
                if (isNaN(month) || isNaN(year) || !moment([year, month - 1]).isValid()) {
                    return false;
                }

                const currentDate = moment().startOf('month');
                const expirationDate = moment([year, month - 1]);

                return expirationDate.isSameOrAfter(currentDate)
            }),
});

const MakeMemberModal= ({is_member_ship_button_allow, customer_id,reFetchData, styles  }) => {
    console.log("id of child",is_member_ship_button_allow)
    const [squareCard, setSquareCard] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('select');
    const [subscriptionPlan, setSubscriptionPlan] = useState(null);
    const [userCards, setUserCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [useExistingCard, setUseExistingCard] = useState(false);
    const [open, setOpen] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const dispatch = useDispatch();
    const { selectedUserCard } = useSelector(state => state?.bookAppointmentStore)

    const authStore = useSelector(state => state?.authStore)
    const crmStore = useSelector(state => state?.crmStore)
    const {user} = authStore
    const {crmSubscriptions = []} = crmStore

   
    const toggleWarningModal = () => setShowWarningModal(!showWarningModal);
    const handlePaymentMethodChange = (event) => {
        setSelectedPaymentMethod(event.target.value);
      };
   

      useEffect(() => {
        const fetchExistingUserCards = async () => { 
          if (selectedPaymentMethod === 'existing_card') {
          
            const { payload } = await dispatch(getAllExistingUserCards( customer_id))
          }
        };
    
        fetchExistingUserCards();
      }, [ selectedPaymentMethod, dispatch]);


    const {
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        touched,
        errors,
    } = useFormik({ 
        initialValues: {
            number: '',
            expirationDate: '', 
            cvv: '',
        },
        validationSchema: cardValidationSchema,
        onSubmit: async (data) => {
           await  handleMakeMembership('BrainTree',selectedPlan,data)
        },
    });


    useEffect(() => { 

        (async () => {
            if (open) {
                setLoading(true) 
                let {payload} = await dispatch(getAllExistingUserCards(customer_id))
               await getActivePlan()
                if (payload?.data?.id) { 
                    if (payload?.data?.payment_method_id === 4) {
                        setUserCards(payload?.data?.cards?.cards || [])
                    } else {
                         setUserCards(payload?.data?.cards || [])
                    }
                } else {
                    setUserCards([])
                }
            }
        })()
    }, [open]) 
    

    const handleChange = (event) => {
        setSelectedPlan(event.target.value);
    };
 

    const getActivePlan = async () => {
        let res = await dispatch(getUserActiveSubscription(customer_id))

        setLoading(false)
        if (res?.payload?.data?.plan_id){
            setSubscriptionPlan(res?.payload?.data)
            setSelectedPlan(res?.payload?.data?.plan_id)  
        }
    }  

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);  
        setUseExistingCard(false)
        setSelectedCard(null)
    }

//  square tokken
    const tokenize = async (method) => {
        const tokenResult = await method.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
            }

            throw new Error(errorMessage);
        }
    };

    const handlePaymentMethodSubmission = async (event) => {
        event.preventDefault();  
        await handleMakeMembership('Square',selectedPlan) 

    }

    const handleMakeMembership =async (type,selectedPlan,data) => {

        let selectedPlanDetails
        crmSubscriptions.forEach((item)=> {
            if (item?.plan_id === selectedPlan){
                selectedPlanDetails = item
            }
        })
        let confirm = await MySwal.fire({
            icon: 'warning',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? you want to buy this plan <b> {selectedPlanDetails?.plan_name}</b> with this card.
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '20px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            console.log("Card Active")
            setLoading(true)
            let subscriptionFinalPayload = { 
                customer_id: customer_id,
            }
           if (type === 'now') {
               subscriptionFinalPayload['payment_method_token']= data
           }else if (type === 'BrainTree'){

               let cardData = {
                   user_id: customer_id,
                   number: data?.number,
                   expirationDate: data?.expirationDate,
                   cvv: data?.cvv,
               }
               let {payload} = await dispatch(crmCreateCard(cardData))

               if (payload?.data?.token) {
                   subscriptionFinalPayload['payment_method_token']=  payload?.data?.token
               }else {
                   setLoading(false)
                   return
               }
           }else if (type === 'Square'){
               try {
                   let squareToken = await tokenize(squareCard)
                   let cardData = {
                       user_id: customer_id,
                       nonce:squareToken,
                   }
                   let {payload} = await dispatch(crmCreateCard(cardData))

                   console.log(payload?.data)
                   if (payload?.data?.id) {
                       subscriptionFinalPayload['payment_method_token']=  payload?.data?.id
                   }else {
                       setLoading(false)
                       await MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        showConfirmButton: false,
                        html: <Box sx={{ mb: 4 }}>
                            {payload?.errors[0]}
                            <Box sx={{ mt: 3, }}>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        minWidth: "max-content",
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        px: '30px',
                                        mx: '10px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => MySwal.close()}
                                >
                                    Try Again!
                                </Button>
                            </Box>
                        </Box>
                    })
                    //    toast.error(payload?.errors[0], { duration: 5000 });

                       console.error(payload?.errors[0]);
                       return
                   }
               } catch (e) {
                   setLoading(false)
                   console.error(e.message, "message");
                   return
               }
           }


            let subscriptionResponse
            if (subscriptionPlan?.plan_id){
                subscriptionFinalPayload['old_plan_id'] = subscriptionPlan?.plan_id
                subscriptionFinalPayload['new_plan_id'] = selectedPlan
                subscriptionResponse = await dispatch(updateCrmCustomerSubscription(subscriptionFinalPayload))
            }else {
                subscriptionFinalPayload["plan_id"] = selectedPlan
                subscriptionResponse = await dispatch(createCrmCustomerSubscription(subscriptionFinalPayload))
            }
            await getActivePlan()
            setLoading(false)
            const {payload} = subscriptionResponse

            if (payload?.success === 'true'){
                resetForm();
              
              
                setLoading(true); // Restart loading
              
                setTimeout(async () => {
                    setLoading(false); // Stop loading after 40 seconds
                    await successConfirmation("Success", `Congratulations on Signing up a new member to ${selectedPlanDetails?.plan_name}.`);
                    await dispatch(getAllCRMCustomer());
                    handleClose();
                  }, 40000); // 40 seconds delay
                
           
                  handleClose();

            }
            console.log(payload,"payload")
        }

    }     

 

    const memberShipSelection = () => (
        <Grid item lg={12} md={12} sm={12} sx={{mb: '24px',mt: useExistingCard ? "24px" :''}}>
            <FormControl style={{width: '100%'}} sx={selectInput}>
                <Typography>Subscription Plan</Typography>
                <Select
                    value={selectedPlan}
                    onChange={handleChange}
                    renderValue={(selected) => {
                        if (selected === 'select') {
                            return 'Choose membership plan';
                        }

                        const selectedOption = crmSubscriptions.find((plan) => plan.plan_id === selected);
                        return selectedOption?.plan_name || '';
                    }}
                >
                    <MenuItem selected={"select" === selectedPlan} value={"select"}>Choose membership plan</MenuItem>

                    {crmSubscriptions?.length > 0 &&
                        crmSubscriptions.map((plan, index) => {
                            return(
                            <MenuItem
                                value={plan?.plan_id}
                                key={index}
                            >
                                <Checkbox checked={selectedPlan === plan?.plan_id} />
                                <ListItemText primary={plan?.plan_name} />
                            </MenuItem>
                        )})
                    }
                </Select>
            </FormControl>
        </Grid>
    )
    const defaultusercard = async (cardId, event) => {
        const result = await MySwal.fire({
          title: 'Are you sure?',
          text: "Do you want to make this card default?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, make it default'
        });
    
        if (result.isConfirmed) {
          setLoading(true);
    
          console.log("card data", selectedUserCard);
          const payload = {
            card_id: cardId,
            provider_id: user?.id,
            user_id: selectedUserCard?.data?.user?.id,
          }; 
          console.log("data", payload);
      
          try {
            await dispatch(defaultUserCard(payload));
    
    
            setSelectedCard(event);
          } catch (error) {
            console.error("Error in defaultUserCard:", error);
    
          } finally {
            setLoading(false);
    
          }
        } else {
    
        }
      };   
    
      const deleteusercard = async (cardId, event) => { 
    
        const result = await MySwal.fire({     
          title: 'Are you sure?',
          text: "Do you want to delete this card?",   
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it'
        });
    
        if (result.isConfirmed) {
          setLoading(true)
    
          console.log("card data", selectedUserCard?.data?.application_token);
    
          const payload = {
      
            card_id: cardId,
            customer_id: customer_id,
            provider_id: user?.id
          };
          console.log("data", payload);
    
          try {
            await dispatch(deleteUserCard(payload));
    
    
          } catch (error) {
            console.error("Error in deleteUserCard:", error);   
      
          } finally {
            let { payload } = await dispatch(getAllExistingUserCards(customer_id))
            setUserCards(payload?.data?.cards?.cards)
            setLoading(false);
    
          }
        } else {   
    
        }
      };

    return (
        <Box>
            {loading ? <Waveloading fullSize/> : ""}
            <CustomButtoncolor
               handleClick={() => {
                if (is_member_ship_button_allow === 1) {
                  handleOpen(); 
                } else {
                  toggleWarningModal();
                }
              }}
                mainColor={"#EB5757"}   
                size="md"
                val={'Make / Change Membership'}
                style={{
                    padding: '7px 15px',
                    fontSize: "18px",
                    minWidth: 'max-content',
                    borderRadius: "6px!important",     
                    width: "100%",
                    "@media (minWidth: 420px)": {
                        width: 'auto'
                    },
                    ...styles
                    }}
            />
            <Modal     
                open={open}
                onClose={handleClose}     
            >
                <Box sx={style}>
                    <Grid container spacing={1} sx={wrapper}>
                        <Grid item lg={12} style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                            { useExistingCard && <IconButton  onClick={() => {
                                setUseExistingCard(false)
                                setSelectedCard(null)   
                            }}>       
                                <ArrowBackIosNewIcon/>     
                            </IconButton>}
                            <Typography
                                sx={heading} 
                            >
                                {subscriptionPlan?.plan_id ?'Change Membership': "Make Member"}
                            </Typography>  
                            <IconButton
                                onClick={() => handleClose()}
                            >
                                <Close/>
                            </IconButton>
                        </Grid>
                        <Box style={{marginTop: '26px', width: "100%", padding: "0px 3px 0px 10px"}}>
                            <form   
                                onSubmit={async (e) => {   
                                    e.preventDefault()
                                    if (selectedPlan!=='select') {
                                       if (subscriptionPlan?.plan_id !== selectedPlan) {

                                           if (useExistingCard) {
                                               if (selectedCard) {
                                                   await handleMakeMembership("now", selectedPlan, selectedCard)
                                               } else {
                                                   await successConfirmation('Error', "Choose A Card", 'error')

                                               }
                                           } else {

                                               if (user?.active_payment_method?.payment_gateway === "BrainTree") {
                                                   handleSubmit(e)
                                               } else {
                                                   await handlePaymentMethodSubmission(e)
                                               }
                                           }
                                       }else {
                                           await successConfirmation('Warning',`Already  Subscribed  with ${subscriptionPlan?.plan_name}`,'warning')
                                       }

                                    }else {
                                     await successConfirmation('Error',"Choose Membership Plan",'error')
                                    }
                                }}
                            >
                                {
                                    useExistingCard &&  userCards?.length > 0 && userCards.map((item, index) => (
                                        <Card
                                            key={index}
                                            sx={{
                                                mt: 2,
                                                border: `0.8px solid ${selectedCard === (item?.token || item?.id) ? "#128D64" : "#C4C4C4"}`,
                                                borderRadius: "4px",
                                                boxShadow: "none",
                                                position: "relative",
                                                overflow: "unset",
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setSelectedCard(item?.token || item?.id)}
                                        >
                                            <Box
                                                sx={{display: "flex", alignItems: "center", width: '100%', px: '70px'}}
                                            >
                                                <img
                                                    src={visa}
                                                    width="50px"
                                                    style={{marginLeft: 2}}
                                                    alt=""
                                                />
                                                <Typography
                                                    variant="h5"
                                                    color="initial"
                                                    sx={{
                                                        ml: 3,
                                                        fontSize: "16px",
                                                        "@media (max-width:500px)": {
                                                            marginLeft: "5px",
                                                            textAlign: "center",
                                                            fontSize: "15px",
                                                        },
                                                    }}
                                                >
                                                    {item?.cardType || item?.card_brand}-{item?.last4 || item?.last_4}
                                                </Typography>
                                                {selectedCard === (item?.token || item?.id) && <Box sx={{ml: "50px"}}>
                                                    <img
                                                        src={successIcon}
                                                        alt="successIcon"
                                                    />
                                                </Box>}
                                            </Box>
                                        </Card>
                                    ))}
                                {memberShipSelection()}  
            
                                {user?.active_payment_method?.payment_method_id === 4 && !useExistingCard &&
                                    <SquareCard
                                        setSquareCard={setSquareCard}
                                        paymentMethod={user?.active_payment_method}
                                    />
                                }
                                {
                                    user?.active_payment_method?.payment_gateway === 3 && !useExistingCard &&
                                    <BraintreeCard
                                        errors={errors}
                                        touched={touched}   
                                        setFieldValue={setFieldValue}
                                        values={values}  
                                    />  
                                }
   

                                <Grid item lg={12} md={12} sm={12} sx={btnwrappe} style={{marginBottom: '10px'}}>
                                    <CustomButtoncolor
                                        mainColor={"#4F4F4F"}   
                                        size="md"  
                                        val='Cancel'
                                        type={'button'}
                                        style={{      
                                            padding: '7px 5px',
                                            fontSize: "18px",
                                            minWidth: 'max-content',
                                            borderRadius: "6px!important",
                                            width: "15%",
                                            "@media (minWidth: 420px)": {
                                                 width: 'auto'
                                            },
                                        }}
                                        handleClick={handleClose}
                                    />   
                                    <Button  
                                        type={'submit'}
                                        variant="contained"
                                        size="large"
                                        style={{
                                            height: "50px",   
                                            backgroundColor: 'linear-gradient(180deg, #72D5FF -43.75%, #0496FF 86.52%)',
                                            borderRadius: '8px',
                                        }}
                                    >
                                        {subscriptionPlan?.plan_id ? "Change Membership" : "Make Member"}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>          
                                    {!useExistingCard &&  userCards?.length > 0 &&
                                        <Typography
                                            onClick={() => setUseExistingCard(true)}
                                            sx={{
                                                color: '#006AFF',
                                                mt: '16px',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                "&:hover":{
                                                    textDecoration:'underline'
                                                }
                                            }}
                                        >
                                            Use Existing Card
                                        </Typography>
                                    }
                                </Grid>
                            </form>
                        </Box>
                    </Grid>
                </Box>
            </Modal>
            {showWarningModal && <WarningModal />}
        </Box>
    )
};

export default MakeMemberModal;

