import React, {useEffect, useState} from 'react';
import {MySwal, successConfirmation} from "../../../../../services/utills";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {scrollCSS} from "../../../../../App";
import {Avatar, Box, Button, Grid, IconButton, Typography} from "@mui/material";
import {
    deleteAppointmentGallery,
    deleteAppointmentMilestone,
    getAppointmentMilestone
} from "../../../../../store/crm/appointment/attachment";
import Loader from "../../../../../components/loader/loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {DeleteOutline} from "@mui/icons-material";
import Waveloading from '../../../../loading/Waveloading';
import CustomModal from '../../../../../components/modal/customModal';

const AppointmentMilestones = () => {
    const [attachmentDetails, setAttachmentDetails] = useState(null)
    const [onHover, setOnHover] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modalImage,setModalImage]=useState(null)

    const crmAppointmentAttachmentStore = useSelector(state => state?.crmAppointmentAttachmentStore)
    const {milestones = []} = crmAppointmentAttachmentStore

    const dispatch = useDispatch()
    const {appointment_id} = useParams()


    useEffect(() => {
        (async () => {
            await dispatch(getAppointmentMilestone(appointment_id))
        })()
    }, [])


    const handleDelete = async (id) => {

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? you want to remove this milestone.
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes,Delete It.
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
            let res = await dispatch(deleteAppointmentMilestone({id: id, booking_id: appointment_id}))
            console.log(res)
            if (res?.payload?.success === "true") {
                await successConfirmation('Milestone Remove Successfully')
            }
            setLoading(false)
        }
    }

    const handleSelectedGallery = (attachment) => {

        if (attachmentDetails?.id){
            setAttachmentDetails(attachmentDetails?.id === attachment?.id  ? null : attachment)
        }else {
            setAttachmentDetails(attachment)
        }
    }
    return (
        <Box
            sx={{
                background: "#fff",
                minHeight: '100px',
                borderRadius: "8px",
                p: '24px',
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    mb: "32px",
                }}
            >
                <Typography
                    sx={{
                        fontSize: '20px',
                    }}
                >
                    Milestone
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        gap: '2px',
                        alignItems: "center",
                    }}
                >

                </Box>
            </Box>

            {loading ? <Waveloading fullSize/> : ""}

           <Grid
                    container
                    sx={{
                        gap: '20px',
                        // overflowY: 'auto',
                        // maxHeight: '500px',
                    }}
                >
                    {
                        milestones?.length > 0 ?
                            milestones.map((attachment) =>  (
                                <Grid
                                    item
                                    xs={12}
                                    key={attachment?.id}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            background: '#F2F2F2',
                                            borderRadius: "4px",
                                            cursor:'pointer',
                                        }}
                                        onClick={() => handleSelectedGallery(attachment)}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'helvetica-lt-std-bold',
                                                ml: "10px",
                                                textTransform:'capitalize',
                                            }}
                                        >
                                            {attachment?.tag_name}
                                        </Typography>

                                        <IconButton
                                            onClick={() => handleSelectedGallery(attachment)}
                                        >
                                            {attachmentDetails?.id === attachment?.id ?  <ArrowDropDownIcon/>:<ArrowDropUpIcon/>}

                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mt: '16px',
                                                display: "flex",
                                                gap: '16px',
                                                flexWrap:'wrap',
                                            }}
                                        >
                                            {
                                                attachment?.files?.length > 0 ?
                                                    attachment?.files.map((file, index) => (
                                                       <Box>
                                                        <Avatar
                                                            key={index}
                                                            src={file?.file}
                                                            onClick={()=>setModalImage(file?.file)}
                                                            sx={{
                                                                height: '180px',
                                                                width: '180px',
                                                            }}
                                                            variant="rounded"
                                                        />
                                                           <Box
                                                               sx={{
                                                                   display:'flex',
                                                                   justifyContent:'space-between'
                                                               }}
                                                           >
                                                               <Typography>
                                                                   {moment(file?.created_at).format("YYYY-MM-DD")}
                                                               </Typography>
                                                               <IconButton
                                                                       sx={{
                                                                        fontSize: "15px",
                                                                        position: "relative",
                                                                        p: "5px",
                                                                       
                                                                        background: "#EB5757",
                                                                        border: '1px solid #EB5757',
                                                                      
                                                                        "&:hover": {
                                                                            border: '1px solid #EB5757',
                                                                            background: '#fff',
                                                                            "svg": {
                                                                                color: '#EB5757'
                                                                            }
                                                                        }
                                                                    }}
                                                                    onMouseEnter={() => {
                                                                        setOnHover(true)
                                                                    }}
                                                                    onMouseLeave={() => {
                                                                        setOnHover(false)
                                                                    }}
                                                                    onClick={()=>handleDelete(file?.id)}
                                                                >
                                                                    <DeleteOutlineIcon sx={{color: '#fff', fontSize: '20px'}} />
                                                                </IconButton>
                                                            
                                                           </Box>
                                                       </Box>
                                                    ))
                                                    :
                                                    <Avatar
                                                        sx={{
                                                            height: '180px',
                                                            width: '180px',
                                                        }}
                                                        variant="rounded"
                                                    />
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                            :
                            <Grid xs={12} item>
                                <Typography sx={{textAlign: 'center'}}>None Milestone Gallery</Typography>
                            </Grid>
                    }
                </Grid>
                <CustomModal
                customWidth={"1000px"}
                open={modalImage!==null}
                setOpen={() => setModalImage(null)}
            >
                <Box>

            <Avatar
                 src={modalImage}
                // onClick={()=>handleMoveMilestone(file?.id)}
                sx={{
                    cursor:'pointer',
                    height: '100%',
                    width: '100%',
                }}
                variant="rounded"
            />
            </Box>
            </CustomModal>
        </Box>
    );
};

export default AppointmentMilestones;