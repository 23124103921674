import React, {useEffect, useState} from 'react';
import {Box, Modal, Typography} from "@mui/material";
import {getAllSubmittedTreatmentForms} from "../../../../../store/user/chart-form";
import {useDispatch} from "react-redux";
import {PlayArrow} from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Loader from "../../../../../components/loader/loader";
import Waveloading from '../../../../loading/Waveloading';
import LinkModal from '../../../../../components/card-appointment/LinkModal';

const AppointmentSubmittedForm = ({customerID,providerId}) => {
    const [allSubmittedForm, setAllSubmittedForm] = useState([])
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLinkUrl, setSelectedLinkUrl] = useState('');

  const openModal = (url) => {
    console.log("modal open",url)
    setSelectedLinkUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLinkUrl('');
  };
    useEffect(()=>{
        (async ()=>{
            setLoading(true)
            let {payload}= await dispatch(getAllSubmittedTreatmentForms({id: customerID, provider_id: providerId}))
            setAllSubmittedForm(payload)
            setLoading(false)
        })()
    },[])

    
    return (
        <Box sx={{background: "#fff", minHeight: '300px', borderRadius: "8px", p:{sm:'24px', xs:'8px'}}}>
            <Typography sx={{
                fontFamily: 'helvetica-lt-std-bold',
                fontSize: '20px',
                fontStyle: "normal",
                fontWeight: 700,
            }}>
               Submitted Form
            </Typography>
            {loading ? <Waveloading/>:
            <Box>
                <Typography
                    sx={{
                        my: '25px',
                        fontFamily: "helvetica-lt-std-bold",
                    }}
                >
                    Consent Submitted  Form:
                </Typography>
                {
                    allSubmittedForm?.consent?.length ?
                        allSubmittedForm?.consent?.map((form, index) => (
                            <Box
                                sx={{
                                    width: '100%',
                                    background: '#EDF2F7',
                                    p: '5px 10px',
                                    borderRadius: '5px',
                                    mt: '5px',
                                    mb: '10px',
                                    height:'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    justifyContent:'space-between',
                                }}
                                key={index}
                                onClick={() => openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}?submit=1`)}

                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <PlayArrow
                                        sx={{
                                            fontSize: '1.3rem',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            ml: '10px',
                                        }}
                                    >
                                        {form?.name}
                                    </Typography>
                                </Box>
                                {
                                    form?.is_submitted &&

                                    // <CheckCircleOutlineIcon
                                    //     sx={{
                                    //         color: '#27AE60',
                                    //         fontSize: '1.8rem'
                                    //     }}
                                    // />
                                <Typography variant="subtitle1" sx={{fontFamily: "helvetica-lt-std-bold"}}>{form?.submitted_time}</Typography>
                                }
                                
                            </Box>
                        )) :
                        <Typography
                            sx={{
                                fontFamily: "helvetica-lt-std-bold",
                                textAlign: 'center',
                                mt: '24px'
                            }}          
                        >
                            Empty.
                        </Typography>
                }
                <Typography
                    sx={{
                        my: '25px',
                        fontFamily: "helvetica-lt-std-bold",
                    }}
                >
                    Customer Service Submitted  Form:
                </Typography>
                {
                    allSubmittedForm?.customer_service_form?.length ?
                        allSubmittedForm?.customer_service_form?.map((form, index) => (
                            <Box
                                sx={{
                                    width: '100%',
                                    background: '#EDF2F7',
                                    p: '5px 10px',
                                    borderRadius: '5px',
                                    mt: '5px',
                                    mb: '10px',
                                    height:'35px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    justifyContent:'space-between',
                                }}
                                key={index}
                                onClick={() => openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}?submit=1`, '_blank')
                                }
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <PlayArrow
                                        sx={{
                                            fontSize: '1.3rem',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            ml: '10px',
                                        }}
                                    >
                                        {form?.name}
                                    </Typography>
                                </Box>
                                {
                                    form?.is_submitted &&
                                /*    <CheckCircleOutlineIcon
                                        sx={{
                                            color: '#27AE60',
                                            fontSize: '1.8rem'
                                        }}
                                    />*/
                                    <Typography variant="subtitle1" sx={{fontFamily: "helvetica-lt-std-bold"}}>{form?.submitted_time}</Typography>

                }
                            </Box>
                        )) :
                        <Typography
                            sx={{
                                fontFamily: "helvetica-lt-std-bold",
                                textAlign: 'center',
                                mt: '24px'
                            }}
                        >
                            Empty.
                        </Typography>
                }
                <Typography
                    sx={{
                        my: '25px',
                        fontFamily: "helvetica-lt-std-bold",
                    }}
                >
                    Submitted Chart  Form:
                </Typography>
                {
                    allSubmittedForm?.chart_form?.length ?
                        allSubmittedForm?.chart_form?.map((form, index) => (
                            <Box
                                sx={{
                                    width: '100%',
                                    background: '#EDF2F7',
                                    p: '5px 10px',
                                    borderRadius: '5px',
                                    mt: '5px',
                                    mb: '10px',
                                    height:'35px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    justifyContent:'space-between',
                                }}
                                key={index}
                                onClick={() => {
                                    openModal(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}?submit=1`, '_blank')
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <PlayArrow
                                        sx={{
                                            fontSize: '1.3rem',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            ml: '10px',
                                        }}
                                    >
                                        {form?.name}
                                    </Typography>
                                </Box>
                                {
                                    form?.is_submitted &&
                                    <CheckCircleOutlineIcon
                                        sx={{
                                            color: '#27AE60',
                                            fontSize: '1.8rem'
                                        }}
                                    />
                                }
                            </Box>
                        )) :
                        <Typography
                            sx={{
                                fontFamily: "helvetica-lt-std-bold",
                                textAlign: 'center',
                                mt: '24px'
                            }}
                        >
                            Empty.
                        </Typography>
                }
            </Box>
            
            }
        
  
        <LinkModal open={isModalOpen} onClose={closeModal} linkUrl={selectedLinkUrl} />
        </Box>

    );
};

export default AppointmentSubmittedForm;