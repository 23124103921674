import React, {useState} from 'react';
import useStyles from "../styles";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {customConfirmation} from "../../../services/utills";
import {cancelUserBooking} from "../../../store/booking-widget";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {Box, Card, Container, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import Loader from "../../../components/loader/loader";
import CustomModal from "../../../components/modal/customModal";
import RescheduleCalender from "../rescheduleCalender";
import CloseTab from "../closeTab";
import Button from "@mui/material/Button";
import {ReactComponent as Close} from "../../../assets/icon/close-outline.svg";
import moment from "moment";
import Waveloading from '../../loading/Waveloading';


const PlanConfirmation = ({confirmation,setConfirmation,handleReset}) => {
    const [loading,setLoading] = useState(false)
    const [rescheduleData, setRescheduleData] = React.useState(null);


    const classes = useStyles();

    const user = useSelector((state) => state?.authStore?.user);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleBook = () => {
        if (window.innerWidth >= 768) { // Assuming 768 pixels is the breakpoint for desktop screens
            window.location.href = user?.explore_services_url + "/#services";
          } else {
            window.location.href = "/home";
          }
      
    }
    const handleRescheduleAppointment = async () => {

        if (confirmation?.id) {

            if (await customConfirmation("Reschedule Appointment", " Would you like to reschedule?", "Yes, Reschedule", "Cancel")) {
                setRescheduleData(confirmation)
                // let res = await dispatch(cancelUserBooking())
            }
        }
    }

    const handleScheduleClose = () => {
        setRescheduleData(null)
    }
    const handleCancel = async (item) => {
        if (confirmation?.id) {
            if (await customConfirmation("Cancel Appointment", "Are you sure you want to cancel your appointment?")) {
                setLoading(true)
                let res = await dispatch(cancelUserBooking({booking_id: confirmation?.id,type:false}))
                setLoading(false)
                if (res?.payload?.success ==='true'){
                    const MySwal = withReactContent(Swal);
                    const result = await MySwal.fire({
                        title: '',
                        text: '',
                        showConfirmButton: false,
                        didOpen: () => {
                            // Directly access the swal popup and add styles
                            const popup = document.querySelector('.swal2-popup');
                            if (popup) {
                                popup.style.maxWidth = '700px';
                                popup.style.width = '100%';
                                // Add more styles as needed
                            }
                        },
                        html:
                            <Box>
                                <Box>
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: '15px 15px'}}>
                                        <IconButton
                                            size="small"
                                            onClick={() => MySwal.close()}
                                        >
                                            <Close/>
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Box sx={{p:'40px 20px'}}>
                                    <Typography  sx={{textAlign: "center",fontFamily: "helvetica-lt-std-bold", fontSize:'30px',fontWeight: '600'}}>
                                        Your appointment has been cancelled successfully!
                                    </Typography>
                                </Box>
                            </Box>
                    });

                }
            }
        }
    }
    const handleClose = () => {
        setRescheduleData(null)
    }



    return (
        <Box className={classes.main}>
            {loading? <Waveloading fullSize/> :""}
            <CustomModal
                customWidth={'700px'}
                setOpen={() => handleClose()}
                open={!!rescheduleData?.id}>
                <Box>
                    <RescheduleCalender
                        loading={loading}
                        handleClose={()=>handleScheduleClose()}
                        setLoading={setLoading}
                        setConfirmation={setConfirmation}
                        rescheduleData={rescheduleData}
                    />
                </Box>

            </CustomModal>
            <Container maxWidth="md">
                <Typography
                    variant="h4"
                    color="initial"
                    sx={{ fontFamily: "helvetica-lt-std-bold" }}
                >
                    Appointment Confirmed!
                </Typography>
                <Typography
                    variant="h6"
                    color="initial"
                    sx={{ fontFamily: "helvetica-lt-std-roman", color: "#B0A4A4", mt: 1 }}
                >
                    We are looking forward to seeing you on selected time
                </Typography>
                <CloseTab/>

                {/* Card */}

                <Card sx={{ textAlign: "center", mt: 3 }} className={classes.card}>
                    <Container maxWidth="sm">
                        {confirmation?.length > 0 && confirmation.map((item,index)=>(
                            <Box key={index}>
                                <Box sx={{ mt: 4 }}>
                                    <Typography
                                        variant="h6"
                                        color="initial"
                                        sx={{ fontFamily: "helvetica-lt-std-bold" }}
                                    >
                                        {moment(item?.date).format("dddd, MMMM D, YYYY")}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color="initial"
                                        sx={{ fontFamily: "helvetica-lt-std-bold", mt: "5px" }}
                                    >
                                        {item?.start_time} - {item?.end_time}
                                    </Typography>
                                </Box>
                                 <Typography
                                        variant="h6"
                                        color="initial"
                                        sx={{ mt: 2, fontFamily: "helvetica-lt-std-roman" }}
                                    >
                                     {item?.service_name}
                                 </Typography>
                            </Box>
                        ))}

                        {confirmation?.length > 0 && confirmation.map((item ,index)=> index ===0 &&(
                        <Box sx={{ mt: 3 }} key={index}>
                            {item?.provider?.address?.address && <Typography
                                variant="h6"
                                color="initial"
                                className={classes.grayColor}
                            >
                                {item?.provider?.address?.address}
                            </Typography>}

                            {item?.provider?.phone && <Typography
                                variant="h6"
                                color="initial"
                                className={classes.grayColor}
                            >
                                {item?.provider?.phone}
                            </Typography>}
                        </Box>
                        ))}

                        {/* Button */}

                        <Box sx={{ mt: 2 }}>
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    className={classes.buttonStyle}*/}
                            {/*    sx={{*/}
                            {/*        bgcolor: "#1565c0",*/}
                            {/*        fontSize:{xs:'15px', md:'20px'}*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Add on Calender*/}
                            {/*</Button>*/}
                            {/*<Button*/}
                            {/*    variant="outlined"*/}
                            {/*    className={classes.buttonStyle}*/}
                            {/*    sx={{*/}
                            {/*        bgcolor: "tranparent",*/}
                            {/*        fontSize:{xs:'15px', md:'20px'}*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Share on Twitter*/}
                            {/*</Button>*/}
                        </Box>

                        {/* Relevent Links */}

                        <Box sx={{ mt:3, pb:{xs:3, md:6} }}>
                            <Typography onClick={()=>handleRescheduleAppointment()} variant="h6" color="initial" className={classes.blueColor}>
                                Reschedule Appointment
                            </Typography>
                            {/*<Typography*/}
                            {/*    variant="h6"*/}
                            {/*    color="initial"*/}
                            {/*    className={classes.blueColor}*/}
                            {/*    onClick={()=>handleCancel()}*/}
                            {/*>*/}
                            {/*    Cancel Appointment*/}
                            {/*</Typography>*/}
                            <Typography
                                variant="h6"
                                color="initial"
                                className={classes.blueColor}
                                onClick={()=>handleBook()}
                            >
                                Book another Appointment
                            </Typography>
                        </Box>
                    </Container>
                    <Box></Box>
                </Card>
            </Container>
        </Box>
    );
};

export default PlanConfirmation;