import React, {useState} from 'react';
import {Pagination, PaginationItem} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";

const CustomPagination = ({totalData,page,setPage,itemsPerPage}) => {


    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Pagination
                count={Math.ceil(totalData?.length / itemsPerPage) || 10}
                page={page}
                onChange={(event, newPage) => {
                    setPage(newPage);
                }}
                variant="outlined"
                shape="rounded"
                renderItem={(item) => (
                    <PaginationItem
                        sx={{background: '#fff'}}
                        slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                        {...item}
                    />
                )}
            />
        </Box>
    );
};

export default CustomPagination;