import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {
    Autocomplete, Checkbox,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as yup from "yup";
import SubmitButton from "../../../../../components/custom-button/submitButton";
import {useDispatch, useSelector} from "react-redux";
import {getProviderStaff} from "../../../../../store/crm/staff"; 
import Loader from "../../../../../components/loader/loader";
import {useParams} from "react-router";
import {storeCrmReminder} from "../../../../../store/crm/crm/crm-details";
import Waveloading from '../../../../loading/Waveloading';


const CrmAddReminder = () => {
    const [loading,setLoading] = useState(false)

    const crmStaffStore = useSelector(state => state?.crmStaffStore)
    const {crmStaffs = []} = crmStaffStore  

    const {user_id} = useParams()
    const dispatch = useDispatch()
    useEffect(() => {

        (async () => {
            if (!crmStaffs?.length) {
                await dispatch(getProviderStaff())   
            }
        })()   
    }, [])
     


    const validationSchema = yup.object({
        note: yup.string().min(3, "Note  is Too Short").required('Note is required'),
        created_by: yup.mixed().required('Staff is required'),
        date: yup
            .string()
            .required('Date is required')
            .test('greaterThanToday', 'Date must be greater than today', function(value) {
                const today = new Date();
                const selectedDate = new Date(value);   
                return selectedDate > today;
            }),
    });

    const {values, handleSubmit, setFieldValue, handleChange,resetForm, touched, errors} = useFormik({
        initialValues: {   
            date: '',         
            created_by: '',     
            note: '',
        }   ,  
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            setLoading(true)
            let finalData = {
                date: data?.date,
                created_by: data?.created_by?.id,
                note: data?.note,
                customer_id: user_id,
            }

            let res = await dispatch(storeCrmReminder(finalData))  
            if(res?.payload?.success === 'true'){
                resetForm()
            }else {
            }
            setLoading(false)
        },
    });

    return (
        <Box sx={{background: "#fff", minHeight: '100px', borderRadius: '8px',p:{sm:'24px', xs:'8px'}}}>
            <Typography   
                sx={{
                    fontFamily: 'helvetica-lt-std-bold',
                    fontSize: '20px',
                    fontStyle: "normal",
                    fontWeight: 700,
                    mb: '20px',
                }}>
                Add New Reminder
            </Typography>
            {loading ? <Waveloading fullSize/> :''}
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <FormLabel sx={{...labelStyles}}>Add Date</FormLabel>
                            <TextField  
                                type="date"
                                size={'small'}
                                name={"date"}       
                                variant="outlined"
                                placeholder={"Select Date"}
                                value={values?.date}
                                onChange={handleChange}
                            />
                            {errors?.date && touched?.date && <FormHelperText
                                sx={{color: '#CB3838', width: 'max-content', mx: 0}}>{errors?.date}</FormHelperText>}

                        </FormControl>
                    </Grid>  
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <FormLabel sx={{...labelStyles}}>Created By:</FormLabel>
                            <Autocomplete
                                options={crmStaffs}
                                size={'small'} 
                                value={values?.created_by}
                                disableCloseOnSelect={false}    
                                isOptionEqualToValue={(option, value) => {        
                                    return option?.id === value?.id
                                }} 
                                getOptionLabel={(option) => option?.name || ''}   
                                renderInput={(params) => {
                                    const {inputProps, helperText, error, ...rest} = params;
                                    return (<TextField   {...rest} inputProps={{...inputProps}}
                                                         placeholder={"Select Staff"}/>
                                    )
                                }}

                                onChange={(event, newValue) => {
                                    setFieldValue('created_by', newValue);
                                }}
                                renderOption={(props, option, {selected}) => (
                                    <MenuItem  {...props} key={option?.id}>
                                        <Checkbox
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option?.name}
                                    </MenuItem>
                                )}
                            />
                            {errors?.created_by && touched?.created_by && <FormHelperText sx={{
                                color: '#CB3838',
                                width: 'max-content',
                                mx: 0
                            }}>{errors?.created_by}</FormHelperText>}

                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel sx={{...labelStyles}}>Notes:</FormLabel>
                            <textarea
            className="MuiInputBase-input MuiOutlinedInput-input"
            rows={3}
           name='note'
            value={values?.note}
            onChange={handleChange}
            style={{
                width: '100%',
                resize: 'vertical', 
                padding: '8px',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                fontFamily: 'inherit', 
                fontSize: '1rem', 
            }}
        />
                           
                            {errors?.note && touched?.note && <FormHelperText
                                sx={{color: '#CB3838', width: 'max-content', mx: 0}}>{errors?.note}</FormHelperText>}

                        </FormControl>
                    </Grid>
                    <Grid xs={12} item>
                        <SubmitButton val={'Save'}/>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default CrmAddReminder;

 const labelStyles ={
     color:'#000',
     fontSize:'16px',
     mb:'10px',
     "&.Mui-focused": {
         color:'#000!important',
     }
 }