import React from 'react';
import { FormControl, MenuItem, Select} from "@mui/material";
import './styles.css';

const FilterInput = ({limit,setLimit,isBorder}) => {


    const handleChange =async (e)=>{
        const inputValue = e.target.value;
        setLimit(inputValue)
    }
    return (
        <FormControl sx={{
            // maxWidth: "40px" ,
            border: isBorder ? "1px solid #BDBDBD":'',
            borderRadius: '4px',
            // mr: '20px',
            mr:{sm:'0px',lg:'20px',xs:"2px"},
            '@media (max-width:350px)':{
                ".css-hfutr2-MuiSvgIcon-root-MuiSelect-icon":{
                    right:'1px'
                },
                ".MuiSelect-select":{
                    overflow:'unset !important'
                }
            }
        }}>
            <Select
                size={'small'}
                value={limit}
                onChange={handleChange}
                sx={{
                    background: "#fff",
                    borderRadius:'4px',
                    ".MuiOutlinedInput-notchedOutline": {
                        borderWidth: "0!important"
                    },
                    ".MuiInputBase-input":{
                        borderColor:"#EDF2F7",
                        p:'5px',
                        pr:"52",

                        "&:focus":{
                            borderColor: "#EDF2F7",
                            outline: "none",
                        },
                        "&:hover":{
                            borderColor: "#EDF2F7",
                            outline: "none",
                        }
                    }

                    
                }}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
            </Select>
        </FormControl>
    );
};

export default FilterInput;