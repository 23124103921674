import React from 'react';
import {Box, IconButton, Modal} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    outline:'none',
    transform: 'translate(-50%, -50%)',
    minHeight:'200px',
    minWidth:'200px',
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius: "15px",
};
  
export const modalScroll ={
    maxHeight:"80vh",
    overflowY:'auto',
    "&::-webkit-scrollbar": {
        width: "10px",
        height: "10px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff"
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(0, 0, 0, 0.2)",
        boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
      }
}

const CustomModal = ({open,setOpen,isCloseSideClick,children,isCloseHide,customWidth,mdWidth}) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={()=>setOpen(!!isCloseSideClick)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...modalStyle, width: {xs:mdWidth || '90%'  ,md:customWidth || "auto"}}}>
                    {!isCloseHide && <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: '15px 15px'}}>
                        <IconButton
                            size="small" 
                            sx={{}}
                            onClick={() => setOpen(false)}
                        > 
                            <CloseIcon sx={{color:'red'}}/>
                        </IconButton>
                    </Box>}
                    <Box sx={{p:{sm:'0 20px 20px 20px', xs: '0 10px 20px 10px'},...modalScroll}}>
                        {children}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default CustomModal; 