import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../components/loader/loader";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import FormInput, {
  inputStyle,
  labelStyle,
} from "../../../components/form/formInput";
import SubmitButton from "../../../components/custom-button/submitButton";
import { useEffect } from "react";
import Image from "../../../components/image/image";
import batch from "../../../assets/icon/batch.png";
import Checkbox from "@mui/material/Checkbox";
import UserLayout from "../index";
import { getUserDetails } from "../../../store/auth";
import FormInputPhone from "../../../components/form/formInputPhone";
import { updateUserAccount } from "../../../store/user/my-account";
import {
  dateOfBirthMonths,
  getMonthDays,
  MySwal,
} from "../../../services/utills";
import Autocomplete from "@mui/material/Autocomplete";
import RemovalAccountModal from "../../../components/modal/AccountRemoval";
import Footer from "../../home/Footer";

const MyAccount = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const authStore = useSelector((state) => state?.authStore);
  const { user } = authStore;
  

  useEffect(() => {
    (async () => {
      if (!user?.id) {
        await dispatch(getUserDetails());
      }
    })();
  }, [user]);

  const validationSchema = Yup.object({
    postal_code: Yup.string().matches(
      /^(?:\d{5}|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$/,
      "Postal code is invalid"
    ),
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string().when("countryCode", {
      is: "+1",
      then: Yup.string()
        .matches(/^[1-9][0-9]\d{8}$/, "Invalid pakistani phone number")
        .required("Phone number is required"),
      otherwise: Yup.string()
        .matches(/^(\d{3}-?)?\d{3}-?\d{4}$/, "Invalid canada phone number")
        .required("Phone number is required"),
    }),
    countryCode: Yup.string(),
    profile_image: Yup.mixed(),
    birth_month: Yup.mixed(),
    birth_day: Yup.mixed(),
  });
  const {
    values,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    setFieldValue,
    handleChange,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      name: "",
      profile_image: null,
      email: "",
      phone: "",
      countryCode: "+1",
      city: "",
      state: "",
      country: "",
      birth_day: "",
      birth_month: "",
      address: "",
      postal_code: "",
      gender: "",
      bio: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(data).forEach(function (key, index) {
        if (key === "profile_image") {
          if (typeof data[key] !== "string") {
            formData.append(key, data[key]);
          }
        } else if (key === "countryCode" || key === "phone") {
          formData.append(key, data["countryCode"] + data["phone"]);
        } else {
          formData.append(key, data[key]);
        }
      });

      let res = await dispatch(updateUserAccount(formData));

      if (res?.payload) {
        if (res?.payload?.success === "true") {
          await MySwal.fire({
            icon: "success",
            title: "Congratulations",
            showConfirmButton: false,
            html: (
              <Box sx={{ mb: 4 }}>
                <Typography>
                  Profile Information Updated Successfully.
                </Typography>
                <Button
                  sx={{
                    mt: 3,
                    backgroundColor: "#fff",
                    minWidth: "200px",
                    height: "62px",
                    border: "1px solid #666666",
                    borderRadius: "10px",
                    fontSize: "20px",
                    color: "#666666",
                    "&:hover": {
                      backgroundColor: "#fff",
                      border: "1px solid #666666",
                    },
                  }}
                  onClick={() => MySwal.close()}
                >
                  Ok
                </Button>
              </Box>
            ),
          });
        }
        if (res?.payload?.errors) {
          const errors = res?.payload?.errors;
          try {
            if (Object.keys(errors)?.length > 0) {
              Object.keys(errors).forEach(function (key, index) {
                setFieldTouched(key, true);
                setFieldError(key, errors[key]);
              });
            }
          } catch (e) {}
        }
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    (() => {
      if (user?.id) {
        const address = user?.address;

        setFieldValue("name", user?.name || "");
        setFieldValue("profile_image", user?.profile_image || null);
        setFieldValue("postal_code", address?.postal_code || "");
        setFieldValue("email", user?.email || "");
        setFieldValue("city", address?.city || "");
        setFieldValue("state", address?.state || "");
        setFieldValue("country", address?.country || "");
        setFieldValue("address", address?.address || "");
        setFieldValue("gender", user?.gender || "");
        setFieldValue("birth_month", user?.birth_month || "");
        setFieldValue("birth_day", user?.birth_day || "");
        setFieldValue("bio", user?.bio || "");
        if (user?.phone.includes("+92")) {
          setFieldValue("phone", user?.phone.replace(/^\+92/, ""));
          setFieldValue("countryCode", "+92");
        }
        if (user?.phone.includes("+1")) {
          setFieldValue("phone", user?.phone.replace(/^\+1/, ""));
          setFieldValue("countryCode", "+1");
        }
      }
    })();
  }, [user?.id]);

  return (
    <UserLayout>
      {loading ? <Loader fullSize /> : ""}

      <form onSubmit={handleSubmit}>
        <Grid
          container
          sx={{
            height: "auto",
            background: "#fff",
            pb: { sm: 0, xs: "120px" },
          }}
        >
          <Grid item xs={12} lg={12} md={6} xl={2.1}>
            <Image
              styles={{
                width: "160px",
                height: "160px",
                margin: { sm: "unset", xs: "0 auto" },
              }}
              name={"profile_image"}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          </Grid>
          <Grid item lg={12} md={6} xs={12} xl={9.9}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection:{xs:"column",sm:"row"},
                justifyContent: { sm: "start", xs: "center" },
                alignItems: { sm: "baseline", xs: "center" },
              }}
            >
              <Grid item lg={4}>
                <Typography
                  variant="h5"
                  color="initial"
                  sx={{
                    mb: 2,
                    fontSize: "24px",
                    fontFamily: "helvetica-lt-std-roman",
                    textAlign:{xs:"center",sm:"left"},
                  }}
                >
                  {user?.name}
                </Typography>
              </Grid>
              {user?.is_membered === 1 && (
                <Grid item lg={4}>
                  <Box sx={{ display: "flex",justifyContent:{xs:"center",sm:"normal"}, alignItems: "center" }}>
                    <Typography
                      variant="h5"
                      color="initial"
                      sx={{
                        color: "#128D64",
                        mb: 2,
                        fontSize: "14px",
                        fontFamily: "helvetica-lt-std-roman",
                      }}
                    >
                      <img
                        src={batch}
                        style={{ marginRight: "10px" }}
                        width="10px"
                        alt=""
                      />
                      Paid Member
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <FormInput
                  name={"name"}
                  label={"Full Name"}
                  placeholder={"Type your name"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormInputPhone
                  name={"phone"}
                  label={"Contact Number"}
                  placeholder={
                    values?.countryCode === "+1" ? "xxx-xxx-xxxx" : "3xxxxxxxxx"
                  }
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={4} sx={{ display: "flex" }}>
                <FormControl>
                  <FormLabel sx={labelStyle}>Gender</FormLabel>
                  <RadioGroup row name="gender">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values?.gender === "Male"}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              handleChange({
                                target: { name: "gender", value: "Male" },
                              });
                            }
                          }}
                        />
                      }
                      label="Male"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values?.gender === "Female"}
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              handleChange({
                                target: { name: "gender", value: "Female" },
                              });
                            }
                          }}
                        />
                      }
                      label="Female"
                    />
                  </RadioGroup>
                  {errors?.gender && touched?.gender && (
                    <FormHelperText
                      sx={{
                        color: "#CB3838",
                        width: "max-content",
                        mx: 0,
                      }}
                    >
                      {errors?.gender}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item lg={4} xs={12}>
                <FormControl fullWidth>
                  <FormLabel sx={labelStyle}>Date of Birth</FormLabel>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      options={dateOfBirthMonths}
                      getOptionLabel={(option) => option}
                      value={values?.birth_month}
                      disableClearable
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(event, value) => {
                        setFieldValue("birth_month", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"Month"}
                          sx={inputStyle}
                        />
                      )}
                    />
                    <Autocomplete
                      fullWidth
                      options={getMonthDays(values?.birth_month)}
                      getOptionLabel={(option) => option.toString()}
                      value={values?.birth_day}
                      disableClearable
                      isOptionEqualToValue={(option, value) =>
                        option.toString() === value.toString()
                      }
                      onChange={(event, value) => {
                        setFieldValue("birth_day", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"Date"}
                          sx={inputStyle}
                        />
                      )}
                    />
                  </Box>
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={12} sx={{ display: "flex" }}>
                <FormInput
                  name={"email"}
                  label={"Email"}
                  placeholder={"Email"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>

              <Grid item lg={4} xs={12}>
                <FormInput
                  name={"address"}
                  label={"Address"}
                  placeholder={"Type your address"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={4} xs={12}>
                <FormInput
                  name={"city"}
                  label={"City"}
                  placeholder={"City"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <FormInput
                  name={"state"}
                  label={"State"}
                  placeholder={"State"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <FormInput
                  name={"country"}
                  label={"Country"}
                  placeholder={"Country"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={4} xs={12}>
                <FormInput
                  name={"postal_code"}
                  label={"Postal Code"}
                  placeholder={"Postal Code"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormInput
                  name={"bio"}
                  label={"Bio"}
                  placeholder={"Bio"}
                  multiline
                  rows={4}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: "5px" , display: "flex",
                justifyContent: "end", }}>
              <SubmitButton val={"Update"} mainColor={"#aa2167"} subColor={"#fff"}  mb={"20px"} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                padding: "0px 5px",
              }}
            >
              <RemovalAccountModal />
            </div>

          </Grid>
        </Grid>
      </form>
      <Box
        style={{
          marginLeft: "calc(-50vw + 50%)",
          marginRight: "calc(-50vw + 50%)",
        }}
      >
        <Footer />
      </Box>
    </UserLayout>
  );
};

export default MyAccount;
