import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../../lib/apiHeader";


export const storeGiftCard= createAsyncThunk('storeGiftCard', async (data,{dispatch}) => {
  try {
      const response = await axios.post(apis.gift_cards_store, {...data},{
          headers: apiHeaders()
      })
      if (response?.data?.success==='true'){
        await dispatch(getgiftcard())

        return  response.data
    }else {
        return response?.data
    }

  }catch (err){
    console.log("error that comes",err)
      if (err?.response?.status!==200){
          toast.error(err?.response?.data?.message,{duration:4000})
      }  
    
  }
})
// 
export const updategiftcard= createAsyncThunk('updategiftcard', async (data,{dispatch}) => {
  try {
      const response = await axios.post(apis.update_Gift_Card, {...data},{
          headers: apiHeaders()
      })

      if (response?.data?.success==='true'){
          await dispatch(getgiftcard())

          return  response.data
      }else {
          return response?.data
      }
  }catch (err){
      console.log(err)
      if (err?.response?.status!==200){
          toast.error(err?.message,{duration:4000})
      }
      if (err?.response?.status!==200){
          return {
              data :err?.response,
              errors:true
          }
      }
  }
})


// update
export const deletegiftcard = createAsyncThunk('deletegiftcard', async (data,{dispatch}) => {
  try {
      const response = await axios.post(apis.delete_gift_card, {...data},{
          headers: apiHeaders()
      })

      if (response?.data?.success ==='true'){
        await dispatch(getgiftcard())
          toast.success("Gift Card Deleted Successfully",{duration:3000})

      }else {
          console.log(response?.data)
      } 

  }catch (err){
      console.log(err)
      if (err?.response?.status!==200){
          toast.error(err?.message,{duration:4000})
      }
  }
})

export const getgiftcardId = createAsyncThunk('getgiftcardId', async (id) => {
  try {
      const response = await axios.get(apis.gift_card_id+id,{
          headers: apiHeaders()
      })
      return  response?.data

  }catch (err){  
      if (err?.response?.status!==200){
          return {
              data : [],
              errors:err?.response?.data?.errors
          }
      }
  }
}) 


export const getgiftcard = createAsyncThunk("getgiftcard", async (status, { getState }) => {
  console.log('API called with status:', status); // Log the status

  const { search, pageLimit, currentPage } = getState().clinicGiftCardsStore; 
  let url = apis.get_gift_card;

  // Add status filter if present and not "All"
  if (status && status !== "All") {  
    url += `?status=${status}`;
  }

  // Add search filter if present
  if (search) {
    // Append '&' or '?' based on whether the status parameter is already present
    url += url.includes('?') ? `&search=${search}` : `?search=${search}`;
  }

  try {
    const response = await axios.get(url, {
      headers: apiHeaders(),
    });    

    if (response?.data?.success === "true") {
      return response?.data;   
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
});

export const updategiftFilter = createAsyncThunk('updategiftFilter', async (data) => {
  return data
})
export const clinicGiftCardsSlice = createSlice({
    name: 'clinicGiftCardsSlice',
    initialState: {
        services: [], 
        meta: {},
        links: {},
        search:'', 
        pageLimit:10, 
        currentPage:1,
        gifts:[],
    },
    reducers: {
    }, 
    extraReducers: builder => {
       
            // 
            builder.addCase(getgiftcard.fulfilled, (state, action) => {
              state.gifts= action.payload
          })

       
        builder.addCase(updategiftFilter.fulfilled, (state, action) => {
          const { search, pageLimit, currentPage } = action.payload; 
         
          if (search){
              state.search = search === 'reset' ? '' : search ;
          }
          if (currentPage){
            state.currentPage = currentPage ;
        }
         
      });
    }
})


export default clinicGiftCardsSlice.reducer