import { Box, Button, Container, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Link, Modal, Radio, Tab, Tabs, TextField, TextareaAutosize, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CustomButtoncolor from '../custom-button/customColorButton';
// import { sendReminder } from '../../store/crm/pos';
import { sendReminder } from '../../store/crm/pos';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Label, Margin } from '@mui/icons-material';
import { setEmail, setSms } from '../../store/crm/crm';
import { useFormik } from 'formik';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import { getAppointmentNotes } from '../../store/crm/appointment/notes';
import { useParams } from 'react-router';
import {scrollCSS} from "../../App";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddNotesModal from './AddNotesModal';
import { getCrmNotes } from '../../store/crm/crm/crm-details';
import { getAppointmentDetails } from '../../store/crm/appointment';
import AppointmentDetailsmodal from './AppointmentDetailsmodal';
import Waveloading from '../../pages/loading/Waveloading';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 550px)': {
      width: "95%",
    },
    gap:"5px",
   

  };
  const wrapper={
    alignItems:"center",
  }
  const heading={
    fontSize:"24px",
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'500',
    textAlign:"center",
    width:'100%'

  }
 
  export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}
export const btnwrappe ={
  marginTop:"5px",
  display:"flex",
  justifyContent:"flex-end",
  gap:"10px"
  
}
 export const textareaStyle = {

  height: '220px',
  border:"none",
    background: "#EDF2F7",
    borderRadius: '4px',
    outline:"none",
      "&:focus": {
        borderColor: "#EDF2F7",
        outline: "none",
      },
      "&:hover": {
        borderColor: "#EDF2F7",
        outline: "none",
    }
    
};


const NoteModal= ({customer_id}) => {
      const dispatch = useDispatch()
      const [open, setOpen] = useState(false) 
      const [loading , setLoading]=useState(false)
      const handleClose = () => setOpen(false)
      const [message, setMessage] = useState("")
      const [subject, setSubject] = useState("")
      const [active, setActive] = useState(0) 
      const crmDetailsStore = useSelector(state => state?.crmDetailsStore)
      const {notes=[]} = crmDetailsStore 
      const [appointmentDetails, setAppointmentDetail] = useState(null)
    const {appointment_id} = useParams()
    const crmAppointmentStore = useSelector( 
        (state) => state?.crmAppointmentStore
      ); 
      const { appointmentDetail = [] } = crmAppointmentStore;
      

      const handleOpen = () => {
        setOpen(true); 
        if (!open) { 
            setLoading(true);
            dispatch(getCrmNotes(customer_id))
                .finally(() => setLoading(false));
        } 
    };


      useEffect(() => {
        // Ensuring the modal is open before making the API call
        if (open) {
            setLoading(true);
         dispatch(getCrmNotes(customer_id))
                .finally(() => setLoading(false));
        }
    }, [ open, dispatch]);
   

    const handleChange = (event, newValue) => {
        setActive(newValue)
    }  
     console.log("notes",notes,)
     const handleAppointmentDetail = async (id) => {

        let { payload } = await dispatch(getAppointmentDetails(id))

        if (payload?.id) {
            setAppointmentDetail(payload)
        } else {
            setAppointmentDetail(null)
        }
    }
      
    return (
      <>
        <CustomButtoncolor
        handleClick={handleOpen}
         mainColor={"#FDB0C0"}
         size="md"
         val='Notes'
         style={{
          padding:'10px 15px',
          fontSize: "14px",
          minWidth:'max-content',
          borderRadius: "6px!important",
          width: "100%",
          "@media (minWidth: 420px)": {
              width: 'auto'
          },
      }}                                  
      />
     <Modal
     style={{}}
       open={open}
       onClose={handleClose}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >
        <Container maxWidth="xl">
        {loading ? <Waveloading fullSize/>:''}
       <Box sx={style}>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <IconButton
                            size="small"
                            onClick={() => setOpen(false)}
                            style={{ color: "red" }} 
                        >
                           
                            <CloseRoundedIcon  style={{fontSize: '2rem' }} /> 
                        </IconButton>
                    </Box>
       <Box sx={{background:"#fff",minHeight:'100px',borderRadius: "8px",p:{sm:'24px', xs:'8px'}}} >
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
          <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
                width: '100%',
                // overflowX: 'auto'
               
            }}>
                <Tabs
                    value={active}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                        ".MuiTabs-indicator": {
                            background: '#000000'
                        },
                        ".Mui-selected": {
                            fontWeight: '600',
                            color: '#000!important',
                        },
                        '@media (max-width: 600px)': { // Adjust based on your responsive design needs
                            width: 'auto',
                            display: 'flex',
                        },
                        '& .MuiTabs-scroller': {
                            display: 'flex', // Ensure that the scroller is always flex to align tabs correctly
                        },
                    }}
                >
                    <Tab
                        sx={{
                            fontFamily:active === 0 ? "helvetica-lt-std-bold":"helvetica-lt-std-roman",
                            fontSize:'18px',
                            color: '#000',
                            textTransform: "capitalize",
                        }}
                        label={"Medical/Staff Notes "}
                        key={0}
                    />
                         <Tab
                        sx={{
                            fontFamily:active === 1 ? "helvetica-lt-std-bold":"helvetica-lt-std-roman",
                            fontSize:'18px',
                            color: '#000',
                            textTransform: "capitalize",
                        }}
                        label={"Customer Notes"}
                        key={1}/>

                        
                </Tabs>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12} sx={{ display:'flex', justifyContent:{lg:'flex-end', xs:'start'}, pr:{lg:3, xs:0} }}>
            
        <AddNotesModal appointmentDetail={appointmentDetail} />
            </Grid>
        </Grid>
       {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
     
        </Box> */}
            <Box
                sx={{
                    py:'24px',
                    overflowY:'auto',
                    maxHeight:'500px',
                    ...scrollCSS,
                }}
            >
                {active ===0 && <Box>
                    {/* <Typography style={{marginTop:"20px",fontWeight:600,fontFamily: 'helvetica-lt-std-bold'}}>Zoey AI</Typography> */}
                    {notes?.data?.Medical?.length > 0 ?
    notes?.data.Medical.map((note,index)=>(
        <Box
            key={index}
            sx={{ 
                py:'24px',
                px:'16px',
                borderTop:index>0 ?'1px solid #9D9D9D':'unset',
            }}>
            <Typography sx={{fontSize:'16px', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                <Typography
                    as={'span'}
                    sx={{
                        fontSize:'16px',fontFamily:"helvetica-world-bold",
                        mr:'3px',
                        fontWeight:600,
                    }}
                >
                    [ {note?.date} ]  {" "}
                    {note?.staff?.name}:
                </Typography>
                {note?.description}
                {note?.booking_id && (
                    <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}>
                        Booking # 
                        <span style={{ marginLeft: '8px' }}>
                            <AppointmentDetailsmodal booking_id={note?.booking_id} />
                        </span>
                    </span>
                )}
            </Typography>
        </Box>  
    ))
    : "none"
}

                     {/* <Typography style={{marginTop:"20px",fontWeight:600,fontFamily: 'helvetica-lt-std-bold'}}>Appointment</Typography>
                     {notes?.customer_appointments_notes?.Medical?.length > 0 ?
                        notes?.customer_appointments_notes?.Medical.map((note,index)=>(
                            <Box
                                key={index}
                                sx={{
                                    py:'24px',
                                    px:'16px',
                                    borderTop:index>0 ?'1px solid #9D9D9D':'unset',
                                }}>
                                <Typography sx={{fontSize:'16px'}}>
                                    <Typography
                                        as={'span'}
                                        sx={{
                                            fontSize:'16px',fontFamily:"helvetica-world-bold",
                                            mr:'3px',
                                            fontWeight:600,
                                        }}
                                    >
                                        [ {note?.date} ]  {" "}
                                        {note?.staff?.name}:
                                    </Typography>
                                    {note?.description} Booking #
                                    <span onClick={() => note?.booking_id && handleAppointmentDetail(note?.booking_id)}  style={{ color: '#2F80ED', cursor:  'pointer'  }}  >
                                        {note?.booking_id}
                                    </span>
                                </Typography>
                            </Box>
                        ))
                        : "none"
                    } */}

                </Box>
                }
                {active ===1 && <Box>
                     {/* <Typography style={{marginTop:"20px",fontWeight:600,fontFamily: 'helvetica-lt-std-bold'}}>Appointment</Typography> */}
                     {notes?.customer_appointments_notes?.Customer?.length > 0 ?
    notes?.customer_appointments_notes?.Customer.map((note, index) => (  
        <Box
            key={index}
            sx={{ 
                py:'24px',
                px:'16px',
                borderTop:index>0 ?'1px solid #9D9D9D':'unset',
            }}>
            <Typography sx={{fontSize:'16px', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                <span
                    sx={{
                        fontSize:'16px', fontFamily:"helvetica-world-bold",
                        fontWeight:600, marginRight: '8px'
                    }}
                >   
                    [{note?.date}] {note?.staff?.name}
                </span>
                (
                    <AppointmentDetailsmodal booking_id={note?.booking_id} />
                )
                <span sx={{ marginLeft: '8px' }}>{note?.description}</span>
            </Typography>
        </Box>   
    ))
    : "none"  
}

                </Box>}
            </Box>
        </Box>
   
       </Box>
       </Container>
     </Modal>
     </>
    )
  }
export default NoteModal