import React from "react";
import {
    Avatar,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {addWhiteShade, calenderColor} from "../../services/utills";
import {scrollCSS} from "../../App";


function WeekView({data, day,setStaffData}) {
    const startDate = day;
    const finalDays =[]
    const headerCells = [];
    const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];


    const navigate = useNavigate();
    const params = useParams();
    const defaultColor= "#f2f2f2"

    for (let j = 0; j < data?.length; j++) {
        let days =[]
        const staff = data[j]?.staff
        const staffSlots = data[j]?.slots

        for (let i = 0; i < 7; i++) {
            const date = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
            const cellData = staffSlots.filter((item) =>new Date(item?.date)?.getDate() === date?.getDate());
            // const modifiedColor = addWhiteShade(staff?.color);
            const modifiedColor = staff?.color ? addWhiteShade(staff?.color) : defaultColor;
            if (cellData?.length && cellData[0]?.slots?.length) {

                days.push(
                    <TableCell key={i} sx={{p: '10px'}}>
                        <Box sx={{
                            minHeight: '120px',
                            width: '170px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            borderRadius: '7px',
                            padding: '10px',
                            backgroundColor: modifiedColor,
                            color: staff?.color
                        }}>
                            <Box>
                                <Box component={Typography} sx={{fontWeight: "bold"}}>{staff?.name}</Box>
                                <Box component={Typography}>{cellData[0]?.checkin} - {cellData[0]?.checkout}</Box>
                            </Box>

                            <Box sx={{display: 'flex'}}>
                                <Box sx={{
                                    backgroundColor: '#fff',
                                    width: '45%',
                                    borderRadius: '10px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}
                                     onClick={()=>{setStaffData(staff)}}
                                >
                                    <CreateIcon sx={{fontSize: '11px'}}/>
                                    <Box sx={{fontSize: '11px', ml: "7px"}}>edit</Box>
                                </Box>
                                {/*<Box sx={{*/}
                                {/*    backgroundColor: '#fff',*/}
                                {/*    width: '45%',*/}
                                {/*    borderRadius: '10px',*/}
                                {/*    display: 'flex',*/}
                                {/*    justifyContent: 'center',*/}
                                {/*    alignItems: 'center',*/}
                                {/*    cursor: 'pointer'*/}
                                {/*}}>*/}
                                {/*    <DeleteOutlineIcon sx={{fontSize: '11px'}}/>*/}
                                {/*    <Box sx={{fontSize: '11px', ml: "7px"}}>delete</Box>*/}
                                {/*</Box>*/}
                            </Box>
                        </Box>
                        {/*<div className="day-name">*/}
                        {/*    {date.toLocaleDateString("default", { weekday: "short" })}*/}
                        {/*</div>*/}
                        {/*<div className="day-number">{date.getDate()}</div>*/}
                        {/*{cellData && <div className="data">{cellData.data}</div>}*/}
                    </TableCell>
                );
            }else {
                days.push(<TableCell key={i} sx={{p: '10px'}}>
                    <Box sx={{
                        minHeight: '120px',
                        width: '170px',

                    }}>
                    </Box>
                </TableCell>)
            }

            if (i === 6) {
                days.unshift(
                    <TableCell key={i + 1}>
                        <Box sx={{display:'flex',alignItems:'center',pl:'4px'}}>
                            <Avatar alt="Remy Sharp" sx={{borderRadius:'7px'}} src={staff?.profile_image} />
                            <Typography sx={{ml:"10px"}}>{staff?.name}</Typography>
                        </Box>
                    </TableCell>
                )
                finalDays.push({"days": days})
                days=[]
            }
        }
    }

    for (let i = 0; i < 7; i++) {
        const date = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000);
        headerCells.push(
            <TableCell sx={{px:'6px',}} key={i}>
                <Box sx={{display:'inline-block',mr:'4px',fontSize:'18px'}} className="day-name">{weekDays[date.getDay()]}</Box>
                <Box sx={{display:'inline-block',mr:'4px',fontSize:'18px'}} className="day-number">{date.getDate()}</Box>
            </TableCell>
        );
    }

    return (
        <TableContainer component={Paper} sx={{
            boxShadow: 'none',
            ...scrollCSS,
            paddingBottom:'5px',
            "&::-webkit-scrollbar ": {
                height: "5px",
            }}}
        >
            <Table aria-label="simple table" sx={{
                boxShadow: 'none', "td , th": {
                    border: "1px solid #E0E0E0"
                }
            }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{background: '#fff'}}>
                            <Box sx={{width:'150px',textAlign:'center',fontSize:'18px'}}>Staff Name</Box>
                        </TableCell>
                        {headerCells}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {finalDays.map((item,rowIndex)=>(
                        <TableRow key={rowIndex}>
                            {item?.days.map((item)=>(
                                item
                            ))}
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell sx={{background: '#fff'}}><Box sx={{color:'#2F80ED',cursor:'pointer'}} onClick={()=>{
                            navigate(`/crm/configuration/clinic/${params?.clinic_id}/add-staff`)
                          
                        }}>+ Add Employee</Box></TableCell>
                        <TableCell sx={{background: '#fff'}}> </TableCell>
                        <TableCell sx={{background: '#fff'}}> </TableCell>
                        <TableCell sx={{background: '#fff'}}> </TableCell>
                        <TableCell sx={{background: '#fff'}}> </TableCell>
                        <TableCell sx={{background: '#fff'}}> </TableCell>
                        <TableCell sx={{background: '#fff'}}> </TableCell>
                        <TableCell sx={{background: '#fff'}}> </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default WeekView;