import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../lib/apiHeader";
import apis from "../../../lib/apis/apis";
import {getUserDetails} from "../../auth";



export const getAllActiveClinicsLocations = createAsyncThunk("getAllActiveClinics", async (status, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_public_location, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data;
            }
            if (response?.data?.success === false) {

                return [];
            }
        } catch (err) {
           return []
        }
    }
);


export const getNearestLocations = createAsyncThunk("getNearestLocations", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_nearest_location+`${data?.postal_code}`, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }
            if (response?.data?.success === false) {
                return {};
            }
        } catch (err) {
            return err?.response?.data
        }
    }
);
export const updateUserLocation = createAsyncThunk('updateUserLocation', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.user_location, {...data},{
            headers: apiHeaders()
        })

        console.log(response)
        if (response?.data?.success === 'true'){
            await dispatch(getUserDetails())
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const userLocationSlice = createSlice({
    name: 'userLocationSlice',
    initialState: {
        activeClinics: [],
        meta: {},
        links: {},
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllActiveClinicsLocations.fulfilled, (state, action) => {
            state.activeClinics = action.payload?.data || []
        })
    }
})


export default userLocationSlice.reducer