import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../lib/apis/apis";
import { apiHeadersImage} from "../../lib/apiHeader";
import toast from "react-hot-toast";


export const storeImage = createAsyncThunk('storeLanguages', async (data) => {
    try {
        const response = await axios.post(apis.upload_image, data,{
            headers: apiHeadersImage()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            // toast.error(err?.message,{duration:4000})
        }
        // return  err?.response
    }
})