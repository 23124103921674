import React, {useState} from 'react';
import {Box, Button, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import FormDateInput from "../../../../components/form/FormDateInput";
import {MdDeleteOutline} from "react-icons/md";
import {confirmation, handleDelete} from "../../../../services/utills";
import * as yup from "yup";
import * as Yup from "yup";
import {useFormik} from "formik";
import { storeStaffHolidays} from "../../../../store/clinics/clinicSettings/business-hours";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Loader from "../../../../components/loader/loader";

const StaffHoliday = ({staffHolidays,staff_id}) => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()


    const validationSchema = yup.object({
        date: Yup.date().typeError("Staff holiday date is required").required("Staff holiday date is required"),
    });

    const {values,handleSubmit,resetForm,handleChange,touched,errors} = useFormik({
        initialValues: {
            date: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data,{setFieldTouched,setFieldError}) => {
            setLoading(true)

            let res = await dispatch(storeStaffHolidays({...data,provider_id:params?.clinic_id,staff_id: staff_id}))
            if (res?.payload?.errors) {
                const {errors} = res?.payload
                console.log(errors)
                if (errors?.date) {
                    setFieldTouched("date", true)
                    setFieldError("date", errors?.date)
                }

            } else if (res?.payload?.success === "true") {
                resetForm()
            }

            setLoading(false)
        },
    });




    const handleDeleteStaffHoliday =async (row)=>{
        if (await confirmation()) {
            setLoading(true);
            let finalData ={
                id:row?.id,
                provider_id:params?.clinic_id,
                staff_id:staff_id,
            }
            await handleDelete(dispatch, "staffHoliday",finalData );
            setLoading(false);
        }
    }


    return (
        <Grid container>
            {loading ? <Loader fullSize/> :<></>}
            <Grid item xs={12} sx={{mt: 4}}>
                <Typography variant={'h4'} sx={{mb: 1}}>
                    Staff holidays
                </Typography>
                <Typography variant={'body1'}>
                    Mark staff off days in calendar
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{mt: 2}}>
                <Grid container sx={{justifyContent: 'space-between', display: 'flex'}}>
                    <Grid item xs={12} lg={5} mr={2}>
                        <FormDateInput
                            name={"date"}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{display:"flex",justifyContent:'flex-end'}}>
                        <Button
                            sx={{
                                backgroundColor: '#09ABF1',
                                borderColor: '#09ABF1',
                                color: '#fff',
                                height: '44px',
                                "&:hover": {
                                    color: '#09ABF1',
                                    borderColor: '#09ABF1',
                                }
                            }}
                            type={'submit'}
                            variant={'outlined'}
                            onClick={() => handleSubmit()}
                        >
                            Save staff holiday
                        </Button>
                    </Grid>
                    {staffHolidays?.length > 0 && <Grid item xs={12} mt={2} lg={5}>
                        <Typography variant={'h5'}>
                            Holidays List
                        </Typography>
                        {staffHolidays?.length > 0 && staffHolidays.map((item, index) => (
                            <Box key={index} sx={{display: 'flex', mt: 2, justifyContent: 'space-between'}}>
                                <Typography>
                                    {item?.date}
                                </Typography>
                                <Tooltip title="Remove Holiday">
                                    <IconButton
                                        size="small"
                                        onClick={() => handleDeleteStaffHoliday(item)}
                                    >
                                        <MdDeleteOutline color={'#E53E31'} fontSize={'20px'}/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        ))}
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StaffHoliday;