import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  main:{
    textAlign:'center',
    marginTop:'25px'
  },

}));
export default useStyles;
