// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */




@media screen and (max-width: 480px) {
    /* .footer-wrappper img{
        width: 10px;
    } */
}`, "",{"version":3,"sources":["webpack://./src/pages/home/style.css"],"names":[],"mappings":"AAAA,eAAe;;;;;AAKf;IACI;;OAEG;AACP","sourcesContent":["/* styles.css */\n\n\n\n\n@media screen and (max-width: 480px) {\n    /* .footer-wrappper img{\n        width: 10px;\n    } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
