import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders} from "../../../../lib/apiHeader"; 


export const getAllClinicsTreatmentGroup = createAsyncThunk('getAllClinicsTreatmentGroup', async (provider_id,{dispatch,getState}) => {
    const { search, pageLimit, currentPage } = getState().clinicTreatmentGroupStore; 
    try {

        const response = await axios.get(apis.get_clinics_treatment_group+`${provider_id}?page=1&limit=100`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  [],
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

export const getTreatmentGroup = createAsyncThunk('getTreatmentGroup', async (provider_id,{dispatch,getState}) => {
    const { search, pageLimit, currentPage } = getState().clinicTreatmentGroupStore; 
    try {

        const response = await axios.get(apis.treatment_group+`${provider_id}&page=${currentPage}&limit=${pageLimit}`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  [],
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})


export const storeClinicsTreatmentGroup = createAsyncThunk('storeClinicsTreatmentGroup', async (data) => {
    try {
        const response = await axios.post(apis.store_clinics_treatment_group, {...data},{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const getClinicsTreatmentGroupByID = createAsyncThunk('getClinicsTreatmentByGroupID', async (id) => {
    try {
        const response = await axios.get(apis.get_clinics_treatment_group_by_id+id,{
            headers: apiHeaders()
        })
        console.log(response?.data)
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

export const updateClinicsTreatmentGroup = createAsyncThunk('updateClinicsTreatmentGroup', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_clinics_treatment_group, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success==='true'){
            await dispatch(getAllClinicsTreatmentGroup(data?.provider_id))

            return  response.data
        }else {
            return response?.data
        }
    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){
            return {
                data :err?.response,
                errors:true
            }
        }
    }
})
export const deleteClinicsTreatmentGroup = createAsyncThunk('deleteClinicsTreatmentGroup', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_clinics_treatment_group+data?.id,{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllClinicsTreatmentGroup(data?.provider_id))
            toast.success("Group / Treatment Deleted Successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})

export const updatetreatmentgroupFilter = createAsyncThunk('updatetreatmentgroupFilter', async (data) => {
    return data
  })

export const clinicsTreatmentGroupSlice = createSlice({
    name: 'clinicsTreatmentGroupSlice',
    initialState: {
        treatmentGroups: [],
        meta: {},
        links: {},
        pageLimit:10,
        currentPage:1,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllClinicsTreatmentGroup.fulfilled, (state, action) => {
            state.treatmentGroups = action.payload
            // state.meta = action.payload.meta
            // state.links = action.payload.links
            // state.currentPage = action.payload?.meta?.current_page;
        })
        builder.addCase(getTreatmentGroup.fulfilled, (state, action) => {
            state.treatmentGroups = action.payload
            state.meta = action.payload.meta
            state.links = action.payload.links
            state.currentPage = action.payload?.meta?.current_page;
        })

        // getTreatmentGroup
        builder.addCase(updatetreatmentgroupFilter.fulfilled, (state, action) => {
            const { search, pageLimit, currentPage } = action.payload; 
            if (pageLimit){
                state.pageLimit = pageLimit ;
            } 
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
           
        });
    }
})


export default clinicsTreatmentGroupSlice.reducer