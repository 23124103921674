// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.timeline {
    display: grid;
    grid-template-rows: 73px auto 73px;
    overflow-x: scroll;
}

.timeline-header {
    display: grid;
    grid-template-columns: 210px repeat(25, 1fr);
    grid-row: 1;
    grid-column: 1 / -1;
    text-align: center;
}

.timeline-body {
    display: grid;
    grid-template-columns: 210px repeat(25, 150px);
    grid-row: 2;
    grid-column: 1 / -1;
}

.time-slot {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.staff-name {
    grid-column: 1;
    padding: 10px;
    font-weight: bold;
}

/* Add borders to every 25th column in the timeline body */
.timeline-body > div {
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.item{
    width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/components/calender-table/styles.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,kCAAkC;IAClC,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,4CAA4C;IAC5C,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8CAA8C;IAC9C,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,iBAAiB;AACrB;;AAEA,0DAA0D;AAC1D;IACI,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB","sourcesContent":["\n.timeline {\n    display: grid;\n    grid-template-rows: 73px auto 73px;\n    overflow-x: scroll;\n}\n\n.timeline-header {\n    display: grid;\n    grid-template-columns: 210px repeat(25, 1fr);\n    grid-row: 1;\n    grid-column: 1 / -1;\n    text-align: center;\n}\n\n.timeline-body {\n    display: grid;\n    grid-template-columns: 210px repeat(25, 150px);\n    grid-row: 2;\n    grid-column: 1 / -1;\n}\n\n.time-slot {\n    border: 1px solid #ddd;\n    padding: 5px;\n    text-align: center;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n\n.staff-name {\n    grid-column: 1;\n    padding: 10px;\n    font-weight: bold;\n}\n\n/* Add borders to every 25th column in the timeline body */\n.timeline-body > div {\n    padding: 5px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n.item{\n    width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
