import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";

export const getAllAppointment = createAsyncThunk("getAllAppointment", async (params, { getState }) => {
    const { search, pageLimit, filterBy, currentPage } = getState()?.crmAppointmentStore;
    const { start_date, end_date } = params;
  console.log("params",params)
    try {
      const response = await axios.get(
        `${apis.get_all_appointment}${filterBy}&start_date=${start_date}&end_date=${end_date}&limit=${pageLimit}&page=${currentPage}${search !== '' ? `&search=${search}` : ''}`,
        {
          headers: apiHeaders(),
        }
      );
  
      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return {};
      }
    } catch (err) {
      return {}; 
    }
  });   

//   serach filter 
  
export const getAppointmentDetails = createAsyncThunk("getAppointmentDetails", async (id, { dispatch }) => {
        try {
            const response = await axios.get(apis.appointment_details+id, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }else {

                return [];
            } 
        } catch (err) {

            return  []
        }
    }
)


export const getAppointmentcalender = createAsyncThunk("getAppointmentcalender", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.appointent_calander+id, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {

            return [];
        } 
    } catch (err) {

        return  []
    }
}
)

export const getconsentform = createAsyncThunk("getconsentform", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.Cosent_form+id, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {

            return [];
        } 
    }
     catch (err) {

        return  []
    }
}
);



export const getAppointmentHistory = createAsyncThunk("getAppointmentHistory", async (data, { dispatch }) => {
        try {
            const response = await axios.post(apis.appointment_history,{...data}, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return {
                    type:data?.type,
                    data:response?.data?.data
                }
            }else {

                return {};
            }
        } catch (err) {

            return  {}
        }
    }
);




export const getAppointmentActivity = createAsyncThunk("getAppointmentActivity", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.activity_log+id, {
            headers: apiHeaders(),
        });
 console.log("response log",response?.data?.success, response?.data?.data)
        if (response) {
            return {
                data:response?.data?.data
                
            }
           
        }else {

           null
        }
    } catch (err) {

        return  {}
    }
}
);

export const changeAppointmentStatus = createAsyncThunk('changeAppointmentStatus', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_appointment_status, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.success === 'true'){
            await dispatch(getAllAppointment())
        }
        return  response?.data

    }catch (err){
      return   err?.response?.data
    }
})

export const updateCrmFilter = createAsyncThunk('updateFilter', async (data) => {
    return data
})



export const getAllRequestedAppointment = createAsyncThunk("getAllRequestedAppointment", async ( data) => {

    try {
        const response = await axios.get(apis.get_all_requested_appointment, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return {};
        }
    } catch (err) {
        return  {}
    }
});
export const approveRequestedAppointment = createAsyncThunk("approveRequestedAppointment", async ( data,{dispatch}) => {

    try {
        const response = await axios.post(apis.approve_appointment_status, {...data},{
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            await dispatch(getAllRequestedAppointment())
            return response?.data;
        }else {

            return {};
        }
    } catch (err) {
        return  {}
    }
});

export const appointmentSlice = createSlice({
    name: "appointmentSlice",
    initialState: {
        appointments: {},  
        appointmentDetails:[],
        consentform:[],
        invoices:[],
        appointmentsHistory:[],
        treatments:[],
        walletHistory:{},
        search:'',
        pageLimit:10,
        filterBy:'Upcoming',
        currentPage:1,
        requestedAppointment:[],
        appointmentDetail:[],
        Activity:[],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllAppointment.fulfilled, (state, action) => {     
            state.appointments = action.payload;
            state.currentPage = action.payload?.meta?.current_page;
        });
        builder.addCase(getAppointmentDetails.fulfilled, (state, action) => {
            state.appointmentDetails = action.payload ;
        });
    
       
        builder.addCase(getAppointmentActivity.fulfilled, (state, action) => {
            state.Activity = action.payload ;
        });
        builder.addCase(getAppointmentcalender.fulfilled, (state, action) => {
            state.appointmentDetail = action.payload ;
        });
       
        builder.addCase(getconsentform.fulfilled, (state, action) => {
            state.consentform = action.payload ;
        });

        builder.addCase(getAllRequestedAppointment.fulfilled, (state, action) => {
            state.requestedAppointment = action.payload ;
        });
        builder.addCase(getAppointmentHistory.fulfilled, (state, action) => {
            const {type,data} = action?.payload

            if (type ==='Invoices'){
                state.invoices = data?.invoices ;
            }
            if (type ==='Appointments'){
                state.appointmentsHistory = data?.appointments ;
            }
            if (type ==='Treatments'){
                state.treatments = data?.treatments ;
            }
            if (type ==='WalletHistory'){
                state.walletHistory = data?.wallet_history;
            }
        });
        builder.addCase(updateCrmFilter.fulfilled, (state, action) => {   
            const {filterBy,pageLimit,search,currentPage} = action?.payload

            if (filterBy){
                state.filterBy = filterBy ;
            }
            if (pageLimit){
                state.pageLimit = pageLimit ;
            }
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
        });

    },
});

export default appointmentSlice.reducer;
