import React from 'react';
import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
const SimpleInput = ({search, setSearch,handleChange}) => {
    return (
        <TextField
            name={"search"}
            sx={{
             border:"1px solid black",
                // background: "#EDF2F7",
                borderRadius:'4px',
                ".MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0!important"
                },
                ".MuiInputBase-input":{
                 
                    height: "11px",
                    minHeight: 'unset',

                    "&:focus":{  
                        "input":{
                            height:'11px'
                        }
                    },   
                    "&:hover":{
                        borderColor: "#EDF2F7",  
                        outline: "none",
                    }
                }
            }}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            placeholder={"Search"}
            value={search}
            onChange={(e)=> {
                if (handleChange){
                    handleChange(e.target.value)
                }else {
                    setSearch(e.target.value)
                }
            }}
        />
    );
};

export default SimpleInput;   