import React, {useRef, useState} from 'react';
import CustomButtoncolor from "../custom-button/customColorButton";
import {Box, Button, Typography} from "@mui/material";
import CustomModal from "../modal/customModal";
import moment from "moment/moment";
import {
    getCrmAppointmentHolidays,
    getCrmAppointmentSchedule,
    rescheduleAppointment
} from "../../store/crm/appointment/book-appointment";
import {
Link as MuiLink,
  
  } from "@mui/material";
import {useDispatch} from "react-redux";
import Loader from "../loader/loader";
import AppointmentTime from "../../pages/crm/appointment/add-appointment/appointmentTime";
import {MySwal, successConfirmation} from "../../services/utills";
import {mySwalButton} from "./CardAppointment";
import CustomButton from "../custom-button/customButton";
import {getAppointmentDetails} from "../../store/crm/appointment";
import Waveloading from '../../pages/loading/Waveloading';
export const datacolor = {
    color: "#2F80ED",
  
  };

const AppointmentRescheduleDate = ({appointment,isConfirm,isRequested,isMouseEnter,setIsMouseEnter,fetchAppointment}) => {
    const [rescheduleModal, setRescheduleModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedFinalDate,setSelectedFinalDate] = useState(null)
    const [selectedTime,setSelectedTime] = useState(null)


    const dispatch = useDispatch()

    const scrollRef = useRef(null); // Create the ref

    // Function to execute scrolling

   
    const executeScroll = () => {
        setTimeout(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }, 500); // Delay of 500ms
    
    }

    const getAppointmentHoliday = async (date, staff) => {
        setLoading(true)
        let finalDate = moment(date).format('YYYY-MM-DD')
        // setSelectedFinalDate(finalDate)
        if (finalDate) {
            let finalData = {
                date: finalDate
            }
            if (staff?.id) {
                finalData['staff_id'] = staff?.id
            }
            await dispatch(getCrmAppointmentHolidays(finalData))
        }

        setLoading(false)

    }
    const getAppointmentSchedule = async (date) => {

        setLoading(true)
        let serviceIds = []
        appointment?.services.forEach((item) => {
            serviceIds.push(item?.id)
        })


        let finalDate = moment(date).format('YYYY-MM-DD')

        setSelectedFinalDate(finalDate)
        setSelectedTime(null)
        let finalData = {
            date: finalDate,
            service_ids: serviceIds
        }

        await dispatch(getCrmAppointmentSchedule(finalData))

        setLoading(false)

    }

    const handleReschedule = async () => {
        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? You want to Reschedule this appointment .
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{...mySwalButton}}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes
                    </Button>
                    <Button
                        sx={{...mySwalButton}}
                        onClick={() => MySwal.close()}
                    >
                        No
                    </Button>
                </Box>
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            if (appointment?.date && appointment?.end_time && appointment?.start_time) {
                let givenDate = moment(appointment?.date);
                let today = moment(new Date());
/*
                if (givenDate.isSameOrAfter(today, 'day')) {
*/
                    setLoading(true)
                    await getAppointmentHoliday(appointment?.date, appointment?.staff)
                    await getAppointmentSchedule(appointment?.date)
                    setSelectedFinalDate(appointment?.date)
                    setLoading(false)
                    setRescheduleModal(true)
                /*} else {
                    await successConfirmation("Warning", 'Booking date is over Due.', 'warning')
                }*/
            }else {
                setRescheduleModal(true)
            }
        }
    }
    const handelRescheduleClick = async () => {
        let finalData = {
            booking_id:appointment?.id,
            date:selectedFinalDate,
            start_time:selectedTime?.start_time,
            end_time:selectedTime?.end_time,
        }
        setLoading(true)
        let {payload} = await dispatch(rescheduleAppointment(finalData))



        if (payload?.success === "true"){
            await dispatch(getAppointmentDetails(appointment?.id))
            if (fetchAppointment){
                await fetchAppointment()
            }
            setLoading(false)
            await successConfirmation("Success","Appointment Reschedule Successfully")
            setRescheduleModal(false)
        }else {
            setLoading(false)
        }
    }
    return (
        <Box>
            { appointment?.id && <CustomModal
                customWidth={"60%"}
                open={rescheduleModal}
                setOpen={setRescheduleModal}
            >
               <Box sx={{display:"flex",justifyContent:'space-between',mb:'10px'}}>
                   <Typography
                       sx={{textAlign:'center',fontSize:'20px',mt:"-6px",fontFamily:'helvetica-lt-std-bold'}}
                   >
                       Reschedule Appointment
                   </Typography>
                   { appointment?.date &&
                       <Typography
                           sx={{
                               textAlign: 'center',
                               fontSize: '20px',
                               mt: "-6px",
                               fontFamily: 'helvetica-lt-std-bold',
                           }}
                       >
                       Current Booking Date test : {moment(appointment?.date).format('dddd, Do MMMM, YYYY')}
                   </Typography>
                   }

               </Box>
                <AppointmentTime
                    selectedFinalDate={selectedFinalDate}
                    setSelectedFinalDate={setSelectedFinalDate}
                    selectedTime={selectedTime}
                    selectedStaff={appointment?.staff || null}
                    setSelectedTime={setSelectedTime}
                    selectedServices={appointment?.services}
                    getAppointmentHoliday={getAppointmentHoliday}
                    getAppointmentSchedule={getAppointmentSchedule}
                    executeScroll={executeScroll}
                />
                {
                    selectedTime?.start_time &&<Box sx={{display:'flex',justifyContent:"center",mt:'24px'}}>
                    <CustomButton handleClick={handelRescheduleClick} val={'Reschedule Appointment'} />
                </Box>
                }
            </CustomModal>}
            {loading ? <Waveloading fullSize/>:''}
            {isRequested ?

<div
style={{
    display: "flex",
    flexDirection: "column",
    fontFamily: "helvetica-lt-std-bold",
    
}}>
<MuiLink href="#" sx={{
    ...datacolor,
    fontSize: '20px',
    color: '#CB3838',
    textDecorationColor: '#CB3838'
}} onClick={() => isMouseEnter && appointment?.id  && handleReschedule()}>
    Set Date and Time
</MuiLink>
</div>
                // <Button
                //     variant="outlined"
                //     color="info"
                //     onMouseEnter={() => {
                //         setIsMouseEnter(true)
                //     }}
                //     onMouseLeave={() => {
                //         setIsMouseEnter(false)
                //     }}
                  
                //     sx={{
                //         height:'100%'
                //     }}
                // >
                //     Reschedule
                // </Button>
                : isConfirm ?
                <Box sx={{ mt:4, }}>
                    <Typography
                        variant="h6"
                        color="initial"
                        sx={{
                            color:'#1565c0',
                            marginTop:'5px',
                            fontFamily: "helvetica-lt-std-bold",
                            cursor:'pointer',
                        }}
                        onClick={() =>appointment?.id  && handleReschedule()}
                    >
                        Reschedule appointment
                    </Typography>
                </Box>
                :
            <CustomButtoncolor
                mainColor={"#333333"}
                handleClick={() =>appointment?.id  && handleReschedule()}
                style={{borderRadius: "4px", padding: '10px 15px'}}
                val={"Reschedule"}
            />
            }

        </Box>
    );
};

export default AppointmentRescheduleDate;