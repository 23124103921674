import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({

    search: {
        '& .MuiOutlinedInput-root': {
          borderRadius:'20px',
          height:'35px',
        },
        '& .MuiInputLabel-root.Mui-focused': {  // Corrected this line
          marginTop: '0px'
        }
      },
      selectParent: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '20px',
          height: '35px',
        },
        '& .MuiInputBase-input': {
          marginTop: '-12px',
          padding: '3px 4px 7px 15px'
        },
        '& .MuiInputLabel-root.Mui-focused': {  // Corrected this line
          marginTop: '0px'
        }
      },
     
      
        mainDiv: {
          padding: '0 8px',  // Adjust as per your requirement. This gives 10px to the left and right.
          // ... any other styles for the div if you have them
        },
      welcomeText: {
          background:'rgba(230, 218, 209, 1)',
          padding:'15px',
          borderRadius:'8px',
          marginTop:'15px'
      },


      
      
      // selectParent: {
      //   height:'25px',
      //   borderRadius:'15px'
      // }


}));
export default useStyles;
