import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../../lib/apis/apis";
import {apiHeaders} from "../../../../lib/apiHeader";

export const getAppointmentReminder = createAsyncThunk("getAppointmentReminder", async (id, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_all_reminders+id, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }
            if (response?.data?.success === false) {

                return [];
            }
        } catch (err) {

            return  []
        }
    }
);
export const storeAppointmentReminder = createAsyncThunk('storeAppointmentReminder', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_reminder, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.success === 'true'){
            await dispatch(getAppointmentReminder(data?.booking_id))
        }
        return  response?.data

    }catch (err){
        return err?.response?.data?.errors

    }
})



export const appointmentReminderSlice = createSlice({
    name: "appointmentReminderSlice",
    initialState: {
        reminders: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAppointmentReminder.fulfilled, (state, action) => {
            state.reminders = action.payload ;
        });

    },
});

export default appointmentReminderSlice.reducer;
