import * as yup from "yup";

export const countryList =[
    {
        code: "CA",
        countryName: "Canada",
        phoneCode: "+1",
        placeholder:'XXX-XXX-XXXX',
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
    },
    {
        code: "AL",
        countryName: "Albania",
        phoneCode: "+355",
        placeholder:'XX XXX XXXX',
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
    },
    {
        code: "DZ",
        countryName: "Algeria",
                                                      placeholder:"",
        phoneCode: "+213",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
    },
    {
        code: "AO",
        countryName: "Angola",
        phoneCode: "+244",
                                                      placeholder:"",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
    },
    {
        code: "AI",
        countryName: "Anguilla",
        phoneCode: "+1-264",
                                                      placeholder:"",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
    },
    {
        code: "AG",
        countryName: "Antigua and Barbuda",
        phoneCode: "+1-268",
                                                      placeholder:"",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
    },
    {
        code: "AR",
        countryName: "Argentina",
        phoneCode: "+54",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "AM",
        countryName: "Armenia",
        phoneCode: "+374",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "AW",
        countryName: "Aruba",
        phoneCode: "+297",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "AU",
        countryName: "Australia",
        phoneCode: "+61",

        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "AT",
        countryName: "Austria",
        phoneCode: "+43",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10,13}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "AZ",
        countryName: "Azerbaijan",
        phoneCode: "+994",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BS",
        countryName: "Bahamas",
        phoneCode: "+1-242",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BH",
        countryName: "Bahrain",
        phoneCode: "+973",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BD",
        countryName: "Bangladesh",
        phoneCode: "+880",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BB",
        countryName: "Barbados",
        phoneCode: "+1 246",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BY",
        countryName: "Belarus",
        phoneCode: "+375",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BE",
        countryName: "Belgium",
        phoneCode: "+32",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BZ",
        countryName: "Belize",
        phoneCode: "+501",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BJ",
        countryName: "Benin",
        phoneCode: "+229",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BM",
        countryName: "Bermuda",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BT",
        countryName: "Bhutan",
        phoneCode: "+975",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BO",
        countryName: "Bolivia",
        phoneCode: "+591",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BA",
        countryName: "Bosnia and Herzegovina",
        phoneCode: "+387",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BW",
        countryName: "Botswana",
        phoneCode: "+267",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BR",
        countryName: "Brazil",
        phoneCode: "+55",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "VG",
        countryName: "British Virgin Islands",
        phoneCode: "+1-284",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BN",
        countryName: "Brunei",
        phoneCode: "+673",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BG",
        countryName: "Bulgaria",
        phoneCode: "+359",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "BF",
        countryName: "Burkina Faso",
        phoneCode: "+226",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "KH",
        countryName: "Cambodia",
        phoneCode: "+855",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CM",
        countryName: "Cameroon",
        phoneCode: "+237",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CV",
        countryName: "Cape Verde",
        phoneCode: "+238",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "KY",
        countryName: "Cayman Islands",
        phoneCode: "+1-345",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TD",
        countryName: "Chad",
        phoneCode: "+235",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{6,7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CL",
        countryName: "Chile",
        phoneCode: "+56",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CN",
        countryName: "China",
        phoneCode: "+86",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CO",
        countryName: "Colombia",
        phoneCode: "+57",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CR",
        countryName: "Costa Rica",
        phoneCode: "+506",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CI",
        countryName: "Côte d’Ivoire",
        phoneCode: "+225",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "HR",
        countryName: "Croatia",
        phoneCode: "+385",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[0-9]{6,}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CY",
        countryName: "Cyprus",
        phoneCode: "+357",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CZ",
        countryName: "Czech Republic",
        phoneCode: "+420",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "DK",
        countryName: "Denmark",
        phoneCode: "+45",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[1-9]\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "DM",
        countryName: "Dominica",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "DO",
        countryName: "Dominican Republic",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "EC",
        countryName: "Ecuador",
        phoneCode: "+593",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "EG",
        countryName: "Egypt",
        phoneCode: "+20",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "SV",
        countryName: "El Salvador",
        phoneCode: "+503",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "EE",
        countryName: "Estonia",
        phoneCode: "+372",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "ET",
        countryName: "Ethiopia",
        phoneCode: "+251",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "FJ",
        countryName: "Fiji",
        phoneCode: "+679",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "FI",
        countryName: "Finland",
        phoneCode: "+358",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{5,12}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "FR",
        countryName: "France",
        phoneCode: "+33",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GA",
        countryName: "Gabon",
        phoneCode: "+241",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{6,7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GM",
        countryName: "Gambia",
        phoneCode: "+220",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GE",
        countryName: "Georgia",
        phoneCode: "+995",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "DE",
        countryName: "Germany",
        phoneCode: "+49",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GH",
        countryName: "Ghana",
        phoneCode: "+233",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GI",
        countryName: "Gibraltar",
        phoneCode: "+350",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{5,7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GR",
        countryName: "Greece",
        phoneCode: "+30",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GD",
        countryName: "Grenada",
        phoneCode: "+1-473",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GU",
        countryName: "Guam (USA)",
        phoneCode: "+1-671",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GT",
        countryName: "Guatemala",
        phoneCode: "+502",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GW",
        countryName: "Guinea-Bissau",
        phoneCode: "+245",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "GY",
        countryName: "Guyana",
        phoneCode: "+592",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        })
    },
    {
        code: "HT",
        countryName: "Haiti",
        phoneCode: "+509",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[2-4]\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "HN",
        countryName: "Honduras",
        phoneCode: "+504",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[1-9]\d{6,7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "HK",
        countryName: "Hong Kong",
        phoneCode: "+852",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^([5689])\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "HU",
        countryName: "Hungary",
        phoneCode: "+36",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[237]\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "IS",
        countryName: "Iceland",
        phoneCode: "+354",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^([37-8])\d{5,6}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "IN",
        countryName: "India",
        phoneCode: "+91",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {    code: "ID",    countryName: "Indonesia",    phoneCode: "+62",    validationSchema: yup.object({      phoneNumber: yup.string().matches(/^[0-9]*$/, "Phone number is not valid")
                .min(8, "Phone number is too short")
                .max(15, "Phone number is too long")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "IR",
        countryName: "Iran",
        phoneCode: "+98",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[0-9]*$/, "Phone number is not valid")
                .min(7, "Phone number is too short")
                .max(11, "Phone number is too long")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "IE",
        countryName: "Ireland",
        phoneCode: "+353",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,13}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "IL",
        countryName: "Israel",
        phoneCode: "+972",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "IT",
        countryName: "Italy",
        phoneCode: "+39",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{6,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "JM",
        countryName: "Jamaica",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "JP",
        countryName: "Japan",
        phoneCode: "+81",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "JO",
        countryName: "Jordan",
        phoneCode: "+962",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "KZ",
        countryName: "Kazakhstan",
        phoneCode: "+7",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "KE",
        countryName: "Kenya",
        phoneCode: "+254",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },

    {
        code: "KW",
        countryName: "Kuwait",
        phoneCode: "+965",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "KG",
        countryName: "Kyrgyzstan",
        phoneCode: "+996",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LA",
        countryName: "Laos",
        phoneCode: "+856",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LV",
        countryName: "Latvia",
        phoneCode: "+371",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LB",
        countryName: "Lebanon",
        phoneCode: "+961",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LS",
        countryName: "Lesotho",
        phoneCode: "+266",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LR",
        countryName: "Liberia",
        phoneCode: "+231",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LI",
        countryName: "Liechtenstein",
        phoneCode: "+423",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LT",
        countryName: "Lithuania",
        phoneCode: "+370",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LU",
        countryName: "Luxembourg",
        phoneCode: "+352",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MO",
        countryName: "Macau",
        phoneCode: "+853",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MK",
        countryName: "Macedonia",
        phoneCode: "+389",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MG",
        countryName: "Madagascar",
        phoneCode: "+261",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[1-9]\d{7,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MW",
        countryName: "Malawi",
        phoneCode: "+265",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MY",
        countryName: "Malaysia",
        phoneCode: "+60",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "ML",
        countryName: "Mali",
        phoneCode: "+223",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MT",
        countryName: "Malta",
        phoneCode: "+356",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,12}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MR",
        countryName: "Mauritania",
        phoneCode: "+222",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
        placeholder: "",
    },
    {
        code: "MU",
        countryName: "Mauritius",
        phoneCode: "+230", validationSchema: yup.object({
            phoneNumber: yup.string().matches(/^[0-9]{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
        placeholder: "",
    },
    {
        code: "MX",
        countryName: "Mexico",
        phoneCode: "+52",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
        placeholder: "",
    },
    {
        code: "FM",
        countryName: "Micronesia",
        phoneCode: "+691",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MD",
        countryName: "Moldova (Republic of)",
        phoneCode: "+373",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[0-9]{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MN",
        countryName: "Mongolia",
        phoneCode: "+976",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[1-9][0-9]{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MS",
        countryName: "Montserrat",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MA",
        countryName: "Morocco",
        phoneCode: "+212",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MZ",
        countryName: "Mozambique",
        phoneCode: "+258",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MM",
        countryName: "Myanmar",
        phoneCode: "+95",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "NA",
        countryName: "Namibia",
        phoneCode: "+264",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "NP",
        countryName: "Nepal",
        phoneCode: "+977",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "NL",
        countryName: "Netherlands",
        phoneCode: "+31",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "NZ",
        countryName: "New Zealand",
        phoneCode: "+64",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "NI",
        countryName: "Nicaragua",
        phoneCode: "+505",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "NE",
        countryName: "Niger",
        phoneCode: "+227",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "NG",
        countryName: "Nigeria",
        phoneCode: "+234",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "MP",
        countryName: "Northern Mariana Islands (USA)",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "NO",
        countryName: "Norway",
        phoneCode: "+47",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "OM",
        countryName: "Oman",
        phoneCode: "+968",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PK",
        countryName: "Pakistan",
        phoneCode: "+92",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PW",
        countryName: "Palau",
        phoneCode: "+680",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PA",
        countryName: "Panama",
        phoneCode: "+507",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PG",
        countryName: "Papua New Guinea",
        phoneCode: "+675",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PY",
        countryName: "Paraguay",
        phoneCode: "+595",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[5679]\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PE",
        countryName: "Peru",
        phoneCode: "+51",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[69]\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PH",
        countryName: "Philippines",
        phoneCode: "+63",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PL",
        countryName: "Poland",
        phoneCode: "+48",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[1-9]\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PT",
        countryName: "Portugal",
        phoneCode: "+351",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[289]\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "PR",
        countryName: "Puerto Rico (USA)",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[2-9]\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "QA",
        countryName: "Qatar",
        phoneCode: "+974",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CG",
        countryName: "Republic Of Congo",
        phoneCode: "+242",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "RO",
        countryName: "Romania",
        phoneCode: "+40",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "RU",
        countryName: "Russia",
        phoneCode: "+7",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "RW",
        countryName: "Rwanda",
        phoneCode: "+250",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "KN",
        countryName: "Saint Kitts & Nevis Anguilla",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LC",
        countryName: "Saint Lucia",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "VC",
        countryName: "Saint Vincent & Grenadines",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "ST",
        countryName: "São Tomé and Príncipe",
        phoneCode: "+239",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "SA",
        countryName: "Saudi Arabia",
        phoneCode: "+966",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^5\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "SN",
        countryName: "Senegal",
        phoneCode: "+221",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^7[0567]\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "RS",
        countryName: "Serbia",
        phoneCode: "+381",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^(6|(\+3816))[0-9]{6,7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "SC",
        countryName: "Seychelles",
        phoneCode: "+248",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^(2|5|7)\d{6}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "SL",
        countryName: "Sierra Leone",
        phoneCode: "+232",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^(25|30|33|44|55|76)\d{6}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "SG",
        countryName: "Singapore",
        phoneCode: "+65",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[689]\d{7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code:"SK",
        countryName:"Slovakia",
        phoneCode:"+421",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code:"SI",
        countryName:"Slovenia",
        phoneCode:"+386",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code:"SB",
        countryName:"Solomon Islands",
        phoneCode:"+677",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{5,7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code:"ZA",
        countryName:"South Africa",
        phoneCode:"+27",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code:"KR",
        countryName:"South Korea",
        phoneCode:"+82",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code:"ES",
        countryName:"Spain",
        phoneCode:"+34",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "LK",
        countryName: "Sri Lanka",
        phoneCode: "+94",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "SR",
        countryName: "Suriname",
        phoneCode: "+597",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[2-9]\d{5,7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "SZ",
        countryName: "Swaziland",
        phoneCode: "+268",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^[23456789]\d{6,7}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "SE",
        countryName: "Sweden",
        phoneCode: "+46",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^(\d-?\d{2}-?\d{3}|\d{2}-?\d{3}|\d{3}-?\d{2}|\d{4}-?\d{1})$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "CH",
        countryName: "Switzerland",
        phoneCode: "+41",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TW",
        countryName: "Taiwan",
        phoneCode: "+886",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TJ",
        countryName: "Tajikistan",
        phoneCode: "+992",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TZ",
        countryName: "Tanzania",
        phoneCode: "+255",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10,12}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TH",
        countryName: "Thailand",
        phoneCode: "+66",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TG",
        countryName: "Togo",
        phoneCode: "+228",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TT",
        countryName: "Trinidad and Tobago",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TN",
        countryName: "Tunisia",
        phoneCode: "+216",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TR",
        countryName: "Turkey",
        phoneCode: "+90",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TM",
        countryName: "Turkmenistan",
        phoneCode: "+993",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{8}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "TC",
        countryName: "Turks and Caicos Islands",
        phoneCode: "+1",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },
    {
        code: "UG",
        countryName: "Uganda",
        phoneCode: "+256",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9,10}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
                                              placeholder:"",
    },

    {
            code: "UA",
            countryName: "Ukraine",
            phoneCode: "+380",
            validationSchema: yup.object({
                phoneNumber: yup
                    .string()
                    .matches(/^\d{9}$/, "Phone number is not valid")
                    .required("Phone number is required"),
            }),
                                              placeholder:"",
        },
        {
            code: "AE",
            countryName: "United Arab Emirates",
            phoneCode: "+971",
            validationSchema: yup.object({
                phoneNumber: yup
                    .string()
                    .matches(/^\d{9}$/, "Phone number is not valid")
                    .required("Phone number is required"),
            }),
                                              placeholder:"",
        },
        {
            code: "GB",
            countryName: "United Kingdom",
            phoneCode: "+44",
            validationSchema: yup.object({
                phoneNumber: yup
                    .string()
                    .matches(/^\d{10,11}$/, "Phone number is not valid")
                    .required("Phone number is required"),
            }),
                                              placeholder:"",
        },
        {
            code: "US",
            countryName: "United States",
            phoneCode: "+1",
            validationSchema: yup.object({
                phoneNumber: yup
                    .string()
                    .matches(/^\d{10}$/, "Phone number is not valid")
                    .required("Phone number is required"),
            }),
                                              placeholder:"",
        },
        {
            code: "UY",
            countryName: "Uruguay",
            phoneCode: "+598",
            validationSchema: yup.object({
                phoneNumber: yup
                    .string()
                    .matches(/^\d{8}$/, "Phone number is not valid")
                    .required("Phone number is required"),
            }),
                                              placeholder:"",
        },
        {
            code: "VI",
            countryName: "US Virgin Islands (USA)",
            phoneCode: "+1",
            validationSchema: yup.object({
                phoneNumber: yup
                    .string()
                    .matches(/^\d{10}$/, "Phone number is not valid")
                    .required("Phone number is required"),
            }),
                                              placeholder:"",
        },
        {
            code: "UZ",
            countryName: "Uzbekistan",
            phoneCode: "+998",
            validationSchema: yup.object({
                phoneNumber: yup
                    .string()
                    .matches(/^\d{9}$/, "Phone number is not valid")
                    .required("Phone number is required"),
            }),
                                              placeholder:"",
        },
    {
        code: "VE",
        countryName: "Venezuela",
        phoneCode: "+58",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
        placeholder: "",
    },
    {
        code: "VN",
        countryName: "Vietnam",
        phoneCode: "+84",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
        placeholder: "",
    },
    {
        code: "YE",
        countryName: "Yemen",
        phoneCode: "+967",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{7,9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
        placeholder: "",
    },
    {
        code: "ZM",
        countryName: "Zambia",
        phoneCode: "+260",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{9}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
        placeholder: "",
    },
    {
        code: "ZW",
        countryName: "Zimbabwe",
        phoneCode: "+263",
        validationSchema: yup.object({
            phoneNumber: yup
                .string()
                .matches(/^\d{10,11}$/, "Phone number is not valid")
                .required("Phone number is required"),
        }),
        placeholder: "",
    },
]
