import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Grid, IconButton,
     Tooltip, Typography
} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";
import FormDateInput from "../../../../components/form/FormDateInput";
import * as yup from "yup";
import {useFormik} from "formik";
import * as Yup from "yup";
import  {
    getAllClinicsBusinessHours,
    getAllClinicsHolidays, storeClinicHolidays
} from "../../../../store/clinics/clinicSettings/business-hours";
import {useParams} from "react-router-dom";
import {MdDeleteOutline} from "react-icons/md";
import {confirmation, handleDelete} from "../../../../services/utills";
import Loader from "../../../../components/loader/loader";
import Hours from "./hours";

const BusinessHours = () => {
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const params = useParams()

    const clinicsBusinessHoursStore = useSelector(state => state.clinicsBusinessHoursStore)

    const {clinicHolidays} = clinicsBusinessHoursStore


    const validationSchema = yup.object({
        date: Yup.date().typeError("holiday date is required").required("holiday date is required")
    });

    const {values, handleSubmit, resetForm, handleChange, touched, errors} = useFormik({
        initialValues: {
            date: '',
        },
        validationSchema: validationSchema,   
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {      
            setLoading(true)

            let res = await dispatch(storeClinicHolidays({...data, provider_id: params?.clinic_id}))
            if (res?.payload?.errors) {           
                const {errors} = res?.payload      

                if (errors?.date) {
                    setFieldTouched("date", true)
                    setFieldError("date", errors?.date)
                }

            } else if (res?.payload?.success === "true") {  
                resetForm()
            }
  
            setLoading(false)
        },  
    });  

    useEffect(() => {
        (async () => {
            setLoading(true)
            await dispatch(getAllClinicsBusinessHours(params?.clinic_id))
            await dispatch(getAllClinicsHolidays(params?.clinic_id))
            setLoading(false)   
        })()
    }, [params?.client_id])
      

    const handleDeleteClick = async (row) => {
        if (await confirmation()) {
            setLoading(true);
            let finalData = {
                id: row?.id,
                provider_id: params?.clinic_id,
            }
            await handleDelete(dispatch, "holiday", finalData);

            setLoading(false);
        }
    }


    return (
        <Box
            sx={{height: 'calc(100vh  - 200px)'}}
        >
            {loading ? <Loader fullSize/> : ""}
            <Grid container p={"20px"}>
                <Grid item xs={12} sx={{mb: 2}}>
                    {/*<Typography variant={'h4'} sx={{mb: 1}}>Business hours</Typography>*/}
                    <Typography variant={'body1'} sx={{fontSize: '22px'}}>
                        Set up weekly business hours
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Hours/>
                </Grid>
                <Grid item xs={12} sx={{mt: 4}}>
                    <Typography variant={'h4'} sx={{mb: 1}}>
                        Business holidays
                    </Typography>
                    <Typography variant={'body1'}>
                        Mark business off days in calendar
                    </Typography>   
                </Grid>
                <Grid item xs={12} sx={{mt: 3}}>
                    <Grid container sx={{justifyContent: 'space-between', display: 'flex'}}>
                        <Grid item xs={12} lg={5} mr={2}>
                            <FormDateInput
                                name={"date"}   
                                // label={"Date Of Birth"}
                                errors={errors}
                                touched={touched}    
                                values={values}
                                handleChange={handleChange}
                            />   
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Button
                                sx={{
                                    backgroundColor: '#09ABF1',
                                    borderColor: '#09ABF1',
                                    color: '#fff',
                                    height: '44px',
                                    "&:hover": {
                                        color: '#09ABF1',
                                        borderColor: '#09ABF1',
                                    }
                                }}
                                type={'submit'}
                                variant={'outlined'}
                                onClick={() => handleSubmit()}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={12} mt={4} lg={6}>
                            <Typography variant={'h5'}>
                                Holidays List
                            </Typography>
                            {clinicHolidays?.length > 0 && clinicHolidays.map((item, index) => (
                                <Box key={index} sx={{display: 'flex', mt: 2, justifyContent: 'space-between'}}>
                                    <Typography>
                                        {item?.date}
                                    </Typography>
                                    <Tooltip title="Remove Holiday">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleDeleteClick(item)}
                                        >
                                            <MdDeleteOutline color={'#E53E31'} fontSize={'20px'}/>   
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default BusinessHours;