import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useStyles from './style';
import { scrollCSS } from '../../App';
import DateRange from '../date-range/date-range';
import MonthYearFilter from './MonthViewFilter';

const MonthlyHours = (props,onDatesChange) => {
  const currentMonthName = new Date().toLocaleString('default', { month: 'long' });
  const currentYear = new Date().getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonthName);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  

  
  const {
    saleshour,
    salesData,
    fetchData,

   } = props;


   function getMonthNumber(monthName) {
    const months = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    }; 
  
    // Return the numeric value for the given monthName
    return months[monthName] || null;
  }
  
  // Usage example:
  // const monthName = "January";
  // const monthNumber = getMonthNumber(monthName);


   const handleButtonClick = async () => {
    const selectedMonthNumber = getMonthNumber(selectedMonth);
    const response = await props.fetchData(selectedYear,selectedMonthNumber, );
    // Process response or perform further actions
  };  
  const handleMonthYearChange = (year, month) => {
    const monthNumber = getMonthNumber(month); // Assuming getMonthNumber is defined somewhere in this component
    fetchData(year, monthNumber); // Assuming fetchData is correctly implemented to call your API
  };
  // useEffect(() => {
  //   // Call fetchData when either selectedMonth or selectedYear changes
  //   handleButtonClick()
  // }, [selectedMonth, selectedYear]);



  
    const color =  
    saleshour === 'Monthly SALES/HOURS'
     ? 'black' // Black
     : '#000'; // Default color



     const background =
     saleshour === 'Monthly SALES/HOURS'
       ? '#FFF7F8' 
       : '#FFF7F8';


       const classes = useStyles(props);

       function createData(name, customerName, email) {
         return { name, customerName, email };
       }


       const rows = [
         createData(salesData?.total_appointment, salesData?.forecasted_sale,salesData?.time),
       ];

       const completedRows = [
        createData(salesData?.total_completed, salesData?.completed_sale, salesData?.completed_time, ),
      ];
      const cancelRows = [
        createData(salesData?.total_cancel, salesData?.cancel_sale, salesData?.cancel_time, ),
      ];


  return (
    <Box sx={{ padding: "40px 10px" }}>


    <Box sx={{ position:'relative', padding:'20px 10px',  borderRadius: "8px", overflow:'hidden', background: background , height:"700px" }}>
      {/* Table */}
      <Box sx={{ height:{sm:'155px', xs:'130px'}}}>
      <Typography variant="h5" sx={{ fontFamily:'helvetica-lt-std-bold', color:color, padding:'20px 20px' }}>
           {saleshour}

        </Typography>
        <Box sx={{marginLeft:"9px" , display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:{lg:'center', xs:'start'}, '@media (min-width:1600px)': {flexDirection:'row'} }}>

        <MonthYearFilter
        initialYear={selectedYear}
        initialMonth={selectedMonth}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
        onMonthYearChange={handleMonthYearChange}
      />
           {/* <Button style={{marginTop:"9px"}} variant="contained" onClick={handleButtonClick}>
          Fetch Report
        </Button> */}
        </Box>
        </Box>
      <TableContainer
        component={Paper}
        sx={{
          mt: 5,
          boxShadow: "none",
          maxHeight: "650px",
          overflowY: "auto",
          ...scrollCSS,
        }}
      >
        <Table sx={{minWidth:'350px'}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                  width:"35%"
                  
                }}
              >
                Total Appointments
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0,  width:"35%"  }}
              >
                Forecasted Sales
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0,   width:"35%"}}
              >
                Time
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                $  {row.customerName}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        
        <Table sx={{minWidth:'350px'}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                  width:'35%'
                }}
              >
                Total Completed
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0, width:'35%' ,}}
              >
                Sales
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0,width:'30%' }}
              >
                Time
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>          
            {completedRows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                 $ {row.customerName}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Table sx={{minWidth:'350px'}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                  width:'35%'
                }}
              >
               Total Cancel
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0, width:'35%'}}
              >
                Cancel Sales 
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0,width:'30%' }}
              >
                Time
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {cancelRows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                 $ {row.customerName}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

          
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count=""
        rowsPerPage=""
        page=""
        onPageChange=""
        onRowsPerPageChange=""
      /> */}


    </Box>
</Box>
  )
}

export default MonthlyHours
