import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import CustomButtoncolor from "../../../components/custom-button/customColorButton";
import CustomButton from "../../../components/custom-button/customButton";
import { useNavigate } from "react-router";
import Vector from "../../../assets/images/Vector.png";
import { useDispatch, useSelector } from "react-redux";
import {getAllPOSBooking, resendInvoice, updatePOSFilters} from "../../../store/crm/pos";
import Loader from "../../../components/loader/loader";
import FilterInput from "../../../components/form/filterInput";
import {
  btnwrap,
  contentwrap,
  contentwrape,
  content,
  heading,
  wrapper,
  headerwraper,
  outercontainer,
  subheading,
  vectorWrapper,
  btnwrapper,
  maincontainer,
} from "./posStyles";
import Remindermodal from "../../../components/modal/Reminder";
import moment from "moment";
import BackendPagination from "../../../components/pagination/backendPagination";
import BackendSearchField from "../../../components/pagination/backendSearch";
import {MySwal, successConfirmation} from "../../../services/utills";
import {
  mySwalButton,
  ribbonwrapper,
} from "../../../components/card-appointment/CardAppointment";
import HttpsIcon from "@mui/icons-material/Https";
import { useLocation } from "react-router-dom";
import ribbon from "../../../assets/images/ribbon.svg";
import redRibbon from "../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../assets/images/yellow-badg.svg";
import { scrollCSS } from "../../../App";
import { Password } from "@mui/icons-material";
import PasswordEditModal from "../../../components/modal/Password";
import VoidInvoice from "../../../components/modal/VoidInvoice";
import Waveloading from "../../loading/Waveloading";
import {deleteCrmAttachment} from "../../../store/crm/crm/crm-details";

const posFilters = [
  { name: "All", value: "All" },
  { name: "Newest/Latest", value: "Latest" },
  { name: "Oldest", value: "Oldest" },
  { name: "Paid", value: "Paid" },
  { name: "Pending", value: "Pending" },
  { name: "Un Paid", value: "Un Paid" },
  { name: "Void", value: "Void" },
];

const Pos = () => {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let today = moment(new Date());

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const crmPOSStore = useSelector((state) => state?.crmPOSStore);
  const searchPerformedRef = useRef(false);
  const {
    allPOSBookings = {},
    filterBy, 
    pageLimit,     
    currentPage,    
    search,
  } = crmPOSStore;    
  const location = useLocation();
  const locationStateValue = location.state?.value;
  useEffect(() => {
    (async () => {
      await dispatch(updatePOSFilters({ filterBy: "Paid" }));
      handleClose(); 
      await fetchPOSBookings();
    })(); 
  }, []); 
 
  const fetchPOSBookings = async () => {
    setLoading(true);
    await dispatch(getAllPOSBooking());  
    setLoading(false);   
  };     
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = async (value) => {
    await dispatch(updatePOSFilters({ filterBy: value, currentPage: 1 }));
    handleClose();
    await fetchPOSBookings(); 
  }
  const fetchPageData = async (page) => {
    await dispatch(updatePOSFilters({ currentPage: page }));
    await fetchPOSBookings();
  };    
  const handleLimitChange = async (val) => {   
    await dispatch(updatePOSFilters({ pageLimit: val, currentPage: 1 }));
    await fetchPOSBookings();
  };
  const handleSearch = async (val) => {
    await dispatch(updatePOSFilters({ search: val }));
  };

  useEffect(() => {
    console.log("Location state value:", locationStateValue);
    const performSearch = async () => {
      if (locationStateValue && locationStateValue !== search && !searchPerformedRef.current) {
        console.log("Triggering search for new value:", locationStateValue);      
        await dispatch(updatePOSFilters({ search: locationStateValue}));
        await fetchPOSBookings()   
        searchPerformedRef.current = true      
      }  
    };  
    performSearch();    
  }, [locationStateValue, search, dispatch]);  

  const ViewInvoices = (id) => {    
    navigate(`/crm/invoices/${id}`);
    // history.push(`/invoice/${id}`)
  };

  const handleEditInvoice = async (id) => {
    let confirm = await MySwal.fire({
      icon: "question",
      title: "Confirmation",    
      showConfirmButton: false,
      html: (
        <Box sx={{ mb: 4 }}>
          <Typography sx={{}}>
            Are you sure? You want to Edit Invoice of this appointment .
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Button
              sx={{ ...mySwalButton }}
              onClick={() => MySwal.clickConfirm()}
            >
              Yes
            </Button>
            <Button sx={{ ...mySwalButton }} onClick={() => MySwal.close()}>
              Cancel
            </Button>
          </Box>
        </Box>
      ),
    });
    if (!!confirm?.isConfirmed) {
      navigate(`/crm/appointment/invoice/edit/${id}`);
    }
  };
  const handleResendInvoice = async (id) => {
    let confirm = await MySwal.fire({
      icon: "question",
      title: "Resend Invoice",
      showConfirmButton: false,
      html: (
        <Box sx={{ mb: 4 }}>
          <Typography sx={{}}>
            Are you sure? You want to Resend Invoice of this appointment .
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Button
              sx={{ ...mySwalButton }}
              onClick={() => MySwal.clickConfirm()}
            >
              Yes
            </Button>
            <Button sx={{ ...mySwalButton }} onClick={() => MySwal.close()}>
              Cancel
            </Button>
          </Box>
        </Box>
      ),
    });
    if (!!confirm?.isConfirmed) {
      setLoading(true)
      let res = await dispatch(resendInvoice({booking_id:id}))
      if (res?.payload?.success === "true") {
        await successConfirmation('Invoice Resend Successfully')
      }
      setLoading(false)
    }
  };
  const handleLockedEditInvoice = async (booking) => {
    let confirm = await MySwal.fire({
      icon: "warning",
      title: "Edit Invoice",
      showConfirmButton: false,
      html: (
        <Box sx={{ mb: 4 }}>
          <Typography sx={{}}>
            Are you sure? You want to Edit Invoice of this appointment .
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Button
              sx={{ ...mySwalButton }}
              onClick={() => MySwal.clickConfirm()}
            >
              Yes
            </Button>
            <Button sx={{ ...mySwalButton }} onClick={() => MySwal.close()}>
              Cancel
            </Button>
          </Box>
        </Box>
      ),
    });
    if (!!confirm?.isConfirmed) {
      navigate(`/crm/appointment/invoice/edit/${booking?.booking_id}`);
    }
  };
        
  return (
    <Box>
      <Box sx={headerwraper}>   
        {loading ? <Waveloading fullSize /> : ""}

        <Box sx={{display: 'flex', alignItems: "baseline",mb:{sm:'0px',xs:'15px'}}}>
                    <FilterInput
                        limit={pageLimit}
                        setLimit={handleLimitChange} 
                    />
                     <Typography sx={{ marginLeft:"3px", fontSize: "14px", color: "#404040" }}>
            Showing {allPOSBookings?.meta?.from} to {allPOSBookings?.meta?.to}{" "}
            of {allPOSBookings?.meta?.total} entries 
            {/* (filtered from{" "}
            {allPOSBookings?.meta?.total} total entries) */}
          </Typography>
                </Box>  
        {/* <Box style={contentwrap} sx={{ my: { sm: 2, md: 0 } }}>    
          <FilterInput limit={pageLimit} setLimit={handleLimitChange} />
         
          <div></div>
        </Box> */}
        <Box>
          <Grid container>
            <Grid
              itnpmem
              xs={12}
              sm={3.3}
              style={btnwrap}
              sx={{ my: { xs: 1, sm: 0 } }}
            >
              <CustomButton
                val={"Add Invoice"}
                style={{ width: "100%" }}
                size={"large"}
                handleClick={() => {
                  localStorage.setItem("url", pathname);
                  navigate(`/crm/point-of-sale/new-invoice`);
                }}
              />
            </Grid>
            <Grid item xs={10} sm={7}>
              <FormControl
                sx={{
                  mr: { sm: "10px", xs: "10px" },
                  ml: { sm: "10px", xs: "10px" },
                  mb: { sm: "0px", xs: "15px" },
                  width: { xs: "90%", sm: "auto" },
                }}
              >
                <BackendSearchField
                  search={search}
                  handleSearch={handleSearch}
                  fetchData={async () => {
                    await dispatch(updatePOSFilters({ currentPage: 1 }));
                    await fetchPOSBookings();
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={1.3}>
              <Box variant="outlined" sx={vectorWrapper} onClick={handleClick}>
                <img src={Vector} alt="" />
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    width: "250px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <MenuItem
                  disabled
                  sx={{
                    opacity: "unset!important",
                    py: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#000",
                      textTransform: "uppercase",
                    }}
                  >
                    Sort Data:
                  </Typography>
                </MenuItem>
                {posFilters.map((item, index) => (
                  <MenuItem
                    key={index}
                    sx={{
                      py: "7px",
                      my: "3px",
                      background:
                        item?.value === filterBy ? "#09ABF1!important" : "#fff",
                      color: item?.value === filterBy ? "#fff" : "#000",
                      "&:hover": {
                        background: "#09ABF1",
                        color: "#fff",
                      },
                    }}
                    onClick={() => handleFilter(item?.value)}
                  >
                    {item?.name}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 173px)",
          overflowY: "auto",
          ...scrollCSS,
        }}
      >
        {allPOSBookings?.data?.length ? (
          allPOSBookings?.data?.map((POSBooking, index) => (
            <React.Fragment key={index}>
              <Box sx={maincontainer}>
                <Box sx={ribbonwrapper}>
                  {POSBooking?.customer?.customer_batch && (
                    POSBooking?.customer?.customer_batch !== "New User" ? (
                    <>
                      {POSBooking?.customer?.customer_batch.includes(
                        "Expire/Cancel"
                      ) ? (
                        <>
                          <img
                            style={{ marginTop: "4px" }}
                            src={redRibbon}
                            alt={"member"}
                          />
                          <Typography style={{ color: "#FF0000" }}>
                            {POSBooking?.customer?.customer_batch}
                          </Typography>
                        </>
                      ) : (
                        <>
                          {POSBooking?.customer?.customer_batch.includes(
                            "Declined"
                          ) ? (
                            <>
                              <img
                                style={{ marginTop: "4px" }}
                                src={yellowRibbon}
                                alt={"member"}
                              />
                              <Typography style={{ color: "#dcd90a"}}>
                                {POSBooking?.customer?.customer_batch}
                              </Typography>
                            </>  
                          ) : (
                            <>
                              <img
                                style={{ marginTop: "4px" }}
                                src={ribbon}
                                alt={"member"}
                              />
                              <Typography style={{ color: "#219653" }}>
                                {POSBooking?.customer?.customer_batch}
                              </Typography>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography style={{ color: "#09ABF1" }}>
                        {POSBooking?.customer?.customer_batch}
                      </Typography>
                    </>  
                  ))}
                </Box>
                <Box sx={outercontainer}>
                  <Box sx={contentwrape}>
                    <Box sx={{ mt: { xs: 3 } }}>
                      <Typography sx={subheading}>Customer Name:</Typography>
                      <Box style={{ marginTop: "4px" }}>
                        <div>
                          <Typography
                            sx={{
                              ...heading,
                              textTransform: "capitalize",
                            }}
                          >
                            {POSBooking?.customer?.name}
                          </Typography>
                        </div>

                        <div>
                          <Typography sx={content}>
                            Member Id: #{POSBooking?.customer?.id}
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ padding: "7px 20px" }}>
                    <Divider
                      orientation="vertical"
                      style={{
                        borderColor: "#9A9797",
                        heigth: "100%",
                        width: "2px",
                      }}
                    />
                  </Box>
                  <Box
                    item={index.toString()}
                    lg={8}
                    sx={{
                      ...wrapper,
                      justifyContent: "space-between",
                      alignItems: { xs: "baseline", md: "start" },
                      marginTop:{ xs: "0px", md: "20px" },
                    }}
                  >
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={subheading}>Booking ID:</Typography>
                        <Typography sx={content}>
                          {POSBooking?.booking_id}
                        </Typography>
                      </Box>
                      <Typography sx={content}> </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={subheading}>Booking Status:</Typography>
                        <Typography
                          sx={{
                            ...content,
                            background: POSBooking?.status_color,
                            p: "2px 12px",
                            borderRadius: "13px",
                            color: "#fff",
                            ml: 1,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {POSBooking?.status}
                        </Typography>
                      </Box>
                      {/*<div style={{marginTop: '15px'}}>*/}
                      {/*    <Typography sx={subheading}>{POSBooking?.invoices_methods?.length > 1 ? " Payment Methods" : " Payment Method"}:</Typography>*/}
                      {/*    <Typography sx={content}>*/}
                      {/*        {POSBooking?.invoices_methods?.length > 0 && POSBooking?.invoices_methods?.map((invoice, index) => (*/}
                      {/*            <Typography sx={{...content,display:'block'}} key={index} as={'span'}>*/}
                      {/*                {invoice?.payment_methods}*/}
                      {/*            </Typography>*/}
                      {/*        ))}*/}
                      {/*    </Typography>*/}
                      {/*</div>*/}
                    </Box>
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={content}></Typography>
                        {/*<Typography sx={subheading}>{POSBooking?.invoices_methods?.length > 1  ? "Transaction IDs":"Transaction ID"}</Typography>*/}
                        <Typography sx={subheading}>
                          {"Invoice  ID :"}
                        </Typography>
                        <Typography sx={content}>
                          {POSBooking?.invoices_methods?.length > 0 && (
                            <Typography sx={content} key={index} as={"span"}>
                              {POSBooking?.invoices_methods[0]?.id}
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "end" }}>
                        <Typography
                          sx={subheading}
                          style={{ marginTop: "15px" }}
                        >
                          {" "}
                          Total Amount:
                        </Typography>
                        <Typography sx={content}>
                          ${POSBooking?.grand_total}
                        </Typography>
                        {/*<Typography sx={content}>Wallet:{"29$"}</Typography>*/}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: { sm: "300px", md: "200px" },
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "start" }}>
                        <Typography sx={subheading}>Due Date: </Typography>
                        <Typography sx={content}>
                          {moment(POSBooking?.date).format(
                            "dddd, MMMM D, YYYY"
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 2 }}
                      >
                        <Typography
                          sx={subheading}
                          style={{ marginTop: "0px" }}
                        >
                          Invoice Status:
                        </Typography>
                        <Typography
                          style={{
                            background:
                              POSBooking?.payment_status === "Paid"
                                ? "#27AE60"
                                : "#EB5757",

                            borderRadius: "13px",
                            display: "flex",
                            justifyContent: "center",
                            color: "#fff",
                            padding: "1px 12px",
                            width: "fit-content",
                          }}
                        >
                          {POSBooking?.payment_status}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ padding: "7px 20px" }}>
                    <Divider
                      orientation="vertical"
                      style={{
                        borderColor: "#9A9797",
                        heigth: "100%",
                        width: "2px",
                      }}
                    />
                  </Box>

                  <Box sx={btnwrapper}>
                    <div style={{ marginBottom: "2%" }}>
                      {POSBooking?.payment_status === "Paid" &&
                      POSBooking?.status === "Completed" ? (
                        <PasswordEditModal id={POSBooking?.booking_id} />
                      ) : (
                        <Remindermodal
                          customer_id={POSBooking?.customer?.id}
                          booking_id={POSBooking?.booking_id}
                        />
                      )}
                    </div>

                    <div style={{ marginBottom: "2%" }}>
                      {
                        POSBooking?.payment_status === "Paid" &&
                        POSBooking?.status === "Completed" ? (
                          <VoidInvoice
                            id={POSBooking?.invoices_methods[0]?.id}
                          />
                        ) : (
                          <CustomButtoncolor
                            mainColor={"#333333"}
                            size="large"
                            val="Edit Invoice"
                            handleClick={() =>
                              handleEditInvoice(POSBooking?.booking_id)
                            }
                            style={{
                              width: "100%",
                              borderRadius: "6px!important",
                            }}
                          />
                        )
                        // :
                        // <Button
                        //     size="large"
                        //     startIcon={<HttpsIcon sx={{fontSize: '18px!important'}}/>}
                        //     sx={{
                        //         // borderRadius:"0px",
                        //         color: "#219653",
                        //         borderColor: "#219653",
                        //         textTransform: "capitalize",
                        //         width: "100%",
                        //         borderRadius: "6px!important",
                        //         fontSize: "14px",
                        //         "&:hover": {
                        //             backgroundColor: "#219653",
                        //             borderColor: "#219653",
                        //             color: "#fff",
                        //         },
                        //     }}
                        //     variant={"outlined"}
                        //     onClick={() => handleLockedEditInvoice(POSBooking)}
                        // >
                        //     {"Invoice"}
                        // </Button>
                      }
                    </div>
                    {POSBooking?.payment_status === "Paid" && POSBooking?.status === "Completed" && (
                        <div style={{ marginBottom: "2%" }}>
                          <CustomButtoncolor
                              handleClick={() => handleResendInvoice(POSBooking?.booking_id)}
                              mainColor="#333333"
                              size="large"
                              val="Resend Invoice"
                              style={{ width: "100%", borderRadius: "6px!important" }} // Note: Using !important is generally not recommended
                          />
                        </div>
                    )}
                    <div style={{ marginBottom: "2%" }}>
                      <CustomButtoncolor
                        handleClick={() => ViewInvoices(POSBooking?.booking_id)}
                        mainColor={"#333333"}
                        size="large"
                        val="View Invoice"
                        style={{ width: "100%", borderRadius: "6px!important" }}
                      />
                    </div>

                  </Box>
                </Box>
              </Box>
            </React.Fragment>
          ))
        ) : (
          <Box sx={{ width: "100%", mt: "30px" }}>
            <Typography sx={{ textAlign: "center" }}>Empty</Typography>
          </Box>
        )}

        {allPOSBookings?.meta?.last_page > 1 && (
          <BackendPagination
            count={allPOSBookings?.meta?.last_page}
            currentPage={currentPage}
            handleOnChange={fetchPageData}
          />
        )}
      </Box>
    </Box>
  );
};

export default Pos;
