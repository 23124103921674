import React, {useEffect, useState} from 'react';
import FormAutoComplete from "../../../../components/form/FormAutoComplete";
import * as yup from "yup";
import {useFormik} from "formik";
import {Box, FormControl, FormHelperText, FormLabel, Grid, TextField} from "@mui/material";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {generateYearsBetween} from "../../../../services/utills";
import FormInput, {inputStyle} from "../../../../components/form/formInput";
import {useDispatch, useSelector} from "react-redux";
import {storeClinicBusinessDetails} from "../../../../store/clinics/clinicSettings/businessDetails";
import Loader from "../../../../components/loader/loader";
import Image from "../../../../components/image/image";
import * as Yup from "yup";
import ImageLogo from '../../../../components/image/ImageLogo';
import RemovalAccountModal from "../../../../components/modal/AccountRemoval";


const registeredTypeOptions =[
    'Established Since','Licensed Since',
]
const businessTypeOptions =[
    'Business Founders',
    'Business Owners',
]
const BusinessDetails = () => {
    const [loading,setLoading] = useState(false)
    const dispatch =useDispatch()

    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)


    const validationSchema = yup.object({
        registered_type: yup.string().typeError("Registered type is required").required('Registered type is required'),
        registered_year: yup.string().typeError("Registered year is required").required('Registered year is required'),
        business_type: yup.string().typeError("Business type is required").required('Business  type is required'),
        company_name: yup.string().required('Company name is required'),
        business_owners_name: yup.string().required('Business Owner name is required'),
        business_tags: yup.string().required('Business tags is required'),
        // profile_image: Yup.mixed().required("Image is Required"),
        // story_about_business: yup.string().min(30,"Story About Business is Too Short").required('Story About Business is required'),
    });


    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {  
            business_owners_name: '',  
            business_tags: '',
            company_name: '',
            business_type: null,
            registered_year: null,
            registered_type: null,  
            profile_image:null,  
            treatment_form_logo:null ,
            service_prefix: '',
            time_zone: '',
            owner_phone: '',
            story_about_business: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {

            const formData = new FormData()
            Object.keys(data).forEach(function (key) {
               
                if (key === "profile_image" || key === "treatment_form_logo") {
                  
                    if (typeof data[key] === 'string') {
                         formData.append(key, '');
                    } else {
                       
                        formData.append(key, data[key]);
                        if (data[key]) {
                            console.log(key + " name: ", data[key].name);
                            console.log(key + " size: ", data[key].size);   
                            
                        }
                    }
                } else {
                  
                    formData.append(key, data[key]);
                }
            });      
                formData.append("id", clinicData?.id);
               



            if (clinicData?.id){
                setLoading(true)
              let res = await  dispatch(storeClinicBusinessDetails(
                  {data:formData,id:clinicData?.id}
              ))
                if (res?.payload){
                }
                setLoading(false)
            }
        },
    })
    
    useEffect(()=>{
        if (clinicData?.id){
            setFieldValue('registered_type',clinicData?.registered_type || null)
            setFieldValue('registered_year',clinicData?.registered_year || null)
            setFieldValue('business_type',clinicData?.business_type || null)
            setFieldValue('company_name',clinicData?.company_name || "")
            setFieldValue('business_owners_name',clinicData?.business_owners_name || "")
            setFieldValue('business_tags',clinicData?.business_tags || "")  
            setFieldValue('service_prefix',clinicData?.service_prefix || "")
            setFieldValue('time_zone',clinicData?.time_zone || "")
            setFieldValue('owner_phone',clinicData?.owner_phone || "")
            // setFieldValue('first_name',clinicData?.first_name || "")
            if (clinicData?.profile_image && clinicData?.profile_image !== values.profile_image) {
                setFieldValue('profile_image', clinicData?.profile_image);
            }
    
            if (clinicData?.treatment_form_logo && clinicData?.treatment_form_logo !== values.treatment_form_logo) {
                setFieldValue('treatment_form_logo', clinicData?.treatment_form_logo);
            }
            // setFieldValue('story_about_business',clinicData?.story_about_business || "")
        }
    },[clinicData,clinicData?.id])      
    return (
        <Box
            sx={{height: 'calc(100vh  - 200px)'}}
        >
            {loading ? <Loader/> : <form onSubmit={handleSubmit}>
                <Grid container p={"20px"} sx={{justifyContent: 'space-between', width: "100%"}}>
                <Grid container spacing={2} p={"20px"} sx={{ justifyContent: 'space-between', width: "100%" }}>
  {/* Left side */}
  <Grid item xs={12} md={6} lg={6}>
    <Image
      label={'Image'}
      name={"profile_image"}
      values={values}   
      errors={""}
      touched={""}
      setFieldValue={setFieldValue}
    />
  </Grid>
  
  {/* Right side */}
  <Grid item xs={12} md={6} lg={6} sx={{paddingLeft:{sm:'20px !important', lg:'100px !important'} }}>
    <ImageLogo
      label={'Treatment Form Logo'}
      name={"treatment_form_logo"}
      values={values}
      errors={""}
      touched={""}
      setFieldValue={setFieldValue}
    />     
  </Grid>
</Grid>

                  
                    <Grid item xs={12} lg={5.5}>
                        <FormAutoComplete
                            name={"registered_type"}
                            options={registeredTypeOptions}  
                            values={values}
                            errors={errors}
                            multiple={false}
                            touched={touched}
                            label={'Registered type'}
                            setFieldValue={setFieldValue}
                        />
   
                    </Grid>
                    <Grid item xs={12} lg={5.5}>
                        <FormAutoComplete
                            name={"registered_year"}
                            options={generateYearsBetween()}
                            values={values}
                            multiple={false}
                            errors={errors}
                            touched={touched}
                            label={'Registered year'}
                            setFieldValue={setFieldValue}
                        />
                    </Grid>  
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"company_name"}
                            label={"Company Name"}
                            placeholder={'Type  Company  Name'}
                            errors={errors}
                            touched={touched}
                            values={values}  
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5}>
                        <FormAutoComplete
                            name={"business_type"}
                            options={businessTypeOptions}
                            values={values}
                            multiple={false}
                            errors={errors}
                            touched={touched}
                            label={'Business type'}
                            setFieldValue={setFieldValue}
                        />

                    </Grid>

                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"business_owners_name"}  
                            label={"Business Owner Name"}    
                            placeholder={'Type Business Owner Name'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5}>  
                        <FormInput
                            name={"business_tags"}  
                            label={"Business tags (comma seperated)"}
                            placeholder={'Type Business tags '}
                            errors={errors}  
                            touched={touched}    
                            values={values}   
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"service_prefix"}
                            label={"Location Prefix"}
                            placeholder={'Type Location Prefix '}
                            errors={errors}   
                            touched={touched}  
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"time_zone"}        
                            label={"Time Zone"}
                            placeholder={'Type Time Zone '}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"owner_phone"}
                            label={"Owner Phone"}
                            placeholder={'Type Owner Phone '}
                            errors={errors}
                            touched={touched}   
                            values={values}  
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth sx={{mb: "16px"}}>
                            <FormLabel sx={{fontSize: "16px", color: '#27272e', letterSpacing: '.3px'}}>Story About
                                Business</FormLabel>
                            <TextField
                                minRows={6}
                                maxRows={6}
                                sx={inputStyle}
                                name={"story_about_business"}
                                multiline={true}
                                value={values?.story_about_business}
                                placeholder={'Type Story about Business'}
                                onChange={handleChange}
                            />
                            {/* {errors?.story_about_business && touched?.story_about_business && <FormHelperText sx={{
                                color: '#CB3838',
                                width: 'max-content',
                                mx: 0
                            }}>{errors?.story_about_business}</FormHelperText>} */}

                        </FormControl>
                    </Grid>
                    <Grid container spacing={2} sx={{mt: "16px", '@media (max-width: 430px)' :{ flexDirection:"row-reverse" }}}>
                        <Grid item xs={12} sm={6} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                            <RemovalAccountModal />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}  sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <SubmitButton val={"Add Business Details"} />
                        </Grid>
                    </Grid>


                </Grid>
            </form>   
            }
        </Box>
    );
};

export default BusinessDetails;