import React, {useEffect, useRef, useState} from 'react';
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import {Grid} from "@mui/material";

const SquareCard = ({paymentMethod,setSquareCard}) => {
    const [paymentError, setPaymentError] = useState(null);
    const [methodLoading, setMethodLoading] = useState(false);
    const hasRun = useRef(false);


    const initializeCard = async (payments) => {
        const card = await payments.card();
        await card.attach('#card-container');
        return card;
    };

    useEffect(() => {
        if (!hasRun.current) {

            const initializeSquarePayment = async (method) => {
                if (!window.Square) {
                    toast.error("Square is not loaded. Reload it or Contact with provider", {duration: 5000})

                    setPaymentError("Square is not loaded. Reload it or Contact with provider")
                    return
                    // throw new Error('Square.js failed to load properly');
                }
                let payments;
                try {
                    payments = window.Square.payments(method?.application_key, method?.merchant_id);
                } catch {
                    toast.error("Square Credential are invalid", {duration: 5000})
                    setPaymentError('Square Credential are invalid');
                    return;
                }

                let card;
                try {
                    card = await initializeCard(payments);
                    setSquareCard(card);
                } catch (e) {
                    toast.error("Square Credential are invalid", {duration: 5000})

                    setPaymentError("Square Credential are invalid.")
                    console.error('Initializing Card failed', e);
                }
            };

            (async () => {
                const cardContainer = document.getElementById('card-container');
                setMethodLoading(true)
                if (cardContainer && !cardContainer.hasChildNodes()) {
                    if (paymentMethod?.payment_method_id === 4) {
                        await initializeSquarePayment(paymentMethod);
                    }
                }
                setMethodLoading(false)

            })()
            hasRun.current = true; // Mark as run
        }
    }, []);
    return (
        <Grid container>
            <Grid item xs={12} sx={{mt: "16px"}}>
                {methodLoading ? <Box sx={{mb:'24px'}}>Loading...</Box> :""}
                <Box id="card-container"></Box>
            </Grid>
        </Grid>
    );
};

export default SquareCard;