import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import useStyles from './style';
import { scrollCSS } from '../../App';
import DateRange from '../date-range/date-range';
export const inputStyle = {
  background: "#FFF",
  borderRadius: "4px",
  width:"60%",
  '@media (max-width: 1600px) ': {
    width:'100%'
 },
 '@media (max-width: 1000px) ': {
  width:'100%'
},
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },

  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};
const Map = (props,onDatesChange) => {
 
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const {
    saleshour,
    salesData,
    fetchData

   } = props;
   const handleButtonClick = async () => {
    // Call fetchData with the selected date
    await fetchData(selectedDate);
  };
    const color =
    saleshour === 'SALES/HOURS'
     ? '#000' // Black
      // Green
     : saleshour === 'Monthly SALES/HOURS'
     ? 'black' // Black
     : '#000'; // Default color


     const background =
     saleshour === 'SALES/HOURS'
       ? '#E5E7FD' 
     
       : saleshour === 'Monthly SALES/HOURS'
       ? '#E5E7FD' 
       : '#E5E7FD'; // Default color


       const classes = useStyles(props);

       function createData(name, customerName, email) {
         return { name, customerName, email };
       }


       const rows = [
         createData(salesData?.total_appointment, salesData?.forecasted_sale,salesData?.time),
       ];

       const completedRows = [
        createData(salesData?.total_completed, salesData?.completed_sale, salesData?.completed_time, ),
      ];
      const cancelRows = [
        createData(salesData?.total_cancel, salesData?.cancel_sale, salesData?.cancel_time, ),
      ];

      const handleDateChange = async (e) => {
        const newDate = e.target.value;
        setSelectedDate(newDate);
        // Immediately call fetchData with the new selected date
        await fetchData(newDate);
      };
  return (
    <Box
     sx={ {
      padding: {lg:'40px 10px', xs:'20px 10px'},
     
      } }>


    <Box sx={{ position:'relative', padding:'20px 0',  borderRadius: "8px", overflow:'hidden', background: background ,  height:'auto',
      '@media (min-width:1600px)': {padding:'20px 10px', height:'700px'} }}>
      {/* Table */}
      <Box sx={{ height:{sm:'155px', xs:'130px'} }}>
      <Typography variant="h5" sx={{ fontFamily:'helvetica-lt-std-bold', color:color, padding:'20px 20px' }}>
           {saleshour}

        </Typography>
        <Box sx={{ display:"flex" , justifyContent:"space-between",flexDirection:'column','@media (min-width:1600px)': {flexDirection:'row'} }}>
        <TextField
            variant="outlined"
            name="date" 
            sx={inputStyle}
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
          />
     

        </Box>
        </Box>

      <TableContainer
        component={Paper}
        sx={{
          mt: 0,   
          boxShadow: "none",
          maxHeight: "650px",
          overflowY: "auto",
          ...scrollCSS,
        }}
      >
        <Table sx={{minWidth:{sm:'350px', xs:'430px'}}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                  width:"35%"
                  
                }}
              >
                Total Appointments
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0,   width:"35%" }}
              >
                Forecasted Sales
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0,  width:"35%" }}
              >
                Time
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
               $   {row.customerName}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        
        <Table sx={{minWidth:{sm:'350px', xs:'430px'}}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                  width:'35%'
                }}
              >
                Total Completed
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0, width:'35%' }}
              >
                Sales
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0,width:'30%' }}
              >
                Time
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>          
            {completedRows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableLink} >
                $ {row.customerName}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Table sx={{minWidth:{sm:'350px', xs:'430px'}}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                  width:'35%'
                }}
              >
               Total Cancel
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0,  width:'35%' }}
              >
                Cancel Sales
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0, width:'30%' }}
              >
                Time
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {cancelRows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                ${row.customerName}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

          
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count=""
        rowsPerPage=""
        page=""
        onPageChange=""
        onRowsPerPageChange=""
      /> */}


    </Box>
</Box>
  )
}

export default Map
