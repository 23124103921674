import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../lib/apiHeader";
import apis from "../../../lib/apis/apis";


export const getAllCards = createAsyncThunk('getAllCards', async (data,{dispatch}) => {
    try {

        const response = await axios.get(apis.get_all_cards, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return []

        }

    } catch (err) {
       return  []
    }
})


export const deleteCard = createAsyncThunk('deleteCard', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.delete_card,{...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllCards())
            toast.success("Card Deleted Successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})
export const defaultCard = createAsyncThunk('defaultCard', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.default_card,{...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllCards())
            toast.success("Card set Default Successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})



export const storeUserCreateCard = createAsyncThunk('storeUserCreateCard', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.create_card, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {
            console.log( response?.data?.errors)

            return response?.data
        } else if (response?.data?.success === "true") {

            await dispatch(getAllCards())

            return response?.data
        }

    }catch (err){
        // if (err?.response?.data?.errors){
            return err?.response?.data
        // }
        // if (err?.response?.status!==200){
        //     toast.error(err?.message,{duration:4000})
        // }

    }
})
export const storePublicUserCreateCard = createAsyncThunk('storePublicUserCreateCard', async (data,{dispatch}) => {
    const token = data?.token
    delete data['token']
    try {
        const response = await axios.post(apis.create_card, {...data},{
            headers:  {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        if (response?.data?.errors) {
            console.log( response?.data?.errors)

            return response?.data
        } else if (response?.data?.success === "true") {

            return response?.data
        }

    }catch (err){
        // if (err?.response?.data?.errors){
            return err?.response?.data
        // }
        // if (err?.response?.status!==200){
        //     toast.error(err?.message,{duration:4000})
        // }

    }
})


export const getAllCardsTransaction = createAsyncThunk('getAllCardsTransaction', async (data,{dispatch}) => {
    try {

        const response = await axios.get(apis.customer_wallet_transaction, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return []

        }

    } catch (err) {
        return  []
    }
})


export const userCreateCardSlice = createSlice({
    name: "userCreateCardSlice",
    initialState: {
        cards: [],
        transactions: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllCards.fulfilled, (state, action) => {
            state.cards = action.payload
        })
        builder.addCase(getAllCardsTransaction.fulfilled, (state, action) => {
            state.transactions = action.payload
        })
    }
});

export default userCreateCardSlice.reducer;
