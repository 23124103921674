import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../../lib/apiHeader";
import apis from "../../../../lib/apis/apis";
import {getClinicByID} from "../../index";

export const storeClinicBookingSettings = createAsyncThunk('storeClinicBookingSettings', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.clinic_booking_setting, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {
            console.log( response?.data?.errors)

        } else if (response?.data?.success === "true") {
            await dispatch(getClinicByID(data?.provider_id))
            toast.success(` Booking Settings Updated Successfully`, {duration: 3000})
        }

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
            return err?.response?.data
        }

    }
})
export const getClinicPaymentArray = createAsyncThunk("getClinicPaymentArray", async (status, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_payment_gateway, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }
            if (response?.data?.success === false) {

                return [];
            }
        } catch (err) {
            return []
        }
    }
);

export const clinicBookingSettingsSlice = createSlice({
    name: "clinicBookingSettingsSlice",
    initialState: {
        servicesTypes: [],
        paymentArray: [],
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getClinicPaymentArray.fulfilled, (state, action) => {
            state.paymentArray = action.payload || []
        })
    }

});

export default clinicBookingSettingsSlice.reducer;
