import React from 'react';
import {Box, Button, Typography} from "@mui/material";

import { styled } from '@mui/material/styles'
import {Link} from "react-router-dom";

const BoxWrapper = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: '90vw'
    }
}))

const NotFound = () => {
    return (
        <Box className='content-center'>
            <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                <BoxWrapper>
                    <Typography variant='h1' sx={{ mb: 2.5 }}>
                        404
                    </Typography>
                    <Typography variant='h5' sx={{ mb: 2.5, letterSpacing: '0.18px', fontSize: '1.5rem !important' }}>
                        Page Not Found ⚠️
                    </Typography>
                    <Typography variant='body2'>We couldn&prime;t find the page you are looking for.</Typography>
                </BoxWrapper>
                {/*<Img alt='error-illustration' src='/images/pages/404.png' />*/}
                <Link to='/'>
                    <Button variant='outlined' sx={{ px: 5.5,mt:5,textDecorationL:'none' }}>
                        Back to Home
                    </Button>
                </Link>
            </Box>
        </Box>
    );
};
export default NotFound;