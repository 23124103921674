import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders} from "../../../../lib/apiHeader";


export const getAllClinicsRooms = createAsyncThunk('getAllClinicsRooms', async (provider_id,{dispatch,getState}) => {
    const { search, pageLimit, currentPage } = getState().clinicRoomsStore; 
    try {
        const response = await axios.get(apis.get_clinics_rooms+`${provider_id}?page=1&limit=1000`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})


export const getRooms = createAsyncThunk('getRooms', async (provider_id,{dispatch,getState}) => {
    const { search, pageLimit, currentPage } = getState().clinicRoomsStore; 
    try {
        const response = await axios.get(apis.get_rooms+`${provider_id}&page=${currentPage}&limit=${pageLimit}`, {
            headers: apiHeaders()
        })
   
        if (response?.data?.success ==='true'){
            return response?.data?.data 
        }      
        if (response?.data?.success === false) {    
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})


export const storeClinicsRooms = createAsyncThunk('storeClinicsRooms', async (data) => {
    try {
        const response = await axios.post(apis.store_clinics_room, {...data},{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const getClinicsRoomsByID = createAsyncThunk('getClinicsRoomsByID', async (id) => {
    try {
        const response = await axios.get(apis.get_clinics_rooms_by_id+id,{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

export const updateClinicsRooms = createAsyncThunk('updateClinicsRooms', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_clinics_rooms, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success==='true'){
            await dispatch(getAllClinicsRooms(data?.provider_id))

            return  response.data
        }else {
            return response?.data
        }
    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){
            return {
                data :err?.response,
                errors:true
            }
        }
    }
})
export const deleteClinicsRooms = createAsyncThunk('deleteClinicsRooms', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_clinics_rooms+data?.id,{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllClinicsRooms(data?.provider_id))
            toast.success("Clinic Room Deleted Successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})

export const updateroom = createAsyncThunk('updateroom', async (data) => {
    return data
  })
export const clinicsRoomsSlice = createSlice({
    name: 'clinicsRoomsSlice',
    initialState: {
        rooms: [],
        meta: {},
        links: {},
        pageLimit:10,
        currentPage:1,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllClinicsRooms.fulfilled, (state, action) => {
            state.rooms = action.payload
        
        })
        builder.addCase(getRooms.fulfilled, (state, action) => {
            state.rooms = action.payload
            state.meta = action.payload.meta
            state.links = action.payload.links
            state.currentPage = action.payload?.meta?.current_page;
        })
        // getRooms
        builder.addCase(updateroom.fulfilled, (state, action) => {
            const { search, pageLimit, currentPage } = action.payload; 
            if (pageLimit){  
                state.pageLimit = pageLimit ;
            } 
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
           
        });

    }
})


export default clinicsRoomsSlice.reducer