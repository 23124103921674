import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiHeaders } from "../../lib/apiHeader";
import axios from "axios";
import apis from "../../lib/apis/apis";
import toast from "react-hot-toast";

export const getAllLanguages = createAsyncThunk(
  "getAllLanguages",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get(apis.get_all_languages, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      }
      if (response?.data?.success === false) {
        return {
          data: response.data,
          errors: true,
        };
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        return {
          data: [],
          errors: err?.response?.data?.errors,
        };
      }
    }
  }
);
export const storeLanguages = createAsyncThunk('storeLanguages', async (data) => {
  try {
    const response = await axios.post(apis.store_languages, {...data},{
      headers: apiHeaders()
    })
    return  response?.data

  }catch (err){
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})
    }
    if (err?.response?.status!==200){

      return {
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})
export const updateLanguages = createAsyncThunk('updateLanguages', async (data,{dispatch}) => {
  try {
    const response = await axios.post(apis.update_languages, {...data},{
      headers: apiHeaders()
    })

    if (response?.data?.success==='true'){
      await dispatch(getAllLanguages())
      return  response.data
    }else {
      return response?.data
    }
  }catch (err){
    console.log(err)
    if (err?.response?.status!==200){
      return {
        data :err?.response,
        errors:true
      }
    }
  }
})

export const deleteLanguages = createAsyncThunk('deleteLanguages', async (id,{dispatch}) => {
  try {
    const response = await axios.get(apis.delete_languages+id,{
      headers: apiHeaders()
    })

    if (response?.data?.success ==='true'){
      await dispatch(getAllLanguages())
      toast.success("Languages Deleted Successfully",{duration:3000})

    }else {
      console.log(response?.data)
    }

  }catch (err){
    console.log(err)
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})
    }
  }
})

export const getLanguageByID = createAsyncThunk('getLanguageByID', async (id) => {
  try {
    const response = await axios.get(apis.get_language_by_id+id,{
      headers: apiHeaders()
    })
    return  response?.data

  }catch (err){
    if (err?.response?.status!==200){
      return {
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})
export const languagesSlice = createSlice({
  name: "languagesSlice",
  initialState: {
    languages: [],
    meta: {},
    links: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllLanguages.fulfilled, (state, action) => {
      state.languages = action.payload;
      state.meta = action.payload.meta;
      state.links = action.payload.links;
    });
  },
});

export default languagesSlice.reducer;
