import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";


const PublicRoutes = () => {
    const authStore = useSelector(state => state.authStore)
    const {logged} = authStore

    return logged ===false ?
        <>
            <Outlet/>
        </>
        : <Navigate to={'/'}/>
    ;
};

export default PublicRoutes;