import React, { useState, useRef } from "react";
import { Paper, IconButton, InputBase, Divider } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const BackendSearch = ({ membershipList, bcolor, iconcolor, bordColor }) => {
    const [search, setSearch] = useState("");
    const [filteredItems, setFilteredItems] = useState(membershipList);
    const inputRef = useRef(null);

    const handleChange = (e) => {
        const { selectionStart, selectionEnd } = e.target;
        const { value } = e.target;

        setSearch(value);
        
        if (value === '') {
            setFilteredItems(membershipList);
        } else {
            const filtered = membershipList.filter(item =>
                item.customer.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredItems(filtered);
        }

        requestAnimationFrame(() => {
            inputRef.current.setSelectionRange(selectionStart, selectionEnd);
        });
    };

    const handleClearButtonClick = () => {
        setSearch('');
        setFilteredItems(membershipList);
        inputRef.current.focus();
    };

    return (
        <div>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                onSubmit={(e) => e.preventDefault()}
            >
                <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Subscription by customer name"
                    inputProps={{ 'aria-label': 'search google maps' }}
                    value={search}
                    onChange={handleChange}
                    inputRef={inputRef}
                />
               
            </Paper>

            {/* Display the filtered list */}
            {/* <div>
                {filteredItems.map((item, index) => (
                    <div key={index}>
                        {item.customer.name} - ${item.plan.plan_price} - {item.status}
                    </div>
                ))}
            </div> */}
        </div>
    );
}

export default BackendSearch;
