import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import { apiHeaders } from "../../../lib/apiHeader";

// Thunk to fetch chat list
export const getChatlist = createAsyncThunk("getChatlist", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(apis.chat_list, {
      headers: apiHeaders(),
    });

    if (response?.data?.success === "true") {
      return response?.data?.data;
    } else {
      return rejectWithValue('Failed to fetch chat list');
    }
  } catch (err) {
    return rejectWithValue(err.response.data || 'Something went wrong');
  }  
});
// contact_chat
// delete_chat
export const getContactlist = createAsyncThunk("getContactlist", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(apis.contact_chat, {
      headers: apiHeaders(),    
    });
  
    if (response?.data?.success === "true") {
      return response?.data?.data;
    } else {
      return rejectWithValue('Failed to fetch chat list');
    }
  } catch (err) {
    return rejectWithValue(err.response.data || 'Something went wrong');
  }
});
export const getChatdelete = createAsyncThunk("getChatdelete", async (id, { rejectWithValue }) => {
  try {
    const response = await axios.get(apis.delete_chat+id, {
      headers: apiHeaders(),    
    })
    if (response?.data?.success === "true") {
      return response?.data;
    } else {
      return rejectWithValue('Failed to fetch chat list');
    }
  } catch (err) {
    return rejectWithValue(err.response.data || 'Something went wrong');
  }
});

export const selectChat = createAsyncThunk('selectChat', async (id, { getState, rejectWithValue }) => {
  try {
    
      const response = await axios.get(`${apis.selected_chat}${id}`, {
          headers: apiHeaders(),
      });
      if (response?.data?.success === "true") {
          console.log("Fetched data:", response?.data?.data)    
          return response?.data?.data; 
      } else {
         
          return rejectWithValue('Failed to fetch chat data');
      }
  } catch (err) {
      console.error("Error fetching chat:", err)
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch chat');
  }
});
export const GetMessages = createAsyncThunk('GetMessages', async (id, { getState, rejectWithValue }) => {
  try {
      // Make the API request to get the selected chat
      const response = await axios.get(apis.Get_Messagese+id, {
          headers: apiHeaders(),
      });

      // Check if the response was successful
      if (response?.data?.success === "true") {
          console.log("Fetched data:", response?.data?.data);

          // Return the fetched chat data
          return response?.data; 
      } else {
          // Handle case when the response is not successful
          return rejectWithValue('Failed to fetch chat data');
      }
  } catch (err) {
      console.error("Error fetching chat:", err);
      // Return the error as a rejected value
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch chat');
  }
});
// 
    


export const SendMsgss = createAsyncThunk('SendMsgss', async (payload) => {
  try {
    const response = await axios.post(apis.Send_Messages_user, payload, {
      headers: apiHeaders(),
    });
 
    if (response.data.success === 'true') {
      return response.data.data;
    } else {
      return {};
        
    }
  } catch (error) {
      return {};
  }
});

export const ReadMessages = createAsyncThunk('ReadMessages', async (id, { getState, rejectWithValue }) => {
  try {
      // Make the API request to get the selected chat
      const response = await axios.get(apis.Read_Messages+id, {
          headers: apiHeaders(),
      });

      // Check if the response was successful
      if (response?.data?.success === "true") {
          console.log("Fetched data:", response?.data?.data);

          // Return the fetched chat data
          return response?.data?.data; 
      } else {
          // Handle case when the response is not successful
          return rejectWithValue('Failed to fetch chat data');
      }
  } catch (err) {
      console.error("Error fetching chat:", err);
      // Return the error as a rejected value
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch chat');
  }
});
export const getUserDetails = createAsyncThunk("me", async () => {
  try {
      const response = await axios.get(apis.me, {
          headers: apiHeaders(),
      });

      if (response?.data?.success === true) {
          return {
             data: response?.data?.data,
              token: true,
              loading: false
          }
      }
      if (response?.data?.success === false) {
          localStorage.removeItem('token')
          return {
              token: false,
              loading: false
          }
      }
  } catch (err) {
      localStorage.removeItem('token')
          return {
              token: false,
              loading: false
          };
  }
}
);
export const SendMsg = createAsyncThunk('SendMsg', async (payload) => {
  try {
    const response = await axios.post(apis.Send_Messages, payload, {
      headers: apiHeaders(),
    });

    if (response.data.success === 'true') {
      return response.data;
    } else { 
      return {};
        
    }
  } catch (error) {
      return {};
  }
});

export const ChatModuleSlice = createSlice({ 
  name: 'ChatModuleSlice',
  initialState: {
    Chatlist: [],
    Messages: [],
    contacts: [],
    userProfile: {}, 
    selectedChat: null,
    logged: null,
    loading: false,
  },
  reducers: {
    ADD_MESSAGE_OPTIMISTICALLY: (state, action) => {
        state.Messages.push(action.payload);
    },
    RECEIVE_MESSAGE: (state, action) => {
        // Handle the message coming from WebSocket
        state.Messages.push(action.payload);
    },
    clearSelectedChat: (state) => {
        state.selectedChat = null;
    }
}, 
  extraReducers: builder => {
    builder
      .addCase(getChatlist.fulfilled, (state, action) => {
        state.Chatlist = action.payload || [];
      })
      .addCase(getContactlist.fulfilled, (state, action) => {
        state.contacts = action.payload;
      })
      .addCase(GetMessages.fulfilled, (state, action) => {
        state.Messages = action.payload;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userProfile = action?.payload?.data;
        state.logged = action?.payload?.token;
        state.loading = action?.payload?.loading;
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedChat = action.payload;
      })
      .addCase(getChatlist.rejected, (state, action) => {
        console.error(action.payload);
      })
      .addCase(selectChat.rejected, (state, action) => {
        console.error(action.error.message);
      });
  },
});

export const { clearSelectedChat } = ChatModuleSlice.actions;

export default ChatModuleSlice.reducer;
