import React from 'react';
import {Button} from "@mui/material";

const SubmitButton = ({val,size,mainColor,subColor,style,mb}) => {
    return (
        <Button
            size={size}
            sx={{
                backgroundColor:mainColor||'#E6DAD1 ',
                borderColor:mainColor||'#E6DAD1',
                color:subColor||'#000',
                textTransform: "capitalize",
                "&:hover": {
                    color:mainColor||'#E6DAD1',
                    borderColor:mainColor||'#E6DAD1',
                },
                '@media (max-width:319px)':{ marginBottom:mb}
            }}
            variant={'outlined'}
            type={'submit'}
            style={style}
        >
            {val}
        </Button>
    );
};

export default SubmitButton;