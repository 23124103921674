import { Button, Container, FormControl, Grid, IconButton, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Waveloading from '../../../loading/Waveloading'
import CustomButton from '../../../../components/custom-button/customButton'
import BackendSearchField from '../../../../components/pagination/backendSearch'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { deletegiftcard, getgiftcard, updategiftFilter } from '../../../../store/clinics/clinicSettings/gift-cards'
import moment from 'moment'
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import {MySwal, successConfirmation} from "../../../../services/utills";
import toast from 'react-hot-toast'
import { escapeRegExp } from '../../../../components/table'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D8F3FF",
      color: "#27272E",
      fontSize: "16px",
      fontWeight: 700,
    },  
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
    
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },  
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
const GiftCard = () => {
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const [filterData, setFilterData] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clinicGiftCardsStore = useSelector(state => state?.clinicGiftCardsStore)
    const {gifts,search,currentPage} = clinicGiftCardsStore
  console.log("gifts",gifts)  
    useEffect(()=>{   
      (async ()=>{   
          setLoading(true)  
          await dispatch(getgiftcard())
          setLoading(false)
      })()
  },[dispatch])        
  
    const user = useSelector(state => state?.authStore?.user)
    const handleButtonClick =  async (status) => {  
      console.log('Button clicked with status:', status);    
      setLoading(true);// Add this line
      try {
        await dispatch(getgiftcard(status));   
        
      } catch (error) {   
        toast.error("error occure") 
       
      } finally {
        setLoading(false);    
    
      }
    };
 

    const handleClick = () => {
      if (params?.clinic_id) {
        navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/add-gift`);
      }
    };
    const handleEdit = (row, isEdit) => {
      console.log("id", row)
      if (isEdit) {
          navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/edit-gift/${row?.id}`)
      }
  }   
  const deleteusercard = async (item) => {
   
    const result = await MySwal.fire({
        title: 'Are you sure?',
        text: "Do you want to delete ?",
        icon: 'warning',
        showCancelButton: true,       
        confirmButtonColor: '#3085d6', 
        cancelButtonColor: '#d33',  
        confirmButtonText: 'Yes, delete it'
    });

    if (result.isConfirmed) { 
      console.log("item",item)   
        setLoading(true)  
        const payload ={
          id:item?.id,
          provider_id:item?.provider_id
        }          
        try {  
            await dispatch(deletegiftcard(payload)); 
            await dispatch(getgiftcard());   

            setLoading(false)        
         
        } catch (error) {  
            toast.error("Error occur");
            setLoading(false)   
        } 
    } else {
      setLoading(false)     
    }       
};  
const handleSearch = async (val) => {
  console.log("search",val)  
  await dispatch(updategiftFilter({search:val}))
}         
const refreshData = async () => {
  setLoading(true);
  await dispatch(getgiftcard())    
  setLoading(false);     
};  
useEffect(() => {
  if (search !== "" || search?.length) {
    const searchRegex = new RegExp(escapeRegExp(search), "i");
    let filteredRows;

    // Ensure you are filtering on cardData.data if cardData is an object containing the data array
    filteredRows = gifts?.data?.filter((row) => {
      return Object.keys(row).some((field) => {
        return row[field] !== null && searchRegex.test(row[field].toString());
      });
    });

    if (filteredRows?.length > 0) {
      setFilterData(filteredRows);
    } else {
      setFilterData([]);
    }
  } else {
    setFilterData(gifts?.data);
  }
}, [search, gifts]);

const handleCopyUrl = async (item) => {   
  if (!item?.discount_code) {   
    return;   
  }

  // Copy the item URL to the clipboard
  navigator.clipboard.writeText(item.discount_code);

  // Show a success message
  let timerInterval;
  await MySwal.fire({
    icon: 'success',
    title: 'Promo Code Copied Successfully',
    showConfirmButton: false,
    html: (
      <Box sx={{ mb: 2 }}>
        <Typography>
          I will close in <b></b> milliseconds.
        </Typography>
        <Button
          sx={{ mt: "16px" }}
          onClick={() => MySwal.close()}
        >
          Ok. Close It
        </Button>
      </Box>
    ),
    timer: 4000,
    timerProgressBar: true,
    didOpen: () => {
      const b = MySwal.getHtmlContainer().querySelector('b');
      timerInterval = setInterval(() => {
        b.textContent = MySwal.getTimerLeft();
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  });
};

  
  return ( 
    <>      
    <Grid container p={'20px'}>    
      <Grid item xs={12} sx={{ mb: '16px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', '@media (max-width: 420px)':{display:"block" }, }}>
      <FormControl sx={{mr: "10px",  mb: { xs: 2, sm: 0}}}>   
         <BackendSearchField      
                      search={search}
                      handleSearch={handleSearch}   
                      fetchData={async ()=>{
                          await dispatch(updategiftFilter({currentPage:1}))
                          await refreshData()
                      }}
                  />  
        </FormControl>
        <CustomButton val={'Add Gifts Cards'} mrt={"10px"} size={'large'} handleClick={handleClick} />
      </Grid>  
      <Grid item xs={12} style={{display:"flex",gap:"3",justifyContent: 'flex-end'}}>
      <div>  
          <Button 
      variant="outlined" 
      size="large" 
      // color="success" 
      style={{ marginTop: "20px", marginRight: "10px"}}  
      onClick={() => handleButtonClick("All")}
    >  
      All    
    </Button>    
       
    <Button     
      variant="outlined"    
      size="large"   
      color="success"
      style={{ marginTop: "20px", marginRight: "10px"}} 
      onClick={() => handleButtonClick("Active")}
    > 
      Active
    </Button>  
    <Button 
      variant="outlined"   
      size="large" 
      // color="error"
      style={{ marginTop: "20px", marginRight: "10px", color: "#dcd90a", borderColor: "#dcd90a" }} 
      onClick={() => handleButtonClick("In-Active")}
    >
  InActive
    </Button>       
    <Button 
      variant="outlined"     
      size="large"  
      color="error"
      style={{ marginTop: "20px", marginRight: "10px" }} 
      onClick={() => handleButtonClick("Expired")}  
    >     
 EXPIRED 
    </Button> 
   
  </div>

      </Grid>
      </Grid>    



      {loading ? <Waveloading/> :
<Container maxWidth="xl">

<TableContainer component={Paper}>   
    <Table sx={{ minWidth: 700 }} aria-label="customized table">  
      
     <TableBody>       
      <TableContainer component={Paper}>
  <Table sx={{ minWidth: 700 }} aria-label="customized table">  
    <TableHead>
      <TableRow>
        <StyledTableCell>Title</StyledTableCell>
        <StyledTableCell>Gift Cards Code</StyledTableCell>
        <StyledTableCell>Prices</StyledTableCell>   
        <StyledTableCell>Status</StyledTableCell>  
        <StyledTableCell align="left">Expiration Date</StyledTableCell>
        <StyledTableCell align="left">Action</StyledTableCell>
      </TableRow>
    </TableHead> 
    <TableBody>       
      {Array.isArray(filterData) && filterData.length > 0 ? (
        filterData.map((row) => (
          <StyledTableRow key={row.name}>
            <StyledTableCell component="th" scope="row">
              {row?.title}
            </StyledTableCell> 
            <StyledTableCell align="left">{row?.code}</StyledTableCell>
            <StyledTableCell component="th" scope="row">
              {row?.prices && row?.prices.map((priceObj, index) => (
                <span key={index}>
                  {priceObj.price}
                  {index !== row.prices.length - 1 && ', '}
                </span>
              ))}
            </StyledTableCell>
            <StyledTableCell align="left">{row?.status}</StyledTableCell>
            <StyledTableCell align="left">
              {moment(row?.expiry_date).format("dddd MMMM D, YYYY")}
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
              <Tooltip title="Edit">
                <IconButton
                  size="small"
                  onClick={() => handleEdit(row, true)}
                >
                  <MdModeEditOutline color={"#12B104"} fontSize={'20px'}/>
                </IconButton>   
              </Tooltip>
              <Tooltip title="Delete">   
                <IconButton
                  size="small"
                  onClick={() => deleteusercard(row)}   
                >
                  <MdDeleteOutline color={'#E53E31'} fontSize={'20px'}/>
                </IconButton>
              </Tooltip>
            </StyledTableCell>
          </StyledTableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={6} style={{ textAlign: 'center' }}>
            <p style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
              No data available
            </p>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
</TableContainer>


      </TableBody>
    </Table>
  </TableContainer>
</Container>
}


  </>
  )
}

export default GiftCard