import React from 'react';
import {Box, FormHelperText, Grid, TextField} from "@mui/material";
import {formatCreditCardNumber, formatCVC, formatExpiryDate} from "../../../../services/utills";
import Payment from "payment";

const BraintreeCard = ({errors,touched,setFieldValue,values}) => {

    const handleInputChange = ({target}) => {
        const {name, value} = target

        if (name === 'number') {
            let res = formatCreditCardNumber(value, Payment)
            setFieldValue('number', res)
        } else if (name === 'expirationDate') {
            let res = formatExpiryDate(value)
            setFieldValue('expirationDate', res)
        } else if (name === 'cvv') {
            let res = formatCVC(value, values?.number, Payment)
            setFieldValue('cvv', res)
        }else if (name === 'amount'){
            if (!isNaN(value) && value.trim() !== '') {
                const intValue = parseInt(value, 10);
                setFieldValue('amount',intValue);
            } else if (value === '') {
                setFieldValue('amount',value);
            }
        }
    }

    return (
        <Grid container>
        <Grid item xs={12} sx={{ mb: '24px'}}>
            <Box>
                <TextField
                    value={values?.number}
                    onChange={handleInputChange}
                    label="Card Number"
                    name="number"
                    variant="outlined"
                    placeholder='0000 0000 0000 0000'
                    sx={{width: '100%'}}
                />
                {errors.number && touched.number && <FormHelperText
                    sx={{color: '#CB3838', width: 'max-content', mx: 0}}>{errors.number}</FormHelperText>}
            </Box>
            <Box sx={{display: "flex", justifyContent: "space-between", my: '24px'}}>
                <Box sx={{width:'45%'}}>
                    <TextField
                        fullWidth
                        value={values?.expirationDate}
                        onChange={handleInputChange}
                        name="expirationDate"
                        variant="outlined"
                        placeholder='MM/YYYY'
                        inputProps={{maxLength: '7'}}
                        label="Expiry Date"
                        sx={{marginRight: "10px"}}
                    />
                    {errors.expirationDate && touched.expirationDate &&
                        <FormHelperText sx={{color: '#CB3838', width: 'max-content', mx: 0}}>
                            {errors.expirationDate}
                        </FormHelperText>
                    }
                </Box>
                <Box sx={{width:'45%'}}>
                    <TextField
                        fullWidth
                        value={values?.cvv}
                        onChange={handleInputChange}
                        name="cvv"
                        variant="outlined"
                        label="CVC"
                        sx={{marginRight: "10px"}}
                    />
                    {errors.cvv && touched.cvv &&
                        <FormHelperText sx={{color: '#CB3838', width: 'max-content', mx: 0}}>
                            {errors.cvv}
                        </FormHelperText>
                    }
                </Box>
            </Box>
        </Grid>
        </Grid>
    );
};

export default BraintreeCard;