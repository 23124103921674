import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import apis from "../../lib/apis/apis";

export const fetchAllPublicClinic = createAsyncThunk('fetchAllPublicClinic', async (param,{dispatch}) => {
    const response = await axios.get(apis.get_public_clinic).catch(err=>{
        console.log(err)
    })

    if (response?.data?.data) {
      return   response.data.data
    }else {
        return []
    }
})

export const publicSlice = createSlice({
    name: 'publicSlice',
    initialState: {
        clinics: [],
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(fetchAllPublicClinic.fulfilled, (state, action) => {
            state.clinics = action.payload
        })
    }
})


export default publicSlice.reducer