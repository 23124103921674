import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStyles from "./style";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

export const inputStyle = {
  background: "#EDF2F7",
  borderRadius: "4px", 
  ".MuiInputBase-input": {
    borderColor: " #EDF2F7",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#EDF2F7",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#EDF2F7",
      outline: "none",
    },
  },
};

const Test = () => {
  const classes = useStyles();
  return (
    <div className={classes.invoice}>
      <div style={{ paddingTop: "35px" }}>
        <Box
          className={classes.outerBox}
          sx={{
            // width: 600,  
            // height: 300,
          }}
        >
          <div className={classes.innserSection}>
            <Grid container>
              <Grid item md={6}>
                <Typography variant="h6" color="initial">
                  Booking Details
                </Typography>
              </Grid>
              <Grid item md={6} style={{ textAlign: "end" }}>
                <Typography
                  variant="h6"
                  color="initial"
                  className={classes.walletBalance}
                >
                  Wallet Balance: $300.00
                </Typography>
              </Grid>
            </Grid>
            <Box className={classes.innerBox}>
              <main maxWidth="sm" className={classes.container}>
                <div className={classes.grayContainer}>
                  <Grid container spacing={0} className={classes.innerSection}>
                    <Grid
                      item
                      md={4}
                      lg={4}
                      className={classes.containerItem}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        variant="text"
                        color="primary"
                        sx={{
                          backgroundColor: "#EFEDED",
                          p: 0,
                          minWidth: "30px",
                        }}
                      >
                        +
                      </Button>
                      <Box
                        component="span"
                        sx={{
                          border: "0.5px solid #C4C4C4",
                          borderRadius: "4.13483px",
                          ml: 1,
                          padding: "2px 4px",
                          backgroundColor: "#fff",
                        }}
                      >
                        3
                      </Box>
                      <Button
                        variant="text"
                        color="primary"
                        sx={{
                          backgroundColor: "#EFEDED",
                          p: 0,
                          ml: 1,
                          minWidth: "30px",
                        }}
                      >
                        -
                      </Button>
                    </Grid>
                    <Grid item md={6} lg={6} className={classes.containerItem}>
                      <Typography variant="h6" color="initial">
                        1 Syringe Fillers + Free Botox
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      lg={2}
                      style={{
                        textAlign: "end",
                        display: "flex",
                        alignItems: "center",
                      }}
                      className={classes.containerItem}
                    >
                      <Typography variant="subtitle1" color="initial">
                        <TextField
                          name=""
                          placeholder="$0"
                          fullWidth
                          sx={inputStyle}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* Price */}

                  <Grid
                    container
                    spacing={0}
                    className={classes.innerSection}
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    <Grid item md={6} lg={6} className={classes.containerItem}>
                      <Typography variant="body1" color="#000">
                        Sub Total
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      lg={6}
                      sx={{ textAlign: "end" }}
                      className={classes.containerItem}
                    >
                      <Typography variant="body1" color="#000">
                        $1,500.00
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    className={classes.innerSection}
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    <Grid item md={3} lg={3} className={classes.containerItem}>
                      <Typography variant="body1" color="#000">
                        Discount
                      </Typography>
                    </Grid>
                    <Grid item md={7} lg={7} className={classes.containerItem}>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="fixed"
                            control={
                              <Radio
                                size="small"
                                sx={{ mr: 1, padding: "0px" }}
                              />
                            }
                            label="Fixed"
                          />
                          <FormControlLabel
                            value="percentage"
                            sx={{ ml: 1 }}
                            control={
                              <Radio
                                size="small"
                                sx={{ mr: 1, padding: "0px" }}
                              />
                            }
                            label="Percentage"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      lg={2}
                      sx={{ textAlign: "end" }}
                      className={classes.containerItem}
                    >
                      <Typography variant="body1" color="#000">
                        $0.00
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    className={classes.innerSection}
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    <Grid item md={6} lg={6} className={classes.containerItem}>
                      <Typography variant="body1" color="#000">
                        Total
                      </Typography>
                    </Grid>   
                    <Grid
                      item
                      md={6} 
                      lg={6}    
                      sx={{ textAlign: "end" }}
                      className={classes.containerItem}
                    >
                      <Typography variant="body1" color="#000">
                        $1,500.00
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container  
                    spacing={0}    
                    className={classes.innerSection}
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    <Grid
                      item
                      md={10}
                      lg={10}
                      className={classes.containerItem}
                    >
                      <Typography variant="body1" color="#000">
                        Tax
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      lg={2}
                      sx={{ textAlign: "end" }}
                      className={classes.containerItem}
                    >
                      <Typography variant="body1" color="#000">
                        <TextField
                          name=""
                          placeholder="$0"
                          fullWidth
                          sx={inputStyle}
                        />
                      </Typography>
                    </Grid>      
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    className={classes.innerSection}
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    <Grid
                      item
                      md={10}
                      lg={10}
                      className={classes.containerItem}
                    >
                      <Typography variant="body1" color="#000">
                        Wallet
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      lg={2}
                      sx={{ textAlign: "end" }}
                      className={classes.containerItem}
                    >
                      <Typography variant="body1" color="#000">
                        <TextField
                          name=""
                          placeholder="$0"
                          fullWidth
                          sx={inputStyle}
                        />
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    className={classes.innerSection}
                    style={{
                      marginBottom: "0",
                    }}
                  >
                    <Grid
                      item
                      md={10}
                      lg={10}
                      className={classes.containerItem}
                    >
                      <Typography variant="body1" color="#000">
                        Tip
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      lg={2}
                      sx={{ textAlign: "end" }}
                      className={classes.containerItem}
                    >
                      <Typography variant="body1" color="#000">
                        <TextField
                          name=""
                          placeholder="$0"
                          // fullWidth
                          sx={inputStyle}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider variant="middle" />

                  <Grid
                    container
                    spacing={0}
                    className={classes.innerSection}
                    style={{}}
                  >
                    <Grid item md={6} lg={6} className={classes.containerItem}>
                      <Typography variant="body1" color="#000">
                        Grand Total
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      lg={6}
                      sx={{ textAlign: "end" }}
                      className={classes.containerItem}
                    >
                      <Typography variant="h5" fontWeight="bold" color="#000">
                        $205.00
                      </Typography>
                    </Grid>
                  </Grid>
{/* 
                  <div className={classes.innerSection}>
                    <FormControl sx={{ width: "100%", p: 2 }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <Grid container spacing={0}>
                          <Grid item lg={3}>
                            <FormControlLabel
                              value="brainTree"
                              control={
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, paddin   g: "0px" }}
                                />
                              }
                              label="Brain Tree"
                            />
                          </Grid>
                          <Grid item lg={3}>
                            <FormControlLabel
                              value="cash"
                              sx={{ ml: 1 }}
                              control={
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, padding: "0px" }}
                                />
                              }
                              label="Cash"
                            />
                          </Grid>

                          <Grid item lg={6}>
                            <FormControlLabel
                              value="debitCreditCard"
                              sx={{ ml: 1 }}
                              control={
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, padding: "0px" }}
                                />
                              }
                              label="Credit or Debit Card"
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ mt: 1 }}
                      >
                        <Grid container spacing={0}>
                          <Grid item lg={3}>
                            <FormControlLabel
                              value="eTransfer"
                              control={
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, padding: "0px" }}
                                />
                              }
                              label="E-Transfer"
                            />
                          </Grid>
                          <Grid item lg={6}>
                            <FormControlLabel
                              value="futurePayment"
                              sx={{ ml: 1 }}
                              control={
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, padding: "0px" }}
                                />
                              }
                              label="Future Payment" 
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                      <Button
                        variant="contained"
                        sx={{ mt: 2 }}
                        color="primary"
                      >
                        Confirm Sale
                      </Button>
                    </FormControl>
                  </div> */}


                  
                  <div className={classes.innerSection}>
                    <FormControl sx={{ width: "100%", p: 2 }}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                            <FormControlLabel
                              value="brainTree"
                              control={
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, padding: "0px" }}
                                />
                              }
                              label="Brain Tree"
                            />
                            <FormControlLabel
                              value="cash"
                              sx={{ ml: 1 }}
                              control={
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, padding: "0px" }}
                                />
                              }
                              label="Cash"
                            />
                            <FormControlLabel
                              value="debitCreditCard"
                              sx={{ ml: 1 }}
                              control={
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, padding: "0px" }}
                                />
                              }
                              label="Credit or Debit Card"
                            />
                            <FormControlLabel
                              value="eTransfer"
                              sx={{ mt:2 }}
                              control={
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, padding: "0px" }}
                                />
                              }  
                              label="E-Transfer"
                            />     
                            <FormControlLabel    
                              value="futurePayment"
                              sx={{ ml: 1, mt:2 }}nnn  
                              control={   
                                <Radio
                                  size="small"
                                  sx={{ mr: 1, padding: "0px" }}
                                />
                              }    
                              label="Future Payment"
                            />    
                      </RadioGroup>
                      <Button  
                        variant="contained"   
                        sx={{ mt: 2 }}
                        mainColor={'#27AE60'} 

                      >
                        Confirm Sale
                      </Button>            
                    </FormControl>
                  </div>
                  





                </div>
              </main>
            </Box>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Test;
