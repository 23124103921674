import React, {useEffect, useState} from 'react';
import {Box, FormControl, FormLabel, Grid, IconButton, TextField,} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import toast from "react-hot-toast";
import ConfigurationLayout from "../configurationLayout";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import Loader from "../../../components/loader/loader";
import SubmitButton from "../../../components/custom-button/submitButton";
import FormInput, {inputStyle, labelStyle} from "../../../components/form/formInput";
import * as yup from "yup";
import {useFormik} from "formik";

import {getPaymentMethodsByID, storePaymentMethods, updatePaymentMethods} from "../../../store/payments-methods";
import FormStatusInput from "../../../components/form/FormStatusInput";
import {GiCancel} from "react-icons/gi";

const AddPaymentsMethods = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()


    const validationSchema = yup.object({
        name: yup.string().min(3,"Method Name is Too Short").required('Method  Name is required'),
        status: yup.string().required('Status is required'),
        is_gateway: yup.number().required('Gateway is required'),
        labels: yup.array().of(yup.string().required())

    });


    const {values,setValues,handleSubmit,setFieldValue,handleChange,touched,errors} = useFormik({
        initialValues: {
            name: '',
            status: 'In-Active',
            is_gateway: 0,
            labels:[]
        },
        validationSchema: validationSchema,
        onSubmit: async (data,{setFieldTouched,setFieldError}) => {
            setLoading(true)

            let res
            if (params?.payment_method_id){
                res = await dispatch(updatePaymentMethods(data))
            }else {
                res = await dispatch(storePaymentMethods(data))
            }
            if (res?.payload?.errors) {
                const {errors} = res?.payload
                console.log(errors)
                if (errors?.name) {
                    setFieldTouched("name", true)
                    setFieldError("name", errors?.name)
                }

            } else if (res?.payload?.success === "true") {
                toast.success(`Payment method ${params?.payment_method_id ?"updated" :"Stored"} Successfully`, {duration: 3000})
                navigate('/admin/configuration/payment-methods')
            }

            setLoading(false)
        },
    });


    useEffect(()=>{
        (async  ()=>{
            if (params?.payment_method_id){
                setLoading(true)
                let res =await  dispatch(getPaymentMethodsByID(params?.payment_method_id))
                const data = res?.payload?.data
                if (data?.id){
                    await setFieldValue("name", data?.name)
                    await setFieldValue('status', data?.status || 'In-Active')
                    await setFieldValue('labels', data?.labels || [])
                    await setFieldValue('is_gateway', data?.is_gateway === 1 ? 1 : 0)
                    await setFieldValue('id', data?.id)
                }
                setLoading(false)
            }

        })()
    },[params?.payment_method_id])


    const handleAddLabel = () => {
        setFieldValue('labels', [...values.labels, '']);
    };
    const handleRemoveLabel = (value) => {
        const newLabels = values.labels.filter(label => label !== value);
        setFieldValue('labels', newLabels);
    };
    return (
        <ConfigurationLayout>
            <Box sx={{height: "calc(100vh - 125px)", backgroundColor:"white"}}>
                {loading ? <Loader/> :
                    <Grid container p={3}>
                        <Grid item xs={12} md={8}>
                            <form onSubmit={handleSubmit}>
                                <FormInput
                                    name={"name"}
                                    label={"Name"}
                                    placeholder={'Type name'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />

                                <Box sx={{display:'flex',flexWrap:'wrap',mb:'16px'}}>
                                    {values.labels.map((label, index) => (
                                        <Box key={index} sx={{width:'300px',display:'flex',alignItems:'center',mr:'20px'}}>
                                            <FormControl fullWidth>
                                                <FormLabel sx={labelStyle}>Field Label</FormLabel>
                                                <TextField
                                                    fullWidth
                                                    name={`labels[${index}]`}
                                                    sx={inputStyle}
                                                    placeholder={"Type Field Label"}
                                                    value={label}
                                                    onChange={handleChange}
                                                />
                                                {/*{errors[name] && touched[name] && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors[name]}</FormHelperText>}*/}
                                            </FormControl>
                                            <Box sx={{padding:'4px',mt:"17px"}}>
                                                <IconButton
                                                    size="small"
                                                    sx={{}}
                                                    onClick={() =>handleRemoveLabel(label)}
                                                >
                                                    <GiCancel
                                                        color={"#CB3838"}
                                                        fontSize={"26px"}
                                                    />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',flexWrap:"wrap"}}>
                                    <FormStatusInput
                                        name={"status"}
                                        errors={errors}
                                        row
                                        label={"Status"}
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        handleChange={handleChange}
                                    />

                                    <FormStatusInput
                                        name={"is_gateway"}
                                        errors={errors}
                                        label={"Gateway"}
                                        row
                                        touched={touched}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        handleChange={handleChange}
                                    />
                                    <Box>
                                        <CustomButton handleClick={handleAddLabel}  val={"Add Payment Field"}/>
                                    </Box>
                                </Box>
                                <Box sx={{display:'flex',alignItems:'center',mt:'10px',justifyContent:'flex-end'}}>
                                    <Box sx={{mr:'16px'}}>
                                        <CustomButton
                                            mainColor={'#ED1E45'}
                                            val={'Cancel'}
                                            handleClick={()=>{navigate('/admin/configuration/payment-methods')}}
                                        />
                                    </Box>
                                    <SubmitButton val={params?.payment_method_id ? "Update Payment Method":"Add Payment Method"}/>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                }
            </Box>
        </ConfigurationLayout>
    );
};

export default AddPaymentsMethods;