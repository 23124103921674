import React from 'react';
import {useSelector} from "react-redux";
import {Box, Typography} from "@mui/material";
import ComingSoon from "../../components/coming-soon";
import Header from "../../components/header/header";

const Staff = () => {
    const userStore = useSelector(state => state?.authStore?.user)

    return (
        <Box>
            <Header/>
            <Typography sx={{textAlign:'center'}} variant={'h3'}>Welcome {userStore?.name}</Typography>
            <ComingSoon/>
        </Box>
    );
};

export default Staff;