import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Hidden from '@mui/material/Hidden';
import FormControl from '@mui/material/FormControl';
import { getAllCalenderBookings, updateCrmCalenderFilter } from '../../store/crm/calender';
import { monthsArray } from '../../services/utills';

const MonthView = ({ setStartDate, fetchCalenderBookings, paddingselect }) => {
  const dispatch = useDispatch();
  const crmCalenderStore = useSelector(state => state?.crmCalenderStore);
  const { calenderType, calenderBookings = [], selectedStaff, isCancel } = crmCalenderStore;
  const { month_counts = {}, bookings = [] } = calenderBookings;
 
  
  const initialMonth = crmCalenderStore.startDate ? crmCalenderStore.startDate.getMonth() : 0;
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);

  const handleMonthChange = async (event) => {   
    const newMonth = event.target.value;
    setSelectedMonth(newMonth);

    const startDate = crmCalenderStore.startDate || new Date(); // Default to the current date if startDate is undefined
    const end = new Date(startDate);
    end.setMonth(newMonth);
    setStartDate(end);

    await dispatch(updateCrmCalenderFilter({ calenderType: "Month" }));
    await fetchCalenderBookings(end);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: 'max-content',
            maxWidth: '100%',
            overflowX: 'auto',
          }}
        >
          <Hidden smUp implementation="css">
            {/* Mobile month dropdown */}
            <FormControl>
              <Select
                value={selectedMonth}
                onChange={handleMonthChange}
                sx={{
                  fontSize: '18px',
                  color: '#000',
                  textTransform: 'capitalize',
                  fontFamily: 'helvetica-lt-std-roman',
                  '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {  // Targeting a child class
                    padding:paddingselect
                },
                }}
              >
                {monthsArray.map((item, index) => (
                  <MenuItem value={item.key} key={index}>
                    {item.shortName} ({month_counts[item.index] || 0})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Hidden>
        </Box>
      </Grid>
      
    </Grid>
  );
};

export default MonthView;
