import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";

export const getProviderStaff = createAsyncThunk("getProviderStaff", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_staffs, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }
            if (response?.data?.success === false) {
    
                return [];
            }
        } catch (err) {

            return  []
        }
    }
);
export const assignCrmStaff = createAsyncThunk('assignCrmStaff', async (data) => {
    try {
        const response = await axios.post(apis.assign_staff, {...data},{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
              
    }
})
export const removeCrmStaff = createAsyncThunk('removeCrmStaff', async (data) => {
    try {
        const response = await axios.post(apis.remove_staff, {...data},{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
              
    }
})



export const crmStaffSlice = createSlice({
    name: "appointmentSlice",
    initialState: {
        crmStaffs: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProviderStaff.fulfilled, (state, action) => {
            state.crmStaffs = action.payload ;
        });

    },
});

export default crmStaffSlice.reducer;
