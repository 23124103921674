import React, { useState } from "react";
import "./styles.scss";
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
// import {Visibility, VisibilityOff} from "@material-ui/icons";
import { FormHelperText } from "@mui/material";
import { MdOutlineVisibilityOff, MdVisibility } from "react-icons/md";
import {inputStyle} from "./formInput";

const FormPasswordInput = ({
  name,
  values,
  handleChange,
  errors,
  touched,
  label,
  placeholder,
  isAuto
}) => {
  const [show, setShow] = useState(false);
  const handleClickShowPassword = () => setShow((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <FormControl fullWidth sx={{ mb: "16px" }}>
      <FormLabel
        focused={false}
        sx={{ fontSize: "16px", color: "#27272e", letterSpacing: ".3px" }}
      >
        {label}
      </FormLabel>
      <OutlinedInput
        fullWidth
        name={name}
        sx={inputStyle}     
        placeholder={placeholder}
        value={values[name]}
        inputProps={{
            autoComplete: isAuto? 'on' : 'new-password',
            form: {
                autoComplete: isAuto? 'on' : 'off',
            },
        }}
        onChange={handleChange}
        type={show ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {show ? <MdVisibility /> : <MdOutlineVisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {errors[name] && touched[name] && (
        <FormHelperText sx={{ color: "#CB3838", width: "max-content", mx: 0 }}>
          {errors[name]?.length < 5
            ? errors[name].map((item, index) => (
                <Box key={index} as={"span"} sx={{ display: "block" }}>
                  {item}
                </Box>
              ))
            : errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormPasswordInput;
