import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../lib/apiHeader";
import {getUserDetails} from "../../auth";


export const getAllUserMembership = createAsyncThunk('getAllMembership', async (id,{dispatch}) => {
    try {
        const response = await axios.get(apis.get_subscription_list+id, {
            headers: apiHeaders()
        })
        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return []
        }

    } catch (err) {
        if (err?.response?.data?.success === false){
            toast.error(err?.response?.data?.errors,{duration:5000})
        }
        return  []
    }
})
export const getUserMembershipDetails = createAsyncThunk('getUserMembershipDetails', async (id,{dispatch}) => {
    try {
        const response = await axios.get(apis.get_my_subscription, {
            headers: apiHeaders()
        })
        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return []
        }

    } catch (err) {
        return  []
    }
})



export const customerCreateMembership = createAsyncThunk('customerCreateMembership', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.create_subscription, {...data},{
            headers: apiHeadersImage()
        })
        if (response?.data?.success ==='true'){
            await dispatch(getUserMembershipDetails())
            dispatch(getUserDetails())
            toast.success("Membership plan subscribe successfully",{duration:5000})
        }
        return  response?.data

    }catch (err) {
        if (err?.response?.status !== 200) {
            toast.error(err?.message, {duration: 4000})
        }
        return err?.response

    }
})
export const customerCancelMembership = createAsyncThunk('customerCancelMembership', async (id,{dispatch}) => {
    try {
        const response = await axios.get(apis.cancel_subscription,{
            headers: apiHeaders()
        })
        if (response?.data?.success ==='true'){
            await dispatch(getUserMembershipDetails())
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

        return err?.response?.data
    }
})
export const customerUpdateMembership = createAsyncThunk('customerUpdateMembership', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_subscription, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.success ==='true'){
            await dispatch(getUserMembershipDetails())
            dispatch(getUserDetails())
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

export const userMembershipSlice = createSlice({
    name: 'userMembershipSlice',
    initialState: {
        userMembershipsList: [],
        userMembership: [],
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllUserMembership.fulfilled, (state, action) => {
            state.userMembershipsList = action.payload
        })
        builder.addCase(getUserMembershipDetails.fulfilled, (state, action) => {
            state.userMembership = action.payload
        })
    }
})


export default userMembershipSlice.reducer