import {
    Box, Breadcrumbs, IconButton, Link, List, ListItem, ListItemText, Drawer, Typography
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { getAllManual } from "../../../store/clinics/clinicSettings/usermanual";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Waveloading from "../../loading/Waveloading";
import MenuIcon from '@mui/icons-material/Menu';

const Usermanual = () => {
    const [loading, setLoading] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [selectedVideoTitle, setSelectedVideoTitle] = useState('video'); // State to store the current video URL
    const dispatch = useDispatch();
    const params = useParams();
    const usermanualStore = useSelector(state => state?.usermanualStore);
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleDrawerToggle = () => {
        setOpenDrawer(!openDrawer);
    };

    const handleVideoClick = (videoUrl,title) => {
        // Ensure the video URL is properly formatted by replacing spaces or pluses with %20
        if (videoUrl) {
            setCurrentVideo(videoUrl);
            setSelectedVideoTitle(title)
        }
    };


    useEffect(() => {
        (async () => {
            setLoading(true);
            await dispatch(getAllManual(params?.clinic_id))
            setLoading(false);
        })();
    }, [dispatch, params?.clinic_id]);

    return (
        <Box sx={{overflowY:"hidden"}}>
            <Box sx={{marginBottom:"20px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/crm/dashboard">
                        Home
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>Video Manual</Typography>
                    <Typography sx={{ color: 'text.primary' }}>{selectedVideoTitle}</Typography> 

                </Breadcrumbs>

                <Box>
                    <IconButton
                        sx={{ display: { xs: "block", sm: "block", md: "none" } }}
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Drawer
                        anchor="left"
                        open={openDrawer}
                        onClose={handleDrawerToggle}
                        sx={{
                            display: { xs: "block", sm: "block", md: "none" }, // only show on mobile
                            "& .MuiDrawer-paper": {
                                width: "245px",
                                boxSizing: "border-box",
                            },
                        }}
                    >
                        <Box sx={{ width: "245px", padding: "20px" }}>
                            <Typography variant="h6" gutterBottom>
                                Video Manual
                            </Typography>
                            <List>
                                {usermanualStore?.manual?.map((link, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => {
                                            handleVideoClick(link?.video,link?.title);
                                            handleDrawerToggle(); // close the drawer after selection
                                        }}
                                        sx={{
                                            borderBottom: "1px solid #dee2e6",
                                            "&:hover": {
                                                backgroundColor: "#C9EDFC",
                                            },
                                        }}
                                    >
                                        <ListItemText primary={link?.title} />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Drawer>
                </Box>
            </Box>

            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    height: "calc(100vh - 155px)",
                    backgroundColor: "#EDF2F7",
                }}
            >
                {loading ? <Waveloading fullSize /> : ""}

                <Box
                    sx={{
                        backgroundColor: "#fff",
                        mr: "30px",
                        width: "245px",
                        borderRadius: "7px",
                        overflowY: "auto",
                        height: "100%",
                        display: { md: "block", xs: "none", sm: "none", lg:"block" },
                        "&::-webkit-scrollbar": {
                            width: "10px",
                            height: "10px"
                        },
                        "&::-webkit-scrollbar-track": {
                            background: "#fff"
                        },
                        "&::-webkit-scrollbar-thumb": {
                            borderRadius: "4px",
                            background: "rgba(0, 0, 0, 0.2)",
                            boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
                        }
                    }}
                >
                    <Typography variant="h6" gutterBottom style={{marginTop:"10px",marginLeft:"20px"}}>
                        Video Manual
                    </Typography>
                    <List>
                        {usermanualStore?.manual?.map((link, index) => (
                            <ListItem key={index} button onClick={() => handleVideoClick(link?.video,link?.title)}
                                      sx={{
                                          borderBottom: "1px solid #dee2e6",
                                          "&:hover": {
                                              backgroundColor: "#C9EDFC",
                                          },
                                      }}>
                                <ListItemText  primary={link?.title} />
                            </ListItem>
                        ))}
                    </List>
                </Box>

                <Box
                    sx={{
                        backgroundColor: "#fff",
                        width: "100%",
                        borderRadius: "7px",
                        height: "100%",
                        overflowY: "hidden"
                    }}
                >
                    {currentVideo ? (
                        <video key={currentVideo} width="100%" height="auto" controls>
                            <source src={currentVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '600px',
                                width: '100%',
                                border: '1px solid #dee2e6',
                            }}
                        >
                            <Typography variant="h5" align="center">
                                Select a video to play
                            </Typography>
                        </Box>
                    )}
                </Box>

            </Box>
        </Box>
    );

};

export default Usermanual;
