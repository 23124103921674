import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../../lib/apiHeader";

export const getAppointmentAttachments = createAsyncThunk("getAppointmentAttachments", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_all_attachment+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const deleteAppointmentAttachment = createAsyncThunk("deleteAppointmentAttachment", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.delete_attachment+data?.id, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                dispatch(getAppointmentAttachments(data?.booking_id))
                return response?.data?.data;
            }else {

                return [];
            }
        } catch (err) {

            return  []
        }
    }
);

export const storeAppointmentAttachment = createAsyncThunk('storeAppointmentAttachment', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_attachment, data?.data,{
            headers: apiHeadersImage()
        })

        if (response?.data?.success === 'true'){
            await dispatch(getAppointmentAttachments(data?.booking_id))
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

/******************* Tags **********************/
export const getAllTags = createAsyncThunk("getAllTags", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_all_tags, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const storeTag = createAsyncThunk('storeTag', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_tags, {...data},{
            headers: apiHeadersImage()
        })

        if (response?.data?.success === 'true'){
            await dispatch(getAllTags())
        }
        return  response?.data

    }catch (err){
        return err?.response
    }
})

/******************* Milestone **********************/
export const getAppointmentMilestone = createAsyncThunk("getAppointmentMilestone", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_all_milestone+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});
export const addAppointmentMileStone = createAsyncThunk("addAppointmentMileStone", async (data, { dispatch }) => {
    try {
        const response = await axios.post(apis.store_milestone, {image_id:data?.id
    }, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            dispatch(getAppointmentMilestone(data?.booking_id))
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});

/******************* Gallery **********************/
export const getAppointmentGallery = createAsyncThunk("getAppointmentGallery", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_all_gallery+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const storeAppointmentGallery = createAsyncThunk('storeAppointmentGallery', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_gallery, data?.data,{
            headers: apiHeadersImage()
        })

        if (response?.data?.success === 'true'){
            await dispatch(getAppointmentGallery(data?.booking_id))
        }
        return  response?.data

    }catch (err){
        console.log("err",err)
        if (err?.response?.status!==200){
            toast.error(err?.response?.data?.message,{duration:4000})
        }
        if (err?.response?.status!==200){
 
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const deleteAppointmentGallery = createAsyncThunk("deleteAppointmentGallery", async (data, { dispatch }) => {
    try {
        const response = await axios.get(apis.delete_gallery+data?.id, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            dispatch(getAppointmentGallery(data?.booking_id))
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});
export const deleteAppointmentGalleryFile = createAsyncThunk("deleteAppointmentGalleryFile", async (data, { dispatch }) => {
    try {
        const response = await axios.get(apis.delete_gallery_file+data?.id, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            dispatch(getAppointmentGallery(data?.booking_id))
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});
export const deleteAppointmentMilestone = createAsyncThunk("deleteAppointmentMilestone", async (data, { dispatch }) => {
    try {
        const response = await axios.get(apis.remove_milestone+data?.id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            dispatch(getAppointmentGallery(data?.booking_id))
            return response?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});




export const crmStaffSlice = createSlice({
    name: "appointmentSlice",
    initialState: {
        attachments: [],
        galleryList: [],
        milestones: [],
        tags:[],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAppointmentAttachments.fulfilled, (state, action) => {
            state.attachments = action.payload ;
        });
        builder.addCase(getAppointmentMilestone.fulfilled, (state, action) => {
            state.milestones = action.payload ;
        });
        builder.addCase(getAppointmentGallery.fulfilled, (state, action) => {
            state.galleryList = action.payload ;
        });
        builder.addCase(getAllTags.fulfilled, (state, action) => {
            state.tags = action.payload ;
        });

    },
});

export default crmStaffSlice.reducer;
