import React from "react";
import SubmitButton from "../../../../components/custom-button/submitButton";
import FormInput from "../../../../components/form/formInput";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import {  useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import {
  storeClinicContactDetails,
} from "../../../../store/clinics/clinicSettings/contactDetails";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams } from "react-router";
import CustomButton from "../../../../components/custom-button/customButton";
import { useEffect } from "react";

const ContactDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const clinicData = useSelector(state => state?.clinicStore?.clinicByID)
  const validationSchema = Yup.object({
    contact_email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
      website: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[^\s]*)?$/,
      "Invalid website address"
    )
      .required("Website is required"),
    
      phone: Yup.string()
      .matches(
        /^(\+92|\+1)?([0-9]){10}$/,
        "Invalid phone number"
      )
      .required("Phone number is required"),
    mobile: Yup.string()
      .matches(
        /^(\+92|\+1)?([0-9]){10}$/,
        "Invalid Mobile number"
      )
      .required("Mobile number is required"),
    
  });

  const { values, handleSubmit, setFieldValue, handleChange, touched, errors ,resetForm} =
    useFormik({
      initialValues: {
        contact_email: "",
        website: "",
        phone: "",
        mobile: "",
      },
      validationSchema: validationSchema,
      onSubmit: async (data, { setFieldTouched, setFieldError }) => {
        try {
          const resultAction = await dispatch(
              storeClinicContactDetails({...data, provider_id: params?.clinic_id,})
          );
          const response = unwrapResult(resultAction);
          console.log("response", response);
        } catch (error) {
          console.log("error", error);
        }
      },
    });

  useEffect(() => {
    if (clinicData?.id) {
      setFieldValue("contact_email", clinicData?.contact_email || '');
      setFieldValue("website", clinicData?.website ||'');
      setFieldValue("phone", clinicData?.phone || '');
      setFieldValue("mobile", clinicData?.mobile || '');
    }
  }, [clinicData,clinicData?.id]);
  return (
    <form onSubmit={handleSubmit}>
      <Grid container p={"20px"} sx={{ justifyContent: "space-between" }}>
        <Grid item xs={12} lg={5.5}>
          <FormInput
            name={"contact_email"}
            label={"Contact Email"}
            placeholder={"Type your contact email"}
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <FormInput
            name={"website"}
            label={"Website"}
            placeholder={"Type your website"}
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <FormInput
            name={"phone"}
            label={"Phone"}
            placeholder={"Type your phone "}
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <FormInput
            name={"mobile"}
            label={"Mobile"}
            placeholder={"Type your mobile"}
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />

          {/* <FormInput
            name={"mobile"}
            label={"Mobile"}
            placeholder={"Type your Mobile"}
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          /> */}
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginRight: "10px" }}>
            <CustomButton mainColor={"#ED1E45"} val={"Cancel"}  handleClick={()=>{
                resetForm(); 
            }} />
          </div>

          <SubmitButton val={"save"} />
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactDetails;
