import React, { useState, useEffect } from 'react';
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icon/close-outline.svg";
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    outline: 'none',
    transform: 'translate(-50%, -50%)',
    minHeight: '288px',
   width:"486px",
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius: "15px",
};

export const modalScroll = {
    maxHeight: "80vh",
    overflowY: 'auto',
    "&::-webkit-scrollbar": {
        width: "10px",
        height: "10px"
    },
    "&::-webkit-scrollbar-track": {
        background: "#fff"
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(0, 0, 0, 0.2)",
        boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
    }
}

const WarningModal = () => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        // Automatically open the modal when the component mounts
        setOpen(true);
    }, []);

    return (
        <div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...modalStyle,  }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: '6px 15px' }}>
                    <IconButton
                            size="small"
                            onClick={() => setOpen(false)}
                            style={{ color: "red" }} // Apply red color here
                        >
                           
                            <CloseRoundedIcon  style={{fontSize: '2rem' }} /> {/* Use Material-UI's Close icon */}
                        </IconButton>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: { sm: '0 20px 20px 20px', xs: '0 10px 20px 10px' },
                        ...modalScroll
                    }}>
                        <ErrorRoundedIcon style={{ color: "red", fontSize: '5rem' }} /> {/* Adjust the fontSize for bigger icon */}
                        <Typography variant="body2" style={{ marginTop: '20px', color: "red", textAlign: "center",fontSize:"20px" }}>
                            Only Original Member Clinic Admin can Cancel; Contact Clinic or Corporate.
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default WarningModal;
