import { Search } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { FcGoogle } from "react-icons/fc";

const SearchFieldGoogle = ({ search, setSearch }) => {
  return (
    <TextField
      name="search"
      value={search}
      sx={{
        background: "#FFFFFF",
        border: "1px solid #4F4F4F",
        borderRadius: "4px",
        ".MuiInputBase-input": {
          borderColor: "#4F4F4F",
          height: "11px",
          minHeight: "unset",
          "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            input: {
              height: "11px",
            },
          },
        },
      }}
      onChange={(e) => setSearch(e.target.value)}
      placeholder="Sync from"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <FcGoogle style={{ fontSize: "1.5rem" }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    ></TextField>
  );
};

export default SearchFieldGoogle;
