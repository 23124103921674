import React, { useRef, useState } from "react";
import { TextField, IconButton, Container, Typography, Box } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getZoeyAi } from "../../../store/crm/zoey-ai";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from 'react-markdown';
import Waveloading from "../../loading/Waveloading";

const ZoeyAi = () => {
  const [question, setQuestion] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const rescheduleButtonRef = useRef(null);

  const handleQuestionChange = (e) => setQuestion(e.target.value);

  const handleIconClick = async () => {
    if (!question.trim() || isProcessing) return; // Prevent duplicate calls

    setIsProcessing(true);
    setLoading(true);
 
    try {
      const payload = { question };
      const response = await dispatch(getZoeyAi(payload));

      const newResponse = {  
        question,
        answer: response?.payload?.answer || '',
      };

      setResponses(prevResponses => [...prevResponses, newResponse]);
      setQuestion("");

      // Scroll to the latest response
      setTimeout(() => {
        rescheduleButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
      setIsProcessing(false);
    }
  };

  const handleSafeIconClick = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); 
      handleIconClick();
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: responses.length === 0 ? "center" : "space-between", 
        backgroundColor: "white"
      }}
    >
      {loading && (
        <Box sx={{
          position: 'fixed',
          width: '100%',
          height: '100vh',
          top: "0",
          left: { sm: '5%', xs: 0 },
          backdropFilter: 'blur(2px)',
        }}>
          <Waveloading /> 
        </Box>
      )} 

      {responses.map((response, index) => (
        <Box key={index} sx={{ mb: 2, mt: 3 }} ref={rescheduleButtonRef}>
          <Typography variant="h5" sx={{whiteSpace: "pre-wrap", backgroundColor: "#9b9b9b47", padding: "10px 20px", marginBottom: "15px" }}>
            {response.question}
          </Typography>
          <ReactMarkdown>{response.answer}</ReactMarkdown>
        </Box>
      ))}

      {/* Show heading only when no responses */}
      {responses.length === 0 && (
        <Typography variant="h3" sx={{ mb: 3, textAlign: "center" }}>What can I help with?</Typography>
      )}

      {/* Input field moves to bottom after response */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: responses.length > 0 ? 'sticky' : 'static', // Stick to bottom after response
        bottom: 0,
        width: '100%',
        backgroundColor: "white",
        paddingBottom: responses.length > 0 ? 2 : 0,
      }}>
      <TextField
  label="Ask a question"
  variant="outlined"
  fullWidth
  value={question}
  minRows={2}
  multiline
  onChange={handleQuestionChange}
  onKeyDown={(e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Shift + Enter: Insert a new line
        e.preventDefault();
        const cursorPosition = e.target.selectionStart;
        const newValue =
          question.slice(0, cursorPosition) + "\n" + question.slice(cursorPosition);

        handleQuestionChange({ target: { value: newValue } });

        // Move cursor to the correct position after inserting newline
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = cursorPosition + 1;
        }, 0);
      } else {
        // Enter alone: Trigger search
        e.preventDefault();
        handleSafeIconClick(e);
      }
    }
  }}
  InputProps={{
    endAdornment: (
      <IconButton onClick={handleIconClick} disabled={!question.trim() || isProcessing}>
        <ArrowForwardIcon />
      </IconButton>
    ),
  }}
  sx={{ mb: 2 }}
/>


      </Box>
    </Container>
  );
};



export default ZoeyAi;