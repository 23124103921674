import React from "react";
import { Link } from "react-router-dom";
import { PRIVACY_POLICY } from "../../config";
import { LICENSE_AGREMENT } from "../../config";
import "./styles.scss";

const PublicFooter = ({ heading, to, toText }) => {

  return (
    <div className={"pb-footer"}>
      <div className={"pf-heading"}>
        <p style={{ fontFamily:'helvetica-lt-std-roman' }}>{heading}</p>
        <Link to={to} style={{ fontFamily:'helvetica-lt-std-roman' }}>{toText}</Link>
      </div>
      <div className={"pf-links"}>
        <Link to={LICENSE_AGREMENT} style={{ fontFamily:'helvetica-lt-std-roman' }}>License Agreement </Link>
        <Link to={PRIVACY_POLICY} style={{ fontFamily:'helvetica-lt-std-roman' }}> - Privacy Policy</Link>
      </div>
    </div>
  );
};

export default PublicFooter;
