import {
  Box,
  Button,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import Loader from "../../../components/loader/loader";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserLayout from "..";
import {
  getBookingStatus,
  storeUserInvoice,
} from "../../../store/booking-widget";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  getUserTreatmentplan,
  updatePlanFilters,
  updateTreatmentPlan,
} from "../../../store/user/treatment-plan";
import FilterInput from "../../../components/form/filterInput";
import BackendSearchField from "../../../components/pagination/backendSearch";
import CustomModal from "../../../components/modal/customModal";
import RescheduleCalender from "../../booking-widget/rescheduleCalender";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  bookingButton,
  statusStyles,
} from "../../../components/custom-button/buttonStyles";
import moment from "moment";
import BackendPagination from "../../../components/pagination/backendPagination";
import { customConfirmation } from "../../../services/utills";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import Footer from "../../home/Footer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D8F3FF",
    color: "#27272E",
    fontSize: "16px",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: "7px",
    paddingBottom: "7px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
}));

const TreatmentPlane = () => {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const [rescheduleData, setRescheduleData] = React.useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const treatmentPlanStore = useSelector((state) => state?.TreatmentPlanStore);
  const { userPlan, filterBy, pageLimit, search, currentPage } =
    treatmentPlanStore;
  const bookingWidgetStore = useSelector((state) => state?.bookingWidgetStore);
  const { bookingStatus, userInvoice } = bookingWidgetStore;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilter = async (val) => {
    await dispatch(updatePlanFilters({ filterBy: val, currentPage: 1 }));
    handleClose();
    await fetchAllBookedPlan();
  };

  const handleLimitChange = async (val) => {
    await dispatch(updatePlanFilters({ pageLimit: val, currentPage: 1 }));
    await fetchAllBookedPlan();
  };

  const fetchAllBookedPlan = async () => {
    setLoading(true);
    await dispatch(getUserTreatmentplan());
    setLoading(false);
  };
  const handleSearch = async (val) => {
    await dispatch(updatePlanFilters({ search: val }));
  };

  const handleRowClick = (index) => {
    if (selectedRow === index) {
      setSelectedRow(null);
    } else {
      setSelectedRow(index);
    }
  };

  const fetchPageData = async (page) => {
    await dispatch(updateTreatmentPlan({ currentPage: page }));
    await fetchAllBookedPlan();
  };
  const handleRescheduleAppointment = async (item) => {
    if (
      await customConfirmation(
        "Reschedule Appointment",
        " Would you like to reschedule?",
        "Yes, Reschedule",
        "Cancel"
      )
    ) {
      setRescheduleData(item);
    }
  };

  const handleRescheduleClose = () => {
    setRescheduleData(null);
  };

  const handleEdit = async (data) => {
    await dispatch(storeUserInvoice(data));
    navigate("/user/appointment/invoice");
  };
  useEffect(() => {
    if (pathname === "/user/appointment/invoice") {
      if (!userInvoice?.id) {
        navigate("/user/plans/listing");
      }
    }
  }, [pathname]);

  useEffect(() => {
    (async () => {
      if (pathname === "/user/plans/listing") {
        dispatch(getBookingStatus());
        await fetchAllBookedPlan();
      }
    })();
  }, [pathname]);

  return (
    <>
      <UserLayout>
        {loading ? <Loader fullSize /> : ""}
        <Box sx={{ marginBottom: { sm: "0", xs: "120px" } }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "16px",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FilterInput
                limit={pageLimit}
                setLimit={handleLimitChange}
                isBorder={true}
              />
              <Typography>
                Showing {userPlan?.meta?.from} to {userPlan?.meta?.to} of{" "}
                {userPlan?.meta?.total} entries
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
            >
              <FormControl
                sx={{
                  mr: "10px",
                  width: { xs: "100%", sm: "300px" },
                  m: { xs: "10px 0", lg: "0" },
                }}
              >
                <BackendSearchField
                  search={search}
                  handleSearch={handleSearch}
                  fetchData={async () => {
                    await dispatch(updateTreatmentPlan({ currentPage: 1 }));
                    await fetchAllBookedPlan();
                  }}
                />
              </FormControl>
              <Button
                variant="outlined"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  p: "8px 15px !important",
                  minWidth: "max-content",
                  color: "black",
                  borderColor: "black",
                  ml: { xs: 0, sm: 2 },
                  "&:hover": {
                    color: "black",
                    borderColor: "black",
                  },
                }}
                startIcon={<FilterAltIcon sx={{ color: "#828282" }} />}
              >
                Sort By
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {bookingStatus?.length > 0 ? (
                  bookingStatus.map((item, index) => (
                    <MenuItem
                      key={index}
                      sx={{
                        background: item === filterBy ? "#09ABF1" : "",
                      }}
                      onClick={() => handleFilter(item)}
                    >
                      {item}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem onClick={handleClose}>Empty</MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
          <Paper
            sx={{
              ".css-16c50h-MuiInputBase-root-MuiTablePagination-select": {
                lineHeight: "1.1375em",
              },
            }}
          >
            <CustomModal
              customWidth={"700px"}
              setOpen={() => handleRescheduleClose()}
              open={!!rescheduleData?.id}
            >
              <Box>
                <RescheduleCalender
                  type={true}
                  loading={loading}
                  fetchAllBookedPlan={fetchAllBookedPlan}
                  handleClose={handleRescheduleClose}
                  setLoading={setLoading}
                  rescheduleData={rescheduleData}
                />
              </Box>
            </CustomModal>
            <CustomModal
              open={isSuccess}
              setOpen={setIsSuccess}
              customWidth={"680px"}
            >
              <Box sx={{ p: "16px" }}>
                <Typography>
                  Your appointment has been cancelled successfully!
                </Typography>
              </Box>
            </CustomModal>
            <TableContainer
              sx={{
                minHeight: "calc(100vh - 311px)",
                "@media (max-width: 400px)": {
                  display: "none",
                },
              }}
            >
              {loading === true && <Loader fullSize />}
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      align={"center"}
                      sx={{
                        minWidth: "60px",
                        fontSize: "16px",
                        fontFamily: "helvetica-lt-std-bold",
                      }}
                    >
                      ID
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        minWidth: "250px",
                        fontSize: "16px",
                        fontFamily: "helvetica-lt-std-bold",
                      }}
                    >
                      Plan Name
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        minWidth: "160px",
                        fontSize: "16px",
                        fontFamily: "helvetica-lt-std-bold",
                      }}
                    >
                      Completed Session
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        minWidth: "160px",
                        fontSize: "16px",
                        fontFamily: "helvetica-lt-std-bold",
                      }}
                    >
                      Total Amount
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        minWidth: "200px",
                        fontSize: "16px",
                        fontFamily: "helvetica-lt-std-bold",
                      }}
                    >
                      Paid Amount
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: "16px",
                        fontFamily: "helvetica-lt-std-bold",
                      }}
                    >
                      Due Amount
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        fontSize: "16px",
                        fontFamily: "helvetica-lt-std-bold",
                      }}
                    >
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {userPlan?.data?.length > 0 ? (
                  <TableBody>
                    {userPlan?.data?.map((item, index) => (
                      <React.Fragment key={index}>
                        <StyledTableRow
                          hover
                          onClick={() => handleRowClick(index)}
                          sx={{
                            backgroundColor:
                              selectedRow === index ? "#F5F5F5" : "transparent",
                            cursor: "pointer",
                          }}
                        >
                          <StyledTableCell
                            align={"center"}
                            component="th"
                            scope="row"
                          >
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "helvetica-lt-std-roman",
                              }}
                            >
                              {item?.id}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "helvetica-lt-std-roman",
                              }}
                            >
                              {item?.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "helvetica-lt-std-roman",
                              }}
                            >
                              {" "}
                              {item?.no_of_bookings?.length > 1
                                ? "Total Sessions:"
                                : "Total Session:"}{" "}
                              {item?.no_of_bookings}
                              {/* {(item?.provider_name) ? `(${item.provider_name})` : ''} */}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "helvetica-lt-std-roman",
                              }}
                            >
                           
                              {(item?.provider_name) ? `(${item.provider_name})` : ''}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {item?.completed_session || 0}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            ${item?.plan_total_amount || 0}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            ${item?.plan_received_amount || 0}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            ${item?.plan_remaining_amount || 0}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {selectedRow === index ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                        {selectedRow === index && (
                          <StyledTableRow>
                            <StyledTableCell
                              colSpan={7}
                              sx={{
                                py: "16px!important",
                              }}
                            >
                              <React.Fragment>
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    size="small"
                                    aria-label="a dense table"
                                  >
                                    <TableHead>
                                      <TableRow
                                        sx={{
                                          background: "#F5F5F5",
                                        }}
                                      >
                                        <TableCell
                                          sx={{
                                            width: "110px",
                                          }}
                                        >
                                          Session No
                                        </TableCell>
                                        <TableCell>Booking Status</TableCell>
                                        <TableCell>Date & Time</TableCell>
                                        <TableCell>Action</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {item?.bookings?.length > 0 &&
                                        item?.bookings.map((row, rowIndex) => (
                                          <TableRow
                                            key={rowIndex}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                          >
                                            <TableCell scope="row">
                                              {row.current_session}
                                            </TableCell>
                                            <TableCell>
                                              <Typography
                                                sx={{
                                                  ...statusStyles,
                                                  color: "#fff",
                                                  background:
                                                    row?.status_color ||
                                                    "#DFDFDF",
                                                  fontSize: "16px",
                                                  width: "max-content",
                                                  px: "20px",
                                                  fontFamily:
                                                    "helvetica-lt-std-roman",
                                                }}
                                              >
                                                {row?.status === "NoShow"
                                                  ? "No Show"
                                                  : row?.status}
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              <Typography
                                                sx={{
                                                  color: row.date
                                                    ? "#000"
                                                    : "#F25022",
                                                }}
                                              >
                                                {row.date
                                                  ? moment(row.date).format(
                                                      "MMMM DD, YYYY"
                                                    )
                                                  : "Not Set"}
                                              </Typography>
                                              <Typography>
                                                {row.date
                                                  ? row.start_time +
                                                    " - " +
                                                    row.end_time
                                                  : ""}
                                              </Typography>
                                            </TableCell>
                                            <StyledTableCell
                                              sx={{
                                                minWidth: "100px",
                                              }}
                                            >
                                              {row?.status === "Completed" ? (
                                                <Button
                                                  onClick={() =>
                                                    handleEdit(row)
                                                  }
                                                  sx={bookingButton}
                                                >
                                                  view Invoice
                                                </Button>
                                              ) : (
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    gap: "10px",
                                                  }}
                                                >
                                                  {(row?.status !== "NoShow" ||
                                                    row?.status !==
                                                      "Cancel") && (
                                                    <Button
                                                      sx={bookingButton}
                                                      onClick={() =>
                                                        handleRescheduleAppointment(
                                                          row
                                                        )
                                                      }
                                                    >
                                                      Reschedule
                                                    </Button>
                                                  )}
                                                </Box>
                                              )}
                                            </StyledTableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </React.Fragment>
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow sx={{ height: "300px", textAlign: "center" }}>
                      <TableCell
                        colSpan={7}
                        align={"center"}
                        sx={{
                          fontWeight: "bold",
                          borderBottom: "none",
                          fontSize: "20px",
                        }}
                      >
                        No Treatment Plans Available
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  mt: "24px",
                  padding: "4px",
                }}
              >
                <BackendPagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  count={userPlan?.meta?.last_page}
                  currentPage={currentPage}
                  handleOnChange={fetchPageData}
                />
              </Box>
            </TableContainer>
          </Paper>
          {userPlan?.data?.length > 0 ? (
            userPlan?.data.map((row, index) => (
              <React.Fragment key={index}>
                <Grid
                  container
                  sx={{
                    backgroundColor: "#f4ede3",
                    mt: 3,
                    mb: 3,
                    borderRadius: "8px",
                    p: 3,
                    display: { xs: "block", sm: "none" },
                  }}
                  onClick={() => handleRowClick(index)}
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{}}>
                        <Typography
                          paragraph
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            display: "block",
                            mt: "15px",
                            fontWeight: "700",
                          }}
                        >
                          ID
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="h5"
                          sx={{
                            color: "#18A0FB",
                            fontSize: "14px",
                            textDecorationLine: "underline",
                            display: "block",
                          }}
                        >
                          {row?.id}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{}}>
                        <Typography
                          paragraph
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          Plan Name
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          paragraph
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          {row?.title}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{}}>
                        <Typography
                          paragraph
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          Completed Session
                        </Typography>
                      </Box>
                      <Box sx={{}}>
                        <Typography
                          paragraph
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          {row?.completed_session || 0}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{}}>
                        <Typography
                          paragraph
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          Paid Amount
                        </Typography>
                      </Box>
                      <Box sx={{}}>
                        <Typography
                          paragraph
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          ${row?.plan_total_amount || 0}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{}}>
                        <Typography
                          paragraph
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                        >
                          Remaining Amount
                        </Typography>
                      </Box>
                      <Box sx={{}}>
                        <Typography
                          paragraph
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                          }}
                        >
                          ${row?.plan_remaining_amount || 0}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Button
                      variant="outlined"
                      type="large"
                      sx={{
                        color: "#666",
                        borderColor: "#666",
                        width: "49%",
                        marginRight: "10px",
                        fontSize: "12px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      type="large"
                      sx={{
                        color: "#666",
                        borderColor: "#666",
                        width: "49%",
                        fontSize: "12px",
                      }}
                    >
                      Reschedule
                    </Button> */}
                    </Box>
                  </Grid>
                </Grid>

                {selectedRow !== null && (
                  <Grid
                    container
                    sx={{
                      backgroundColor: "#f4ede3",
                      mt: 3,
                      mb: 3,
                      borderRadius: "8px",
                      p: 3,
                      display: { xs: "block", sm: "none" },
                    }}
                  >
                    {row?.bookings?.length > 0 &&
                      row?.bookings.map((booking, bookingIndex) => (
                        <Grid item key={bookingIndex}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{}}>
                              <Typography
                                paragraph
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  display: "block",
                                  mt: "15px",
                                  fontWeight: "700",
                                }}
                              >
                                Session No
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "#18A0FB",
                                  fontSize: "14px",
                                  textDecorationLine: "underline",
                                  display: "block",
                                }}
                              >
                                {booking.current_session}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{}}>
                              <Typography
                                paragraph
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                }}
                              >
                                Booking Status
                              </Typography>
                            </Box>
                            <Box sx={{}}>
                              <Typography
                                paragraph
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                }}
                              >
                                {booking?.status === "NoShow"
                                  ? "No Show"
                                  : booking?.status}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "",
                            }}
                          >
                            <Box sx={{}}>
                              <Typography
                                paragraph
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                }}
                              >
                                Date & Time
                              </Typography>
                            </Box>
                            <Box sx={{}}>
                              <Typography
                                sx={{
                                  color: booking.date ? "#000" : "#F25022",
                                }}
                              >
                                {booking.date
                                  ? moment(booking.date).format("MMMM DD, YYYY")
                                  : "Not Set"}
                              </Typography>
                              <Typography>
                                {booking.date
                                  ? booking.start_time +
                                    " - " +
                                    booking.end_time
                                  : ""}
                              </Typography>
                            </Box>
                          </Box>
                          {booking?.status === "Completed" ? (
                            <Button
                              onClick={() => handleEdit(row)}
                              sx={bookingButton}
                            >
                              view Invoice
                            </Button>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                              }}
                            >
                              {(booking?.status !== "NoShow" ||
                                booking?.status !== "Cancel") && (
                                <Button
                                  sx={bookingButton}
                                  onClick={() =>
                                    handleRescheduleAppointment(row)
                                  }
                                >
                                  Reschedule
                                </Button>
                              )}
                            </Box>
                          )}
                        </Grid>
                      ))}
                  </Grid>
                )}
              </React.Fragment>
            ))
          ) : (
            <Typography
              variant="body2"
              color="initial"
              sx={{
                mt: 3,
                textAlign: "center",
                display: { xs: "block", sm: "none" },
              }}
            >
              No data available.
            </Typography>
          )}
        </Box>
        <Box
          style={{
            marginLeft: "calc(-50vw + 50%)",
            marginRight: "calc(-50vw + 50%)",
          }}
        >
          <Footer />
        </Box>
      </UserLayout>
    </>
  );
};
export default TreatmentPlane;
