import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, TextField, TextareaAutosize, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CustomButtoncolor from '../custom-button/customColorButton';
// import { sendReminder } from '../../store/crm/pos';
import { sendReminder } from '../../store/crm/pos';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Label } from '@mui/icons-material';
import { setEmail, setSms } from '../../store/crm/crm';
import { useFormik } from 'formik';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "750px",
    background: '#fff',
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 550px)': {
      width: "95%",
    },
    gap:"5px"
  };
  const wrapper={ 
    alignItems:"center",
  }
  const heading={
    fontSize:"24px",
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'500',
    textAlign:"center",
    width:'100%'

  }
 
  export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
} 
export const btnwrappe ={
  marginTop:"5px",
  display:"flex",
  justifyContent:"flex-end",
  gap:"10px"
  
}
 export const textareaStyle = {

  height: '220px',
  border:"none",
    background: "#EDF2F7",
    borderRadius: '4px',
    outline:"none",
      "&:focus": {
        borderColor: "#EDF2F7",
        outline: "none",
      },
      "&:hover": {
        borderColor: "#EDF2F7",
        outline: "none",
    }
    
};

const DoxyModal = ({ appointment }) => { 
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const validate = (values) => {
      const errors = {};
  
      if (!values.message) {
        errors.message = "Message is required";
      }
  
    
      return errors;
    };
  
    const formik = useFormik({
      initialValues: {
        message: "",
      
      },
      validate,
      onSubmit: (values) => {
      handleSentemail(values.message);
      },
    });
  
    const handleSentemail= async (message) => {
      try {
        const payload = {
        //   customer_id: customer_id,
        //   message: message,
        //   subject: subject,
        };
        dispatch(setSms(payload));
        toast.success('Set email successfully'); // Display success toast
        handleClose();
      } catch (error) {
        console.log('Error sending email', error);
      }
    };
    const handleCloseModal = () => {
      handleClose();  
    };
    
     console.log("doxy modal",appointment)
  return (
    <>
     <CustomButtoncolor
      handleClick={handleOpen}
       mainColor={"#09ABF1"}
       size="md"
       val='Appointment link'
       style={{
        padding:'7px 5px',
        fontSize: "18px",
        minWidth:'max-content',
        borderRadius: "6px!important",
        width: "100%",
        "@media (minWidth: 420px)": {
            width: 'auto'
        },   
    }}                                  
    />
   <Modal
   style={{}}
     open={open}
     onClose={handleClose}
     aria-labelledby="modal-modal-title"
     aria-describedby="modal-modal-description"
   >
     <Box sx={style}>  
     <Grid container spacing={1} >
      
     <Grid item lg={12} style={{display:"flex", justifyContent:"flex-end",width:"100%" }}>

       <CloseIcon onClick={handleCloseModal} /> 
     </Grid>
     <Box style={{marginTop:'5px',width:"100%",padding:"0px 3px 0px 10px"}}>

     <Box      
            sx={{ 
                // background: '#F1F5F9',  
                // px: { sm: '35px', xs: '5px' },  
                // mt: appointmentDetails?.status === 'Completed' ? '35px' : '', 
                mb: '6px',
            }} 
        >
            <Grid container sx={{ background: '#fff', p:"15px", borderRadius: '7px' }}>
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle1" sx={{ fontSize:"16px", fontWeight:"600", mb: '5px' }}>
                            Virtual Appointment Link:
                        </Typography>
                        <Typography variant="body1" sx={{ overflowWrap: 'anywhere' }}>
                        <Link style={{cursor:"pointer"}}>
                            {appointment?.doxy_link}
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle1" sx={{ fontSize:"16px", fontWeight:"600", mb: '5px' }}>
                            User:
                        </Typography>   
                        <Typography variant="body1">
                            {appointment?.doxy_username ? appointment.doxy_username : 'N/A'}
                        </Typography>  
                    </Box>  
                </Grid>   
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography variant="subtitle1" sx={{ fontSize:"16px", fontWeight:"600", mb: '5px' }}>
                            Password:
                        </Typography>
                        <Typography variant="body1">
                            {appointment?.doxy_password ? appointment.doxy_password : 'N/A'}
                        </Typography> 
                    </Box>
                </Grid>  
            </Grid>
        </Box>

     </Box>  

   </Grid>
 
     </Box>
   </Modal>
   </>
  )
}

export default DoxyModal;

