import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { inputStyle, labelStyle } from "../../../../../components/form/formInput";
import MailTemplate from "../mailTemplate";
import CustomButton from "../../../../../components/custom-button/customButton";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlaceData, updateNotificationTemplate } from "../../../../../store/notification-templates";
import { successConfirmation } from "../../../../../services/utills";
import { Modifier, ContentState, EditorState } from 'draft-js';

export const selectStyle = {
    background: "#fff",
    borderRadius: "4px",
    height: "44px",
    marginRight: "5px",
    marginLeft: "5px",
    // ".MuiOutlinedInput-notchedOutline": {
    //   borderWidth: "0!important",
    // },
    ".MuiSelect-select": {
        //   borderColor: " #fff",

        minHeight: "unset",
        "&:focus": {
            borderColor: "#fff",
            outline: "none",
            input: {
                height: "11px",
            },
        },
        "&:hover": {
            borderColor: "#fff",
            outline: "none",
        },
    },
};

const EmailPushNotification = ({ activeType, data, eventName, setLoading }) => {
      // In EmailPushNotification component, define editorState and setEditorState states
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());


    // States for each editor
    const [customerEditorState, setCustomerEditorState] = useState(() => EditorState.createEmpty());
    const [providerEditorState, setProviderEditorState] = useState(() => EditorState.createEmpty());
    const [adminEditorState, setAdminEditorState] = useState(() => EditorState.createEmpty());
    const [staffEditorState, setStaffEditorState] = useState(() => EditorState.createEmpty());


    const [customerChecked, setCustomerChecked] = useState(0);
    const [customerSubject, setCustomerSubject] = useState('');
    const [customerMailBody, setCustomerMailBody] = useState('');
    const [providerChecked, setProviderChecked] = useState(0);
    const [providerSubject, setProviderSubject] = useState('');
    const [providerMailBody, setProviderMailBody] = useState('');
    const [staffChecked, setStaffChecked] = useState(0);
    const [staffSubject, setStaffSubject] = useState('');
    const [staffMailBody, setStaffMailBody] = useState('');
    const [adminChecked, setAdminChecked] = React.useState(0);
    const [adminSubject, setAdminSubject] = useState('');
    const [selectedBookingType, setSelectedBookingType] = useState("");
    const [adminMailBody, setAdminMailBody] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const notificationTemplateStore = useSelector(state => state?.notificationTemplateStore)
    const { items = [], } = notificationTemplateStore
// State for each editor

    const { event_id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {

        /************* customer *****************/
        setCustomerChecked(data?.is_customer || 0)
        setCustomerSubject(data?.customer_subject || '')
        setCustomerMailBody(data?.customer_body || '')

        /************* provider *****************/
        setProviderChecked(data?.is_provider || 0)
        setProviderSubject(data?.provider_subject || '')
        setProviderMailBody(data?.provider_body || '')

        /************* admin *****************/
        setAdminChecked(data?.is_admin || 0)
        setAdminSubject(data?.admin_subject || '')
        setAdminMailBody(data?.admin_body || '')
        /************* admin *****************/
        setStaffChecked(data?.is_staff || 0)
        setStaffSubject(data?.staff_subject || '')
        setStaffMailBody(data?.staff_body || '')

    }, [data])

    const handleSaveTemplate = async () => {
        console.log("Admin mail body"+adminMailBody)
        const customerPlainText = getPlainTextForAPI(customerEditorState);
        const customerplain = getPlainTextadmin(providerEditorState)
        const customer = getPlainTextForProvider(adminEditorState)
        const staff = getPlainTextForStaff(staffEditorState)

        let finalPayload = {
            notification_event_id: event_id,
            is_customer: customerChecked,
            customer_subject: customerSubject,
            customer_body: customerPlainText,

            is_admin: adminChecked,
            admin_subject: adminSubject,
            admin_body: customer,

            is_provider: providerChecked,
            provider_subject: providerSubject,
            provider_body: customerplain,

            is_staff: staffChecked,
            staff_subject: staffSubject,
            staff_body: staff,
        }
        if (activeType === 'Mail') {
            finalPayload['type'] = 'Email'
        } else {
            finalPayload['type'] = 'Push'
        }
        setLoading(true)
        let { payload } = await dispatch(updateNotificationTemplate(finalPayload))
        setLoading(false)
        if (payload?.success === 'true') {
            await successConfirmation('Success', `${eventName} ${activeType} Events Updated`)
        } else {
            console.log(payload, "payload")

        }

    }
    const handleChange = (event, type) => {
        if (type === 'customer') {
            setCustomerChecked(event.target.checked ? 1 : 0);
        } else if (type === 'admin') {
            setAdminChecked(event.target.checked ? 1 : 0);
        } else if (type === 'provider') {

            setProviderChecked(event.target.checked ? 1 : 0);
        }else if (type === 'staff') {
            setStaffChecked(event.target.checked ? 1 : 0);
        }

    };
    useEffect(() => {
        // Dispatch the fetchData action when the component mounts
        dispatch(getAllPlaceData());
    }, []);
    console.log("all data ", items)

    /* const handleBookingTypeChange = (event) => {
         const selectedValue = event.target.value;
         setSelectedValue(selectedValue);
         setCustomerMailBody(` ${selectedValue}`);
     };*/
    const handleMenuItemSelect = (menuItem,editorType) => {
        // Set the selected menu item
        switch (editorType) {
            case 'customer':
                insertTextAtSelectionCustomer(menuItem);
                break;
            case 'provider':
                insertTextAtSelectionProvider(menuItem);
                break;
            case 'admin':
                insertTextAtSelectionAdmin(menuItem);
                break;
            case 'staff':
                insertTextAtSelectionStaff(menuItem);
                break;
            // ... default case or error handling ...
        }
        // Reset the selectedValue to prevent further insertion without selection
    };


    const insertTextAtSelectionCustomer = (text) => {
        const currentContent = customerEditorState.getCurrentContent();
        const currentSelection = customerEditorState.getSelection();
        const newContentState = Modifier.insertText(currentContent, currentSelection, text);
        const newEditorState = EditorState.push(customerEditorState, newContentState, 'insert-characters');
        setCustomerEditorState(newEditorState);    
    };
       


    const insertTextAtSelectionProvider = (text) => {
        const currentContent = providerEditorState.getCurrentContent();
        const currentSelection = providerEditorState.getSelection();
        const newContentState = Modifier.insertText(currentContent, currentSelection, text);
        const newEditorState = EditorState.push(providerEditorState, newContentState, 'insert-characters');
        setProviderEditorState(newEditorState);
    };

    const insertTextAtSelectionAdmin = (text) => {
        const currentContent = adminEditorState.getCurrentContent();
        const currentSelection = adminEditorState.getSelection();
        const newContentState = Modifier.insertText(currentContent, currentSelection, text);
        const newEditorState = EditorState.push(adminEditorState, newContentState, 'insert-characters');
        setAdminEditorState(newEditorState);
    };
    const insertTextAtSelectionStaff = (text) => {
        const currentContent = staffEditorState.getCurrentContent();
        const currentSelection = staffEditorState.getSelection();
        const newContentState = Modifier.insertText(currentContent, currentSelection, text);
        const newEditorState = EditorState.push(staffEditorState, newContentState, 'insert-characters');
        setStaffEditorState(newEditorState);
    };
    const getPlainTextForAPI = (editorState) => {
        return editorState.getCurrentContent().getPlainText();
    };
    const getPlainTextadmin = (editorState) => {
        return editorState.getCurrentContent().getPlainText();
    };
    const getPlainTextForProvider = (editorState) => {
        return editorState.getCurrentContent().getPlainText();
    };
    const getPlainTextForStaff = (editorState) => {
        return editorState.getCurrentContent().getPlainText();
    };
      
    return (
        <Box>   
            <Box>   
                <FormControlLabel
                    value="end"
                    control={
                        <Switch
                            color="primary"
                            checked={customerChecked === 1}
                            onChange={(e) => handleChange(e, 'customer')}
                        />
                    }
                    label={`Customer ${activeType}`}
                    labelPlacement="end"
                />
                {
                    customerChecked === 1 && <Box
                        sx={{
                            mt: '16px',
                            borderTop: "1px solid #E0E0E0",
                            pt: "16px",
                        }}
                    >
                        <FormControl fullWidth sx={{ mb: "16px" }}>
                            <FormLabel sx={labelStyle}>Subject for Mail Send to Customer</FormLabel>
                            <TextField
                                fullWidth
                                sx={inputStyle}
                                inputProps={{
                                    autoComplete: 'new-password',
                                    form: {
                                        autoComplete: 'off',
                                    },
                                }}
                                placeholder={"Type Main Subject"}
                                value={customerSubject}
                                onChange={(e) => setCustomerSubject(e.target?.value)}

                            />
                        </FormControl>
                        <FormControl
  sx={{
    minWidth: { xs: "100%", sm: "220px" },
    mt: { xs: 2, sm: 1 },
  }}
>
  <Select
    value={selectedValue}
    onChange={(event) => handleMenuItemSelect(event.target.value,'customer')}
    displayEmpty
    sx={selectStyle}
  >
    <MenuItem value="">Add placeholder</MenuItem>
    {items?.length ? (
      items.map((type, index) => (
        <MenuItem key={index} value={type.value}>
          {type.key}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="" disabled>
        No Booking Types available
      </MenuItem>
    )}
  </Select>
</FormControl>

  
<MailTemplate
  mailBody={customerMailBody}
  setMailBody={setCustomerMailBody}
  selectedValue={selectedValue}
  setSelectedValue={setSelectedValue}
  editorState={customerEditorState}
  setEditorState={setCustomerEditorState}
/>
                    </Box>
                }
            </Box>

            <Box>
                <FormControlLabel
                    value="end"
                    control={
                        <Switch
                            color="primary"
                            checked={adminChecked === 1}
                            onChange={(e) => handleChange(e, "admin")}
                        />
                    }
                    label={`Admin ${activeType}`}
                    labelPlacement="end"
                />
                {
                    adminChecked === 1 && <Box
                        sx={{
                            mt: '16px',
                            borderTop: "1px solid #E0E0E0",
                            pt: "16px",
                        }}
                    >

                        <FormControl fullWidth sx={{ mb: "16px" }}>
                            <FormLabel sx={labelStyle}>Subject for Mail Send to Admin</FormLabel>
                            <TextField
                                fullWidth
                                sx={inputStyle}
                                inputProps={{
                                    autoComplete: 'new-password',
                                    form: {
                                        autoComplete: 'off',
                                    },
                                }}
                                placeholder={"Type Main Subject"}
                                value={adminSubject}
                                onChange={(e) => setAdminSubject(e.target?.value)}

                            />
                        </FormControl>
                        <FormControl
                            sx={{
                                minWidth: { xs: "100%", sm: "220px" },
                                mt: { xs: 2, sm: 1 },
                            }}
                        >
                            <Select
                                value={selectedValue}
                                onChange={(event) => handleMenuItemSelect(event.target.value,'admin')}
                                displayEmpty
                                sx={selectStyle}
                            >
                                <MenuItem value="">Add placeholder</MenuItem>
                                {items?.length ? (
                                    items.map((type, index) => (
                                        <MenuItem key={index} value={type.value}>
                                            {type.key}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" disabled>
                                        No Booking Types available
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <MailTemplate
                            mailBody={adminMailBody}
                            setMailBody={setAdminMailBody}
                            selectedValue={selectedValue}
                            setSelectedValue={setSelectedValue}
                            editorState={adminEditorState}
                            setEditorState={setAdminEditorState}
                        />

                    </Box>
                }
            </Box>
            <Box>
                <FormControlLabel
                    value="end"
                    control={
                        <Switch
                            color="primary"
                            checked={providerChecked === 1}
                            onChange={(e) => handleChange(e, "provider")}
                        />
                    }
                    label={`Provider ${activeType}`}
                    labelPlacement="end"
                />
                {
                    providerChecked === 1 && <Box
                        sx={{
                            mt: '16px',
                            borderTop: "1px solid #E0E0E0",
                            pt: "16px",
                        }}
                    >
                        <FormControl fullWidth sx={{ mb: "16px" }}>
                            <FormLabel sx={labelStyle}>Subject for Mail Send to Provider</FormLabel>
                            <TextField
                                fullWidth
                                sx={inputStyle}
                                inputProps={{
                                    autoComplete: 'new-password',
                                    form: {
                                        autoComplete: 'off',
                                    },
                                }}
                                placeholder={"Type Mail Subject"}
                                value={providerSubject}
                                onChange={(e) => setProviderSubject(e.target?.value,)}

                            />
                        </FormControl>
                        <FormControl
                            sx={{
                                minWidth: { xs: "100%", sm: "220px" },
                                mt: { xs: 2, sm: 1 },
                            }}
                        >
                            <Select
                                value={selectedValue}
                                onChange={(event) => handleMenuItemSelect(event.target.value,'provider')}
                                displayEmpty
                                sx={selectStyle}
                            >
                                <MenuItem value="">Add placeholder</MenuItem>
                                {items?.length ? (
                                    items.map((type, index) => (
                                        <MenuItem key={index} value={type.value}>
                                            {type.key}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" disabled>
                                        No Booking Types available
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <MailTemplate
                            mailBody={providerMailBody}
                            setMailBody={setProviderMailBody}
                            selectedValue={selectedValue}
                            setSelectedValue={setSelectedValue}
                            editorState={providerEditorState}
                            setEditorState={setProviderEditorState}
                        />

                    </Box>
                }
            </Box>
            <Box>
                <FormControlLabel
                    value="end"
                    control={
                        <Switch
                            color="primary"
                            checked={staffChecked === 1}
                            onChange={(e) => handleChange(e, "staff")}
                        />
                    }
                    label={`Staff ${activeType}`}
                    labelPlacement="end"
                />
                {
                    staffChecked === 1 && <Box
                        sx={{
                            mt: '16px',
                            borderTop: "1px solid #E0E0E0",
                            pt: "16px",
                        }}
                    >
                        <FormControl fullWidth sx={{ mb: "16px" }}>
                            <FormLabel sx={labelStyle}>Subject for Mail Send to Staff</FormLabel>
                            <TextField
                                fullWidth
                                sx={inputStyle}
                                inputProps={{
                                    autoComplete: 'new-password',
                                    form: {
                                        autoComplete: 'off',
                                    },
                                }}
                                placeholder={"Type Mail Staff"}
                                value={staffSubject}
                                onChange={(e) => setStaffSubject(e.target?.value,)}

                            />
                        </FormControl>
                        <FormControl
                            sx={{
                                minWidth: { xs: "100%", sm: "220px" },
                                mt: { xs: 2, sm: 1 },
                            }}
                        >
                            <Select
                                value={selectedValue}
                                onChange={(event) => handleMenuItemSelect(event.target.value,'staff')}
                                displayEmpty
                                sx={selectStyle}
                            >
                                <MenuItem value="">Add placeholder</MenuItem>
                                {items?.length ? (
                                    items.map((type, index) => (
                                        <MenuItem key={index} value={type.value}>
                                            {type.key}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" disabled>
                                        No Booking Types available
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <MailTemplate
                            mailBody={staffMailBody}
                            setMailBody={setStaffMailBody}
                            selectedValue={selectedValue}
                            setSelectedValue={setSelectedValue}
                            editorState={staffEditorState}
                            setEditorState={setStaffEditorState}
                        />

                    </Box>
                }
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: 'flex-end',
                    mt: "16px",
                }}
            >
                <CustomButton handleClick={handleSaveTemplate} val={'Update Templates'} />
            </Box>
        </Box>
    );
}

export default EmailPushNotification;