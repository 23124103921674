import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../../lib/apiHeader";

export const getAllClinicsServices = createAsyncThunk('getAllClinicsServices', async ({ provider_id, category_id }, { dispatch }) => {
  console.log("Provider ID:", provider_id);
    try {
      // Start with the base URL
      let baseUrl = apis.get_service + `${provider_id}`;
      let queryString = `&page=1&limit=1500`;
  
      // Append category_id to the query string if it's provided and in array format
      if (Array.isArray(category_id)) {
        category_id.forEach((value, index) => {
          queryString +=` &category_ids[${index}]=${encodeURIComponent(value)}`;
        });
      } else if (category_id) {
        // If category_id is not an array, just append it
        queryString +=` &category_ids=${encodeURIComponent(category_id)}`;
      }
  
      // Construct the full URL with the manually built query string
      let url = baseUrl + queryString;
  
      // Now make the API call with the constructed URL
      const response = await axios.get(url, { // Use the full URL string
        headers: apiHeaders()
      });
  
      if (response?.data?.success === 'true') {
        return response?.data?.data;
      } else if (response?.data?.success === false) {
        return {
          data: response.data,
          errors: true
        };
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        return {
          data: [],
          errors: err?.response?.data?.errors
        };
      }
    }
  });

export const  getClinicsServices = createAsyncThunk('getClinicsServices', async ({ provider_id, category_id } , { getState, dispatch }) => {
    const { search, pageLimit, currentPage } = getState().clinicServicesStore; 
    try {
    
      let baseUrl = apis.get_clinic_services + `${provider_id}`;
      let queryString = `&page=${currentPage}&limit=${pageLimit}`; 
  
      if (search !== '') {
        queryString += `&search=${encodeURIComponent(search)}`;
      } 
       
      if (Array.isArray(category_id)) {  
        category_id.forEach((value, index) => {
          queryString += `&category_ids[${index}]=${encodeURIComponent(value)}`;
        });
      } else if (category_id) {
        queryString += `&category_ids=${encodeURIComponent(category_id)}`;
      }   
  
      let url = baseUrl + queryString;  
    
      const response = await axios.get(url, { 
        headers: apiHeaders()
      });
  
      if (response?.data?.success === 'true') {
        return response?.data?.data;
      } else if (response?.data?.success === false) {
        return {
          data: response.data,
          errors: true
        };
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        return {
          data: [],
          errors: err?.response?.data?.errors
        };
      }
    }
  });
export const storeClinicsServices = createAsyncThunk('storeClinicsServices', async (data, {dispatch}) => {
    try {
        const response = await axios.post(apis.store_clinics_service, data?.data,{
            headers: apiHeadersImage()
        })
        if (response?.data?.success ==='true'){
          await  dispatch( getClinicsServices(data.provider_id))
            toast.success("Services Added Successfully",{duration:3000})
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }  
})


// 




export const UpdateServices = createAsyncThunk('UpdateServices', async ( {serviceId,data}, {dispatch}) => {
    try {
        const response = await axios.post(`${apis.update_service}/${serviceId}`, data?.data,{
            headers: apiHeadersImage()
        })
        if (response?.data?.success ==='true'){
          if (data.provider_id) {
            await dispatch(getClinicsServices({ provider_id: data.provider_id }));
          } else {
            console.error("Provider ID is undefined");
          }
          // await  dispatch( getClinicsServices(data.provider_id))
            toast.success("Services Added Successfully",{duration:3000})
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){
 
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

// 
export const getClinicsServicesByID = createAsyncThunk('getClinicsServicesByID', async (id) => {
    try {
        const response = await axios.get(apis.get_service_by_id+id,{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
}) 


export const getServiceBundel = createAsyncThunk('getServiceBundel', async (id) => {
  try {
      const response = await axios.get(apis.get_Bundle+id,{
          headers: apiHeaders()
      })
      return  response?.data?.data 

  }catch (err){
      if (err?.response?.status!==200){
          return {
              data : [],
              errors:err?.response?.data?.errors 
          }
      }
  }
}) 


// 
export const updateClinicsServices = createAsyncThunk('updateClinicsServices', async ( {provider_id,data}, { dispatch }) => {
    try {
        const response = await axios.post( `${apis.update_clinics_service}?provider_id=${provider_id}`, data, {
              
            headers: apiHeadersImage()       
        })
     
        if (response?.data?.success === 'true') {
          if (data.provider_id) {
            await dispatch(getClinicsServices({ provider_id: data.provider_id }));
          } else {
            console.error("Provider ID is undefined");
          }
            
            toast.success("Services Updated Successfully", { duration: 3000 })

            return response.data
        } else {
            return response?.data
        }
    } catch (err) {   
        if (err?.response?.status !== 200) {
            toast.error(err?.message, { duration: 4000 })
        }
        if (err?.response?.status !== 200) {
            return {
                data: err?.response,
                errors: true
            }
        }
    }
})

export const deleteClinicsServices = createAsyncThunk('deleteClinicsServices', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_clinic_service+data?.id,{
            headers: apiHeaders()
        })
   
        if (response?.data?.success ==='true'){
          if (data.provider_id) {
            await dispatch(getClinicsServices({ provider_id: data.provider_id })); 
          } else {
            console.error("Provider ID is undefined");
          }
            // await dispatch(getClinicsServices(data?.provider_id))
            toast.success("Clinic Services Deleted Successfully",{duration:3000})

        }else {
        }  
  
    }catch (err){
        if (err?.response?.status!==200){    
          console.log("message",err)  
            toast.error(err?.response?.data?.errors,{duration:4000})   
        }   
    }
})



export const updateserviceFilter = createAsyncThunk('updateserviceFilter', async (data) => {
    return data
})

export const clinicsServicesSlice = createSlice({
    name: 'clinicsServicesSlice',
    initialState: {
        services: [], 
        meta: {},
        links: {},
        search:'', 
       Bundel:[],
        pageLimit:10,
        currentPage:1,
        Promos:[],
    },
    reducers: {
    }, 
    extraReducers: builder => {
        builder.addCase(getAllClinicsServices.fulfilled, (state, action) => {
            state.services = action.payload
            state.meta = action.payload.meta
            state.links = action.payload.links
            state.currentPage = action.payload?.meta?.current_page;

        })

 
            builder.addCase(getClinicsServices.fulfilled, (state, action) => {
                state.services = action.payload
                state.meta = action.payload.meta
                state.links = action.payload.links
                state.currentPage = action.payload?.meta?.current_page;

            })
            
            builder.addCase(getServiceBundel.fulfilled, (state, action) => {
              state.Bundel = action.payload
              state.meta = action.payload.meta
              state.links = action.payload.links
              state.currentPage = action.payload?.meta?.current_page;

          })
            // 
         
            //
        // getClinicsServices
        builder.addCase(updateserviceFilter.fulfilled, (state, action) => {
            const { search, pageLimit, currentPage } = action.payload; 
            if (pageLimit){
                state.pageLimit = pageLimit ;
            } 
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
            
           
        });
        
    }
})


export default clinicsServicesSlice.reducer