import React, {useEffect, useState} from 'react';
import Loader from "../../../../components/loader/loader";
import {useDispatch, useSelector} from "react-redux";
import * as yup from "yup";
import {useFormik} from "formik";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel, FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
} from "@mui/material";
import * as Yup from "yup";
import FormInput, {labelStyle} from "../../../../components/form/formInput";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {getAllPaymentMethods} from "../../../../store/payments-methods";
import FormStatusInput from "../../../../components/form/FormStatusInput";
import {
    getClinicPaymentArray,
    storeClinicBookingSettings
} from "../../../../store/clinics/clinicSettings/booking-settings";

const BookingSettings = () => {
    const [loading, setLoading] = useState(false)
    const [selectedMethods, setSelectedMethods] = useState([])
    const [providerPayment, setProviderPayment] = useState([])


    const dispatch = useDispatch()
    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)
    const paymentMethods = useSelector(state => state?.paymentMethodStore?.paymentMethods?.data)
    const paymentArray = useSelector(state => state?.clinicBookingSettingsSlice?.paymentArray)


    const validationSchema = yup.object({
        booking_process: Yup.number().required('Booking Process is required'),
        booking_mini_amount: Yup.string().when('booking_process', {
            is: 1,
            then: Yup.string().matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, "Only Integer Allowed") ,
            otherwise: Yup.string()
        }),

        staff_available: Yup.string().when('booking_process', {
            is: 1,
            then: Yup.string().required('Booking Process is required'),
            otherwise: Yup.string()
        }),
        is_consultation_fee: Yup.string().when('booking_process', {
            is: 1,
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
        is_upfront_enable: Yup.string().when('booking_process', {
            is: 1,
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
        is_upfront_percentage: Yup.string().when('booking_process', {
            is: 1,
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
        paymentMethodIds: Yup.array().when('booking_process', {
            is: 1,
            then: Yup.array().typeError("At least one Payment method must be selected").min(1, "At least one Payment method must be selected").required('At least one Payment method must be selected'),
            otherwise: Yup.array()
        }),
        merchant_id: Yup.string().when('paymentMethodIds', {
            is: (data) => {
               return data.length > 0 && data.some(user => user.is_gateway === 1)
            },
            then: Yup.string().required('Merchant id is required'),
            otherwise: Yup.string(),
        }),
        application_key: Yup.string().when('paymentMethodIds', {
            is: (data) => {
               return data.length > 0 && data.some(user => user.is_gateway === 1)
            },
            then: Yup.string().required('Application key is required'),
            otherwise: Yup.string(),
        }),
        application_token: Yup.string().when('paymentMethodIds', {
            is: (data) => {
               return data.length > 0 && data.some(user => user.is_gateway === 1)
            },
            then: Yup.string().required('Application token is required'),
            otherwise: Yup.string(),
        }),


        booking_type: Yup.string().when('booking_process', {
            is: 1,
            then: Yup.string().typeError("Booking Process is required").required('Booking Process is required'),
            otherwise: Yup.string()
        }),
        // short_description: Yup.string().required('Short  Description is required'),
        discount_booking_amount: Yup.string().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Only Integer Allowed"
        ),

        tax_booking_amount: Yup.string().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Only Integer Allowed"
        ).required('Tax booking amount is required'),
        consultation_fee: Yup.string().when('is_consultation_fee', {
            is: 1,
            then: Yup.string().matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, "Only Integer Allowed") ,
            otherwise: Yup.string()
        }),
        doxy_link:Yup.string(),
        doxy_username:Yup.string(),
        meeting_link_clinic:Yup.string(),
        doxy_password:Yup.string()

    });


    /**************** brain Tree ******************/
    //     id:  3yv89grzr4ngdymx
    //     public_key : 59751fed226757c5cc3d5d7787b7cce7
    //     private_key : tpfx8823qpmxdjzs

    /**************** Square ******************/
    //     id:  L4F9NREF73W7H
    //     public_key :sandbox-sq0idb-8bAUugmr5uenTrk8YWOTjA
    //     private_key : EAAAEO7zEKZ-tvXLQXT7AZxK7GH20Nb59jMg3_Tau6Mr1puyeFOwRC469u8r7bLt


    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            booking_process: 0,
            booking_mini_amount: '',
            discount_booking_amount: '',
            tax_booking_amount: '',
            booking_type: "Manually",
            discount_tax_in_percentage:0,
            default_tax_in_percentage:0,
            short_description: '',
            paymentMethodIds: [],
            merchant_id: '',
            application_key: '',
            application_token: "",
            staff_available: 0,

            consultation_fee: '',
            is_consultation_fee: 0,
            is_upfront_enable: 0,
            is_upfront_percentage: 0,
            doxy_link: "",
            doxy_username: "",
            doxy_password: "",
            meeting_link_clinic: "",
            google_map_link: "",
            google_review_link: "",

        },
        validationSchema: validationSchema,
        onSubmit: async (FormData, {setFieldTouched, setFieldError}) => {
            if (clinicData?.id) {
                setLoading(true)
                let finalData ={
                    booking_process: FormData?.booking_process,
                    booking_mini_amount: FormData?.booking_mini_amount,
                    discount_booking_amount: FormData?.discount_booking_amount,
                    tax_booking_amount: FormData?.tax_booking_amount,
                    booking_type: FormData?.booking_type,
                    discount_tax_in_percentage:FormData?.discount_tax_in_percentage,
                    default_tax_in_percentage:FormData?.default_tax_in_percentage,
                    short_description: FormData?.short_description,
                    staff_available: FormData?.staff_available,
                    is_consultation_fee: FormData?.is_consultation_fee,
                    is_upfront_enable: FormData?.is_upfront_enable,
                    is_upfront_percentage: FormData?.is_upfront_percentage,
                    consultation_fee: FormData?.consultation_fee,
                    doxy_link: FormData?.doxy_link,
                    doxy_username: FormData?.doxy_username,
                    doxy_password: FormData?.doxy_password,
                    meeting_link_clinic: FormData?.meeting_link_clinic,
                    google_map_link: FormData?.google_map_link,
                    google_review_link: FormData?.google_review_link,
                }
                let finalPaymentIds=[]
                FormData?.paymentMethodIds.forEach((value) =>{
                    finalPaymentIds.push(value?.id)
                })

                let data ={}

                selectedMethods.forEach((item)=>{
                    if (item?.is_gateway === 1 ){
                         let result =  paymentArray.filter((arr )=>arr.toLowerCase() === item?.name.toLowerCase())
                        data["payment_gateway"] = result[0]
                        data["method_id"] = item?.id
                        data["merchant_id"]= FormData?.merchant_id
                        data["application_key"]= FormData?.application_key
                        data["application_token"]= FormData?.application_token
                    }
                })
                if (FormData?.booking_process ===1){
                    finalData['paymentMethodIds']=finalPaymentIds
                    if (data?.method_id){
                        finalData['data'] = data
                    }
                }
                let res = await dispatch(storeClinicBookingSettings({provider_id: clinicData?.id, ...finalData}))
                if (res?.payload) {
                    if (res?.payload?.errors){
                        let err = res?.payload?.errors
                        if (err?.paymentMethodIds){
                            setFieldTouched("paymentMethodIds",true)
                            setFieldError("paymentMethodIds","payment Method is Required")
                        }
                    }
                }
                setLoading(false)
            }
        },
    })


    useEffect(() => {
        (async () => {
            if (!paymentMethods?.length) {
                setLoading(true)
                await dispatch(getAllPaymentMethods())
                await dispatch(getClinicPaymentArray())
                setLoading(false)
            }
        })()
    }, [])


    useEffect(() => {
        if (clinicData?.id && clinicData?.booking_setting?.id) {
            try {
                const bookingSetting = clinicData?.booking_setting
                setFieldValue('booking_mini_amount', bookingSetting?.booking_mini_amount || '')
                // setFieldValue('paymentMethodIds', clinicData?.provider_payment || [])
                setFieldValue('staff_available', bookingSetting?.staff_available || 0)
                setFieldValue('is_consultation_fee', bookingSetting?.is_consultation_fee || 0)
                setFieldValue('is_upfront_enable', bookingSetting?.is_upfront_enable || 0)
                setFieldValue('is_upfront_percentage', bookingSetting?.is_upfront_percentage || 0)
                setFieldValue('short_description', bookingSetting?.short_description || '')
                setFieldValue('tax_booking_amount', bookingSetting?.tax_booking_amount || '')
                setFieldValue('consultation_fee', bookingSetting?.consultation_fee || '')
                setFieldValue('doxy_link', bookingSetting?.doxy_link || '')
                setFieldValue('doxy_username', bookingSetting?.doxy_username || '')
                setFieldValue('doxy_password', bookingSetting?.doxy_password || '')
                setFieldValue('meeting_link_clinic', bookingSetting?.meeting_link_clinic || '')
                setFieldValue('google_map_link', bookingSetting?.google_map_link || '')
                setFieldValue('google_review_link', bookingSetting?.google_review_link || '')
                setFieldValue('default_tax_in_percentage', bookingSetting?.default_tax_in_percentage || 0)
                setFieldValue('discount_tax_in_percentage', bookingSetting?.discount_tax_in_percentage || 0)
                setFieldValue('discount_booking_amount', bookingSetting?.discount_booking_amount || '')
                setFieldValue('booking_type', bookingSetting?.booking_type || '')
                setFieldValue('booking_process', bookingSetting?.booking_process || 0)
                setSelectedMethods(clinicData?.provider_payment.filter((item)=>item?.is_enabled !==0) || [])

                setProviderPayment(clinicData?.provider_payment)

                if (clinicData?.provider_payment?.length){
                    clinicData?.provider_payment.forEach((item) => {
                        if (item?.is_gateway === 1 && item?.is_enabled ===1) {
                            setFieldValue('merchant_id', item?.merchant_id || '')
                            setFieldValue('application_key', item?.application_key || '')
                            setFieldValue('application_token', item?.application_token || '')
                        }
                    })
                }

            } catch (e) {

            }
        }
    }, [clinicData, clinicData?.id])


    // remove from  backend after finding solution


    const handleAutoSelect = () => {
        if (paymentMethods?.length) {
            // let methodOne
            let methodTwo
            paymentMethods.forEach((item) => {
                // if (item?.id === 1){
                //     methodOne = item
                // }
                if (item?.id === 2){
                    methodTwo = item
                }
            })

            if (selectedMethods?.length){
                // let res1 =selectedMethods.some((item)=>item?.id === 1)
                let res2 =selectedMethods.some((item)=>item?.id === 2)
                let array =[]
                // if (res1 === false){
                //     array.push(methodOne)
                // }
                if (res2 === false){
                    array.push(methodTwo)
                }
                if (array?.length){
                    setSelectedMethods([...selectedMethods,...array])
                }

            }else {
                setSelectedMethods([methodTwo])
                // setSelectedMethods([methodOne,methodTwo])
            }

        }
    }
    const handleCheckbox = (item, e) => {
            const {checked} = e.target
            if (checked) {
                if (item?.is_gateway === 1) {
                    let res = selectedMethods.filter((row) => row?.is_gateway !== 1)
                    if (providerPayment?.length > 0) {
                        let result = providerPayment.filter((row) => row?.id === item?.id)
                        result = result[0]
                        setFieldValue('merchant_id', result?.merchant_id || '')
                        setFieldValue('application_key', result?.application_key || '')
                        setFieldValue('application_token', result?.application_token || '')
                    }

                    setSelectedMethods([...res, item])
                } else {
                    setSelectedMethods([...selectedMethods, item])
                }
            } else {
                setSelectedMethods(selectedMethods.filter((row) => row?.id !== item?.id))
            }
    }

    const isChecked = (item, selected) => {
        const found = selected.find((select) => select.id === item?.id);
        return !!found;
    }

    useEffect(() => {

        setFieldValue('paymentMethodIds', selectedMethods)

    }, [selectedMethods, selectedMethods?.length])


    return (
        <Box
            sx={{height: 'calc(100vh  - 200px)'}}
        >
            {loading ? <Loader fullSize/> : ""}
            <form onSubmit={handleSubmit}>
                <Grid container p={"20px"} sx={{justifyContent: 'space-between', width: "100%"}}>
                    <Grid item xs={12} md={5.5}>
                        <FormStatusInput
                            name={"booking_process"}
                            label={"Booking Process"}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            handleAutoSelect={handleAutoSelect}
                        />
                    </Grid>
                    {/*{values?.booking_process === 1 && <Grid item xs={12} lg={5}>*/}
                    {/*    <FormControl sx={{mb: '16px'}}>*/}
                    {/*        <FormLabel sx={labelStyle}>Booking Type</FormLabel>*/}
                    {/*        <RadioGroup*/}
                    {/*            row*/}
                    {/*            name="booking_type"*/}
                    {/*            value={values?.booking_type}*/}
                    {/*            onChange={handleChange}*/}
                    {/*        >*/}
                    {/*            <FormControlLabel value="Automatically" control={<Radio/>} label="Automatically"/>*/}
                    {/*            <FormControlLabel value="Manually" control={<Radio/>} label="Manually"/>*/}
                    {/*        </RadioGroup>*/}
                    {/*        {errors?.booking_type && touched?.booking_type && <FormHelperText sx={{*/}
                    {/*            color: '#CB3838',*/}
                    {/*            width: 'max-content',*/}
                    {/*            mx: 0*/}
                    {/*        }}>{errors?.booking_type}</FormHelperText>}*/}
                    {/*    </FormControl>*/}
                    {/*</Grid>}*/}

                    {values?.booking_process === 1 && <Grid item xs={12} md={5.5}>
                        <FormStatusInput
                            name={"staff_available"}
                            label={"Staff Available"}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    }
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"tax_booking_amount"}
                            label={"Tax Booking Amount"}
                            placeholder={'Type tax booking amount'}
                            errors={errors}
                            mb={"3px"}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                        <FormStatusInput
                            name={"default_tax_in_percentage"}
                            label={"Tax in %"}
                            errors={errors}
                            row
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"discount_booking_amount"}
                            label={"Discount Booking Amount"}
                            placeholder={'Type discount booking amount'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            mb={"3px"}
                            handleChange={handleChange}
                        />
                        <FormStatusInput
                            name={"discount_tax_in_percentage"}
                            label={"Discount in %"}
                            errors={errors}
                            row
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    {values?.booking_process === 1 && <Grid item xs={12} md={5.5}>
                        <FormStatusInput
                            name={"is_consultation_fee"}
                            label={"Is Consultation Fee"}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    }
                    {values?.booking_process === 1 && <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"consultation_fee"}
                            label={"Consultation Fee"}
                            errors={errors}
                            touched={touched}
                            placeholder={'Type Consultation Fee'}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>}
                    {values?.booking_process === 1 && <Grid item xs={12} md={5.5}>
                        <FormStatusInput item xs={12} md={3}
                            name={"is_upfront_enable"}
                            label={"Is Upfront"}
                            errors={errors}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    }
                    {values?.booking_process === 1 && <Grid item xs={12} md={5.5}>
                        <FormStatusInput item xs={12} md={3}
                                         name={"is_upfront_percentage"}
                                         label={"Upfront In %"}
                                         errors={errors}
                                         touched={touched}
                                         setFieldValue={setFieldValue}
                                         values={values}
                                         handleChange={handleChange}
                        />
                    </Grid>
                    }
                    {values?.booking_process === 1 && <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"booking_mini_amount"}
                            label={"Booking Upfront Amount"}
                            placeholder={'Type booking Upfront amount'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>}

                    {values?.booking_process === 1 && <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"doxy_link"}
                            label={"Meeting Link Customer "}
                            placeholder={'Type Meeting Link Customer '}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>}
                    {values?.booking_process === 1 && <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"meeting_link_clinic"}
                            label={"Meeting Link Clinic"}
                            placeholder={'Type Meeting Link Clinic'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>}

                    {values?.booking_process === 1 && <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"doxy_username"}
                            label={"Username"}
                            placeholder={'Type Username'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>}
                    {values?.booking_process === 1 && <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"doxy_password"}
                            label={"Password"}
                            placeholder={'Type Password'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>}
                    {values?.booking_process === 1 && <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"google_map_link"}
                            label={"Google Map Link"}
                            placeholder={'Type Google Map Link'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>}

                    {values?.booking_process === 1 && <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"google_review_link"}
                            label={"Google Review Link"}
                            placeholder={'Type Google Review Link'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>}
                    <Grid item xs={12} lg={12}>
                        <FormInput
                            name={"short_description"}
                            label={"Short Description"}
                            placeholder={'Type short description'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {values?.booking_process === 1 && <FormControl sx={{mb: '16px'}}>
                            <FormLabel sx={labelStyle}>Payment Methods</FormLabel>
                            <FormGroup row>
                                {paymentMethods?.length > 0 && paymentMethods.map((item, index) => item?.is_gateway === 1 && (
                                    <FormControlLabel
                                        key={index}
                                        label={item?.name}
                                        sx={{mr:"16px"}}
                                        control={
                                            <Checkbox
                                                checked={isChecked(item, selectedMethods)}
                                                onChange={(e) => handleCheckbox(item, e)}
                                            />}
                                    />
                                ))}
                                {paymentMethods?.length > 0 && paymentMethods.map((item, index) => item?.is_gateway === 0 && (
                                    <FormControlLabel
                                        key={index}
                                        sx={{mr:"24px"}}
                                        label={item?.name}
                                        control={
                                            <Checkbox
                                                checked={isChecked(item, selectedMethods)}
                                                onChange={(e) => handleCheckbox(item, e)}
                                            />
                                        }
                                    />
                                ))}
                            </FormGroup>
                            {errors?.paymentMethodIds && touched?.paymentMethodIds && <FormHelperText sx={{
                                color: '#CB3838',
                                width: 'max-content',
                                mx: 0
                            }}>{errors?.paymentMethodIds}</FormHelperText>}

                        </FormControl>}
                    </Grid>
                        {values?.booking_process === 1 && selectedMethods?.length > 0 &&
                            <Grid item xs={12}>
                                {
                                    selectedMethods.map((item, index) => item.is_gateway === 1 &&  (
                                       <Grid container key={index} item xs={12} sx={{justifyContent:'space-between'}}>
                                           <Grid item xs={12} lg={5.5}>
                                               <FormInput
                                                   name={"merchant_id"}
                                                   label={`${item?.name} Merchant ID `}
                                                   errors={errors}
                                                   touched={touched}
                                                   setFieldValue={setFieldValue}
                                                   values={values}
                                                   handleChange={handleChange}
                                               />
                                           </Grid>
                                           <Grid item xs={12} lg={5.5}>
                                               <FormInput
                                                   name={"application_key"}
                                                   label={`${item?.name} Public Key`}
                                                   errors={errors}
                                                   touched={touched}
                                                   setFieldValue={setFieldValue}
                                                   values={values}
                                                   handleChange={handleChange}
                                               />
                                           </Grid>
                                           <Grid item xs={12}>
                                               <FormInput
                                                   name={"application_token"}
                                                   label={`${item?.name} Private Key`}
                                                   errors={errors}
                                                   touched={touched}
                                                   setFieldValue={setFieldValue}
                                                   values={values}
                                                   handleChange={handleChange}
                                               />
                                           </Grid>
                                       </Grid>
                                   ))
                               }
                            </Grid>
                        }
                    <Grid item xs={12}
                          sx={{mt: "16px", display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <SubmitButton
                            val={"Update Booking Setting"}
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default BookingSettings;