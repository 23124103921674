import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";

export const getStaff = createAsyncThunk("getStaff", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_staffs, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }
            if (response?.data?.success === false) {

                return [];
            }
        } catch (err) {

            return  []
        }
    }
);

export const crmStaffsSlice = createSlice({
    name: "crmStaffsStore", // This should match what you use in combineReducers (if used) and useSelector
    initialState: {
        crmStaffs: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getStaff.fulfilled, (state, action) => {
            state.crmStaffs = action.payload; // Ensure payload structure matches expected
        });
    },
});

export default crmStaffsSlice.reducer;
