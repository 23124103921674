import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import FilterInput from "../../../components/form/filterInput";
import CustomButton from "../../../components/custom-button/customButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllStaffAttendance,
  updateAttendanceFilters,
  updateStaffAttendance,
} from "../../../store/crm/attendance";
import BackendSearchField from "../../../components/pagination/backendSearch";
import Loader from "../../../components/loader/loader";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import BackendPagination from "../../../components/pagination/backendPagination";
import TimeCounter from "./timeCounter";
import { MySwal } from "../../../services/utills";
import { confirmationButton } from "../call-logs/Appointment";
import CustomModal from "../../../components/modal/customModal";
import DateRange from "../../../components/date-range/date-range";
import MenuItem from "@mui/material/MenuItem";
import Waveloading from "../../loading/Waveloading";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D8F3FF",
    color: "#27272E",
    fontSize: "16px",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingTop: "7px",
    paddingBottom: "7px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CrmStaffAttendance = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [attendance, setAttendance] = useState({
    staff_name: null,
    date: "",
    start_time: "",
    end_time: "",
    password: "",
  });
  // const handleOpenPayment = () => setOpenPayment(true);
  // const handleClosePayment = () => setOpenPayment(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setIsOpen(false);
  };

  const crmStaffAttendanceStore = useSelector(
    (state) => state?.crmStaffAttendanceStore
  );
  const {
    allStaffAttendance = [],
    filterBy,
    pageLimit,
    currentPage,
    search,
  } = crmStaffAttendanceStore;

  useEffect(() => {
    (async () => {
      await fetchStaffAttendance();
    })();
  }, []);

  const fetchStaffAttendance = async () => {
    setLoading(true);
    await dispatch(getAllStaffAttendance());     
    setLoading(false);
  };
  const handleSearch = async (val) => {
    await dispatch(updateAttendanceFilters({ search: val }));   
  };
  const handleLimitChange = async (val) => {
    await dispatch(updateAttendanceFilters({ pageLimit: val, currentPage: 1 }));
    await fetchStaffAttendance();      
  };
  const fetchPageData = async (page) => {
    await dispatch(updateAttendanceFilters({ currentPage: page }));   
    await fetchStaffAttendance();
  };

  const handleAction = async (data, status, value) => {
    let confirm = await MySwal.fire({
      icon: "question",
      title: "Confirmation",
      showConfirmButton: false,
      html: (
        <Box sx={{ mb: 4 }}>
          <Typography sx={{}}>
            Are you sure? You want to {value || status}?   
          </Typography>   
          <Box sx={{ mt: 3 }}>
            <Button
              sx={confirmationButton}
              onClick={() => MySwal.clickConfirm()}
            >
              Yes. Do it.
            </Button>
            <Button sx={confirmationButton} onClick={() => MySwal.close()}>
              Cancel
            </Button>
          </Box>
        </Box>
      ),
    });   
    if (!!confirm?.isConfirmed) {
      let finalPayload = {
        status: status,
        staff_id: data?.staff_id,
      };

      setLoading(true);
      let { payload } = await dispatch(updateStaffAttendance(finalPayload));

      setLoading(false);

    }
  };

  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 109px)",
        background: "#fff",   
        padding:{xs:'10px',sm:'24px'},
        borderRadius: "7px",
      }}
    >
      <Box>
        <Box
          sx={{
            display: {xs:"block",sm:"flex"},
            alignItems: "center",
            gap: "20px",
            justifyContent: "center",

          }}
        >
          <Typography
            sx={{
              textAlign: {xs:"left",sm:"center"},
              fontSize: {xs:"16px",sm:"18px"},
              fontFamily: "helvetica-lt-std-bold",
              mb:{xs:"5px",sm:"0px"}
            }}
          >
            Attendance Date:
          </Typography>
          <Typography
            as={"span"}
            sx={{ fontSize: {xs:"16px",sm:"18px"}, fontFamily: "helvetica-lt-std-bold",mb:{xs:"15px",sm:"0px"} }}
          >
            {moment(new Date()).format("dddd, D MMMM, YYYY")}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsOpen(true)}   
          >
            Add Attendance
          </Button>
        </Box>
        <Box
          sx={{
            mt: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FilterInput
            isBorder
            limit={pageLimit}
            setLimit={handleLimitChange}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControl sx={{ mr: "10px", ml: "10px" }}>
              <BackendSearchField
                search={search}
                handleSearch={handleSearch}
                fetchData={async () => {
                  await dispatch(updateAttendanceFilters({ currentPage: 1 }));
                  await fetchStaffAttendance();
                }}
              />
            </FormControl>
          </Box>  
        </Box>
        <TableContainer sx={{ mt: "24px",display: { xs: "none",sm:"block",lg:'block',xl:'block' }, }}   >
          {loading === true && <Waveloading fullSize />}
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Staff ID</StyledTableCell>
                <StyledTableCell>Staff Name</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            {allStaffAttendance?.length > 0 ? (
              <TableBody>   
                {allStaffAttendance.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row?.staff_id}</StyledTableCell>
                    <StyledTableCell>{row?.staff_name}</StyledTableCell>
                    <StyledTableCell
                      sx={{
                        color:
                          row?.status === "CLOCK-IN"
                            ? "#4169e1"
                            : row?.status === "CLOCK-OUT"
                            ? "#008000"
                            : "#d9534f",
                      }}
                    >
                      {row?.status}
                    </StyledTableCell>
                    <StyledTableCell>{row?.date}</StyledTableCell>
                    <StyledTableCell>
                      {row?.status === "CLOCK-IN" ? (
                        <TimeCounter
                          initialMilliseconds={moment
                            .duration(row?.time?.toString() || 0)
                            .asMilliseconds()}
                        />
                      ) : (
                        row?.time
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        minWidth: "100px",
                      }}
                    >
                      {row?.status === "CLOCK-IN" ? (
                        <CustomButton
                          val={"CLOCK-OUT"}
                          mainColor={"#d9534f"}
                          size={"small"}
                          handleClick={() =>
                            handleAction(row, "Check Out")
                          }
                        />
                      ) : (
                        <CustomButton
                          val={"CLOCK-IN"}
                          size={"small"}
                          handleClick={() => handleAction(row, "Check In")}
                        />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow sx={{ height: "300px", textAlign: "center" }}>
                  <TableCell
                    colSpan={6}
                    align={"center"}
                    sx={{
                      fontWeight: "bold",
                      borderBottom: "none",
                      fontSize: "20px",
                    }}
                  >
                    No Data
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
          {allStaffAttendance?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: "24px",
              }}
            >
              <Typography>
                Showing {allStaffAttendance?.meta?.from} to{" "}
                {allStaffAttendance?.meta?.to} of{" "}
                {allStaffAttendance?.meta?.total} entries
              </Typography>
              <BackendPagination
                count={allStaffAttendance?.meta?.last_page}
                currentPage={currentPage}
                handleOnChange={fetchPageData}
              />
            </Box>
          )}
        </TableContainer>
      </Box>

      <CustomModal
        open={isOpen}
        setOpen={setIsOpen}
        isCloseSideClick={true}
        isCloseHide={false}
        customWidth={"700px"}
      >
        <Box sx={{ textAlign: "center", fontWeight: "bold" }}>
          <Typography variant="h5">Add Attendance</Typography>
        </Box>
        <form autoComplete="off">
          <Grid
            container
            sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
          >
            <Grid item lg={5.8} md={5.8} sm={12} xs={12}>
              <Autocomplete
                options={allStaffAttendance}
                value={attendance?.staff_id}
                disableCloseOnSelect={false}
                isOptionEqualToValue={(option, value) => {
                  return option?.staff_id === value?.staff_id;
                }}
                getOptionLabel={(option) => option?.staff_name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    // inputProps={{...inputProps}}
                    label="Staff Name"
                  />
                )}
                onChange={(event, newValue) => {
                  setAttendance({ ...attendance, staff_name: newValue });
                }}
                renderOption={(props, option, { selected }) => (
                  <MenuItem
                    {...props}
                    key={option?.staff_id}
                    sx={{
                      textTransform: "capitalize",
                    }}
                  >
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option?.staff_name}
                  </MenuItem>
                )}
              />
            </Grid>
            <Grid item lg={5.8} md={5.8} sm={12} xs={12}>
              <TextField
                sx={{ width: "100%", mt: { md: 0, xs: 2 } }}
                id="date"
                label="Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  setAttendance({ ...attendance, date: e?.target?.value })
                }
              />
            </Grid>

            <Grid item lg={5.8} md={5.8} sm={12} xs={12} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: "100%" }}
                id="date"
                label="Start Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  setAttendance({ ...attendance, start_time: e?.target?.value })
                }
              />
            </Grid>
            <Grid item lg={5.8} md={5.8} sm={12} xs={12} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: "100%" }}
                id="date"
                label="End Time"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  setAttendance({ ...attendance, end_time: e?.target?.value })
                }
              />
            </Grid>
            <Grid item xs={12} md={5.78} lg={12} sx={{ mt: 3 }}>
              <TextField
                sx={{ width: "100%" }}
                id="date"
                label="Password"
                type="password"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  setAttendance({ ...attendance, password: e?.target?.value })
                }
              />
            </Grid>
            <Button variant="contained" color="primary" sx={{ mt: 5, mb: 5 }}>
              Save
            </Button>
          </Grid>
        </form>
      </CustomModal>

      {allStaffAttendance?.length > 0 &&
        allStaffAttendance.map((row, index) => (
          <Grid
            container
            key={index}
            sx={{
              boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.25)",
              mt: 3,
              mb: 3,
              borderRadius: "8px",
              p: 3,
              display: { xs: "block", sm: "none" },
            }}
          >
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    paragraph
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      display: "block",
                      mt: "15px",
                      fontWeight: '600'
                    }}
                  >
                    Staff ID
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#18A0FB",
                      fontSize: "14px",
                      textDecorationLine: "underline",
                      display: "block",
                    }}
                  >
                    #{row.staff_id}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    paragraph
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: '600'
                    }}
                  >
                    Staff Name
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    paragraph
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    {row.staff_name}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    paragraph
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: '600'
                    }}
                  >
                    Status
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    paragraph
                    sx={{
                      color: row.status === "CLOCK-IN" ? "#4169e1" : row.status === "CLOCK-OUT" ? "#008000" : "#d9534f",
                      fontSize: "14px",
                    }}
                  >
                    {row.status}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    paragraph
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: '600'
                    }}
                  >
                    Date
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    paragraph
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    {row.date}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    paragraph
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: '600'
                    }}
                  >
                    Time
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    paragraph
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                    }}
                  >
                    {row.status === "CLOCK-IN" ? (
                      <TimeCounter
                        initialMilliseconds={moment
                          .duration(row.time?.toString() || 0)
                          .asMilliseconds()}
                      />
                    ) : (
                      row.time
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {row.status === "CLOCK-IN" ? (
                
                  <CustomButton
                  val={"CLOCK-OUT"}
                  mainColor={"#d9534f"}
                  size={"large"}
                  handleClick={() =>
                    handleAction(row, "Check Out")
                  }
                />
                ) : (
                  <CustomButton
                          val={"CLOCK-IN"}
                          size={"large"}
                          handleClick={() => handleAction(row, "Check In")}
                        />
                )}
              </Box>
            </Grid>
          </Grid>
        ))}

    </Box>
  );
};

export default CrmStaffAttendance;
