import React, {useEffect, useState} from "react";
import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router";
import {useLocation, useParams} from "react-router-dom";

import Slide from "react-reveal/Slide";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../components/loader/loader";
import {getAllNotificationEvents} from "../../../store/notification-templates";
import CustomerSignup from "./notifications/customerSignup";

const NotificationTemplateLayout = () => {
    const [loading, setLoading] =useState(false);

    const navigate = useNavigate();
    const {event_id} = useParams()
    const dispatch = useDispatch()
    const location = useLocation();


    const notificationTemplateStore = useSelector(state => state?.notificationTemplateStore)

    const {notificationEvents=[]} = notificationTemplateStore



    const handleButtonClick = (val) => {
        navigate(val);
    };

    useEffect(()=>{
        (async ()=>{
            if (!notificationEvents?.length > 0){
                setLoading(true)
                await dispatch(getAllNotificationEvents())
                setLoading(false)
            }
        })()
    },[])

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                height: "calc(100vh - 125px)",
                backgroundColor: "#EDF2F7",
            }}
        >
            <Slide left>
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        mr: "30px",
                        width: "245px",
                        borderRadius: "7px",
                        overflowY: "auto",
                        height: "100%",
                        "&::-webkit-scrollbar": {
                            width: "10px",
                            height: "10px"
                          },
                          "&::-webkit-scrollbar-track": {
                            background: "#fff"
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "4px",
                            background: "rgba(0, 0, 0, 0.2)",
                            boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
                          }
                    }}
                >
                    <List
                        sx={{
                            width: "100%",
                            px: "0px",
                            ".MuiListItemIcon-root ": {
                                minWidth: "37px",
                            },
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        {notificationEvents?.length > 0 && notificationEvents.map((item, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => {
                                    handleButtonClick(`${item?.url}/${item?.id}`);
                                }}
                                sx={{
                                    py: "7px",
                                    borderBottom: "1px solid #dee2e6",
                                    backgroundColor: event_id && parseInt(event_id) === item?.id
                                        ? "#C9EDFC"
                                        : "#fff",
                                    "&:hover": {
                                        backgroundColor: "#C9EDFC",
                                    },
                                }}
                            >
                                <ListItemText primary={item?.event_name}/>
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
            </Slide>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    width: "100%",
                    borderRadius: "7px",
                    height: "100%",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                        width: "10px",
                        height: "10px"
                      },
                      "&::-webkit-scrollbar-track": {
                        background: "#fff"
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "4px",
                        background: "rgba(0, 0, 0, 0.2)",
                        boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
                      }
                }}
            >
                {loading ? <Loader/> :
                    notificationEvents?.length > 0 && notificationEvents.map(
                        (item, index) =>  event_id && parseInt(event_id) === item?.id && (
                            <Box key={index}>
                                <CustomerSignup eventName={item?.event_name}/>
                            </Box>
                        )
                    )
                }
            </Box>
        </Box>
    );
};

export default NotificationTemplateLayout;
