import React, { useState, useEffect, useCallback } from "react";
import ComingSoon from "../../../components/coming-soon";
import UserLayout from "../../user";
import {
  Box,
  MenuItem,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../components/loader/loader";
import batch from "../../../assets/icon/batch.png";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
  Container,
  Button,
} from "@mui/material";
import * as XLSX from "xlsx";

import SubmitButton from "../../../components/custom-button/submitButton";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateUserAccount } from "../../../store/user/my-account";
import useStyles from "./style";
import FormDateInput from "../../../components/form/FormDateInput";
import printer from "../../../assets/images/printer.png";
import cloudIcon from "../../../assets/images/cloud-download.png";
import mail from "../../../assets/images/mail-outline.png";
import DateRange from "../../../components/date-range/date-range";
import { MemberBookings, getMemberCount, getMemberReports, } from "../../../store/crm/reports";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ribbon from "../../../assets/images/ribbon.svg";
import redRibbon from "../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../assets/images/yellow-badg.svg";
import { useNavigate } from "react-router";
import AppointmentDetails from "../appointment/appointment-details/appointmentDetails";
import CustomModal from "../../../components/modal/customModal";
import { getAppointmentDetails } from "../../../store/crm/appointment";
import Waveloading from "../../loading/Waveloading";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import "react-html-table-to-excel/dist/TableToExcel.css";
import "./style.css"
import { getProviderStaff } from "../../../store/crm/staff";

export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginRight: "5px",
  marginLeft: "5px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};
const excelButtonStyles = {
  border: "none",
};
export const selectStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginRight: "5px",
  marginLeft: "5px",
  height: '44px',
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",
    
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

const MemberReport = () => {
  const [shouldFetch, setShouldFetch] = useState(true);
  const [loadingSecondTable, setloadingSecondTable] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const classes = useStyles();
  const [staff, setStaff] = React.useState('');
  const [appointmentDetails, setAppointmentDetail] = useState(null)
  const [search, SetSearch] = useState(null)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { state } = useLocation()
  const reportsStore = useSelector(state => state?.reportsStore)
  const { memberBooking = [], memberCount = [], } = reportsStore
  const crmStaffStore = useSelector(state => state?.crmStaffStore)
  const {crmStaffs = []} = crmStaffStore
  const [selectedRow, setSelectedRow] = useState(false);
  const navigate = useNavigate()

  const [selectedRadio, setSelectedRadio] = useState("Member");
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };
  const handleSearchChange = (event) => {
    SetSearch(event.target.value)
  }

  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().required("End Date is required"),
  })

  useEffect(() => {
    dispatch(getProviderStaff());   
  }, [])
 
  const handleChange = (event) => {
    setStaff(event.target.value); 
  }

console.log("handel submit",staff)
  const handleSubmit = useCallback(async (values) => {
    const payload = {
      start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : null,
      end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null,
      date_type: selectedRadio,
      name: search,
      staff_id :staff, 
    };
   console.log("issues in payload",payload) 
    try {
      setLoading(true);      
      await dispatch(getMemberCount(payload));
      // Additional success checks can be added here if needed
      setLoading(false);
      setDataFetched(true);
      return true; // Indicates success
    } catch (error) {
      console.error(error);
      setLoading(false);
      return false; // Indicates failure
    }
  }, [setLoading, dispatch, setDataFetched]);

  // const handleAppointmentDetail = async (id) => {

  //   let { payload } = await dispatch(getAppointmentDetails(id))       

  //   if (payload?.id) {
  //     setAppointmentDetail(payload)
  //   } else {
  //     setAppointmentDetail(null) 
  //   }
  // }
  function ButtonWithIcon() {
    return (
      <Button
        variant="outlined"
        color="inherit"
        sx={{ mt: { md: 0, xs: 2 }, ml: { md: 2, xs: 1 } }}
        className={classes.buttons}

      >
        <img src={cloudIcon} alt="" className={classes.buttonIcons} /> Download Excel
      </Button>
    );
  }


  const handleAppointmentClick = (url) => {
    navigate(url) 
  }
  const today = new Date();
  const formik = useFormik({
    initialValues: {
      start_date: today,  
      end_date: today,

    }, 
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  });


  const handleRowClick = useCallback(async (startDate, endDate, type) => {
    console.log("dates", startDate, endDate);

    // Helper function to format the date in "YYYY-MM-DD" format
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);          
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    
    // Format the dates   
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    setSelectedRow(true);

    const payload = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      date_type: selectedRadio,
      type: type,
      staff_id :staff,
    };
    console.log("payload",payload)
    try {
      setloadingSecondTable(true);
      await dispatch(MemberBookings(payload));
      setloadingSecondTable(false);
    } catch (error) {
      console.error(error);
      setloadingSecondTable(false);     
    }
}, [selectedRadio, setloadingSecondTable, dispatch])

  // useEffect(() => {  
  //   if (state?.value) {
  //     let payload = {}
  //     if (state?.value == 'Today') {
  //       payload = { start_date: new Date(), end_date: new Date(), date_type: selectedRadio }
  //     } else if (state?.value == 'Weekly') {
  //       payload = { start_date: moment().startOf('week'), end_date: moment().endOf('week'), date_type: selectedRadio }
  //     } else if (state?.value == 'Monthly') {
  //       payload = { start_date: moment().startOf('month'), end_date: moment().endOf('month'), date_type: selectedRadio ,staff_id:staff}
  //     }
  //     handleSubmit(payload)                           
  //   }
  // }, [])

  const handlePrint = () => {
           
    const printWindow = window.open('', '');
    const printableContent = document.getElementById('printableTable').outerHTML;
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Table</title>
          <style>
            /* Add your CSS styles for printing here */
            /* For example, you can hide unwanted elements */
            body { margin: 0; }
          </style>
        </head>  
        <body>
          ${printableContent}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }

  useEffect(() => {
    const fetchData = async () => {
      console.log("values" ,state?.value)
      if (state?.value && shouldFetch ) {
        let startDateValue = state.startDate ? moment(state.startDate).format('YYYY-MM-DD') : null;
        let endDateValue = state.endDate ? moment(state.endDate).format('YYYY-MM-DD') : null;

        // Update startDate and endDate state for DateRange filter
        formik.setFieldValue('start_date', startDateValue);
        formik.setFieldValue('end_date', endDateValue)
    
        let payload = {
          start_date: startDateValue,   
          end_date: endDateValue,
          date_type: selectedRadio,
          type: state.value,
          staff_id:staff,
        };

        console.log("data",payload)
        const isSubmitSuccessful = await handleSubmit(payload);
        if (isSubmitSuccessful) {
          await handleRowClick(payload.start_date, payload.end_date, payload.type); // Ensure correct arguments
        }
        setShouldFetch(false);
      }
    };   

    fetchData();
  }, [state, selectedRadio, handleSubmit, handleRowClick, setStartDate, setEndDate]);

  return (
    <>
      <Container maxWidth="xl" sx={{ ml: 0, p: "0 !important" }}>
        <Grid
          container
          sx={{
            alignItems: { md: "start", xs: "center" },
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {/* <FormControl
                sx={{
                  width: { xs: "100%", sm: "unset", md: "unset" },
                  mt: { xs: 2, sm: 1 },
                }}
              >
                <FormLabel>Search</FormLabel>  
                <TextField
                  variant="outlined"
                  name={"name"}
                  sx={inputStyle}
                  placeholder={"Customer Name"}
                  value={search}
                  onChange={handleSearchChange}
                />
              </FormControl> */}

              <FormControl
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },
                  mt: { xs: 2, sm: 1 },
                }}
              >
                <FormLabel>Select Date</FormLabel>
                <DateRange
                  setStartDate={(date) => formik.setFieldValue('start_date', date)}
                  startDate={formik.values.start_date}
                  setEndDate={(date) => formik.setFieldValue('end_date', date)}
                  endDate={formik.values.end_date}
                  onBlur={formik.handleBlur}
                />
                {(formik.touched.start_date || formik.touched.end_date) && (formik.errors.start_date || formik.errors.end_date) ? (
                  <div style={{ color: "red" }}>Date field is required</div>
                ) : null}  
              </FormControl>  
              {/* <FormControl    
                sx={{    
                  minWidth: { xs: "100%", sm: "220px" },  
                  maxWidth: { xs: "100%", sm: "220px" },   
                  mt: { xs: 2, sm: 1 },  
                }}
              >     
                <FormLabel>Staff</FormLabel>  
                <Select 
                  value={staff}             
                  onChange={handleChange}
                  displayEmpty
                  sx={selectStyle}
                >
                   {crmStaffs?.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>     
                  ))}   
                </Select>
              </FormControl>    */}
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={selectedRadio}
                  onChange={handleRadioChange}   
                  defaultValue="top"
                  sx={{ mt: 2, ml: 2 }}
                >
                  <FormControlLabel
                    value="Created"
                    control={<Radio />}
                    sx={{ mr: { xs: 1, md: 5 } }}
                    label="By Created Date"
                  />
                  <FormControlLabel
                    value="Member"
                    control={<Radio />}
                    label="By Member  Date"
                  />
                </RadioGroup>
                <Box sx={{ mt: 2, ml: 3 }}>
                  <Button variant="contained" color="primary" type="submit">
                    Fetch Report
                  </Button>
                </Box>
              </Box>

            </Grid>
            <Grid item lg={7.8} md={6.9} xs={12} sx={{ display: 'flex' }}>

            </Grid>

          </form>
        </Grid>

      </Container>
      {loading ? ( 
        <Waveloading />
      ) : dataFetched ? (
        <>

          <Box sx={{ ml: 0, p: "0 !important", mt:4 }}>

            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between", mt: 5 }}
            >
              <Grid item lg={5.9} md={5.9} xs={12}>
                <Box>
                  <Grid
                    item
                    xs={12}
                    className={classes.odd}
                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "NoOfUsers")}
      
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >
                        Number of users:
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
                          fontSize:"20px",
                          fontWeight: "bold",
                        }}
                      >
                        {memberCount?.counts?.no_of_users}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid

                    item
                    xs={12}
                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "NoOfMembers69")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >
                        Number of Members($69):  
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
 
 fontSize:"20px",                         fontWeight: "bold",
                        }}
                      >
                        {memberCount?.counts?.plan_69_member}

                      </Typography>
                    </Box>
                  </Grid>
                  <Grid

item
xs={12}

className={classes.odd}
sx={{
  fontWeight: "bold",
  background: "#fff",
  borderRadius: "8.4769px",
  borderBottom: "1px solid rgb(226, 232, 240)",
}}
>
<Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "20px ",
  }}
  onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "NoOfMembers149")}
>
  <Typography
    sx={{
      display: "flex",
      alignItems: "center",
      color: "#2F80ED",
      textDecoration: "underline",
    }}
  >
    Number of Member ($149):
  </Typography>
  <Typography
    sx={{
      display: "flex",
      alignItems: "center",
      color: "#000000",
 
 fontSize:"20px",     fontWeight: "bold",
    }}
  >
    {memberCount?.counts?.plan_149_member}

  </Typography>
</Box>
</Grid>
                  <Grid

                    item
                    xs={12}

                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "NoOfMembers199")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >
                        Number of Members($199):
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
                             fontSize:"20px",       
                            fontWeight: "bold",
                        }}
                      >
                        {memberCount?.counts?.plan_199_member}

                      </Typography>
                    </Box>
                  </Grid>
                  <Grid

                    item
                    xs={12}
                    className={classes.odd}
                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "DeclinedMembers")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >
                        Declined Members:
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
 
 fontSize:"20px",                         fontWeight: "bold",
                        }}
                      >
                        {memberCount?.counts?.declined_member}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid

                    item
                    xs={12}

                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "ALLCancelledMembers")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >
                        All Cancelled Members:
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
 
 fontSize:"20px",                         fontWeight: "bold",
                        }}
                      >
                        {memberCount?.counts?.all_cancelled_member}

                      </Typography>
                    </Box>
                  </Grid>
                

                </Box>
              </Grid>
              <Grid item lg={5.9} md={5.9} xs={12} sx={{ mt: { md: 0, xs: 3 } }}>
                <Box>

                  <Grid

                    item
                    xs={12}
                    className={classes.odd}
                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "AllMember")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >
                        Total Members($69 +$199+ $149):
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
 
 fontSize:"20px",                         fontWeight: "bold",
                        }}
                      >
                        {memberCount?.counts?.total_member}

                      </Typography>
                    </Box>
                  </Grid>
                  <Grid

                    item
                    xs={12}

                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "CancelledMembers")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >
                        Cancelled Members:
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
 
 fontSize:"20px",                         fontWeight: "bold",
                        }}
                      >

                        {memberCount?.counts?.total_cancelled_members}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid

                    item
                    xs={12}
                    className={classes.odd}
                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "CancelledMembers69")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >
                        Total Cancel Member($69):
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
 
 fontSize:"20px",                         fontWeight: "bold",
                        }}
                      >
                        {memberCount?.counts?.plan_69_cancel_member}

                      </Typography>
                    </Box>
                  </Grid>
                  <Grid

item
xs={12}

sx={{
  fontWeight: "bold",
  background: "#fff",
  borderRadius: "8.4769px",
  borderBottom: "1px solid rgb(226, 232, 240)",
}}
>
<Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "20px ",
  }}
  onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "CancelledMembers149")}
>
  <Typography
    sx={{  
      display: "flex",
      alignItems: "center",
      color: "#2F80ED",
      textDecoration: "underline",
    }}
  >
  Total  Cancel Members ($149):
  </Typography>  
  <Typography
    sx={{
      display: "flex",   
      alignItems: "center",  
      color: "#000000",
      fontSize:"20px",     
      fontWeight: "bold",
    }}
  >
    {memberCount?.counts?.plan_149_cancel_member}
   
  </Typography>
</Box>
</Grid>
                  <Grid

                    item
                    xs={12}
                    className={classes.odd}
                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "CancelledMembers199")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >   
                        Total Cancel Member($199):
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
 
 fontSize:"20px",                         fontWeight: "bold",
                        }}
                      >
                        {memberCount?.counts?.plan_199_cancel_member}

                      </Typography>
                    </Box>   
                  </Grid>    

                  <Grid

                    item
                    xs={12}

                    sx={{
                      fontWeight: "bold",
                      background: "#fff",
                      borderRadius: "8.4769px",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        padding: "20px ",
                      }}
                      onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, "TotalRenewMember")}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#2F80ED",
                          textDecoration: "underline",
                        }}
                      >
                        Total Renewal Member:
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#000000",
 
 fontSize:"20px",                         fontWeight: "bold",
                        }}
                      >
                        {memberCount?.counts?.total_renewal_member}

                      </Typography>
                    </Box>
                  </Grid>
                 
                </Box>
              </Grid>
            </Grid>



          </Box>

          {/* Buttons */}


          {selectedRow ? (

            <Container maxWidth="xl" sx={{ marginLeft: 0, mt: 4, p: "0 !important" }}>



              <Box
                sx={{
                  display: "flex",
                  justifyContent: { md: "end", xs: "start" },
                  flexWrap: "wrap",
                }}
              >

                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ mt: { md: 0, xs: 2 }, ml: { md: 2, xs: 1 } }}
                  className={classes.buttons}
                  onClick={handlePrint}
                >
                  <img src={printer} alt="" className={classes.buttonIcons} /> Print
                </Button>
                {/* <div style={{ border: "none !important" }}> */}
                <ReactHTMLTableToExcel
                    
                 table="saleReportPrint"
                 filename="MemberReport"   
                 sheet="tablexlsx"
                  buttonText={<ButtonWithIcon />}
                  className="custom-excel-button"

                />
                {/* </div> */}
            {/*    <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ mt: { md: 0, xs: 2 }, ml: { md: 2, xs: 1 } }}
                  className={classes.buttons}
                >
                  <img src={mail} alt="" className={classes.buttonIcons} /> Email
                  Report
                </Button>*/}
              </Box>
              {loadingSecondTable ? (
                <Waveloading />
              ) : (
                <TableContainer
                  component={Paper}
                  sx={{ mt: 2, boxShadow: "none", maxHeight: "650px" }}
                >
                  <Table sx={{}} aria-label="simple table" id="printableTable">
                    <TableHead className={classes.tableHead}>
                      <TableRow sx={{}}> 

                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: "700",
                            borderBottom: 0,
                            minWidth: "160px",
                            width: "100px",
                          }}
                        >
                          Reference ID
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: "700", borderBottom: 0 }}
                        >
                          Customer Name
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: "700", borderBottom: 0 }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: "700", borderBottom: 0 }}
                        >
                          Phone
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: "700", borderBottom: 0 }}
                        >
                          Registration Date
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: "700", borderBottom: 0 }}
                        >
                        Renew Date
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: "700", borderBottom: 0 }}
                        >
                          Membership Plan
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontWeight: "700", borderBottom: 0 }}
                        >
                          Wallet Balance
                        </TableCell>
                      </TableRow>
                    </TableHead> 
                    <TableBody>
                      {memberBooking?.members?.length ? (memberBooking?.members?.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          className={classes.odd}
                        > 
                          <TableCell   
                            className={classes.tableLink}   
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/profile-info/${item?.id}`)}
                          >
                            {item?.id}
                          </TableCell>

                          <TableCell align="left">
                            <div style={{display:"flex",direction:"column"}}>

                          
                            {item?.customer_batch && item?.customer_batch !== "" ? (
                              <>
                                {item?.customer_batch !== "New User" ? (
                                  <>
                                    {item?.customer_batch.includes("Expire/Cancel") ? (   
                                      <div style={{ display: "flex", alignItems: "start" }}> 
                                        <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                                        <Typography style={{ color: "#ff0000" }}>
                                          {item?.customer_batch}
                                        </Typography>
                                      </div>
                                    ) : (
                                      <>
                                        {item?.customer_batch.includes("Declined") ? (
                                          <div style={{ display: "flex", alignItems: "start" }}>
                                            <img style={{ marginTop: '4px' }} src={yellowRibbon} alt={'member'} />
                                            <Typography style={{ color: "#dcd90a" }}>
                                              {item?.customer_batch}
                                            </Typography>
                                          </div>
                                        ) : (
                                          <div style={{ display: "flex", alignItems: "start" }}>
                                            <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                            <Typography style={{ color: "#219653" }}>
                                              {item?.customer_batch}
                                            </Typography>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <Typography style={{ color: "#09ABF1" }}>
                                    {item?.customer_batch}
                                  </Typography>
                                )}
                              </>
                            ) : (
                              // Render this if item?.customer_batch is empty or not set
                              ""
                            )}
                            <Typography className={classes.tableLink} sx={{ cursor: 'pointer' }} onClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/profile-info/${item?.id}`)}>
                              {item?.name}
                            </Typography>
                            </div>
                          </TableCell>


                          <TableCell align="left" className={classes.tableLink}>
                            {item?.email}  
                          </TableCell>
                          <TableCell align="left"> {item?.phone}</TableCell>
                          <TableCell align="left">{item?.created_at}</TableCell>
                          <TableCell align="left">{item?.member_date}</TableCell>
                          <TableCell align="left">{item?.membership_plan}</TableCell>
                          <TableCell align="left">${item?.my_wallet}</TableCell>
                        </TableRow>
                      ))
                      ) : (
                        <TableRow>
                          <TableCell
                            sx={{
                              height: '300px'
                            }}
                            colSpan={9}
                            align="center"
                          >
                            Member Report data not found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <Table
                      aria-label="simple table"
                      id="saleReportPrint"
                      style={{ display: 'none' }}
                  > 
                    <TableHead className={classes.tableHead}>
                    <TableRow sx={{}}>

<TableCell
  align="left"
  sx={{
    fontWeight: "700",
    borderBottom: 0,
    minWidth: "160px",
    width: "100px",
  }}
>
  Reference ID
</TableCell>
<TableCell
                            align="left"
                            sx={{ fontWeight: "700", borderBottom: 0 }}
                        >

                          Customer Badge
                        </TableCell>

<TableCell
  align="left"
  sx={{ fontWeight: "700", borderBottom: 0 }}
>
  Customer Name
</TableCell>
<TableCell
  align="left"
  sx={{ fontWeight: "700", borderBottom: 0 }}
>
  Email
</TableCell>
<TableCell
  align="left"
  sx={{ fontWeight: "700", borderBottom: 0 }}
>
  Phone
</TableCell>
<TableCell
  align="left"
  sx={{ fontWeight: "700", borderBottom: 0 }}
>
  Registration Date
</TableCell>
<TableCell
  align="left"
  sx={{ fontWeight: "700", borderBottom: 0 }}
>
  Member Date
</TableCell>
<TableCell
  align="left"
  sx={{ fontWeight: "700", borderBottom: 0 }}
>
  Membership Plan
</TableCell>
<TableCell
  align="left"
  sx={{ fontWeight: "700", borderBottom: 0 }}
>
  Wallet Balance
</TableCell>
</TableRow>
                    </TableHead>

                    <TableBody sx={{
                      mt: 5,
                      boxShadow: "none",
                      maxHeight: 'calc(100vh - 250px)', // Adjust the height as needed
                      overflowY: 'auto', // Add this line to make the content scrollable

                    }}>
                      
                      {memberBooking?.members?.length ? (
                        memberBooking?.members?.map((item, index)  => (
                              <TableRow
                                  key={index}
                                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                  className={classes.odd}
                              >
                                <TableCell align="left">
                                  {item?.id}
                                </TableCell>
                                <TableCell align="left">

                                {item?.customer_batch}
                                </TableCell>
                                <TableCell align="left">

                                {item?.name}
                                </TableCell>
                                <TableCell align="left" className={classes.tableLink}>
                            {item?.email}  
                          </TableCell>
                          <TableCell align="left"> {item?.phone}</TableCell>
                          <TableCell align="left">{item?.created_at}</TableCell>
                          <TableCell align="left">{item?.member_date}</TableCell>
                          <TableCell align="left">{item?.membership_plan}</TableCell>
                          <TableCell align="left">{item?.my_wallet}</TableCell>
                              </TableRow>))
                      ) : (
                          <TableRow>
                            <TableCell
                                sx={{
                                  height: '300px'
                                }}
                                colSpan={9}
                                align="center"
                            >
                              no data
                            </TableCell>
                          </TableRow>
                      )}
                    </TableBody>


                  </Table>
                </TableContainer>
              )}



            </Container>
          ) : ""}
        </>
      ) : ""}
      <CustomModal
        customWidth={"1000px"}
        open={appointmentDetails !== null}
        setOpen={() => setAppointmentDetail(null)}
      >
        <Box>
          <AppointmentDetails appointmentDetails={appointmentDetails} />
        </Box>
      </CustomModal>
    </>

  );
};

export default MemberReport;


