import React, {useEffect, useMemo} from 'react';
import {useDropzone} from "react-dropzone";
import {Avatar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import upload from '../../assets/images/cloud-upload-outline.png'

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: '8px',
    borderColor: '#C4C4C4',
    height:'149px',
    width:'149px',
    maxWidth:'149px',
    borderStyle: 'dashed',
    backgroundColor: '#F1F5F9',
    color: '#202124',
    outline: 'none',
    cursor:'pointer',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
const ImageUploader = ({handleSave,acceptFiles}) => {
    const {acceptedFiles, isFocused,isDragAccept,getRootProps, isDragReject,getInputProps} = useDropzone({
        accept: acceptFiles || {
            'image/*': [],
        }
    });


    useEffect(()=>{
        if (acceptedFiles?.length){
            handleSave(acceptedFiles)
        }
    },[acceptedFiles])

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <Box {...getRootProps({style})}>
            <input {...getInputProps()}  />
            <Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <Avatar src={upload}/>
                <Typography sx={{fontSize:'16px',color:'#000',textAlign:'center'}}>Drop Files to upload</Typography>
            </Box>
        </Box>
    );
};

export default ImageUploader;