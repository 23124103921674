import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getClinicServiceTypes, storeClinicServiceType} from "../../../../store/clinics/clinicSettings/service-types";
import Loader from "../../../../components/loader/loader";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import {labelStyle} from "../../../../components/form/formInput";

import SubmitButton from "../../../../components/custom-button/submitButton";
import * as yup from "yup";
import {useFormik} from "formik";
import Waveloading from '../../../loading/Waveloading';

const ServiceType = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()


    const serviceType = useSelector(state => state.clinicServiceTypesStore?.servicesTypes)
    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)


    const validationSchema = yup.object({
        service_type: yup.string().required('Events type  is required'),

    });


    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            service_type: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)

            await dispatch(storeClinicServiceType({...data,"provider_id":clinicData?.id}))
            setLoading(false)
        },
    });

    useEffect(() => {
        (async () => {
            if (!serviceType?.length){
                setLoading(true)
            }
            await dispatch(getClinicServiceTypes())
            setLoading(false)
        })()
    }, [dispatch])

    useEffect(()=>{
        if (clinicData?.id && serviceType?.length){
            if (clinicData?.service_type_provider?.length> 0){
                try {
                    let service = clinicData?.service_type_provider[0]
                    setFieldValue('service_type',service?.service_type || '')
                }catch (e) {
                    console.log(e)
                }
            }
        }
    },[clinicData,clinicData?.id,serviceType?.length])


    return (
        <Box>
            {loading ? <Waveloading/> :
                <Grid container p={5}>
                    <Grid item xs={12}>
                        {serviceType?.length > 0 ? <form onSubmit={handleSubmit}>
                            <FormControl sx={{mb: '16px'}}>
                                <FormLabel sx={labelStyle}>Choose a Service Type</FormLabel>
                                <RadioGroup
                                    row
                                    name="service_type"
                                    value={values?.service_type}
                                    onChange={handleChange}
                                >
                                    {serviceType.map((item,index)=>(
                                        <FormControlLabel key={index} sx={{mr:'60px'}} value={item} control={<Radio/>} label={item.replace("_"," ")}/>
                                    ))}
                                </RadioGroup>
                                {errors?.service_type && touched?.service_type && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors?.service_type}</FormHelperText>}
                            </FormControl>
                            <Box sx={{display: 'flex',justifyContent:'flex-end', alignItems: 'center'}}>
                                <SubmitButton val={'Update Service Types'}/>
                            </Box>
                        </form>:
                            <Box sx={{textAlign:'center',width:"100%"}}>
                                <Typography variant={"h5"} >
                                    No services Available
                                </Typography>
                            </Box>
                        }
                    </Grid>
                </Grid>
            }
        </Box>
    );
};

export default ServiceType;