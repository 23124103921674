import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiHeaders } from "../../lib/apiHeader";
import axios from "axios";
import apis from "../../lib/apis/apis";

export const getAllNotificationEvents = createAsyncThunk(
  "getAllNotificationEvents",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get(apis.get_all_notification_templates_events, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      }
      if (response?.data?.success === false) {
        return {
          data: response.data,     
          errors: true,
        };
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        return {
          data: [],
          errors: err?.response?.data?.errors,
        };
      }
    }
  }
);
// get placeholder data 
export const getAllPlaceData = createAsyncThunk(
  "getAllPlaceData",   
  async (data, { dispatch }) => {
    try {
      const response = await axios.get(apis.get_placeholder_value, {
        headers: apiHeaders(),
      });

      if (response?.data?.success=== 'true'){
        return response?.data?.data
      }else {
        return {}
      }
    }catch (err){
        return {}
    }      
  }
);

export const getNotificationTemplateById = createAsyncThunk('getNotificationTemplateById', async (event_id,{getState}) => {
  try {
    const response = await axios.get(apis.get_notification_template_by_id+event_id, {
      headers: apiHeaders()
    })
    if (response?.data?.success=== 'true'){
      return response?.data?.data
    }else {
      return {}
    }
  }catch (err){
      return {}
  }
})

export const updateNotificationTemplate = createAsyncThunk('updateNotificationTemplate', async (data,{dispatch}) => {
  try {
    const response = await axios.post(apis.update_notification_template, {...data},{
      headers: apiHeaders()
    })

    if (response?.data?.success==='true'){
      await dispatch(getNotificationTemplateById(data?.notification_event_id))
      return  response.data
    }else {
      return response?.data
    }
  }catch (err){
    return err?.response
  }
})

export const notificationTemplateSlice = createSlice({
  name: "notificationTemplateSlice",
  initialState: {
    notificationEvents: [],
    notificationTemplates: {},
    items:[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllNotificationEvents.fulfilled, (state, action) => {
      state.notificationEvents = action.payload;
    });
    builder.addCase(getNotificationTemplateById.fulfilled, (state, action) => {
      state.notificationTemplates = action.payload;
    });
    builder.addCase(getAllPlaceData.fulfilled, (state, action) => {
      state.items = action.payload;
    });
    
  },
});

export default notificationTemplateSlice.reducer;
