import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./style";
import { scrollCSS } from "../../App";
import DateRange from "../date-range/date-range";
import DateRangePicker from "../date-range/DatePicker";
import moment from "moment";

const WeeklyHours = (props, onDatesChange) => {
  const { saleshour, salesData, fetchData } = props;
  const startOfWeek = moment().startOf("isoWeek").toDate();
  const endOfWeek = moment().endOf("isoWeek").toDate();
  const [localStartDate, setLocalStartDate] = useState(startOfWeek);
  const [localEndDate, setLocalEndDate] = useState(endOfWeek);

    
    
      // Fetch data function

  const fetchDatafun = async () => {
    const response = await props.fetchData(localStartDate, localEndDate);
    // Process response or perform further actions
  };
  const handleDateChange = (start, end) => {
    setLocalStartDate(start);
    setLocalEndDate(end);
    props.fetchData(start, end);
    // fetchDatafun()
  
  };

  // Call fetchData on component mount
  // useEffect(() => {
  //   fetchDatafun();
  // }, []); 

  const handleButtonClick = async () => {
    await fetchDatafun();
  };

  const color =
    saleshour === "Weekly SALES/HOURS"
      ? "rgb(46, 125, 50)" // Green
      : "#000"; // Default color

  const background = saleshour === "Weekly SALES/HOURS" ? "#E8F5E9" : "#E8F5E9"; // Default color

  const classes = useStyles(props);

  function createData(name, customerName, email) {
    return { name, customerName, email };
  }

  const rows = [
    createData(
      salesData?.total_appointment,
      salesData?.forecasted_sale,
      salesData?.time
    ),
  ];

  const completedRows = [
    createData(
      salesData?.total_completed,
      salesData?.completed_sale,
      salesData?.completed_time
    ),
  ];
  const cancelRows = [
    createData(
      salesData?.total_cancel,
      salesData?.cancel_sale,
      salesData?.cancel_time
    ),
  ];

  return (
    <>

    <Box sx={{ padding: "40px 10px" , }}>
      <Box
        sx={{
          position: "relative",
          padding: "20px 0",
          borderRadius: "8px",
          overflow: "hidden",
          background: background,
          height: "auto",
          "@media (min-width:1600px)": { padding: "20px 10px" , height:'700px'},
          "@media (max-width:390px)": { height: "auto" },
          "@media (min-width:1200px) and (max-width: 1350px) ": {
            height: "265px",
          },
        }}
      >
        {/* Table */}
        <Box sx={{ height:{sm:'155px', xs:'130px'} }}>
          <Typography
            variant="h5"
            sx={{
              fontFamily: "helvetica-lt-std-bold",
              color: color,
              padding: "20px 20px",
            }}
          >
            {saleshour}
          </Typography>


        <Box sx={{marginLeft:"9px" , display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:{lg:'center', xs:'start'}, '@media (min-width:1600px)': {flexDirection:'row'} }}>
        <DateRangePicker
  widthMember="100%"
  smWidth="60%"
        setStartDate={setLocalStartDate}
        setEndDate={setLocalEndDate}    
        startDate={localStartDate}
        endDate={localEndDate}
        onChange={handleDateChange} // Add this prop to your DateRange component if it supports it
      />
           {/* <Button sx={{height:"fit-content" , marginRight:"3px" , '@media (min-width:1600px)': {marginTop:'0'}, mt:2 , width:"fit-content"}} variant="contained" onClick={handleButtonClick}>
          Fetch Report
        </Button> */}
        </Box>
        </Box>
        <TableContainer
        component={Paper}
        sx={{
          mt: 5,
          boxShadow: "none",
          maxHeight: "650px",
          overflowY: "auto",
          ...scrollCSS,
        }}
      >
        <Table sx={{ minWidth: "350px" }} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: "18px",
                  borderBottom: 0,
                  width:"35%"
                }}
              >
                Total Appointments
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: "18px",
                  borderBottom: 0,
                  width:"35%"
                }}
              >
                Forecasted Sales
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: "18px",
                  borderBottom: 0,
                  width:"35%"
                }}
              >
                Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  $ {row.customerName}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Table sx={{ minWidth: "350px" }} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: "18px",
                  borderBottom: 0,
                  width: "35%",
                }}
              >
                Total Completed
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: "18px",
                  borderBottom: 0,
                  width: "35%",
                }}
              >
                Sales
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: "18px",
                  borderBottom: 0,
                  width: "30%",
                }}
              >
                Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {completedRows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                 $ {row.customerName}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Table sx={{ minWidth: "350px" }} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: "18px",
                  borderBottom: 0,
                  width: "35%",
                }}
              >
                Total Cancel
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: "18px",
                  borderBottom: 0,
                  width: "35%",
                }}
              >
                Cancel Sales
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: "18px",
                  borderBottom: 0,
                  width: "30%",
                }}
              >
                Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cancelRows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                 $ {row.customerName}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        </Box>


      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count=""
        rowsPerPage=""
        page=""
        onPageChange=""
        onRowsPerPageChange=""
      /> */}

      </Box>
      </>
  )

};

export default WeeklyHours;
