import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Container,
  Grid,
  CardMedia,
  InputAdornment,
  Slide,
} from "@mui/material";
import useStyles from "./style";
import PersonIcon from "@mui/icons-material/Person";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import planImage from '../../assets/images/plan image1.png';
import planImage1 from '../../assets/images/plan image2.png';
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import thread from '../../assets/images/thread-lift.png'
import medical from '../../assets/images/medical.png'
import skin from '../../assets/images/skin-condition.png'
import laser from '../../assets/images/laser-hair.png'
import wellness from '../../assets/images/wellness.png'
import tattoo from '../../assets/images/tattoo-removal.png'
import surgical from '../../assets/images/non-surgical.png'
import therapeutic from '../../assets/images/therapeutic.png'
import ppr from '../../assets/images/ppr-hair.png'
import carbon from '../../assets/images/carbon-laser.png'
import staff1 from '../../assets/images/fozia-johri.png'
import location from '../../assets/images/vancouver.png'
import homeoutline from '../../assets/images/homeoutline.png'
import booking from '../../assets/images/Vector (Stroke).png'
import menu from '../../assets/images/homeIcon.png'
import plan from '../../assets/images/wallet2.png'
import defaultservice from "../../assets/images/defaultservice.jpg"
import profile from '../../assets/images/user.png'
import Slider from "react-slick";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './style.css';
import { AllClinicServices, AllStaff, Servicepromo, ServicesPlan, SubCategory, getAllClinic, getsubscriptionPlan } from "../../store/home";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";


import Login from "../login";
import Waveloading from "../loading/Waveloading";
import Footer from "./Footer";
import { InView } from "react-intersection-observer";

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedLocation = location?.state?.selectedLocation;
  const homeStore = useSelector(state => state?.homeStore)
  const [loginOpen, setOpenLogin] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
    const {clinic,SubscriptionPlan, Services,ServicePlan,staff} = homeStore
 const selectedname=location?.state?.selectedLocation      
 const [showLogin, setShowLogin] = useState(false);
 
 console.log("clinic name of all",selectedname)
 const getSelectedClinic = () => {
  // Retrieve the clinic information as a string
  const clinicInfoStr = localStorage.getItem('selected');
  
  // Parse the string back into an object
  const clinicInfo = JSON.parse(clinicInfoStr);
  
  if (clinicInfo) {
      console.log('Selected Clinic:', clinicInfo);
      // Now you have access to the entire object
      return clinicInfo;
  } else {
      // Handle the case where no clinic has been selected yet
      return null;
  }
};

const selected = getSelectedClinic();
console.log("Selected Clinic Info:", selected);
// const { id,name ,clinicInfo } = getSelectedClinic() || {};
 const id= localStorage.getItem('selectedLocationId')  
  const [activeButton, setActiveButton] = useState(localStorage.getItem('activeButton') || 'home');
  const selectedLocationString = localStorage.getItem('selectedLocation');
  const SelectedLocationname = selectedLocationString ? JSON.parse(selectedLocationString) : null;
  const [selectedClinic, setSelectedClinic] = useState(selectedLocation ||selected || selectedname|| SelectedLocationname  ||null);
  const [loading, setLoading] = useState(false);  
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const localStorageKey = 'selectedLocation';   
  const newValue = selectedClinic; 
  const authStore = useSelector((state) => state?.authStore);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (event) => { 
   
    setSearchTerm(event.target.value.toLowerCase());
  }     
  const { user } = authStore;
  if (user?.id) {
        localStorage.setItem('selectedLocationId', user?.defaultProvider?.id);
    }

  var settings = {
    dots: true,
    infinite: false,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    padding:10,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1.5,
          initialSlide: 1.5
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1.2
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const backhandler= (selectedClinic ) => {
    navigate(`/location`);     
  };
  localStorage.setItem(localStorageKey, JSON.stringify(newValue)); 

  useEffect(() => {
    (async () => {
         await dispatch(getAllClinic())
       
    })()
},[])  

const clinicId = selectedClinic ? selectedClinic.id : null;


const shouldRedirectToLogin = !user?.id || user?.role_id !== 3;
useEffect(() => {
  if ((selectedClinic && selectedClinic.id) || id) {   
    setLoading(true);
    dispatch(getsubscriptionPlan(clinicId||id))
  
   
   
   
    // AllStaff
      .then((response) => {     
       setLoading(false);
       setDataFetched(true); 
      })
      .catch((error) => {
        console.error('Error fetching subscription plan:', error);
       
      });
  }
}, [dispatch, selectedClinic]);

const targetRef = useRef(null);
const servicePlanRef =useRef(null)
const StaffRef = useRef(null)
useEffect(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
    
        if ((selectedClinic && selectedClinic.id)) {
        setLoading(true)
        dispatch(AllClinicServices(clinicId||id))
        setLoading(false);
        }
       
      }
    });
  }, {
    root: null, // assuming the viewport; adjust as necessary
    rootMargin: '0px',
    threshold: 0.1 // Adjust this value based on when you want the callback to fire
  });

  if (targetRef.current) {
    observer.observe(targetRef.current);
  }

  return () => {
    if (targetRef.current) {
      observer.unobserve(targetRef.current);
    }
  };
}, [dispatch, selectedClinic]);
useEffect(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Trigger API calls when the <Grid> container comes into view
        if ((selectedClinic && selectedClinic.id)) {
          dispatch(ServicesPlan(clinicId||id))
        }
      }
    });
  }, {
    root: null, // assuming the viewport; adjust as necessary
    rootMargin: '0px',
    threshold: 0.1 // Adjust this value based on when you want the callback to fire
  });

  if (servicePlanRef.current) {
    observer.observe(servicePlanRef.current);
  }

  return () => {
    if (servicePlanRef.current) {
      observer.unobserve(servicePlanRef.current);
    }
  };
}, [dispatch, selectedClinic]);

useEffect(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {  
        // Trigger API calls when the <Grid> container comes into view
        if ((selectedClinic && selectedClinic.id)) {
          setLoading(true)   
          dispatch(AllStaff(clinicId||id))  
          setLoading(false)
        }
      }    
  
    });  
  }, {
    root: null, // assuming the viewport; adjust as necessaryg
    rootMargin: '0px',
    threshold: 0.1 // Adjust this value based on when you want the callback to fire
  });
   
  if (StaffRef.current) {
    observer.observe(StaffRef.current)
  }

  return () => {
    if (StaffRef.current) {
      observer.unobserve(StaffRef.current);
    }
  };
}, [dispatch, selectedClinic]);


const subscriptionPlanHandel = (plan) => {  
  console.log("check",plan)
  setActiveButton('member');   
  if (shouldRedirectToLogin) {            
    setOpenLogin(true)
  } else {
    navigate(`/user/membership`,{ state: { plan } });
  }
};



useEffect(() => {
    setLoading(true);
  const handlePlanRedirection = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("plan")) {
      const planprice = queryParams.get("plan");
      const planObject = SubscriptionPlan.find(plan => plan.plan_price === planprice);
      if (planObject) {
          setLoading(false);
          subscriptionPlanHandel(planObject);
      }
    }
  };

  if (SubscriptionPlan && SubscriptionPlan.length > 0) {
    handlePlanRedirection();
  }
}, [SubscriptionPlan, shouldRedirectToLogin]);

const handleCategoryClick = (Slug) => {
  navigate(`/Categories/${Slug}/${clinicId || id}`,{ state: { selectedClinic }});
}; 
useEffect(() => {
  const savedLocation = localStorage.getItem(localStorageKey);
  console.log("saved location" ,savedLocation)
  if (savedLocation) {
    setSelectedClinic(JSON.parse(savedLocation)); 
  }   
}, []);   
  
   
let capitalizedUserName = "";
if (user?.name) {    
  capitalizedUserName = user.name.charAt(0).toUpperCase() + user.name.slice(1);
}

const clinicOptions = clinic.data && Array.isArray(clinic.data)
  ? clinic.data.map((clinic) => ({
      name: clinic?.name, 
      id: clinic?.id,     
    }))
  : [];

  const handleClinicSelect = (event, newValue) => {
      localStorage.setItem('selectedLocationId', newValue?.id);     
      setSelectedClinic(newValue);  
      localStorage.setItem(localStorageKey, JSON.stringify(newValue));
  };
const selectedLocationId = localStorage.getItem('selectedLocationId');
    if (selectedClinic === null && id === null) {
        navigate("/location"); 
        return null;        
    }

console.log("selected clinic",selectedClinic)

const handleBoxClick = () => {  
   
  if (shouldRedirectToLogin) {            
    setOpenLogin(true)
  } else {
  
    navigate('/user/bookings');
  }
  setActiveButton('booking');
  localStorage.setItem('activeButton', 'booking');
};
   
const handleplan = () => {
  setActiveButton('member');
  if (shouldRedirectToLogin) {
    setOpenLogin(true)
  } else {
    setActiveButton('member');
    navigate(`/user/membership`,{ state: { selectedClinic }});
  }
};
const handlePlanClick = (url) => {
        // Navigate to the URL
        navigate(url);
};
const handleHome = () => {
  setActiveButton('home');
  if (shouldRedirectToLogin) {
    setOpenLogin(true)
  } else {
   
    navigate('/home');;
  }
  setActiveButton('home');
  localStorage.setItem('activeButton', 'home');
 

};
const handleprofile = () => {
 
  if (shouldRedirectToLogin) {
    setOpenLogin(true)
   
  } else {
  
    navigate('/user/my-account');
   
   
  }
  setActiveButton('profile');
  localStorage.setItem('activeButton', 'profile');
};
const promohandel =()=>{
  dispatch(Servicepromo(clinicId||id)) 
  navigate( `/Promo`,{ state: { selectedClinic }})
}

  return (
    <>
      {loginOpen ? (
        <Box
          sx={{  
            position: "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: 0,
            zIndex: "1111111111111!important",
          }}
        >
          <Slide top>
            <Login loginOpen={loginOpen} setOpenLogin={setOpenLogin}  showFooter={true} />
          </Slide>
        </Box>
      ) : (
       "" 
      )}
   

    <Box sx={{ overflow:'hidden',position:'relative',display: { sm: "block", md: "none"} }}>
      <Container maxWidth="lg" sx={{ overflow:'hidden',position:'relative' }}>
        <Box sx={{ display: { sm: "block", md: "none" }, mt: 4 }}>
        <Autocomplete  
      className={classes.selectParent}
      disablePortal
      id="combo-box-demo"
      options={clinicOptions} 
      value={selectedClinic}
      onChange={handleClinicSelect}
      renderInput={(params) => (
        <TextField {...params} sx={{ label: { mt: '-10px',zIndex:0 } }} label="Select Your Clinic Location" />
      )}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, value) => option.id === (value.id || id)}
    />
    {loading ? <Waveloading fullSize /> : ''}
        <>
    
    {!user?.id || user?.role_id !== 3 ? (  
          
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Box >
            <KeyboardBackspaceIcon onClick={backhandler} />
          </Box>
          <PersonIcon  onClick={() => setOpenLogin(true)}/>

        </Box>
           
           ) : (
             ""
           )}
   
   {user?.id && user?.role_id === 3 ? (
         
             <Box sx={{ display: "flex", justifyContent: "space-between", my: 4, }}>
             <Box onClick={backhandler}>
                 <ArrowBackIcon />
             </Box>
             <Box sx={{ display: "flex", justifyContent: "space-between", }}>
             <Box sx={{ display: "flex", justifyContent: "end", }}>
             <AccountBalanceWalletIcon />
             <sup style={{ fontWeight:'600',left:'-19px',top:'-8px' }}>{user?.wallet_point || 0}</sup>
           </Box>
           <Box  onClick={handleprofile} sx={{ backgroundColor:'rgba(235, 213, 194, 1)',width:'30px',height:'30px',borderRadius:'20px',display:'flex',alignItems:'center',justifyContent:'center'  }}>
             <Typography sx={{ color:'#000',fontSize:'16px',fontWeight:'500'}}>{capitalizedUserName.charAt(0)} </Typography>
           </Box>

           </Box>
           </Box>
           ) : (
             ""
           )}

        <Typography variant="h5">Welcome</Typography>

        <Box className={classes.welcomeText} onClick={promohandel} sx={{cursor:"pointer"}}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Check out our latest promos  
          </Typography>
          <Typography variant="body1" >
            Book Now <TrendingFlatIcon />
          </Typography>
        </Box>
 <Box sx={{ mt: 3 }}> 
          <Typography variant="h6">Our Membership Plans</Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}> 
          {SubscriptionPlan && SubscriptionPlan.length > 0 ? (
              SubscriptionPlan.map((plan, index)=>(
      
           <Grid item 
           xs={(index === SubscriptionPlan.length - 1 && SubscriptionPlan.length % 2 !== 0) ? 12 : 6}
      sm={(index === SubscriptionPlan.length - 1 && SubscriptionPlan.length % 2 !== 0) ? 12 : 6} 
           key={plan.id}>
            <Box sx={{ position: 'relative' }}>
              <CardMedia
               onClick={() => subscriptionPlanHandel(plan)}
        
                sx={{ borderRadius: '6px' }}
                component="img"
                alt="Descriptive alt text"
                height="110"
                image={plan?.subscription_plan?.plan_image || defaultservice} 
              />
              <Typography
                variant="body2"
                // sx={{ position: 'absolute', bottom: '15px', color: '#fff', left: '10px' }}
             style={
              {
                margin: 0,
                fontFamily: 'helvetica-lt-std-roman',
                fontWeight: 400,
                fontSize: '0.875rem',
                lineHeight: 1.43,
                color: '#fff',
                position: 'absolute',
                bottom: 0,
                padding: '5px 10px',
                background: '#0000007a',
                borderRadius: '0 0 0 5px'
            }
             }  
              >
                {`$${plan?.subscription_plan?.plan_price} Plan`} 
              </Typography>
            </Box>
            </Grid>
          
        ))): (
          <Typography variant="body2" sx={{ ml:2 }}>No Subscription Plans available</Typography>
        )}
       
          </Grid>
        </Box> 
      
       

        <TextField
        id="search"
        type="search"
        label="Search"
        value={searchTerm}
        onChange={handleChange}
        className={classes.search}
        sx={{ width: '100%', mt:3, borderRadius:'20px' ,display:'flex', alignItems:'cernter', label:{mt:'-10px'}, zIndex:0 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ zIndex:0 }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

<Typography variant="h6" sx={{ mt:4 }}>Our Services</Typography>

      <Box  >
   
      <Grid  ref={targetRef} container spacing={2} sx={{ display:'flex', justifyContent:'space-between', mt:2, mb:5 , }}>
      {Services && Services.length > 0 ? (
        Services.filter(service => service.title.toLowerCase().includes(searchTerm)).map((service) => (
          <Grid item xs={12} sm={6} key={service.slug}>
            <Box sx={{ position: 'relative' }} onClick={() => handleCategoryClick(service.slug)}>
              <CardMedia
                sx={{ borderRadius: '6px', mt: 2 }} 
                component="img"
                alt="Descriptive alt text"
                height="110"
                image={service.file_path || defaultservice}
              />
              <Typography variant="body1" sx={{ position: 'absolute', bottom: '25px', fontWeight: '600', fontSize: '18px', color: '#fff', textAlign: 'center', left: '50%', transform: 'translateX(-50%)', width: '100%' }}>
                {service.title}
              </Typography>
            </Box>
          </Grid>
        ))
      ) : (
        <Typography variant="body2" sx={{ ml: 2 }}>No Services available</Typography>
      )}
    
  
      </Grid> 
  
    
      
      </Box>
      <Typography variant="h6" sx={{ mt:4 }}>Our Service Plans</Typography>

      <Box>
    
      <Grid  ref={servicePlanRef} container spacing={2} sx={{ display:'flex', justifyContent:'space-between', mt:2, mb:5 }}>
      {ServicePlan?.data && ServicePlan?.data.length > 0 ?  (
     ServicePlan?.data.map((serviceplan) => (
      <Grid item xs={12} sm={6}>
              <Box sx={{ position:'relative' }}  onClick={() => handlePlanClick(serviceplan?.site_url)} >
            
              <CardMedia
                  sx={{ borderRadius:'6px', mt:2 }}
                  component="img"
                  alt="Descriptive alt text"
                  height="110"
                     image={serviceplan?.image|| defaultservice} 
                />
                <Typography variant="body1" sx={{ position:'absolute', bottom:'25px',fontWeight:'bold', color:'#fff',textAlign:'center', width:'100%', left: '50%',transform: 'translateX(-50%)'}}>
               {serviceplan?.title}
                </Typography>
              
              </Box>

            </Grid>
 ))):(
  <Typography variant="body2" sx={{ ml:2 }}>No Services plan available</Typography>
)}    
          </Grid>
     
      </Box>

        {/* Staff */}
        {loading && <Waveloading fullSize />}
      <Typography variant="h6" sx={{ mt:4 }}>Our Fleet of Professionals</Typography>
      <div style={{ marginTop:'20px' }} ref={StaffRef}>
        <Slider {...settings} className={classes.slickSliderr}>
        {staff && staff.length > 0 ?  (
staff.map((data) => (     
          <Box sx={{ position:'relative' }} className={classes.mainDiv}>
            <img src={data?.profile_image|| defaultservice} style={{ width:"100%", height:"272px" }} alt="" />
            <Box sx={{ position:'absolute', bottom:'20px', left: '10%',right:'%' }}>
            <Typography variant="h5" sx={{ fontWeight:'600',fontSize:'18px', color:'#fff',mb:'3' }}>
               {data?.name}
            </Typography>
            <Typography variant="overline" sx={{ fontWeight:'500',fontSize:'13px', color:'#fff',lineHeight:'0px',letterSpacing:'0.225px' }}>
        {data?.job_role}
            </Typography>
            </Box>
          </Box>
))):""}   

        
          
          
        </Slider>
      </div>
      
  
     {/* Location */}
      
      <Typography variant="h6" sx={{ mt:4 }}>Our Locations</Typography>
      
    
      <div style={{ paddingBottom:'200px',marginTop:'20px' }}>
        <Slider {...settings} className={classes.slickSliderr}>
        {clinic.data && Array.isArray(clinic.data)
  ? clinic.data.map((clinicItem) => (
      <Box key={clinicItem.id} sx={{ position: 'relative' }} className={classes.mainDiv}>
        {/* Check if profile_image is a string before rendering */}
        {typeof clinicItem.profile_image === 'string' ? (
          <img src={clinicItem.profile_image || defaultservice} style={{ width: "100%",height:"272px" }} alt="" />
        ) : null}
        <Box sx={{ position: 'absolute', bottom: '20px', left: '10%', right: '%' }}>
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px', color: '#fff', mb: '3' }}>
            {clinicItem?.name}
          </Typography>
          {/* Check if location is a string before rendering */}
          {clinicItem.location?.length > 0 &&
            <Typography variant="overline" sx={{ fontWeight: '500', fontSize: '13px', color: '#fff', lineHeight: '0px', letterSpacing: '0.225px' }}>
          {clinicItem?.location[0]?.address}
            </Typography>
} 
        </Box>
      </Box>
  ))
  : (
    <Typography variant="body2">No clinic available</Typography>
  )
}


        {/* {clinic.data && Array.isArray(clinic.data)
  ? clinic.data.map((clinicItem) => (
      <Box key={clinicItem.id} sx={{ position: 'relative' }} className={classes.mainDiv}>
        <img src={clinicItem?.profile_image} style={{ width: "100%" }} alt="" />
        <Box sx={{ position: 'absolute', bottom: '20px', left: '10%', right: '%' }}>
          <Typography variant="h5" sx={{ fontWeight: '600', fontSize: '18px', color: '#fff', mb: '3' }}>
            {clinicItem?.name}
          </Typography>
          <Typography variant="overline" sx={{ fontWeight: '500', fontSize: '13px', color: '#fff', lineHeight: '0px', letterSpacing: '0.225px' }}>
            3609 W 16th Ave, Vancouver, BC, Canada {clinicItem?.location}
          </Typography>
        </Box>
      </Box>
  ))
  : (
    <Typography variant="body2">No clinic available</Typography>
  )
} */}


          
          
        </Slider>
      </div>

      </>

      </Box>
      
      </Container>

{/* footer */}
      {/* <Box sx={{ position:'fixed',bottom:'0',display:'flex',justifyContent:'space-between', backgroundColor:'#fff',zIndex:'999', boxShadow:'0px -18.000001907348633px 37px 0px rgba(0, 0, 0, 0.5)',borderRadius:'11px', width:'100%', p:{xs:'10px 15px 20px 15px',sm:'10px 30px 30px 30px'},'@media (max-width:340px)':{
              p:"10px 5px 10px 5px"
            }}} className="footer-wrappper">
         <Box onClick={handleHome}  sx={{  backgroundColor: activeButton === 'home' ? 'rgba(239, 230, 224, 1)' : 'transparent',
         borderRadius:'7px',p:'8px',display:'flex',alignItems:'center',flexDirection:'column',mr:{xs:'8px',sm:'0px'},'@media (max-width:340px)':{
          p:"5px",
        } }}>
         <CardMedia
                  sx={{ width:{xs:'20px',sm:'30px',} }}
                  component="img"
                  alt="Descriptive alt text"
                  image={homeoutline} 
                />
         <Typography variant="body1" sx={{ fontWeight:'300',fontSize:{xs:'12px',sm:'18px'}, mt:'10px', color:'rgba(30, 30, 30, 1)', }}>
            Home
            </Typography>
         </Box>
        
        <Box  onClick={handleBoxClick}  sx={{  backgroundColor:
      activeButton === 'booking' ? 'rgba(239, 230, 224, 1)' : 'transparent',borderRadius:'7px',p:'8px',display:'flex',alignItems:'center',flexDirection:'column' ,mt:'5px'}}>
      <CardMedia
               sx={{ width:{xs:'18px',sm:'26px', }}}
               component="img"
               alt="Descriptive alt text"
               image={booking} 
             />
      <Typography variant="body1" sx={{ fontWeight:'300',fontSize:{xs:'12px',sm:'18px'},mt:'10px', color:'rgba(30, 30, 30, 1)', }}>
      Bookings
         </Typography>
      </Box>
      <Box
          onClick={handleHome}
          sx={{
            borderRadius: "7px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CardMedia
            sx={{
              width: { xs: "80%", sm: "100%" },
              mt: { xs: "-40px", sm: "-40px" },
              '@media (max-width:319px)':{
                width:'80%',
                position:'relative',
                right:'5px'

              },
            }}
            component="img"
            alt="Descriptive alt text"
            image={menu}
          />
        </Box>
            
     
     
     
      <Box   onClick={handleplan} sx={{ backgroundColor:
      activeButton === 'member' ? 'rgba(239, 230, 224, 1)' : 'transparent',  borderRadius:'7px',p:'8px',display:'flex',alignItems:'center',flexDirection:'column' }}>
      <CardMedia
               sx={{ width:{xs:'20px',sm:'30px',} }}
               component="img"
               alt="Descriptive alt text"
               image={plan} 
             />
      <Typography variant="body1" sx={{ fontWeight:'300',fontSize:{xs:'12px',sm:'18px'},mt:'10px', color:'rgba(30, 30, 30, 1)', }}>
         Member
         </Typography>
      </Box> 
              
     
     
     
        <Box onClick={handleprofile} sx={{ backgroundColor:
      activeButton === 'profile' ? 'rgba(239, 230, 224, 1)' : 'transparent', borderRadius:'7px',p:'8px',display:'flex',alignItems:'center',flexDirection:'column' ,ml:{xs:'5px',sm:'0px'} }}>
        <CardMedia
                 sx={{ width:{xs:'20px',sm:'30px'},
                }}
                 component="img"
                 alt="Descriptive alt text"
                 image={profile} 
               />
  <Typography variant="body1" sx={{ fontWeight:'300',fontSize:{xs:'12px',sm:'18px'},mt:'10px', color:'rgba(30, 30, 30, 1)',}}>
       Account
           </Typography>
        </Box>
      
    
        
        
      </Box> */}
      <Footer />

      </Box>
      
      </>
  );
};

export default Home;
