import React, {useEffect, useState} from 'react';
import {FormControl, Grid} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import CustomTable from "../../../components/table";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getAllMenus} from "../../../store/menus";
import ConfigurationLayout from "../configurationLayout";
import Loader from "../../../components/loader/loader";
import SimpleInput from "../../../components/form/simpleInput";
import TableData from '../../../components/table/TableData';

const Menus = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const menuStore = useSelector(state => state?.menuStore)

    useEffect(() => {
        (async () => {
            if (!menuStore?.menus?.length){
                setLoading(true)
            }
            await dispatch(getAllMenus())   
            setLoading(false)
        })()
    }, [dispatch,menuStore?.menus?.length])
    console.log(" menu data",menuStore)

    const handleClick = () => {
        navigate('/admin/configuration/add-menus')
    }

    const roleHead = [
        {value: "Name", key: 'name'},
        {value: "Key", key: 'key'},
        {value: "Url", key: 'url'},
        {value: "Icon", key: 'icon'},
        {value: "Status", key: 'status'},
        {value: "Action", key: 'action'}
    ]


    const handleEdit = (row, isEdit) => {
        if (isEdit) {
            navigate(`/admin/configuration/edit-menus/${row?.id}`)
        }
    }
    return (
        <ConfigurationLayout>
            <Grid container p={'20px'}>
                <Grid item xs={12} sx={{
                    mb: '16px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%', 
                    flexWrap:'wrap',
                    '@media (max-width: 410px)' :{
                        display:'flex',
                        justifyContent:'flex-start'
                       }, 
                }}>
                    <FormControl sx={{mr: "10px"}}>
                        <SimpleInput
                            search={search}
                            setSearch={setSearch}
                        />
                    </FormControl>
                    <CustomButton val={'Add Menu'} mrt={"10px"} size={'large'} handleClick={handleClick}/>
                </Grid>
                <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                    {loading?<Loader/> :
                        <TableData
                            headRow={roleHead}
                            isStatus={true}
                            tableData={menuStore?.menus}
                            type={"menu"}
                            search={search}
                            handleEdit={handleEdit}
                        />
                    }
                </Grid>
            </Grid>
        </ConfigurationLayout>
    );
};

export default Menus;