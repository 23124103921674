import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate } from 'react-router-dom'; // Import both useLocation and useNavigate hooks

const CustomBread = ({ crmCustomerDetails }) => {
    console.log("issue fixed",crmCustomerDetails)
    const location = useLocation();
    const navigate = useNavigate(); // Use useNavigate hook to programmatically navigate
    const pathnames = location.pathname.split('/').filter(x => x);

    const isLastSegmentNumeric = !isNaN(pathnames[pathnames.length - 1]);
    const targetSegmentIndex = isLastSegmentNumeric ? pathnames.length - 2 : pathnames.length - 1;
    const targetPathSegment = pathnames[targetSegmentIndex];
    const breadcrumbLabel = targetPathSegment.replace(/-/g, ' ').replace(/^\w/, (c) => c.toUpperCase());

    const items = [ 
      { label: 'Home', href: '/crm/dashboard' },
      { label: 'Appointment', href: '/crm/appointments' }, 

      {   
        label:  crmCustomerDetails?.customer?.name , 
        onClick: () => navigate(`/crm/appointments`, {   
          state: {searchvalue: crmCustomerDetails?.customer?.name },      
        }),     
      },    
      {         
        label: 'Appointment History',      
        href: `crm/appointments/history/${crmCustomerDetails?.customer?.id}`   
      },             
      { label: breadcrumbLabel, href: location.pathname },         
           
    ];    

       
    return (  
      <div>
        {items.map((item, index) => (    
          <React.Fragment key={index}>  
            {item.onClick ? (
              <button onClick={item.onClick} style={{ all: 'unset', cursor: 'pointer' }}>
                {item.label}  
              </button>     
            ) : ( 
              <a href={item.href} style={{ textDecoration:'none',color:'#4F4F4F',fontWeight: index === items.length - 1 ? 'bold' : '500', }}>{item.label}</a>   
            )}
            {index < items.length - 1 && <span> <ArrowForwardIosIcon sx={{fontSize:'12px'}}/> </span>}     
          </React.Fragment>  
        ))}                 
      </div>
    );    
};   

export default CustomBread;
