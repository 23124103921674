import React, {useState} from 'react';
import PublicHeader from "../../components/public-header";
import PublicLayout from "../../layout/PublicLayout";
import {Formik} from "formik";
import FormInput from "../../components/form/formInput";
import FormButton from "../../components/form/formButton";
import * as Yup from "yup";
import {forgetPassword} from "../../store/auth";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import toast from "react-hot-toast";
import Loader from "../../components/loader/loader";
import homeoutline from '../../assets/images/homeoutline.png';
import booking from "../../assets/images/Vector (Stroke).png";
import menu from '../../assets/images/homeIcon.png'
import plan from "../../assets/images/wallet2.png";
import profile from "../../assets/images/user.png";
import { Box, CardMedia, Typography } from '@mui/material';




const ForgetPassword = () => { 
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const initialValues = {
        email: "",
    };
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email is Required"),
    });


    const handleHome = () => {
        navigate("/home");
      }; 

    const onSubmit=async (data, {setSubmitting}) => {
        setLoading(true)
        let res = await dispatch(forgetPassword(data))
        if (res?.payload?.success) {
            toast.success(res?.payload?.message, {duration: 3000})
            localStorage.setItem('forgetEmail', data?.email)
            navigate('/reset-password')
        }
        if (res?.payload?.success === false) {
            toast.error(res?.payload?.errors, {duration: 3000})

        }
        setLoading(false)
        setSubmitting(false);
    }
    const extractSubdomain = () => {
        const hostname = window.location.hostname;
        const hostnameParts = hostname.split('.');
        return hostnameParts.length > 2 ? hostnameParts[0] : null;
    };
    const subdomain = extractSubdomain();

    return (
        <PublicLayout>
            {loading?<Loader fullSize/> : ''}
            <div className={'register-card'}>
                <PublicHeader
                    heroText={"Password Reset"}
                    heroSubText={"Enter your  email and we will send you a reset link"}
                    // to={"/login"}
                />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({values,isSubmitting, handleSubmit,errors, handleChange, touched}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <FormInput 
                                    name={"email"}
                                    label={"Email"}
                                    placeholder={'Type your e-mail'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                                <FormButton
                                    disable={isSubmitting}
                                    text={'Email OTP code'}
                                />
                            </form>
                        )
                    }}
                </Formik>
                {subdomain == "app" &&
                <Box
        sx={{
          display: { sm: "none", xs: "block" },
          marginLeft: "calc(-50vw + 50%)",
          marginRight: "calc(-50vw + 50%)",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            zIndex: "999",
            // boxShadow: "0px -18.000001907348633px 37px 0px rgba(0, 0, 0, 0.5)",
            borderRadius: "11px",

            width: "100%",
            p: { xs: "10px 15px 20px 15px", sm: "10px 30px 30px 30px" },
            border: "1px solid lightgray",
            '@media (max-width:340px)':{
              p:"10px 5px 10px 5px"
            }
          }}
          className="footer-wrappper"
        >

          <Box
            sx={{
              backgroundColor: "rgba(239, 230, 224, 1)",
              borderRadius: "7px",
              p: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mr: { xs: "8px", sm: "0px" },
              '@media (max-width:340px)':{
                marginLeft:"6px",
                p:"5px"
              }
            }}
          >
            <CardMedia
              sx={{ width: { xs: "20px", sm: "30px" } }}
              component="img"
              alt="Descriptive alt text"
              image={homeoutline}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                fontSize: { xs: "12px", sm: "18px" },
                mt: "10px",
                color: "rgba(30, 30, 30, 1)",
              }}
            >
              Home
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: "7px",
              p: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              mt: "5px",
            }}
          >
            <CardMedia
              sx={{
                width: { xs: "18px", sm: "26px" },
                "&:hover": {
                  filter:
                    "invert(32%) sepia(69%) saturate(6027%) hue-rotate(355deg) brightness(97%) contrast(83%)",
                },
              }}
              component="img"
              alt="Descriptive alt text"
              image={booking}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                fontSize: { xs: "12px", sm: "18px" },
                mt: "10px",
                color: "rgba(30, 30, 30, 1)",
              }}
            >
              Bookings
            </Typography>
          </Box>
          <Box
            onClick={handleHome}
            sx={{
              borderRadius: "7px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CardMedia
              sx={{
                width: { xs: "80%", sm: "100%" },
                mt: { xs: "-40px", sm: "-40px" },
                "@media (max-width:319px)": {
                  width: "80%",
                  position: "relative",
                  right: "5px",
                },
              }}
              component="img"
              alt="Descriptive alt text"
              image={menu}
            />
          </Box>
          <Box
            sx={{
              borderRadius: "7px",
              p: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CardMedia
              sx={{ width: { xs: "20px", sm: "30px" } }}
              component="img"
              alt="Descriptive alt text"
              image={plan}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                fontSize: { xs: "12px", sm: "18px" },
                mt: "10px",
                color: "rgba(30, 30, 30, 1)",
              }}
            >
              Member
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: "7px",
              p: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              ml: { xs: "5px", sm: "0px" },
            }}
          >
            <CardMedia
              sx={{ width: { xs: "20px", sm: "30px" } }}
              component="img"
              alt="Descriptive alt text"
              image={profile}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "300",
                fontSize: { xs: "12px", sm: "18px" },
                mt: "10px",
                color: "rgba(30, 30, 30, 1)",
              }}
            >
              Profile
            </Typography>
          </Box>
        </Box>
                </Box>}

            </div>
        </PublicLayout>

    );
};

export default ForgetPassword;