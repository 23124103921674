import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import apis from "../../lib/apis/apis";
import {apiHeaders} from "../../lib/apiHeader";
import toast from "react-hot-toast";

export const loginUser = createAsyncThunk('loginUser', async (data,{dispatch}) => {
    try {

        const response = await axios.post(apis.login, {...data})

        if (response?.data?.success){
            return {
                success:true,
                token: true,
                data: response.data.data,
            }
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            if (err?.response?.data?.message){
                toast.error(err?.response?.data?.message,{duration:3000})
            }
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

export const loginEmail = createAsyncThunk('loginEmail', async (data,{dispatch}) => {
    try {

        const response = await axios.post(apis.email_login, {...data})

        if (response?.data?.success){
            return {
                success:true,
                token: true,
                data: response.data.data,
            }
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            if (err?.response?.data?.message){
                toast.error(err?.response?.data?.message,{duration:3000})
            }
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})
// 
export const crmLoginUser = createAsyncThunk('crmLoginUser', async (data,{dispatch}) => {
    try {

        const response = await axios.post(apis.crm_login, {...data})

        if (response?.data?.success){
            return {
                success:true,
                token: true,
                data: response.data.data,
            }
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            if (err?.response?.data?.message){
                toast.error(err?.response?.data?.message,{duration:3000})
            }
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})
export const getUserDetails = createAsyncThunk("me", async () => {
        try {
            const response = await axios.get(apis.me, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === true) {
                return {
                   data: response?.data?.data,
                    token: true,
                    loading: false
                }
            }
            if (response?.data?.success === false) {
                localStorage.removeItem('token')
                return {
                    token: false,
                    loading: false
                }
            }
        } catch (err) {
            localStorage.removeItem('token')
                return {
                    token: false,
                    loading: false
                };
        }
    }
);

export const registerUser = createAsyncThunk('registerUser', async (data) => {
   try {
       const response = await axios.post(apis.register, {...data})
       if (response?.data?.success){
           return {
               data: response.data,
           }
       }
   }catch (err){
       if (err?.response?.status!==200){
        return {
            data : [],
            errors:err?.response?.data?.errors
        }
       }
   }
})



export const emailVerification = createAsyncThunk('emailVerified', async (data) => {
   try {
       const response = await axios.post(apis.email_verified, {...data})
       if (response?.data?.success){
           return  response.data
       }
       if (response?.data?.success === false) {
           return response?.data
       }
   }catch (err){
       if (err?.response?.status!==200){
        return {
            data : [],
            errors:err?.response?.data?.errors
        }
       }
   }
})

export const resendEmail = createAsyncThunk('resendEmail', async (data) => {
   try {
       const response = await axios.post(apis.resend_otp, {...data})

       if (response?.data?.success){
           return  response.data
       }
       if (response?.data?.success === false) {
           return response?.data
       }

   }catch (err){
       console.log(err)
       if (err?.response?.status!==200){
        return {
            data :err?.response,
            errors:true
        }
       }
   }
})
export const forgetPassword = createAsyncThunk('forgetPassword', async (data) => {
   try {
       const response = await axios.post(apis.forget_password, {...data})

       if (response?.data?.success){
           return  response.data
       }
       if (response?.data?.success === false) {
           return response?.data
       }

   }catch (err){
       console.log(err)
       if (err?.response?.status!==200){
        return {
            data :err?.response,
            errors:true
        }
       }
   }
})
//  milstone_user

export const getMileStone = createAsyncThunk("getMileStone", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.milstone_user+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }               
    } catch ( err) {
        return  []
    }
});
export const resetPassword = createAsyncThunk('resetPassword', async (data) => {
   try {
       const response = await axios.post(apis.reset_password, {...data})

           return  response.data

   }catch (err){
       console.log(err)
                        
       return  err?.response?.data
   }
})

export const removeUser= createAsyncThunk('removeUser', async () => {

    return {}
})


export const fetchUserToken = createAsyncThunk('appToken/fetchToken', async (params,{dispatch}) => {

    try {
        let  token = localStorage.getItem('token')

        if (token){
            dispatch(getUserDetails())
            return {
                loading:true
            }
        }else {
            return {
                loading:false
            }
        }

    }catch (e){
        return {
            loading:false
        }
    }
})
export const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        MileStone:[],
        user: {},
        logged:false,
        loading:true,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(loginUser.fulfilled, (state, action) => {
            if (action?.payload?.data?.id){
                state.user = action.payload.data
                state.logged = action.payload?.token
            }
        })
        builder.addCase(loginEmail.fulfilled, (state, action) => {
            if (action?.payload?.data?.id){
                state.user = action.payload.data
                state.logged = action.payload?.token
            }
        })
        builder.addCase(getMileStone.fulfilled, (state, action) => {
            state.MileStone= action.payload ;
        });  

        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            state.user=action?.payload?.data
            state.logged=action?.payload?.token
            state.loading=action?.payload?.loading
        })
        builder.addCase(removeUser.fulfilled, (state, action) => {
            state.user={}
            state.logged=false
            state.loading=false
        })
        builder.addCase(fetchUserToken.fulfilled, (state, action) => {
            state.loading = action.payload.loading
        })
    }
})


export default authSlice.reducer