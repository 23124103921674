import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../lib/apiHeader";


export const getAllMembership = createAsyncThunk('getAllMembership', async (data,{dispatch}) => {
    try {

        const response = await axios.get(apis.all_subscription_plans, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return []
        }

    } catch (err) {
        return  []
    }
})



export const storeMembership = createAsyncThunk('storeMembership', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_subscription_plan, {...data},{
            headers: apiHeadersImage()
        })
        if (response?.data?.success ==='true'){
            await dispatch(getAllMembership())
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const getMembershipByID = createAsyncThunk('getMembershipByID', async (id) => {
    try {
        const response = await axios.get(apis.subscription_plans_by_id+id,{
            headers: apiHeaders()
        })
        console.log(response?.data)
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

export const updateMembership = createAsyncThunk('updateMembership', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_subscription_plan, {...data},{
            headers: apiHeadersImage()
        })

        if (response?.data?.success==='true'){
            await dispatch(getAllMembership())
            return  response.data
        }else {
            return response?.data
        }
    }catch (err){
        console.log(err)

        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){
            return err?.response?.data
        }
    }
})
export const deleteMembership = createAsyncThunk('deleteMembership', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_subscription_plan+data?.id,{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllMembership())
            toast.success("Membership Plan Deleted Successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})

export const membershipSlice = createSlice({
    name: 'membershipSlice',
    initialState: {
        memberships: [],
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllMembership.fulfilled, (state, action) => {
            state.memberships = action.payload
        })
    }
})


export default membershipSlice.reducer