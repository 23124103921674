import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomBread = ({ crmCustomerDetails }) => {
    console.log(" issue ",crmCustomerDetails);
    const location = useLocation();
    const navigate = useNavigate();
    const pathnames = location.pathname.split('/').filter(x => x);

    const isLastSegmentNumeric = !isNaN(pathnames[pathnames.length - 1]);
    const targetSegmentIndex = isLastSegmentNumeric ? pathnames.length - 2 : pathnames.length - 1;
    const targetPathSegment = pathnames[targetSegmentIndex];
    const breadcrumbLabel = targetPathSegment.replace(/-/g, ' ').replace(/^\w/, (c) => c.toUpperCase());

    const items = [ 
      { label: 'Home', href: '/crm/dashboard' },
      { label: 'CRM', href: '/crm/customer-relationship-management' },
      {
        label: crmCustomerDetails?.customer_name,
        onClick: () => navigate(`/crm/customer-relationship-management`, {
          state: { value: crmCustomerDetails?.customer_name }, 
        }),
      },
      {
        label: 'Appointment History',
        href: `/crm/customer-relationship-management/customer/history/${crmCustomerDetails?.customer_id}`
      },
      { label: breadcrumbLabel, href: location.pathname },
    ];

    return (
      <div>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {item.href ? (
              <a href={item.href} style={{ textDecoration: 'none', color: '#4F4F4F', fontWeight: index === items.length - 1 ? 'bold' : '500', }}>{item.label}</a>
            ) : (
              // Use a button or div for items with an onClick instead of an href
              <button 
                onClick={item.onClick} 
                style={{ background: 'none', border: 'none', padding: 0, color: '#4F4F4F', fontWeight: '500', cursor: 'pointer', textDecoration: 'none' }}
              >
                {item.label}
              </button>
            )}
            {index < items.length - 1 && <span> <ArrowForwardIosIcon sx={{ fontSize: '12px' }} /> </span>}
          </React.Fragment>
        ))}
      </div>
    );
};

export default CustomBread;
