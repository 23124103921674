import React, {useState} from 'react';
import PublicHeader from "../../components/public-header";
import PublicLayout from "../../layout/PublicLayout";
import {Formik} from "formik";
import FormInput from "../../components/form/formInput";
import FormButton from "../../components/form/formButton";
import * as Yup from "yup";
import { loginUser, resetPassword} from "../../store/auth";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import toast from "react-hot-toast";
import FormPasswordInput from "../../components/form/formPasswordInput";
import Loader from "../../components/loader/loader";

const ResetPassword = () => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const forgetEmail = localStorage.getItem("forgetEmail")
    const initialValues = {
        email: "" ||forgetEmail? forgetEmail :'',
        otp: "",
        password: "",
    };
    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email is Required"),
        otp: Yup.string().matches(
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
            "Alphabet and Special Character are  not allowed"
        ).min(6, 'Must be exactly 6 digits')
            .max(6, 'Must be exactly 6 digits').required("OTP is Required"),
        password: Yup.string()
            .min(8, 'Password must be 8 characters long')
       /*     .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')*/
            // .matches(/[^\w]/, 'Password requires a symbol')
            .required("Required"),
    });

    const onSubmit=async (data, {setSubmitting,setFieldTouched,setFieldError}) => {

        setLoading(true)
        let res =await dispatch(resetPassword(data))
        if (res?.payload?.success){
            toast.success(res?.payload?.message, {duration:4000})
            // navigate("/login")
            // navigate("/admin/configuration/roles");
            let loginResponse = await dispatch(loginUser({ email: data.email, password: data.password ,social_login:"0"}));
            if (loginResponse?.payload?.success) {
                navigate("/admin/configuration/roles");
            } else {
               
                toast.error("Login failed. Please try logging in with your new password.");
                navigate("/login"); 
            }
        }
        if (res?.payload?.errors) {
            let errors = res?.payload?.errors
            if (typeof errors[0] === 'string') {
                toast.error(errors ,{duration:3000})

            }else if (errors?.otp) {
                await setFieldTouched("otp", true)
                setFieldError("otp", errors?.otp)
            }else if (errors?.email ) {
                await setFieldTouched("email", true)
                setFieldError("email", errors?.email)
            }else if (errors?.password) {
                await setFieldTouched("password", true)
                setFieldError("password", errors?.password)
            }else { 
                errors.forEach( (item)=>{
                    if (item.includes('Email')){
                         setFieldTouched("email", true)
                        setFieldError("email", item)
                    }
                    if (item.includes('Otp')){
                         setFieldTouched("otp", true)
                        setFieldError("otp", item)
                    }
                })
            }

        }
        setSubmitting(false);
        setLoading(false)
    }

    return (
        <PublicLayout>
            {loading? <Loader fullSize/>:''}
            <div className={'register-card'}>
                <PublicHeader
                    heroText={"Let's get back"}
                    heroSubText={"Reset"}
                    to={"/forget-password"}
                />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({values,isSubmitting, handleSubmit,errors, handleChange, touched}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <FormInput
                                    name={"email"}
                                    label={"Email"}
                                    placeholder={'Type your e-mail'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                                <FormInput
                                    name={"otp"}
                                    label={"Enter OTP verification code that was emailed to you"}
                                    placeholder={'Type your OTP'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                    labelStyle={{ textTransform: 'none' }}  // Add this line to pass inline style
                                />
                                <FormPasswordInput
                                    name={"password"}
                                    label={"Enter New Password"}
                                    placeholder={'Type your password'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                                <FormButton
                                    disable={isSubmitting}
                                    text={'Reset Password'}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </div>
        </PublicLayout>

    );
};

export default ResetPassword;