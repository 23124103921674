import React from "react";
import { Button } from "@mui/material";

const CustomButtoncolor = ({ val, size, handleClick, mainColor,active, subColor ,style, fwidth, fsize,paddin}) => {
    return (
        <Button
            size={size}
            sx={{
                // borderRadius:"0px",
                color: active ? "#fff" : mainColor || "#09ABF1",
                backgroundColor: active ? mainColor : '',
                borderColor: mainColor || "#09ABF1",
                textTransform: "capitalize",
                width:fwidth,
                fontSize: "14px",
                "&:hover": {
                    backgroundColor: mainColor || "#09ABF1",
                    borderColor: mainColor || "#09ABF1",
                    color: subColor || "#fff",
                },
                '@media (max-width: 450px)' :{
                    fontSize:fsize
                },
                '@media (max-width: 319px)' :{
                    fontSize:"13px !important",
                    padding:paddin
                }
            }}
            variant={"outlined"}
            onClick={handleClick}
            style={style}
        >
            {val}
        </Button>
    );
};

export default CustomButtoncolor;
