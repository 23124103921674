import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";

import { apiHeadersImage} from "../../../lib/apiHeader";
import apis from "../../../lib/apis/apis";
import {getUserDetails} from "../../auth";



export const updateUserAccount = createAsyncThunk("updateUserAccount", async (data, { dispatch }) => {
        try {
            const response = await axios.post(apis.profile_update, data, {
                headers: apiHeadersImage(),
            });

            if (response?.data?.success === "true") {
              await  dispatch(getUserDetails())
            }
            return response?.data

        } catch (err) {
           return err?.response?.data
        }
    }
);

export const userAccount = createSlice({
    name: 'userAccount',
    initialState: {
        activeClinics: [],
        meta: {},
        links: {},
    },
    reducers: {
    },
    // extraReducers: builder => {
    //     builder.addCase(getAllActiveClinicsLocations.fulfilled, (state, action) => {
    //         state.activeClinics = action.payload
    //     })
    // }
})


export default userAccount.reducer