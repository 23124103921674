import React, { useEffect, useState } from 'react';
import {MySwal, successConfirmation} from "../../../services/utills";

import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/loader/loader';
import useStyles from './style';
import {
    getAllProducts,
    getAllPurchases,
    getAllVendors,
    storePurchases,
    updatePurchases,
} from '../../../store/crm/inventory';
import Waveloading from '../../loading/Waveloading';

const Purchase = () => {
    
    const [loading, setLoading] = useState(false);
    const [purchaseData, setPurchaseData] = useState({
        supplier_id: null,
        product_id: null,
        status: '',
        note: '',
        price: '',
        quantity: '',
    });
    const [purchaseError, setPurchaseError] = useState({
        supplier_id: '',
        product_id: '',
        status: '',
        note: '',
        price: '',
        quantity: '',
    });

    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [selectedPurchase, setSelectedPurchase] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
const [selectedRowData, setSelectedRowData] = useState(null)

    const inventoryStore = useSelector((state) => state?.inventoryStore);
    const { purchase = [], productVendors = [], products = [] } = inventoryStore;

    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            setLoading(true);
            await dispatch(getAllPurchases());
            setLoading(false);
            await dispatch(getAllProducts());
            await dispatch(getAllVendors());
        })();
    }, []);

    const handleChange = (event, name) => {
        console.log('change');
        if(!isEditMode)
            setPurchaseData({ ...purchaseData, [name]: event?.target?.value });
        else setSelectedRowData({ ...selectedRowData, [name]: event.target?.value })
    };

    const handleSavePurchase = async () => {
        setLoading(true);
        let finalPayload = { ...purchaseData };
        finalPayload['product_id'] = purchaseData?.product_id;
        finalPayload['supplier_id'] = purchaseData?.supplier_id.id;

        let { payload } = await dispatch(storePurchases(finalPayload));

        setLoading(false);
        if (payload?.success === 'true') {
            setPurchaseData({
                supplier_id: null,
                product_id: null,
                status: '',
                note: '',
                price: '',
                quantity: '',
            });
            await successConfirmation('Success', 'Purchase Added Successfully');
        } else {
            setPurchaseError(payload?.data?.errors);
        }
    };

    const handlePendingButtonClick = (row) => {
        setIsUpdateMode(true);
        setSelectedPurchase(row);
    };

    const handleUpdatePurchase = async () => {
        setLoading(true);
        let finalPayload = { ...selectedRowData };
        finalPayload['product_id'] = selectedRowData?.product.id;
        finalPayload['supplier_id'] = selectedRowData?.supplier?.id;
        delete finalPayload.product
        delete finalPayload.supplier
        delete finalPayload.provider
        console.log(finalPayload,selectedRowData.product)
        let { payload } = await dispatch(updatePurchases(finalPayload));

        if (payload?.success === 'true') {
            setPurchaseData({
                supplier_id: null,
                product_id: null,
                status: '',
                note: '',
                price: '',
                quantity: '', 
            });
            await dispatch(getAllPurchases());
            setLoading(false);
            await successConfirmation('Success', 'Purchase Added Successfully');
        } else {
            setPurchaseError(payload?.data?.errors);
        }
        setIsEditMode(false)
    };
    const handleUpdateButtonClick = async (id) => {
        try {
            const confirm = await MySwal.fire({
                icon: 'question',
                title: 'Confirmation',
                showConfirmButton: false,
                html: (
                    <Box sx={{ mb: 4 }}>
                        <Typography sx={{}}>
                            Are you sure you want to complete this purchase?
                        </Typography>
                        <Box sx={{ mt: 3 }}>
                            <Button
                                sx={{
                                    backgroundColor: '#fff',
                                    minWidth: 'max-content',
                                    border: '1px solid #666666',
                                    borderRadius: '10px',
                                    fontSize: '16px',
                                    px: '20px',
                                    mx: '10px',
                                    color: '#666666',
                                    '&:hover': {
                                        backgroundColor: '#fff',
                                        border: '1px solid #666666',
                                    },
                                }}
                                onClick={() => MySwal.clickConfirm()}
                            >
                                Yes, Update It.
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: '#fff',
                                    minWidth: 'max-content',
                                    border: '1px solid #666666',
                                    borderRadius: '10px',
                                    px: '10px',
                                    mx: '10px',
                                    fontSize: '16px',
                                    color: '#666666',
                                    '&:hover': {
                                        backgroundColor: '#fff',
                                        border: '1px solid #666666',
                                    },
                                }}
                                onClick={() => MySwal.close()}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                ),
            });

            if (!!confirm?.isConfirmed) {
                setLoading(true);


                // Dispatch an action to update the purchase data
                const { payload } = await dispatch(updatePurchases({id: id}));

                if (payload?.success === 'true') {
                    setIsUpdateMode(false); // Exit update mode
                    setSelectedPurchase(null); // Clear selected purchase

                    await successConfirmation('Success', 'Purchase Updated Successfully');
                } else {
                    setPurchaseError(payload?.data?.errors);
                }
                setPurchaseData({
                    supplier_id: null,
                    product_id: null,
                    status: '',
                    note: '',
                    price: '',
                    quantity: '',
                });
                setLoading(false);
            }
        } catch (error) {
            console.error('Error updating purchase:', error);
            // Optionally, you can set an error state or show an error message to the user
        }
    };
    return (
        <Box sx={{
             minHeight: "calc(100vh - 109px)",
            background: "#fff",
            padding: {xs:"10px",sm:"24px"},
            borderRadius: "7px",
        }}>
            <Typography variant="h5" color="initial" sx={{mt: 5}}>
                Add Purchase
            </Typography>
            <Grid
                container
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            > 
 <Grid item lg={3.9} md={3.9} sm={5.9} xs={12}>
  <Autocomplete
    sx={{
      mt: 2,
    }}
    options={products}
    onFocus={() => {
      setPurchaseError({ ...purchaseError, product_id: '' });
    }}
    value={
      (isEditMode ? (selectedRowData?.product || null) : purchaseData) || null
    }
    disableCloseOnSelect={false}
    isOptionEqualToValue={(option, value) => {
      return option?.id === value?.id;
    }}
    getOptionLabel={(option) => option?.name || ''}
    renderInput={(params) => {
      const { inputProps, helperText, error, ...rest } = params;
      return (
        <TextField
          {...rest}
          inputProps={{ ...inputProps }}
          label="Product Name"
        />
      );
    }}
    onChange={(event, newValue) => {
      console.log(selectedRowData?.product, newValue);
      if (!isEditMode) {
        setPurchaseData({
          ...purchaseData,
          name: newValue?.name || '',
          product_id: newValue?.id || null,
        });
      } else {
        setSelectedRowData({
          ...selectedRowData,
          product: {
            ...newValue,
            brand_id: newValue?.brand?.id || null,
            category_id: newValue?.category?.id || null,
            brand: undefined,
            category: undefined,
          },
        });
      }
    }}
    renderOption={(props, option, { selected }) => (
      <MenuItem {...props} key={option?.id}>
        <Checkbox style={{ marginRight: 8 }} checked={selected} />
        {option?.name}
      </MenuItem>
    )}
  />
  {purchaseError?.product_id && (
    <FormHelperText
      sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}
    >
      {purchaseError?.product_id}
    </FormHelperText>
  )}
</Grid>

                <Grid item lg={3.9} md={3.9} sm={5.9} xs={12}>
                    <TextField
                    className={isEditMode ? classes.focusedInput : ''}
                          label="Quantity"
                        onFocus={() => {
                            setPurchaseError({...purchaseError, quantity: ''})
                        }}
                        variant="outlined"
                        value={(isEditMode ? selectedRowData?.quantity : purchaseData?.quantity) || ''}
                     
                        onChange={(e) => handleChange(e, 'quantity')}
                      
                        // autoFocus={isEditMode}
                        //  onChange={(e) => handleChange(e, 'quantity')}
                        sx={{width: "100%", mt: 2}}
                    />
                    {purchaseError?.quantity &&
                        <FormHelperText
                            sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                        >
                            {purchaseError?.quantity}
                        </FormHelperText>
                    }
                </Grid>
                <Grid item lg={3.9} md={3.9} sm={5.9} xs={12} sx={{ mt: 2 }}>
  <FormControl fullWidth sx={{
    ...(isEditMode && {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#00000099',
      },
    }),
  }}>
    <InputLabel
      shrink={Boolean(isEditMode ? selectedRowData?.status : purchaseData?.status)}
      sx={{ background: "#fff", padding: "0 10px" }}
    >
      Status
    </InputLabel>
    <Select
      sx={{
        ...(isEditMode && {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00000099',
          },
        }),
      }}
      value={(isEditMode ? selectedRowData?.status : purchaseData?.status) || ""}
      label="Status"
      onFocus={() => {
          setPurchaseError({ ...purchaseError, status: '' })
      }}
      onChange={(e) => handleChange(e, 'status')}
    >
      <MenuItem value={"Created"}>PO Created</MenuItem>
      <MenuItem value={"Received"}>PO Received</MenuItem>
    </Select>
  </FormControl>
  {purchaseError?.status &&
    <FormHelperText
      sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}
    >
      {purchaseError?.status}
    </FormHelperText>
  }
</Grid>

                
               
                  <Grid item lg={3.9} md={3.9} sm={5.9} xs={12}>
                    <TextField
                    className={isEditMode ? classes.focusedInput : ''}
                          label="price"
                        onFocus={() => {
                            setPurchaseError({...purchaseError, price: ''})
                        }}
                        variant="outlined"
                        value={(isEditMode ? selectedRowData?.price : purchaseData?.price) || ''}
                     
                        onChange={(e) => handleChange(e, 'price')}
                      
                        // autoFocus={isEditMode}
                        //  onChange={(e) => handleChange(e, 'quantity')}
                        sx={{width: "100%", mt: 2}}
                    />
                    {purchaseError?.price &&
                        <FormHelperText
                            sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                        >
                            {purchaseError?.price}
                        </FormHelperText>
                    }
                </Grid>
               
                 <Grid item lg={3.9} md={3.9} sm={5.9} xs={12}>
                    <TextField
                    className={isEditMode ? classes.focusedInput : ''}
                          label="Notes"
                        onFocus={() => {
                            setPurchaseError({...purchaseError, note: ''})
                        }}
                        variant="outlined"
                        value={(isEditMode ? selectedRowData?.note : purchaseData?.note) || ''}
                     
                        onChange={(e) => handleChange(e, 'note')}
                      
                        // autoFocus={isEditMode}
                        //  onChange={(e) => handleChange(e, 'quantity')}
                        sx={{width: "100%", mt: 2}}
                    />
                    {purchaseError?.note &&
                        <FormHelperText
                            sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                        >
                            {purchaseError?.note}
                        </FormHelperText>
                    }
                </Grid>
                <Grid item lg={3.9} md={3.9} sm={5.9} xs={12}>
                    <Autocomplete
                        sx={{
                            mt: 2
                        }}
                        options={productVendors}
                        onFocus={() => {
                            setPurchaseError({...purchaseError, supplier_id: ''})  
                        }}
                        // value={purchaseData?.supplier_id}
                        value={(isEditMode ? selectedRowData?.supplier : purchaseData?.supplier_id) || null}
                 
                        disableCloseOnSelect={false}
                        isOptionEqualToValue={(option, value) => {
                            return option?.id === value?.id
                        }}
                        getOptionLabel={(option) => option?.name || ''}
                        renderInput={(params) => {
                            const {inputProps, helperText, error, ...rest} = params;
                            return (
                                <TextField
                                    {...rest}
                                    inputProps={{...inputProps}}
                                    label="Supplier Name"
                                />
                            )
                        }}
                        onChange={(event, newValue) => {
                            setPurchaseData({...purchaseData, supplier_id: newValue});
                        }}
                        renderOption={(props, option, {selected}) => (
                            <MenuItem  {...props} key={option?.id}>
                                <Checkbox
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option?.name}
                            </MenuItem>
                        )}
                    />
                    {purchaseError?.supplier_id &&
                        <FormHelperText
                            sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                        >
                            {purchaseError?.supplier_id}
                        </FormHelperText>
                    }
                </Grid>
            </Grid>
            <Grid
                container
                sx={{display: "flex", justifyContent: "space-between"}}
            >
                
            </Grid>
            {/* <Button
                variant="outlined"
                color="primary"
                sx={{mt: 4}}
                onClick={()=>handleSavePurchase()}
            >
                Save
            </Button> */}
            <Button
    variant="outlined"
    color="primary"
    sx={{ mt: 4 }}
    onClick={isEditMode ? handleUpdatePurchase : handleSavePurchase}
>
    {isEditMode ? 'Update' : 'Save'}
</Button>
            
            {/* <Button
                variant="outlined"
                color="primary"
                sx={{mt: 4}}
                onClick={()=> UpdateClick()}
            >
                edit
            </Button> */}
            <Typography variant="h1" color="initial"></Typography>
            <Typography variant="h5" color="initial" sx={{mt: 5, mb: 3}}>
                Purchase List
            </Typography>
            <TableContainer sx={{mt: 5,}}>
            {
                loading ? <Waveloading/> :
                    <Table sx={{}} aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <TableRow> 
                                <TableCell 
                                    align="left"
                                    sx={{
                                        fontWeight: "700",
                                        borderBottom: 0,
                                    }}
                                > 
                                    <Checkbox/> 
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        fontWeight: "700",
                                        borderBottom: 0,
                                    }}
                                >
                                    ID
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        fontWeight: "700",
                                        borderBottom: 0,
                                    }}
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        fontWeight: "700",
                                        borderBottom: 0, 
                                    }}
                                >
                                    Quantity
                                </TableCell> 
                                <TableCell
                                    align="left"
                                    sx={{fontWeight: "700", borderBottom: 0}}
                                > 
                                     Price
                                </TableCell>
                                <TableCell 
                                    align="left"
                                    sx={{fontWeight: "700", borderBottom: 0}}
                                >
                                    Status
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{fontWeight: "700", borderBottom: 0}}
                                >
                                    Supplier
                                </TableCell>
                                <TableCell
                                      align="left"
                                    sx={{fontWeight: "700", borderBottom: 0}}
                                > 
                                    PO Date
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{fontWeight: "700", borderBottom: 0}}
                                >
                                       PO Received
                                </TableCell>
                                 <TableCell
                                   align="left"
                                   sx={{fontWeight: "700", borderBottom: 0}}
                                 >
                                   Action
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {purchase?.length > 0 ?
                                purchase.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        className={classes.odd}
 
                                    >   
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={classes.tableLink}
                                            sx={{cursor: 'pointer'}}
                                          >
                                            <Checkbox/>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={classes.tableLink}
                                            sx={{cursor: 'pointer'}}
                                        >
                                             {row?.id}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography
                                                variant="body1"
   
   >
                                                {row?.product?.name}
                                             </Typography>
                                        </TableCell>
                                        <TableCell align="left">
 <Typography variant="body1" color="initial">
                                                 {row?.quantity}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            {row?.price}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row?.status}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row?.supplier?.name}
                                        </TableCell>
                                        <TableCell align="left">      
                                             {row?.po_date}
                                        </TableCell>
                                         <TableCell align="left">
                                            {row?.po_received}
                                        </TableCell>
                                        {/* {row?.status === 'Created' ? (
                                            <TableCell align="left">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                   
                                                    onClick={() => {
                                                        setIsEditMode(true); 
                                                        setSelectedRowData(row); 
                                                      
                                                    }}
                                                >
                                                    Pending
                                                </Button>
                                            </TableCell>
                                        ) : (   
                                            <TableCell align="left">
                                              
                                                <Button
                                                    variant="contained"
                                                    style={{ backgroundColor: 'green', color: 'white' }}

                                                >
                                                    Completed
                                                </Button>
                                            </TableCell>
                                        )} */}
                                        <TableCell align="left">
                                              
                                              <Button
                                                  variant="contained"
                                                  style={{ backgroundColor: 'green', color: 'white' }}
                                                   onClick={() => {
                                                        setIsEditMode(true); 
                                                        setSelectedRowData(row); 
                                                      
                                                    }}

                                            >
                                             Edit
                                              </Button>
                                          </TableCell>

                                    </TableRow>
                                )) :
                                <TableRow>
                                    <TableCell
                                        colSpan={'9'}    
                                        align={'center'}
                                         sx={{ 
      
                                            height: '300px',
                                        }}
                                    >
                                         No Purchase List Available
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
            }
            </TableContainer>
        </Box>
    )
};
  
export default Purchase 
  