import { Grid, Typography, Container } from "@mui/material";
import Box from "@mui/material/Box";
import useStyles from "./style";
import moment from "moment/moment";
import AppointmentDetails from "../appointmentDetails";
import React, {useState} from "react";
import CustomModal from "../../../../../components/modal/customModal";
import {getAppointmentDetails} from "../../../../../store/crm/appointment";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../../../components/loader/loader";
import Waveloading from "../../../../loading/Waveloading";
import MobileViewLayout from "../../../../../components/mobileview/MobileViewLayout";

const WalletHistory = ({walletHistory}) => {
  const [appointmentDetails, setAppointmentDetail] = useState(null)
  const [loading, setLoading] = useState(null)
  const classes = useStyles();
  const dispatch = useDispatch()


  const handleAppointmentDetail = async (booking_id) => {
    setLoading(true)
    let {payload} = await dispatch(getAppointmentDetails(booking_id))
    setLoading(false)
    if (payload?.id){
      setAppointmentDetail(payload)
    }else {    
      setAppointmentDetail(null)
    } 
  }   

  return ( 

      <Box  
          sx={{   
            background: "#fff",
            borderRadius: "8px",
            minHeight: "100px", 
            p: {sm:'24px', xs:'12px'},
            px:{sm:'24px', xs:'0px'}
          }}  
      >    
        {loading? <Waveloading fullSize/> :""}
        <Container maxWidth="lg" sx={{ml: 0}}>
          <Grid container sx={{display: "flex"}}>
            <Grid item lg={12}>
              <Box>
                <Typography  
                    variant="body2"
                   sx={{color: "#4F4F4F",textDecoration:'underline',  fontFamily: 'helvetica-lt-std-bold'}}
                >  
                  Current Wallet Balance: 
                </Typography>
                <Typography
                    variant="h5"

                    sx={{mt: "4px", fontFamily: "helvetica-lt-std-bold"}}
                >
                  ${walletHistory?.wallet_balance || "0"}
                </Typography>   
              </Box>  
            </Grid>
          </Grid>   
          <MobileViewLayout walletHistory={walletHistory}/>
             
          <Grid 
              container 
              sx={{display: {
                xs:"none",
                sm: 'none', 
                md:"none",
                lg:"flex",
                
              },  pb: 2, mt: 1,}}
          >
            <Grid item lg={3.4}>
              <Box>
                <Typography
                    variant="body2"

                    sx={{color: "#4F4F4F"}}
                >
                  Date:
                </Typography>
              </Box>
            </Grid> 

            <Grid item lg={4}> 
              <Box>
                <Typography
                    variant="body2"

                    sx={{color: "#4F4F4F",mt: 1}}
                >
                  Description:
                </Typography>
              </Box>
               </Grid>
            <Grid item lg={2} className={classes.invoiceHeading}>
              <Box>
                <Typography
                    variant="body2"

                    sx={{color: "#4F4F4F"}}
                >
                  Credit/Debit:
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={2} className={classes.invoiceHeading}>
              <Box> 
                <Typography
                    variant="body2"

                    sx={{color: "#4F4F4F"}}
                >
                  Balance:
                </Typography>
              </Box>
            </Grid>
          </Grid>


          {walletHistory?.history?.length > 0 ?
               walletHistory?.history.map((history, index) => {
                const detailsWithColor = history?.details.replace(
                  /Booking #\s*(\d+)/g, 
                  'Booking #<span style="color: #09ABF1">$1</span>'
                ).replace(
                  /Invoice #\s*(\d+)/g,
                  'Invoice #<span style="color:#09ABF1">$1</span>'
                );
                return(
                  <Grid
                  key={index}
                  container
                  sx={{
                    // display: "flex",
                    pb: 2,
                    borderTop: index !== 0 ? "1px solid #C4C4C4" : '',
                    display: {
                      sm: 'none', 
                      md: 'none', 
                      lg: 'flex', 
                    },
                  }}
              >
                <Grid item lg={3.4}>
                  <Box>
                    <Typography variant="body1" sx={{mt: "4px"}}>
                      {history?.created_at}
                   
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4}>
                <Box>
            <Typography
              variant="body1"
              sx={{
                marginTop: "4px",
                cursor: history?.booking_id ? 'pointer' : 'default',
                "& span": {
                  background: "transparent",
                },
                "& span[style*='background-color']": {
                  borderRadius: "3px",
                  padding: "2px 4px",
                },
              }}
              onClick={() => history?.booking_id && handleAppointmentDetail(history?.booking_id)}
              dangerouslySetInnerHTML={{ __html: detailsWithColor }}
            />
          </Box>
                </Grid>
                <Grid item lg={2} className={classes.invoiceHeading}>
                  <Box>
                    <Typography variant="body1" sx={{marginTop: "4px"}}>
  {history?.type.toLowerCase() === 'credit' ? "+" : (history?.type.toLowerCase() === 'overwrite' ? "" : "-")}
{history.wallet_point}  
                    </Typography>
  
   </Box> 
                </Grid>
                <Grid item lg={2} className={classes.invoiceHeading  }>
                  <Box>
                    <Typography variant="body1" sx={{marginTop: "4px"}}>
                      {history.after_point}
                    </Typography>
                  </Box>
                </Grid>    


              </Grid>
                )

              }
                
              )
              :
              <Grid 
                  container
                  sx={{ display: {
                    xs:"none",
                    sm: 'none', 
                    md: 'none', 
                    lg: 'flex', 
                  }, pb: 2,}}
              >
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{marginTop: "4px", textAlign: 'center'}}>
                    None
                  </Typography>
                </Grid>
              </Grid>
          }
          <CustomModal
              customWidth={"1000px"}
              open={appointmentDetails !== null}
              setOpen={() => setAppointmentDetail(null)}
          >
            <Box>
              <AppointmentDetails appointmentDetails={appointmentDetails}/>
            </Box>
          </CustomModal>
        </Container> 
      </Box>
  );
};

export default WalletHistory;
 
