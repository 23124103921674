import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import {
    convertTimeToMinutes, generateYearsToday,
    getEndTime,
    getTimeSlotsArray, monthsArray,
    MySwal,
    successConfirmation
} from "../../../../services/utills";
import {MenuProps} from "../../calender/crmCalender";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { forwardRef } from 'react';


export const boxStyle ={
    height:'40px',
    width:'40px',
    // border:"1px solid #006AFF",
    display:'flex',
    alignItems:"center",
    borderRadius:'4px',
    justifyContent:'center',
    // color:'#006AFF',
    cursor:'pointer',
    "&:hover": {
        // background:"#006AFF",
        // color:'#fff'
    }
}
export const disableStyle ={
    opacity: 0.5,
    color:'#D5D9DC',

    border:"1px solid #D5D9DC",
    "&:hover": {
        cursor: 'not-allowed'
    }
}
export const dateStyle ={
    p:"10px 0",
    fontSize:'14px',
    fontWeight:'bold',
    height: '40px',
    width: '40px',
    mx: 'auto',
}

export  const showPlanDate = (arr,val) => {
    let filtered = arr.filter((item)=>item?.stepId === val)
    if(filtered){
        return   moment(filtered[0]?.date).format("dddd, MMMM D, YYYY")
    }else {
        return ''
    }

}

export const isPlanSlotsExist = (selectedTime,stepId,time) => {
    if (selectedTime?.length){
        let filtered =  selectedTime.filter((item)=>item?.stepId === stepId)
        if (filtered?.length > 0 && filtered[0]?.slots?.length > 0){
            return !!filtered[0]?.slots.includes(time)
        }else {
            return false
        }
    }else {
        return false
    }
}

const AppointmentPlanSessionTime =forwardRef(({selectedServices,getAppointmentHoliday,
    
    selectedTime,selectedAllPlanTime,setSelectedTime,selectedStaff,getAppointmentSchedule,
    selectedFinalDate,activeSession,executeScroll },ref) => {
    let currentData = new Date()

    const [startDate, setStartDate] = useState(currentData)
    const isCurrentMonth = (currentData.getMonth() === startDate.getMonth()) && (currentData.getFullYear() === startDate.getFullYear())
    const monthStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const monthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    const daysInMonth = monthEnd.getDate();
    const firstDayOfWeek = monthStart.getDay();
    const lastDayOfWeek = monthEnd.getDay();

    const bookAppointmentStore = useSelector(state => state?.bookAppointmentStore)
    const {appointmentHolidays = [], appointmentSchedule = []} = bookAppointmentStore



    const weeks = [];
    let days = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
        days.push(
            <TableCell key={`empty-${i}`} sx={{p: "0"}}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }


    for (let i = 1; i <= daysInMonth; i++) {
        const isExist = appointmentHolidays?.length > 0 && appointmentHolidays.some((item) => parseFloat(item) === parseFloat(i))

        days.push(
            <TableCell key={i} sx={{p: "0"}}>
                <Box
                    sx={{
                        ...dateStyle,
                        cursor: ((isCurrentMonth && i < currentData.getDate()) || isExist) ? 'default' : 'pointer',
                        color: ((isCurrentMonth && i < currentData.getDate()) || isExist) ? "#8D8D8D" : i === selectedFinalDate?.day && (startDate.getMonth() + 1) === selectedFinalDate?.month && startDate.getFullYear() === selectedFinalDate?.year ? '#006AFF' : '#000',
                        pointerEvents: ((isCurrentMonth && i < currentData.getDate()) || isExist) ? "none!important" : 'auto',
                        textDecoration: ((isCurrentMonth && i < currentData.getDate()) || isExist) ? "line-through" : 'none',
                    }}
                    onClick={() => {
                        const date = moment(new Date(startDate.getFullYear(), startDate.getMonth(), i)).format('YYYY-MM-DD')
                        getAppointmentSchedule(date,activeSession)
                    }}
                >
                    {i}
                </Box>
            </TableCell>
        );

        if ((i + firstDayOfWeek) % 7 === 0 || i === daysInMonth) {
            weeks.push(<TableRow key={`week-${i}`}>{days}</TableRow>);
            days = [];
        }
    }
    for (let i = (lastDayOfWeek + 1) % 7; i < 6; i++) {
        days.push(
            <TableCell key={`empty-${i}`} sx={{p: "0"}}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }
    const isDisabled = (month) => {
        const today = new Date();
        const year = today.getFullYear();
        const currentMonth = today.getMonth();
        const currentYear = startDate.getFullYear();
        if (year === currentYear) {
            return month < currentMonth;
        } else {
            return false
        }
    }

    const handleSelectedTime = async (slotsArray,startTime,stepId) => {
        let filteredDate = selectedAllPlanTime.filter((item)=>item?.stepId !== stepId)

        let totalTime = 0
        selectedServices?.forEach((item) => {
            let time = convertTimeToMinutes(item?.time_duration)
            totalTime = totalTime + time
        })

        let endTime = getEndTime(startTime,totalTime || 0)
        let getArraySlots = getTimeSlotsArray(startTime,endTime,slotsArray)
        let lastIndex = getArraySlots[getArraySlots?.length - 1]


        if (appointmentSchedule?.slots.includes(lastIndex)){
            let data ={}
            selectedAllPlanTime.forEach((item)=>{
                if (item?.stepId === stepId){
                    data['stepId'] = item?.stepId
                    data['date'] = item?.date
                    data['start_time'] =startTime
                    data['end_time'] = endTime
                    data['slots'] = getArraySlots
                }
            })

            // setSelectedTime([data,...filteredDate])
            setSelectedTime(prevSelected => [...prevSelected.filter(s => s.stepId !== stepId), data]);
            // setSelectedTime(prevSelectedTimes => [...prevSelectedTimes.filter(item => item.stepId !== stepId), data]);

            executeScroll();
        }else {
            let data ={}
            selectedAllPlanTime.forEach((item)=>{
                if (item?.stepId === stepId){
                    data['stepId'] = item?.stepId
                    data['date'] = item?.date
                }
            })
            await MySwal.fire({
                // position: 'top-end',
                icon: 'error',
                // text: 'Something went wrong!',
                title: 'Invalid Time Slots.',
                showConfirmButton: true,
                timer: 5000,
            })
            setSelectedTime([...filteredDate])
        }





    }

    const isTimeSelected = (array ,stepId) => {
        return array?.some((item) => item?.stepId === stepId && item?.date)
    }

    return (
        <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Grid item xs={12}>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            p: '12px',
                            justifyContent: 'space-between'
                        }}>
                        <Box sx={{display: 'flex', gap: '10px'}}>
                            <FormControl sx={{minWidth: 90}}>
                                <Select
                                    size={"small"}
                                    value={startDate?.getMonth()}
                                    MenuProps={MenuProps}
                                    onChange={(e) => {
                                        const end = new Date(startDate);
                                        end.setMonth(e?.target?.value);
                                        setStartDate(end);
                                        getAppointmentHoliday(end, selectedStaff)
                                    }}
                                    sx={{
                                        background: '#EDF2F7',
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: "0!important"
                                        },
                                        "svg": {
                                            fontSize: '34px',
                                        }
                                    }}
                                >
                                    {monthsArray?.length > 0 && monthsArray?.map((item, index) => (
                                        <MenuItem
                                            disabled={isDisabled(item?.key)}
                                            value={item?.key}
                                            key={index}
                                        >
                                            {item?.name}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl sx={{minWidth: 90}}>
                                <Select
                                    size={"small"}
                                    value={startDate?.getFullYear()}
                                    MenuProps={MenuProps}
                                    onChange={(e) => {
                                        const end = new Date(startDate);
                                        end.setFullYear(e?.target?.value);
                                        setStartDate(end);
                                        getAppointmentHoliday(end, selectedStaff)
                                    }}
                                    sx={{
                                        background: '#EDF2F7',
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: "0!important"
                                        },
                                        "svg": {
                                            fontSize: '34px',
                                        }
                                    }}
                                >
                                    {generateYearsToday(10)?.length > 0 &&
                                        generateYearsToday(10)?.map((item, index) => (
                                            <MenuItem value={item} key={index}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{display: 'flex', gap: '10px'}}>
                            <Box sx={isCurrentMonth ? {...boxStyle, ...disableStyle} : {...boxStyle}} onClick={() => {
                                if (!isCurrentMonth) {
                                    const end = new Date(startDate);
                                    end.setMonth(end.getMonth() - 1);
                                    setStartDate(end);
                                    getAppointmentHoliday(end, selectedStaff)
                                }
                            }}>
                                <KeyboardArrowLeftIcon sx={{fontSize: '32px'}}/>
                            </Box>
                            <Box sx={boxStyle} onClick={() => {
                                const end = new Date(startDate);
                                end.setMonth(end.getMonth() + 1);
                                setStartDate(end);
                                getAppointmentHoliday(end, selectedStaff)
                            }}>
                                <KeyboardArrowRightIcon sx={{fontSize: '32px'}}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Table aria-label="simple table" sx={{
                            boxShadow: 'none', "td , th": {
                                border: 'none',
                                textAlign: 'center',
                            },
                            "th": {
                                fontWeight: 'bold'
                            },
                            "td": {
                                py: "4px"
                            }
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Su</TableCell>
                                    <TableCell>Mo</TableCell>
                                    <TableCell>Tu</TableCell>
                                    <TableCell>We</TableCell>
                                    <TableCell>Th</TableCell>
                                    <TableCell>Fr</TableCell>
                                    <TableCell>Sa</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {weeks}
                                <TableRow sx={{
                                    "td": {
                                        py: "5px!important"
                                    }
                                }}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Grid>
            <div   ref={ref}>

           
            <Grid item xs={12} sx={{display: "flex", flexDirection: 'column',}}>
                <Box sx={{height: '64px', display: 'flex', alignItems: 'center',justifyContent:'space-between'}}>
                    {isTimeSelected(selectedTime,activeSession) ?
                        <Typography sx={{fontSize: '20px',fontFamily: "helvetica-lt-std-bold",}}>
                            Available Slots on {showPlanDate(selectedTime,activeSession)}
                        </Typography> : ""
                    }
                    {
                        isTimeSelected(selectedTime,activeSession) ?
                            <Typography sx={{fontSize: '20px',fontFamily: "helvetica-lt-std-bold",}}>
                                Session: {activeSession + 1}
                            </Typography>
                            :""
                    }
                </Box>
                <Box
              
                    sx={{
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                    {appointmentSchedule?.success &&  isTimeSelected(selectedTime,activeSession) && <Box>
                        <Typography sx={{
                            mt: '16px',
                            mb: "8px",
                            fontSize: '16px',
                            fontFamily: "helvetica-lt-std-bold",
                        }}>
                            Morning
                        </Typography>
                        <Box>
                            {appointmentSchedule?.morningTimeSlots?.length > 0 ?
                                appointmentSchedule?.morningTimeSlots.map((item, index) => (
                                    <Button
                                    key={index}
                                    variant={"outlined"}
                                    sx={{
                                      
                                        color: selectedTime.some(selected => selected.start_time === item) ?  '#006AFF':'#fff',
                                        backgroundColor: selectedTime.some(selected => selected.start_time === item) ?  '#fff' :'#006AFF',
                                        border: '1px solid #006AFF',
                                        fontWeight: 'bold',
                                        minWidth: '140px',
                                        mr: '10px',
                                        mb: '10px',
                                        "&:hover": { 
                                            color: '#fff',
                                            backgroundColor: '#006AFF',
                                        }
                                    }}
                                    onClick={() => {
                                        handleSelectedTime(appointmentSchedule?.slots, item, activeSession);
                                      
                                    }}
                                  >
                                    {selectedTime.some(selected => selected.start_time === item) ? "✔" : ''} {item}
                                  </Button>
                                  
                                  
                                  
                                ))
                                : <Button
                                    disabled
                                    variant={"contained"}
                                    sx={{
                                        backgroundColor: '#fff',
                                        color: '#006AFF',
                                        fontFamily: "helvetica-lt-std-bold",
                                    }}
                                >
                                    All Booked
                                </Button>
                            }
                        </Box>
                        <Typography
                            sx={{
                                mt: '16px',
                                mb: "8px",
                                fontSize: '16px',
                                fontFamily: "helvetica-lt-std-bold",
                            }}
                        >
                            Afternoon
                        </Typography>
                        <Box>
                            {appointmentSchedule?.afternoonTimeSlots?.length > 0 ?
                                appointmentSchedule?.afternoonTimeSlots.map((item, index) => (
                                    <Button
                                    key={index}
                                    variant={"outlined"}
                                    sx={{
                                      
                                        color: selectedTime.some(selected => selected.start_time === item) ?  '#006AFF':'#fff',
                                        backgroundColor: selectedTime.some(selected => selected.start_time === item) ?  '#fff' :'#006AFF',
                                        border: '1px solid #006AFF',
                                        fontWeight: 'bold',
                                        minWidth: '140px',
                                        mr: '10px',
                                        mb: '10px',
                                        "&:hover": {
                                            color: '#fff',
                                            backgroundColor: '#006AFF',
                                        }
                                    }}
                                    onClick={() => {
                                        handleSelectedTime(appointmentSchedule?.slots, item, activeSession);
                                      
                                    }}
                                  >
                                    {selectedTime.some(selected => selected.start_time === item) ? "✔" : ''} {item}
                                  </Button>
                                  
                                ))
                                : <Button
                                    disabled
                                    variant={"contained"}
                                    sx={{
                                        backgroundColor: '#fff',
                                        color: '#006AFF',
                                        fontFamily: "helvetica-lt-std-bold",
                                    }}
                                >
                                    All Booked
                                </Button>
                            }
                        </Box>
                        <Typography
                            sx={{
                                mt: '16px',
                                mb: "8px",
                                fontSize: '16px',
                                fontFamily: "helvetica-lt-std-bold",
                            }}
                        >
                            Evening
                        </Typography>
                        <Box>
                            {appointmentSchedule?.eveningTimeSlots?.length > 0 ?
                                appointmentSchedule?.eveningTimeSlots.map((item, index) => (
                                    <Button
                                    key={index}
                                    variant={"outlined"}
                                    sx={{
                                      
                                        color: selectedTime.some(selected => selected.start_time === item) ?  '#006AFF':'#fff',
                                        backgroundColor: selectedTime.some(selected => selected.start_time === item) ?  '#fff' :'#006AFF',
                                        border: '1px solid #006AFF',
                                        fontWeight: 'bold',
                                        minWidth: '140px',
                                        mr: '10px',
                                        mb: '10px',
                                        "&:hover": {
                                            color: '#fff',
                                            backgroundColor: '#006AFF',
                                        }
                                    }}
                                    onClick={() => {
                                        handleSelectedTime(appointmentSchedule?.slots, item, activeSession);
                                      
                                    }}
                                  >
                                    {selectedTime.some(selected => selected.start_time === item) ? "✔" : ''} {item}
                                  </Button>
                                  
                                ))
                                : <Button
                                    disabled
                                    variant={"contained"}
                                    sx={{
                                        backgroundColor: '#fff',
                                        color: '#006AFF',
                                        fontFamily: "helvetica-lt-std-bold",
                                    }}
                                >
                                    All Booked
                                </Button>
                            }
                        </Box>
                    </Box>}
                </Box>
            </Grid>
            </div>
        </Grid>
    );
}
);
export default AppointmentPlanSessionTime;