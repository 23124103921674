import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {
    Typography,
    Avatar,
    Grid,
    FormLabel,
    Select,
    MenuItem,
    FormControl,
    TextField,
    IconButton, Checkbox, FormControlLabel
} from "@mui/material";
import ImageUploader from "../../../../../components/image-uploader/imageUploader";
import CustomButton from "../../../../../components/custom-button/customButton";
import {
    getAllTags,
    storeAppointmentAttachment,
    storeAppointmentGallery, storeTag
} from "../../../../../store/crm/appointment/attachment";
import {useDispatch, useSelector} from "react-redux";
import {successConfirmation} from "../../../../../services/utills";
import Loader from '../../../../../components/loader/loader';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {getAppointmentDetails} from "../../../../../store/crm/appointment";
import CustomModal from "../../../../../components/modal/customModal";
import {useParams} from "react-router";
import {storeCrmCustomerGallery} from "../../../../../store/crm/crm/crm-details";
import Waveloading from '../../../../loading/Waveloading';

const labelStyles ={
    color:'#000',
    fontSize:'16px',
    mb:'10px',
    "&.Mui-focused": {
        color:'#000!important',
    }
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CrmAddGallery = ({setActive}) => {
    const [files,setFiles] = useState([])
    const [loading,setLoading] = useState(false)
    // const [selectedServices,setSelectedServices] = useState({})
    const [tagId,setTagId] = useState({})
    const [comments,setComments] = useState('')
    const [isMilestone,setIsMilestone] = useState(false)
    const [tag,setTag] = useState('')
    const [isAddTag,setIsAddTag] = useState(false)

    const dispatch = useDispatch()
    const {user_id} = useParams()
    const crmAppointmentAttachmentStore = useSelector(state => state?.crmAppointmentAttachmentStore)
    const {tags=[]}= crmAppointmentAttachmentStore


    const handleSubmit = async () => {
      if (files?.length  && tagId?.id){
      // if (files?.length && selectedServices?.id && comments?.length){

          setLoading(true)
          const formData = new FormData();

          // formData.append("service_id", selectedServices?.id);
          formData.append("tag_id", tagId?.id);
          formData.append("comment", comments);
          formData.append("customer_id", user_id);
          formData.append("is_milestone", isMilestone ? "1" :'0');

          files.forEach(value => formData.append("files" + '[]', value))
          let res = await  dispatch(storeCrmCustomerGallery({data:formData,customer_id:user_id}))

          setLoading(false)
          if (res?.payload?.success === 'true'){
              // setSelectedServices({})
              setFiles([])
              setComments('')
              await successConfirmation(`Gallery ${isMilestone ? " and Milestone " :"" }Added Successfully`)
              if (setActive){
                  setActive(1)
              }
          }
      }else {
          await successConfirmation("Error",`All Fields are required`,'error')

      }
    }

    const handleRemove = (file) => {
        if (files?.length) {
            let finalData = files.filter((item) => file?.path !== item?.path && file?.size !== item?.size)
            setFiles(finalData)
        }
    }

    useEffect(()=>{
        (async ()=>{
            if (!tags?.length > 0){
                await dispatch(getAllTags())
            }
        })()
    },[])

    const handleSave = (item) => {
        let finalData = [...files,...item]
        let uniqueFiles = finalData.filter((file, index, self) =>
            index === self.findIndex((f) => (
                f.name === file.name && f.size === file.size
                ))
        );

        setFiles(uniqueFiles)

    }

    const handleSaveTag = async () => {
      if (tag?.length > 2){
          setLoading(true)

          let res = await  dispatch(storeTag({tag_name:tag}))
          setLoading(false)

          if(res?.payload?.success === 'true'){
              await successConfirmation('Success',"Tag Added Successfully")
              setIsAddTag(false)
          }else {
              await successConfirmation('Error',"Tag Already Exist",'error')

          }
      }
    }
    return (
        <Box sx={{background:"#fff",minHeight:'100px',borderRadius:'8px',p:'24px'}}>
            <Typography sx={{fontSize:'20px'}}>Add New Gallery</Typography>
            {loading? <Waveloading fullSize/>:''}
            <Box sx={{display:'flex',gap:"20px",flexWrap:'wrap',my:'24px'}}>
                {files?.length > 0 && files.map((file,key)=>(
                    <Box key={key}>
                        <IconButton
                            sx={{
                                fontSize: "15px",
                                position: "relative",
                                p:"5px",
                                zIndex:'1',
                                background:"#EB5757!important",
                                m:'5px',
                                right:'-111px'
                            }}
                            onClick={() => handleRemove(file)
                        }
                        >
                            <DeleteOutlineIcon sx={{color:'#fff',fontSize:'16px'}}/>
                        </IconButton>
                        <Avatar
                            src={URL.createObjectURL(file)}
                            sx={{
                                borderRadius:'8px' ,
                                height:'149px',
                                width:'149px',
                                mt:'-36px'
                            }}
                        />
                    </Box>
                ))}
                <ImageUploader handleSave={handleSave} />
            </Box>
            <Grid
                container
                spacing={3}
                sx={{
                    flexDirection:'column',
                }}
            >
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <FormLabel sx={{...labelStyles}}>Comments</FormLabel>
                        {/* <TextField
                            size={'small'}
                            variant="outlined"
                            placeholder={""}
                            value={comments}
                            onChange={(e)=>setComments(e.target.value)}
                        /> */}
                           <textarea
                                className="MuiInputBase-input MuiOutlinedInput-input"
                                rows={3}
                                placeholder={""}
                                value={comments}
                                onChange={(e)=>setComments(e.target.value)}
                                style={{
                                    width: '100%',
                                    resize: 'vertical',
                                    padding: '8px',
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                    borderRadius: '4px',
                                    fontFamily: 'inherit',
                                    fontSize: '1rem',
                                }}
                            />
                    </FormControl>
                </Grid>
                <Grid
                    xs={12}
                    md={6}
                    item
                    sx={{
                        display:"flex",
                        JustifyContent:'space-between',
                    }}
                >
                    <FormControl fullWidth sx={{maxWidth:'50%',alignItems:'centers'}}>
                        <FormLabel sx={{...labelStyles}}>Gallery Tag</FormLabel>
                        <Select
                            size={'small'}
                            value={tagId?.id || ''}
                            displayEmpty
                            MenuProps={MenuProps}
                        >
                            {tags?.length > 0 ?
                                tags?.map((service,key) => (
                                <MenuItem
                                    key={key}
                                    value={service?.id}
                                    onClick={()=>setTagId(service)}
                                >
                                    {service?.tag_name}
                                </MenuItem>
                            ))
                                : <MenuItem>
                                    Add Tag
                                </MenuItem>
                            }
                        </Select>
                    </FormControl>
                   <Box
                       sx={{
                           width:'50%',
                           display:"flex",
                           alignItems:'flex-end',
                           justifyContent:'flex-end'
                       }}
                   >
                       <CustomButton
                           val={"Add Tag"}
                           handleClick={()=>setIsAddTag(true)}
                       />
                   </Box>
                </Grid>
                <Grid xs={12} item>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={isMilestone}
                            onChange={e => {
                                setIsMilestone(e?.target?.checked)
                            }}
                        />
                    }
                        label={"Add Into Milestone"}
                    />
                </Grid>
                <Grid xs={12} item>
                    <CustomButton val={'Save'} handleClick={handleSubmit}/>
                </Grid>
            </Grid>
            <CustomModal
                customWidth={'500px'}
                setOpen={() => setIsAddTag(false)}
                open={isAddTag}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize:'18px',
                            fontFamily: 'helvetica-lt-std-bold',
                            textAlign:"center",
                        }}
                    >
                        Add New Tag
                    </Typography>

                    <FormControl fullWidth>
                        <FormLabel sx={{...labelStyles}}>Tag</FormLabel>
                        <TextField
                            size={'small'}
                            variant="outlined"
                            placeholder={""}
                            value={tag}
                            onChange={(e)=>setTag(e.target.value)}
                        />
                    </FormControl>

                    <Box
                        sx={{
                            display:'flex',
                            justifyContent:'center',
                            mt:'24px',
                        }}
                    >
                        <CustomButton
                            val={'Save'}
                            size={'large'}
                            handleClick={handleSaveTag}
                        />
                    </Box>
                </Box>
            </CustomModal>
        </Box>
    );
};

export default CrmAddGallery;