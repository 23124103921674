import {deleteRole, updateRole} from "../store/roles";
import {deletePermission, updatePermission} from "../store/permissions";
import {deleteClinic, updateClinic} from "../store/clinics";
import {deleteMenu, deleteghl, updateMenu, updateghl} from "../store/menus";
import {deleteLanguages, updateLanguages} from "../store/languages";
import {deletePaymentMethod, updatePaymentMethods} from "../store/payments-methods";
import {deleteNotificationEvent, updateNotificationEvent} from "../store/notification-event";
import { updateNotificationTemplate} from "../store/notification-templates";
import {deleteClinicsRooms, updateClinicsRooms} from "../store/clinics/clinicSettings/clinic-rooms";
import {deleteClinicsTreatment, updateClinicsTreatment} from "../store/clinics/clinicSettings/clinic-treatment";
import {
    deleteClinicsTreatmentForm,
    updateClinicsTreatmentForm
} from "../store/clinics/clinicSettings/clinic-treatment-form";
import {
    deleteClinicsTreatmentGroup,
    updateClinicsTreatmentGroup
} from "../store/clinics/clinicSettings/clinic-treatment-group";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {deleteClinicsServices} from "../store/clinics/clinicSettings/clinics-services";
import {deleteClinicsStaff} from "../store/clinics/clinicSettings/clinic-staff";
import {
    deleteClinicsHoliday,
    deleteStaffBreakTime,
    deleteStaffHoliday
} from "../store/clinics/clinicSettings/business-hours";
import toast from "react-hot-toast";
import {deleteClinicsPlan} from "../store/clinics/clinicSettings/clinic-plan";
import {deleteCard} from "../store/user/user-cards";
import {deleteMembership, updateMembership} from "../store/super-admin/membership";
import {Box, Button, Typography} from "@mui/material";
import React from "react";
import { deletemanual, updatemanual } from "../store/clinics/clinicSettings/usermanual";


export const MySwal = withReactContent(Swal);

export const handleDelete =async (dispatch,type,finalData)=>{
    switch (type) {
        case 'role':
          await dispatch(deleteRole(finalData?.id))
            break;
        case 'permission':
          await dispatch(deletePermission(finalData?.id))
            break;
        case 'clinic':
          await dispatch(deleteClinic(finalData?.id))
            break;
        case 'menu':
          await dispatch(deleteMenu(finalData?.id))
            break;
            case 'ghl':
                await dispatch(deleteghl(finalData?.id))
                  break;
                  case 'manual':
                    await dispatch(deletemanual(finalData?.id))
                      break;
        case 'language':
          await dispatch(deleteLanguages(finalData?.id))
            break;
        case 'paymentMethod':
          await dispatch(deletePaymentMethod(finalData?.id))
            break;
        case 'notificationEvent':
          await dispatch(deleteNotificationEvent(finalData?.id))
            break;
        case 'room':
          await dispatch(deleteClinicsRooms(finalData))
            break;
        case 'treatment':
          await dispatch(deleteClinicsTreatment(finalData))
            break;
        case 'treatmentGroup':
          await dispatch(deleteClinicsTreatmentGroup(finalData))
            break;
        case 'treatmentForm':
          await dispatch(deleteClinicsTreatmentForm(finalData))
            break;
        case 'service':
          await dispatch(deleteClinicsServices(finalData))
            break;
        case 'staff':
          await dispatch(deleteClinicsStaff(finalData))
            break;
        case 'holiday':
          await dispatch(deleteClinicsHoliday(finalData))
            break;
        case 'staffHoliday':
          await dispatch(deleteStaffHoliday(finalData))
            break;
        case 'staffBreak':
          await dispatch(deleteStaffBreakTime(finalData))
            break;
        case 'plan':
          await dispatch(deleteClinicsPlan(finalData))
            break;
        case 'cardDelete':
          await dispatch(deleteCard(finalData))
            break;
        case 'membership':
          await dispatch(deleteMembership(finalData))
            break;
        default:
            break;

    }

}
export const handleEditConfiguration =async (data,dispatch,type)=>{
    switch (type) {

        case 'role':
          let  res = await dispatch(updateRole(data))
            if (res?.payload?.success ==='true'){
                toast.success("Role status updated successfully",{duration:3000})
            }
            break; 
            case 'ghl':
                let resghl = await dispatch(updateghl(data))
                  if (resghl?.payload?.success ==='true'){        
                      toast.success("ghl status updated successfully",{duration:3000})
                  }
                  break;
                  case 'manual':
                    let resmanual = await dispatch(updatemanual(data))
                      if (resmanual?.payload?.success ==='true'){        
                          toast.success("manual status updated successfully",{duration:3000})
                      }
                      break;
        case 'permission':
          let  resPermission = await dispatch(updatePermission(data))   

            if (resPermission?.payload?.success ==='true'){
                toast.success("Permission status updated successfully",{duration:3000})  
            }
            break;
        case 'clinic':
            let clinicRes = await dispatch(updateClinic(data))  
            if (clinicRes?.payload?.success ==='true'){
                toast.success("Clinic status updated successfully",{duration:3000})
            }
            break;
        case 'menu':
            let menuRes = await dispatch(updateMenu(data))
            if (menuRes?.payload?.success ==='true'){
                toast.success("Menu status updated successfully",{duration:3000})
            }
            break;
        case 'language':
            let languageRes =await dispatch(updateLanguages(data))
            if (languageRes?.payload?.success ==='true'){
                toast.success("Language status updated successfully",{duration:3000})
            }
            break;
        case 'paymentMethod':
            let paymentMethod = await dispatch(updatePaymentMethods(data))
            if (paymentMethod?.payload?.success ==='true'){
                toast.success("Payment method status updated successfully",{duration:3000})
            }
            break;
        case 'notificationEvent':
            let notificationEventRes = await dispatch(updateNotificationEvent(data))
            if (notificationEventRes?.payload?.success ==='true'){
                toast.success("Notification Event status updated successfully",{duration:3000})
            }
            break;
        case 'notificationTemplate':
            let notificationTemplateRes = await dispatch(updateNotificationTemplate(data))
            if (notificationTemplateRes?.payload?.success ==='true'){
                toast.success("Notification status updated successfully",{duration:3000})
            }
            break;
        case 'room':
            let roomRes = await dispatch(updateClinicsRooms(data))
            if (roomRes?.payload?.success ==='true'){
                toast.success("Room status updated successfully",{duration:3000})
            }
            break;
        case 'treatment':
            let treatmentRes = await dispatch(updateClinicsTreatment(data))
            if (treatmentRes?.payload?.success ==='true'){
                toast.success("Treatment status updated successfully",{duration:3000})
            }
            break;
        case 'treatmentForm':
            let treatmentFormRes = await dispatch(updateClinicsTreatmentForm(data))
            if (treatmentFormRes?.payload?.success ==='true'){
                toast.success("Treatment form status updated successfully",{duration:3000})
            }
            break;
        case 'treatmentGroup':
            let treatmentGroupRes = await dispatch(updateClinicsTreatmentGroup(data))
            if (treatmentGroupRes?.payload?.success ==='true'){
                toast.success("Group / Treatment status updated successfully",{duration:3000})
            }
            break;
        case 'membership':
            let membershipRes = await dispatch(updateMembership(data))
            if (membershipRes?.payload?.success ==='true'){
                toast.success("Membership plan status updated successfully",{duration:3000})
            }
            break;
        default:
            break;

    }


}





export const confirmation = async (text,confirmText) =>{

    let res =await MySwal.fire({
        title: 'Are you sure?',
        text: text ? text :"You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmText ? confirmText :'Yes, delete it!'
    })

    return !!res?.isConfirmed;

}


export const successConfirmation =async (title,subTitle,type)=>{
    await MySwal.fire({
        icon:type || 'success',
        title: title || '  Successfully',
        showConfirmButton:false,
        html:<Box sx={{mb: 4}}>
            {subTitle &&  <Typography sx={{}}>
                {subTitle}
            </Typography>}
            <Button
                sx={{
                    mt:3,
                    backgroundColor: "#fff",
                    minWidth: "200px",
                    height: '62px',
                    border: "1px solid #666666",
                    borderRadius: '10px',
                    fontSize: '20px',
                    color: "#666666",
                    "&:hover": {
                        backgroundColor: "#fff",
                        border: "1px solid #666666",
                    },
                }}
                onClick={() => MySwal.close()}
            >
                Ok
            </Button>
        </Box>
    })
}
export const customConfirmation = async (text,subText,confirmText,cancelText) =>{
    const result = await MySwal.fire({
        title: '',
        text: '',
        showConfirmButton: false,
        didOpen: () => {
            // Directly access the swal popup and add styles
            const popup = document.querySelector('.swal2-popup');
            if (popup) { 
                popup.style.maxWidth = '650px';
                popup.style.width = '100%';
                // Add more styles as needed
            }
        },
        html:
            <Box sx={{mt: 3}}>
                <Typography variant={'h4'} sx={{textAlign: "center", fontWeight: '600'}}>
                    {text||"Are you sure you want to use this card" }
                </Typography>

                {subText && <Typography  sx={{textAlign: "center",fontWeight:'400',fontSize:'20px', mt: 2, p: '15px 30px', mx: '20px', mb: '5px','@media (max-width:320px)':{
                                p:'15px'
                              },}}>
                    {subText}
                </Typography>}
                <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', mt: 3, mb: 4}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: {sm:'200px', xs:'70px'},
                            height: {sm:'62px', xs:'45px'},
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: {sm:'20px', xs:'15px'},
                            '@media (max-width:319px)':{
                                fontSize:'11px'
                              },
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        {cancelText || "No"}
                    </Button>
                    <Button
                        onClick={() => MySwal.clickConfirm()}
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: {sm:'200px', xs:'70px'},
                            border: "1px solid #666666",
                            color: "#666666",
                            borderRadius: '10px',
                            fontSize: {sm:'20px', xs:'15px'},
                            '@media (max-width:319px)':{
                                fontSize:'11px'
                              },
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                    >
                        {confirmText || "Yes"}
                    </Button>
                </Box>

            </Box>
    });

    return !!result?.isConfirmed;

}



export function convertTimeToMinutes(time) {
    try {
        const [hours, minutes] = time.split(':');
        return (parseInt(hours) * 60) + parseInt(minutes);
    }catch (e){
        return  '30'
    }
}





export function generateYearsBetween(startYear = 1950, endYear) {
    const endDate = endYear || new Date().getFullYear();
    let years = [];

    for (let i = startYear; i <= endDate; i++) {
        years.push(startYear);
        startYear++;
    }
    return years;
}

export function generateYears(value= 50) {
    const endDate =  new Date().getFullYear() + value;
    let startDate =  new Date().getFullYear() - value;
    let years = [];

    for (let i = startDate; i <= endDate; i++) {
        years.push(startDate);
        startDate++;
    }
    return years;
}
export function generateYearsToday(value) {
    const endDate =  new Date().getFullYear() + value;
    let startDate =  new Date().getFullYear();
    let years = [];

    for (let i = startDate; i <= endDate; i++) {
        years.push(startDate);
        startDate++;
    }
    return years;
}

export const timeSlots= [  "12:00 AM", "12:30 AM", "01:00 AM", "01:30 AM", "02:00 AM", "02:30 AM",  "03:00 AM", "03:30 AM", "04:00 AM", "04:30 AM", "05:00 AM", "05:30 AM",  "06:00 AM", "06:30 AM", "07:00 AM", "07:30 AM", "08:00 AM", "08:30 AM",  "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM","12:00 PM","12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM",  "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM",  "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM",  "09:00 PM", "09:30 PM", "10:00 PM", "10:30 PM", "11:00 PM", "11:30 PM"]

export function generateTimeArray(start, end) {
    const startTime = new Date(`01/01/2022 ${start}`);
    const endTime = new Date(`01/01/2022 ${end}`);
    const timeArray = [];

    while (startTime <= endTime) {
        const hours = startTime.getHours();
        const minutes = startTime.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        const hour12 = hours % 12 || 12;
        const time = `${hour12 <10 ? "0":""}${hour12}:${minutes} ${ampm}`;
        timeArray.push(time);

        startTime.setMinutes(startTime.getMinutes() + 30);
    }

    return timeArray;
}


export const getSlotsArrays =(timeArray)=>{

  try {
      const morningTimeSlots = [];
      const afternoonTimeSlots = [];
      const eveningTimeSlots = [];
      timeArray.forEach((timeSlot) => {
          const hour = parseInt(timeSlot.split(":")[0]);
          const period = timeSlot.split(" ")[1]; // Get AM/PM from the time slot

          if ((hour === 12 && period === "AM")|| (hour >= 0 && hour < 12 && period ==="AM")) {
              morningTimeSlots.push(timeSlot);
          } else if (hour === 12 || (hour >= 1 && hour < 6 && period === "PM")) {
              afternoonTimeSlots.push(timeSlot);
          } else if ( (hour >= 6 && hour < 12 && period === "PM")) {
              eveningTimeSlots.push(timeSlot);
          }
      });

      return {
          morningTimeSlots:morningTimeSlots,
          afternoonTimeSlots:afternoonTimeSlots,
          eveningTimeSlots:eveningTimeSlots,
          slots:timeArray,
          success:true,
      }
  }catch (e) {
      return []
  }
}
export function validateTime(start_time, end_time) {
    // Convert start and end times to 24-hour format
    let start_time_24 = parseInt(start_time.slice(0, -2));
    if (start_time.endsWith("PM") && start_time_24 !== 12) {
        start_time_24 += 12;
    }
    let end_time_24 = parseInt(end_time.slice(0, -2));
    if (end_time.endsWith("PM") && end_time_24 !== 12) {
        end_time_24 += 12;
    } else if (end_time.endsWith("AM") && end_time_24 === 12) {
        end_time_24 = 0;
    }

    // Adjust end time if it's on the following day
    if (start_time_24 > end_time_24 || (start_time_24 === end_time_24 && end_time.endsWith("AM"))) {
        end_time_24 += 24;
    }

    // Add minutes to start and end times
    start_time_24 += parseInt(start_time.slice(-5, -3));
    end_time_24 += parseInt(end_time.slice(-5, -3));

    // Check that start time is less than end time
    if (start_time_24 >= end_time_24) {
        return "Error: End time must be after than start time";
    }

    // // Return success message if validation passes
    // return "Validation successful";
}


export const parseTime = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
        hours = "00";
    }
    if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
    }
    return new Date(0, 0, 0, hours, minutes);
};


export const appointmentTime =()=>{
    const timeSlots = Array.from(new Array(12 * 4)).map(
        (_, index) => {
            const hour = Math.floor(index / 4);
            const minute = index % 4 === 0 ? '00' : (index % 4) * 15;
            return `${hour.toString().padStart(2, '0')}:${minute === 0 ? '00' : minute}`;
        }
    );

    timeSlots.shift(); // Remove the first element which is 00:00

    //timeSlots.unshift('00:00'); // Add 00:00 to the beginning

    // timeSlots.splice(4, 0, '01:00'); // Add 01:00 after the first 4 slots

    return timeSlots
}


export const getEndTime = (startTime,totalTime) => {
   try {
       const [startHour, startMinute, period] = startTime.split(/:| /);
       let hoursToAdd = Math.floor(totalTime / 60);
       let minutesToAdd = totalTime % 60;

       const formattedStartHour = parseInt(startHour) % 12; // Normalize start hour to 0-11 range
       let endHour = formattedStartHour + hoursToAdd;
       let endMinute = parseInt(startMinute) + minutesToAdd;
       let endPeriod = period;

       if (endMinute >= 60) {
           endHour += 1;
           endMinute -= 60;
       }

       if (endHour >= 12) {
           endPeriod = "PM";
       }

       if (endHour === 12) {
           endPeriod = (period === "AM") ? "PM" : "AM";
       }

       if (endHour > 12) {
           endHour %= 12;
       }

       const formattedEndHour = (endHour === 0) ? 12 : endHour;

       return  `${formattedEndHour.toString().padStart(2, "0")}:${endMinute.toString().padStart(2, "0")} ${endPeriod}`;

   }catch (e) {
       return ''
   }
}
// export const getCRMTimeSlotIndex = (timeSlot,end) => {
//
//     let foundTimeSlot
//     timeSlotsWithGap.forEach((item,index)=>{
//         if (item===timeSlot){
//             foundTimeSlot =  index
//         }
//     })
//
//     if (end){
//         return  foundTimeSlot
//     }else {
//         return foundTimeSlot;
//     }
// }

export const timeSlotsWithGap =  [
    "12:00 AM", "12:15 AM", "12:30 AM", "12:45 AM",
    "01:00 AM", "01:15 AM", "01:30 AM", "01:45 AM",
    "02:00 AM", "02:15 AM", "02:30 AM", "02:45 AM",
    "03:00 AM", "03:15 AM", "03:30 AM", "03:45 AM",
    "04:00 AM", "04:15 AM", "04:30 AM", "04:45 AM",
    "05:00 AM", "05:15 AM", "05:30 AM", "05:45 AM",
    "06:00 AM", "06:15 AM", "06:30 AM", "06:45 AM",
    "07:00 AM", "07:15 AM", "07:30 AM", "07:45 AM",
    "08:00 AM", "08:15 AM", "08:30 AM", "08:45 AM",
    "09:00 AM", "09:15 AM", "09:30 AM", "09:45 AM",
    "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM",
    "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM",
    "12:00 PM", "12:15 PM", "12:30 PM", "12:45 PM",
    "01:00 PM", "01:15 PM", "01:30 PM", "01:45 PM",
    "02:00 PM", "02:15 PM", "02:30 PM", "02:45 PM",
    "03:00 PM", "03:15 PM", "03:30 PM", "03:45 PM",
    "04:00 PM", "04:15 PM", "04:30 PM", "04:45 PM",
    "05:00 PM", "05:15 PM", "05:30 PM", "05:45 PM",
    "06:00 PM", "06:15 PM", "06:30 PM", "06:45 PM",
    "07:00 PM", "07:15 PM", "07:30 PM", "07:45 PM",
    "08:00 PM", "08:15 PM", "08:30 PM", "08:45 PM",
    "09:00 PM", "09:15 PM", "09:30 PM", "09:45 PM",
    "10:00 PM", "10:15 PM", "10:30 PM", "10:45 PM",
    "11:00 PM", "11:15 PM", "11:30 PM", "11:45 PM"
]
export const crmTimeSlots =  [
    "09:00 AM",  "09:30 AM",
    "10:00 AM",  "10:30 AM",
    "11:00 AM",  "11:30 AM",
    "12:00 PM",  "12:30 PM",
    "01:00 PM",  "01:30 PM",
    "02:00 PM",  "02:30 PM",
    "03:00 PM",  "03:30 PM",
    "04:00 PM",  "04:30 PM",
    "05:00 PM",  "05:30 PM",
    "06:00 PM",  "06:30 PM",
    "07:00 PM",  "07:30 PM",
    "08:00 PM",  "08:30 PM",
    "09:00 PM",  "09:30 PM",
    "10:00 PM",  "10:30 PM",
    "11:00 PM",  "11:30 PM",
]

export const getTimeSlotsArray = (startTime,endTime,slotsArray) => {
    const startIndex = timeSlotsWithGap.indexOf(startTime);
    const endIndex = timeSlotsWithGap.indexOf(endTime);

    if (startIndex === -1 || endIndex === -1 || startIndex >= endIndex) {
        return [];
    }

    let filterArray = timeSlotsWithGap.slice(startIndex, endIndex+1);
    const midIndex = Math.floor(filterArray.length / 2);
    console.log(midIndex)
    let midValue =  filterArray[midIndex];
    // if (slotsArray?.includes(midValue)){
        return filterArray
    // }else {
    //     return []
    // }
}

export const  findPaymentMethod =(arrayList , type)=>{
    if (arrayList?.length >0){
        if (type === 'paypal'){
           let res = arrayList.find((item) => item?.name?.toLowerCase() === 'paypal')
            return !!res?.id ;
        }
        if (type==='square'){
            let res =arrayList.find((item) => item?.name?.toLowerCase() === 'square')
            return !!res?.id;
        }else  {
            return false
        }
    }else {
        return false
    }

}

export const hexToRGBA = (hexCode, opacity) => {
    let hex = hexCode.replace('#', '')
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
    }
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const colors = ['#FF5733', '#C70039', '#900C3F', '#581845', '#FFC300', '#DAF7A6', '#FF5733', '#C70039', '#900C3F', '#581845'];

export function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

export const calenderColor = (type) => {
    switch (type) {
        case 1:
            return {
                background: '#E8E9F5',
                color: '#323681',
            }
        case 2:
            return {
                background: '#EBCFEF',
                color: '#A533B8',
            }
        case 3:
            return {
                background: '#CCDADA',
                color: '#235449',
            }
        case 4:

            return {
                background: '#F3DED2',
                color: '#BB641F',
            }
        default:
            return {
                background: '#E8E9F5',
                color: '#323681',
            }
    }
}
export function addWhiteShade(color) {
    // Convert color to RGB values
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);

    // Calculate the modified RGB values with added white shade
    const modifiedR = Math.floor((r + 255) / 2);
    const modifiedG = Math.floor((g + 255) / 2);
    const modifiedB = Math.floor((b + 255) / 2);

    // Convert the modified RGB values back to a color string
    return `#${modifiedR.toString(16)}${modifiedG.toString(16)}${modifiedB.toString(16)}`;
}




export const dayViewTimeSlots  = [
    "12:00 AM",
    "01:00 AM",
    "02:00 AM",
    "03:00 AM",
    "04:00 AM",
    "05:00 AM",
    "06:00 AM",
    "07:00 AM",
    "08:00 AM",
    "09:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "01:00 PM",
    "02:00 PM",
    "03:00 PM",
    "04:00 PM",
    "05:00 PM",
    "06:00 PM",
    "07:00 PM",
    "08:00 PM",
    "09:00 PM",
    "10:00 PM",
    "11:00 PM",
];

export const getTimeSlotIndex = (timeSlot,end) => {

    const searchTimeWithoutMinutes = timeSlot.replace(":30", ":00");

   let foundTimeSlot
    dayViewTimeSlots.forEach((item,index)=>{
        if (item===searchTimeWithoutMinutes){
            foundTimeSlot =  index
        }
    })

    if (end  && timeSlot.includes(":30")){
      return  foundTimeSlot + 1
    }else {
        return foundTimeSlot;
    }
}


export const monthsArray = [
    {key:0,index:"01",shortName:'Jan',count:4,name:'January'},
    {key:1,index:"02",shortName:'Feb',count:30,name:'February'},
    {key:2,index:"03",shortName: 'Mar',count:9,name:'March'},
    {key:3,index:"04",shortName:'Apr',count:0,name:'April'},
    {key:4,index:"05",shortName:'May',count:20,name:'May'},
    {key:5,index:"06",shortName: 'Jun',count:4,name:'June'},
    {key:6,index:"07",shortName:'Jul',count:0,name:'July'},
    {key:7,index:"08",shortName:'Aug',count:4,name:'August'},
    {key:8,index:"09",shortName:'Sep',count:10,name:'September'},
    {key:9,index:"10",shortName:'Oct',count:6,name:'October'},
    {key:10,index:"11",shortName: 'Nov',count:4,name:'November'},
    {key:11,index:"12",shortName: 'Dec',count:0,name:'December'},
];

export const dateOfBirthMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const getMonthDays = (month) => {
    if (month){
        let index = dateOfBirthMonths.indexOf(month)
        const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        let days = daysInMonth[index];
        return Array.from({length: days}, (_, i) => i + 1);
    }else {
        return ['Select Month']
    }
}

const clearNumber = (value = '') => {
    return value.replace(/\D+/g, '')
}

// Format credit cards according to their types
export const formatCreditCardNumber = (value, Payment) => {
    if (!value) {
        return value
    }
    const issuer = Payment.fns.cardType(value)
    const clearValue = clearNumber(value)
    let nextValue
    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`
            break
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`
            break
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`
            break
    }

    return nextValue.trim()
}

// Format expiration date in any credit card
export const formatExpirationDate = value => {
    const finalValue = value
        .replace(/^([1-9]\/|[2-9])$/g, '0$1/') // 3 > 03/
        .replace(/^(0[1-9]|1[0-2])$/g, '$1/') // 11 > 11/
        .replace(/^([0-1])([3-9])$/g, '0$1/$2') // 13 > 01/3
        .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2') // 141 > 01/41
        .replace(/^([0]+)\/|[0]+$/g, '0') // 0/ > 0 and 00 > 0
        // To allow only digits and `/`
        .replace(/[^\d\/]|^[\/]*$/g, '')
        .replace(/\/\//g, '/') // Prevent entering more than 1 `/`

    return finalValue
}
export const formatExpiryDate = value => {
    const finalValue = value
        .replace(/^([1-9]\/|[2-9])$/g, '0$1/') // 3 > 03/
        .replace(/^(0[1-9]|1[0-2])$/g, '$1/') // 11 > 11/
        .replace(/^([0-1])([3-9])$/g, '0$1/$2') // 13 > 01/3
        .replace(/^(0?[1-9]|1[0-2])([0-9]{2,4})$/g, '$1/$2') // Change from {2} to {2,4} for year
        .replace(/^([0]+)\/|[0]+$/g, '0') // 0/ > 0 and 00 > 0
        // To allow only digits and `/`
        .replace(/[^\d\/]|^[\/]*$/g, '')
        .replace(/\/\//g, '/') // Prevent entering more than 1 `/`

    return finalValue
}

// Format CVC in any credit card
export const formatCVC = (value, cardNumber, Payment) => {
    const clearValue = clearNumber(value)
    const issuer = Payment.fns.cardType(cardNumber)
    const maxLength = issuer === 'amex' ? 4 : 3

    return clearValue.slice(0, maxLength)
}


export  const timeZones = [
    {timeZone: 'America/Edmonton' , name:'Mountain Time Zone',code:'MDT',time:'(- 2 hours)' },
    {timeZone: 'America/Halifax',name:'Atlantic' ,code:'ADT',time:'(+ 1 hour)'},
    {timeZone: 'America/Regina',name:'Central Time Zone - Saskatchewan' ,code:'CST',time:'(- 2 hours)'},
    {timeZone: 'America/St_Johns',name:'Newfoundland' ,code:'NDT',time:'(+ 1 hour 30 minutes)'},
    {timeZone: 'America/Toronto',name:'Eastern Time Zone' ,code:'EDT',time:''},
    {timeZone:'America/Vancouver', name:'Pacific Time Zone' ,code:'PDT',time:'(- 3 hours)'},
    {timeZone:'America/Winnipeg',name:'Central Time Zone' ,code:'CDT',time:'(- 1 hour)'},
];
export const getCurrentTimeByTimeZone = (time) => {
    const options = { timeZone: time };
    const now = new Date();

    return now.toLocaleTimeString('en-US', options);
};

export function getDaysBetweenDates(startDate, endDate) {
    // Convert both dates to milliseconds
    const startDateInMs = new Date(startDate).getTime();
    const endDateInMs = new Date(endDate).getTime();

    // Calculate the difference in milliseconds
    const differenceInMs = endDateInMs - startDateInMs;

    // Convert the difference to days and return it
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    return differenceInDays;
}



export const AppointmentAllStatusArray = {
    Refund: "Refund",
    Canceled: "Canceled",
    CheckIn: "Check-In",
    CheckOut: "Check-Out",
    NoShow: "No Show",
    Pending: "Pending",
    Completed: "Completed",
}

export const getBorderColor = (status) => {
  switch (status) {
      case 'Completed':
          return '#219653'
      default:
          return '#2F80ED'
  }

}