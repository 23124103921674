import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";

export const getmemshiplist = createAsyncThunk("getmemshiplist", async (status, { getState }) => {
  console.log('API called with status:', status); // Add this line
  let url = apis.get_membership_list;

  if (status && status !== "All") {
    url += `?status=${status}`;
  }

  try {
    const response = await axios.get(url, {
      headers: apiHeaders(),
    });

    if (response?.data?.success === "true") {
      return response?.data?.data;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
});




export const getmemshiplistdeatil = createAsyncThunk("getmemshiplistdeatil",  async (data) => {

  try {
    const response = await axios.get(apis.get_membership_list_detail+ data, {
      headers: apiHeaders(),
    });

    if (response?.data?.success === "true") {
      return response?.data?.data;
    } 
    else {
      return [];
    }
  } catch (err) {
    return [];   
  }
})
// invoice_details

export const getinvoice = createAsyncThunk("getinvoice",  async (data) => {

  try {
    const response = await axios.get(apis.invoice_details+ data, {
      headers: apiHeaders(),
    });

    if (response?.data?.success === "true") {
      return response?.data?.data;
    } 
    else {
      return [];
    }
  } catch (err) {
    return [];   
  }
})
export const getUserexsitingdata = createAsyncThunk("getUserexsitingdata", async (_, { getState }) => {
  const { search,  pageLimit, currentPage } = getState()?.membershipstore;
  try {
    const response = await axios.get(apis.get_user, {
      headers: apiHeaders(),
    });

    if (response?.data?.success === "true") {
      return response?.data?.data;
    
    } else {
      return [];
    }
  
  } catch (err) {
    return [];   
  }
});
// get_user
//  create subscription
export const createmembershipSubscription = createAsyncThunk("createmembershipSubscription", async (data) => {

  try {
      const response = await axios.post(apis.save_membership_subscription,{...data}, {
          headers: apiHeaders(),
      });

      return response?.data;

  } catch (err) {   

      return  err?.response  
  }
}
);

export const UpdatemembershipSubscription = createAsyncThunk("UpdatemembershipSubscription", async (data) => {

  try {
      const response = await axios.post(apis.update_membership_subscription,{...data}, {
          headers: apiHeaders(),
      });

      return response?.data;

  } catch (err) {   

      return  err?.response  
  }
}
);

export const cancelmembershipSubscription = createAsyncThunk("cancelmembershipSubscription", async (data) => {

  try {
      const response = await axios.post(apis.cancel_membership,{...data}, {
          headers: apiHeaders(),
      });

      return response?.data;

  } catch (err) {   

      return  err?.response  
  }
}
);

export const pausemembershipSubscription = createAsyncThunk("pausemembershipSubscription", async (data) => {

  try {
      const response = await axios.post(apis.pause_membership,{...data}, {
          headers: apiHeaders(),
      });

      return response?.data;

  } catch (err) {   

      return  err?.response  
  }
}
);

export const resumemembershipSubscription = createAsyncThunk("resumemembershipSubscription", async (data) => {

  try {
      const response = await axios.post(apis.resume_membership,{...data}, {
          headers: apiHeaders(),
      });

      return response?.data;

  } catch (err) {   

      return  err?.response  
  }
}
);



export const updatemember = createAsyncThunk('updatemember', async (data) => {
  return data;
});

export const membershipSlice = createSlice({ 
  name: "membershipSlice",  
  initialState: {
    membershiplist: [],
    pageLimit: 10,
    currentPage: 1,  
    userData:[],  
    membershipcreate:[],
    update:[],  
    search:'', 
    detail:[],
    cancel_member:[],
    appointmentInvoices:[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getmemshiplist.fulfilled, (state, action) => {
      state.membershiplist = action.payload;
    });   

    // createmembershipSubscription    
    builder.addCase(createmembershipSubscription.fulfilled, (state, action) => {
      state.membershipcreate = action.payload;
    });
    // 
    builder.addCase(UpdatemembershipSubscription.fulfilled, (state, action) => {
      state.update = action.payload;
    });
    // cancelmembershipSubscription
    builder.addCase(cancelmembershipSubscription.fulfilled, (state, action) => {
      state.cancel_member = action.payload;
    });

    builder.addCase(getUserexsitingdata.fulfilled, (state, action) => {
      state.userData = action.payload;
    });
    // getmemshiplistdeatil
    builder.addCase(getmemshiplistdeatil.fulfilled, (state, action) => {
      state.detail = action.payload;
    });
    // getinvoice
    builder.addCase(getinvoice.fulfilled, (state, action) => {
      state.appointmentInvoices = action.payload;
    });
  
    builder.addCase(updatemember.fulfilled, (state, action) => {
      const { pageLimit, currentPage ,search} = action?.payload;

      if (pageLimit) {
        state.pageLimit = pageLimit;
      }
      if (search){
        state.search = search === 'reset' ? '' : search ;
    }
      if (currentPage) {
        state.currentPage = currentPage;
      }
    });
  }
});

export default membershipSlice.reducer;
   