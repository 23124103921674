import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {apiHeaders} from "../../../lib/apiHeader";
import apis from "../../../lib/apis/apis";



export const getAllUserNotification = createAsyncThunk("getAllUserNotification", async (status, { dispatch }) => {
    try {
        const response = await axios.get(apis.customer_notification, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        } else {
            return [];
        }
    } catch (err) {
        return []
    }
})
export const readUserNotification = createAsyncThunk("readUserNotification", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.read_notification+`${id!=='all' ? `?notification_id=${id}`:""}`, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
           await dispatch(getAllUserNotification())
            return response?.data?.data;
        } else {
            return [];
        }
    } catch (err) {
        return []
    }
})


export const userNotificationSlice = createSlice({
    name: 'userNotificationSlice',
    initialState: {
        notifications: [],
        unreadCounter: 0,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllUserNotification.fulfilled, (state, action) => {

            state.notifications = action.payload?.notifications || []
            state.unreadCounter = action.payload?.unreadCounter || 0
        })
    }
})


export default userNotificationSlice.reducer