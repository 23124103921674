import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // oddProp: (props) => ({
  //   "&:nth-child(odd)": {
  //     backgroundColor: props.bgRow,
  //   },
  //   "&:nth-child(even)": {
  //     backgroundColor: '#D8F3FF',
  //   },
  // }),


  oddProp: (props) => ({
     
      backgroundColor: props.bgRow,
    
   
  }),
  

  odd: {
    "&:nth-child(odd)": {
      background: "#F5FCFF",
    },
  },



  // Table
  tableHead: {
    background: "#D8F3FF",
    borderRadius: "8.4769px",
  },
  tableLink: (props) => ({
    color: props.textcolor,
  }),
}));
export default useStyles;
