import React, {useState} from 'react';
import {Box, Button, FormHelperText, Grid, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import FormDateInput from "../../../../components/form/FormDateInput";
import {MdDeleteOutline} from "react-icons/md";
import {confirmation, handleDelete, parseTime, timeSlots} from "../../../../services/utills";
import * as yup from "yup";
import * as Yup from "yup";
import {useFormik} from "formik";
import {storeStaffBreakTime} from "../../../../store/clinics/clinicSettings/business-hours";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import {inputStyle} from "../../../../components/form/formInput";
import Loader from "../../../../components/loader/loader";




const StaffBreakTime = ({staffBreakTimes,staff_id}) => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()


    const validationSchema = yup.object({
        date: Yup.date().typeError("Staff holiday date is required").required("Staff holiday date is required"),
        start_time: yup
            .date()
            .transform((value, originalValue) =>
                originalValue ?  parseTime(originalValue) : null
            )
            .required("Start time is required").typeError("Start time is required"),
        end_time: yup
            .date()
            .transform((value, originalValue) =>
                originalValue ? parseTime(originalValue) : null
            )
            .required("End time is required").typeError("End time is required")
            .min(yup.ref("start_time"), "End time must be after start time"),
    });


    const {values, handleSubmit, setFieldValue,resetForm, handleChange, touched, errors} = useFormik({
        initialValues: {
            date: '',
            start_time: '',
            end_time: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)

            let res = await dispatch(storeStaffBreakTime({...data, provider_id: params?.clinic_id,staff_id:staff_id}))
            if (res?.payload?.errors) {
                const {errors} = res?.payload

                if (errors?.date) {
                    setFieldTouched("date", true)
                    setFieldError("date", errors?.date)
                }
                if (errors?.start_time) {
                    setFieldTouched("start_time", true)
                    setFieldError("start_time", errors?.start_time)
                }
                if (errors?.end_time) {
                    setFieldTouched("end_time", true)
                    setFieldError("end_time", errors?.end_time)
                }

            } else if (res?.payload?.success === "true") {
                resetForm()
            }

            setLoading(false)
        },
    });


    const handleDeleteStaffBreak = async (row) => {
        if (await confirmation()) {
            setLoading(true);
            let finalData = {
                id: row?.id,
                provider_id: params?.clinic_id,
                staff_id: staff_id,
            }
            await handleDelete(dispatch, "staffBreak", finalData);
            setLoading(false);
        }
    }


    return (
        <Grid container>
            {loading?<Loader fullSize/> :<></>}
            <Grid item xs={12} sx={{mt: 4}}>
                <Typography variant={'h4'} sx={{mb: 1}}>
                    Staff Break Time
                </Typography>
                <Typography variant={'body1'}>
                    Mark staff break time in calendar
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{mt: 2}}>
                <form onSubmit={handleSubmit}>

                    <Grid container sx={{justifyContent: 'space-between', display: 'flex'}}>
                        <Grid item xs={12} lg={5} mb={'16px'} >
                            <Autocomplete
                                name={'start_time'}
                                options={timeSlots}
                                getOptionLabel={(option) => option}
                                value={values?.start_time}
                                renderInput={(params) => {
                                    const { inputProps, helperText, error,...rest } = params;
                                    return(<TextField   {...rest}    inputProps={{ ...inputProps }} sx={inputStyle} placeholder={"Start Time"}/>
                                    )}}

                                onChange={(event, newValue) => {
                                    setFieldValue("start_time", newValue);
                                }}

                                // getOptionDisabled={getStartTimeOptionDisabled(selectedEndTime)}
                            />
                            {errors?.start_time && touched?.start_time && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors?.start_time}</FormHelperText>}

                        </Grid>
                        <Grid item xs={12} lg={5} mb={'16px'}  >
                            <Autocomplete
                                name={'end_time'}
                                options={timeSlots}
                                getOptionLabel={(option) => option}
                                value={values?.end_time}
                                renderInput={(params) => {
                                    const { inputProps, helperText, error,...rest } = params;
                                    return(<TextField   {...rest}    inputProps={{ ...inputProps }} sx={inputStyle} placeholder={"End Time"}/>
                                    )}}

                                onChange={(event, newValue) => {
                                    setFieldValue("end_time", newValue);
                                }}
                                // getOptionDisabled={getEndTimeOptionDisabled(selectedStartTime)}
                            />
                            {errors?.end_time && touched?.end_time && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors?.end_time}</FormHelperText>}

                        </Grid>
                        <Grid item xs={12} lg={5} mr={2}>
                            <FormDateInput
                                name={"date"}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end'}}>
                            <Button
                                sx={{
                                    backgroundColor: '#09ABF1',
                                    borderColor: '#09ABF1',
                                    color: '#fff',
                                    mt: '16px',
                                    height: '44px',
                                    "&:hover": {
                                        color: '#09ABF1',
                                        borderColor: '#09ABF1',
                                    }
                                }}
                                type={'submit'}
                                variant={'outlined'}>
                                Save staff break time
                            </Button>
                        </Grid>
                        {staffBreakTimes?.length > 0 && <Grid item xs={12} mt={4} lg={6}>
                            <Typography variant={'h5'}>
                                Staff break time List
                            </Typography>
                            {staffBreakTimes?.length > 0 && staffBreakTimes.map((item, index) => (
                                <Box key={index} sx={{display: 'flex', mt: 2, justifyContent: 'space-between'}}>
                                    <Typography>
                                        {item?.date}   from  {item?.start_time} - {item?.end_time}
                                    </Typography>
                                    <Tooltip title="Remove break time">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleDeleteStaffBreak(item)}
                                        >
                                            <MdDeleteOutline color={'#E53E31'} fontSize={'20px'}/>
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            ))}
                        </Grid>
                        }
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default StaffBreakTime;