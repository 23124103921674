import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../lib/apiHeader";




/************************ Product Category *********************************/

export const getAllProductCategory = createAsyncThunk("getAllProductCategory", async (data, { getState }) => {
    try {
        const response = await axios.get(apis.get_all_product_category, {
                headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});
export const getProductCategoryById = createAsyncThunk("getProductCategoryById", async (data, { getState }) => {

    try {
        const response = await axios.get(apis.get_all_product_category_by_id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const storeProductCategory = createAsyncThunk("storeProductCategory", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.store_product_category,{...data}, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === 'true'){
          await  dispatch(getAllProductCategory())
        }
        return response?.data;

    } catch (err) {

        return  err?.response
    }
});

export const updateProductCategory = createAsyncThunk("updateProductCategory", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.update_product_category,{...data}, {
            headers: apiHeaders(),
        });


        return response?.data;

    } catch (err) {

        return  err?.response
    }
});

/************************ Vendors *********************************/

export const getAllVendors = createAsyncThunk("getAllVendors", async (data, { getState }) => {
    try {
        const response = await axios.get(apis.get_all_vendors, {
                headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return {};
        }
    } catch (err) {
        return  {}
    }
});
export const getVendorById = createAsyncThunk("getVendorById", async (data, { getState }) => {

    try {
        const response = await axios.get(apis.get_vendors_by_id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const storeVendors = createAsyncThunk("storeVendors", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.store_vendors,{...data}, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === 'true'){
            await  dispatch(getAllVendors())
        }
        return response?.data;

    } catch (err) {

        return  err?.response
    }
});

export const updateVendors = createAsyncThunk("updateVendors", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.update_vendors,{...data}, {
            headers: apiHeaders(),
        });


        return response?.data;

    } catch (err) {

        return  err?.response
    }
});

/************************ Brand *********************************/

export const getAllBrands = createAsyncThunk("getAllBrands", async (data, { getState }) => {
    try {
        const response = await axios.get(apis.get_all_brands, {
                headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return {};
        }
    } catch (err) {
        return  {}
    }
});
export const getBrandById = createAsyncThunk("getBrandById", async (data, { getState }) => {

    try {
        const response = await axios.get(apis.get_brand_by_id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const storeBrand = createAsyncThunk("storeBrand", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.store_brand,{...data}, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === 'true'){
            await  dispatch(getAllBrands())
        }
        return response?.data;

    } catch (err) {

        return  err?.response
    }
});

export const updateBrand = createAsyncThunk("updateBrand", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.update_brand,{...data}, {
            headers: apiHeaders(),
        });

        return response?.data;

    } catch (err) {

        return  err?.response
    }
});

/************************ Product *********************************/

export const getAllProducts = createAsyncThunk("getAllProducts", async (data, { getState }) => {
    try {
        const response = await axios.get(apis.get_all_products, {
                headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return {};
        }
    } catch (err) {
        return  {}
    }
});
export const getProductById = createAsyncThunk("getProductById", async (data, { getState }) => {

    try {
        const response = await axios.get(apis.get_product_by_id+data, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const storeProduct = createAsyncThunk("storeProduct", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.store_product,data, {
            headers: apiHeadersImage(),
        });
        if (response?.data?.success === 'true'){
            await  dispatch(getAllProducts())
        }
        return response?.data;

    } catch (err) {

        return  err?.response
    }
});

export const updateProduct = createAsyncThunk("updateProduct", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.update_product,data, {
            headers: apiHeadersImage(),
        });
        if (response?.data?.success === 'true'){
            await  dispatch(getAllProducts())
        }

        return response?.data;

    } catch (err) {

        return  err?.response
    }
});

/************************ Product *********************************/

export const getAllPurchases = createAsyncThunk("getAllPurchases", async (data, { getState }) => {
    try {
        const response = await axios.get(apis.get_all_purchase, {
                headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return {};
        }
    } catch (err) {
        return  {}
    }
});
export const getPurchasesById = createAsyncThunk("getPurchasesById", async (data, { getState }) => {

    try {
        const response = await axios.get(apis.get_purchase_by_id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const storePurchases = createAsyncThunk("storePurchases", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.store_purchase,{...data}, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === 'true'){
            await  dispatch(getAllPurchases())
        }
        return response?.data;

    } catch (err) {

        return  err?.response
    }
});

export const updatePurchases = createAsyncThunk("updatePurchases", async (data,{dispatch}) => {

    try {
        const response = await axios.post(apis.update_purchase,{...data}, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === 'true'){
            await  dispatch(getAllProducts())
        }

        return response?.data;

    } catch (err) {

        return  err?.response
    }
});


/************************ Invoice Consume *********************************/

export const getAllConsumeInvoice = createAsyncThunk("getAllConsumeInvoice", async (data, { getState }) => {
    try {
        const response = await axios.get(apis.get_all_consume_invoice, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return {};
        }
    } catch (err) {
        return  {}
    }
});

export const getAllConsumeInvoiceSearch = createAsyncThunk("getAllConsumeInvoiceSearch", async (data, { getState }) => {
    try {
        const {start,end,product} = data
        const response = await axios.get(
            apis.search_consume_invoice+start+`&end_date=${end}&product_id=${product?.id || null}`,
            {
                headers: apiHeaders(),
            });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return {};
        }
    } catch (err) {
        return  {}
    }
});


export const inventorySlice = createSlice({
    name: "inventorySlice",
    initialState: {
        productCategories:[],
        productVendors:[],
        productBrands:[],
        products:[],
        purchase:[],
        invoiceConsume:[],

    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllProductCategory.fulfilled, (state, action) => {
            state.productCategories = action.payload ;
        });
        builder.addCase(getAllVendors.fulfilled, (state, action) => {
            state.productVendors = action.payload ;
        });
        builder.addCase(getAllBrands.fulfilled, (state, action) => {
            state.productBrands = action.payload ;
        });
        builder.addCase(getAllProducts.fulfilled, (state, action) => {
            state.products = action.payload ;
        });
        builder.addCase(getAllPurchases.fulfilled, (state, action) => {
            state.purchase = action.payload ;
        });
        builder.addCase(getAllConsumeInvoice.fulfilled, (state, action) => {
            state.invoiceConsume = action.payload ;
        });
        builder.addCase(getAllConsumeInvoiceSearch.fulfilled, (state, action) => {
            state.invoiceConsume = action.payload ;
        });
        

    },
});

export default inventorySlice.reducer;