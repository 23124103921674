// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdw-editor-toolbar{
    margin-bottom: 0!important;
}
.DraftEditor-editorContainer{
    background: #EDF2F7!important;
    border-bottom-left-radius: 7px!important;
    padding: 10px 20px!important;
    border-bottom-right-radius: 7px!important;
    min-height: 200px!important;
}
.rdw-editor-toolbar{
    border: 1px solid #EDF2F7!important;
    border-top-left-radius: 7px!important;
}
.public-DraftStyleDefault-block{
    margin: 0!important;
}`, "",{"version":3,"sources":["webpack://./src/components/rich-textfield/styles.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;AACA;IACI,6BAA6B;IAC7B,wCAAwC;IACxC,4BAA4B;IAC5B,yCAAyC;IACzC,2BAA2B;AAC/B;AACA;IACI,mCAAmC;IACnC,qCAAqC;AACzC;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".rdw-editor-toolbar{\n    margin-bottom: 0!important;\n}\n.DraftEditor-editorContainer{\n    background: #EDF2F7!important;\n    border-bottom-left-radius: 7px!important;\n    padding: 10px 20px!important;\n    border-bottom-right-radius: 7px!important;\n    min-height: 200px!important;\n}\n.rdw-editor-toolbar{\n    border: 1px solid #EDF2F7!important;\n    border-top-left-radius: 7px!important;\n}\n.public-DraftStyleDefault-block{\n    margin: 0!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
