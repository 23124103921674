        import React, {useEffect,  useRef, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAppointmentDetails, getAppointmentcalender, getconsentform} from "../../../store/crm/appointment";
import AppointmentDetails from "../appointment/appointment-details/appointmentDetails";
import {Box, Button, Divider, Grid, Link, Link as MuiLink, Typography} from "@mui/material";
import Loader from "../../../components/loader/loader";
import AppointmentStaffStatus from "../appointment/AppointmentStaffStatus/appointmentStaffStatus";
import AppointmentReschedule from "../../../components/card-appointment/AppointmentReschedule";
import CustomButtoncolor from "../../../components/custom-button/customColorButton";
import {
    content,
    handleEditReschedule,
    heading,
    ribbonwrapper,
    textul, wallet
} from "../../../components/card-appointment/CardAppointment";
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import TreatmentFormButton from "../../../components/card-appointment/treatmentForm";
import {PlayArrow} from "@mui/icons-material";
import {useNavigate} from "react-router";  
import ribbon from "../../../assets/images/ribbon.svg";
import redRibbon from "../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../assets/images/yellow-badg.svg";
import Waveloading from '../../loading/Waveloading';
import SetEmailModal from '../../../components/modal/SetEmailModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { Link } from 'reac';
import { FormLinkData } from '../../../store/user/chart-form';
import { MySwal } from '../../../services/utills';
import {mySwalButton} from  '../../../components/card-appointment/CardAppointment'
// import NotesModal from '../../../components/modal/NotesModal';
import { getAppointmentNotes } from '../../../store/crm/appointment/notes';
import { getCrmNotes } from '../../../store/crm/crm/crm-details';
import NoteModal from '../../../components/modal/NoteModal';
import CalenderNoteModal from '../../../components/modal/CalenderNotes';
export const boxwrapper = {
    border: "1px solid black",
    padding: "10px",
    height: "13vh",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "8px",
  };

  export const box = {
    display: "block",
    margin: "15px auto",
    
  };   
  const statusColorMapping = {

    Pending :'#56CCF2',
    Confirmed :'#323681',
    UnConfirmedPending :'#56CCF2',
    ConfirmedPending : '#323681',      
    CheckIn:'#F2C94C',
    Completed: '#219653',
    CheckOut : '#219653',
    Canceled : '#EB5757',
    Refund: '#EB5757',
    NoShow :'#9A9797',
    PendingConfirmation: '#56CCF2',

  };


const CalenderAppointmentDetail = ({appointment_id,fetchCalenderBookings,onRescheduleSuccess}) => {
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()   
    const navigate = useNavigate()
 
    const scrollableRef = useRef(null);

    const handleKeyDown = (event) => { 
        if (event.key === 'ArrowDown') {
            if (scrollableRef.current) {
                scrollableRef.current.scrollBy(0, 10); // Adjust the scroll value as needed
            }
        }
    };

    useEffect(() => {
        const scrollableElement = scrollableRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('keydown', handleKeyDown);
            scrollableElement.tabIndex = "0"; // Make the element focusable
            scrollableElement.style.outline = "none"; // Optional: to remove the focus outline
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []);

    // scroll code ends here

    const handleAppointmentClick = (url) => {
        navigate(url)
    }  
  
    const handleCellClick = (itemId) => {
        
        navigate(`/crm/customer-relationship-management`, {  
          state: { value: itemId, },
        })   
    };   
   
    const crmAppointmentStore = useSelector((state) => state?.crmAppointmentStore)
    const {appointmentDetail = [],consentform=[]} = crmAppointmentStore


    useEffect(() => {
        (async () => {
            setLoading(true)
            await dispatch(getAppointmentcalender(appointment_id)) 
            await dispatch (getconsentform(appointment_id))
          
            setLoading(false) 
        })()  
    }, [])            
           
    // useEffect(()=>{      
    //     (async ()=>{
    //         await dispatch(getCrmNotes(appointmentDetails?.customer?.id))
    //         console.log("calenderDetails",appointmentDetails?.customer?.id )
    //     })()
    // },[])

console.log("cosent form",appointmentDetail)
const handleRowClick = async (userid,formid) => {
    let confirm = await MySwal.fire({
        icon: 'question',
        title: 'Confirmation',
        showConfirmButton: false,
        html: <Box sx={{mb: 4}}>
            <Typography sx={{}}>
                Are you sure? You want to send Email .  
            </Typography>
            <Box sx={{mt: 3,}}>
                <Button
                    sx={{...mySwalButton }}
                    onClick={() => MySwal.clickConfirm()}
                >
                    Yes
                </Button>
                <Button
                    sx={{...mySwalButton}}
                    onClick={() => MySwal.close()}
                >
                    No
                </Button>
            </Box>
        </Box>
    })
    if (!!confirm?.isConfirmed) {
        const payload = {

            user_id: userid,
            form_id: formid
          };
      
        setLoading(true);
      dispatch(FormLinkData(payload));
      setLoading(false);
       
        }else {
            // setRescheduleModal(true)
        }
    }
    // if (onRescheduleSuccess) {
    //     onRescheduleSuccess();  
    //   }     
      const status = appointmentDetail?.status;
      const background = statusColorMapping[status] || 'gray';
      const color = statusColorMapping[status] || 'gray';
      const borderColor= statusColorMapping[status] || 'gray';
    return (
        <Box ref={scrollableRef} sx={{ minHeight: '300px', overflow: 'auto' }}> 
            {
                loading ? <Waveloading/> :
                    <Box sx={{background: '#F1F5F9'}}> 
                        {/* <Box
                            sx={{
                                display: "flex",
                                gap: '15px',
                                alignItems: 'center',
                                px: {sm:'35px', xs:'5px'},
                                pt: '25px',
                                mb:appointmentDetails?.status === 'Completed' ? '0px' : '15px',
                                flexWrap:{xs:'wrap',md:'nowrap'},
                            }}> */}

                            <Grid container sx={{ display:'flex', p:{sm:3, xs:1}, gap:1,'@media (max-width:319px)':{ gap:"6px"} }}>
                            {appointmentDetail?.status !== 'Completed' && appointmentDetail?.status !== 'Canceled' &&
                            <Grid item lg={1.5} md={2.1} xs={5.8} sm={3.8} sx={{ mt:1, ml:{sm:0, md:0    , xs:0, lg:1} }}>
                                <AppointmentStaffStatus
                                    fetchCalenderBookings={fetchCalenderBookings}
                                    appointment={appointmentDetail}  
                                    fwidth={'100%'}  
                                />
                            </Grid> 
                                }
                            {(appointmentDetail?.status === 'Pending' || appointmentDetail?.status === 'Confirmed' || appointmentDetail?.status === 'No Show') &&
                            <Grid item lg={1.5} md={2.1} xs={5.8} sm={3.8} sx={{ mt:1, ml:{sm:0, md:0    , xs:0, lg:1} }}>
                            <AppointmentStaffStatus
                               
                                label={"Check-In"}  
                                value={'CheckIn'}
                                mainColor={"#F2C94C"}
                                fetchCalenderBookings={fetchCalenderBookings}
                                appointment={appointmentDetail}
                                fwidth={'100%'}  
                            />
                              </Grid>  
                            }
                               {appointmentDetail?.status === 'CheckIn' &&
                              <Grid item lg={1.5} md={2.1} xs={5.8} sm={3.8} sx={{ mt:1, ml:{sm:0, md:0  , xs:0, lg:1} }}>
                                <AppointmentStaffStatus
                                    label={"Check-Out"} 
                                    value={'CheckOut'}
                                    mainColor={"#F2C94C"}
                                    appointment={appointmentDetail}
                                    fetchCalenderBookings={fetchCalenderBookings}
                                    fwidth={'100%'}
                                 />
                                </Grid>
                            }
                              {appointmentDetail?.status !== 'Void' && appointmentDetail?.status !== 'Canceled' && appointmentDetail?.status !== 'Completed' &&
                              <Grid item lg={1.5} md={2.1} xs={5.8} sm={3.8} sx={{ mt:1, ml:{sm:0, md:0  , xs:0, lg:1} }}>
                                <AppointmentReschedule appointment={appointmentDetail}  onRescheduleSuccess={onRescheduleSuccess} fwidth={'100%'} />

                              </Grid>  
                                }
                              { appointmentDetail?.status !== 'Void' && appointmentDetail?.status !== 'Canceled' && appointmentDetail?.status !== 'Completed' && 
                             <Grid item xl={2} lg={2.5} sm={3.8} md={2.8} xs={5.8} sx={{ mt:1, ml:{sm:0, md:0  , xs:0, lg:1} }}>
                              <CustomButtoncolor
                                mainColor={"#09ABF1"}
                                handleClick={() => handleEditReschedule(navigate, appointmentDetail)}
                                style={{borderRadius: "4px", padding: '10px 15px', width:'100%'}}       
                                val={"Edit Appointment"}
                                fwidth={'100%'}
                            />
                              </Grid>  
                            }
                              {appointmentDetail?.status !== 'Void' && appointmentDetail?.status !== 'Canceled' && appointmentDetail?.status !== 'Completed' && 
                              <Grid item lg={1.5} md={2.1} xs={5.8} sm={3.8} sx={{ mt:1, ml:{sm:0, md:0  , xs:0, lg:1} }}>
                              <AppointmentStaffStatus       
                                label={"Cancel/Refund"}   
                                value={'Canceled'}            
                                fetchCalenderBookings={fetchCalenderBookings}  
                                mainColor={"#EB5757"}          
                                appointment={appointmentDetail}      
                                fwidth={'100%'}           
                            />             
                              </Grid>   
                            }
                              <Grid item lg={1.5} md={2.1} xs={5.8} sm={3.8} sx={{ mt:1, ml:{sm:0, md:0  , xs:0, lg:1} }}>
                              <TreatmentFormButton data={appointmentDetail} fwidth={'100%'}/>  
                              </Grid>
                              
    {appointmentDetail?.status !== 'Completed' && appointmentDetail?.status !== 'No Show' && appointmentDetail?.status !== 'Canceled' &&
    <Grid item lg={1.5} md={2.1} xs={5.8} sm={3.8} sx={{ mt:1, ml:{sm:0, md:0, xs:0, lg:1} }}>
        <AppointmentStaffStatus    
            onRescheduleSuccess={onRescheduleSuccess}   
            label={"No Show"} 
            value={'No Show'}
            fetchCalenderBookings={fetchCalenderBookings}
            mainColor={"#9A9797"}
            appointment={appointmentDetail}
           
            fwidth={'100%'}
        />
    </Grid>    
} 
    
<Grid item xs={5.8} md={2} xl={1.5} sm={3} sx={{ mt:1, ml:{sm:0, md:0, xs:0, lg:1} }}>
     <SetEmailModal    
    customer_id={appointmentDetail?.customer?.id}   
    />               
 </Grid>       
{appointmentDetail?.status == 'Pending' &&
<Grid item xl={3} lg={2.5} md={2.5} xs={7} sm={4.5} sx={{ mt:1, ml:{sm:0, md:0  , xs:0, lg:1} }}>
 <AppointmentStaffStatus
 onRescheduleSuccess={onRescheduleSuccess}
 label={"Confirm Appointment"}
 fetchCalenderBookings={fetchCalenderBookings}     
 value={'Confirmed'}           
 mainColor={"#219653"}    
 customer_id={appointmentDetail?.customer?.id}
 appointment={appointmentDetail}
 fwidth={'100%'}    
/> 
</Grid>   
}       
<Grid  item xl={2} md={2.5} lg={2.5} xs={4.5} sm={3.8} sx={{ mt:1, ml:{sm:0, md:0  , xs:0, lg:1} }}>
<CalenderNoteModal customer_id={appointmentDetail?.customer?.id} appointment_id={appointmentDetail?.id}  />
</Grid>
</Grid>
                            {/* {appointmentDetails?.status !== 'Completed' && <AppointmentStaffStatus    
                                label={"Refund"}
                                isFetch
                                value={'Refund'}   
                                fetchCalenderBookings={fetchCalenderBookings}
                                mainColor={"#EB5757"}
                                appointment={appointmentDetails}
                            />} */}
                            
              
                        {/* </Box> */}  
 {  
    appointmentDetail?.type === "Virtual Consultation" ? (
        <Box
            sx={{ 
                background: '#F1F5F9',
                px: { sm: '35px', xs: '5px' }, 
                mt: appointmentDetail?.status === 'Completed' ? '35px' : '',       
                mb: '6px',
            }}       
        >   
            <Grid container sx={{ background: '#fff', padding:"15px", borderRadius: '7px' }}>
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography  sx={{ fontSize:"16px", fontWeight:"600", mb: '5px' }}>
                            Virtual Appointment Link:
                        </Typography>
                        <Typography variant="body1" sx={{ overflowWrap: 'anywhere' }}> 
                        <Link style={{cursor:"pointer"}}  
                        onClick={() => {
                    window.open(`${appointmentDetail?.doxy_link}`, '_blank') }}  >  
                            {appointmentDetail?.doxy_link}
                            </Link>    
                        </Typography>
                    </Box>
                </Grid>    
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Typography  sx={{ fontSize:"16px", fontWeight:"600", mb: '5px' }}>
                            User: 
                        </Typography>   
                        <Typography variant="body1">
                            {appointmentDetail?.doxy_username ? appointmentDetail.doxy_username : 'N/A'}
                        </Typography>  
                    </Box>  
                </Grid>   
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>   
                        <Typography sx={{ fontSize:"16px", fontWeight:"600", mb: '5px' }}>
                            Password:         
                        </Typography>  
                        <Typography variant="body1">
                            {appointmentDetail?.doxy_password ? appointmentDetail.doxy_password : 'N/A'}
                        </Typography>     
                    </Box>
                </Grid>  
            </Grid>
        </Box>
    ) : null
}
   
                        {/* <Box 
                         sx={{
                            background: '#F1F5F9', 
                            px: {sm:'35px', xs:'5px'},
                            mt: appointmentDetails?.status === 'Completed' ? '35px' : "",
                            mb:"6px"  
                        }}>  */}

                                      {/* </Box> */}
 
                       
                        <Box
                         sx={{
                            background: '#F1F5F9', 
                            px: {sm:'35px', xs:'5px'},
                            mt: appointmentDetail?.status === 'Completed' ? '35px' : ""
                        }}>
                            <Grid container sx={{background: '#fff', p: {sm:'35px', xs:'5px'}, borderRadius: '7px' }}>
                                <Grid item xs={12} md={4}>
                                    <Box>  
                                        <Typography>Customer Name:</Typography>
                                        <Box>           
                                            <div>
                                                <Typography sx={{  
                                                    ...textul,
                                                    textTransform: 'capitalize',
                                                    cursor:"pointer"
                                                   
                                                }} onClick={ () =>handleCellClick(appointmentDetail?.customer?.name)}>{appointmentDetail?.customer?.name}</Typography>
                                            </div>
                                            <Box sx={ribbonwrapper}>
                                                {appointmentDetail?.customer?.customer_batch && (
                                                    appointmentDetail?.customer?.customer_batch !== "New User" ? (
                                                    <>  
                                                        {appointmentDetail?.customer?.customer_batch.includes("Expire/Cancel") ? (
                                                            <>
                                                                <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                                                                <Typography style={{ color: "#FF0000" }}>
                                                                    {appointmentDetail?.customer?.customer_batch}
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {appointmentDetail?.customer?.customer_batch.includes("Declined") ? (
                                                                    <>
                                                                        <img style={{ marginTop: '4px' }} src={yellowRibbon} alt={'member'} />
                                                                        <Typography style={{  color: "#dcd90a" }}>
                                                                            {appointmentDetail?.customer?.customer_batch}
                                                                        </Typography>
                                                                   </> 
                                                                ) : (   
                                                                    <>
                                                                        <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                                                       <Typography style={{ color: "#219653" }}>
                                                                            {appointmentDetail?.customer?.customer_batch}
                                                                         </Typography>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography style={{ color: "#09ABF1" }}>
                                                            {appointmentDetail?.customer?.customer_batch}
                                                        </Typography>  
                                                    </>
                                                ))}
                                            </Box>
                                            <div>  
                                                <MuiLink href="#" color="inherit">
                                                    Member ID {appointmentDetail?.customer?.id}
                                                </MuiLink>
                                            </div>
                                        </Box>    
                                    </Box>
                                </Grid>
                                <Grid item sx={{px: '24px',display:{xs:'none',md:'block'}}}>
                                    <Divider
                                        orientation="vertical" 
                                        style={{borderColor: "#9A9797", height: "100%", width: "2px"}}
                                    />  
                                </Grid>
                                <Grid
                                    item 
                                    xs={12}
                                    md={4}      
                                    sx={{
                                        display: 'flex',  
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        margin:{xs:'15px 0px',md:'0px'}
                                    }}
                                >
                                    <Box sx={{display: "flex"}}>
                                        <Typography sx={{minWidth: {xs:'60px' ,sm:'100px'}}}>Wallet:</Typography>
                                        <Typography sx={content}>
                                            ${appointmentDetail?.customer?.wallet_point}
                                        </Typography>
                                    </Box>
                                    <Box sx={{display: "flex"}}>
                                        <Typography sx={{minWidth: {xs:'60px' ,sm:'100px'}}}>Phone:</Typography>
                                        <Typography sx={content}>{appointmentDetail?.customer?.phone}</Typography>
                                    </Box>
                                    <Box sx={{display: "flex"}}>
                                        <Typography sx={{minWidth: {xs:'60px' ,sm:'100px'}}}>Email:</Typography>
                                        <Typography sx={{ ...content, overflowWrap:'anywhere' }}>
                                            {appointmentDetail?.customer?.email}
                                            </Typography>
                                    </Box>
                                </Grid>   
                                <Grid item sx={{         
                                display:"flex", 
                                marginLeft:"auto",
                            '@media (max-width:340px)':{display:'flex',justifyContent:'end'}}}  
                                lg={2}
                                 md={2}    
                                 xs={12}>
                 <Box    
                        sx={{  
                            ...boxwrapper,
                            height: 'max-content',
                            borderColor,
                            cursor: 'pointer',
                        }}
                    >
                        <Box
                            sx={{
                                ...box,
                                height: 'max-content',
                                // background: appointmentDetails?.status === 'Confirmed' ? 'green' : 'red',
                                // my: appointment?.plan?.id ? '5px' : '',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointmentDetail?.id}`)}
                        >
                             
                            <Typography sx={{...content, color:"#fff", background ,borderRadius:"22px",padding:'2px 6px'}}>
                                Booking No
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{
                                    color
                                }}
                            >
                                {appointmentDetail.id}
                            </Typography>
                        </Box>
                    </Box>
                    </Grid>
                            </Grid>
                        </Box>   
                         
{
    consentform?.length > 0 ? consentform.map((form, index) => (
        <Box
            sx={{
                width: '100%',
                marginLeft:{sm:"35px", xs:'15px'}, 
                backgroundColor: '#FFFFFF' , 
                p: '5px 10px',
                borderRadius: '5px',
                mt: '10px',
                mb: '10px',
                display: 'flex',
                // height: { sm: '35px', xs: 'auto' },
                alignItems: 'center',
                cursor: 'pointer',
                justifyContent: 'space-between',
                border: form.is_submitted ? '1px solid #27AE60' : 'none',
                // maxWidth: form.is_submitted ? '93%' : '93%', 
                maxWidth: {md:'93%',sm:'88%', xs:'93%'}
            }}
            key={index}
            onClick={() => {
                if (form.is_submitted) {
                    window.open(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}?submit=1`);
                   
                } else {
                    window.open(`${window?.location?.origin}/crm/${form?.customer_id}/${form?.url}`, '_blank');
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <PlayArrow
                    sx={{
                        fontSize: '1.3rem',
                    }}
                />
                <Typography
                    sx={{
                        ml: '10px',
                    }}
                >
                    {form?.name}
                </Typography>
            </Box>
            <Box
                sx={{   
                    display: 'flex',
                    alignItems: 'center',    
                }}
            >
               
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AttachEmailIcon
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(form?.customer_id,form?.treatment_form_id)
                        }}
                        sx={{ 
                            fontSize: '1.3rem',
                        }}
                    />
                </div>
                {form.is_submitted && (
                    <React.Fragment>
                        <Typography
                            sx={{
                                fontSize: '0.8rem',
                                marginRight: '5px',
                                // color: '#27AE60',
                            }}
                        >   
                            {form.submitted_time ? form.submitted_time:""} 
                        </Typography>
                       
                        <CheckCircleIcon
                            sx={{
                                color: '#27AE60',
                                fontSize: '1.8rem',
                            }}
                        />
                    </React.Fragment>
                )}
            </Box> 
        </Box>
    )) : ""
}
                
 <AppointmentDetails appointmentDetails={appointmentDetail}/>   

     </Box>
            }
        </Box>
    );
}

export default CalenderAppointmentDetail;