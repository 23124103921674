

export const apiHeaders =()=> {
    const token =localStorage.getItem('token')

    return  {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
}
export const apiHeadersImage =()=> {
    const token = localStorage.getItem('token')
    return  {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
    }
}
