import React, { useState } from "react";
import ComingSoon from "../../../components/coming-soon";
import UserLayout from "../../user";
import {
  Box,
  Checkbox,
  Divider,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../components/loader/loader";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
  Container,
  Button,
} from "@mui/material";
import SubmitButton from "../../../components/custom-button/submitButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateUserAccount } from "../../../store/user/my-account";
import useStyles from "./style";
import FormDateInput from "../../../components/form/FormDateInput";
import printer from "../../../assets/images/printer.png";
import cloudIcon from "../../../assets/images/cloud-download.png";
import mail from "../../../assets/images/mail-outline.png";
import DateRange from "../../../components/date-range/date-range";
import { WalletReport, WalletTabelReports, getAppointmentTypes, getMemberCount, getMemberReports } from "../../../store/crm/reports";
import moment from "moment";
import { Link as MuiLink } from "@mui/material";
import Waveloading from "../../loading/Waveloading";
import { getAllClinic } from "../../../store/home";
import ribbon from "../../../assets/images/ribbon.svg";
import redRibbon from "../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../assets/images/yellow-badg.svg";
import CustomModal from "../../../components/modal/customModal";
import { useNavigate } from "react-router";
import {
  content,
  handleEditReschedule,
  heading,
  ribbonwrapper,
  textul, wallet
} from "../../../components/card-appointment/CardAppointment";
import AppointmentDetails from "../appointment/appointment-details/appointmentDetails";
import { getAppointmentDetails } from "../../../store/crm/appointment";
import { getProviderStaff } from "../../../store/crm/staff";

function createData(
  name,
  customerName,
  email,
  carbs,
  protein,
  memberDate,
  memberPlan,
  walletBalace
) {
  return {
    name,
    customerName,
    email,
    carbs,
    protein,
    memberDate,
    memberPlan,
    walletBalace,
  };
}
const rows = [
  createData(
    1,
    "Sonia Akhtar",
    "sonitapk@yahoo.com",
    "(647) 513-5747",
    "2023-03-08 20:15:25",
    "2023-03-16",
    "Free Customer",
    "$ 311.88"
  ),
  createData(
    2,
    "Michelle Betton",
    "sonitapk@yahoo.com",
    6477408632,
    "2023-03-08 20:15:25",
    "2023-03-30",
    "Free Customer",
    "$ 77.97"
  ),
  createData(
    3,
    "Sulaiman Ahmad",
    "sul_11@icloud.com",
    4372437297,
    "2023-01-31 19:59:48",
    "2022-10-17",
    "$69 Plan",
    "	$ 433.92"
  ),
  createData(
    4,
    "Rosa Turchio",
    "rosa@cpcanada.com",
    6479893132,
    "	2023-01-06 19:36:14",
    "2023-01-05",
    "Free Customer",
    "$ 336.74"
  ),
  createData(
    5,
    "	orothy kubska",
    "dkubska@gmail.com",
    "+1647-667-9599",
    "2022-12-15 15:44:09",
    "2022-08-12",
    "Free Customer",
    "$ 138.99"
  ),
];

export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginRight: "5px",
  marginLeft: "5px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};
export const box = {
  display: "block",
  margin: "15px auto",
};
export const selectStyle = {
  width:"100%",
  background: "#fff",
  borderRadius: "4px",
  height: "44px",
  marginRight: "5px",
  marginLeft: "5px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",

    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};
export const boxwrapper = {
  border: "1px solid black",
  padding: "10px",
  height: "13vh",
  alignItems: "center",
  textAlign: "center",
  borderRadius: "8px",
};

const statusColorMapping = {

  Pending: '#56CCF2',
  Confirmed: '#323681',
  UnConfirmedPending: '#56CCF2',
  ConfirmedPending: '#323681',
  CheckIn: '#F2C94C',
  Completed: '#219653',
  CheckOut: '#219653',
  Canceled: '#EB5757',
  Refund: '#EB5757',
  NoShow: '#9A9797',
  PendingConfirmation: '#56CCF2',
  
};
 
const MemberReport = () => {
  const [loadingSecondTable ,setloadingSecondTable ] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);   
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedClinicType, setSelectedClinicType] = useState([]);
  const crmAppointmentStore = useSelector((state) => state?.crmAppointmentStore)
  const { appointmentDetails = [] } = crmAppointmentStore
  const [appointmentDetail, setAppointmentDetail] = useState(null)
  const classes = useStyles();
  const [search, SetSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const reportsStore = useSelector((state) => state?.reportsStore);
  const { walletReports = [], wallet = [] ,appointmentTypes = [], } = reportsStore;
  const [selectedBookingType, setSelectedBookingType] = useState("");
  const homeStore = useSelector(state => state?.homeStore);
  // const [selectedClinicType, setSelectedClinicType] = useState("");    
  const { clinic } = homeStore;
  const [selectedRadio, setSelectedRadio] = useState("Booking");
console.log("data in detail",wallet)
const [staff, setStaff] = React.useState('');
const crmStaffStore = useSelector(state => state?.crmStaffStore)
const {crmStaffs = []} = crmStaffStore

useEffect(() => {
  dispatch(getProviderStaff());
}, []);
const handleChange = (event) => {
  setStaff(event.target.value); 
};
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };
  const handleBookingTypeChange = (event) => {
    setSelectedBookingType(event.target.value);
  };

  const handleAppointmentClick = (url) => {
    navigate(url)
  }
  const validationSchema = Yup.object().shape({
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().required("End Date is required"),
  });

  const navigate = useNavigate()
  
  const handleClinicChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedClinicType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  useEffect(() => {
    (async () => {
      dispatch(getAppointmentTypes())
      dispatch(getAllClinic())
    })()
  }, [])
  const today = new Date();
  const formik = useFormik({   
    initialValues: {
      start_date: today,  
      end_date: today,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        start_date: values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : null,
        end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null,
        date_type: selectedRadio,
        staff_id :staff,

      };    
      if (selectedBookingType) {
        payload.booking_type = selectedBookingType;
      }    
   
      if (selectedStatus) {   
        payload.status = selectedStatus;
         }
   
         console.log("values",values)
         if (selectedClinicType && selectedClinicType.length > 0) {
          payload.provider_ids = selectedClinicType; // Assuming your API can handle an array of ids
          // If your API expects a string of comma-separated ids, you might do something like:
          // payload.provider_ids = selectedClinicType.join(',');
        }
      
      try {   
        setSelectedRow(null);
        setLoading(true);
        await dispatch( WalletReport(payload))
        setLoading(false);  
        setDataFetched(true);
      } catch (error) {  
        console.error(error);
      }
    },  
  });

  // 
  const handleSearchChange = (event) => {
    SetSearch(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleRowClick = async(startDate, endDate, type) => {
    setSelectedRow(true)
    const payload = {
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      type: type,
      date_type: selectedRadio,
      booking_type: selectedBookingType,
      status: selectedStatus,
      staff_id :staff,

    };  
  
    if (selectedClinicType && selectedClinicType.length > 0) {
      payload.provider_ids = selectedClinicType; // Assuming your API can handle an array of ids
      // If your API expects a string of comma-separated ids, you might do something like:
      // payload.provider_ids = selectedClinicType.join(',');
    }
  

    try { 
      setloadingSecondTable(true)
    await dispatch(WalletTabelReports(payload)); 
    setloadingSecondTable(false);  


    } catch (error) {
      console.error(error);
    }
  };  
  const handleAppointmentDetail = async (id) => {

    let { payload } = await dispatch(getAppointmentDetails(id))

    if (payload?.id) {
      setAppointmentDetail(payload)
    } else {
      setAppointmentDetail(null)
    }
  }

  
  // console.log("wallet report",walletReports)
  const status = appointmentDetails?.status;
  const background = statusColorMapping[status] || 'gray';
  const color = statusColorMapping[status] || 'gray';
  const borderColor = statusColorMapping[status] || 'gray';    
  return (     
    <>
      <Container maxWidth="xl" sx={{ ml: 0, p: "0 !important" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          sx={{
            alignItems: { md: "start", xs: "center" },
            justifyContent: "space-between",
            mt: 3,   
          }}   
        >
          <Grid
            item  
            lg={12}
            md={12}
            xs={12}
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            {/* <FormControl
              sx={{
                width: { xs: "100%", sm: "unset", md: "unset" },
                mt: { xs: 2, sm: 1 },
              }}
            >
              <FormLabel>Search</FormLabel>
              <TextField
                variant="outlined"
                name={"email"}
                sx={inputStyle}
                placeholder={"Service Name"}
                value={search}
                onChange={handleSearchChange}
              />
            </FormControl> */}  

         <FormControl   
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },     
                  mt: { xs: 2, sm: 1, lg: 2 },   
                }}  
              >      
      <FormLabel>Select Date</FormLabel> 
        <DateRange
        setStartDate={(date) => formik.setFieldValue('start_date', date)}
        startDate={formik.values.start_date}
        setEndDate={(date) => formik.setFieldValue('end_date', date)}
        endDate={formik.values.end_date}  
        onBlur={formik.handleBlur}
      />

 {(formik.touched.start_date || formik.touched.end_date) && (!formik.values.start_date || !formik.values.end_date) && (
   <div style={{ color: "red" }}>Date field is required</div>   
  )}   
              </FormControl>
            {/* clinic */}   
            {/* <FormControl
                sx={{   
                  minWidth: { xs: "100%", sm: "220px" },
            marginTop:"19px"
                }}
              >   
                <FormLabel>Clinic</FormLabel>
                <Select 
                  value={selectedClinicType}
                  selectedBookingType
                  onChange={ handleClinicChange}
                  displayEmpty
                  sx={selectStyle}
                >    
                  <MenuItem value="">Select Clinic</MenuItem>  
                  {clinic?.data?.length &&
                    clinic?.data?.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            <FormControl   
  sx={{
    minWidth: { xs: "100%", sm: "220px" },
    marginTop: "19px",
    marginRight:"4px"
  }} 
>
  <FormLabel>Clinic</FormLabel>
  <Select
    multiple
    value={selectedClinicType}
    onChange={handleClinicChange}
    input={<OutlinedInput label="Clinic" />}
    renderValue={(selected) => {
      // Check if more than one clinic is selected
      if (selected.length > 1) {
        // Find the name of the first selected clinic and append '...'
        const firstClinicName = clinic?.data.find(c => c.id === selected[0])?.name;
        return `${firstClinicName}, ...`;
      } else if (selected.length === 1) {
        // Just find and return the name of the single selected clinic
        return clinic?.data.find(c => c.id === selected[0])?.name;
      }
      return '';
    }}
    displayEmpty
    sx={selectStyle}
  >
    <MenuItem disabled value="">
      <em>Select Clinic</em>
    </MenuItem> 
    {clinic?.data?.map((type) => (
      <MenuItem key={type.id} value={type.id}>
        <Checkbox checked={selectedClinicType.indexOf(type.id) > -1} />
        <ListItemText primary={type.name} />
      </MenuItem>
    ))}
  </Select>
</FormControl>

            <FormControl
                sx={{
                  minWidth: { xs: "100%", sm: "220px" },
                  marginTop:"19px",
                  marginRight:"4px"
                }}
              > 
                <FormLabel>Booking Type</FormLabel>
                <Select
                  value={selectedBookingType}
                  onChange={handleBookingTypeChange}
                  displayEmpty
                  sx={selectStyle}
                >
                  <MenuItem value="">Select Booking Type</MenuItem>
                  {appointmentTypes?.length &&
                    appointmentTypes?.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            <FormControl sx={{ minWidth: { xs: '100%', sm: '220px' },   marginTop:"19px" }}> 
                <FormLabel>Status</FormLabel>
                <Select
                  value={selectedStatus}   
                  onChange={handleChangeStatus}    
                  displayEmpty
                  sx={selectStyle}
                >
                  <MenuItem value="All">Status</MenuItem>
                  <MenuItem value="Paid">Paid</MenuItem>
                  <MenuItem value="UnPaid">UnPaid</MenuItem>
                  <MenuItem value="CheckOut">Completed</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="No Show">No show</MenuItem>  
                  <MenuItem value="Cancel">Cancel</MenuItem>  
                  <MenuItem value="Void">Void</MenuItem>
                  <MenuItem value="Confirmed">Confirmed</MenuItem>
                </Select>
              </FormControl> 
              <FormControl    
                sx={{    
                  minWidth: { xs: "100%", sm: "220px" },  
                  maxWidth: { xs: "100%", sm: "220px" },   
                  mt: { xs: 2, sm: 1 },  
                }}
              >     
                <FormLabel>Staff</FormLabel>  
                <Select
               style={{ marginTop:"10px",marginLeft:"10px" }}
                  value={staff}             
                  onChange={handleChange}
                  displayEmpty
                  sx={selectStyle}
                >
                   {crmStaffs?.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>     
                  ))}   
                </Select>
              </FormControl> 
          </Grid>
          <Grid item lg={7.8} md={6.9} xs={12} sx={{ display: "flex", flexWrap:{sm:'nowrap', xs:'wrap'} }}>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              value={selectedRadio}
              onChange={handleRadioChange}
              defaultValue="top"
              sx={{ mt: 2 }}
            >
              <FormControlLabel
                value="Created"
                control={<Radio />}
                sx={{ mr: { xs: 1, md: 5 } }}
                label="By Created Date"
              />
              <FormControlLabel
                value="Booking"
                control={<Radio />}
                label="By Booking  Date"
              />
            </RadioGroup>
            <Box sx={{ mt: 2, ml: {sm:3, xs:0} }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Fetch Report
              </Button>
            </Box>
          </Grid>
        </Grid>  
   </form>
      </Container>   
      {loading ? (
        <Waveloading />
      ) : dataFetched ? (  
        <>
          <Box sx={{ ml: 0, p: "0 !important",mt:4 }}>
          <TableContainer component={Paper} >
              <Table sx={{}} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: "700",
                        borderBottom: 0,
                      }}  
                    >
                      Appointment Type 
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                      Count
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                      Total <span style={{ fontSize: "12px" }}>(exc tax and wallet)</span> 
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
  
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                    onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, walletReports?.data?.treatments?.type)}
                  >
                    <TableCell component="th" scope="row" className={classes.tableLink} sx={{ cursor: 'pointer' }}>
                      Click Here! Treatment 
                    </TableCell> 
                    <TableCell align="left"> 
                      <Typography
                        variant="body1"   
                        color="initial"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#00BD06",
                          fontSize:"20px"
                        }}
                      >
                        {walletReports?.data?.treatments?.count || 0} 
                      </Typography>
                    </TableCell>
                    <TableCell align="left">

                      <Typography variant="body1" color="initial" fontSize={ "20px" }>
                        $ {walletReports?.data?.treatments?.total || 0} 

                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow 
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                    onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, walletReports?.data?.in_person_consultation?.type)}
                  >
                    <TableCell component="th" scope="row" className={classes.tableLink} sx={{ cursor: 'pointer' }}>
                      Click Here! In Person Consultation
                    </TableCell>
                    <TableCell align="left">
                      <Typography       
                        variant="body1"
                        color="initial"   
                        sx={{
                          display: "flex", 
                          alignItems: "center",  
                          color: "#00BD06",
                          fontSize:"20px"
                        }}
                      >
                     {walletReports?.data?.in_person_consultation?.count || 0}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">

                      <Typography variant="body1" color="initial" fontSize={ "20px" }>
                      $ {walletReports?.data?.in_person_consultation?.total || 0}

                      </Typography>
                    </TableCell>
                  </TableRow>    
                  <TableRow 
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                    onClick={() => handleRowClick(formik.values.start_date, formik.values.end_date, walletReports?.data?.virtual_consultation?.type)}
                  >
                    <TableCell component="th" scope="row" className={classes.tableLink} sx={{ cursor: 'pointer' }}>
                      Click Here! Virtual Consultation

                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="body1"
                        color="initial"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#00BD06",
                        }}
                      >
                       {walletReports?.data?.virtual_consultation?.count || 0}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">   

                      <Typography variant="body1" color="initial" fontSize={ "20px" }>
 $ {walletReports?.data?.virtual_consultation?.total || 0}

                      </Typography>
                    </TableCell>
                  </TableRow>
  
                </TableBody> 
              </Table>
            </TableContainer>
          </Box>

          {/* Buttons */}  

          {selectedRow ? (  
         <Container
            maxWidth="xl"
            sx={{ marginLeft: 0, mt: 4, p: "0 !important" }}  
          >
                {loadingSecondTable ? ( 
              <Waveloading />
            ) : ( 
            <TableContainer
              component={Paper}
              sx={{ mt: 2, boxShadow: "none", maxHeight: "650px" }}
            >  
              <Table sx={{}} aria-label="simple table"> 
                <TableHead className={classes.tableHead}>
                  <TableRow sx={{}}>
                    <TableCell
                      align="left" 
                      sx={{
                        fontWeight: "700",
                        borderBottom: 0,
                        minWidth: "160px",
                        width: "100px",
                      }}
                    >
                   Booking Ref
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                      Customer 
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                      Customer Contact
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                      Service Name
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                      Price
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                    Type
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                     Date
                    </TableCell>
                   
                    <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                      Wallet Used
                    </TableCell> 
                     <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                     Member Clinic
                    </TableCell> <TableCell
                      align="left"
                      sx={{ fontWeight: "700", borderBottom: 0 }}
                    >
                     Remaining Wallet 
                    </TableCell>
                  </TableRow>
                </TableHead> 
    <TableBody> 
  
 {wallet?.data?.bookings?.length ? (      
    wallet?.data?.bookings.map((item, index) => (
  

                      <TableRow
                        key={index}    
                         sx={{  
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        className={classes.odd}   
                      > 
                        <TableCell 
                          component="th"
                          scope="row"
                          className={classes.tableLink}  
                          sx={{ cursor: 'pointer' }}
                          onClick={() => item?.id && handleAppointmentDetail(item?.id)}
                        >
                          {item?.id}       
                        </TableCell>
                        <TableCell
                          align="left"
                          // className={classes.tableLink}
                          sx={{ cursor: "pointer" }}
                        >
                     {item?.customer?.customer_batch && (
                                item?.customer?.customer_batch !== "New User" ? (
                                <>
                                  {item?.customer?.customer_batch.includes("Expire/Cancel") ? (
                                    <div style={{ display: "flex", alignItems: "start" }}>
                                      <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                                      <Typography style={{ color: "#ff0000" }}>
                                        {item?.customer?.customer_batch}
                                      </Typography>
                                    </div>
                                  ) : (
                                    <>
                                      {item?.customer?.customer_batch.includes("Declined") ? (
                                        <div style={{ display: "flex", alignItems: "start" }}>
                                          <img style={{ marginTop: '4px' }} src={yellowRibbon} alt={'member'} />
                                          <Typography style={{  color: "#dcd90a" }}>
                                            {item?.customer?.customer_batch}
                                          </Typography>
                                        </div>
                                      ) : (
                                        <div style={{ display: "flex", alignItems: "start" }}>
                                          <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                          <Typography style={{ color: "#219653" }}>
                                            {item?.customer?.customer_batch}
                                          </Typography>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Typography style={{ color: "#09ABF1" }}>
                                    {item?.customer?.customer_batch}
                                  </Typography>
                                </>
                              ))}
                         <Typography
                                className={classes.tableLink}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/profile-info/${item?.customer?.id}`)}
                              >
                                {item?.customer?.name}
                              </Typography>
                        </TableCell>   
  
                        <TableCell align="left" className={classes.tableLink}>
                          {item?.customer?.email}
                        </TableCell>  
                        <TableCell align="left" >
                          {item?.service_name}
                        </TableCell>
                        <TableCell align="left" >
                          {item?.services_total_cost}  
                        </TableCell> 
                        <TableCell align="left" >
                        {item?.booking_type}  
                        </TableCell>
                        <TableCell align="left"> {item?.date}</TableCell>
                        <TableCell align="left"> {item?.wallet_used}</TableCell> 
                        <TableCell align="left"> {item?.provider?.name}</TableCell>
                        <TableCell align="left">{item?.remaining_wallet_balance}</TableCell>   

                      </TableRow>
                    ))         
                  ) : ( 
                    <TableRow>  
                      <TableCell   
                        sx={{
                          height: "300px",
                        }}
                        colSpan={9}
                        align="center"
                      >  
                        Member Report data not found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
          
              </Table>
            </TableContainer>
            )}
             
          </Container> 
          ):null}
        </>
      ) : (
        ""
      )} 
        <CustomModal
        customWidth={"1000px"}
        open={appointmentDetail !== null}
        setOpen={() => setAppointmentDetail(null)}
      >  
        <Box sx={{ background: '#F1F5F9' }}>
          <Box   
            sx={{
              background: '#F1F5F9',
              px: {xs:'10px',sm:'35px'},
              mt: appointmentDetails?.status === 'Completed' ? '35px' : "20px"
            }}>
            <Grid container sx={{ background: '#fff', p: {xs:'10px',sm:'35px'}, borderRadius: '7px', flexDirection:{xs:'column',md:'row'} }}>
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography>Customer Name:</Typography>
                  <Box>       
                    <div>
                      <Typography sx={{
                        ...textul,
                        textTransform: 'capitalize',
                        cursor: "pointer"
                      }} onClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/profile-info/${appointmentDetails?.customer?.id}`)}>{appointmentDetails?.customer?.name}</Typography>
                    </div>
                    <Box sx={ribbonwrapper}>
                      {
                        appointmentDetails?.customer?.customer_batch && (
                        appointmentDetails?.customer?.customer_batch !== "New User" ? (
                        <>
                          {appointmentDetails?.customer?.customer_batch.includes("Expire/Cancel") ? (
                            <>
                              <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                              <Typography style={{ color: "#FF0000" }}>
                                {appointmentDetails?.customer?.customer_batch}
                              </Typography>
                            </>
                          ) : (
                            <>
                              {appointmentDetails?.customer?.customer_batch.includes("Declined") ? (
                                <>
                                  <img style={{ marginTop: '4px' }} src={yellowRibbon} alt={'member'} />
                                  <Typography style={{  color: "#dcd90a" }}>
                                    {appointmentDetails?.customer?.customer_batch}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                  <Typography style={{ color: "#219653" }}>
                                    {appointmentDetails?.customer?.customer_batch}
                                  </Typography>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Typography style={{ color: "#09ABF1" }}>
                            {appointmentDetails?.customer?.customer_batch}
                          </Typography>
                        </>
                      ))}
                    </Box>
                    <div>
                      <MuiLink href="#" color="inherit">
                        Member ID {appointmentDetails?.customer?.id}
                      </MuiLink>
                    </div>
                  </Box>  
                </Box>
              </Grid>
              <Grid item sx={{ px: '24px', dispaly:{xs:'none',md:'block'} }}>
                <Divider
                  orientation="vertical"
                  style={{ borderColor: "#9A9797", height: "100%", width: "2px" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={3}
                sx={{

                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  my: {xs:'15px',md:'0px'}
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ minWidth: '100px' }}>Wallet:</Typography>
                  <Typography sx={content}>
                    ${appointmentDetails?.customer?.wallet_point}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ minWidth: '100px' }}>Phone:</Typography>
                  <Typography sx={content}>{appointmentDetails?.customer?.phone}</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ ...heading, minWidth: '100px' }}>Email:</Typography>
                  <Typography sx={content}>{appointmentDetails?.customer?.email}</Typography>
                </Box>
              </Grid>
              <Grid item sx={{ display: "flex", marginLeft:{xs:"none",md: "auto"} }} xs={12} sm={4} md={2}>
                <Box
                  sx={{
                    ...boxwrapper,
                    height: 'max-content',
                    borderColor,
                    cursor: 'pointer',
                  }}
                >
                  <Box
                    sx={{
                      ...box,
                      height: 'max-content',
                      // background: appointmentDetails?.status === 'Confirmed' ? 'green' : 'red',
                      // my: appointment?.plan?.id ? '5px' : '',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointmentDetails?.id}`)}
                  >

                    <Typography sx={{ ...content, color: "#fff", background, borderRadius: "22px", padding: '2px 6px' }}>
                      Booking No
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color
                      }}
                    >
                      {appointmentDetails.id}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <AppointmentDetails appointmentDetails={appointmentDetails} />
        </Box>
      </CustomModal>
    </>
  );
};

export default MemberReport;
