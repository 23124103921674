import React from 'react';
import './styles.scss';
import {Button} from "@mui/material";

const FormButton = ({text,disable}) => {
    return (
        <Button
            color="primary"
            variant="contained"
            fullWidth
            disabled={!!disable}
            type="submit"
            sx={{
                height:"44px"
            }}
        >
            {text}
        </Button>
    );
};

export default FormButton;