import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../../lib/apiHeader";




export const clinicstaff = createAsyncThunk('clinicstaff', async (provider_id,{getState,dispatch}) => {
    const { search, pageLimit, currentPage } = getState().clinicsStaffSliceStore; 
    try {
        const response = await axios.get(apis.clinic_staff+`${provider_id}&page=${currentPage}&limit=${pageLimit}`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){   
            return response?.data?.data
        }
        if (response?.data?.success === false) {

            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

// 
export const getAllClinicsStaff = createAsyncThunk('getAllClinicsStaff', async (provider_id,{dispatch,getState}) => {
    const { search, pageLimit, currentPage } = getState().clinicRoomsStore; 
    try {

        const response = await axios.get(apis.get_clinic_staff+`${provider_id}?page=1&limit=1000`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

export const getStaffScheduleData = createAsyncThunk('getStaffScheduleData', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.clinic_staff_schedule,{...data}, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})



export const storeClinicsStaff = createAsyncThunk('storeClinicsStaff', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_clinics_staff, data?.data,{
            headers: apiHeadersImage()
        })
        if (response?.data?.success ==='true'){
            await  dispatch( getAllClinicsStaff(data.provider_id))
            toast.success("Staff Added Successfully",{duration:3000}) 
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const getClinicsStaffByID = createAsyncThunk('getClinicsStaffByID', async (data) => {
    try {
        const response = await axios.get(apis.get_staff_by_id+`provider_id=${data?.provider_id}&id=${data?.id}`,{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

export const updateClinicsStaff = createAsyncThunk('updateClinicsStaff', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_clinics_staff, data?.data,{
            headers: apiHeadersImage()
        })

        if (response?.data?.success ==='true'){
            await  dispatch( getAllClinicsStaff(data.provider_id))
            toast.success("Staff updated Successfully",{duration:3000})
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const deleteClinicsStaff = createAsyncThunk('deleteClinicsStaff', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_clinic_staff+data?.id,{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllClinicsStaff(data?.provider_id))
            toast.success("Clinic Staff Deleted Successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})

export const updatestaff = createAsyncThunk('updatestaff', async (data) => {
    return data
  })

export const clinicsStaffSlice = createSlice({
    name: 'clinicsStaffSlice',
    initialState: {
        staffs: [],
        meta: {},
        links: {},
        scheduleData: [],
        pageLimit:10,
        currentPage:1,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllClinicsStaff.fulfilled, (state, action) => {
            state.staffs = action.payload
          
        })
        builder.addCase(clinicstaff.fulfilled, (state, action) => {
            state.staffs = action.payload
            state.meta = action.payload.meta
            state.links = action.payload.links
            state.currentPage = action.payload?.meta?.current_page;
        })
        
        builder.addCase(getStaffScheduleData.fulfilled, (state, action) => {
            state.scheduleData = action.payload
        })
   
        builder.addCase(updatestaff.fulfilled, (state, action) => {
            const { search, pageLimit, currentPage } = action.payload; 
            if (pageLimit){  
                state.pageLimit = pageLimit ;
            } 
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
           
        });

    }
})


export default clinicsStaffSlice.reducer