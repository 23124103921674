import React from 'react';
import './styles.scss'
const PublicLayout = ({children}) => {
    return (
        <div className={'pb-container'}>
            {children}
        </div>
    );
};

export default PublicLayout;