// ** MUI imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import {hexToRGBA} from "../../services/utills";

// ** Util Imports

const StepperWrapper = styled(Box)(({ theme }) => ({
    '& .MuiStep-root': {
        '& .step-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
       
    },
    '& .step:last-child .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root, & .step.step-with-margin .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root': {
        [theme.breakpoints.down('sm')]:{
            marginTop: '18px',
        }
    
    },
    '& .MuiStep-root': {
        '& .step-label': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
       
                   '& .MuiStepLabel-label': {
            fontWeight: 500,
            fontSize: '0.875rem',
            letterSpacing: '0.1px',
            color: theme.palette.text.primary,
            '@media (max-width:400px)':{
                fontSize:'13px'
            },
            '@media (max-width:366px)':{
                fontSize:'12px'
            },
            '@media (max-width:345px)':{
                fontSize:'11px'
            },
            '@media (max-width:321px)':{
                fontSize:'10px'
            }
        },
          
        '& .step-number': {
            fontWeight: 600,
            letterSpacing: 0.25,
            fontSize: '2.125rem',
            marginRight: theme.spacing(2.5),
            color: theme.palette.text.primary
        },
        '& .step-title': {
            fontWeight: 500,
            fontSize: '0.875rem',
            letterSpacing: '0.1px',
            color: theme.palette.text.primary,
            '@media (max-width:600px)':{
                fontSize:'13px'
            }
        },
        '& .step-subtitle': {
            fontWeight: 400,
            fontSize: '0.75rem',
            letterSpacing: '0.4px',
            color: theme.palette.text.secondary
        },
        "& .MuiStepLabel-root":{
            flexDirection:"column-reverse",

            "& .MuiStepLabel-alternativeLabel":{
                marginTop:0,
                marginBottom:'8px',
                fontWeight:'bold'
            }
           

        },
        '& .MuiStepLabel-root.Mui-disabled': {

            '& .step-number': {
                color: theme.palette.text.disabled
            }
        },
        '& .Mui-error': {
            '& .MuiStepLabel-labelContainer, & .step-number, & .step-title, & .step-subtitle': {
                color: theme.palette.error.main
            }
        }
    },
    '& .MuiStepConnector-root': {
        '& .MuiStepConnector-line': {
            borderWidth: 3,
            borderRadius: 3
        },
        '&.Mui-active, &.Mui-completed': {
            '& .MuiStepConnector-line': {
                borderColor: theme.palette.primary.main,
                '@media (max-width:600px)':{
                    marginTop:'18px',
                }
            }
        },
        '&.Mui-disabled .MuiStepConnector-line': {
            borderColor: hexToRGBA(theme.palette.primary.main, 0.12)
        }
    },
    '& .MuiStepper-alternativeLabel': {
       
        '& .MuiStepConnector-root': {
            top: 47,
            left:" calc(-50% + 12px)",
            right: "calc(50% + 12px)",

            '@media (max-width:600px)':{
                top:27,
            }
        },
        '& .MuiStepLabel-labelContainer': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            '& > * + *': {
                marginTop: theme.spacing(1)
            }
        }
    },




    
    '& .MuiStepConnector-root.Mui-disabled .MuiStepConnector-line': {
        [theme.breakpoints.down('sm')]: {
          marginTop: '20px',
        },
      },
    '& .MuiStepper-vertical': {
        '& .MuiStep-root': {
            '& .step-label': {
                justifyContent: 'flex-start'
            },
            '& .MuiStepContent-root': {
                borderWidth: 3,
                marginLeft: theme.spacing(2.25),
                borderColor: theme.palette.primary.main
            },
            '& .button-wrapper': {
                marginTop: theme.spacing(4)
            },
            '&.active + .MuiStepConnector-root .MuiStepConnector-line': {
                borderColor: theme.palette.primary.main
            }
        },
        '& .MuiStepConnector-root': {
            marginLeft: theme.spacing(2.25),
            '& .MuiStepConnector-line': {
                borderRadius: 0
            }
        }
    }
}))

export default StepperWrapper