import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../../lib/apiHeader";
import apis from "../../../../lib/apis/apis";
import {getAllClinics, getClinicByID} from "../../index";


export const getAllClinicLocation = createAsyncThunk(
    "getAllClinicLocation",
    async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_all_clinics, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }
            if (response?.data?.success === false) {
                console.log(response)
                return {};
            }
        } catch (err) {
            if (err?.response?.status !== 200) {
                return {
                    data: [],
                    errors: err?.response?.data?.errors,
                };
            }
        }
    }
);
export const storeClinicLocation = createAsyncThunk('storeClinicLocation', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_clinics_location, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.errors) {
            return response?.data
        } else if (response?.data?.success === "true") {
            await dispatch(getClinicByID(data?.provider_id))
            toast.success(`Business Location Stored Successfully`, {duration: 3000})
        }

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})



export const updateClinicLocation = createAsyncThunk('updateClinicLocation', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_clinics_location, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success==='true'){
            await dispatch(getClinicByID(data?.provider_id))
            toast.success(`Business Location Updated Successfully`, {duration: 3000})
        }else {
            return response?.data
        }
    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            return {
                data :err?.response,
                errors:true
            }
        }
    }
})


export const deleteClinicLocation = createAsyncThunk('deleteClinicLocation', async (id,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_clinics_location+id,{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllClinics())
            toast.success("Clinic Deleted Successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})

export const getClinicLocationByID = createAsyncThunk('getClinicLocationByID', async (id) => {
    try {
        const response = await axios.get(apis.get_clinics_location_by_id+id,{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            console.log(err?.response)
        }
    }
})

export const clinicBusinessLocation = createSlice({
    name: "clinicBusinessLocation",
    initialState: {
        location: [],
        meta: [],
        links: [],
        locationById: [],
    },
    reducers: {},
    extraReducers: (builder) => {

    },
});

export default clinicBusinessLocation.reducer;
