import React, { useEffect, useState } from "react";
import UserLayout from "../index";
import Box from "@mui/material/Box";
import {
  Grid,
  Typography,
  Container,
  Modal,
  TablePagination,
  Button,
} from "@mui/material";
import walletCart from "../../../assets/images/wallet-cart.png";
import wallet from "../../../assets/icon/wallet-balance.png";
import add from "../../../assets/icon/addIcon.svg";
import clock from "../../../assets/icon/ClockClockwise.png";
import question from "../../../assets/icon/Question.png";
import paymentIcon from "../../../assets/logo/paymentIcon.jpg";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../../store/auth";
import AddUserCard from "./addUserCard";
import {
  getAllCards,
  getAllCardsTransaction,
} from "../../../store/user/user-cards";
import CardsList from "./cardsList";
import TransactionList from "./transactionList";
import Loader from "../../../components/loader/loader";
import toast from "react-hot-toast";
import { scrollCSS } from "../../../App";
import moment from "moment";
import Footer from "../../home/Footer";
import BackendPagination from "../../../components/pagination/backendPagination";

const UserWallet = () => {
  const [expanded, setExpanded] = React.useState(false);
  
  const [expandedSec, setExpandedSec] = React.useState(false);
    
  const [expandedthi, setExpandedthi] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [providerPayment, setProviderPayment] = useState(null);
  const [activeProviderPayment, setActiveProviderPayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  const authStore = useSelector((state) => state?.authStore);
  const cardStores = useSelector((state) => state?.userCreateCardStore);
  const { user } = authStore;
  const provider = user?.defaultProvider;
  const { cards, transactions ,pageLimit, search, currentPage} = cardStores;

  useEffect(() => {
    (async () => {
      if (!user?.id) {
        await dispatch(getUserDetails());
      }
      if (user?.id && user?.defaultProvider) {
        let defaultProvider = user?.defaultProvider;

        let res =
          defaultProvider?.provider_payment?.length > 0 &&
          defaultProvider?.provider_payment.filter(
            (item) => item?.payment_gateway === "Square" && item?.status === 1
          );
        if (res?.length) {
          setActiveProviderPayment(res[0]);
        }
        setProviderPayment(defaultProvider?.provider_payment || []);
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      // setLoading(true)
      await dispatch(getAllCards());
      await dispatch(getAllCardsTransaction());
      setLoading(false);
    })();
  }, []);
  const fetchAllBookedPlan = async () => {
    setLoading(true)
    await dispatch(getAllCardsTransaction())
    setLoading(false)
  }
  const fetchPageData = async (page) => {
    await dispatch(getAllCardsTransaction({ currentPage: page }))
    await fetchAllBookedPlan()
  }

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };
  const handleAccordionChange2 = () => {
    setExpandedSec(!expandedSec);
  };
  const handleAccordionChange3 = () => {
    setExpandedthi(!expandedthi);
  };

  const handleAddCard = () => {
    if (
      providerPayment.some((item) => item?.payment_method?.is_gateway === 1)
    ) {
      setIsOpen(true);
    } else {
      toast.error("Payment gateway is not configure in this location / spa", {
        duration: 4000,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <UserLayout>
      {loading ? <Loader fullSize /> : ""}
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        {provider?.name && (
          <Grid
            item
            xs={12}
            sx={{ mb: "16px", p: "3px 16px", backgroundColor: "#f4ede3" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocationOnOutlinedIcon />
              <Typography
                sx={{
                  ml: "16px",
                  color: "#000",
                  textDecoration: "underline",
                }}
              >
                {provider?.name}
              </Typography>
            </Box>
          </Grid>
        )}
        {provider?.name ? (
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              padding: "20px",
              borderRadius: "4px",
              border: "0.8px solid #C4C4C4",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <img src={wallet} alt={"balance"} />
                </Box>
                <Typography
                  sx={{
                    ml: "16px",
                    fontFamily: "helvetica-lt-std-roman",
                    fontSize: "18px",
                  }}
                  variant="h6"
                >
                  My Wallet Balance
                </Typography>
              </Box>
              <Typography
                variant="h5"
                sx={{
                  mt: "12px",
                  fontFamily: "helvetica-lt-std-roman",
                  fontSize: { xs: "22px", md: "30px" },
                }}
              >
                $ {user?.wallet_point || 0}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                <Box>
                  <img src={paymentIcon} alt={"balance"} />
                </Box>
                <Typography
                  sx={{
                    ml: "16px",
                    fontFamily: "helvetica-lt-std-roman",
                    fontSize: "18px",
                  }}
                  variant="h6"
                >
                  My Payment Methods:
                </Typography>
              </Box>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  mt: 1,
                  fontFamily: "helvetica-lt-std-light",
                  fontSize: "16px",
                }}
              >
                Select a card to make it a default payment method
              </Typography>
            </Box>
            <CardsList allCardsList={cards} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2,
                p: { xs: "0", md: "2" },
                cursor: "pointer",
                width: "max-content",
              }}
              onClick={handleAddCard}
            >
              <Box>
                <img src={add} alt={"balance"} style={{ width: "100%" }} />
              </Box>
              <Typography
                sx={{
                  ml: "16px",
                  color: "#2F80ED",
                  fontFamily: "helvetica-lt-std-bold",
                  fontSize: { xs: "15px", md: "18px" },
                }}
                variant="h6"
              >
                Add Payment Methods:
              </Typography>
            </Box>
            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
              <Box>
                <AddUserCard
                  user={user}
                  setLoading={setLoading}
                  providerPayment={providerPayment}
                  setIsOpen={setIsOpen}
                  active={activeProviderPayment}
                />
              </Box>
            </Modal>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography sx={{ color: "red", textAlign: "center" }}>
              Please Set your default / Spa location in Location Tab
            </Typography>
          </Grid>
        )}
        {provider?.name && (
          <Grid
            item
            xs={12}
            lg={6}
            pt={"16px"}
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "auto", maxWidth: "100%", objectFit: "contain" }}>
              <img
                src={walletCart}
                alt={"wallet-cart"}
                style={{ width: "100%" }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Box sx={{ display: "flex", alignItems: "center", mt: 7 }}>
        <img src={clock} alt="" style={{ marginRight: "25px" }} />
        <Typography
          color="#000"
          sx={{ fontSize: "19px", fontFamily: "helvetica-lt-std-bold" }}
        >
          My Transaction History:
        </Typography>
      </Box>

      {/* Table */}
      <Container
        maxWidth="100%"
        sx={{
          marginLeft: 0,
          mt: "15px",
          p: "0 !important",
          "@media (max-width: 400px)": {
            display: "none",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{ mt: 2, boxShadow: "none", maxHeight: "650px", ...scrollCSS }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{}}>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Date
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Purpose
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Balance
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Current
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions?.length > 0 ? (
                transactions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TransactionList
                      row={item}
                      key={index}
                      index={index}
                      style={{ marginBottom: "14px" }}
                    />
                  ))
              ) : (
                <TableRow sx={{ height: "300px" }}>
                  <TableCell colSpan={4} align={"center"}>
                    No Transaction History
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={transactions?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
         <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                mt: '24px',
                padding: '4px'
              }}>   
                <BackendPagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  count={transactions?.meta?.last_page}
                  currentPage={currentPage}
                  handleOnChange={fetchPageData}
                />
              </Box>
      </Container>
      {/* mobile site */}
      {transactions?.length > 0 ? (
        transactions
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((data) => (
            <Grid
              container
              sx={{
                boxShadow: "0px 4px 4px 2px rgba(0, 0, 0, 0.25)",
                mt: 3,
                mb: 3,
                borderRadius: "8px",
                p: 3,
                display: { xs: "block", sm: "none" },
                backgroundColor: "#f4ede3",
              }}
            >
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{}}>
                    <Typography
                      paragraph
                      sx={{
                        color: "#000",
                        fontWeight:"bold",
                        fontSize: "14px",
                        display: "block",
                        mt: "15px",
                      }}
                    >
                      Date
                    </Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#000",
                        fontWeight:"bold",
                        fontSize: "14px",
                        textDecorationLine: "underline",
                        display: "block",
                      }}
                    >
                      {moment(data?.created_at).format("DD-MM-YYYY hh:mm")}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection:'column',
                    // justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Box sx={{}}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#000",
                        fontWeight:"bold",
                        fontSize: "14px",
                        display: "block",
                      }}
                    >
                      Description
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#000",
                        fontWeight:"bold",
                        fontSize: "14px",
                        display: "block",
                      }}
                    >
                      {data?.details || " "}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{}}>
                    <Typography
                      paragraph
                      sx={{
                        color: "#000",
                        fontWeight:"bold",
                        fontSize: "14px",
                        display: "block",
                        mt: "15px",
                      }}
                    >
                      Wallet Amount
                    </Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#000",
                        fontWeight:"bold",
                        fontSize: "14px",
                        display: "block",
                      }}
                    >
                      {" "}
                      {data?.type.toLowerCase() === "credit" ? "+" : "-"}
                      {data.wallet_point}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{}}>
                    <Typography
                      paragraph
                      sx={{
                        color: "#000",
                        fontWeight:"bold",
                        fontSize: "14px",
                        display: "block",
                        mt: "15px",
                      }}
                    >
                      Current Balance
                    </Typography>
                  </Box>
                  <Box sx={{}}>
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#000",
                        fontWeight:"bold",
                        fontSize: "14px",
                        display: "block",
                      }}
                    >
                      {data?.after_point}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ))
      ) : (
        <Typography>No Transaction History</Typography>
      )}

      {/* Accordian */}
      <Box sx={{ display: "flex", alignItems: "center", mt: 5 }}>
        <img src={question} alt="" style={{ marginRight: "25px" }} />
        <Typography
          color="#000"
          sx={{ fontSize: "19px", fontFamily: "helvetica-lt-std-bold" }}
        >
          Billing FAQs:
        </Typography>
      </Box>
      <Container
        maxWidth="lg"
        sx={{ marginLeft: 0, mt: 4, pb: {sm:4, xs:'140px'}, px: "0 !important" }}
      >
        <Accordion
          onChange={handleAccordionChange}
          sx={{
            boxShadow: "0px 0px 11px -4px rgba(81, 65, 65, 0.25)",
            borderRadius: "6px",
          }}
        >
          <AccordionSummary
            expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="h6"
              sx={{ fontSize: "18px", fontFamily: "helvetica-lt-std-roman" }}
            >
              Do My Credits Roll Over Into The Next Month?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body1"
              sx={{ fontSize: "18px", fontFamily: "helvetica-lt-std-light" }}
            >
              Yes, all unused credits roll over into the next month.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          onChange={handleAccordionChange2}
          sx={{
           
            boxShadow: "0px 0px 11px -4px rgba(81, 65, 65, 0.25)",
            borderRadius: "6px",
            mt: 3,
          }}
        >
          <AccordionSummary
            expandIcon={expandedSec ? <RemoveIcon /> : <AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="h6"
              sx={{ fontSize: "18px", fontFamily: "helvetica-lt-std-roman" }}
            >
              Can I Pause My Account?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body1"
              sx={{ fontSize: "18px", fontFamily: "helvetica-lt-std-light" }}
            >
              Yes you can contact us for pausing your membership.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          onChange={handleAccordionChange3}
          sx={{
            boxShadow: "0px 0px 11px -4px rgba(81, 65, 65, 0.25)",
            borderRadius: "6px",
            mt: 3,
          }}
        >
          <AccordionSummary
            expandIcon={expandedthi ? <RemoveIcon /> : <AddIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="h6"
              sx={{ fontSize: "18px", fontFamily: "helvetica-lt-std-roman" }}
            >
              What Happens When I Cancel?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body1"
              sx={{ fontSize: "18px", fontFamily: "helvetica-lt-std-light" }}
            >
              When you cancel your membership all your unused wallet points will
              remain as it is to be used against any service. The service
              pricing however will be changed to non member prices.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
      <Box
        style={{
          marginLeft: "calc(-50vw + 50%)",
          marginRight: "calc(-50vw + 50%)",
        }}
      >
        <Footer />
      </Box>
    </UserLayout>
  );
};

export default UserWallet;
