// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  margin-top: 16px !important;
}

.MuiInputAdornment-root {
  z-index: 2;
  background: #EDF2F7 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/form/styles.scss","webpack://./src/components/form/styles.css"],"names":[],"mappings":"AAEA;EACE,2BAAA;ACDF;;ADKA;EACE,UAAA;EACA,8BAAA;ACFF","sourcesContent":["\n\n.button{\n  margin-top: 16px!important;\n}\n\n\n.MuiInputAdornment-root{\n  z-index: 2;\n  background: #EDF2F7!important;\n\n}\n",".button {\n  margin-top: 16px !important;\n}\n\n.MuiInputAdornment-root {\n  z-index: 2;\n  background: #EDF2F7 !important;\n}/*# sourceMappingURL=styles.css.map */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
