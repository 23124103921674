import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";
import moment from "moment";

export const getAllCalenderBookings = createAsyncThunk("getAllCalenderBookings", async ( data,{getState }) => {

    try {
        const { selectedStaff, calenderType, isCancel} = getState()?.crmCalenderStore
        let payload = {
            type: calenderType,
            date:moment(data?.date).format('YYYY-MM-DD'),
        }
        if (isCancel){
            payload['is_cancel']=isCancel
        }
        if (selectedStaff!=='all'){
            payload['staff_id'] = selectedStaff
        }
        const response = await axios.post(apis.crm_calender_booking, {...payload}, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        } else {
            return [];
        }
    } catch (err) {
        return []
    }
});


export const updateCrmCalenderFilter = createAsyncThunk('updateCrmCalenderFilter', async (data) => {
    return data
})





export const crmCalenderSlice = createSlice({
    name: "crmCalenderSlice",
    initialState: {
        calenderBookings: [],
        selectedStaff:'all',
        calenderType:'Day',
        isCancel:false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCalenderBookings.fulfilled, (state, action) => {
            state.calenderBookings = action.payload;
        });

        builder.addCase(updateCrmCalenderFilter.fulfilled, (state, action) => {
            const {calenderType,selectedStaff,isCancel} = action?.payload

            if (calenderType){
                state.calenderType = calenderType ;
            }
            if (selectedStaff){
                state.selectedStaff = selectedStaff ;
            }

            if (isCancel===true || isCancel ===false){
                state.isCancel = isCancel ;
            }
        });

    },
});

export default crmCalenderSlice.reducer;
