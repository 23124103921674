import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";

export const getAllStaffAttendance = createAsyncThunk("getAllStaffAttendance", async (data, { getState }) => {
    const {search,pageLimit,filterBy,currentPage} = getState()?.crmPOSStore
    try {
        const response = await axios.get(apis.get_all_crm_attendance+`${filterBy}&limit=${pageLimit}&page=${currentPage}${search!=='' ? `&search=${search}`:'' }`, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }else {
            return {};
        }
    } catch (err) {
        return  {}
    }
})
export const updateStaffAttendance = createAsyncThunk("updateStaffAttendance", async (data, { dispatch }) => {
    try {
        const response = await axios.post(apis.update_crm_attendance,{...data}, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            dispatch(getAllStaffAttendance())
        }
        return response?.data?.data;

    } catch (err) {
        return  err?.response
    }
})

export const updateAttendanceFilters = createAsyncThunk('updateAttendanceFilters', async (data) => {
    return data
})



export const attendanceSlice = createSlice({
    name: "attendanceSlice",
    initialState: {
        allStaffAttendance: [],
        search:'',
        pageLimit:10,
        filterBy:'All',
        currentPage:1,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllStaffAttendance.fulfilled, (state, action) => {
            state.allStaffAttendance = action.payload ;
        });
        builder.addCase(updateAttendanceFilters.fulfilled, (state, action) => {
            const {filterBy,pageLimit,search,currentPage} = action?.payload
            if (filterBy){
                state.filterBy = filterBy ;
            }
            if (pageLimit){
                state.pageLimit = pageLimit ;    
            }
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
        });
    },
});

export default attendanceSlice.reducer;
