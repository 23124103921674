import React, {Fragment, useEffect, useRef, useState} from 'react';

import {
  Autocomplete,
  Avatar,
  Box, Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField, Tooltip,
  Typography
} from "@mui/material";
import {styled, useTheme} from '@mui/material/styles';

import StepperWrapper from "../../../../components/steps/StepperWrapper";
import toast from "react-hot-toast";
import {inputStyle, labelStyle} from "../../../../components/form/formInput";
import AppointmentPayment from './payment/appointmentPayment';
import SearchField from '../../../../components/form/SearchField';
import CustomButton from '../../../../components/custom-button/customButton';
import * as Yup from 'yup';
import * as yup from 'yup';

import {useFormik} from 'formik';
import {useDispatch, useSelector} from "react-redux";
import {
  getAllExistingUser, getAllExistingUserCards, getAppointmentById,
  getCrmAllPlans,
  getCrmAppointmentHolidays,
  getCrmAppointmentSchedule, getCustomerById,
  getServicesCategory, updateCrmFilters, updateHolidaySchedule
} from "../../../../store/crm/appointment/book-appointment";
// import TableContainer from "@mui/material/TableContainer";
import {tableCellClasses} from "@mui/material/TableCell";
import {getProviderStaff} from "../../../../store/crm/staff";
import CustomPagination from "../../../../components/pagination/pagination";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import AppointmentTime from "./appointmentTime";
import moment from "moment";
import Loader from "../../../../components/loader/loader";
import {convertTimeToMinutes, successConfirmation} from "../../../../services/utills";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {findGuestUser} from "../../../../store/booking-widget";
import {useLocation} from "react-router-dom";
import {useParams} from "react-router";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/breadcrumbs";
import ribbon from "../../../../assets/images/ribbon.svg";
import redRibbon from "../../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../../assets/images/yellow-badg.svg";
import AppointmentPlanSessionTime from "./appointmentPlanSessionTime";
import MobileStepper from "@mui/material/MobileStepper";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import CustomModal from "../../../../components/modal/customModal";
import AddServices from "../../../configuration/clinic-settings/services/AddService";
import Waveloading from '../../../loading/Waveloading';
import BackendPagination from '../../../../components/pagination/backendPagination';
import BackendSearchField from '../../../../components/pagination/backendSearch';
import { getgiftcard } from '../../../../store/clinics/clinicSettings/gift-cards';
import PriceSelectorModal from './ModalPrices';

export function removeCard(selectedServices, finalData) {
  return selectedServices.filter((item) => item?.id !== finalData?.id);
}

export const contenttime={
  fontSize:'20px',
  fontFamily:'Helvetica',
  fontWeight:"400",
  paddingTop:'3px',
  padding:'20px 0px'
}
export const booking={
  '@media (max-width: 1200px)': {
    marginLeft:'auto'
  },

}
export const timehead={
  fontSize:'20px',
  fontFamily:'helvetica-lt-std-roman',
  fontWeight:"700",

}

export const content={

  fontSize:'16px',
  fontFamily:'helvetica-lt-std-roman',
  fontWeight:"400",
  '@media (max-width: 1024px)': {
    fontSize:'18px',
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:"400",
  }
}

export const customernote={
  fontSize:'20px',
  fontFamily:'helvetica-lt-std-roman',
  fontWeight:"400",
  padding:'15px 0px'

}
export const customerside={
  display:'flex',justifyContent:"space-between",alignItems:"center",
  '@media (max-width: 480px)': {
    display:'unset',

  }


}
export const wrappercard={
  display:'flex',alignItems:"stretch",
  '@media (max-width: 786px)': {
    display:'flex',
    flexDirection:'row'

  },


  '@media (max-width: 580px)': {
    display:'flex',
    flexDirection:'column',
    alignItems:'center'

  }
}

const customerTypes =[
  {name:'New Customer' ,key:"new_customer"},
  // {name:'Walk In Customer' ,key:"walk_in_customer"},
  {name:'Existing Customer' ,key:"existing_customer"},
]

const steps = [
  {
    title: 'Select Customer',
  },
  {
    title: 'Select Service',
  },
  {
    title: 'Select Times',
  }
]




const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: '16px',
    paddingTop:'3px',
    paddingBottom:'3px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AddAppointment = () => {
  const [activeType, setActiveType] = useState('existing_customer');
  const [activeStep, setActiveStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const mainDivRef = useRef(null);

  const top100Films = [
    { label: "Massage Therapy", year: 1994 },
    { label: "Face & Body Contouring", year: 1972 },
    { label: "Laser Hair Removals", year: 1974 },
    { label: "Medical Grade Facials", year: 2008 },
  ];

  /******************** Step 1 States ***********************/
  const [selectedUser, setSelectedUser] = useState({});
  const [filteredUser, setFilteredUser] = useState([]);
  const [userCards, setUserCards] = useState([]);

  /******************** Step 2 States ***********************/
  const [selectedCategory, setSelectedCategory] = useState('');
  const [addExtraService, setAddExtraService] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [bookingType, setBookingType] = useState('')
  const [selectedServices, setSelectedServices] = useState([])

  /******************** Step 3 States ***********************/
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedFinalDate, setSelectedFinalDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const existingCustomerContainerRef = useRef(null);

  const extraserviceRef =useRef(null);
  /******************** Pagination States ***********************/
  const [page, setPage] = useState(1);
  const [servicePage, setServicePage] = useState(1);
  const serviceItemsPerPage = 10
  const itemsPerPage = 10

  /************************  Appointment By ID****************************/
  const [activeAppointment, setActiveAppointment] = useState('')
  const [appointmentDetailsById, setAppointmentDetailsById] = useState(null)

  /************************  Plan ****************************/
  const [selectedPlanTime, setSelectedPlanTime] = useState([])
  const [sessionActiveStep, setSessionActiveStep] = useState(0);
  const maxSession = bookingType === 'plan' &&  selectedServices[0]?.no_of_bookings ?  parseInt(selectedServices[0]?.no_of_bookings): 0;
  const serviceCategoryContainerRef = useRef(null);
  const handleDateChange = (date) => {
    // Set the selected date
    setSelectedFinalDate(date);
  };
  useEffect(() => {
    const handleWindowResize = () => {

      if (window.innerWidth >= 768) {
        setBookingType('service');
      } else {
        setBookingType('');
      }
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const theme = useTheme();
  const dispatch = useDispatch()
  const {pathname} = useLocation()
  const {appointment_id,user_id} = useParams()
  const bookAppointmentStore = useSelector((state) => state?.bookAppointmentStore)
  const crmStaffStore = useSelector(state => state?.crmStaffStore)
  const authStore = useSelector(state => state.authStore)
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const {existingUsers = [], servicesCategory = [], crmAllPlans = [] , filterBy, pageLimit, currentPage, search} = bookAppointmentStore
  const {crmStaffs = []} = crmStaffStore
  const clinicGiftCardsStore = useSelector(state => state?.clinicGiftCardsStore)
  const {gifts} = clinicGiftCardsStore
  console.log("plan data",crmAllPlans)
  const fetchPageData = async (page) => {
    await dispatch(updateCrmFilters({currentPage: page}))
    await fetchCrmCustomer()
  }
  const handleSearch = async (val) => {
    await dispatch(updateCrmFilters({search: val}))
  }
  // Handler for when "Any" staff is selected
  const handleAnyStaffSelected = async (event) => {
    if (event.target.checked) {
      setSelectedStaff(null);
      await getAppointmentHoliday(selectedFinalDate, null);
    }
  };

  // Handler for staff selection change in Autocomplete
  const handleStaffChange = async (event, newValue) => {
    setSelectedStaff(newValue);
    await getAppointmentHoliday(selectedFinalDate, newValue);
  };
  useEffect(() => {
    console.log("Crm Useeffect Staff")
    console.log(selectedServices[0]?.staff_service)
    console.log(crmStaffs)
    console.log("End Crm Useeffect Staff")


    if (selectedServices[0]?.staff_service?.length === 1) {
      // Find the matching staff object
      const matchedStaff = crmStaffs.find(staff => staff.id === selectedServices[0]?.staff_service[0]?.id);

      // If a match is found, set it as the selected staff and get appointment holiday
      if (matchedStaff) {
        setSelectedStaff(matchedStaff);
        getAppointmentHoliday(selectedFinalDate, selectedServices[0]?.staff_service[0]);
      }
    }
  }, [crmStaffs, setSelectedStaff, selectedFinalDate]);

  const scrollToServiceCategoryContainer = () => {
    if (serviceCategoryContainerRef.current) {
      serviceCategoryContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(()=>{   
    (async ()=>{   
        setLoading(true)  
        await dispatch(getgiftcard())
        setLoading(false)
    })()
},[dispatch])      
  const handleServiceAdded = (category) => {
    console.log("selected id",category)
    setAddExtraService(false);
    setBookingType('service');
    handleGetServices(category,true)
  };

  useEffect(()=>{
    let appointmentActive
    if (pathname === "/crm/add-appointment"){
      setAddExtraService(false);
      appointmentActive = 'add'
    }else if (pathname?.includes("/crm/appointment/reschedule/edit/")){
      appointmentActive = 'edit'
    }else if (pathname?.includes("/crm/add-appointment/user/")){
      appointmentActive = 'newUser'
    }
    setActiveAppointment(appointmentActive)


  },[pathname])

  const handleExistingCustomerCheck = (isChecked) => {
    // extraserviceRef
    console.log('handleExistingCustomerCheck called. isChecked:', isChecked);
    if (isChecked) {
      console.log('Scrolling into view...');
      if (existingCustomerContainerRef.current) {
        existingCustomerContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        console.log('Scrolling executed.');
      } else {
        console.log('existingCustomerContainerRef is null or undefined.');
      }
    }
  };


  useEffect(()=>{
    (async ()=>{
      if ((activeAppointment === 'edit') && appointment_id!==undefined){
        setLoading(true)
        let appointmentRes = await dispatch(getAppointmentById(appointment_id))
        const appointmentById = appointmentRes?.payload
        console.log("data comes", appointmentById?.date, appointmentById)
        if (appointmentById?.id)
        {
          setActiveType('existing_customer')
          setBookingType("service")
          setSelectedUser  (appointmentById?.customer)
          setSelectedServices(appointmentById?.services)
          setSelectedCategory(appointmentById?.category)
          setFilteredData(appointmentById?.category?.services)
          setAppointmentDetailsById(appointmentById)
          handleDateChange(appointmentById?.date)
          await dispatch(updateHolidaySchedule({
            holidays: appointmentById?.holidays,
            schedule: appointmentById?.schedule
          }))
          setSelectedTime(appointmentById?.date_time)
          if (activeAppointment === 'edit') {
            setActiveStep(1)
          }

        }
        setLoading(false)
      }
      if ((activeAppointment === 'newUser') && user_id!==undefined){
        setLoading(true)
        let customerRes = await dispatch(getCustomerById(user_id))
        if(customerRes?.payload?.id){
          setActiveStep(1)
          setSelectedUser(customerRes?.payload)
          await handleSelectedUser(customerRes?.payload)
        }
        setLoading(false)
      }
    })()

  },[activeAppointment,user_id])


  useEffect(() => {
    (async () => {
      await dispatch(getAllExistingUser())
      dispatch(getProviderStaff())
      dispatch(getServicesCategory())
      dispatch(getCrmAllPlans())
    })()
  }, [])

  const fetchCrmCustomer = async () => {
    setLoading(true)
    await dispatch(getAllExistingUser()) 
    setLoading(false)
  }
  useEffect(() => {
    console.log("check")
    setFilteredUser(existingUsers || [])
  }, [existingUsers])

  const validationSchema = yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
    medical_notes: Yup.string(),
    staff_notes: Yup.string(),
    note_by: Yup.mixed(),
  });
  const validationUserSchema = yup.object({
    medical_notes: Yup.string(),
    staff_notes: Yup.string(),
    note_by: Yup.mixed(),
  });

  const {values, resetForm, setFieldTouched,setFieldError,handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      medical_notes: '',
      staff_notes: '',
      note_by: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      if (data?.medical_notes!=='' || data?.staff_notes !==''){
        if (data?.note_by === null){
          await setFieldTouched(true)
          setFieldError('note_by',"Please Select A Staff")
          return;
        }
      }
      if (data?.note_by !==null ){
        if (data?.medical_notes === '' && data?.staff_notes ===''){
          await setFieldTouched(true)
          setFieldError('medical_notes',"Please Add Medical or Staff Notes")
          return;
        }
      }

      setLoading(true)
      let res = await dispatch(findGuestUser({email: data?.email}))
      setLoading(false)

      if (res?.payload?.success === 'true') {
        await successConfirmation('Warning', 'User Already Exist with this Email', 'warning')
      } else if (!res?.payload?.success) {
        handleNext()
      }

    },
  });
  const {
    values:userValues,
    resetForm:userResetForm,
    setFieldTouched:setUserFieldTouched,
    setFieldError:setUserFieldError,
    handleSubmit:handleUserSubmit,
    setFieldValue:setUserFieldValue,
    handleChange:userHandleChange,
    touched:userTouched,
    errors:userErrors
  } = useFormik({
    initialValues: {
      medical_notes: '',
      staff_notes: '',
      note_by: null,
    },
    validationSchema: validationUserSchema,
    onSubmit: async (data) => {

      if (data?.medical_notes!=='' || data?.staff_notes !==''){
        if (data?.note_by === null){
          setUserFieldTouched(true)
          setUserFieldError('note_by',"Please Select A Staff")
          return;
        }
      }
      if (data?.note_by !==null ){
        if (data?.medical_notes === '' && data?.staff_notes ===''){
          setUserFieldTouched(true)
          setUserFieldError('medical_notes',"Please Add Medical or Staff Notes")
          return;
        }
      }

      await handleSelectedUser(selectedUser,true)

    },
  });

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    if (activeStep === steps.length - 1) {
      toast.success('Form Submitted');
    }
  }

  const UserForm = () => (
      <Grid container sx={customerside} style={{marginTop: '15px'}}>
        <Grid item xs={12} md={5.5} sx={{ width:{xs:"100%",sm:'auto'} }}>
          <FormControl fullWidth sx={{mb: "16px"}}>
            <FormLabel sx={labelStyle}>Name</FormLabel>
            <TextField
                fullWidth
                name="name"
                value={values?.name}
                sx={inputStyle}
                onChange={handleChange}
                placeholder="Name"
            />
            {errors?.name && touched?.name &&
                <FormHelperText sx={{
                  color: '#CB3838',
                  width: 'max-content',
                  mx: 0
                }}>{errors?.name}
                </FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
          <FormControl fullWidth sx={{mb: "16px"}}>
            <FormLabel sx={labelStyle}>Email</FormLabel>
            <TextField
                fullWidth
                name="email"
                onChange={handleChange}
                value={values?.email}
                sx={inputStyle}
                placeholder="Email"
            />
            {errors?.email && touched?.email &&
                <FormHelperText sx={{
                  color: '#CB3838',
                  width: 'max-content',
                  mx: 0
                }}>
                  {errors?.email}
                </FormHelperText>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
          <FormControl fullWidth sx={{mb: "16px" ,}}>
            <FormLabel sx={labelStyle}>Phone No</FormLabel>
            <TextField
                fullWidth
                name="phone"
                onChange={handleChange}
                value={values?.phone}
                sx={inputStyle}
                placeholder="Phone No"
            />
            {errors?.phone && touched?.phone && <FormHelperText sx={{
              color: '#CB3838',
              width: 'max-content',
              mx: 0
            }}>{errors?.phone}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
          <FormControl fullWidth sx={{mb: "16px"}}>
            <FormLabel sx={labelStyle} style={{marginBottom: 10}}>Medical Notes</FormLabel>
            <TextField
                fullWidth
                name="medical_notes"
                sx={inputStyle}
                onChange={handleChange}
                value={values?.medical_notes}
                placeholder="Medical Notes"
                multiline
                rows={4}
            />
            {errors?.medical_notes && touched?.medical_notes &&
                <FormHelperText sx={{
                  color: '#CB3838',
                  width: 'max-content',
                  mx: 0
                }}>
                  {errors?.medical_notes}
                </FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5.5} style={{paddingTop: 10}} sx={{ width:{xs:"100%",sm:'auto'}}}>
          <FormControl fullWidth sx={{mb: "16px"}}>
            <FormLabel sx={labelStyle} style={{marginBottom: 10}}>Staff Notes</FormLabel>
            <TextField
                fullWidth
                style={{mt: 4}}
                onChange={handleChange}
                value={values?.staff_notes}
                name="staff_notes"
                sx={inputStyle}
                placeholder="Staff Notes"
                multiline
                rows={4}
            />
            {errors?.staff_notes && touched?.staff_notes && <FormHelperText sx={{
              color: '#CB3838',
              width: 'max-content',
              mx: 0
            }}>{errors?.staff_notes}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid
            item
            xs={12}
            sm={5.5}
            style={{paddingTop: 10}}
            sx={{ width:{xs:"100%",sm:'auto'}}}
        >
          <FormControl fullWidth>
            <FormLabel sx={{...labelStyle}}>Note By</FormLabel>
            <Autocomplete
                options={crmStaffs}
                value={values?.note_by}
                disableCloseOnSelect={false}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id
                }}
                getOptionLabel={(option) => option?.name || ''}
                renderInput={(params) => (
                    <TextField {...params} placeholder={'Select Staff'} sx={inputStyle}/>
                )}
                onChange={(event, newValue) => {
                  setFieldValue('note_by', newValue);
                }}
                renderOption={(props, option, {selected}) => (
                    <MenuItem  {...props} key={option?.id} >
                      <Checkbox
                          style={{marginRight: 8}}
                          checked={selected}
                      />
                      {option?.name}
                    </MenuItem>
                )}
            />
            {errors?.note_by && touched?.note_by && <FormHelperText sx={{
              color: '#CB3838',
              width: 'max-content',
              mx: 0
            }}>{errors?.note_by}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
  )
  const formatTimeDuration = (timeDuration) => {
    // Check if the input is in the expected format "HH:MM"
    if (!timeDuration || !timeDuration.includes(':')) {
      return 'Time not available'; // Return a message if the format is not as expected
    }

    const parts = timeDuration.split(':'); // This splits the string into ["HH", "MM"]

    // Make sure that we have exactly two parts ["HH", "MM"]
    if (parts.length !== 2) {
      return 'Time not available'; // Return a message if the format doesn't have two parts
    }

    // Safely parse the hours and minutes using parseInt and ensure we are not parsing undefined or null
    const hours = parseInt(parts[0], 10); // Extract and convert hours part to number
    const minutes = parseInt(parts[1], 10); // Extract and convert minutes part to number

    // Handle possible NaN values
    if (isNaN(hours) || isNaN(minutes)) {
      return 'Time not available'; // Return a message if either hours or minutes are not numbers
    }

    // Determine how to format the output
    let formattedTime = '';

    if (hours > 0) {
      formattedTime += `${hours} H`; // Add hours to string if more than 0
    }
    if (minutes > 0) {
      if (formattedTime.length > 0) formattedTime += ' '; // Add a space if hours were added before
      formattedTime += `${minutes} Minutes`; // Add minutes to string if more than 0
    }

    return formattedTime || 'Time not available'; // Return formatted time or a message if time is 00:00
  };


  const handleSelectData = (service) => {
    let finalData = { ...service, service_quantity: 1, booking_cost: null };
  console.log("issue occur",service)
    // Check if prices array exists
    if (service?.prices && Array.isArray(service.prices) && service.prices.length > 0) {
      // Open modal to select price
      setOpenModal(true);
      setModalData(service); // Store service data in modalData for reference
    } else {
      // No prices array, proceed with selecting service
      if (bookingType === 'plan') {
        setSelectedServices([finalData]);
      } else if (bookingType === 'giftcard') {
        setSelectedServices([finalData]);
        if (mainDivRef.current) {
          setTimeout(() => {
            mainDivRef.current.scrollIntoView({ behavior: 'smooth' });
          }, 50);  
        }
      } else {
        if (isExist(selectedServices, finalData)) {
          const updatedServices = removeCard(selectedServices, finalData);
          setSelectedServices(updatedServices);
        } else {
          setSelectedServices([...selectedServices, finalData]);
          if (mainDivRef.current) {
            setTimeout(() => {
              mainDivRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 50);
          }
        }
      }
    }
  };
  
  
  


  const onSelectPrice = (selectedPrice, giftCardId) => {
console.log("prices",selectedPrice, giftCardId)
    const updatedService = { ...modalData, selected_price: selectedPrice, gift_card_id: giftCardId };
  
    // Find if the service already exists in selectedServices array
    const existingIndex = selectedServices.findIndex(service => service.id === updatedService.id);
  
    // If it exists, update it; otherwise, add it to the array
    if (existingIndex !== -1) {
      const updatedServices = [...selectedServices];
      updatedServices[existingIndex] = updatedService;
      setSelectedServices(updatedServices);
    } else {
      setSelectedServices([...selectedServices, updatedService]);
    }
};

  
  const handleCloseModal = () => {  
    setOpenModal(false);
    setModalData(null);
  };
  
  
  const handleSelectedUser =async (user,isNext)=>{
    console.log("Cards:")
    console.log(userCards)
    if (userCards?.length){
      if (isNext){
        handleNext()
      }
    }else {

      setLoading(true)
      let {payload} = await dispatch(getAllExistingUserCards(user?.id))


      if (payload?.data?.id) {
        if (payload?.data?.payment_method_id === 4) {
          setUserCards(payload?.data?.cards || [])
          console.log("nexttab")

        } else {
          setUserCards(payload?.data?.cards || [])
          console.log("find")
        }
      } else {
        setUserCards([])
        console.log("extraserviceRef.current",extraserviceRef.current)
        if (extraserviceRef.current) {
          extraserviceRef.current.scrollIntoView({ behavior: 'smooth' });
          console.log('Scrolling executed. on existing ');
        }


      }


      setLoading(false)
      console.log("extraserviceRef.current check",extraserviceRef.current)
 
      if (isNext){
        handleNext()

      }
    }
  }
  const scrollRef = useRef(null); // Create the ref

  // Function to execute scrolling


  const executeScroll = () => {
    setTimeout(() => { 
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500); // Delay of 500ms

  }

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
            <Fragment key={step}>
              <Grid item xs={12} sx={customerside} style={{paddingTop: '11px!important'}}>  
                <Typography sx={content}>Select customer from the list below</Typography>
                <FormControl sx={{mr: "10px", ml: "10px"}}>
                  <BackendSearchField
                      search={search}
                      handleSearch={handleSearch}          
                      fetchData={async () => { 
                        await dispatch(updateCrmFilters({currentPage: 1})) 
                        await fetchCrmCustomer() 
                      }}  
                  />
                </FormControl>
              </Grid>
              <Grid container>
                <Grid item xs={12} ref={existingCustomerContainerRef}>
                  {existingUsers?.data?.length > 0 ?
                      <form onSubmit={handleUserSubmit}>
                        <Box>
                          <TableContainer>
                            <Table aria-label="customized table" sx={{mt: '10px'}}>
                              <TableBody>
                                {
                                  existingUsers?.data?.slice((page - 1) * itemsPerPage, page * itemsPerPage)?.map((user, index) => (selectedUser?.id ? selectedUser?.id === user?.id : true) && (
                                      <Tooltip
                                          key={index}
                                          title={user?.is_membered === 1? user?.provider_name:''}
                                      >
                                        <StyledTableRow
                                            sx={{
                                              cursor:'pointer',
                                              my:"4px",
                                              "&:hover": {
                                                background:"#F6FAFD"
                                              }  
                                            }}
                                            onClick={()=>{
                                              if (selectedUser?.id) { 
                                                setSelectedUser({})
                                                setUserCards([])
                                              } else {
                                                setSelectedUser(user)
                                              }
                                            }}
                                        >
                                          <StyledTableCell sx={{width: '70px', pl: '0'}}>
                                            <Checkbox
                                                checked={selectedUser?.id === user?.id}
                                                onChange={(e) => {
                                                  if (e?.target?.checked) {
                                                    setSelectedUser(user)
                                                  } else {
                                                    setSelectedUser({})
                                                    setUserCards([])
                                                  }
                                                }}
                                            />
                                          </StyledTableCell>
                                          <StyledTableCell
                                              sx={{
                                                pl: 0,
                                              }}
                                          >
                                            <Box
                                                sx={{
                                                  display:"flex",alignItems:"center",
                                                }}
                                            >
                                              {user?.is_batch === 1 &&
                                                  <Box sx={{mr:'10px'}}>
                                                    <img
                                                        style={{marginTop: '4px'}}
                                                        src={user.customer_batch.includes("Expire/Cancel")
                                                            ? redRibbon
                                                            : user.customer_batch.includes("Declined")
                                                                ? yellowRibbon
                                                                : ribbon}
                                                        alt={'member'}
                                                    />
                                                  </Box>
                                              }
                                              <Typography>
                                                {user?.name}
                                              </Typography>
                                            </Box>
                                          </StyledTableCell>
                                          <StyledTableCell>{user?.phone}</StyledTableCell>
                                          <StyledTableCell>{user?.email}</StyledTableCell>
                                        </StyledTableRow>
                                      </Tooltip>
                                  ))
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                          {/* {filteredUser?.length > itemsPerPage && !selectedUser?.id &&
                                            <Box sx={{display: 'flex', justifyContent: 'center', mt: '40px'}}>
                                                <CustomPagination
                                                    totalData={filteredUser}
                                                    page={page}
                                                    setPage={setPage}
                                                    itemsPerPage={itemsPerPage}
                                                />
                                            </Box>
                                        } */}
                          <div style={{marginTop:"15px"}}>
                            {

                                (!selectedUser?.id && existingUsers?.meta?.last_page > 1) &&
                                <BackendPagination
                                    count={filteredUser?.meta?.last_page}
                                    currentPage={currentPage}
                                    handleOnChange={fetchPageData}
                                />
                            }

                          </div>

                          {selectedUser?.id && (
                              <Box sx={{display: 'flex', justifyContent: 'center', mt: '16px'}}>
                                <SubmitButton size={'large'} mainColor={'#27AE60'} subColor={'#fff'} val={'Continue '}/>
                              </Box>
                          )}
                        </Box>
                      </form>
                      :
                      <Typography sx={{textAlign: 'center', mt: '20px'}}>
                        No Users
                      </Typography>
                  }
                </Grid>
              </Grid>
            </Fragment>
        )
      case 1:
        return (
            <Fragment key={step}>
              {selectedCategory === "extra" &&
                  <Grid container sx={{display: 'flex', justifyContent: 'end'}}>
                    <Grid item xs={12} lg={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                      <FormControl fullWidth sx={{mb: "16px"}}>
                        <FormLabel style={{fontsize: '6px'}}>Add Service Name</FormLabel>
                        <TextField
                            fullWidth
                            name=""
                            sx={inputStyle}
                            placeholder=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                      <FormControl fullWidth sx={{mb: "16px"}}>
                        <FormLabel style={{fontsize: '10px'}}>Time</FormLabel>
                        <TextField
                            fullWidth
                            name="Time"
                            sx={inputStyle}
                            placeholder=""
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                      <FormControl fullWidth sx={{mb: "16px"}}>
                        <FormLabel style={{fontsize: '10px'}}>Price</FormLabel>
                        <TextField
                            fullWidth
                            label=''
                            sx={inputStyle}
                            placeholder=""
                        />

                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                      <FormControl fullWidth sx={{mb: "16px"}}>
                        <FormLabel style={{fontsize: '10px'}}>Discount</FormLabel>
                        <TextField
                            fullWidth
                            label=''
                            sx={inputStyle}
                            placeholder=""
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
              }
              <Grid container>
                {bookingType !== '' && <Grid item xs={12} sx={customerside}>
                  <Typography sx={content}>
                    Select service from the menu below 
                  </Typography> 
                  <FormControl sx={{mr: "10px", ml: "10px"}}>
                    <SearchField
                        totalData={bookingType === 'plan' ? crmAllPlans : selectedCategory?.services}
                        setFilterData={setFilteredData}
                        setPage={setServicePage} 
                        isServices={bookingType !== 'plan'}
                        allServicesCategories={servicesCategory}
                    />
                  </FormControl> 
                </Grid>
                }

                {(bookingType === "") &&
                    <Grid item xs={12}>
                      <Typography
                          sx={{my: '24px', textAlign: 'center', fontFamily: "helvetica-lt-std-bold"}}>
                        Choose Booking Type
                      </Typography>
                    </Grid>
                }
                {(bookingType === "service") && selectedCategory === '' && !filteredData?.length &&
                    <Grid item xs={12}>
                      <Typography
                          sx={{my: '24px', textAlign: 'center', fontFamily: "helvetica-lt-std-bold"}}>
                        {selectedCategory === '' && "Select Events Category"}
                      </Typography>
                    </Grid>
                }
                <Grid item xs={12} ref={serviceCategoryContainerRef}>
                  <Grid container sx={wrappercard} style={{marginTop: '20px',justifyContent:"space-around", gap: {lg:'20px', md:'10px', xs:'5px'}, alignItems:{xs:'center',sm:'start'}}}>
                  {filteredData?.length > 0 &&
  filteredData
    .slice((servicePage - 1) * serviceItemsPerPage, servicePage * serviceItemsPerPage)
    .map((service, index) => (
      <Grid
        key={service?.id}
        item
        lg={3.8} 
        sm={5.8}
        xs={12} 
        sx={{ cursor: 'pointer', mt: 2 , 
          [theme.breakpoints.down('786')]: {
          width: bookingType === "giftcard" ? '80%' : "80%",
        }, }}
 
        onClick={() => handleSelectData(service)}

      >
        <Checkbox
          checked={isExist(selectedServices, service)}
          sx={{
            top: "3px",
            zIndex: '1'
          }}
        />
        <Card sx={{ mt: '-40px', height: '100%' }}>
          <Avatar
            src={service?.image}
            sx={{
              width: "100%",
              height: "125px",
            }}
            variant='rounded'
          />
          <Box
            sx={{
              p: '8px',

            }}>
            <Typography style={{
              fontSize: '13.7px',
              fontWeight: '700',
              fontFamily: 'helvetica-lt-std-bold',
              textAlign: 'center'
            }}>
              {service?.name || service?.plan_name || service?.title}
            </Typography>
            { bookingType !== "giftcard"  ?
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: '8px' }}>
              <Box>
                <Typography> Time:</Typography>
              </Box>
              <Box>
                <Typography>{service?.time_duration ? formatTimeDuration(service?.time_duration) : 'Time not available'} </Typography>
              </Box>
            
            </Box>
            :   <Box sx={{ display: 'flex', justifyContent: 'center', mt: '8px' }}>
            <Box>
              {/*<Typography> date:</Typography>*/}
            </Box>
            <Box>
              {/*<Typography>{service?.expiry_date ? moment(service?.expiry_date ).format("dddd MMMM D, YYYY") : 'Date and Time Not Set'} </Typography>*/}
            </Box>

          </Box>}
          { bookingType !== "giftcard"  ?
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: '8px' }}>
              <div style={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '12px' }}>
                  Regular Price
                </Typography>
                <Typography style={{
                  fontSize: '17px',
                  fontWeight: '700',
                  fontFamily: 'helvetica-lt-std-bold',           
                  color: selectedUser?.is_membered !== 1 ? '#04B4FF' : '#000'
                }}>
                  ${service?.regular_price}
                </Typography>
              </div>
              <Box sx={{ p: '2px 10px' }}>
                <Divider
                  orientation='vertical'
                  style={{
                    borderColor: '#8D8D8D',
                  }}
                />
              </Box>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  sx={{ fontSize: '12px' }}
                >
                  Member’s Price
                </Typography>
                <Typography style={{
                  fontSize: '17px',
                  fontWeight: '700',
                  fontFamily: 'helvetica-lt-std-bold',
                  color: selectedUser?.is_membered === 1 ? '#04B4FF' : '#000'
                }}>
                  ${service?.member_price}
                </Typography>
              </div>
            </Box>
            :"" }
            {service.room && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="body1" sx={{ fontWeight: '500', fontSize: '16px' }}>Room No:</Typography>
                <Typography variant="body1" color="initial" sx={{ ml: 1, fontWeight: '600' }}>
                  {service.room}
                </Typography>
              </Box>
            )}
          </Box>
        </Card>
      </Grid>
    ))}



                    {filteredData?.length > serviceItemsPerPage &&
                        <Grid item xs={12}
                              sx={{display: 'flex', justifyContent: 'center', mt: '40px'}} >
                          <CustomPagination
                              totalData={filteredData}   
                              page={servicePage}        
                              setPage={setServicePage} 
                              itemsPerPage={serviceItemsPerPage}/>
                        </Grid>
                    }
                    <Grid item xs={12} style={{display:"flex",justifyContent:"flex-end", marginTop:"20px",   '@media (max-width: 1600px) ': {display:"unset "}}} ref={mainDivRef}>
                    {selectedServices?.length > 0 && bookingType !== "giftcard" && (
  <CustomButton
    size={'large'}
    mainColor={'#27AE60'}
    style={{
      width: "200px",
      "&:hover": {
        borderColor: "#27AE60",
      },
    }}
    handleClick={handleSaveTwo}
    val={'Continue'}
  />
)}
                    </Grid>
                  </Grid>
                </Grid>
                {selectedUser?.id && <Grid container sx={customerside}>
                  <Grid xs={12} item>
                    <Typography style={customernote} sx={{ width:{xs:"100%",sm:'auto'}}}>
                      Add Notes
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                    <FormControl fullWidth sx={{mb: "16px"}}>
                      <FormLabel sx={labelStyle} style={{marginBottom: 10}}>
                        Medical Notes
                      </FormLabel>
                      <TextField
                          fullWidth
                          name={'medical_notes'}
                          sx={inputStyle}
                          onChange={userHandleChange}
                          value={userValues?.medical_notes}
                          placeholder="Medical Notes"
                          multiline
                          rows={4}
                      />
                      {userErrors?.medical_notes && userTouched?.medical_notes &&
                          <FormHelperText sx={{
                            color: '#CB3838',
                            width: 'max-content',
                            mx: 0
                          }}>{userErrors?.medical_notes}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5.5} style={{paddingTop: 10}} sx={{ width:{xs:"100%",sm:'auto'}}}>
                    <FormControl fullWidth sx={{mb: "16px"}}>
                      <FormLabel sx={labelStyle} style={{marginBottom: 10}}>
                        Staff Notes
                      </FormLabel>
                      <TextField
                          fullWidth
                          name={'staff_notes'}
                          style={{mt: 4}}
                          onChange={userHandleChange}
                          value={userValues?.staff_notes}
                          sx={inputStyle}
                          placeholder="Staff Notes"
                          multiline
                          rows={4}
                      />
                      {userErrors?.staff_notes && userTouched?.staff_notes && <FormHelperText sx={{
                        color: '#CB3838',
                        width: 'max-content',
                        mx: 0
                      }}>{userErrors?.staff_notes}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  <Grid
                      item
                      xs={12}
                      sm={5.5}
                      style={{paddingTop: 10}}
                      sx={{ width:{xs:"100%",sm:'auto'}}}
                  >
                    <FormControl fullWidth>
                      <FormLabel sx={{...labelStyle}}>Note By</FormLabel>
                      <Autocomplete
                          options={crmStaffs}
                          value={userValues?.note_by}
                          disableCloseOnSelect={false}
                          isOptionEqualToValue={(option, value) => {
                            return option?.id === value?.id
                          }}
                          getOptionLabel={(option) => option?.name || ''}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  placeholder={'Select Staff'}
                                  sx={inputStyle}
                              />
                          )}
                          onChange={(event, newValue) => {
                            setUserFieldValue('note_by',newValue);
                          }}
                          renderOption={(props, option, {selected}) => (
                              <MenuItem  {...props} key={option?.id} >
                                <Checkbox
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option?.name}
                              </MenuItem>
                          )}
                      />
                      {userErrors?.note_by && userTouched?.note_by && <FormHelperText sx={{
                        color: '#CB3838',
                        width: 'max-content',
                        mx: 0
                      }}>{userErrors?.note_by}</FormHelperText>}
                    </FormControl>
                  </Grid>
                </Grid>
                }
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between', mt: '24px'}} >
                  {/* <Box>
                                    {activeAppointment === 'add' && <CustomButton
                                        size={'large'}
                                        mainColor={'#EB5757'}
                                        subColor={'#fff'} 
                                        handleClick={handleBack}
                                        val={"Back"}
                                    />}

                                </Box> */}
                  {/* { selectedServices?.length > 0 &&
                                    <CustomButton size={'large'} handleClick={handleSaveTwo} val={'Continue'}/>
                                }   */}
                </Grid>
              </Grid>

            </Fragment>
        )
      case 2:
        return (
            <Fragment>
              <div ref={datepageRef}>
                <Grid container>
                  {bookingType === 'service' && (
                      <Grid item md={12} sx={{ display: 'flex', alignItems: 'center', gap: '30px', flexWrap: { xs: 'wrap', sm: 'nowrap' } }}>
                        <Typography>Staff Preference:</Typography>
                        <FormControlLabel
                            size="small"
                            control={
                              <Radio
                                  checked={selectedStaff === null}
                                  onChange={handleAnyStaffSelected}
                              />
                            }
                            label="Any"
                            sx={{
                              '& .MuiSvgIcon-root': {
                                fontSize: 16,
                              },
                            }}
                        />

                        <FormControl>
                          <Autocomplete
                              sx={{ minWidth: '200px' }}
                              options={selectedServices[0]?.staff_service ? selectedServices[0].staff_service : []}                                            value={selectedStaff}
                              disableCloseOnSelect={false}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              getOptionLabel={(option) => option?.name || ''}
                              renderInput={(params) => (
                                  <TextField {...params} placeholder={'Select Staff'} sx={inputStyle} />
                              )}
                              onChange={handleStaffChange}
                              renderOption={(props, option, { selected }) => (
                                  <MenuItem {...props}>
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option?.name}
                                  </MenuItem>
                              )}
                          />
                        </FormControl>
                      </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography sx={contenttime}>Select date & time from available slots</Typography>
                    <Typography sx={timehead}>Treatment Slot: {getTreatmentTime()} Minutes</Typography>
                  </Grid>
                  {bookingType === 'plan' && <Grid item xs={12} >
                    <Typography
                        sx={{fontSize: '20px', textAlign: 'center', fontFamily: "helvetica-lt-std-bold",}}>
                      Session: {sessionActiveStep + 1}
                    </Typography>
                  </Grid>}
                  <Grid xs={12} item sx={{mt: "24px",}} className="testingg">
                    {
                      bookingType === 'plan' ?
                          <Box >
                            <AppointmentPlanSessionTime
                                activeSession={sessionActiveStep}
                                selectedServices={selectedServices[0]?.planService}
                                selectedStaff={selectedStaff}
                                selectedAllPlanTime={selectedPlanTime}
                                selectedTime={selectedPlanTime}
                                setSelectedTime={setSelectedPlanTime}
                                selectedFinalDate={getSelectedDate(sessionActiveStep)}
                                getAppointmentSchedule={getPlanAppointmentSchedule}
                                getAppointmentHoliday={getAppointmentHoliday}
                                executeScroll={executeScroll}
                                ref={dateRef}
                            />

                            {maxSession > 1 &&
                                <Box sx={{my:'16px'}}>
                                  <MobileStepper
                                      variant="text"
                                      steps={maxSession}
                                      position="static"
                                      activeStep={sessionActiveStep}
                                      nextButton={
                                        <Button
                                            size="small"
                                            variant={'contained'}
                                            onClick={handleSessionNext}
                                            disabled={sessionActiveStep === maxSession - 1}
                                        >
                                          <Box sx={{mt:'2px'}}>Next Session</Box>
                                          {theme.direction === 'rtl' ? (
                                              <KeyboardArrowLeft/>
                                          ) : (
                                              <KeyboardArrowRight/>
                                          )}
                                        </Button>
                                      }
                                      backButton={
                                        <Button
                                            size="small"
                                            variant={'contained'}
                                            onClick={handleSessionBack}
                                            disabled={sessionActiveStep === 0}
                                        >
                                          {theme.direction === 'rtl' ? (
                                              <KeyboardArrowRight/>
                                          ) : (
                                              <KeyboardArrowLeft/>
                                          )}
                                          <Box sx={{mt:'2px'}}>Previous Session</Box>
                                        </Button>
                                      }
                                  />
                                </Box>
                            }
                          </Box>
                          :
                          <AppointmentTime
                              ref={dateRef}
                              selectedServices={selectedServices}
                              selectedStaff={selectedStaff}
                              selectedTime={selectedTime}
                              setSelectedTime={setSelectedTime}
                              setSelectedFinalDate={setSelectedFinalDate}
                              selectedFinalDate={selectedFinalDate}
                              getAppointmentSchedule={getAppointmentSchedule}
                              getAppointmentHoliday={getAppointmentHoliday}
                              executeScroll={executeScroll}

                          />

                    }
                  </Grid>
                  <Grid item xs={12}
                        sx={{display: 'flex', justifyContent: 'space-between', marginTop: '24px'}}>
                    {/* <Box>
                                    <CustomButton
                                        size={'large'}
                                        mainColor={'#EB5757'}
                                        subColor={'#fff'}
                                        handleClick={handleBack}
                                        val={"Back"}
                                    />
                                </Box> */}
                    {/*<CustomButton size={'large'} val={'Continue'}/>*/}
                  </Grid>
                </Grid>
              </div>

            </Fragment>
        )
      default:
        return 'Unknown Step'
    }
  }

  /**********************   Plan *********************************/
  const getPlanAppointmentSchedule = async (date,stepId) => {

    setLoading(true)

    if (date){
      let finalDate = [
        {
          stepId:stepId,
          date:date,
        }
      ]
      if (selectedPlanTime?.length > 0){
        let filterDate =  selectedPlanTime.filter((item)=>item?.stepId !== stepId)
        setSelectedPlanTime([...filterDate,...finalDate])
      }else {
        setSelectedPlanTime([...finalDate])
      }


    }

    let serviceIds = []
    selectedServices[0]?.planService.forEach((item) => {
      serviceIds.push(item?.id)
    })


    let finalDate = moment(date).format('YYYY-MM-DD')

    handleDateChange(finalDate)
    setSelectedTime(null)
    let finalData = {
      date: finalDate,
      service_ids: serviceIds,
      staff_id: selectedStaff?.id

    }

    let res = await dispatch(getCrmAppointmentSchedule(finalData))

    setLoading(false)
    console.log("issue",dateRef)
    setTimeout(() => {

      if (dateRef.current) {
        dateRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);

  }

  const getSelectedDate = (stepId) => {

    let filtered = selectedPlanTime.filter((item)=>item?.stepId === stepId)

    if (filtered?.length > 0 ){
      let obj = filtered[0]
      let day = moment(obj?.date).format('DD')
      let month = moment(obj?.date).format('MM')
      let year = moment(obj?.date).format('YYYY')
      return {
        day: parseInt(day),
        month: parseInt(month),
        year:parseInt(year),
      }
    }else {
      return {day:0,month: 13,year:1111}
    }
  }
  const handleSessionNext = () => {
    setSessionActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSessionBack = () => {
    setSessionActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getTreatmentTime = () => {

    if (bookingType === 'plan') {
      let totalTime = 0
      selectedServices[0]?.planService?.forEach((item) => {
        let time = convertTimeToMinutes(item?.time_duration)
        totalTime = totalTime + time
      })

      return totalTime
    } else {

      let totalTime = 0
      selectedServices.forEach((item) => {
        let time = convertTimeToMinutes(item?.time_duration)
        totalTime = totalTime + time
      })

      return totalTime
    }
  }

  const datepageRef =useRef(null)

  const getAppointmentHoliday = async (date, staff) => {

    setLoading(true)
    let newDate = new Date()
    let finalDate = date === false ? moment(date).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD') ;
    console.log("date comes", date)
    if (finalDate) {
      let finalData = {
        date: finalDate
      }
      if (staff?.id) {
        finalData['staff_id'] = staff?.id
      }
      let res = await dispatch(getCrmAppointmentHolidays(finalData))
    }

    setLoading(false)

  }
  const handleSaveTwo = async () => {
    let newDate = new Date()
    handleNext()
    if (activeAppointment === 'add' || activeAppointment === 'newUser') {
      setLoading(true)
      await getAppointmentHoliday(newDate)
      handleDateChange(moment(newDate).format('YYYY-MM-DD'))
      await getAppointmentSchedule(newDate)

      setLoading(false)
      console.log("issue debugging",datepageRef.current)
      setTimeout(() => {
        if (datepageRef.current) {
          datepageRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);

    }
  }

  const dateRef = useRef(null);

  const getAppointmentSchedule = async (date) => {

    setLoading(true)
    let serviceIds = []
    selectedServices.forEach((item) => {
      serviceIds.push(item?.id)
    })


    let finalDate = moment(date).format('YYYY-MM-DD')

    handleDateChange(finalDate)
    setSelectedTime(null)
    let finalData = {
      date: finalDate,
      service_ids: serviceIds,
      staff_id: selectedStaff?.id
    }

    let res = await dispatch(getCrmAppointmentSchedule(finalData))

    setLoading(false)
    setTimeout(() => {
      if (dateRef.current) {
        dateRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);

  }

  const isExist = (arr, foundItem) => {

    if (arr?.length) {
      return arr.some((item) => bookingType === 'plan' ? foundItem?.plan_id === item?.plan_id : foundItem?.id === item?.id)
    } else {
      return false
    }

  }
  const renderContent = () => {
    if (activeType === 'new_customer' && activeStep === 0) {
      return (
          <Fragment>
            <form onSubmit={handleSubmit}>
              <Grid ref={existingCustomerContainerRef}>
                <Grid item style={customerside} sx={{ width:{xs:"100%",sm:'auto'}}}>
                  <Typography sx={content}>Add a New Customer</Typography>
                </Grid>
                <Grid item xs={12}>
                  {UserForm()}
                </Grid>
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', mt: '24px'}}>
                  <SubmitButton val={"Continue "} size={"large"}/>
                </Grid>
              </Grid>
            </form>
          </Fragment>
      )
    } else if (activeType === 'walk_in_customer' && activeStep === 0) {
      return (
          <Fragment>
            <form onSubmit={handleSubmit}>
              <Grid>
                <Grid item xs={12} sx={customerside}>
                  <Typography sx={content}>Add a New Walk In Customer</Typography>
                </Grid>
                <Grid item xs={12}>
                  {UserForm()}
                </Grid>
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', mt: '24px'}}>
                  <SubmitButton val={"Continue "} size={"large"}/>
                </Grid>
              </Grid>
            </form>
          </Fragment>
      )
    }
    return (
        <Grid container>
          {getStepContent(activeStep)}
        </Grid>
    )
  }
  const handleGetServices = async (category, fromServiceAdded = false) => {
    console.log("modal off call", category);
    setSelectedCategory(category);
    setSelectedCategoryId(category?.category_id);
    setFilteredData(category?.services);


    if (fromServiceAdded && category?.services?.length > 0) {

      setTimeout(() => {
        const lastService = category.services[category.services.length - 1];
        handleSelectData(lastService);
      }, 0);
    }
  };

  // const handleGetServices = async (category) => {
  //     console.log("modal off call",category)
  //     setSelectedCategory(category);
  //     setSelectedCategoryId(category?.category_id);
  //     setFilteredData(category?.services)

  // }

  const handleBookingTypeChange = (type) => {
    if (type === bookingType) {
      setBookingType('');
      setFilteredData([]);
      setSelectedCategory('');
    } else {
      setBookingType(type);
  
      if (type === 'plan') {
        scrollToServiceCategoryContainer();
        setFilteredData(crmAllPlans);
      } else if (type === 'giftcard') {
        scrollToServiceCategoryContainer();
        setFilteredData(gifts?.data);
      } else {
        setFilteredData([]);
        setSelectedCategory('');
      }
    }
  
    setSelectedServices([]);
  };

  return (
      <Box>
        <Grid container>
          <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
            <CustomBreadcrumbs
                back={'/crm/appointments'}
                backLabel={'Appointments'}
                name={activeAppointment === 'edit' ? "Edit Appointment" :  "Book New Appointment" || null}
            />
          </Grid> 
        </Grid>
        {loading ? <Waveloading fullSize/> : ""}

        <Grid container style={{display: 'flex', justifyContent: 'space-between',}}>
          {(activeStep === 0 || activeStep === 1) && (
              <Grid item xs={12} lg={2} md={5} sm={2.8} style={{marginTop: '41px'}} ref={extraserviceRef}>
                {activeStep === 0 && (
                    <Box>
                      <FormControl sx={{mb: '16px'}}>
                        <FormLabel sx={{...labelStyle}}>Customer Selection</FormLabel>
                        {customerTypes.map((item, index) => (
                            <FormControlLabel
                                control={<Checkbox
                                    checked={item?.key === activeType}
                                    onChange={e => {
                                      setActiveStep(0)
                                      setActiveType(item?.key)
                                      setUserCards([])
                                      setSelectedUser({})
                                      resetForm()
                                      handleExistingCustomerCheck(e.target.checked)


                                    }}
                                />}
                                label={item?.name}/>
                        ))}
                      </FormControl>
                    </Box>
                )}
                {activeStep === 1 && (
                    <Box ref={existingCustomerContainerRef}>
                      <FormControl sx={{mb: '16px', width:{xl:'auto',lg:'155px', xs:'100%'}}}>
                        <CustomButton
                            handleClick={() => setAddExtraService(true)}
                            mainColor={'#27AE60'}
                            val={"Add Extra Service"}
                        />

                        <CustomModal
                            open={addExtraService}
                            setOpen={()=>setAddExtraService(false)}
                            customWidth={"800px"}
                        >
                          <AddServices  onClose={handleServiceAdded} clinicId={authStore?.user?.id}/>
                        </CustomModal>

                        <Box sx={{  display:{lg:'none',sm:'none', xs:'block'},mt:2, backgroundColor:"white", border:"1px solid silver", borderRadius:"5px" }}>
                          <Autocomplete
                              disablePortal 
                              id="service-category-selector" 
                              options={servicesCategory}
                              getOptionLabel={(option) => option.category_name}
                              sx={{ width: '100%' }}
                              renderInput={(params) => <TextField {...params} label="Service Categories:" />}
                              onChange={(event, newValue) => {
                                if (newValue) { 
                                  handleBookingTypeChange('service');
                                  handleGetServices(newValue);
                                  scrollToServiceCategoryContainer();
                                }
                              }} 
                          />

                        </Box>
                        <Box sx={{  display:{lg:'none',sm:'none', xs:'block'},mt:2, backgroundColor:"white", border:"1px solid silver", borderRadius:"5px" }}>
                          <Autocomplete
                              disablePortal
                              id="plan-selector"
                              options={crmAllPlans}
                              getOptionLabel={(option) => option.plan_name}
                              sx={{ width: '100%' }}
                              renderInput={(params) => <TextField {...params} label="Plan:" />}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  handleBookingTypeChange('plan'); 
                                  scrollToServiceCategoryContainer();
                                }
                              }}
                          />

                        </Box>
                        <Box sx={{  display:{lg:'none',sm:'none', xs:'block'},mt:2, backgroundColor:"white", border:"1px solid silver", borderRadius:"5px" }}>
                          <Autocomplete
                              disablePortal
                              id="plan-selector"
                              options={crmAllPlans}
                              getOptionLabel={(option) => option.plan_name}
                              sx={{ width: '100%' }}
                              renderInput={(params) => <TextField {...params} label="Gift Cards:" />}
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  handleBookingTypeChange('plan'); 
                                  scrollToServiceCategoryContainer();
                                }
                              }}
                          />

                        </Box>

                        <List
                            sx={{
                              mt: '24px',
                              width: "100%",
                              display:{lg:'block', sm:'block', xs:'none'},
                              ".MuiListItemIcon-root ": {
                                minWidth: "37px",
                              },
                            }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                          <ListItemButton
                              sx={{
                                py: '0',
                              }}
                              onClick={() => handleBookingTypeChange('service')}
                          >
                            <ListItemText primary="Service Categories:"/>
                            <Checkbox
                                checked={bookingType === 'service'}
                            />
                          </ListItemButton>
                         
                          <Collapse in={bookingType === 'service'} timeout="auto" unmountOnExit> 
                          {/* {loading ? <Waveloading fullSize/> :  */}

    <List component="div" disablePadding> 
      {servicesCategory?.length > 0 && servicesCategory.map((category, index) => ( 
          category?.is_show === 1 && (
              <ListItemButton
               sx={{py: '0', pl: '30px'}}
                  key={index}
                  onClick={() => {
                    handleGetServices(category)
                    scrollToServiceCategoryContainer()
                  }}
              >
                <FormControlLabel
                    control={
                      <Checkbox
                          checked={selectedCategoryId === category?.category_id}
                      />
                    }
                    label={category?.category_name}
                />
              </ListItemButton>
          )
      ))}
    </List>   


                          </Collapse>
                     
                          <ListItemButton
                              sx={{
                                py: '0',
                              }}
                              onClick={() => handleBookingTypeChange('plan')}
                          >
                            <ListItemText primary="Bundle:"/>
                            <Checkbox
                                checked={bookingType === 'plan'}
                            />
                          </ListItemButton>
                          <ListItemButton
                              sx={{
                                py: '0',
                              }}
                              onClick={() => handleBookingTypeChange('giftcard')}
                          >
                            <ListItemText primary="Gift Cards:"/>
                            <Checkbox
                                checked={bookingType === 'giftcard'}
                            />
                          </ListItemButton>
                        </List>
                        
                      </FormControl> 
                    </Box>
                )}
              </Grid>
          )}

          <Grid
              item
              xs={12}
              lg={activeStep === 2 ? 7.8 : 5.8}
              md={activeStep === 2 ? 12 : 11.6}
              sm={activeStep === 2 ? 12 : 8.6}

          >
            <Fragment>
              <Card sx={{mt: 4, pt: 5}}>


                { (activeStep=== 1) || activeStep === 2 ? (
                    <Box sx={{mb:3, ml:3}}>
                      <CustomButton
                          size={'large'}
                          mainColor={'#EB5757'}
                          subColor={'#fff'}
                          handleClick={handleBack}
                          val={"Go Back"}
                      />
                    </Box>
                ) : null }
                <StepperWrapper>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((step, index) => {
                      return (
                          <Step key={index}>
                            <StepLabel
                                onClick={() => {
                                  if (index === 0 && selectedUser?.id) {
                                    setActiveStep(index);
                                  } else if (index === 1 && selectedServices?.length > 0) {
                                    setActiveStep(index);
                                  } else if (index === 2) { 
                                    setActiveStep(index);
                                  }
                                }}
                                sx={{cursor: 'pointer'}}
                            >
                              {step?.title}
                            </StepLabel>
                          </Step>
                      )
                    })}
                  </Stepper>
                </StepperWrapper>
                <CardContent>{renderContent()}</CardContent>
              </Card>
            </Fragment>
          </Grid>
          {/* payment Method  */}
          {/* <div ref={scrollRef}> */}
          <Grid ref={scrollRef}  sx={booking} item xs={12} lg={4} sm={8.6} md={11.6}>

            <AppointmentPayment
                bookingType={bookingType}
                appointmentDetailsById={appointmentDetailsById}
                activeAppointment={activeAppointment}
                selectedFinalDate={selectedFinalDate}
                selectedTime={selectedTime}
                setSelectedServices={setSelectedServices}
                selectedServices={selectedServices}
                selectedStaff={selectedStaff}
                selectedUser={selectedUser}
                newUser={values}
                userCards={userCards}
                selectedUserNotes={userValues}
                selectedPlanTime={selectedPlanTime}
            />

          </Grid>
          {/* </div> */}
        </Grid>
        {modalData && (
  <PriceSelectorModal
    open={openModal}
    onClose={handleCloseModal}
    onSelectPrice={onSelectPrice}
    service={modalData}
  />
)}
      </Box>
  );
};

export default AddAppointment;