import React, {useEffect, useRef} from 'react';
import PerfectScrollbarComponent from 'react-perfect-scrollbar'
import {styled} from "@mui/material/styles";
import {Check} from "@mui/icons-material";
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import Box from "@mui/material/Box";
import {getInitials} from "./Chat";
import {Avatar, Typography} from "@mui/material";
import { getAllMessages } from '../../../store/user/Chat-modue';

const PerfectScrollbar = styled(PerfectScrollbarComponent)(({ theme }) => ({
    padding: theme.spacing(5)
}))
const ChatLog = (props) => {
    const { data, hidden } = props
    console.log("get chat in chat",data)


    // ** Ref
    const chatArea = useRef(null)
    
    const scrollToBottom = () => {
        if (chatArea.current) {
            if (hidden) { 
                // @ts-ignore
                chatArea.current.scrollTop = Number.MAX_SAFE_INTEGER
            } else {
                // @ts-ignore
                chatArea.current._container.scrollTop = Number.MAX_SAFE_INTEGER
            }    
        }
    }

    // ** Formats chat data based on sender
    const formattedChatData = () => {
        let chatLog = []
        if (data?.chat) {  
            chatLog = data?.chat
        }
        const formattedChatLog = []
        let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : 11

        let msgGroup = {
            senderId: chatMessageSenderId,
            messages: []
        }
        chatLog.forEach((msg, index) => {
            if (chatMessageSenderId === msg.senderId) {
                msgGroup.messages.push({
                    time: msg.time,
                    msg: msg.message,
                    feedback: msg.feedback
                })
            } else {
                chatMessageSenderId = msg.senderId
                formattedChatLog.push(msgGroup)
                msgGroup = {
                    senderId: msg.senderId,
                    messages: [
                        {
                            time: msg.time,
                            msg: msg.message,
                            feedback: msg.feedback
                        }
                    ]
                }    
            }
            if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
        })
        return formattedChatLog
    }

    const renderMsgFeedback = (isSender, feedback) => {
        if (isSender) {
            if (feedback.isSent && !feedback.isDelivered) {
                return <Check sx={{ mr: 2, fontSize: '1rem', color: 'text.secondary' }} />
            } else if (feedback.isSent && feedback.isDelivered) {
                return <DoneAllOutlinedIcon sx={{ mr: 2, fontSize: '1rem', color: feedback.isSeen ? 'success.main' : 'text.secondary' }} />
            } else {
                return null
            }
        }
    }
    useEffect(() => {
        if (data && data.chat && Array.isArray(data.chat) && data.chat.length) {
            scrollToBottom();
        }
    }, [data]);
    

    // ** Renders user chat
    const renderChats = () => { 
        return formattedChatData().map((item, index) => {
            const isSender = item.senderId === data.userContact.id

{console.log("profile_image",item.senderId,data.userContact.id)}
            return (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: !isSender ? 'row' : 'row-reverse',
                        mb: index !== formattedChatData().length - 1 ? 5.75 : undefined
                    }} 
                >
                    <Box>
                        {/* <Avatar
                            skin='light'
                            // color={data.contact.avatarColor ? data.contact.avatarColor : undefined}
                            sx={{
                                width: '2rem',
                                height: '2rem',
                                fontSize: '0.875rem',
                                ml: isSender ? 2 : undefined,
                                mr: !isSender ? 2 : undefined
                            }}
                            // data.contact.avatar && 
                            {...(!isSender
                                ? {
                                    src: data.contact.profile_image,
                                    alt: data.contact.name
                                }
                                : {})}
                            {...(isSender
                                ? {
                                    src: data.userContact.avatar,
                                    alt: data.userContact.name
                                }
                                : {})}
                        >
                            {/* {data.contact.avatarColor ? getInitials(data.contact.name) : null} */}
                            {/* { getInitials(data.contact.name) }
                        </Avatar> */}

                        <Avatar
    skin='light'
    sx={{
        width: '2rem',
        height: '2rem',
        fontSize: '0.875rem',
        ml: isSender ? 2 : undefined,
        mr: !isSender ? 2 : undefined,
        // color:"#d9fdd3"
    }}
    {...(data.profile_image && !isSender
        ? {
            src: data?.profile_image || 'pr', // Use fallback empty string
            alt: data?.name || 'Unknown'    // Use fallback 'Unknown'
        }
        : {})}
    {...(isSender
        ? {
            src: data?.userContact?.profile_image || '',     // Use fallback empty string
            alt: data?.userContact?.name || 'You'     // Use fallback 'You'
        }
        : {})}
>  

{console.log('Contact Name:', data)} 
    {getInitials(data?.name || 'U')} {/* Fallback to 'U' if name is missing */}
</Avatar>
                    </Box>

                    <Box className='chat-body' sx={{ maxWidth: ['calc(100% - 5.75rem)', '75%', '65%'] }}>
    {item.messages.map((chat, index) => {
        const time = new Date(chat.time);

        return (
            <Box key={index} sx={{ '&:not(:last-of-type)': { mb: 3.5 } }}>
                <Box>
                    <Typography
                        sx={{
                            boxShadow: 1,
                            borderRadius: "30px",
                            width: 'fit-content',
                            fontSize: '0.875rem',
                            p: "16px",
                            ml: isSender ? 'auto' : undefined,
                            borderTopLeftRadius: !isSender ? 0 : undefined,
                            borderTopRightRadius: isSender ? 0 : undefined,
                            color: '#fff',
                            backgroundColor: isSender ? "#64748B" : '#818CF8'
                        }}
                    >
                        {chat.msg}
                    </Typography>
                </Box>
                {/* Render the time for every message */}
                <Box
                    sx={{
                        mt: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: isSender ? 'flex-end' : 'flex-start'
                    }}
                >
                    {renderMsgFeedback(isSender, chat.feedback)}
                    <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                        {time ? new Date(time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : null}
                    </Typography>
                </Box>
            </Box>
        );
    })}
</Box>
                </Box>
            )
        })
    }

    const ScrollWrapper = ({ children }) => {
        if (hidden) {
            return (
                <Box ref={chatArea} sx={{ p: 5, height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                    {children}
                </Box>
            )
        } else {
            return (
                <PerfectScrollbar ref={chatArea} options={{ wheelPropagation: false }}>
                    {children}
                </PerfectScrollbar>
            )
        }
    }

    return (
        <Box sx={{ height: 'calc(100% - 135px)' }}>
            <ScrollWrapper>{renderChats()}</ScrollWrapper>
        </Box>
    )
};

export default ChatLog;