import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {useNavigate} from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {  fetchUserToken } from "../../store/auth";
const CloseTab = () => {
    const timerDuration = 0.3 * 60 * 1000;
const dispatch=useDispatch()
    const navigate = useNavigate()
    // Initialize the timer state and start time
    const [timer, setTimer] = useState(timerDuration);
    const [startTime, setStartTime] = useState(Date.now());

    const loadUser=async()=>{
        await dispatch(fetchUserToken());
        navigate('/user/bookings');
        }

    // Update the timer value every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Calculate the elapsed time
            const elapsedTime = Date.now() - startTime;

            // Calculate the remaining time
            const remainingTime = Math.max(0, timerDuration - elapsedTime);

            // Update the timer state
            setTimer(remainingTime);

            // Stop the timer when the time is up
            if (remainingTime <= 0) {
                clearInterval(intervalId);
                loadUser()
            }
        }, 1000);

        // Clear the interval when the component unmounts or the timer reaches zero
        return () => clearInterval(intervalId);
    }, [startTime, timerDuration]);

    // Convert the timer value to minutes and seconds
    const minutes = Math.floor(timer / 60000);
    const seconds = Math.floor((timer % 60000) / 1000);

    // Render the timer value
    return (
        <Box sx={{color:'#C75450',fontSize:'16px'}}>
            This tab will closed in {minutes}:{seconds.toString().padStart(2, '0')}
        </Box>
    );
};

export default CloseTab;