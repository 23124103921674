import React, {useState} from 'react';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

const styles = {
    oddRow: {
        backgroundColor: "#F5FCFF", // Set your desired color
    },
};

const TransactionList = ({row,index}) => {

    return (
        <TableRow
            key={row?.Date}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            style={index % 2 === 1 ? {} : styles.oddRow}
        >
            <TableCell align="left" sx={{ width: "25%",borderBottom:0, fontSize:'18px',fontWeight:'400' }}>
                {moment(row?.created_at).format('DD-MM-YYYY hh:mm')}
            </TableCell>
            <TableCell align="left" sx={{ width: "50%",borderBottom:0, fontSize:'18px',fontWeight:'400' }}>
                {row.details || ''}
            </TableCell>
            <TableCell align="center" sx={{ width: "25%",borderBottom:0, fontSize:'18px',fontWeight:'400' }}>
                {row?.type.toLowerCase() === 'overwrite' ? '' : (row?.type.toLowerCase() === 'credit' ? '+' : '-')}
                {row.wallet_point}
            </TableCell>
            <TableCell align="center" sx={{ width: "25%",borderBottom:0, fontSize:'18px',fontWeight:'400' }}>
                {row.after_point}
            </TableCell>
        </TableRow>
    );
};

export default TransactionList;