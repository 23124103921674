import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment/moment";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { dateStyle, disableStyle} from "./bookingCalender";
import {editUserBooking, getBooingHolidays, getBookingSchedules} from "../../store/booking-widget";
import {convertTimeToMinutes, getEndTime, getTimeSlotsArray, MySwal} from "../../services/utills";
import toast from "react-hot-toast";
import { useRef } from 'react';
import Loader from '../../components/loader/loader';
import { useLocation, useNavigate } from 'react-router';

export const boxStyle ={
    height:'30px',
    width:'30px',
    border:"1px solid #E6DAD1",
    display:'flex', 
    alignItems:"center",
    borderRadius:'4px',
    justifyContent:'center',
    color:'#000', 
    cursor:'pointer',
    padding:'4px 0',
    "&:hover": {
        background:"#E6DAD1",  
        color:'#fff'
    }
}

const RescheduleCalender = ({ rescheduleData,handleClose ,setConfirmation,type,fetchAllBookedPlan,setLoading,loading}) => {
    const [selectedDate,setSelectedDate] = useState(0)
    const [selectedFullDate,setSelectedFullDate] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)
    const navigate = useNavigate(); 
    const location = useLocation();
    const [loadingdate, setLoadingDate] = useState(false);
    // const [loading, setLoading] = useState(false);

    let currentData = new Date() 
    const ref = useRef();
    const refbtn = useRef();
    const [ startDate,setStartDate] = useState(currentData)
    const isCurrentMonth = (currentData.getMonth() === startDate.getMonth()) && ( currentData.getFullYear() === startDate.getFullYear())
    const monthStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const monthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    const daysInMonth = monthEnd.getDate();
    const firstDayOfWeek = monthStart.getDay();
    const lastDayOfWeek = monthEnd.getDay();

    const bookingHolidays = useSelector(state => state?.bookingWidgetStore?.holidays)
    const bookingSlots = useSelector(state => state?.bookingWidgetStore?.bookingSlots)

    const dispatch = useDispatch()

    const weeks = [];
    let days = [];
    useEffect(()=> {
        (async ()=>{
            setLoading(true)
            let finalData = {
                date: moment(startDate).format("YYYY-MM-DD"),
                provider_id: rescheduleData?.provider?.id
            }
            if (rescheduleData?.staff?.id) {
                finalData['staff_id'] = rescheduleData?.staff?.id
            }
            let res = await dispatch(getBooingHolidays(finalData))

            setLoading(false)
           
          
        })()
    },[startDate])

    const handleTimeSelect = (slotsArray,startTime) => {
      

        let totalTime =0
        rescheduleData?.services.forEach((item)=>{
            let time  = convertTimeToMinutes(item?.time_duration)
            totalTime = totalTime + time
        })

        let endTime = getEndTime(startTime,totalTime)
        let getArraySlots = getTimeSlotsArray(startTime,endTime,slotsArray)
        let lastIndex = getArraySlots[getArraySlots?.length - 1]

        if (bookingSlots?.slots.includes(lastIndex)){
            let object ={
                start:startTime,
                end:endTime,
                slots :getArraySlots
            }

            setSelectedTime(object)
            setTimeout(() => {
                if (refbtn.current) {
                    refbtn.current.scrollIntoView({ behavior: "smooth" });
                }
            }, 100);

        }else {
            toast.error("Booking ending time exceed working time",{duration:5000})
            setSelectedTime(null)
        }

    }

    for (let i = 0; i < firstDayOfWeek; i++) {
        days.push(
            <TableCell key={`empty-${i}`}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }

    for (let i = 1; i <= daysInMonth; i++) {
        const isExist = bookingHolidays?.length> 0 && bookingHolidays.some((item) => parseFloat(item) === parseFloat(i))

        days.push(
            <TableCell key={i}>
                <Box
                    sx={{
                        ...dateStyle,
                        background: ((isCurrentMonth && i < currentData.getDate()) || isExist ||(i !== selectedDate)) ?"#fff" : "#E6DAD1",
                        cursor: ((isCurrentMonth && i < currentData.getDate()) || isExist) ? 'default' : 'pointer',
                        border: ((isCurrentMonth && i < currentData.getDate()) || isExist) ? "#fff" :'1px solid #E6DAD1',
                        color:((isCurrentMonth && i < currentData.getDate()) || isExist) ? "#ADB1B5" :(i !== selectedDate) ? '#000':'#000',
                        pointerEvents:((isCurrentMonth && i < currentData.getDate()) || isExist) ? "none" :'auto',
                        "&:hover": {
                            background: ((isCurrentMonth && i < currentData.getDate()) || isExist) ?"#fff" : "#E6DAD1",
                            color: ((isCurrentMonth && i < currentData.getDate()) || isExist) ?"#ADB1B5" :'#000'
                        }
                    }}
                    onClick={async ()=> {
                        setLoadingDate(true)
                        if (ref.current) {
                            ref.current.scrollIntoView({ behavior: "smooth" });
                          }
                        setSelectedDate(i)
                        setSelectedTime(null)
                        const date = moment(new Date(startDate.getFullYear(), startDate.getMonth() , i)).format('YYYY-MM-DD')
                        setSelectedFullDate(date)

                        let selectedServicesIds=[]
                        rescheduleData?.services.forEach((item)=>{
                            selectedServicesIds.push(item?.id)
                        })

                        let finaData ={
                            date:date ,
                            provider_id:rescheduleData?.provider?.id,
                            service_ids:selectedServicesIds,
                        }
                        if (rescheduleData?.staff?.id){
                            finaData["staff_id"] = rescheduleData?.staff?.id
                        }

                        let  res = await dispatch(getBookingSchedules(finaData))
                        if (res?.payload?.success === "true"){
                        }
                        setLoadingDate(false)
                        console.log("issue",ref.current)
                        
                    }}
                >
                    {i}
                </Box>
            </TableCell>
        );

        if ((i + firstDayOfWeek) % 7 === 0 || i === daysInMonth) {
            weeks.push(<TableRow key={`week-${i}`}>{days}</TableRow>);
            days = [];
        }
    }
    for (let i = (lastDayOfWeek + 1) % 7; i < 6; i++) {
        days.push(
            <TableCell key={`empty-${i}`}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }

    const handleReset = () => {
        setSelectedDate(0)
        setSelectedTime(null)
        setSelectedFullDate(null)
    }
    const handleRescheduleBooking = async () => {
        handleClose()
        setLoading(true)
        let finalData  = {
            booking_id:rescheduleData?.id,
            date:selectedFullDate,
            start_time:selectedTime?.start,
            end_time:selectedTime?.end,
            type:type || false
        }
        let res = await dispatch(editUserBooking(finalData))
        setLoading(false)
       
        if (res?.payload?.success === "true"){
          
            if (fetchAllBookedPlan){
               await fetchAllBookedPlan()
            }
            if (type){
                await MySwal.fire({
                    icon: 'success',
                    title: 'Congratulations',
                    showConfirmButton:false,
                    html:<Box sx={{mb: 4}}>
                        <Typography sx={{}}>
                            Your booking has been reschedule.
                        </Typography>
                        <Button
                            sx={{
                                mt:3,
                                backgroundColor: "#fff",
                                minWidth: "200px",
                                height: '62px',
                                border: "1px solid #666666",
                                borderRadius: '10px',
                                fontSize: '20px',
                                color: "#666666",
                                "&:hover": {
                                    backgroundColor: "#fff",
                                    border: "1px solid #666666",
                                },
                            }}
                            onClick={() => {
                                MySwal.close();
                                navigate("/user/bookings");
                                handleClose()
                            }}
                        >   
                            Ok
                        </Button>
                        
                    </Box>
                }) 
            }
            if (setConfirmation){
                setConfirmation(res?.payload?.data)
                // console.log("Redirecting to /user/bookings");
                // navigate("/user/bookings");
                // navigate("/user/bookings")
                // const queryParams = new URLSearchParams(location.search);
                // if (!queryParams.has('booking_id')) {
                //  
                // }
            }
        }else {       
            const errors = res?.payload?.errors
            if (errors){
                toast.error(errors,{duration:5000})
            }
        } 
        setLoading(false)
    }
 
    return ( 
        <Box sx={{minHeight: '800px' }}>
            <Box
                sx={{
                    borderTop:'1px solid #D5D9DC',
                    borderLeft:'1px solid #D5D9DC',  
                    borderTopRightRadius:'4px',
                    borderTopLeftRadius:'4px',
                    borderRight:'1px solid #D5D9DC',
                    display:'flex',
                    p:'12px',
                    justifyContent:'space-between'
                }}>

                <Box sx={isCurrentMonth ?{...boxStyle,...disableStyle} : {...boxStyle}} onClick={()=>{
                    if (!isCurrentMonth){   
                        const end = new Date(startDate);
                        end.setMonth(end.getMonth() - 1);
                        setStartDate(end);
                        handleReset()
                        // getMonthHolidays(end,selectedStaff)
                    }
                }}>
                    <KeyboardArrowLeftIcon/>
                </Box>
                <Typography sx={{textAlign:'center',fontSize:'20px',fontWeight:'bold',p:'5px 0'}}>
                    {startDate.toLocaleDateString("en-US", {month: "short", year: "numeric"})}
                </Typography>
                <Box  sx={boxStyle} onClick={()=>{
                    const end = new Date(startDate);
                    end.setMonth(end.getMonth() + 1);
                    setStartDate(end);
                    handleReset()
                    // getMonthHolidays(end,selectedStaff)
                }}>
                    <KeyboardArrowRightIcon/>
                </Box>
            </Box>
            <Box sx={{
                border:'1px solid #D5D9DC',
                borderBottomLeftRadius:'4px',
                borderBottomRightRadius:'4px',
            }}>
                <Table style={{ tableLayout:'fixed' }} aria-label="simple table" sx={{
                    boxShadow: 'none', "td , th": {
                        border:'none',
                        textAlign:'center',
                    },
                    "th": {
                        fontWeight:'bold'   
                    },
                    "td": {
                        padding:"5px 0 !important",
                        margin:'0 !important'
                    }
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell >S</TableCell>
                            <TableCell>M</TableCell>
                            <TableCell >T</TableCell>
                            <TableCell >W</TableCell>
                            <TableCell >T</TableCell>
                            <TableCell >F</TableCell>
                            <TableCell >S</       TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {weeks}
                        <TableRow sx={{
                            "td": {
                                py:"20px!important"
                            }
                        }}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell ></TableCell>
                            <TableCell ></TableCell>
                            <TableCell ></TableCell>
                            <TableCell ></TableCell>
                            <TableCell></TableCell>
                        </TableRow> 
                    </TableBody>
                </Table>
            </Box>
            {loadingdate === true ? <Loader fullSize />:(
                 <div ref={ref} >

        
                 {bookingSlots?.success && !loading && selectedFullDate && <Box >
                     {selectedFullDate && <Typography
                         variant={'h3'}
                         sx={{
                             fontWeight: 'bold',
                             my: '16px',
                             fontSize: '18px',
                             color: 'black'
                         }}
                     >
                         Availability on {moment(selectedFullDate).format("dddd, MMMM D, YYYY")}
                     </Typography>}
                     <Typography sx={{
                         mt: '16px',
                         mb: "8px",
                         fontSize: '16px',
                         fontWeight: "bold"
                     }}>Morning</Typography>
                     <Box sx={{ '@media (max-width:600px)':{
                               display:'flex ',
                               justifyContent:'center',
                               flexWrap:'wrap'
                             }, }}>
                         {bookingSlots?.morningTimeSlots?.length > 0 ?
                             bookingSlots?.morningTimeSlots.map((item, index) => ( 
                                 <Button 
                                         key={index}  
                                         variant={"outlined"}      
                                         sx={{
                                           color: selectedTime?.slots.includes(item)
                                             ? "#000"  
                                              : "#000",
                                             backgroundColor: selectedTime?.slots[0] === item ? "#fff" : "#E6DAD1",
                                           border: "1px solid #E6DAD1",
                                           fontWeight: "bold",
                                           minWidth: "140px",  
                                           '@media (max-width:340px)':{
                                             minWidth: "110px !important",
                                           },
                                           '@media (max-width:300px)':{
                                             minWidth: "110px !important", 
                                           },
                                           mr: "10px",
                                           mb: "10px",
                                           "&:hover": {
                                             border: "1px solid #E6DAD1",
                                             color: selectedTime?.slots.includes(
                                               item
                                             )
                                               ? "#000"
                                               : "#000",
                                               backgroundColor: selectedTime?.slots[0] === item ? "#fff" : "#E6DAD1",
                                           },   
                                         }}
                                         onClick={() =>
                                           handleTimeSelect(
                                             bookingSlots?.slots,
                                             item
                                           )
                                         }        
                                       >
                                         {selectedTime?.slots[0] === item ? "✔ " : ""}{item}
                                       </Button>
                             ))
                             : <Button
                                 disabled
                                 variant={"contained"}
                                 sx={{
                                     backgroundColor: '#000',
                                     color: '#E6DAD1',
                                     fontWeight: 'bold',
                                 }}
                             >
                                 All Booked
                             </Button>
                         }
                     </Box>
                     <Typography sx={{
                         mt: '16px',
                         mb: "8px", 
                         fontSize: '16px',
                         fontWeight: "bold"
                     }}>Afternoon</Typography>
                     <Box sx={{ '@media (max-width:600px)':{
                               display:'flex ',
                               justifyContent:'center',
                               flexWrap:'wrap'
                             }, }}>
                         {bookingSlots?.afternoonTimeSlots?.length > 0 ?
                             bookingSlots?.afternoonTimeSlots.map((item, index) => (
                                 <Button
                                         key={index}                                      
                                         variant={"outlined"}
                                         sx={{ 
                                           color: selectedTime?.slots.includes(item)
                                             ? "#000"
                                             : "#000",
                                             backgroundColor: selectedTime?.slots[0] === item ? "#fff" : "#E6DAD1",
                                           border: "1px solid #E6DAD1",
                                           fontWeight: "bold",
                                           minWidth: "140px",
                                           '@media (max-width:340px)':{
                                             minWidth: "110px !important",
                                           },
                                           '@media (max-width:300px)':{
                                             minWidth: "110px !important",
                                           },
                                           mr: "10px",
                                           mb: "10px",
                                           "&:hover": {
                                             border: "1px solid #E6DAD1",
                                             color: selectedTime?.slots.includes(
                                               item
                                             )
                                               ? "#000"
                                               : "#000",
                                               backgroundColor: selectedTime?.slots[0] === item ? "#fff" : "#E6DAD1",
                                           },
                                         }}
                                         onClick={() =>
                                           handleTimeSelect(
                                             bookingSlots?.slots,
                                             item
                                           )
                                         }
                                       >
                                         {selectedTime?.slots[0] === item ? "✔ " : ""}{item}
                                       </Button>
                             ))
                             : <Button
                                 disabled
                                 variant={"contained"}
                                 sx={{
                                     backgroundColor: '#E6DAD1',
                                     color: '#000',
                                     fontWeight: 'bold',
                                 }}
                             >
                                 All Booked
                             </Button>
                         }
                     </Box>
                     <Typography sx={{
                         mt: '16px',
                         mb: "8px",
                         fontSize: '16px',
                         fontWeight: "bold"
                     }}>Evening</Typography>
                     <Box sx={{ '@media (max-width:600px)':{
                               display:'flex ',
                               justifyContent:'center',
                               flexWrap:'wrap'
                             }, }}>
                         {bookingSlots?.eveningTimeSlots?.length > 0 ?
                             bookingSlots?.eveningTimeSlots.map((item, index) => (
                                 <Button
                                 key={index}
                                 variant={"outlined"}
                                 sx={{
                                   color: selectedTime?.slots.includes(item)
                                     ? "#000"
                                     : "#000",
                                     backgroundColor: selectedTime?.slots[0] === item ? "#fff" : "#E6DAD1",
                                   border: "1px solid #E6DAD1",
                                   fontWeight: "bold",
                                   minWidth: "140px",
                                   '@media (max-width:340px)':{
                                     minWidth: "110px !important",
                                   },
                                   '@media (max-width:300px)':{
                                     minWidth: "110px !important",
                                   },
                                   mr: "10px",
                                   mb: "10px",
                                   "&:hover": {
                                     border: "1px solid #E6DAD1",
                                     color: selectedTime?.slots.includes(
                                       item
                                     )
                                       ? "#000"
                                       : "#000",
                                       backgroundColor: selectedTime?.slots[0] === item ? "#fff" : "#E6DAD1",
                                   },
                                 }}
                                 onClick={() =>
                                   handleTimeSelect(
                                     bookingSlots?.slots,
                                     item
                                   )
                                 }
                               >
                                 {selectedTime?.slots[0] === item ? "✔ " : ""}{item}
                               </Button>
                             ))
                             : <Button
                                 disabled
                                 variant={"contained"}
                                 sx={{
                                     backgroundColor: '#E6DAD1',
                                     color: '#000',
                                     fontWeight: 'bold',
                                 }}
                             >
                                 All Booked
                             </Button>
                         }
                     </Box>
                 </Box>
                 }
                    </div>

            )}
            
          
               <div sx={{minHeight: '200px' }}  ref={refbtn}>

            {selectedTime?.start &&<Button
                fullWidth
                variant={"contained"}
                onClick={() => handleRescheduleBooking()}
                sx={{backgroundColor: '#E6DAD1', height: '48px',color:'#000', my: '24px'}}
            >
                Reschedule
            </Button>}
            </div>


            
        </Box>
    );
};

export default RescheduleCalender;