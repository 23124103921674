import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { apiHeadersImage} from "../../../../lib/apiHeader";
import apis from "../../../../lib/apis/apis";
import {getClinicByID} from "../../index";

export const storeClinicBusinessDetails = createAsyncThunk('storeClinicBusinessDetails', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_clinics_business_info, data?.data,{
            headers: apiHeadersImage()
        })

        if (response?.data?.errors) {
            return response?.data
        } else if (response?.data?.success === "true") {
            await dispatch(getClinicByID(data?.id))
            toast.success(`Business Details Updated Successfully`, {duration: 3000})
        }

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})


export const clinicBusinessDetails = createSlice({
    name: "clinicBusinessDetails",
    initialState: {
        servicesTypes: [],
    },
    reducers: {},
    extraReducers: (builder) => {

    },
});

export default clinicBusinessDetails.reducer;
