import React, {useEffect, useState} from 'react';
import {Box,  Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SmsNotification from "../templates/sms/smsNotification";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import {getNotificationTemplateById} from "../../../../store/notification-templates";
import Loader from "../../../../components/loader/loader";
import EmailPushNotification from "../templates/mail/emailPushNotification";

const CustomerSignup = ({eventName}) => {
    const [activeType, setActiveType] = useState("Mail")
    const [loading, setLoading] = useState(false)


    const {event_id} = useParams()
    const dispatch = useDispatch()
    const notificationTemplateStore = useSelector(state => state?.notificationTemplateStore)
    const {notificationTemplates={}} = notificationTemplateStore


    const handleStaffChange = async (event, newValue) => {
        setActiveType(newValue)
    }

    useEffect(()=>{
        (async ()=>{
            if (event_id !== undefined && !notificationTemplates?.event_id){
                setLoading(true)
               await dispatch(getNotificationTemplateById(event_id))
                setLoading(false)
            }
        })()
    },[event_id])

    return (
        <Box
            sx={{
                p: '24px',
            }}
        >
            {loading ? <Loader fullSize/> :''}
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    width: 'max-content',
                    maxWidth: '100%',
                    overflowX: 'auto',
                }}
            >
                <Tabs
                    value={activeType}
                    onChange={handleStaffChange}
                    scrollButtons="auto"
                    aria-label="auto tabs example"
                    sx={{
                        ".MuiTabs-indicator": {
                            background: '#000000'
                        },
                        ".Mui-selected": {
                            color: '#000!important',
                            fontWeight: '600'
                        }
                    }}
                >
                    <Tab
                        label={"Mail"}
                        value={'Mail'}
                        sx={{
                            fontSize: '18px',
                            color: '#000',
                            textTransform: "capitalize",
                            fontFamily: activeType === 'Mail' ? "helvetica-lt-std-bold" : 'helvetica-lt-std-roman',
                        }}
                    />
                    <Tab
                        label={"SMS"}
                        value={'SMS'}
                        sx={{
                            fontSize: '18px',
                            color: '#000',
                            textTransform: "capitalize",
                            fontFamily: activeType === 'SMS' ? "helvetica-lt-std-bold" : 'helvetica-lt-std-roman',
                        }}
                    />
                    <Tab
                        label={"Push Notification"}
                        value={'Push Notification'}
                        sx={{
                            fontSize: '18px',
                            color: '#000',
                            textTransform: "capitalize",
                            fontFamily: activeType === 'Push Notification' ? "helvetica-lt-std-bold" : 'helvetica-lt-std-roman',
                        }}
                    />

                </Tabs>
            </Box>
            <Box sx={{mt: '24px'}}>
                <Typography
                    sx={{
                        fontFamily: 'helvetica-lt-std-bold',
                    }}
                >
                    {eventName}
                </Typography>

                { activeType ==='Mail' &&
                    <Box>
                        <EmailPushNotification
                            activeType={activeType}
                            eventName={eventName}
                            setLoading={setLoading}
                            data={notificationTemplates?.Email}
                        />
                    </Box>
                }
                { activeType ==='SMS' &&
                    <Box>
                        <SmsNotification
                            activeType={activeType}
                            eventName={eventName}
                            setLoading={setLoading}
                            data={notificationTemplates?.Sms}
                        />
                    </Box>
                }
                { activeType ==='Push Notification' &&
                    <Box>
                        <EmailPushNotification
                            activeType={activeType}
                            eventName={eventName}
                            setLoading={setLoading}
                            data={notificationTemplates?.Push}
                        />
                    </Box>
                }


                {/*<FormControlLabel*/}
                {/*    value="end"*/}
                {/*    control={*/}
                {/*    <Switch*/}
                {/*        color="primary"*/}
                {/*        checked={checked}*/}
                {/*        onChange={handleChange}*/}
                {/*    />*/}
                {/*}*/}
                {/*    label="Staff Mail"*/}
                {/*    labelPlacement="end"*/}
                {/*/>*/}
            </Box>
        </Box>
    );
}

export default CustomerSignup;