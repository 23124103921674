import React, {useEffect, useState} from 'react';
import {Box, FormControl, FormControlLabel, FormLabel, MenuItem, Select, Switch, TextField} from "@mui/material";
import MailTemplate from "../mailTemplate";
import {getAllPlaceData, updateNotificationTemplate} from "../../../../../store/notification-templates";
import {successConfirmation} from "../../../../../services/utills";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {inputStyle, labelStyle} from "../../../../../components/form/formInput";
import CustomButton from "../../../../../components/custom-button/customButton";
export const selectStyle = {
    background: "#fff",
    borderRadius: "4px",
    height: "44px",
    marginRight: "5px",
    marginLeft: "5px",
    // ".MuiOutlinedInput-notchedOutline": {
    //   borderWidth: "0!important",
    // },
    ".MuiSelect-select": {
    //   borderColor: " #fff",
  
      minHeight: "unset",
      "&:focus": {
        borderColor: "#fff",
        outline: "none",
        input: {
          height: "11px",
        },
      },
      "&:hover": {
        borderColor: "#fff",
        outline: "none",
      },
    },
  };

const SmsNotification = ({activeType,data,setLoading,eventName}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [customerChecked, setCustomerChecked] = useState(0);
    const [customerMailBody, setCustomerMailBody] = useState('');
    const [providerChecked, setProviderChecked] = useState(0);   
    const [providerMailBody, setProviderMailBody] = useState('');
    const [adminChecked, setAdminChecked] = React.useState(0);
    const [adminMailBody, setAdminMailBody] = useState('');
    const [staffChecked, setStaffChecked] = React.useState(0);
    const [staffMailBody, setStaffMailBody] = useState('');
    const [selectedValue, setSelectedValue] = useState('');      


    const {event_id} = useParams()
    const dispatch = useDispatch()
    const notificationTemplateStore= useSelector(state => state?.notificationTemplateStore)
    const { items = [], } = notificationTemplateStore

    useEffect(()=>{
            /************* customer *****************/
            setCustomerChecked(data?.is_customer || 0)
            setCustomerMailBody(data?.customer_body || '')

            /************* provider *****************/
            setProviderChecked(data?.is_provider || 0)
            setProviderMailBody(data?.provider_body || '')

            /************* admin *****************/
            setAdminChecked(data?.is_admin || 0)
            setAdminMailBody(data?.admin_body || '')
        /************* admin *****************/
            setStaffChecked(data?.is_staff || 0)
            setStaffMailBody(data?.staff_body || '')


    },[data])

    const handleBookingTypeChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedValue(selectedValue);
      
      // Add the selectedValue to the customerMailBody at the current cursor position
      const textarea = document.querySelector('.MuiInputBase-input.MuiOutlinedInput-input');
      const startPos = textarea.selectionStart;
      // const endPos = textarea.selectionEnd;
    
      const currentText = customerMailBody || ''; 
      const newText = currentText.slice(0, startPos) + selectedValue ;
      
      setCustomerMailBody(newText);
      
      // Update selectedItems as needed
      setSelectedItems([...selectedItems, selectedValue]);
    };
    
       

    const handleSaveTemplate =async () => {
        let finalPayload = {
            type:'Sms',
            notification_event_id: event_id,
            is_customer: customerChecked,   
            customer_body: customerMailBody,

            is_admin: adminChecked,
             admin_body: adminMailBody,

            is_provider: providerChecked,
            provider_body: providerMailBody,

            is_staff: staffChecked,
            staff_body: staffMailBody,
        }

        setLoading(true)
        let {payload}= await dispatch(updateNotificationTemplate(finalPayload))
        setLoading(false)
        if (payload?.success === 'true'){
            await successConfirmation('Success',`${eventName} ${activeType} Events Updated`)
        }else {
            console.log(payload,"payload")      

        }

    }
         
    const handleChange = (event,type) => {
        if (type === 'customer'){
            setCustomerChecked(event.target.checked ? 1 :0);
        }else if (type ==='admin'){
            setAdminChecked(event.target.checked ? 1 :0);
        }else if (type === 'provider') {
            setProviderChecked(event.target.checked ? 1 :0);
        }else if (type === 'staff') {
            setStaffChecked(event.target.checked ? 1 :0);
        }
    };        

    useEffect(() => {
        
        dispatch(getAllPlaceData());
      }, []);
    return (  
        <Box>
            <Box>
                <FormControlLabel
                    value="end"
                    control={
                        <Switch
                            color="primary"
                            checked={customerChecked===1}
                            onChange={(e)=>handleChange(e,'customer')}
                        />
                    }
                    label={`Customer ${activeType}`}
                    labelPlacement="end"  
                />
                {
                    customerChecked === 1  && <Box
                        sx={{
                            mt: '16px',
                            borderTop: "1px solid #E0E0E0",
                            pt:"16px",
                        }}   
                    >

<FormControl
  sx={{  
    minWidth: { xs: "100%", sm: "220px" },
    mt: { xs: 2, sm: 1 },
    mb:{xs: 2,lg:3}
  }}
> 
  <Select
    value={selectedValue}
    onChange={(event) => {
        setSelectedValue(event.target.value); 
        handleBookingTypeChange(event); 
      }}
    displayEmpty
    sx={selectStyle}
  >
    <MenuItem value="">Add placeholder</MenuItem>
    {items?.length ? (
      items.map((type, index) => (
        <MenuItem key={index} value={type.value}>   
          {type.key}
        </MenuItem>
      ))
    ) : (   
      <MenuItem value="" disabled>
        No Booking Types available
      </MenuItem>
    )}
  </Select>
</FormControl> 


<textarea  
    className="MuiInputBase-input MuiOutlinedInput-input"
    rows={3}  
    placeholder="Enter here!"
    value={customerMailBody}
    onChange={(e) => setCustomerMailBody(e.target.value)}  
    style={{
        width: '100%',
        resize: 'vertical', 
        padding: '8px',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        fontFamily: 'inherit', 
        fontSize: '1rem', 
    }}
/>
                    </Box>  
                }
            </Box>
            <Box>   
                <FormControlLabel
                    value="end"
                    control={    
                        <Switch
                            color="primary"
                            checked={adminChecked===1}
                            onChange={(e)=>handleChange(e,"admin")}
                        />
                    }
                    label={`Admin ${activeType}`}
                    labelPlacement="end"
                />
                {
                    adminChecked === 1 && <Box
                        sx={{
                            mt: '16px',
                            borderTop: "1px solid #E0E0E0",
                            pt:"16px",
                        }}
                    >
                             <textarea
                                 className="MuiInputBase-input MuiOutlinedInput-input"
                                 rows={3}
                                 placeholder="Enter here!"
                                 value={adminMailBody}
                                 onChange={(e)=>setAdminMailBody(e.target.value)}
                                 style={{
                                     width: '100%',
                                     resize: 'vertical', // Allow vertical resizing
                                     padding: '8px',
                                     border: '1px solid rgba(0, 0, 0, 0.23)',
                                     borderRadius: '4px',
                                     fontFamily: 'inherit', // Use the same font as Material-UI inputs
                                     fontSize: '1rem', // Use the same font size as Material-UI inputs
                                 }}
                             />


                    </Box>
                }
            </Box>
            <Box>
                <FormControlLabel
                    value="end"
                    control={
                        <Switch
                            color="primary"
                            checked={providerChecked===1}
                            onChange={(e)=>handleChange(e,"provider")}
                        />
                    }
                    label={`Provider ${activeType}`}
                    labelPlacement="end"
                />
                {
                    providerChecked === 1  && <Box
                        sx={{
                            mt: '16px',
                            borderTop: "1px solid #E0E0E0",
                            pt:"16px",
                        }}
                    >
                          <textarea
                              className="MuiInputBase-input MuiOutlinedInput-input"
                              rows={3}
                              placeholder="Enter here!"
                              value={providerMailBody}
                              onChange={(e)=>setProviderMailBody(e.target.value)}
                              style={{
                                  width: '100%',
                                  resize: 'vertical', // Allow vertical resizing
                                  padding: '8px',
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                  borderRadius: '4px',
                                  fontFamily: 'inherit', // Use the same font as Material-UI inputs
                                  fontSize: '1rem', // Use the same font size as Material-UI inputs
                              }}
                          />


                    </Box>
                }
            </Box>
            <Box>
                <FormControlLabel
                    value="end"
                    control={
                        <Switch
                            color="primary"
                            checked={staffChecked===1}
                            onChange={(e)=>handleChange(e,"staff")}
                        />
                    }
                    label={`Staff ${activeType}`}
                    labelPlacement="end"
                />
                {
                    staffChecked === 1  && <Box
                        sx={{
                            mt: '16px',
                            borderTop: "1px solid #E0E0E0",
                            pt:"16px",
                        }}
                    >
                          <textarea
                              className="MuiInputBase-input MuiOutlinedInput-input"
                              rows={3}
                              placeholder="Enter here!"
                              value={staffMailBody}
                              onChange={(e)=>setProviderMailBody(e.target.value)}
                              style={{
                                  width: '100%',
                                  resize: 'vertical', // Allow vertical resizing
                                  padding: '8px',
                                  border: '1px solid rgba(0, 0, 0, 0.23)',
                                  borderRadius: '4px',
                                  fontFamily: 'inherit', // Use the same font as Material-UI inputs
                                  fontSize: '1rem', // Use the same font size as Material-UI inputs
                              }}
                          />


                    </Box>
                }
            </Box>
            <Box
                sx={{
                    display:"flex",
                    justifyContent:'flex-end',
                    mt:"16px",
                }}
            >
                <CustomButton handleClick={handleSaveTemplate} val={'Update Templates'}/>
            </Box>
        </Box>
    );
};
export default SmsNotification;