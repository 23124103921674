import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Divider, Grid, Link as MuiLink, Typography} from "@mui/material";
import Loader from "../../../components/loader/loader";
import {
    content,
     heading,
    ribbonwrapper,
    textul
} from "../../../components/card-appointment/CardAppointment";
import ribbon from "../../../assets/images/ribbon.svg";
import redRibbon from "../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../assets/images/yellow-badg.svg";
import AppointmentDetails from "../../crm/appointment/appointment-details/appointmentDetails";
import {getBookingDetails} from "../../../store/booking-widget";

const BookingDetails = ({appointment_id}) => {
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const bookingWidgetStore = useSelector((state) => state?.bookingWidgetStore)
    const {bookingDetails = []} = bookingWidgetStore


    useEffect(() => {
        (async () => {
            setLoading(true)
            await dispatch(getBookingDetails(appointment_id))
            setLoading(false)
        })()
    }, [])

    return (
        <Box sx={{minHeight: '300px'}}>
            {
                loading ? <Loader/> :
                    <Box sx={{background: '#F1F5F9',pt:'35px'}}>
                        <Box sx={{
                            background: '#F1F5F9',
                            px:{ xs:'10px',sm:'35px'},
                            mt: bookingDetails?.status === 'Completed' ? '35px' : ""
                        }}>
                            <Grid container sx={{background: '#fff',display:'flex',flexDirection:{xs:'column',md:'row'}, p:{ xs:'10px',sm:'35px'}, borderRadius: '7px'}}>
                                <Grid item xs={5}>
                                    <Box>
                                        <Typography sx={{ width:'max-content' }}>Customer Name:</Typography>
                                        <Box>
                                            <div>
                                                <Typography sx={{
                                                    ...textul,
                                                    textTransform: 'capitalize'
                                                }}>{bookingDetails?.customer?.name}</Typography>
                                            </div>
                                            {bookingDetails?.customer?.is_membered === 1 && <Box sx={{ width:{xs:'200px',sm:'max-content'} }} style={ribbonwrapper}>
                                                <img style={{marginTop: '4px'}} src={ribbon} alt={'image'}/>
                                                <Typography  style={{color: "#219653"}}>
                                                    {bookingDetails?.provider?.name} Member
                                                </Typography>
                                            </Box>}
                                            <div>
                                                <MuiLink href="#" color="inherit">
                                                    Member ID {bookingDetails?.customer?.id}
                                                </MuiLink>
                                            </div>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item sx={{px: '24px'}}>
                                    <Divider
                                        orientation="vertical"
                                        style={{borderColor: "#9A9797", height: "100%", width: "2px"}}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    xs={12}
                                    sx={{
                                        display:'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        mt:{xs:2,md:0}
                                    }}
                                >
                                    <Box sx={{display: "flex",flexWrap:{xs:'wrap',sm:'nowrap'},}}>
                                        <Typography sx={{minWidth: '70px'}}>Wallet:</Typography>
                                        <Typography sx={content}>
                                            ${bookingDetails?.customer?.wallet_point}
                                        </Typography>
                                    </Box>
                                    <Box sx={{display: "flex",flexWrap:{xs:'wrap',sm:'nowrap'},}}>
                                        <Typography sx={{minWidth: '70px'}}>Phone:</Typography>
                                        <Typography sx={content}>{bookingDetails?.customer?.phone}</Typography>
                                    </Box>
                                    <Box sx={{display: "flex",flexWrap:{xs:'wrap',sm:'nowrap'},}}>
                                        <Typography sx={{...heading, minWidth: '70px'}}>Email:</Typography>
                                        <Typography sx={content}>{bookingDetails?.customer?.email}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <AppointmentDetails appointmentDetails={bookingDetails}/>
                    </Box>
            }
        </Box>
    );
};

export default BookingDetails;