import React from 'react';
import {Pagination, PaginationItem} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";

const BackendPagination = ({count,handleOnChange,currentPage,executeScroll}) => {

    const handleChange = (event, newPage) => {
        handleOnChange(newPage);
        // Check if executeScroll is provided and is a function before calling it
        if (executeScroll && typeof executeScroll === 'function') {
            executeScroll();
        }
    };


    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Pagination
                count={count}
                page={currentPage}
                onChange={handleChange}
                variant="outlined"
                shape="rounded"
                renderItem={(item) => {
                    return (
                        <PaginationItem
                            sx={{background: '#fff'}}
                            slots={{previous: ArrowBackIcon, next: ArrowForwardIcon}}
                            {...item}
                        />
                    )
                }}
            />
        </Box>
    );
};

export default BackendPagination;