import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    

    invoicesBottom: {
        display:'flex', justifyContent:'end', flexDirection:'column', alignItems:'end',
      },
      lastChild: {
        display:'flex',
        justifyContent:'end',
        width:'100%',
        borderBottom: "1px solid #C4C4C4",
        paddingBottom:'20px'
      },
      invoiceHeading:{
        display:'flex', alignItems:'center', mt:1, justifyContent:'end'
      }
}));
export default useStyles;