import React, { useState } from "react";
import "./styles.css";
import Box from "@mui/material/Box";
import { timeSlotsWithGap } from "../../services/utills";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import {useParams} from "react-router-dom";

export const modalScroll ={
  "&::-webkit-scrollbar": {
    width: "10px",
    height: "10px"
  },
  "&::-webkit-scrollbar-track": {
    background: "#4D5862 !important"
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "4px",
    background: "#778899 !important",
    boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
  }
}
const CrmDayView = ({ setOpenAppointmentDetail, dayViewData, startDate,minStartTime,maxEndTime }) => {
  const authStore = useSelector((state) => state?.authStore);
  console.log("Start and end time")
  console.log(minStartTime+'  '+maxEndTime)
  const { user } = authStore;
  const [expanded, setExpanded] = useState(false);
  const [showFullText, setShowFullText] = useState(Array(dayViewData?.item?.service_name.length).fill(false));

  const handleClick = (index) => {
    const newShowFullText = [...showFullText];
    newShowFullText[index] = !newShowFullText[index];
    setShowFullText(newShowFullText);
  };

  const getDisplayText = (text, index) => {
    if (showFullText[index]) {
      return text;
    }
    if (text.length <= 15) {
      return text;
    }
    return `${text.slice(0, 15)}...`;
  };
  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };
  const MAX_LENGTH = 30;

  const params = useParams();


  
  const generateTimeSlots = () => {
    const timeSlots = [];
    const businessStartDate = startDate.getDay();
      if (user && user?.business_hours && user?.business_hours[businessStartDate]) {
        let startTimeParts, endTimeParts;
        if (dayViewData?.length > 0) {
          // Use the first object's start_time and the last object's end_time from dayViewData
          const firstDayData = minStartTime.match(/(\d+):(\d+) (AM|PM)/);
          const lastDayData = maxEndTime.match(/(\d+):(\d+) (AM|PM)/);

          startTimeParts = firstDayData;
          endTimeParts = lastDayData;
        }
        else if(user?.business_hours[businessStartDate]?.is_off === 0)
        {
          // Use business hours if dayViewData is not available or empty
          startTimeParts = user.business_hours[businessStartDate].start_time.match(/(\d+):(\d+) (AM|PM)/);
          endTimeParts = user.business_hours[businessStartDate].end_time.match(/(\d+):(\d+) (AM|PM)/);
        }

        if (!startTimeParts || !endTimeParts) {
          // Invalid time format
          return timeSlots;
        }

        let [_, startHour, startMinute, startPeriod] = startTimeParts;
        let [_e, endHour, endMinute, endPeriod] = endTimeParts;

        startHour = parseInt(startHour, 10);
        startMinute = parseInt(startMinute, 10);
        endHour = parseInt(endHour, 10);
        endMinute = parseInt(endMinute, 10);

        if (startPeriod === "PM" && startHour !== 12) {
          startHour += 12;
        }
        if (endPeriod === "PM" && endHour !== 12) {
          endHour += 12;
        }

        let currentTime = new Date(2000, 0, 1, startHour, startMinute);
        const interval = 15 * 60 * 1000; // 15 minutes

        while (currentTime <= new Date(2000, 0, 1, endHour, endMinute)) {
          const formattedTime = currentTime.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });

          timeSlots.push(formattedTime);
          currentTime = new Date(currentTime.getTime() + interval);
        }
      }

    return timeSlots;
  };

  const timeSlots = generateTimeSlots();

  let previousOverlap = 0;
  return (
    <Box
      className="timeline-container"
      sx={{
        position: "relative",
        width: "100%",
     
      }}
    >
      <Box className="time-column" sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      
      }}>
        {timeSlots.length > 0 ? (
            timeSlots.map((hour, hourIndex) => (
                <Box
                    key={hourIndex}
                    sx={{
                      background: "#F1F5F9",
                      mb: "5px",
                      borderRadius: "4px",
                      height: "95px",
                    }}
                >
                  <Box
                      sx={{
                        width: "max-content",
                        transform: "rotate(-90deg)",
                        fontWeight: "600",
                        transformOrigin: "49px 35px",
                      }}
                  >
                    {hour}
                  </Box>
                </Box>
            ))
        ) : (
          <>
            <Box sx={{
              textAlign: "center",
              mt: 5,
              py:6,
              fontSize: 'larger',  // You can specify a numeric value or relative size like 'larger'
              fontWeight: 'bold'
            }}>
              Business Hours Not Set

              
            </Box>
            <Link to={`/crm/configuration/clinic/${user?.id}/business-hours`} style={{ textDecoration: 'none' ,zIndex:'9999999'}}>
            <Button variant="contained" sx={{display:'flex', justifyContent:'center', position:'relative', top:'-25px', left:'43%'}}>
              Select Business Hours
            </Button>
          </Link>
          </>
        )}
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: "0",
          pt: "0px",
          pl: "60px",
          display: "flex",
          gap: "20px",
          height: "100%",
          width: "99%",
          overflowX: "scroll",
          ...modalScroll
          
        }}
      >
          {dayViewData?.length > 0 && dayViewData.map((item, index) => {
              // Ensure the necessary data is available
              if (!item || !item.start_time || !item.end_time) {
                  return null; // Skip rendering if essential data is missing
              }
  
              // Calculate the height of the appointment card based on slots
              const startIndex = timeSlots.indexOf(item.start_time);
              const endIndex = timeSlots.indexOf(item.end_time);
              const final = endIndex - startIndex;
              const finalEndIndex = final <= 5 ? final + 1 : final;
              const totalCount = item.slots?.length > 1 ? item.slots.length - 1 : 1.7;
              // Initialize an overlap counter for each item
              // Assuming dayViewData is sorted by start times
              // Function to calculate overlaps for each record
              function calculateOverlaps(data) {
                  let overlapCounts = new Array(data.length).fill(0);

                  for (let index = 0; index < data.length; index++) {
                      const currentItem = data[index];
                      const currentStartIndex = timeSlots.indexOf(currentItem.start_time);
                      const currentEndIndex = timeSlots.indexOf(currentItem.end_time) + 1;
  
                      // Go through each previous item and check for overlap   
                      for (let otherIndex = 0; otherIndex < index; otherIndex++) {
                          const otherItem = data[otherIndex];  
                          const otherStartIndex = timeSlots.indexOf(otherItem.start_time);
                          const otherEndIndex = timeSlots.indexOf(otherItem.end_time) + 1;

                          // Check if the current item overlaps with the other item
                          if (
                              (currentStartIndex >= otherStartIndex && currentStartIndex < otherEndIndex) ||
                              (currentEndIndex > otherStartIndex && currentEndIndex <= otherEndIndex)
                          ) {
                              // If there is an overlap, increase the overlap count for the current item
                              overlapCounts[index]++;
                          } 
                      }
                  }

                  return overlapCounts;
              }
              function calculateMobileOverlaps(data) {
                  let overlapCounts = new Array(data.length).fill(0);

                  for (let index = 0; index < data.length; index++) {
                      const currentItem = data[index];
                      const currentStartIndex = timeSlots.indexOf(currentItem.start_time);

                      // Go through each previous item and check for overlap based only on start time
                      for (let otherIndex = 0; otherIndex < index; otherIndex++) {
                          const otherItem = data[otherIndex];   
                          const otherStartIndex = timeSlots.indexOf(otherItem.start_time);

                          // Check if the current item's start time is the same as the other item's start time
                          if (currentStartIndex === otherStartIndex) {
                              // If there is an overlap, increase the overlap count for the current item
                              overlapCounts[index]++;
                          }
                      }
                  }

                  return overlapCounts;
              }
              const overlapCounts = calculateOverlaps(dayViewData);
              const mobileoverlapCounts = calculateMobileOverlaps(dayViewData);
// Now overlapInfo contains the marginLeft for each corresponding item in dayViewData
              if(overlapCounts[index] > 0){
                  overlapCounts[index] = 1 + previousOverlap;
              }
// Calculate the positioning of the appointment card based on overlap count
              const marginLeft = `${overlapCounts[index] * 230}px`;
              const marginMobileLeft = `${mobileoverlapCounts[index] * 230}px`;
              previousOverlap = overlapCounts[index];
              const top = `${startIndex > 0 ? 100 * (startIndex) : 0}px`;
              const marginTop = `${index * 0}px`;

              return (
                  <Box
                      className="scrollbar"
                      key={index}
                      onClick={() => setOpenAppointmentDetail(item.id)}
                      sx={{
                          cursor: "pointer",
                          background: item.opacity_color,
                          border: "2px solid whitesmoke",
                          p: "20px",
                          borderRadius: "8px",
                          width: "220px",
                          height: { sm: `${100 * (totalCount)}px`, sx: '81px' },
                          zIndex: index + 1,
                          marginLeft: { sm: `${item?.president_level * 230}px`, xs: marginMobileLeft },
                          position: "absolute",
                          marginTop: marginTop,
                          top: top,
                          overflowY: "scroll",
                          // ...modalScroll (if this is defined elsewhere)
                      }}
                  >
                      <Box
                          sx={{
                              display: "flex",
                              alignItems: "flex-start",
                          }}
                      >
                          <Box
                              sx={{
                                  background: item.status_color,
                                  p: "7px",
                                  borderRadius: "2px",
                                  mr: "7px",
                              }}
                          ></Box>
                          <Typography
                              sx={{
                                  fontFamily: "helvetica-lt-std-bold",
                                  fontSize: "14px",
                                  mt: "-4px",
                                  textTransform: "capitalize",
                              }}
                          >
                              {item.customer?.name || "No Name"} {/* Provide a default if customer name is missing */}
                          </Typography>
                      </Box>
                      <Box>
                          <Typography sx={{ fontSize: "14px" }}>
                              {item.start_time} - {item.end_time}
                          </Typography>
                      </Box>

                      <Box
                    //  sx={{display: { sm: "block", xs: "none" },}}
                      >
   {item?.service_name?.length > 0 &&
      item.service_name.map((service, serviceIndex) => {
          const serviceText = `${service}${
            item.type === "Booking"
              ? ''
              : item.type === "Consultation"
              ? " (In Person Consultation)"
              : " (Virtual)"
          }`;

          return (
            <Typography
              key={serviceIndex}
              sx={{ textTransform: "capitalize", cursor: "pointer" }}
              onClick={() => handleClick(serviceIndex)}
            >
              {getDisplayText(serviceText, serviceIndex)}
            </Typography>
          );
        })}
                      </Box>
                      {item?.appointment_customer_note && typeof item.appointment_customer_note === 'object' && item.appointment_customer_note !== null && (
    <Box>
        <Typography sx={{    fontWeight: '800',  }}>Appointment Notes:</Typography>
        <Typography
        // sx={content}
        onClick={item?.appointment_customer_note?.description.length > MAX_LENGTH ? handleToggleExpand : null}
        style={{
          cursor: item?.appointment_customer_note?.description.length > MAX_LENGTH ? 'pointer' : 'default',
          overflow: 'hidden',
          textOverflow: 'ellipsis',    
          whiteSpace: expanded ? 'normal' : 'nowrap',
          display: 'inline-block',
          maxWidth: '100%',
        }}
      >
        {expanded
          ? item?.appointment_customer_note?.description
          : item?.appointment_customer_note?.description.slice(0, MAX_LENGTH) + (item?.appointment_customer_note?.description.length > MAX_LENGTH ? '...' : '')}
      </Typography>   
    </Box> 
)}

                  </Box>
              );
          })}

      </Box>
    </Box>
  );
};

export default CrmDayView;
