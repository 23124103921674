import { Autocomplete, Box, Button, CardMedia, Container, Grid, TextField, Typography, InputAdornment, Tab, Tabs, Slide, } from '@mui/material';
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';
import { ServicesPlan, getAllClinic, getsubscriptionPlan, SubCategory, AllServices } from '../../store/home';
import useStyles from './style';
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import homeoutline from '../../assets/images/homeoutline.png'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import booking from '../../assets/images/Vector (Stroke).png'
import menu from '../../assets/images/homeIcon.png'
import plan from '../../assets/images/wallet2.png'
import profile from '../../assets/images/user.png'
import { TabContext, TabPanel } from '@mui/lab';
import Login from '../login';
import Waveloading from '../loading/Waveloading';
import defaultservice from "../../assets/images/defaultservice.jpg"
import Footer from './Footer';

const SubCategories = () => {
    const classes = useStyles();
    const { Slug } = useParams();
    const navigate = useNavigate();


    const location = useLocation();
    const selectedLocation = location?.state?.selectedClinic;
    const selectedlocation = location?.state?.selectedlocation;
    const Clinicname = selectedLocation?.name
    const Clinicid = selectedLocation?.id
    const [loginOpen, setOpenLogin] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedClinic, setSelectedClinic] = useState(selectedLocation || selectedlocation || null);
    const dispatch = useDispatch();
    const [activeButton, setActiveButton] = useState(localStorage.getItem('activeButton') || 'home');
    const id = localStorage.getItem('selectedLocationId')
    const backId = localStorage.getItem('selectedLocationId')
    const localStorageKey = 'selectedLocation';
    const homeStore = useSelector(state => state?.homeStore)
    const { clinic, Services, SubscriptionPlan,
        subcategory, ServiceData } = homeStore
    const [value, setValue] = useState(0);

    const handletabs = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange = (event) => {

        setSearchTerm(event.target.value.toLowerCase());
    };
    // all clinic api=
    useEffect(() => {
        (async () => {
            await dispatch(getAllClinic())
        })()

    }, [])

    // level 3 api
    useEffect(() => {

        try {
            let payload = {
                provider_id: Clinicid || id,
                Slug: Slug,

            };
            setLoading(true);

            const response = dispatch(SubCategory(payload));
            setLoading(false);
            setDataFetched(true);

        } catch (error) {
            // Handle API error
        }
    }, [Slug, Clinicid]);


    //  options
    const clinicOptions = clinic.data && Array.isArray(clinic.data)
        ? clinic.data.map((clinic) => ({
            name: clinic?.name,
            id: clinic?.id,
        }))
        : [];
    useEffect(() => {
        const savedLocation = localStorage.getItem(localStorageKey);
        if (savedLocation) {
            setSelectedClinic(JSON.parse(savedLocation));
        }
    }, []);
    // clinic handel
    const handleClinicSelect = (event, newValue) => {
        localStorage.setItem('selectedLocationId', newValue?.id);
        setSelectedClinic(newValue);
        backhandler(newValue)
        localStorage.setItem(localStorageKey, JSON.stringify(newValue));
    };
    const handleHome = () => {
        navigate('/home');
    };
    const backhandler = (selectedClinic) => {
        navigate(`/home`, { state: { selectedLocation: selectedClinic } });
    };
    const backarrow = () => {
        navigate(`/home`, { state: { selectedLocation: selectedLocation } });
    };
    const authStore = useSelector((state) => state?.authStore);

    const { user } = authStore;
    const subcategorySlug = subcategory?.slug;
    const parentSlugs = subcategory?.parent?.slug;
    const childSlugs = subcategory?.child?.map((child) => child.slug);
    const selectedChildSlug = childSlugs?.[value]

    const fetchData = async () => {
        setLoading(true);

        let payload = {
            provider_id: Clinicid || id,
        };

        if (selectedChildSlug && selectedChildSlug.length > 0) {
            payload.treatment = selectedChildSlug;
            payload.group = subcategory?.slug;
        } else {
            payload.treatment = subcategory?.slug;


            if (subcategory?.parent) {
                payload.group = subcategory?.parent?.slug;
            } else {
                payload.group = subcategory?.slug;
            }
        }

        const response = await dispatch(AllServices(payload));

        setLoading(false);
        setDataFetched(true);
    }

    useEffect(() => {
        fetchData();
    }, [subcategory, value]);


    const handleCategoryClick = (url) => {
        // Navigate to the URL
        navigate(url, { state: { selectedLocation: selectedClinic, subcategorySlug, scrollToDiv: 'targetDivId' } },);
    };
    const shouldRedirectToLogin = !user?.id || user?.role_id !== 3;
    const handleBoxClick = () => {
        setActiveButton('booking');
        if (shouldRedirectToLogin) {
            setOpenLogin(true)
        } else {

            navigate('/user/bookings');
        }
        setActiveButton('booking');
        localStorage.setItem('activeButton', 'booking');
    };
    const handleplan = () => {
        setActiveButton('member');
        if (shouldRedirectToLogin) {
            setOpenLogin(true)
        } else {

            navigate('/user/membership');
        }
        setActiveButton('member');
        localStorage.setItem('activeButton', 'member');
    };
    const handleprofile = () => {

        if (shouldRedirectToLogin) {
            setOpenLogin(true)

        } else {
            // Navigate to the booking page
            navigate('/user/my-account');

        }
        setActiveButton('profile');
        localStorage.setItem('activeButton', 'profile');
    };

    let capitalizedUserName = "";
    if (user?.name) {
        // Ensure user.name is not null or undefined
        capitalizedUserName = user.name.charAt(0).toUpperCase() + user.name.slice(1);
    }
    const handleClick = (link) => {
        setLoading(true);

        window.location.href = link
    };

    return (
        <>
            {loginOpen ? (
                <Box
                    sx={{
                        position: "fixed",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        top: 0,
                        zIndex: "1111111111111!important",
                    }}
                >
                    <Slide top>
                        <Login loginOpen={loginOpen} setOpenLogin={setOpenLogin}  showFooter={true} />
                    </Slide>
                </Box>
            ) : (
                ''
            )}
            <Box sx={{ overflow: 'hidden', position: 'relative', display: { sm: "block", md: "none" } }}>
                <Container maxWidth="lg" sx={{ overflow: 'hidden', position: 'relative' }}>
                    <Box sx={{ display: { sm: "block", md: "none" }, mt: 4 }}>
                        <Autocomplete
                         clearIcon={null} 
                            className={classes.selectParent}
                            disablePortal
                            id="combo-box-demo"
                            options={clinicOptions}
                            value={selectedClinic}
                            onChange={handleClinicSelect}
                            renderInput={(params) => (
                                <TextField {...params} sx={{ label: { mt: '-10px',zIndex:0 } }} label="Select store" />
                            )}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) => option.id === (value.id || id)}
                        />
                        {loading ? <Waveloading fullSize /> : ''}
                        <>
                            {!user?.id || user?.role_id !== 3 ? (

                                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                                    <Box >
                                        <KeyboardBackspaceIcon onClick={backarrow} />
                                    </Box>
                                    <PersonIcon onClick={() => setOpenLogin(true)} />

                                </Box>


                            ) : (
                                ''
                            )}

                            {user?.id && user?.role_id === 3 ? (

                                <Box sx={{ display: "flex", justifyContent: "space-between", my: 4, }}>
                                    <Box onClick={backarrow} >
                                        <ArrowBackIcon />
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                                        <Box sx={{ display: "flex", justifyContent: "end", }}>
                                            <AccountBalanceWalletIcon />
                                            <sup style={{ fontWeight: '600', left: '-19px', top: '-8px' }}>{user?.wallet_point || 0}</sup>
                                        </Box>
                                        <Box onClick={handleprofile} sx={{ backgroundColor: 'rgba(235, 213, 194, 1)', width: '30px', height: '30px', borderRadius: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography sx={{ color: '#000', fontSize: '16px', fontWeight: '500' }}>{capitalizedUserName.charAt(0)}</Typography>
                                        </Box>


                                    </Box>
                                </Box>
                            ) : (
                                ''
                            )}


                            <TextField
                                id="search"
                                type="search"
                                label="Search"
                                value={searchTerm}
                                onChange={handleChange}
                                className={classes.search}
                                sx={{ width: '100%', mt: 3, borderRadius: '20px', display: 'flex', alignItems: 'cernter', label: { mt: '-10px' }, zIndex:0 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Grid container spacing={2} sx={{ mt: 2 }}>

                                <Grid item xs={12} sm={6} key={plan.id}>
                                    <Box sx={{ position: 'relative' }}>
                                        <CardMedia
                                            sx={{ borderRadius: '6px' }}
                                            component="img"
                                            alt="Descriptive alt text"
                                            height="110"
                                            image={subcategory?.file_path || defaultservice} // Use planImage from the plan object
                                        />
                                        <Typography
                                            variant="body2"
                                            sx={{ position: 'absolute', bottom: '15px', color: '#fff', left: '10px' }}
                                        >
                                            {subcategory?.title}
                                        </Typography>
                                    </Box>
                                </Grid>

                            </Grid>
{/*                             
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="h6">Select Services</Typography>


                            </Box> */}
                            {subcategory?.child && subcategory.child.length === 0 ? (
                                <>



                                    {ServiceData?.promotion_services && ServiceData?.promotion_services.length > 0 ? (
                                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                            <Typography variant="h6" style={{
                                                marginLeft: "18px",
                                                fontSize: '21px',
                                                fontWeight: '700',
                                                width: "100%",
                                                borderRadius: "8px",
                                                color: "#fff",
                                                padding: "5px 15px",
                                                backgroundColor: "#000"
                                            }}>Promotion</Typography>
                                            {ServiceData?.promotion_services
                                                .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                .map((service) => (
                                                    <>

                                                        <Grid item xs={12} sm={6} key={service.id}>


                                                            <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                {
                                                                    service?.is_price_show != 0 ? (
                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                            {
                                                                                service?.is_upfront_enable === 0 ? (
                                                                                    <>
                                                                                        <Box sx={{ display: 'flex' }}>
                                                                                            <Box>
                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, textDecoration: 'line-through', textDecorationColor: 'black' }}>
                                                                                                    ${service?.regular_price}
                                                                                                </Typography>
                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                    Regular Price
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            <Box>
                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                    ${service?.member_price}
                                                                                                </Typography>
                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                    Promo Price
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </>
                                                                                ) : (
                                                                                    <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4 }}>
                                                                                        $ {service?.upfront_amount} deposit required
                                                                                    </Typography>
                                                                                )
                                                                            }
                                                                            <Box>
                                                                                <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    ):null
                                                                }
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                    {service?.is_price_show != 0 ? (
                                                                        <Box>
                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                    onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                Book Now <TrendingFlatIcon />
                                                                            </Button>
                                                                        </Box>
                                                                    ) : (
                                                                        <>
                                                                            <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                        onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                    Book Consultation <TrendingFlatIcon />
                                                                                </Button>
                                                                                <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                        onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                    Book Virtual Consultation <TrendingFlatIcon />
                                                                                </Button>
                                                                            </Box>
                                                                        </>
                                                                    )}

                                                                    {loading ? <Waveloading fullSize /> : ''}
                                                                    <Box>
                                                                        <Typography sx={{
                                                                            fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                            textDecoration: 'underline', cursor: "pointer"
                                                                        }}
                                                                            //  onClick={() => window.location.href = service?.link}
                                                                                    onClick={() => handleClick(service?.link)} >More Info</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>


                                                        </Grid>
                                                    </>
                                                ))}
                                        </Grid>
                                    ) : null}


                                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mb: 20, mt: 3 }}>

                                        <Typography variant="h6"
                                                    style={{
                                                        marginLeft: "18px",
                                                        fontSize: '21px',
                                                        fontWeight: '700',
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        color: "#fff",
                                                        padding: "5px 15px",
                                                        backgroundColor: "#000"
                                                    }}>List Of Services</Typography>

                                        {ServiceData?.data && ServiceData?.data.length > 0 ? (
                                            ServiceData?.data
                                                .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                .map((service) => (
                                                    service?.is_promo === 0 && service?.is_consultation_category === 0 && service?.is_show_website === 1 && service?.is_extra_service === 0 && service?.is_free === 0 ? (
                                                        <>
                                                            <Grid item xs={12} sm={6} key={service.id}>

                                                                <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                    <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                    {
                                                                        service?.is_price_show != 0 ? (
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                    {
                                                                                        service?.is_upfront_enable === 0 ? (
                                                                                            <>
                                                                                                <Box sx={{ display: 'flex' }}>
                                                                                                    <Box>
                                                                                                        <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4 , textDecoration: 'line-through', textDecorationColor: 'black' }}>
                                                                                                            ${service?.regular_price}
                                                                                                        </Typography>
                                                                                                        <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                            Regular Price
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                    <Box>
                                                                                                        <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                            ${service?.member_price}
                                                                                                        </Typography>
                                                                                                        <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                            Member Price
                                                                                                        </Typography>
                                                                                                    </Box>
                                                                                                </Box>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                $ {service?.upfront_amount}  deposit required
                                                                                            </Typography>
                                                                                        )
                                                                                    }



                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        ):null
                                                                    }
                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                        {service?.is_price_show != 0 ? (
                                                                            <Box>
                                                                                <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                        onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                    Book Now <TrendingFlatIcon />
                                                                                </Button>
                                                                            </Box>
                                                                        ) : (
                                                                            <>
                                                                                <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                    <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                            onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                        Book Consultation <TrendingFlatIcon />
                                                                                    </Button>
                                                                                    <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                            onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                        Book Virtual Consultation <TrendingFlatIcon />
                                                                                    </Button>
                                                                                </Box>
                                                                            </>
                                                                        )}


                                                                        {loading ? <Waveloading fullSize /> : ''}
                                                                        <Box>
                                                                            <Typography sx={{
                                                                                fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                textDecoration: 'underline', cursor: "pointer",
                                                                            }}
                                                                                // onClick={() => window.location.href = service?.link}
                                                                                        onClick={() => handleClick(service?.link)}
                                                                            >More Info</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>


                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                        </>)


                                                ))) : null}
                                    </Grid>
                                </>
                            ) : (
                                <Box sx={{ marginTop: "20px", width: '100%', bgcolor: 'background.paper' }}>
                                    {subcategory?.child && subcategory?.child.length > 0 ? (

                                        <div style={{ overflowX: "auto", width: "100%" }}>


                                            <Tabs
                                                value={value}
                                                onChange={handletabs}
                                                orientation="horizontal"
                                                variant="scrollable"
                                                centered
                                                sx={{
                                                    '& .MuiTabs-scroller': {
                                                        overflowX: 'auto',
                                                    },
                                                }}
                                                TabIndicatorProps={{ style: { display: 'none' } }} >
                                                {subcategory?.child.map((service, index) => (
                                                    <Tab
                                                        key={index}
                                                        label={service?.title}
                                                        sx={{
                                                            marginRight: "20px",
                                                            // marginLeft:'20px',
                                                            textTransform: 'none',
                                                            fontWeight: 'bold',
                                                            border: '1px solid black',
                                                            borderRadius: '20px',
                                                            color: "black",
                                                            minHeight: '35px',
                                                            padding: '10px 16px',
                                                            '&.Mui-selected': {
                                                                backgroundColor: 'black',
                                                                color: 'white',
                                                                border: '1px solid black',

                                                            },
                                                        }} />


                                                ))} </Tabs> </div>) : ''}

                                    {value === 0 && (
                                        <>
                                            {ServiceData?.promotion_services && ServiceData?.promotion_services.length > 0 ? (
                                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                                    <Typography variant="h6" style={{
                                                        marginLeft: "18px",
                                                        fontSize: '21px',
                                                        fontWeight: '700',
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        color: "#fff",
                                                        padding: "5px 15px",
                                                        backgroundColor: "#000"
                                                    }}>Promotion</Typography>
                                                    {ServiceData?.promotion_services
                                                        .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                        .map((service) => (
                                                            <>

                                                                <Grid item xs={12} sm={6} key={service.id}>


                                                                    <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                        <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                        {
                                                                            service?.is_price_show != 0 ? (
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                        {
                                                                                            service?.is_upfront_enable === 0 ? (
                                                                                                <>
                                                                                                    <Box sx={{ display: 'flex' }}>
                                                                                                        <Box>
                                                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4 }}>
                                                                                                                ${service?.regular_price}
                                                                                                            </Typography>
                                                                                                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                Regular Price
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                        <Box>
                                                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                                ${service?.member_price}
                                                                                                            </Typography>
                                                                                                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                Promo Price
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </>
                                                                                            ) : (
                                                                                                <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                    $ {service?.upfront_amount}  deposit required
                                                                                                </Typography>
                                                                                            )
                                                                                        }


                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            ):null
                                                                        }
                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                            {service?.is_price_show != 0 ? (
                                                                                <Box>
                                                                                    <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                            onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                        Book Now <TrendingFlatIcon />
                                                                                    </Button>
                                                                                </Box>
                                                                            ) : (
                                                                                <>
                                                                                    <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                            Book Consultation <TrendingFlatIcon />
                                                                                        </Button>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                            Book Virtual Consultation <TrendingFlatIcon />
                                                                                        </Button>
                                                                                    </Box>
                                                                                </>
                                                                            )}

                                                                            {loading ? <Waveloading fullSize /> : ''}
                                                                            <Box>
                                                                                <Typography sx={{
                                                                                    fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                    textDecoration: 'underline', cursor: "pointer"
                                                                                }}
                                                                                    // onClick={() => window.location.href = service?.link}
                                                                                            onClick={() => handleClick(service?.link)} >More Info</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>


                                                                </Grid>
                                                            </>
                                                        ))}
                                                </Grid>
                                            ) : null}
                                            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, mb: 20 }}>
                                                <Typography variant="h6"
                                                            style={{
                                                                marginLeft: "18px",
                                                                fontSize: '21px',
                                                                fontWeight: '700',
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                color: "#fff",
                                                                padding: "5px 15px",
                                                                backgroundColor: "#000"
                                                            }}>List Of Services</Typography>
                                                {ServiceData?.data && ServiceData?.data.length > 0 ? (
                                                    ServiceData?.data
                                                        .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                        .map((service) => (
                                                            service?.is_promo === 0 && service?.is_consultation_category === 0 && service?.is_show_website === 1 && service?.is_extra_service === 0 && service?.is_free === 0 ? (
                                                                <>
                                                                    <Grid item xs={12} sm={6} key={service.id}>

                                                                        <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                            {
                                                                                service?.is_price_show != 0 ? (
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                            {
                                                                                                service?.is_upfront_enable === 0 ? (
                                                                                                    <>
                                                                                                        <Box sx={{ display: 'flex' }}>
                                                                                                            <Box>
                                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, textDecoration: 'line-through', textDecorationColor: 'black'  }}>
                                                                                                                    ${service?.regular_price}
                                                                                                                </Typography>
                                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                    Regular Price
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                                    ${service?.member_price}
                                                                                                                </Typography>
                                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                    Member Price
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                        $ {service?.upfront_amount} deposit required
                                                                                                    </Typography>
                                                                                                )
                                                                                            }



                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                ):null
                                                                            }
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                                {service?.is_price_show != 0 ? (
                                                                                    <Box>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                            Book Now <TrendingFlatIcon />
                                                                                        </Button>
                                                                                    </Box>
                                                                                ) : (
                                                                                    <>
                                                                                        <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                    onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                                Book Consultation <TrendingFlatIcon />
                                                                                            </Button>
                                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                    onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                                Book Virtual Consultation <TrendingFlatIcon />
                                                                                            </Button>
                                                                                        </Box>
                                                                                    </>
                                                                                )}

                                                                                {loading ? <Waveloading fullSize /> : ''}
                                                                                <Box>
                                                                                    <Typography sx={{
                                                                                        fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                        textDecoration: 'underline', cursor: "pointer"
                                                                                    }}
                                                                                        // onClick={() => window.location.href = service?.link}
                                                                                                onClick={() => handleClick(service?.link)}
                                                                                    >More Info</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>


                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <>

                                                                </>)
                                                        ))) : ''}
                                            </Grid>
                                        </>
                                    )}
                                    {value === 1 && (
                                        <>
                                            {ServiceData?.promotion_services && ServiceData?.promotion_services.length > 0 ? (
                                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                                    <Typography variant="h6" style={{
                                                        marginLeft: "18px",
                                                        fontSize: '21px',
                                                        fontWeight: '700',
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        color: "#fff",
                                                        padding: "5px 15px",
                                                        backgroundColor: "#000"
                                                    }}>Promotion</Typography>
                                                    {ServiceData?.promotion_services
                                                        .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                        .map((service) => (
                                                            <>

                                                                <Grid item xs={12} sm={6} key={service.id}>


                                                                    <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                        <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                        {
                                                                            service?.is_price_show != 0 ? (
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                        {
                                                                                            service?.is_upfront_enable === 0 ? (
                                                                                                <>
                                                                                                    <Box sx={{ display: 'flex' }}>
                                                                                                        <Box>
                                                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4 }}>
                                                                                                                ${service?.regular_price}
                                                                                                            </Typography>
                                                                                                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                Regular Price
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                        <Box>
                                                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                                ${service?.member_price}
                                                                                                            </Typography>
                                                                                                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                Promo Price
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </>
                                                                                            ) : (
                                                                                                <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                    $ {service?.upfront_amount} deposit required
                                                                                                </Typography>
                                                                                            )
                                                                                        }



                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            ):null
                                                                        }
                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                            {service?.is_price_show != 0 ? (
                                                                                <Box>
                                                                                    <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                            onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                        Book Now <TrendingFlatIcon />
                                                                                    </Button>
                                                                                </Box>
                                                                            ) : (
                                                                                <>
                                                                                    <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                            Book Consultation <TrendingFlatIcon />
                                                                                        </Button>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                            Book Virtual Consultation <TrendingFlatIcon />
                                                                                        </Button>
                                                                                    </Box>
                                                                                </>
                                                                            )}

                                                                            <Box>
                                                                                <Typography sx={{
                                                                                    fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                    textDecoration: 'underline', cursor: "pointer"
                                                                                }}
                                                                                    // onClick={() => window.location.href = service?.link}
                                                                                            onClick={() => handleClick(service?.link)} >More Info</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>


                                                                </Grid>
                                                            </>
                                                        ))}
                                                </Grid>
                                            ) : null}
                                            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, mb: 20 }}>
                                                <Typography variant="h6"
                                                            style={{
                                                                marginLeft: "18px",
                                                                fontSize: '21px',
                                                                fontWeight: '700',
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                color: "#fff",
                                                                padding: "5px 15px",
                                                                backgroundColor: "#000"
                                                            }}>List Of Services</Typography>
                                                {ServiceData?.data && ServiceData?.data.length > 0 ? (
                                                    ServiceData?.data
                                                        .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                        .map((service) => (
                                                            service?.is_promo === 0 && service?.is_consultation_category === 0 && service?.is_show_website === 1 && service?.is_extra_service === 0 && service?.is_free === 0 ? (
                                                                <>
                                                                    <Grid item xs={12} sm={6} key={service.id}>

                                                                        <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                            {
                                                                                service?.is_price_show != 0 ? (
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                            {
                                                                                                service?.is_upfront_enable === 0 ? (
                                                                                                    <>
                                                                                                        <Box sx={{ display: 'flex' }}>
                                                                                                            <Box>
                                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4 , textDecoration: 'line-through', textDecorationColor: 'black' }}>
                                                                                                                    ${service?.regular_price}
                                                                                                                </Typography>
                                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                    Regular Price
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                                    ${service?.member_price}
                                                                                                                </Typography>
                                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                    Member Price
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                        $ {service?.upfront_amount} deposit required
                                                                                                    </Typography>
                                                                                                )
                                                                                            }



                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                ):null
                                                                            }
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                                {service?.is_price_show != 0 ? (
                                                                                    <Box>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                            Book Now <TrendingFlatIcon />
                                                                                        </Button>
                                                                                    </Box>
                                                                                ) : (
                                                                                    <>
                                                                                        <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                    onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                                Book Consultation <TrendingFlatIcon />
                                                                                            </Button>
                                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                    onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                                Book Virtual Consultation <TrendingFlatIcon />
                                                                                            </Button>
                                                                                        </Box>
                                                                                    </>
                                                                                )}

                                                                                {loading ? <Waveloading fullSize /> : ''}
                                                                                <Box>
                                                                                    <Typography sx={{
                                                                                        fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                        textDecoration: 'underline', cursor: "pointer"
                                                                                    }}
                                                                                        // onClick={() => window.location.href = service?.link}
                                                                                                onClick={() => handleClick(service?.link)}
                                                                                    >More Info</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>


                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    ''
                                                                </>)
                                                        ))) : ''}
                                            </Grid>
                                        </>
                                    )}
                                    {value === 2 && (
                                        <>
                                            {ServiceData?.promotion_services && ServiceData?.promotion_services.length > 0 ? (
                                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                                                    <Typography variant="h6" style={{
                                                        marginLeft: "18px",
                                                        fontSize: '21px',
                                                        fontWeight: '700',
                                                        width: "100%",
                                                        borderRadius: "8px",
                                                        color: "#fff",
                                                        padding: "5px 15px",
                                                        backgroundColor: "#000"
                                                    }}>Promotion</Typography>
                                                    {ServiceData?.promotion_services
                                                        .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                        .map((service) => (
                                                            <>

                                                                <Grid item xs={12} sm={6} key={service.id}>


                                                                    <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                        <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                        {
                                                                            service?.is_price_show != 0 ? (
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                        {
                                                                                            service?.is_upfront_enable === 0 ? (
                                                                                                <>
                                                                                                    <Box sx={{ display: 'flex' }}>
                                                                                                        <Box>
                                                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4 }}>
                                                                                                                ${service?.regular_price}
                                                                                                            </Typography>
                                                                                                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                Regular Price
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                        <Box>
                                                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                                ${service?.member_price}
                                                                                                            </Typography>
                                                                                                            <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                Promo Price
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    </Box>
                                                                                                </>
                                                                                            ) : (
                                                                                                <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                    $ {service?.upfront_amount} deposit required
                                                                                                </Typography>
                                                                                            )
                                                                                        }



                                                                                    </Box>
                                                                                    <Box>
                                                                                        <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                            ):null
                                                                        }
                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                            {service?.is_price_show != 0 ? (
                                                                                <Box>
                                                                                    <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                            onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                        Book Now <TrendingFlatIcon />
                                                                                    </Button>
                                                                                </Box>
                                                                            ) : (
                                                                                <>
                                                                                    <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                            Book Consultation <TrendingFlatIcon />
                                                                                        </Button>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                            Book Virtual Consultation <TrendingFlatIcon />
                                                                                        </Button>
                                                                                    </Box>
                                                                                </>
                                                                            )}

                                                                            <Box>
                                                                                <Typography sx={{
                                                                                    fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                    textDecoration: 'underline', cursor: "pointer"
                                                                                }}
                                                                                    // onClick={() => window.location.href = service?.link}
                                                                                            onClick={() => handleClick(service?.link)}  >More Info</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>


                                                                </Grid>
                                                            </>
                                                        ))}
                                                </Grid>
                                            ) : null}
                                            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3, mb: 20 }}>
                                                <Typography variant="h6"
                                                            style={{
                                                                marginLeft: "18px",
                                                                fontSize: '21px',
                                                                fontWeight: '700',
                                                                width: "100%",
                                                                borderRadius: "8px",
                                                                color: "#fff",
                                                                padding: "5px 15px",
                                                                backgroundColor: "#000"
                                                            }}>List Of Services</Typography>
                                                {ServiceData?.data && ServiceData?.data.length > 0 ? (
                                                    ServiceData?.data
                                                        .filter(service => service.name.toLowerCase().includes(searchTerm))
                                                        .map((service) => (
                                                            service?.is_promo === 0 && service?.is_show_website === 0 && service?.is_consultation_category === 0 && service?.is_free === 0 ? (
                                                                <>
                                                                    <Grid item xs={12} sm={6} key={service.id}>

                                                                        <Box sx={{ borderRadius: '9px', p: '10px 15px', border: '1px solid #E0E0E0', background: '#efe6e0', display: 'flex', flexDirection: 'column' }}>
                                                                            <Typography variant="h4" sx={{ color: '#000', fontSize: '21px', fontWeight: '700', my: 1 }}>{service?.name}</Typography>
                                                                            {
                                                                                service?.is_price_show != 0 ? (
                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1 }}>
                                                                                            {
                                                                                                service?.is_upfront_enable === 0 ? (
                                                                                                    <>
                                                                                                        <Box sx={{ display: 'flex' }}>
                                                                                                            <Box>
                                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, textDecoration: 'line-through', textDecorationColor: 'black'  }}>
                                                                                                                    ${service?.regular_price}
                                                                                                                </Typography>
                                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                    Regular Price
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                            <Box>
                                                                                                                <Typography variant="h4" sx={{ color: '#000', fontSize: '18px', fontWeight: '700' }}>
                                                                                                                    ${service?.member_price}
                                                                                                                </Typography>
                                                                                                                <Typography variant="subtitle2" sx={{ fontSize: '10px' }}>
                                                                                                                    Member Price
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                        </Box>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Typography sx={{ color: '#000', fontSize: '18px', fontWeight: '700', mr: 4, }}>
                                                                                                        $ {service?.upfront_amount} deposit required
                                                                                                    </Typography>
                                                                                                )
                                                                                            }



                                                                                        </Box>
                                                                                        <Box>
                                                                                            <Typography sx={{ fontFamily: 'helvetica-lt-std-roman', color: '#000', fontSize: '15px', fontWeight: '600' }}>{window.formatTimeDuration(service?.time_duration)}</Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                ):null
                                                                            }
                                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 1, alignItems: 'center' }}>
                                                                                {service?.is_price_show != 0 ? (
                                                                                    <Box>
                                                                                        <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '10px 20px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                onClick={() => handleCategoryClick(service?.site_url)}>
                                                                                            Book Now <TrendingFlatIcon />
                                                                                        </Button>
                                                                                    </Box>
                                                                                ) : (
                                                                                    <>
                                                                                        <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth:'200px' }}>
                                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                    onClick={() => handleCategoryClick(service?.consultation_url)}>
                                                                                                Book Consultation <TrendingFlatIcon />
                                                                                            </Button>
                                                                                            <Button sx={{     color: '#FFFFFF',borderRadius: '8px',padding: '5px 24px', fontSize:'13px',fontWeight: 600,letterSpacing: '1px',lineHeight: 1.5,border: 'none',transition: 'backgroundColor 0.3s', backgroundColor: "#AA2167" }}
                                                                                                    onClick={() => handleCategoryClick(service?.virtual_url)}>
                                                                                                Book Virtual Consultation <TrendingFlatIcon />
                                                                                            </Button>
                                                                                        </Box>
                                                                                    </>
                                                                                )}
                                                                                {loading ? <Waveloading fullSize /> : ''}
                                                                                <Box>
                                                                                    <Typography sx={{
                                                                                        fontSize: '15px', fontWeight: '700', color: 'rgba(28, 75, 137, 1)',
                                                                                        textDecoration: 'underline', cursor: "pointer"
                                                                                    }}
                                                                                        // onClick={() => window.location.href = service?.link}
                                                                                                onClick={() => handleClick(service?.link)}
                                                                                    >More Info</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>


                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    ''
                                                                </>)
                                                        ))) : ''}
                                            </Grid>
                                        </>
                                    )}
                                </Box>


                            )}


                        </>

                    </Box>
                </Container>
                {/* <Box sx={{
                    position: 'fixed', bottom: '0', display: 'flex', justifyContent: 'space-between', backgroundColor: '#fff', zIndex: '999', boxShadow: '0px -18.000001907348633px 37px 0px rgba(0, 0, 0, 0.5)', borderRadius: '11px', width: '100%', p: { xs: '10px 15px 20px 15px', sm: '10px 30px 30px 30px' }, '@media (max-width:340px)': {
                        p: '10px 5px 10px 5px'
                    }
                }} className="footer-wrappper">
                    <Box onClick={backarrow} sx={{
                        backgroundColor:
                            activeButton === 'home' ? 'rgba(239, 230, 224, 1)' : 'transparent', borderRadius: '7px', p: '8px', display: 'flex', alignItems: 'center', flexDirection: 'column', mr: { xs: '8px', sm: '0px' }, '@media (max-width:340px)': {
                                p: "5px",
                                ml: '6px'
                            }
                    }}>
                        <CardMedia
                            sx={{ width: { xs: '20px', sm: '30px', } }}
                            component="img"
                            alt="Descriptive alt text"
                            image={homeoutline}
                        />
                        <Typography variant="body1" sx={{ fontWeight: '300', fontSize: { xs: '5px', sm: '18px' }, mt: '10px', color: 'rgba(30, 30, 30, 1)', }}>
                            Home
                        </Typography>
                    </Box>

                    <Box onClick={handleBoxClick} sx={{
                        backgroundColor:
                            activeButton === 'booking' ? 'rgba(239, 230, 224, 1)' : 'transparent', borderRadius: '7px', p: '8px', display: 'flex', alignItems: 'center', flexDirection: 'column', mt: '5px'
                    }}>
                        <CardMedia
                            sx={{ width: { xs: '18px', sm: '26px', } }}
                            component="img"
                            alt="Descriptive alt text"
                            image={booking}
                        />
                        <Typography variant="body1" sx={{ fontWeight: '300', fontSize: { xs: '12px', sm: '18px' }, mt: '10px', color: 'rgba(30, 30, 30, 1)', }}>
                            Bookings
                        </Typography>
                    </Box>
                    <Box
          onClick={handleHome}
          sx={{
            borderRadius: "7px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CardMedia
            sx={{
              width: { xs: "80%", sm: "100%" },
              mt: { xs: "-30px", sm: "-40px" },
              '@media (max-width:319px)':{
                width:'45px',
                position:'relative',
                right:'5px'

              },
            }}
            component="img"
            alt="Descriptive alt text"
            image={menu}
          />
        </Box>


                    <Box onClick={handleplan} sx={{
                        backgroundColor:
                            activeButton === 'member' ? 'rgba(239, 230, 224, 1)' : 'transparent', borderRadius: '7px', p: '8px', display: 'flex', alignItems: 'center', flexDirection: 'column'
                    }}>
                        <CardMedia
                            sx={{ width: { xs: '20px', sm: '30px', } }}
                            component="img"
                            alt="Descriptive alt text"
                            image={plan}
                        />
                        <Typography variant="body1" sx={{ fontWeight: '300', fontSize: { xs: '12px', sm: '18px' }, mt: '10px', color: 'rgba(30, 30, 30, 1)', }}>
                            Member
                        </Typography>
                    </Box>


                    <Box onClick={handleprofile} sx={{ borderRadius: '7px', p: '8px', display: 'flex', alignItems: 'center', flexDirection: 'column', ml: { xs: '5px', sm: '0px' } }}>
                        <CardMedia
                            sx={{ width: { xs: '20px', sm: '30px', } }}
                            component="img"
                            alt="Descriptive alt text"
                            image={profile}
                        />
                        <Typography variant="body" sx={{
                            backgroundColor:
                                activeButton === 'profile' ? 'rgba(239, 230, 224, 1)' : 'transparent', fontWeight: '300', fontSize: { xs: '12px', sm: '18px' }, mt: '10px', color: 'rgba(30, 30, 30, 1)',
                        }}>
                        Account  
                        </Typography>
                    </Box>
                   



                </Box> */}
                <Footer />
            </Box>
        </>






    );
};

export default SubCategories;