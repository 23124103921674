import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {FormControl, Grid} from "@mui/material";
import SimpleInput from "../../../../components/form/simpleInput";
import CustomButton from "../../../../components/custom-button/customButton";
import Loader from "../../../../components/loader/loader";
import CustomTable from "../../../../components/table";
import {getAllClinicsTreatmentForm, getTreatmentForm, updatetreatmentformFilter} from "../../../../store/clinics/clinicSettings/clinic-treatment-form";
import BackendPagination from '../../../../components/pagination/backendPagination';
import Waveloading from '../../../loading/Waveloading';

const TreatmentForm = () => {

    const [loading, setLoading] = useState(false);
    const [search,setSearch] = useState('')

    const params =useParams()
    const dispatch = useDispatch();    
    const navigate = useNavigate();

    const clinicTreatmentFormStore = useSelector(state => state?.clinicTreatmentFormStore)
    const user = useSelector(state => state?.authStore?.user)
    const {currentPage} = clinicTreatmentFormStore


    useEffect(()=>{    
        (async ()=>{
            if (!clinicTreatmentFormStore?.treatmentForms?.length){
                setLoading(true)
            }

            await dispatch(getTreatmentForm(params?.clinic_id))
            setLoading(false)
        })()
    },[dispatch,clinicTreatmentFormStore?.treatmentForms?.length,params?.clinic_id])


    const refreshData = async () => {
        setLoading(true);
        try {
         await dispatch(getTreatmentForm(params?.clinic_id))
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false); 
    };
    const treatmentHead= [
        {value:"Name",key:'name'},
        {value:"Type",key:'type'},
        {value:"Status",key:'status'},
        {value:"Action",key:'action'}
    ]

    const handleEdit = (row, isEdit) => {
        if (isEdit) {
            navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/edit-treatment-form/${row?.id}`)
        }
    }

    const handleClick =()=>{
        if (params?.clinic_id){
            navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/add-treatment-form`)
        }
    }
    const fetchPageData = async(page) => {
        await dispatch(updatetreatmentformFilter({ currentPage: page }))  
        await refreshData()
    }
 
    return (
        <Grid container p={'20px'} sx={{ justifyContent:"center" }} >
            <Grid item xs={12} sx={{mb:'16px',display:'flex',justifyContent:'flex-end',flexWrap:'wrap',alignItems:'center',width:'100%'}}>
                <FormControl sx={{mr: "10px", mb:{sm:0, xs:1}}}>  
                    <SimpleInput
                        search={search}
                        setSearch={setSearch}
                    />
                </FormControl>
                <CustomButton val={'Add Treatment Form'} size={'large'} handleClick={handleClick}/>
            </Grid>
            <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                {loading ? <Waveloading /> : <CustomTable 
                    headRow={treatmentHead}  
                    tableData={clinicTreatmentFormStore?.treatmentForms}
                    search={search}
                    isStatus={true}
                    handleEdit={handleEdit}
                    type={'treatmentForm'}
                />
                }               
            </Grid>
            {clinicTreatmentFormStore?.treatmentForms?.meta?.last_page > 1 &&
            <BackendPagination
                count={clinicTreatmentFormStore?.treatmentForms?.meta?.last_page}
                currentPage={currentPage}
                handleOnChange={fetchPageData}
            />
        }
        </Grid>
    )
};

export default TreatmentForm;