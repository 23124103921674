import React from "react";
import {Box} from "@mui/material";

const UserLayout = ({children}) => {

    return (
      <Box sx={{ minHeight: 'calc(100vh - 129px)', background: "#fff", padding:'35px' ,borderRadius:'7px'," @media (max-width:500px)": { padding:'5px' }, }}>
          {children}
      </Box>
    );
};

export default UserLayout;
