import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, TextField, TextareaAutosize, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CustomButtoncolor from '../custom-button/customColorButton';
// import { sendReminder } from '../../store/crm/pos';
import { getAllPOSBooking, sendReminder, voidinvoice } from '../../store/crm/pos';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
// import {inputStyle, labelStyle} from "../../components/form/formInput";
import HttpsIcon from '@mui/icons-material/Https';
import { successConfirmation } from '../../services/utills';
import Loader from '../loader/loader';
import Waveloading from '../../pages/loading/Waveloading';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',

    borderRadius: '6px',
    '@media (max-width: 480px)': {
        width: "296px",
    },
};
const innerbox = {
    p: '27px 12px',
}
const wrapper = {
    alignItems: "center",
}
const heading = {
    fontSize: "18px",
    fontFamily: 'helvetica-lt-std-roman',
    fontWeight: '400',
    textAlign: "center",
    width: '100%',
    fontWeight: "bold",
    color: "white"

}
export const textareaStyle = {

    height: '200px',
    border: "none",
    background: "#EDF2F7",
    borderRadius: '4px',
    outline: "none",
    "&:focus": {
        borderColor: "#EDF2F7",
        outline: "none",
    },
    "&:hover": {
        borderColor: "#EDF2F7",
        outline: "none",
    }

};
export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}

const VoidInvoice = ({ id }) => {
    console.log("invoice id", id)
    const dispatch = useDispatch();
    // console.log("id get",customerId, bookingId )
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState("");
    const validate = (values) => {
        const errors = {};

        if (!values.reason) {
            errors.reason = "reason is required";
        }
        if (!values.password) { // Update the field name to "password"
            errors.password = "password is required";
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            reason: "",
            password: "",

        },
        validate,
        onSubmit: async (values) => {
            try {
                const finalPayload = {
                    invoice_id: id,
                    password: values.password,
                    void_reason: values.reason,

                };
                setLoading(true)

                let { payload } = await dispatch(voidinvoice(finalPayload));
                handleClose();
                await dispatch(getAllPOSBooking())
                if (payload?.success === 'true') {
                    // await dispatch(getAllPOSBooking())
                    toast.success('wallet update successfully');

                    setLoading(false)


                } else {
                    setLoading(false)

                    if (payload?.data?.errors) {
                        await successConfirmation('Error', payload?.data?.errors || 'Somethings went wrong.', 'error')
                    }
                }
            } catch (error) {
                setLoading(false)
                console.log('Error wallet update', error);
            }
        },
    }); 
    
    const handleCloseModal = () => {
        handleClose();
    };


    return (
        <>
            <Button
                onClick={handleOpen}
                size="large"
                onClose={handleClose}
                sx={{
                    // borderRadius:"0px",
                    color: "#219653",
                    borderColor: "#219653",
                    textTransform: "capitalize",
                    width: "100%",
                    borderRadius: "6px!important",
                    fontSize: "14px",
                    "&:hover": {
                        backgroundColor: "#219653",
                        borderColor: "#219653",
                        color: "#fff",
                    },
                }}
                variant={"outlined"}
            > 
                {"Void Invoice"}
            </Button> 
            {loading ? <Waveloading fullSize /> : ''}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            > 
          <Box sx={style}>  
<Grid container spacing={1} sx={wrapper}>

 <Grid item lg={12} md={12} sm={12} style={{ display: "contents" }}>
                            <Box style={{ width: "100%", background: "#1976D2", padding: "10px 0px", borderRadius: "9px 10px 0px 0px", margin: "0px 0px 0px 7px", display: 'flex', alignItems: 'center' }}>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" style={{ paddingLeft: "20px" }}>
                                    <CloseIcon style={{ color: "#fff" }} />
                                </IconButton>
                                <Typography variant="h6" id="modal-modal-title" sx={{ flexGrow: 1, textAlign: "center", color: "#fff" }}>
                                    Open Invoice
                                </Typography>
                            </Box>
</Grid> 

 <Box sx={innerbox} >  
                            <Grid item lg={12}>
                                <Typography style={{ textAlign: "center" }}>Enter the Reason to Void this Invoice</Typography>
                            </Grid>
                            <Grid>
                                <FormControl fullWidth>
                                    <TextareaAutosize
                                        style={textareaStyle}
                                        rows={6}
                                        name="reason"

                                        placeholder=""
                                        value={formik.values.reason}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.reason && formik.errors.reason && (
                                        <Typography color="error">{formik.errors.reason}</Typography>
                                    )}
                                </FormControl>
                            </Grid>
                           

                          
                            <Grid container
                            style={{
                                marginTop: '26px',
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row", 
                                width: "100%",
                                padding: "0px 20px",
                              }}
                          >

                                <Grid item lg={5} md={9} sm={9}>
                                    <FormControl fullWidth>

                                        <TextField
                                            id="outlined-basic"
                                            label="Password"
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            placeholder="Password" 
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            variant="outlined"
                                        />

                                    </FormControl>
                                    {formik.touched.password && formik.errors.password && (
                                        <Typography color="error">{formik.errors.password}</Typography>
                                    )}
                                </Grid>
                                <Grid item lg={3} style={{ marginLeft: "7px" }}>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showPassword}
                                                onChange={() => setShowPassword(!showPassword)} // Toggle the state
                                            />
                                        }
                                        label="Show Password"
                                    />
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} style={{ marginLeft: "3px" }}>
                                    <Button
                                        variant="contained"  
                                        size="large"
                                        style={{
                                            height: "50px",
                                            width: "92px",
                                            backgroundColor: 'linear-gradient(180deg, #72D5FF -43.75%, #0496FF 86.52%)',
                                            borderRadius: '21px'
                                        }}
                                        onClick={formik.handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </Grid>


                            </Grid>
                           
 </Box> 

 </Grid>
  </Box>  

</Modal>

        </>
    )
}

export default VoidInvoice;

