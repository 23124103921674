import React, { useEffect, useState } from 'react';
import CRMLayout from "../../../layout/CRMLayout";
import ComingSoon from "../../../components/coming-soon";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Link, Radio, RadioGroup, Tab, Table, TableBody, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, makeStyles } from '@mui/material';
import logo from '../../../assets/logo/3d-logo.png'
import CustomButtoncolor from '../../../components/custom-button/customColorButton';
import Loader from '../../../components/loader/loader';
import {styled} from "@mui/material/styles";
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CustomButton from "../../../components/custom-button/customButton"; 
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from '../../../components/modal/ConfirmationModal';
import FilterInput from '../../../components/form/filterInput';
import DateRange from '../../../components/date-range/date-range';
import moment from 'moment';
import { storeAppointmentNotes } from '../../../store/crm/appointment/notes';
 
export const logowrap = {
  display: 'flex',
  justifyContent: "space-between",
  alignItems: 'center'
};
  
export const heading = {
  fontFamily: 'helvetica-lt-std-light',
  fontWeight: '700!important',  
  fontSize: '30px!important'
};

export const labeldesign = {
  fontFamily: 'helvetica-lt-std-light',
  fontSize: '15px!important'
};

export const wrapper = {
  background: 'white',
  padding: '15px 28px',
  borderRadius: "8px"
};
export const btnstyle ={
    color: "#464646",
    borderColor: "#464646",
    textTransform: "capitalize",
    fontSize: "14px",
    "&:hover": {
      backgroundColor:  "#464646",
      borderColor:  "#464646",
      color: "#fff",  
    },

  variant:"outlined"
 
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D8F3FF",
      color: "#27272E",
      fontSize: "16px",
      fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      paddingTop:'7px',
      paddingBottom:'7px',
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
      border: 0,
  },
}));  



const CallLogs = () => {
  // const [outerTab, setOuterTab] = useState('Appointment');
  // const [innerTab, setInnerTab] = useState(0);
  // const [loading, setLoading] = useState(false);
  // const [checkboxState, setCheckboxState] = useState({
  //   no: true,
  //   yes: false,
  //   noans: false,
  // });
  // const [comments, setComments] = useState('');
  //
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [isRowVisible, setIsRowVisible] = useState(true);
  // const dispatch = useDispatch()
  //
  // const [selectedRange, setSelectedRange] = useState([null, null]);
  // const CallLogStore = useSelector(state => state?.CallLogStore)
  // const {callLog, confirmedcallLog, pageLimit, currentPage,} = CallLogStore
  // const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : '';
  // const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : '';
  // const [selectedType, setSelectedType] = useState('');
  //
  //
  // useEffect(() => {
  //   if (startDate && endDate) {
  //     (async () => {
  //       const selectedType = outerTab === 0 ? 'Appointment' : 'Treatment';
  //
  //       const payload = {
  //         type: selectedType,
  //         start_date: formattedStartDate,
  //         end_date: formattedEndDate,
  //       };
  //
  //       setLoading(true);
  //       await dispatch(callLogData(payload));
  //       setLoading(false);
  //     })();
  //   }
  // }, [startDate, endDate, outerTab]);
  //
  // const handleCheckboxChange = (event, id) => {
  //   const {name, checked} = event.target;
  //   setCheckboxState((prev) => ({
  //     no: name === 'no' ? checked : false,
  //     yes: name === 'yes' ? checked : false,
  //     noans: name === 'noans' ? checked : false,
  //   }));
  //   // Get the payload based on the checkbox that was checked
  //
  //   let Satisfied;
  //   if (name === 'yes' && checked) {
  //     Satisfied = 1;
  //     setInnerTab(1); // Set innerTab state to 1 when "yes" is checked
  //   } else if (name === 'no' && checked) {
  //     Satisfied = 0;
  //   } else if (name === 'noans' && checked) {
  //     Satisfied = 'No Answer'; // Modify as per your requirements
  //   }
  //   // Make the API call
  //   if (Satisfied !== undefined) {
  //     (async () => {
  //
  //       const selectedType = outerTab === 0 ? 'Appointment' : 'Treatment';
  //
  //       const payload = {
  //         booking_id: id,
  //         is_satisfied: Satisfied
  //       };
  //       const payloadconfirm = {
  //         type: selectedType,
  //         start_date: formattedStartDate,
  //         end_date: formattedEndDate,
  //       };
  //
  //       setLoading(true);
  //       await dispatch(TreatmentSatisfied(payload));
  //       await dispatch(confirmedcallLogData(payloadconfirm));
  //       setLoading(false);
  //     })();
  //   }
  // };
  //
  //
  // const handleOuterTabChange = (event, newValue) => {
  //   setOuterTab(newValue);
  // };
  //
  // const handleInnerTabChange = (event, newValue) => {
  //   setInnerTab(newValue);
  // };
  // const [isModalOpen, setIsModalOpen] = useState(false);
  //
  // const handleModalOpen = () => {
  //   setIsModalOpen(true);
  // };
  //
  // const handleModalClose = () => {
  //   setIsModalOpen(false);
  // };
  //
  // const fetchPageData = async (page) => {
  //   const selectedType = outerTab === 0 ? 'Appointment' : 'Treatment';
  //
  //   const payload = {
  //     type: selectedType,
  //     start_date: formattedStartDate,
  //     end_date: formattedEndDate,
  //   };
  //
  //   await dispatch(updateCalllogFilters({currentPage: page}))
  //   await dispatch(callLogData(payload));
  // }
  // const handleLimitChange = async (val) => {
  //
  //   const selectedType = outerTab === 0 ? 'Appointment' : 'Treatment';
  //
  //   const payload = {
  //     type: selectedType,
  //     start_date: formattedStartDate,
  //     end_date: formattedEndDate,
  //   };
  //   await dispatch(updateCalllogFilters({pageLimit: val, currentPage: 1}))
  //   await dispatch(callLogData(payload));
  // }
  // const handleRowVisibility = (isVisible, id) => {
  //   if (isVisible && id === 'Confirmed') {
  //     setIsRowVisible(false);
  //   }
  // };
  //
  // const handleConfirmCall = async () => {
  //
  //   if (startDate && endDate) {
  //     const selectedType = outerTab === 0 ? 'Appointment' : 'Treatment';
  //
  //     const payload = {
  //       type: selectedType,
  //       start_date: formattedStartDate,
  //       end_date: formattedEndDate,
  //     };
  //     setLoading(true);
  //     await dispatch(confirmedcallLogData(payload));
  //     setLoading(false);
  //
  //
  //   }
  // };
  // const handleNotesChange = (value) => {
  //   setComments(value);
  // };
  // const handleSubmit = async (bookingId) => {
  //   let finalData = {
  //     booking_id: bookingId,
  //     type: selectedType,
  //     description: comments,
  //   };
  //
  //   setLoading(true);
  //
  //   await dispatch(storeAppointmentNotes(finalData));
  //   setLoading(false);
  // };

  return (
      <Grid container gap={'12px'} spacing={2} sx={wrapper}>
        {/*{loading ? <Loader fullSize/> : ""}*/}
        {/*<Grid item lg={12} sx={logowrap}>*/}
        {/*  <Typography sx={heading}>Call Log Activity</Typography>*/}
        {/*  <img src={logo} alt="loading" width={'20%'}/>*/}
        {/*</Grid>*/}
        {/*<Grid item lg={12}>*/}
        {/*  <div>*/}
        {/*    <Tabs value={outerTab} onChange={handleOuterTabChange}>*/}
        {/*      <Tab label="Appointment" value="Appointment"/>*/}
        {/*      <Tab label="Treatment" value="Treatment"/>*/}
        {/*    </Tabs>*/}
        
        {/*    {outerTab === "Appointment" && (*/}
        {/*        <Grid container spacing={4} style={{marginTop: "6px"}}>*/}
        {/*          <Grid item lg={7} sx={logowrap}>*/}
        {/*            <Grid item lg={4}>*/}
        {/*              <Typography sx={labeldesign}>Appointment List For:</Typography>*/}
        {/*            </Grid>*/}
        {/*            <Grid item lg={8} style={{border: '1px solid #6f6565'}}>*/}
        {/*              <FormControl style={{width: "100%"}}>*/}
        {/*                <DateRange*/}
        {/*                    setStartDate={setStartDate}*/}
        {/*                    startDate={startDate}*/}
        {/*                    setEndDate={setEndDate}*/}
        {/*                    endDate={endDate}/>*/}
        
        
        {/*              </FormControl>*/}
        
        {/*            </Grid>*/}
        
        {/*          </Grid>*/}
        {/*          /!* empty grid *!/*/}
        {/*          <Grid item lg={4}>*/}
        {/*          </Grid>*/}
        {/*          /!* button *!/*/}
        {/*          <Grid item lg={8}>*/}
        {/*            <div style={{display: 'flex', gap: '5px'}}>*/}
        
        {/*              <Button*/}
        {/*                  variant="outlined"*/}
        {/*                  size="large"*/}
        {/*                  sx={btnstyle}*/}
        {/*                  startIcon={<LocalPrintshopOutlinedIcon style={{fontSize: '20px', color: 'black'}}/>}*/}
        {/*              >*/}
        {/*                Print*/}
        {/*              </Button>*/}
        {/*              <Button variant="outlined"*/}
        {/*                      sx={btnstyle}*/}
        {/*                      size="large"*/}
        {/*                      startIcon={<CloudDownloadOutlinedIcon style={{fontSize: '20px', color: 'black'}}/>}>*/}
        {/*                download report*/}
        {/*              </Button>*/}
        {/*              <Button*/}
        {/*                  variant="outlined"*/}
        {/*                  sx={btnstyle}*/}
        {/*                  size="large"*/}
        {/*                  startIcon={<EmailOutlinedIcon style={{fontSize: '20px', color: 'black'}}/>}>*/}
        {/*                Email Reports*/}
        {/*              </Button>*/}
        {/*            </div>*/}
        {/*          </Grid>*/}
        {/*          /!* empty grid *!/*/}
        {/*          <Grid item lg={4}>*/}
        {/*          </Grid>*/}
        {/*          <Grid item lg={12}>*/}
        {/*            <div>*/}
        {/*              <Tabs value={innerTab} onChange={handleInnerTabChange}>*/}
        {/*                <Tab label="Appoinment Confirmation List"/>*/}
        {/*                <Tab label="Confirmed Appointment"/>*/}
        {/*              </Tabs>*/}
        
        {/*              {innerTab === 0 && (*/}
        {/*                  <TableContainer sx={{mt: '24px'}}>*/}
        {/*                    {loading === true && <Loader fullSize/>}*/}
        {/*                    <Table stickyHeader aria-label="customized table">*/}
        {/*                      <TableHead>*/}
        {/*                        <TableRow>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Booking Id*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Customer Name*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Treatment Name*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Booking Time*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Tell #*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Email*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Appoinment Confirmed*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Notes*/}
        {/*                          </StyledTableCell>*/}
        
        
        {/*                        </TableRow>*/}
        {/*                      </TableHead>*/}
        
        {/*                      <TableBody>*/}
        {/*                        {callLog?.data?.data && callLog.data.data.length ? (*/}
        {/*                            callLog.data.data.map((item) => (*/}
        {/*                                isRowVisible && (*/}
        {/*                                    <StyledTableRow style={{backgroundColor: "#F5FCFF"}}>*/}
        {/*                                      <StyledTableCell>*/}
        {/*                                        {item?.id}*/}
        {/*                                      </StyledTableCell>*/}
        {/*                                      <StyledTableCell>*/}
        {/*                                        {item?.customer?.name}*/}
        {/*                                      </StyledTableCell>*/}
        {/*                                      <StyledTableCell>*/}
        {/*                                        {item?.service_name}*/}
        
        {/*                                      </StyledTableCell>*/}
        {/*                                      <StyledTableCell>*/}
        {/*                                        {item?.date}*/}
        
        {/*                                        {item?.start_time*/}
        {/*                                        }-{item?.end_time}*/}
        {/*                                      </StyledTableCell>*/}
        {/*                                      <StyledTableCell>*/}
        {/*                                        {item?.customer?.phone}*/}
        {/*                                      </StyledTableCell>*/}
        {/*                                      <StyledTableCell>*/}
        {/*                                        {item?.customer?.email}*/}
        {/*                                      </StyledTableCell>*/}
        
        {/*                                      <StyledTableCell*/}
        {/*                                          align="center"*/}
        {/*                                          sx={{*/}
        {/*                                            paddingTop: "40px!important",*/}
        {/*                                            minWidth: "100px",*/}
        {/*                                            display: "flex",*/}
        {/*                                            paddingBottom: "37px!important",*/}
        {/*                                            padding: "36px",*/}
        {/*                                          }}*/}
        {/*                                      >*/}
        {/*                                        <FormControlLabel*/}
        {/*                                            control={*/}
        {/*                                              <Checkbox*/}
        {/*                                                  checked={checkboxState.no}*/}
        {/*                                                  onChange={handleCheckboxChange}*/}
        {/*                                                  name="no"*/}
        {/*                                                  size="small"*/}
        {/*                                              />*/}
        {/*                                            }*/}
        {/*                                            label="no"*/}
        {/*                                        />*/}
        {/*                                        <ConfirmationModal isOpen={isModalOpen}*/}
        {/*                                                           onOpen={handleModalOpen}*/}
        {/*                                                           onClose={handleModalClose}*/}
        {/*                                                           handleRowVisibility={(isVisible) => handleRowVisibility(isVisible, item.id)}*/}
        {/*                                                           handleClick={handleConfirmCall}/>*/}
        
        {/*                                        <FormControlLabel*/}
        {/*                                            control={*/}
        {/*                                              <Checkbox*/}
        {/*                                                  checked={checkboxState.noans}*/}
        {/*                                                  onChange={handleCheckboxChange}*/}
        {/*                                                  name="noans"*/}
        {/*                                                  size="small"*/}
        {/*                                              />*/}
        {/*                                            }*/}
        {/*                                            label="Noans"*/}
        {/*                                        />*/}
        {/*                                        <CustomButtoncolor*/}
        {/*                                            mainColor={"black"}*/}
        {/*                                            size="md"*/}
        {/*                                            val='Reschedule'*/}
        {/*                                        />*/}
        {/*                                        /!* <Button variant="outlined" style={{color:'black',border:"black"}}>Reschedule</Button> *!/*/}
        
        {/*                                      </StyledTableCell>*/}
        {/*                                      <StyledTableCell>*/}
        {/*                                        <Link href="#"> Open notes</Link>*/}
        {/*                                      </StyledTableCell>*/}
        
        {/*                                    </StyledTableRow>)))*/}
        {/*                        ) : (*/}
        {/*                            <TableRow>*/}
        {/*                              <TableCell colSpan={9} align="center">no appointment data found</TableCell>*/}
        {/*                            </TableRow>*/}
        {/*                        )}*/}
        
        
        {/*                      </TableBody>*/}
        {/*                    </Table>*/}
        {/*                    {callLog?.data?.data && callLog?.data?.data.length > 0 && (  // Check if the data array has any items*/}
        {/*                        <Box sx={{*/}
        {/*                          display: 'flex',*/}
        {/*                          alignItems: 'center',*/}
        {/*                          justifyContent: 'space-between',*/}
        {/*                          mt: '24px'*/}
        {/*                        }}>*/}
        {/*                          <div style={{display: 'flex', alignItems: 'baseline'}}>*/}
        {/*                            <FilterInput*/}
        {/*                                isBorder*/}
        {/*                                limit={pageLimit}*/}
        {/*                                setLimit={handleLimitChange}*/}
        {/*                            />*/}
        {/*                            <Typography>Showing {callLog?.data?.meta?.from} to {callLog?.data?.meta?.to} of {callLog?.data?.meta?.total} entries</Typography>*/}
        {/*                          </div>*/}
        {/*                          <BackendPagination*/}
        {/*                              count={callLog?.data?.meta?.last_page}*/}
        {/*                              currentPage={currentPage}*/}
        {/*                              handleOnChange={fetchPageData}*/}
        {/*                          />*/}
        {/*                        </Box>*/}
        {/*                    )}*/}
        {/*                  </TableContainer>*/}
        {/*              )}*/}
        
        {/*              {innerTab === 1 && (*/}
        {/*                  <TableContainer sx={{mt: '24px'}}>*/}
        {/*                    {loading === true && <Loader fullSize/>}*/}
        {/*                    <Table stickyHeader aria-label="customized table">*/}
        {/*                      <TableHead>*/}
        {/*                        <TableRow>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Booking Id*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Customer Name*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Treatment Name*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Booking Time*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Tell #*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Email*/}
        {/*                          </StyledTableCell>*/}
        
        
        {/*                        </TableRow>*/}
        {/*                      </TableHead>*/}
        
        {/*                      <TableBody>*/}
        {/*                        {confirmedcallLog?.data?.data && callLog.data.data.length ? (*/}
        {/*                            confirmedcallLog?.data?.data.map((item) => (*/}
        
        {/*                                <StyledTableRow style={{backgroundColor: "#F5FCFF"}}>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.id}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.name}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.service_name}*/}
        
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.date}*/}
        {/*                                    <br/>*/}
        {/*                                    {item?.start_time*/}
        {/*                                    }-{item?.end_time}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.phone}*/}
        {/*                                  </StyledTableCell>*/}
        
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.email}*/}
        {/*                                  </StyledTableCell>*/}
        
        
        {/*                                </StyledTableRow>*/}
        {/*                            ))*/}
        {/*                        ) : (*/}
        {/*                            <TableRow>*/}
        {/*                              <TableCell colSpan={9} align="center">no appointment confirmed data found*/}
        {/*                                found</TableCell>*/}
        {/*                            </TableRow>*/}
        {/*                        )}*/}
        
        {/*                      </TableBody>*/}
        {/*                    </Table>*/}
        
        {/*                    {confirmedcallLog?.data?.data && confirmedcallLog?.data?.data.length > 0 && (*/}
        {/*                        <Box sx={{*/}
        {/*                          display: 'flex',*/}
        {/*                          alignItems: 'center',*/}
        {/*                          justifyContent: 'space-between',*/}
        {/*                          mt: '24px'*/}
        {/*                        }}>*/}
        {/*                          <div style={{display: 'flex', alignItems: 'baseline'}}>*/}
        {/*                            <FilterInput*/}
        {/*                                isBorder*/}
        {/*                                limit={pageLimit}*/}
        {/*                                setLimit={handleLimitChange}*/}
        {/*                            />*/}
        {/*                            <Typography>Showing {confirmedcallLog?.data?.meta?.from} to {confirmedcallLog?.data?.meta?.to} of {confirmedcallLog?.data?.meta?.total} entries</Typography>*/}
        {/*                          </div>*/}
        
        {/*                          <BackendPagination*/}
        {/*                              count={confirmedcallLog?.data?.meta?.last_page}*/}
        {/*                              currentPage={currentPage}*/}
        {/*                              handleOnChange={fetchPageData}*/}
        {/*                          />*/}
        {/*                        </Box>*/}
        {/*                    )}*/}
        {/*                  </TableContainer>*/}
        {/*              )}*/}
        {/*            </div>*/}
        {/*          </Grid>*/}
        
        {/*        </Grid>*/}
        {/*    )}*/}
        
        {/*    {outerTab === "Treatment" && (*/}
        {/*        <Grid container spacing={4} style={{marginTop: "6px"}}>*/}
        {/*          <Grid item lg={7} sx={logowrap}>*/}
        {/*            <Grid item lg={4}>*/}
        {/*              <Typography sx={labeldesign}>Follow Up List For:</Typography>*/}
        {/*            </Grid>*/}
        {/*            <Grid item lg={8} style={{border: '1px solid black'}}>*/}
        {/*              <FormControl>*/}
        
        {/*                <DateRange*/}
        {/*                    setStartDate={setStartDate}*/}
        {/*                    startDate={startDate}*/}
        {/*                    setEndDate={setEndDate}*/}
        {/*                    endDate={endDate}/>*/}
        
        
        {/*              </FormControl>*/}
        
        {/*            </Grid>*/}
        {/*          </Grid>*/}
        {/*          /!* empty grid *!/*/}
        {/*          <Grid item lg={5}>*/}
        {/*          </Grid>*/}
        
        {/*          <Grid item lg={8}>*/}
        {/*            <div style={{display: 'flex', gap: '5px'}}>*/}
        {/*              <Button*/}
        {/*                  variant="outlined"*/}
        {/*                  size="large"*/}
        {/*                  sx={btnstyle}*/}
        {/*                  startIcon={<LocalPrintshopOutlinedIcon style={{fontSize: '20px', color: 'black'}}/>}*/}
        {/*              >*/}
        {/*                Print*/}
        {/*              </Button>*/}
        {/*              <Button variant="outlined"*/}
        {/*                      sx={btnstyle}*/}
        {/*                      size="large"*/}
        {/*                      startIcon={<CloudDownloadOutlinedIcon style={{fontSize: '20px', color: 'black'}}/>}>*/}
        {/*                download report*/}
        {/*              </Button>*/}
        {/*              <Button*/}
        {/*                  variant="outlined"*/}
        {/*                  sx={btnstyle}*/}
        {/*                  size="large"*/}
        {/*                  startIcon={<EmailOutlinedIcon style={{fontSize: '20px', color: 'black'}}/>}>*/}
        {/*                Email Reports*/}
        {/*              </Button>*/}
        {/*            </div>*/}
        {/*          </Grid>*/}
        {/*          /!* empty grid *!/*/}
        {/*          <Grid item lg={4}>*/}
        {/*          </Grid>*/}
        {/*          <Grid item lg={12}>*/}
        {/*            <div>*/}
        {/*              <Tabs value={innerTab} onChange={handleInnerTabChange}>*/}
        {/*                <Tab label="Treatment Follow Up List"/>*/}
        {/*                <Tab label="Manager Follow Up List"/>*/}
        {/*              </Tabs>*/}
        
        {/*              {innerTab === 0 && (*/}
        {/*                  <TableContainer sx={{mt: '24px'}}>*/}
        {/*                    {loading === true && <Loader fullSize/>}*/}
        {/*                    <Table stickyHeader aria-label="customized table">*/}
        {/*                      <TableHead>*/}
        {/*                        <TableRow>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Booking Id*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Customer Name*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Treatment Name*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Booking Time*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Tell #*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Email*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Satisfied Customer?*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Notes*/}
        {/*                          </StyledTableCell>*/}
        
        
        {/*                        </TableRow>*/}
        {/*                      </TableHead>*/}
        {/*                      <TableBody>*/}
        
        {/*                        {callLog?.data?.data && callLog?.data?.data.length ? (*/}
        {/*                            callLog?.data?.data.map((item) => (*/}
        {/*                                <StyledTableRow style={{backgroundColor: "#F5FCFF"}}>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.id}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.name}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.service_name}*/}
        
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.date}*/}
        
        {/*                                    {item?.start_time*/}
        {/*                                    }-{item?.end_time}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.phone}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.email}*/}
        {/*                                  </StyledTableCell>*/}
        
        {/*                                  <StyledTableCell*/}
        {/*                                      align="center"*/}
        {/*                                      sx={{*/}
        {/*                                        paddingTop: "40px!important",*/}
        {/*                                        minWidth: "100px",*/}
        {/*                                        display: "flex",*/}
        {/*                                        paddingBottom: "37px!important",*/}
        {/*                                        padding: "36px",*/}
        {/*                                      }}*/}
        {/*                                  >*/}
        {/*                                    <FormControlLabel*/}
        {/*                                        control={*/}
        {/*                                          <Checkbox*/}
        {/*                                              checked={checkboxState.no}*/}
        {/*                                              onChange={(event) => handleCheckboxChange(event, item.id)}*/}
        {/*                                              name="no"*/}
        {/*                                              size="small"*/}
        {/*                                          />*/}
        {/*                                        }*/}
        {/*                                        label="no"*/}
        {/*                                    />*/}
        {/*                                    <AddNotes isOpen={isModalOpen} onClose={handleModalClose}*/}
        {/*                                              comments={comments} handleClick={() => handleSubmit(item?.id)}*/}
        {/*                                              setSelectedType={setSelectedType}*/}
        {/*                                              onNotesChange={handleNotesChange}/>*/}
        
        
        {/*                                  </StyledTableCell>*/}
        
        {/*                                  <StyledTableCell>*/}
        {/*                                    <Link href="#"> Add Notes </Link>*/}
        {/*                                  </StyledTableCell>*/}
        
        {/*                                </StyledTableRow>*/}
        {/*                            ))*/}
        {/*                        ) : (*/}
        {/*                            <TableRow>*/}
        {/*                              <TableCell colSpan={9} align="center"> no Treatment data found</TableCell>*/}
        {/*                            </TableRow>*/}
        {/*                        )}*/}
        
        
        {/*                      </TableBody>*/}
        {/*                    </Table>*/}
        {/*                    {callLog?.data?.data && callLog?.data?.data.length > 0 && (  // Check if the data array has any items*/}
        {/*                        <Box sx={{*/}
        {/*                          display: 'flex',*/}
        {/*                          alignItems: 'center',*/}
        {/*                          justifyContent: 'space-between',*/}
        {/*                          mt: '24px'*/}
        {/*                        }}>*/}
        {/*                          <div style={{display: 'flex', alignItems: 'baseline'}}>*/}
        {/*                            <FilterInput*/}
        {/*                                isBorder*/}
        {/*                                limit={pageLimit}*/}
        {/*                                setLimit={handleLimitChange}*/}
        {/*                            />*/}
        {/*                            <Typography>Showing {callLog?.data?.meta?.from} to {callLog?.data?.meta?.to} of {callLog?.data?.meta?.total} entries</Typography>*/}
        {/*                          </div>*/}
        {/*                          <BackendPagination*/}
        {/*                              count={callLog?.data?.meta?.last_page}*/}
        {/*                              currentPage={currentPage}*/}
        {/*                              handleOnChange={fetchPageData}*/}
        {/*                          />*/}
        {/*                        </Box>*/}
        {/*                    )}*/}
        {/*                  </TableContainer>*/}
        {/*              )}*/}
        
        {/*              {innerTab === 1 && (*/}
        {/*                  <TableContainer sx={{mt: '24px'}}>*/}
        {/*                    {loading === true && <Loader fullSize/>}*/}
        {/*                    <Table stickyHeader aria-label="customized table">*/}
        {/*                      <TableHead>*/}
        {/*                        <TableRow>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            ID*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Aesthetician Name*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Treatment Name*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Completion Date*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Customer Name*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Tell #*/}
        {/*                          </StyledTableCell>*/}
        {/*                          <StyledTableCell>*/}
        {/*                            Email*/}
        {/*                          </StyledTableCell>*/}
        
        {/*                          <StyledTableCell>*/}
        {/*                            Notes*/}
        {/*                          </StyledTableCell>*/}
        {/*                        </TableRow>*/}
        {/*                      </TableHead>*/}
        {/*                      <TableBody>*/}
        {/*                        {confirmedcallLog?.data?.data && callLog.data.data.length ? (*/}
        {/*                            confirmedcallLog?.data?.data.map((item) => (*/}
        {/*                                <StyledTableRow style={{backgroundColor: "#F5FCFF"}}>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.id}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.name}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.service_name}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.date}*/}
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.name}*/}
        
        {/*                                  </StyledTableCell>*/}
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.phone}*/}
        {/*                                  </StyledTableCell>*/}
        
        {/*                                  <StyledTableCell>*/}
        {/*                                    {item?.customer?.email}*/}
        {/*                                  </StyledTableCell>*/}
        
        {/*                                  <StyledTableCell>*/}
        {/*                                    <Link href="#"> view notes</Link>*/}
        {/*                                  </StyledTableCell>*/}
        
        {/*                                </StyledTableRow>*/}
        {/*                            ))*/}
        {/*                        ) : (*/}
        {/*                            <TableRow>*/}
        {/*                              <TableCell colSpan={9} align="center">no Treatment list data found</TableCell>*/}
        {/*                            </TableRow>*/}
        {/*                        )}*/}
        {/*                      </TableBody>*/}
        {/*                    </Table>*/}
        {/*                    {confirmedcallLog?.data?.data && confirmedcallLog?.data?.data.length > 0 && (*/}
        {/*                        <Box sx={{*/}
        {/*                          display: 'flex',*/}
        {/*                          alignItems: 'center',*/}
        {/*                          justifyContent: 'space-between',*/}
        {/*                          mt: '24px'*/}
        {/*                        }}>*/}
        {/*                          <div style={{display: 'flex', alignItems: 'baseline'}}>*/}
        {/*                            <FilterInput*/}
        {/*                                isBorder*/}
        {/*                                limit={pageLimit}*/}
        {/*                                setLimit={handleLimitChange}*/}
        {/*                            />*/}
        {/*                            <Typography>Showing {confirmedcallLog?.data?.meta?.from} to {confirmedcallLog?.data?.meta?.to} of {confirmedcallLog?.data?.meta?.total} entries</Typography>*/}
        {/*                          </div>*/}
        {/*                          <BackendPagination*/}
        {/*                              count={confirmedcallLog?.data?.meta?.last_page}*/}
        {/*                              currentPage={currentPage}*/}
        {/*                              handleOnChange={fetchPageData}*/}
        {/*                          />*/}
        {/*                        </Box>*/}
        {/*                    )}*/}
        {/*                  </TableContainer>*/}
        {/*              )}*/}
        {/*            </div>*/}
        {/*          </Grid>*/}
        {/*        </Grid>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</Grid>*/}
      </Grid>
  );
}

export default CallLogs;
