import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";
import moment from "moment/moment";

export const getGeneralReports = createAsyncThunk("getGeneralReports",async (payload) => {
    try {
        const response = await axios.post(apis.general_reports, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {
        return error?.response
    }
});
// member
export const getMemberCount = createAsyncThunk("getMemberCount",async (payload) => {
    try {
        const response = await axios.post(apis.Member_Count, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {
        return error?.response
    }
});  

//  
export const MemberBookings = createAsyncThunk("MemberBookings",async (payload) => {
    try {
        const response = await axios.post(apis.Member_Booking, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {     
        return error?.response    
    }
});  

export const getSalesReports = createAsyncThunk('getSalesReports ', async (payload) => {
    try {
        const response = await axios.post(apis.sales_reports, payload, {
        headers: apiHeaders(),
      });  
  
       return response.data;
    } catch (error) {
        return error?.response  
    }
});
export const getSalesReportsByType = createAsyncThunk('getSalesReportsByType ', async (payload) => {
    try {
        const response = await axios.post(apis.sales_reports_by_type, payload, {
        headers: apiHeaders(),
      });

       return response.data;
    } catch (error) {
        return error?.response
    }
});
export const getAppointmentTypes = createAsyncThunk('getAppointmentTypes ', async (payload) => {
    try {
        const response = await axios.get(apis.booking_types, {
        headers: apiHeaders(),
      });

        if (response?.data?.success === 'true'){
            return response?.data?.data
        }
       return [];
    } catch (error) {
        return []
    }
});

export const getCategoryReports = createAsyncThunk(" getCategoryReports",  async (payload) => {
    try {
        const response = await axios.post(apis.category_report, payload, {
        headers: apiHeaders(),
      });
       return response.data;
    } catch (error) {
        return error?.response
    }
});

export const getMemberReports = createAsyncThunk("getMemberReports", async (payload) => {
    try {
        const response = await axios.post(apis.member_reports, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {
        return error?.response
    }
});
export const WalletReport = createAsyncThunk("WalletReport", async (payload) => {
    try {
        const response = await axios.post(apis.wallet_report, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {
        return error?.response
    }
});

export const getCategoryService= createAsyncThunk("getCategoryService", async ( data,{getState }) => {
    try {
        const response = await axios.get(apis.Service_Category, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {        
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const getSaleReport= createAsyncThunk("getSaleReport",
async (payload) => {
    try {
        const response = await axios.post(apis.Staff_report, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {
        return error?.response
    }
});


export const getEODReports = createAsyncThunk("getEODReports", async (payload) => {
    try {
        const response = await axios.post(apis.eod_reports, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {
        return error?.response
    }
});

export const getStaff = createAsyncThunk("getStaff",  async (payload) => {
    try {
        const response = await axios.post(apis.Staff_reports, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {
        return error?.response
    }
});

export const getAttendanceReport= createAsyncThunk('getAttendanceReport', async (payload) => {
    try {
        const response = await axios.post(apis.Attendace_report, payload, {
        headers: apiHeaders(),             
      });
  
       return response.data;  
    } catch (error) {
        return error?.response
    }
});

export const getStaffSaleReports = createAsyncThunk('getStaffSaleReports ', async (payload) => {
    try {
        const response = await axios.post(apis.staff_sale_reports, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {  
        return error?.response
    }
});

export const getCategorySaleReports = createAsyncThunk("getCategorySaleReports",async (payload) => {
    try {
        const response = await axios.post(apis.category_sale_reports, payload, {
        headers: apiHeaders(),
      }); 
  
       return response.data;     
    } catch (error) {
        return error?.response
    }
});

export const WalletTabelReports = createAsyncThunk("WalletTabelReports",async (payload) => {
    try {
        const response = await axios.post(apis.Wallet_Api, payload, {
        headers: apiHeaders(),
      }); 
  
       return response.data;     
    } catch (error) {
        return error?.response
    }
});






export const updateReportFilter = createAsyncThunk('updateReportFilter', async (data) => {
    return data
})







export const reportsSlice = createSlice({
    name: "reportsSlice",
    initialState: {
        generalReports: [],
        salesReports: [],
        memberReports: [],
        EODReports: [],
        CategoryReport:[],
        staffSaleReports: [],
        categorySaleReports: [],
        wallet:[],
        Staff:[],
        Sale:[],
        memberBooking:[],
        walletReports:[],
      memberCount:[],
        CategoryService:[],
        appointmentTypes:[],
        attendanceReport:[],
        salesBooking:[],
        search:'',
        pageLimit:10,
        filterBy:'Today',
        currentPage:1,
        reportStartTime:moment().add(1, 'day').format('YYYY-MM-DD'),
        reportEndTime:moment().add(1, 'day').format('YYYY-MM-DD'),
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getGeneralReports.fulfilled, (state, action) => {
            state.generalReports = action.payload;
        });
        builder.addCase(getSalesReports.fulfilled, (state, action) => {
            state.salesReports = action.payload ;
        });
        builder.addCase(getMemberReports.fulfilled, (state, action) => {
            state.memberReports = action.payload;
        });
        builder.addCase(WalletReport.fulfilled, (state, action) => {
            state.walletReports = action.payload;
        });

       
        builder.addCase(getEODReports.fulfilled, (state, action) => {
            state.EODReports = action.payload ;
        });
        builder.addCase(getStaffSaleReports.fulfilled, (state, action) => {
            state.staffSaleReports = action.payload;
        });
        builder.addCase(getCategorySaleReports.fulfilled, (state, action) => {
            state.categorySaleReports = action.payload ;
        });

        builder.addCase(WalletTabelReports.fulfilled, (state, action) => {
            state.wallet = action.payload ;
        });

        builder.addCase(getCategoryReports.fulfilled, (state, action) => {
            state.CategoryReport = action.payload ;
        });
        builder.addCase(MemberBookings.fulfilled, (state, action) => {
            state.memberBooking = action.payload ;
        });
        
        builder.addCase(getStaff.fulfilled, (state, action) => {
            state.Staff = action.payload ;
        });
        builder.addCase(getCategoryService.fulfilled, (state, action) => {
            state.CategoryService = action.payload ;
        });
        builder.addCase(getSaleReport.fulfilled, (state, action) => {
            state.Sale = action.payload ;
        });
       
        builder.addCase(getAppointmentTypes.fulfilled, (state, action) => {
            state.appointmentTypes = action.payload ;
        });
        builder.addCase(getMemberCount.fulfilled, (state, action) => {
            state.memberCount = action.payload ;
        });
        builder.addCase(getAttendanceReport.fulfilled, (state, action) => {
            state.attendanceReport = action.payload ;
        });
        builder.addCase(getSalesReportsByType.fulfilled, (state, action) => {
            state.salesBooking = action.payload ;
        });
        
     
       
        builder.addCase(updateReportFilter.fulfilled, (state, action) => {
            const {filterBy,pageLimit,search,currentPage,startTime,endTime} = action?.payload

            if (filterBy){
                state.filterBy = filterBy ;
            }
            if (pageLimit){
                state.pageLimit = pageLimit ;
            }
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
            if (startTime) {
               
                    state.reportStartTime = startTime;
               
            }
            if (endTime) {

                    state.reportEndTime = endTime;
                
            }
        });

    },
});

export default reportsSlice.reducer;
