import React, { useRef } from "react";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const BackendSearchField = ({ search, handleSearch, fetchData, bcolor, iconcolor, bordColor,  placeholder }) => {
    const inputRef = useRef(null);

    const handleChange = (e) => {
        const { selectionStart, selectionEnd } = e.target;
        const { value } = e.target;
    
        if (value === '') {
            handleSearch("reset");
        } else {
            handleSearch(value);
        }
    
        requestAnimationFrame(() => {
            inputRef.current.setSelectionRange(selectionStart, selectionEnd);
        });
    };
    

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && search?.length > 1) { 
            fetchData();
        }
    };

    const handleSearchButtonClick = () => {
        if (search?.length > 1) {
            fetchData();
        }
    };

    const handleClearButtonClick = () => {
        handleSearch('reset');
        fetchData();
        inputRef.current.focus();
    };

    return (
        <TextField
            name="search"
            value={search}
            inputRef={inputRef}
            sx={{
                background: "#FFFFFF",
                border: "1px solid #4F4F4F",
                borderRadius: "4px",
                ".MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0!important"
                },
                ".MuiInputBase-root": {
                    pr: '0px',
                },
                ".MuiInputBase-input": {
                    borderColor: "#4F4F4F",
                    height: "10px",
                    minHeight: "unset",
                    "&:focus": {
                        borderColor: "#EDF2F7",
                        outline: "none",
                        input: {
                            height: "11px",
                        },
                    },
                },
            }}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || "Search by name"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {search?.length > 1 && (
                            <IconButton onClick={handleClearButtonClick}>
                                <ClearIcon sx={{ fontSize: "1.5rem" }} />
                            </IconButton>
                        )}
                        <Button
                            sx={{
                                background: '#09ABF1',
                                color: '#fff',
                                border: '0px',
                                height: '45px',
                                mr: '-1px',
                                textTransform: 'capitalize',
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                                "&:hover": {
                                    background: '#09ABF1',
                                    border: '0px',
                                }
                            }}
                            style={{
                                background: bcolor,
                                color: iconcolor,
                                border: bordColor,
                                "&:hover": {
                                    background: bcolor,
                                    color: iconcolor,
                                }
                            }}
                            variant={"outlined"}
                            onClick={handleSearchButtonClick} >
                            <SearchIcon />
                        </Button>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default BackendSearchField;
