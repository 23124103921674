import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../../lib/apis/apis";
import {apiHeaders} from "../../../../lib/apiHeader";
import {getAllAppointment} from "../index";
import {getSlotsArrays} from "../../../../services/utills";

export const getAllExistingUser = createAsyncThunk("getAllExistingUser", async (data, { getState }) => {
    const {search,pageLimit,filterBy,currentPage} = getState()?.bookAppointmentStore
        try {
            const response = await axios.get(apis.get_all_existing_user+`${filterBy}&limit=${pageLimit}&page=${currentPage}${search!=='' ? `&search=${search}`:'' }`, {
                headers: apiHeaders(),
            });
            if (response?.data?.success === "true") {  
                return response?.data?.data;
            }else {
                return [];
            }
        } catch (err) {

            return  []
        }
    }
);
export const getAllExistingUserCards = createAsyncThunk("getAllExistingUserCards", async (id, { dispatch }) => {
        try {
            const response = await axios.get(apis.user_existing_card+id, {
                headers: apiHeaders(),
            });
           return response?.data
        } catch (err) {
            return  err?.response
        }
    }
);
export const getServicesCategory = createAsyncThunk("getServicesCategory", async (id, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_services_category, {
                headers: apiHeaders(),
            });
            if (response?.data?.success === "true") {
                return response?.data?.data;
            }else {
                return [];
            }
        } catch (err) {
            return  []
        }
    }
);
export const getCrmAllPlans = createAsyncThunk("getCrmAllPlans", async () => {
        try {
            const response = await axios.get(apis.get_all_plan, {
                headers: apiHeaders(),
            });
            if (response?.data?.success === "true") {
                return response?.data?.data;
            }else {
                return {};
            }
        } catch (err) {
            return  {}
        }
    }
);

export const getCrmAppointmentHolidays = createAsyncThunk("getCrmAppointmentHolidays", async (data) => {
        try {
            const response = await axios.get(apis.appointment_holiday+data?.date+`${data?.staff_id ? `&staff_id=${data?.staff_id}`:''}`, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }else {
                return {};
            }
        } catch (err) {
            return  {}
        }
    }
);
export const getCrmAppointmentSchedule = createAsyncThunk("getCrmAppointmentSchedule", async (data) => {
        try {
            const response = await axios.post(apis.appointment_schedule,{...data}, {
                headers: apiHeaders(),
            });
            if (response?.data?.success === 'true' && response?.data?.data){
                if (typeof response?.data?.data === 'object'){
                    return  getSlotsArrays(Object.values(response?.data?.data))
                }else {
                    return  getSlotsArrays(response?.data?.data)

                }
            }else {
                return {};
            }
        } catch (err) {
            return  {}
        }
    }
);


export const crmGuestUser = createAsyncThunk('crmGuestUser', async (data) => {
    try {
        const response = await axios.post(apis.appointment_guest_user, {...data},{
            headers: apiHeaders()
        })
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})

// delete card 
export const deleteUserCard = createAsyncThunk('deleteUserCard', async (data) => {
    try {
        const response = await axios.post(apis.delete_card, {...data},{
            headers: apiHeaders()
        })
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})



//  default card 
// 
export const defaultUserCard = createAsyncThunk('defaultUserCard', async (data) => {
    try {
        const response = await axios.post(apis.default_card_api, {...data},{
            headers: apiHeaders()
        })
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})
export const crmCreateAppointment = createAsyncThunk('crmCreateAppointment', async (data) => {
    try {
        const response = await axios.post(apis.create_appointment, {...data},{
            headers: apiHeaders()
        })
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})
export const crmCreatePlanAppointment = createAsyncThunk('crmCreatePlanAppointment', async (data) => {
    try {
        const response = await axios.post(apis.create_appointment_plan, {...data},{
            headers: apiHeaders()
        })
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})
export const crmCreateGiftCardAppointment = createAsyncThunk('crmCreateGiftCardAppointment', async (data) => {
    try {
        const response = await axios.post(apis.create_giftCard, {...data},{
            headers: apiHeaders()
        })
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})
export const crmUpdateAppointment = createAsyncThunk('crmUpdateAppointment', async (data) => {
    try {
        const response = await axios.post(apis.update_appointment, {...data},{
            headers: apiHeaders()
        })
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})
export const crmCheckoutAppointment = createAsyncThunk('crmCheckoutAppointment', async (data) => {
    try {
        const response = await axios.post(apis.checkout_appointment, {...data},{
            headers: apiHeaders()
        })
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})

export const crmCreateCard = createAsyncThunk('crmCreateCard', async (data) => {
    try {
        const response = await axios.post(apis.create_crm_card, {...data},{
            headers: apiHeaders()
        })
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})
export const rescheduleAppointment = createAsyncThunk('rescheduleAppointment', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.reschedule_appointment, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.success === 'true'){
           await dispatch(getAllAppointment())
        }
        return  response?.data
    }catch (err){
        console.log(err)
        return err?.response?.data
    }
})

export const getAppointmentById = createAsyncThunk("getAppointmentById", async (id) => {
    try {
        const response = await axios.get(apis.appointment_by_id+id, {
            headers: apiHeaders(),
        });
        return response?.data?.data;
    } catch (err) {
        return  {}
    }
});
export const getCustomerById = createAsyncThunk("getCustomerById", async (id) => {
    try {
        const response = await axios.get(apis.customer_by_id+id, {
            headers: apiHeaders(),
        });
        return response?.data?.data;
     } catch (err) {
        return  {}
    }
});

export const updateHolidaySchedule =createAsyncThunk("updateHolidaySchedule",async (data)=>{
    return data
})
export const updateCrmFilters = createAsyncThunk('updateCrmFilters', async (data) => {
    return data
})


export const bookAppointmentSlice = createSlice({
    name: "bookAppointmentSlice",
    initialState: {
        existingUsers: [],
        selectedUserCard: [],
        servicesCategory:[],
        crmAllPlans:[],
        userDetails:{},
        appointmentHolidays:[],
        appointmentSchedule:[],
        search:'',
        pageLimit:10,
        filterBy:'All',
        currentPage:1,
        delcard:[],
    },
    reducers: {},  
    extraReducers: (builder) => {
        builder.addCase(getAllExistingUser.fulfilled, (state, action) => {
            state.existingUsers = action.payload || [] ;
        });
        // 
        builder.addCase(deleteUserCard.fulfilled, (state, action) => {
            state.delcard = action.payload || [] ;
        });
        builder.addCase(getServicesCategory.fulfilled, (state, action) => {
            state.servicesCategory = action.payload ;
        });
        builder.addCase(getAllExistingUserCards.fulfilled, (state, action) => {
            state.selectedUserCard = action.payload ;
        });
        builder.addCase(getCrmAllPlans.fulfilled, (state, action) => {
            state.crmAllPlans = action.payload ;
        });
        builder.addCase(getCrmAppointmentHolidays.fulfilled, (state, action) => {
            state.appointmentHolidays = action.payload ;
        });
        builder.addCase(getCrmAppointmentSchedule.fulfilled, (state, action) => {
            state.appointmentSchedule = action.payload ;
        });
        builder.addCase(updateHolidaySchedule.fulfilled, (state, action) => {
            const {schedule} = action?.payload
            if (typeof schedule === 'object'){
                state.appointmentSchedule =  getSlotsArrays(Object.values(schedule))
            }else {
                state.appointmentSchedule=  getSlotsArrays(schedule)
            }
            state.appointmentHolidays = action.payload?.holidays ;
        });
        builder.addCase(updateCrmFilters.fulfilled, (state, action) => {
            const {filterBy,pageLimit,search,currentPage} = action?.payload
            if (filterBy){
                state.filterBy = filterBy ;
            }
            if (pageLimit){
                state.pageLimit = pageLimit ;
            }
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
        });


    },
});

export default bookAppointmentSlice.reducer;
