import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {apiHeaders} from "../../lib/apiHeader";
import axios from "axios";
import apis from "../../lib/apis/apis";
import {getSlotsArrays} from "../../services/utills";
import toast from "react-hot-toast";
import {updateAttendanceFilters} from "../crm/attendance";
import {getAppointmentDetails} from "../crm/appointment";

export const getBookingServices = createAsyncThunk("getBookingServices", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_booking_services + `${data?.group}&treatment=${data?.treatment}${data?.service ?`&service=${data?.service}`:''}&provider_id=${data?.provider_id}`, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data;
            }

            return {};

        } catch (err) {
           return  err?.response?.data
        }
    }
);
export const getBookingPlans = createAsyncThunk("getBooingPlans", async (data, { dispatch }) => {
        try {

            const response = await axios.get(apis.get_booking_plans + `${data?.plan_slug}${data?.provider_id ?`&provider_id=${data?.provider_id}`:''}`, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data;
            }

            return {};

        } catch (err) {
           return  err?.response?.data
        }
    }
);
export const getPlanSchedules = createAsyncThunk('getPlanSchedules', async (data) => {
    try {
        const response = await axios.post(apis.get_booking_schedule, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success === 'true' && response?.data?.data){
            if (typeof response?.data?.data === 'object'){
                return  getSlotsArrays(Object.values(response?.data?.data))
            }else {
                return  getSlotsArrays(response?.data?.data)

            }
        }else {

            return  []
        }

    }catch (err){

        console.log(err)
        return []
    }
})
export const getBookingStatus = createAsyncThunk("getBookingStatus", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.booking_status , {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }else {
                return [];
            }

        } catch (err) {
            console.log(err?.response)
           return  []
        }
    }
);
export const getBooingHolidays = createAsyncThunk("getBooingHolidays", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_booking_holiday + `${data?.date}&provider_id=${data?.provider_id}${data?.staff_id ? `&staff_id=${data?.staff_id}`:''}`, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data;
            }

            return {};

        } catch (err) {
           return  err?.response?.data
        }
    }
);
export const getBookingSchedules = createAsyncThunk('getBookingSchedules', async (data) => {
    try {
        const response = await axios.post(apis.get_booking_schedule, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success === 'true' && response?.data?.data){
            if (typeof response?.data?.data === 'object'){
                return  getSlotsArrays(Object.values(response?.data?.data))
            }else {
                return  getSlotsArrays(response?.data?.data)

            }
        }else {

            return  []
        }

    }catch (err){

        console.log(err)
        return []
    }
})

export const findGuestUser = createAsyncThunk('findGuestUser', async (data) => {
    try {
        const response = await axios.post(apis.find_guest, {...data},{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){

        return err?.response?.data
    }
})
export const ApplyPromoCode = createAsyncThunk('ApplyPromoCode', async (data) => {
    try {
        const response = await axios.post(apis.promo_code_booking, {...data},{
            headers: apiHeaders()
        })
         console.log("reducer",response)
        if (response?.data?.success === 'true') {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.data?.errors);
        }

        return response?.data;

    } catch (err) {
        const errorMessage = err?.response?.data?.errors || 'An error occurred';
        toast.error(errorMessage);
        return thunkAPI.rejectWithValue(err?.response?.data);
    }
})
export const ApplyGiftCard = createAsyncThunk('ApplyGiftCard', async (data) => {
    try {
        const response = await axios.post(apis.Gift_card_booking, {...data},{
            headers: apiHeaders()
        })
         console.log("reducer",response)
        if (response?.data?.success === 'true') {
            toast.success(response?.data?.message);
        } else {
            toast.error(response?.data?.errors);
        }

        return response?.data;

    } catch (err) {
        const errorMessage = err?.response?.data?.errors || 'An error occurred';
        toast.error(errorMessage);
        return thunkAPI.rejectWithValue(err?.response?.data);
    }
})
// 

export const getUserBookings = createAsyncThunk('getUserBookings', async (data,{getState}) => {
    const {search,pageLimit,filterBy,currentPage} = getState()?.bookingWidgetStore
    try {
        const response = await axios.get(apis.my_booking+`${filterBy}&limit=${pageLimit}&page=${currentPage}${search!=='' ? `&search=${search}`:'' }`,{
            headers: apiHeaders()
        })

        if (response?.data?.success === 'true'){
            return  response?.data?.data
        }else {
            return []
        }



    }catch (err){

        console.log(err?.response)
        return []
    }
})
export const bookCustomerAppointment = createAsyncThunk('bookCustomerAppointment', async (data) => {
    let token = data?.token
    delete data['token']
    try {
        const response = await axios.post(apis.book_appointment, {...data},{
            headers:  {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })

        return  response?.data

    }catch (err){

        console.log(err)
        return err?.response?.data
    }
})
export const bookCustomerPlanAppointment = createAsyncThunk('bookCustomerPlanAppointment', async (data) => {
    let token = data?.token
    delete data['token']
    try {
        const response = await axios.post(apis.book_plan, {...data},{
            headers:  {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })

        return  response?.data

    }catch (err){

        console.log(err)
        return err?.response?.data
    }
})

export const cancelUserBooking = createAsyncThunk('cancelUserBooking', async (data,{dispatch}) => {
    try {
        let type = data?.type
        delete data["type"]
        const response = await axios.post(apis.cancel_booking, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success === 'true'){
            if (type){
                await dispatch(getUserBookings())
            }
            toast.success("Booking Cancel Successfully",{duration:3000})
            return  response?.data
        }
        return  response?.data
    }catch (err){

        console.log(err)
        return err?.response?.data
    }
})
export const editUserBooking = createAsyncThunk('editUserBooking', async (data,{dispatch}) => {
    try {
        let type = data?.type
        delete data["type"]
        const response = await axios.post(apis.edit_booking, {...data},{
            headers:apiHeaders()

        })

        if (response?.data?.success === 'true'){
            if (type){
                await dispatch(getUserBookings())
            }
            toast.success("Booking Reschedule Successfully",{duration:3000})
            return  response?.data
        }
        return  response?.data
    }catch (err){

        console.log(err)
        return err?.response?.data
    }
})
export const storeUserInvoice = createAsyncThunk('storeUserInvoice', async (data) => {
    if (data?.id){
        return data
    }else {
        return  {}
    }
})

export const getBookingDetails = createAsyncThunk("getBookingDetails", async (id, { dispatch }) => {
        try {
            const response = await axios.get(apis.my_booking_details+id, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }else {

                return [];
            }
        } catch (err) {

            return  []
        }
    }
);

export const updateBookingFilters = createAsyncThunk('updateBookingFilters', async (data) => {
    return data
})


export const bookingWidgetSlice = createSlice({
    name: "bookingWidgetSlice",
    initialState: {
        bookingPlans: [],
        holidays: [],
        bookingSlots:[],
        planBookingSlots:[],
        userBookings:[],
        userInvoice:{},
        bookingDetails:[],
        bookingStatus:[],
        search:'',
        promos:[],
        gifts:[],
        guestuser:{},
        pageLimit:10,
        filterBy:'All',
        currentPage:1,  
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBookingServices.fulfilled, (state, action) => {
            state.bookingServices = action.payload?.data ;
        });
        builder.addCase(getBooingHolidays.fulfilled, (state, action) => {
            state.holidays = action.payload?.data ;
        });
     
        builder.addCase(findGuestUser.fulfilled, (state, action) => {
            state.guestuser = action.payload?.data ;
        });

        // ApplyPromoCode
        builder.addCase(ApplyPromoCode.fulfilled, (state, action) => {
            state.promos = action.payload?.data ;
        });
        builder.addCase(ApplyGiftCard.fulfilled, (state, action) => {
            state.gifts = action.payload?.data ;
        });
        builder.addCase(getBookingSchedules.fulfilled, (state, action) => {
            state.bookingSlots = action.payload ;
        });
        builder.addCase(getUserBookings.fulfilled, (state, action) => {
            state.userBookings = action.payload ;
        });
        builder.addCase(storeUserInvoice.fulfilled, (state, action) => {
            state.userInvoice = action.payload ;
        });
        builder.addCase(getBookingStatus.fulfilled, (state, action) => {
            state.bookingStatus = action.payload ;
        });
        builder.addCase(getBookingDetails.fulfilled, (state, action) => {
            state.bookingDetails = action.payload ;
        });

    /********************* Plan **************************/
         builder.addCase(getBookingPlans.fulfilled, (state, action) => {
             state.bookingPlans = action.payload?.data?.data ;
         });
         builder.addCase(getPlanSchedules.fulfilled, (state, action) => {
            state.planBookingSlots = action.payload ;
         });

        /********************* Filter **************************/
        builder.addCase(updateBookingFilters.fulfilled, (state, action) => {
            const {filterBy,pageLimit,search,currentPage} = action?.payload
            if (filterBy){
                state.filterBy = filterBy ;
            }
            if (pageLimit){
                state.pageLimit = pageLimit ;
            }
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
        });
    },
});

export default bookingWidgetSlice.reducer;
