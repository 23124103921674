import {
    Box,
    TextField,
    Grid,
    TextareaAutosize,
    Button,
    Typography,
    Switch,
    FormControlLabel,
    Autocomplete,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody, FormHelperText, FormLabel, IconButton,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {useDispatch, useSelector} from "react-redux";
import {
    getAllBrands,
    getAllProductCategory,
    getAllVendors, getProductById,
    storeBrand, storeProduct,
    storeProductCategory, storeVendors, updateProduct
} from "../../../store/crm/inventory";
import {useLocation} from "react-router-dom";
import {useNavigate, useParams} from "react-router";
import useStyles from "./style";
import moment from "moment";
import Loader from "../../../components/loader/loader";
import {successConfirmation} from "../../../services/utills";
import {labelStyle} from "../../../components/form/formInput";
import {MdDelete} from "react-icons/md";
import {AiFillPlusCircle} from "react-icons/ai";
import Waveloading from "../../loading/Waveloading";



const AddProduct = () => {
    const classes = useStyles();
    const [brandLoading, setBrandLoading] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(true);
    const [vendorLoading, setVendorLoading] = useState(false);
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('0');
    const [productLoading, setProductLoading] = useState(false);


    const [categoryName, setCategoryName] = useState('');
    const [categoryStatus, setCategoryStatus] = useState('Active');
    const [categoryEdit, setCategoryEdit] = useState(null);

    const [brandName, setBrandName] = useState('');
    const [brandStatus, setBrandStatus] = useState('Active');
    const [brandEdit, setBrandEdit] = useState(null);

    const [vendorBrand, setVendorBrand] = useState(null);
    const [vendorName, setVendorName] = useState("");
    const [vendorStatus, setVendorStatus] = useState('Active');
    const [vendorEmail, setVendorEmail] = useState('');
    const [vendorLocation, setVendorLocation] = useState('');
    const [vendorPhone, setVendorPhone] = useState('');
    const [vendorErrors, setVendorErrors] = useState({});

    const [product, setProduct] = useState({
        name:'',
        description:'',
        category_id:null,
        brand_id:null,
        price:'',
        selling_price:'',
        member_sale_price:'',
        sku:'',
        stock_item:'',
        unit_value:'',
        product_image:'',
    });
    const [productErrors, setProductErrors] = useState({
        name:'',
        description:'',
        category_id:"",
        brand_id:"",
        price:'',
        selling_price:'',
        member_sale_price:'',
        sku:'',
        stock_item:'',
        unit_value:'',
    })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const {product_id} = useParams()
    const inventoryStore = useSelector(state => state?.inventoryStore)
    const {
        productCategories = [],
        productVendors = [],
        productBrands = [],
    } = inventoryStore


    useEffect(() => {
        (async () => {
            setCategoryLoading(true)
            await dispatch(getAllProductCategory())
            setCategoryLoading(false)

            setBrandLoading(true)
            await dispatch(getAllBrands())
            setBrandLoading(false)

            setVendorLoading(true)
            await dispatch(getAllVendors())
            setVendorLoading(false)

        })()
    }, [])
 

    useEffect(()=>{
        if (product_id){
            (async ()=>{
                let {payload} = await  dispatch(getProductById(product_id))
                if (payload?.id){
                    console.log(payload)
                    setProduct({
                        id:payload?.id,
                        name:payload?.name,
                        description:payload?.description,
                        category_id:payload?.category,
                        brand_id:payload?.brand,
                        price:payload?.price,
                        selling_price:payload?.selling_price,
                        member_sale_price:payload?.member_sale_price,
                        sku:payload?.sku,
                        stock_item:payload?.stock_item,
                        unit_value:payload?.unit_value,
                        product_image:payload?.product_image|| ""
                    })
                }
            })()
        }
    },[product_id])

    useEffect(() => {
        if (pathname.includes(`/crm/inventory/product/basic-info`)) {
            setActiveTab('0')
        } else if (pathname.includes(`/crm/inventory/product/pricing`)) {
            setActiveTab("1")
        } else if (pathname.includes(`/crm/inventory/product/inventory`)) {
            setActiveTab("2")
        } else if (pathname === `/crm/inventory/product/brand`) {
            setActiveTab("3")
        } else if (pathname === `/crm/inventory/product/category`) {
            setActiveTab("4")
        } else if (pathname === `/crm/inventory/product/vendor`) {
            setActiveTab("5")
        } else if (pathname.includes(`/crm/inventory/product/product-image`)) {
            setActiveTab("6")
        }
    }, [pathname])

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    console.log(product)
  


    const handleCategorySave = async () => {

        if (categoryName?.length > 2) {
            setCategoryLoading(true)
            let {payload} = await dispatch(storeProductCategory({name: categoryName, status: categoryStatus}))
            setCategoryLoading(false)
            if (payload?.success === 'true') {
                setCategoryName('')
                await successConfirmation('Success','Category Added Successfully')
            }


        }
    }
    const handleBrandSave = async () => {

        if (brandName?.length > 2) {
            setBrandLoading(true)
            let {payload} = await dispatch(storeBrand({name: brandName, status: brandStatus}))
            setBrandLoading(false)

            if (payload?.success === 'true') {
                setBrandName('')
                await successConfirmation('Success','Brand Added Successfully')
            }


        }
    }
 

    const handleVendorSave = async () => {
        let finalData = {
            name: vendorName,
            status: vendorStatus,
            email: vendorEmail,
            phone: vendorPhone,
            location: vendorLocation,
            brand_id: vendorBrand?.id,
        }
        setVendorLoading(true)
        let {payload} = await dispatch(storeVendors(finalData))
        setVendorLoading(false)
        if (payload?.success === 'true') {

            setVendorName('')
            setVendorEmail('')
            setVendorPhone('')
            setVendorLocation('')
            setVendorBrand(null)
            await successConfirmation('Success','Vendor Added Successfully')

        }else {
            setVendorErrors(payload?.data?.errors)
        }

    }
    const validateInventoryFields = (product) => {
        let errors = {};
        if (activeTab === '2') {        
            if (!product.sku) {
                errors.sku = "SKU is required";
            }
          
            if (!product.stock_item) {
                errors.stock_item = "Stock Item is required";
            }
            if (!product.unit_value) {
                errors.unit_value = "Unit Value is required";
            }
        }
        setProductErrors(errors);
        return Object.keys(errors).length === 0;
    }
      
    const handleSaveProduct = async () => {
        setProductLoading(true);
        const isValid = validateInventoryFields(product);
        if (isValid && product){
            const formData = new FormData();
            formData.append("brand_id", product?.brand_id?.id);
            formData.append("category_id", product?.category_id?.id);
            formData.append("name", product?.name);
            formData.append("description", product?.description);
            formData.append("selling_price", product?.selling_price);
            formData.append("member_sale_price", product?.member_sale_price);
            formData.append("price", product?.price);  
            formData.append("sku", product?.sku);
            formData.append("stock_item", product?.stock_item);
            formData.append("unit_value", product?.unit_value);
            if (product_id) {
                formData.append("id", product_id);
                const { payload } = await dispatch(updateProduct(formData));
                setProductLoading(false);
                if (payload?.success === 'true') {
                    setProduct({
                        name: '',
                        description: '',
                        category_id: null,
                        product_image: "",
                        brand_id: null,
                        price: '',
                        selling_price: '',
                        member_sale_price: '',
                        sku: '',
                        stock_item: '',
                        unit_value: '',
                    });
                    await successConfirmation('Success', 'Product Update Successfully');
                    navigate('/crm/inventory/product');
                } else {
                    setProductErrors(payload?.data?.errors);
                }
            } else {
                formData.append("product_image", product?.product_image);
                const { payload } = await dispatch(storeProduct(formData));
                setProductLoading(false);
                if (payload?.success === 'true') {
                    setProduct({
                        name: '',
                        description: '',
                        category_id: null,
                        product_image: "",
                        brand_id: null,
                        price: '',
                        selling_price: '',
                        member_sale_price: '',
                        sku: '',
                        stock_item: '',
                        unit_value: '',
                    });
                    await successConfirmation('Success', 'Product Added Successfully');
                    navigate('/crm/inventory/product');
                } else {
                    setProductErrors(payload?.data?.errors);
                }
            }
        } else {
            console.log("Validation failed");
            setProductLoading(false);
        }
    };
    

    const validateCurrentTab = () => {
        const errors = {};
        if (activeTab === '0') { // Basic Info Tab
            if (!product.name) { 
                errors.name = 'Name is required';
            }
            if (!product.category_id) {
                errors.category_id = 'Category is required';
            }
            if (!product.brand_id) {
                errors.brand_id = 'Brand Id is required';
            }
          
        }
           
        setProductErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const handleRedirect = (to) => {
        if (validateCurrentTab()) {
            navigate(to); // Only navigate if there are no validation errors
        }   
    } 
 
    return (   
        <Box
            sx={{ 
                minHeight: "calc(100vh - 109px)",
                background: "#fff",    
                padding: {sm:"24px", xs:'8px'},
                borderRadius: "7px",
            }}
        >
            <TabContext value={activeTab}>
                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example"
                    sx={{   '@media screen and (max-width: 850px)': {
                        '.MuiTabs-scroller': {
                          overflow: 'scroll !important',
                        },
                      }, }}
                    >
                        <Tab
                            label="Basic Info"
                            value={"0"}
                            onClick={() => handleRedirect(`/crm/inventory/product/basic-info${product_id ?`/${product_id}` :""}`)}
                        />
                        <Tab
                            label="Product Image"
                            value={"6"}
                            onClick={() => handleRedirect(`/crm/inventory/product/product-image${product_id ?`/${product_id}` :""}`)}
                        />
                        <Tab label="Pricing" value={"1"}
                             onClick={() => handleRedirect(`/crm/inventory/product/pricing${product_id ?`/${product_id}` :""}`)}/>
                        <Tab label="Inventory" value={"2"}
                             onClick={() => handleRedirect(`/crm/inventory/product/inventory${product_id ?`/${product_id}` :""}`)}/>
                        <Tab label="Brand" value={"3"} onClick={() => handleRedirect('/crm/inventory/product/brand')}/>
                        <Tab label="Category" value={"4"}
                             onClick={() => handleRedirect('/crm/inventory/product/category')}/>
                        <Tab label="Vendor" value={"5"}
                             onClick={() => handleRedirect('/crm/inventory/product/vendor')}/>
                    </TabList>
                </Box>
                <TabPanel value={"0"} sx={{ px:0 }}>
                    {productLoading ? <Waveloading fullSize/> :""}
                    <Grid container sx={{ display:'flex', flexDirection:'column' }}>
             <Grid item lg={8}>
            <TextField 
                label="Name"
                variant="outlined"
                sx={{width: "100%", mt:2}}
                value={product?.name} 
                onChange={(e) => setProduct({...product, name: e.target.value})}
                error={!!productErrors.name}
                helperText={productErrors.name}
            />
                            <TextField
                                label="Description"
                                variant="outlined"
                                sx={{width: "100%",mt:4}} 
                                multiline
                                minRows={3}
                                value={product?.description}
                                onChange={(e) => setProduct({...product , description:e?.target?.value})}
                            />


                            {/* <Autocomplete
                                sx={{
                                    mt: 4
                                }}
                                options={productCategories}
                                onFocus={()=>{
                                    setProductErrors({...vendorErrors,category_id:''})
                                }}
                                value={product?.category_id}
                                disableCloseOnSelect={false}
                                isOptionEqualToValue={(option, value) => {
                                    return option?.id === value?.id
                                }}
                                getOptionLabel={(option) => option?.name || ''}
                                renderInput={(params) => {
                                    const {inputProps, helperText, error, ...rest} = params;
                                    return (
                                        <TextField
                                            {...rest}
                                            inputProps={{...inputProps}}
                                            label="Category"
                                        />
                                    )
                                }}
                                onChange={(event, newValue) => {
                                    setProduct({...product,category_id:newValue});
                                }}
                                renderOption={(props, option, {selected}) => (
                                    <MenuItem  {...props} key={option?.id}>
                                        <Checkbox
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option?.name}
                                    </MenuItem>
                                )}
                            /> */}
                            {/* {productErrors?.category_id &&
                                <FormHelperText
                                    sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                                >
                                    {productErrors?.category_id}
                                </FormHelperText>
                            }


                            <Autocomplete
                                sx={{
                                    mt: 4
                                }}
                                options={productBrands}
                                onFocus={()=>{
                                    setProductErrors({...vendorErrors,brand_id:''})
                                }}
                                value={product?.brand_id}
                                disableCloseOnSelect={false}
                                isOptionEqualToValue={(option, value) => {
                                    return option?.id === value?.id
                                }}
                                getOptionLabel={(option) => option?.name || ''}
                                renderInput={(params) => {
                                    const {inputProps, helperText, error, ...rest} = params;
                                    return (
                                        <TextField
                                            {...rest}
                                            inputProps={{...inputProps}}
                                            label="Brand"
                                            error={!!productErrors.category_id}
                                            helperText={productErrors.category_id || ''}
                                        />
                                    )
                                }}
                                onChange={(event, newValue) => {
                                    setProduct({...product,brand_id:newValue});
                                }}
                                renderOption={(props, option, {selected}) => (
                                    <MenuItem  {...props} key={option?.id}>
                                        <Checkbox
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option?.name}
                                    </MenuItem>
                                )}
                            />
                            {productErrors?.brand_id &&
                                <FormHelperText
                                    sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                                >
                                    {productErrors?.brand_id}
                                </FormHelperText>
                            } */}
 <Autocomplete
    sx={{ mt: 4 }}
    options={productCategories}
    onFocus={() => {
        setProductErrors({...productErrors, category_id: ''}) 
    }} 
    value={product?.category_id}
    disableCloseOnSelect={false}
    isOptionEqualToValue={(option, value) => option?.id === value?.id}
    getOptionLabel={(option) => option?.name || ''}
    onChange={(event, newValue) => {
        setProduct({...product, category_id: newValue});
    }}
    renderInput={(params) => {
        return (
            <TextField
                {...params}
                label="Category"
                error={!!productErrors.category_id}
                helperText={productErrors.category_id || ''}
            />
        )
    }}
    renderOption={(props, option, {selected}) => (
        <MenuItem {...props} key={option?.id}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option?.name}
        </MenuItem>
    )}
/>

<Autocomplete
    sx={{ mt: 4 }}
    options={productBrands}
    onFocus={() => {
        setProductErrors({...productErrors, brand_id: ''}) 
    }}
    value={product?.brand_id}
    disableCloseOnSelect={false}
    isOptionEqualToValue={(option, value) => option?.id === value?.id}
    getOptionLabel={(option) => option?.name || ''}
    onChange={(event, newValue) => {
        setProduct({...product, brand_id: newValue});
    }}
    renderInput={(params) => {
        return (
            <TextField   
                {...params}
                label="Brand"
                error={!!productErrors.brand_id}
                helperText={productErrors.brand_id || ''}
            />
        )
    }}
    renderOption={(props, option, {selected}) => (
        <MenuItem {...props} key={option?.id}>
            <Checkbox style={{ marginRight: 8 }} checked={selected} />
            {option?.name}
        </MenuItem>
    )}
/>


                        </Grid>
                        <Grid
                            item
                            lg={12}
                            sx={{
                                mt: 4,
                                display:'flex',
                                justifyContent:'flex-end'
                            }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    px:'30px',
                                }}
                                onClick={() => handleRedirect(`/crm/inventory/product/product-image${product_id ?`/${product_id}` :""}`)}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={'1'} sx={{ px:0 }}>
                    <Grid container>
                        <Grid item sm={8} xs={12}>
                            <TextField
                                label="Product Price"
                                variant="outlined"   
                                sx={{width: "100%", mt: 2}}
                                value={product?.price}            
                                onChange={(e) => setProduct({...product , price:e?.target?.value})}
                            />
                            <TextField   
                                label="Sale Price"
                                variant="outlined"
                                sx={{width: "100%", mt: 4}}
                                value={product?.selling_price}
                                onChange={(e) => setProduct({...product , selling_price:e?.target?.value})}
                            />
                            <TextField
                                label="Member Sale Price"
                                variant="outlined"
                                sx={{width: "100%", mt: 4}}
                                value={product?.member_sale_price}
                                onChange={(e) => setProduct({...product , member_sale_price:e?.target?.value})}
                            />
                        </Grid>
                        <Grid
                            item
                            sm={8}
                            xs={12}
                            sx={{
                                mt: 4,
                                display:'flex',
                                justifyContent:'space-between'
                            }}>
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{
                                    px:'30px',
                                }}
                                onClick={() => handleRedirect(`/crm/inventory/product/product-image${product_id ?`/${product_id}` :""}`)}
                            >
                                Back
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    px:'30px',
                                }}
                                onClick={() => handleRedirect(`/crm/inventory/product/inventory${product_id ?`/${product_id}` :""}`)}
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={'6'} sx={{ px:0 }}>
                    <Grid container>
                        <Grid item lg={8}>
                            <FormControl fullWidth sx={{mb: "16px"}}>
                                {/*<FormLabel sx={{...labelStyle, mb: "5px"}}>{label}</FormLabel>*/}
                                <Box onClick={() => {
                                    if (mouseEnter) {
                                        document.getElementById("product-image").click()
                                    }
                                }}
                                     sx={{
                                         height: "200px",
                                         width: "200px",
                                         backgroundColor: "#edf2f7",
                                         border: '1px solid #EDF2F7',
                                         display: "flex",
                                         borderRadius: '7px',
                                         alignItems: "center",
                                         justifyContent: "center",
                                         cursor: "pointer",
                                         position: "relative",
                                         "img": {
                                             borderRadius: '7px',
                                         },
                                     }}
                                >
                                    <IconButton
                                        sx={{
                                            fontSize: "1.5rem",
                                            position: "absolute",
                                            top: "5px",
                                            right: "6px",
                                        }}
                                        onMouseEnter={() => setMouseEnter(false)}
                                        onMouseLeave={() => setMouseEnter(true)}
                                        onClick={() => {
                                            setProduct({...product,product_image: null})
                                        }}
                                    >
                                        <MdDelete color={"red"}/>
                                    </IconButton>
                                    <input
                                        type="file"
                                        id={'product-image'}
                                        accept="image/*"
                                        hidden
                                        onChange={(event) => {
                                            setProduct({...product,product_image: event.currentTarget.files[0]})
                                            event.target.value = ''
                                        }}
                                    />
                                    {product?.product_image !== "" &&
                                        <img
                                            src={typeof product?.product_image === 'string' ? product?.product_image : URL.createObjectURL(product?.product_image)}
                                            width={'100%'}
                                            height={'100%'}
                                            alt={'logo'}
                                        />
                                    }
                                    {product?.product_image === "" && <Box sx={{textAlign: 'center'}}>
                                        <AiFillPlusCircle type="file" color={"blue"} fontSize={"2rem"}/>
                                        <Typography variant="body1">Upload Photo</Typography>
                                    </Box>}
                                </Box>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                           xs={12}
                            sx={{
                                mt: 4,
                                display:'flex',
                                justifyContent:'space-between'
                            }}>
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{
                                    px:'30px',
                                }}
                                onClick={() => handleRedirect(`/crm/inventory/product/basic-info${product_id ?`/${product_id}` :""}`)}
                            >
                                Back
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    px:'30px',
                                }}
                                onClick={() => handleRedirect(`/crm/inventory/product/pricing${product_id ?`/${product_id}` :""}`)}
                            >
                                Next
                            </Button>  
                        </Grid>  
                    </Grid>    
                </TabPanel>
                <TabPanel value={'2'} sx={{ px:0 }}>
                {productLoading ? <Waveloading fullSize/> :""}
                    <Grid container>
                        <Grid item lg={8}>
                        <TextField
    label="SKU"
    variant="outlined"
    sx={{width: "100%", mt: 2}}
    value={product?.sku}
    // error={!!productErrors?.sku}
    // helperText={productErrors?.sku || ''}
    onChange={(e) => setProduct({...product , sku:e?.target?.value})}
/>
{productErrors?.sku && (
    <Typography variant="body2" color="error">
        {productErrors.sku}
    </Typography>
)}

<TextField
    label="Stock Item" 
    variant="outlined"
    sx={{width: "100%", mt: 4}}
    value={product?.stock_item}
    // error={!!productErrors?.stock_item}
    // helperText={productErrors?.stock_item || ''}
    onChange={(e) => setProduct({...product , stock_item:e?.target?.value})}
/>
{productErrors?.stock_item && (
    <Typography variant="body2" color="error">
        {productErrors.stock_item}
    </Typography>
)}

<TextField
    label="Unit Value"
    variant="outlined"
    sx={{width: "100%", mt: 4}}
    value={product?.unit_value}
    // error={!!productErrors?.unit_value}
    // helperText={productErrors?.unit_value || ''}
    onChange={(e) => setProduct({...product , unit_value:e?.target?.value})}
/>
{productErrors?.unit_value && (
    <Typography variant="body2" color="error">
        {productErrors.unit_value}
    </Typography>
)}

                            
                        </Grid>
                        <Grid 
                            item
                            md={8}
                            xs={12}
                            sx={{
                                mt: 4,
                                display:'flex',
                                
                                justifyContent:'space-between',
                                '@media (max-width:600px)':{
                                    flexWrap:'wrap'
                                }
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{
                                    px:{xs:'20px',sm:'30px'},
                                    mb:2,
                                    '@media (max-width:600px)':{
                                        fontSize:'0.675rem',
                                        height:'fit-content'
                                    }
                                }}
                                onClick={() => handleRedirect(`/crm/inventory/product/pricing${product_id ?`/${product_id}` :""}`)}
                            >
                                Back
                            </Button>


                            <Box sx={{ display:'flex' }}>
                                {product_id &&
                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{
                                        mr:'10px',
                                        mb:2,
                                        '@media (max-width:600px)':{
                                            fontSize:'0.675rem',
                                            height:'fit-conten t'
                                        }
                                    }}
                                    onClick={() => handleRedirect(`/crm/inventory/product`)}
                                >
                                    Cancel Updating
                                </Button>
                                }

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => { 
                                      
                                            handleSaveProduct();
                                      
                                    }}
                                    sx={{  mb:2,   '@media (max-width:600px)':{
                                        fontSize:'0.675rem',
                                        height:'fit-content'  
                                    } }}
                                >
                                    {product_id ? "Update" : 'Save'} Product
                                </Button>
                            </Box>

                        </Grid>
                        
                    </Grid>   
                </TabPanel>

                {/* Brand */}
                <TabPanel value={'3'} sx={{ px:0 }}>
                    <form>
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Grid item lg={4.5} md={4.5} sm={5.9} xs={12}>
                                <TextField
                                    label="Brand Name"
                                    variant="outlined"
                                    sx={{width: "100%", mt: 2}}
                                    value={brandName}
                                    onChange={(e) => setBrandName(e?.target?.value)}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                        form: {
                                            autoComplete: 'off',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item lg={1.5} md={1.5} sm={1.9} xs={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={brandStatus === 'Active'}
                                            onChange={(e) => {
                                                setBrandStatus(e?.target?.checked ? 'Active' : 'In-Active')
                                            }}
                                        />
                                    }
                                    label="Status"
                                />
                            </Grid>
                            <Grid item lg={5.1} md={5.1} xs={12} sx={{mt: 2}}>
                                {brandEdit &&
                                    <Button
                                        sx={{
                                            mr:"20px",
                                        }}
                                        variant="outlined"
                                        color="error"
                                        onClick={() => {
                                            setBrandEdit(null)
                                            setBrandStatus("Active")
                                            setBrandName("")
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                }
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleBrandSave()}
                                >
                                    {brandEdit ? "Update"  :"Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                    <Typography variant="h5" color="initial" sx={{mt: 5}}>
                        Brand List
                    </Typography>
                    {brandLoading ? <Waveloading/> :
                        <TableContainer component={Paper} sx={{mt: 5, maxHeight: "650px"}}>
                            <Table sx={{}} aria-label="simple table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            <Checkbox/>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            ID
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            Brand Name
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            Brand Item
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Date
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Status
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {productBrands?.length > 0 ?
                                        productBrands.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                                className={classes.odd}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.tableLink}
                                                    sx={{cursor: "pointer"}}
                                                >
                                                    <Checkbox/>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.tableLink}
                                                    sx={{cursor: "pointer"}}
                                                >
                                                    {row?.id}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Typography variant="body1">{row?.name}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography variant="body1">
                                                        {row?.products_counts}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">{moment(row?.created_at).format('YYYY-MM-DD')}</TableCell>
                                                <TableCell align="left">{row?.status}</TableCell>
                                                <TableCell align="left">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={()=>{
                                                            setBrandEdit(row?.id)
                                                            setBrandStatus(row?.status)
                                                            setBrandName(row?.name)
                                                        }}
                                                    >
                                                        Edit 
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )) :
                                        <TableRow>
                                            <TableCell
                                                colSpan={'5'}
                                                align={'center'}
                                                sx={{
                                                    height: '300px',
                                                }}
                                            >
                                                No Category Brand List Available
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </TabPanel>
                <TabPanel value={'4'} sx={{ px:0 }}>
                    <Typography variant="h5" color="initial" sx={{mt: 5}}>
                        Add Categories
                    </Typography>
                    <form>
                        <Grid
                            container
                            sx={{
                                display: "flex", 
                                justifyContent: "space-between",  
                                alignItems: "center",
                            }}
                        >
                            <Grid item lg={4.5} md={4.5} sm={5.9} xs={12}>
                                <TextField
                                    label="Category Name"
                                    variant="outlined"
                                    sx={{width: "100%", mt: 2}}
                                    value={categoryName}
                                    onChange={(e) => setCategoryName(e?.target?.value)}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                        form: {
                                            autoComplete: 'off',
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item lg={1.5} md={1.5} sm={1.9} xs={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={categoryStatus === 'Active'}
                                            onChange={(e) => {
                                                setCategoryStatus(e?.target?.checked ? 'Active' : 'In-Active')
                                            }}
                                        />

                                    }
                                    label="Status"
                                />
                            </Grid>
                            <Grid item lg={5.1} md={5.1} xs={12} sx={{mt: 2}}>
                                {categoryEdit &&
                                    <Button
                                        sx={{
                                            mr:"20px",
                                        }}
                                        variant="outlined"
                                        color="error"
                                        onClick={() => {
                                            setCategoryEdit(null)
                                            setCategoryStatus("Active")
                                            setCategoryName("")
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                }
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleCategorySave()}
                                >
                                    {categoryEdit ? "Update"  : "Save"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                    <Typography variant="h5" color="initial" sx={{mt: 5}}>
                        Categories List
                    </Typography>
                    {categoryLoading ? <Waveloading/> :
                        <TableContainer component={Paper} sx={{mt: 5, maxHeight: "650px"}}>
                            <Table sx={{}} aria-label="simple table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            <Checkbox/>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            ID
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            Category Name
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            Category Item
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Date
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Status
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {}
                                <TableBody>
                                    {productCategories?.length > 0 ?
                                        productCategories.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                                className={classes.odd}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.tableLink}
                                                    sx={{cursor: "pointer"}}
                                                >
                                                    <Checkbox/>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.tableLink}
                                                    sx={{cursor: "pointer"}}
                                                >
                                                    {row.id}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Typography variant="body1">{row?.name}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography variant="body1">
                                                        {row?.products_counts}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    align="left">{moment(row?.created_at).format('YYYY-MM-DD')}</TableCell>
                                                <TableCell align="left">{row?.status}</TableCell>
                                                <TableCell align="left">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={()=>{
                                                            setCategoryEdit(row?.id)
                                                            setCategoryStatus(row?.status)
                                                            setCategoryName(row?.name)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )) :
                                        <TableRow>
                                            <TableCell
                                                colSpan={'5'}
                                                align={'center'}
                                                sx={{
                                                    height: '300px',
                                                }}
                                            >No Product Category List Available</TableCell>
                                        </TableRow>

                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </TabPanel>
                {/* Vendor */}
                <TabPanel value={'5'} sx={{ px:0 }}>
                    <Typography variant="h5" color="initial" sx={{mt: 5}}>
                        Add Vendor
                    </Typography>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Grid item lg={3.9} md={5.5} sm={5.9} xs={12}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                sx={{width: "100%", mt: 2}}
                                value={vendorName}
                                onFocus={()=>{
                                    setVendorErrors({...vendorErrors,name:''})
                                }}
                                onChange={event => setVendorName(event?.target?.value)}
                            />
                            {vendorErrors?.name &&
                                <FormHelperText
                                    sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                                >
                                    {vendorErrors?.name}
                                </FormHelperText>
                            }
                        </Grid>
                        <Grid item lg={3.9} md={5.9} sm={5.9} xs={12}>
                            <TextField
                                label="Phone No"
                                variant="outlined"
                                sx={{width: "100%", mt: 2}}
                                value={vendorPhone}
                                onFocus={()=>{
                                    setVendorErrors({...vendorErrors,phone:''})
                                }}
                                onChange={event => setVendorPhone(event?.target?.value)}
                            />
                            {vendorErrors?.phone &&
                                <FormHelperText
                                    sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                                >
                                    {vendorErrors?.phone}
                                </FormHelperText>
                            }
                        </Grid>
                        <Grid item lg={3.9} md={5.9} xs={5.9} sx={{mt: 2}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={vendorStatus === 'Active'}
                                        onChange={(e) => {
                                            setVendorStatus(e?.target?.checked ? 'Active' : 'In-Active')
                                        }}
                                    />
                                }
                                label="Status"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{display: "flex", justifyContent: "space-between"}}
                    >
                        <Grid item lg={3.9} md={5.5} sm={5.9} xs={12}>
                            <TextField
                                label="Email"
                                variant="outlined"
                                sx={{width: "100%", mt: 2}}
                                value={vendorEmail}
                                onFocus={()=>{
                                    setVendorErrors({...vendorErrors,email:''})
                                }}
                                onChange={event => setVendorEmail(event?.target?.value)}
                            />
                            {vendorErrors?.email &&
                                <FormHelperText
                                    sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                                >
                                    {vendorErrors?.email}
                                </FormHelperText>
                            }
                        </Grid>
                        <Grid item lg={3.9} md={5.5} sm={5.9} xs={12}>
                            <TextField
                                label="Location"
                                variant="outlined"
                                sx={{width: "100%", mt: 2}}
                                value={vendorLocation}
                                onFocus={()=>{
                                    setVendorErrors({...vendorErrors,location:''})
                                }}
                                onChange={event => setVendorLocation(event?.target?.value)}
                            />
                            {vendorErrors?.location &&
                                <FormHelperText
                                    sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                                >
                                    {vendorErrors?.location}
                                </FormHelperText>
                            }
                        </Grid>
                        <Grid item lg={3.9} md={5.5} sm={5.9} xs={12}>
                            <Autocomplete
                                sx={{
                                    mt: 2
                                }}
                                options={productBrands}
                                onFocus={()=>{
                                    setVendorErrors({...vendorErrors,brand_id:''})
                                }}
                                value={vendorBrand}
                                disableCloseOnSelect={false}
                                isOptionEqualToValue={(option, value) => {
                                    return option?.id === value?.id
                                }}
                                getOptionLabel={(option) => option?.name || ''}
                                renderInput={(params) => {
                                    const {inputProps, helperText, error, ...rest} = params;
                                    return (
                                        <TextField
                                            {...rest}
                                            inputProps={{...inputProps}}
                                            label="Brand"
                                        />
                                    )
                                }}
                                onChange={(event, newValue) => {
                                    setVendorBrand(newValue);
                                }}
                                renderOption={(props, option, {selected}) => (
                                    <MenuItem  {...props} key={option?.id}>
                                        <Checkbox
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option?.name}
                                    </MenuItem>
                                )}
                            />
                            {vendorErrors?.brand_id &&
                                <FormHelperText
                                    sx={{color: '#CB3838', width: 'max-content', mx: 0}}
                                >
                                {vendorErrors?.brand_id}
                            </FormHelperText>
                            }
                        </Grid>
                    </Grid>
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{mt: 4}}
                        onClick={() => handleVendorSave()}
                    >
                        Save
                    </Button>
                    <Typography variant="h1" color="initial"></Typography>
                    <Typography variant="h5" color="initial" sx={{mt: 5}}>
                        Vendor List
                    </Typography>
                    {vendorLoading ? <Waveloading/> :
                        <TableContainer component={Paper} sx={{mt: 5, maxHeight: "650px"}}>
                            <Table sx={{}} aria-label="simple table">
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                                width:'70px',
                                            }}
                                        >
                                            <Checkbox/>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            ID
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            Supplier Name
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                fontWeight: "700",
                                                borderBottom: 0,
                                            }}
                                        >
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Phone No
               
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Location
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Brand
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Status
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{fontWeight: "700", borderBottom: 0}}
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {productVendors?.length > 0 ?
                                        productVendors.map((row, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                                className={classes.odd}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.tableLink}
                                                    sx={{cursor: "pointer"}}
                                                >
                                                    <Checkbox/>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    className={classes.tableLink}
                                                    sx={{cursor: "pointer"}}
                                                >
                                                    {row.id}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Typography variant="body1">{row.name}</Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography variant="body1">
                                                        {row.email}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">{row.phone}</TableCell>
                                                <TableCell align="left">{row.location}</TableCell>
                                                <TableCell align="left">{row.brand?.name}</TableCell>
                                                <TableCell align="left">{row.status}</TableCell>
                                                <TableCell align="left">
                                                    <Button variant="contained" color="primary">
                                                        Edit 
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )) :
                                        <TableRow>
                                            <TableCell
                                                colSpan={'9'}
                                                align={'center'}
                                                sx={{
                                                    height: '300px',
                                                }}
                                            >
                                                No Product Vendor List Available
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default AddProduct;
