import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders} from "../../../../lib/apiHeader";


export const getAllClinicsTreatment = createAsyncThunk('getAllClinicsTreatment', async (provider_id,{dispatch}) => {
    try {

        const response = await axios.get(apis.get_clinics_treatment+`${provider_id}?page=1&limit=100`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})



export const storeClinicsTreatment = createAsyncThunk('storeClinicsTreatment', async (data) => {
    try {
        const response = await axios.post(apis.store_clinics_treatment, {...data},{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const getClinicsTreatmentByID = createAsyncThunk('getClinicsTreatmentByID', async (id) => {
    try {
        const response = await axios.get(apis.get_clinics_treatment_by_id+id,{
            headers: apiHeaders()
        })
        console.log(response?.data)
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

export const updateClinicsTreatment = createAsyncThunk('updateClinicsTreatment', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_clinics_treatment, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success==='true'){
            await dispatch(getAllClinicsTreatment(data?.provider_id))

            return  response.data
        }else {
            return response?.data
        }
    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){
            return {
                data :err?.response,
                errors:true
            }
        }
    }
})
export const deleteClinicsTreatment = createAsyncThunk('deleteClinicsTreatment', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_clinics_treatment+data?.id,{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllClinicsTreatment(data?.provider_id))
            toast.success("Role Deleted Successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})

export const clinicsTreatmentSlice = createSlice({
    name: 'clinicsTreatmentSlice',
    initialState: {
        treatments: [],
        meta: {},
        links: {},
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllClinicsTreatment.fulfilled, (state, action) => {
            state.treatments = action.payload.data
            state.meta = action.payload.meta
            state.links = action.payload.links
        })
    }
})


export default clinicsTreatmentSlice.reducer