import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, Divider, Grid, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { MySwal, getBorderColor } from "../../../../../services/utills";
import moment from "moment/moment";
import { useLocation, useNavigate, useParams } from "react-router";
import { box, boxwrapper, content } from "../../../../../components/card-appointment/CardAppointment";
import SetWalletModal from "../../../../../components/modal/SetWallet";
import SetEmailModal from "../../../../../components/modal/SetEmailModal";
import MakeMemberModal from "../../../crmpage/crmMembership/MakeMemberModal";
import CustomButtoncolor from "../../../../../components/custom-button/customColorButton";
import TreatmentFormButton from "../../../../../components/card-appointment/treatmentForm";
import { btndesign, crmButton } from "../../../crmpage/CRMPage";
import { useDispatch, useSelector } from "react-redux";
import AppointmentReschedule from "../../../../../components/card-appointment/AppointmentReschedule";
import AppointmentStaffStatus from "../../AppointmentStaffStatus/appointmentStaffStatus";
import DoxyModal from "../../../../../components/modal/DoxyModal";
import { getAppointmentHistory } from "../../../../../store/crm/appointment";
import Waveloading from "../../../../loading/Waveloading";
import NoteModal from "../../../../../components/modal/NoteModal";
import { getCrmNotes } from "../../../../../store/crm/crm/crm-details";
const commonButtonStyle = {
    minWidth: "107px",
    width: '98%', // Assuming you want to fill the container
    padding: "8px 15px",
    fontSize: "16px",
};
export const buttonStyle = {
    padding: '10px 8px',
    // Add other styles here
};
export const stylebtn = {
    borderRadius: "4px",
    padding: '10px 14px',
    height: 'fit-content',
    '@media (max-width: 450px)': {
        fontSize: '18px',
        width: '100%'
    },
};
export const mySwalButton = {
    backgroundColor: "#fff",
    minWidth: "max-content",
    border: "1px solid #666666",
    borderRadius: '10px',
    fontSize: '16px',
    px: '20px',
    mx: '10px',
    color: "#666666",
    "&:hover": {
        backgroundColor: "#fff",
        border: "1px solid #666666",
    },
}
export const handleEditReschedule = async (redirect, appointment) => {
    let confirm = await MySwal.fire({
        icon: 'question',
        title: 'Confirmation',
        showConfirmButton: false,
        html: <Box sx={{ mb: 4 }}>
            <Typography sx={{}}>
                Are you sure? You want to Edit this appointment .
            </Typography>
            <Box sx={{ mt: 3, }}>
                <Button
                    sx={{ ...mySwalButton }}
                    onClick={() => MySwal.clickConfirm()}
                >
                    Yes
                </Button>
                <Button
                    sx={{ ...mySwalButton }}
                    onClick={() => MySwal.close()}
                >
                    No
                </Button>
            </Box>
        </Box>
    })
    if (!!confirm?.isConfirmed) {
        let givenDate = moment(appointment?.date);
        let today = moment(new Date());
        /*
              if (givenDate.isSameOrAfter(today, 'day')) {
        */
        redirect(`/crm/appointment/reschedule/edit/${appointment?.id}`)
        /* } else {
           await successConfirmation("Warning", 'Booking date is over Due.', 'warning')
         }*/
    }
}
const Appointment = ({  appointmentHistory,onShowParent }) => {
    const location = useLocation();
    const [appointmentLoading, setAppointmentLoading] = useState(false)
    const dispatch = useDispatch();
    const crmStore = useSelector((state) => state?.crmStore); 
    console.log("data comming ",appointmentHistory )
   
    const navigate = useNavigate()
  
    const handleAppointmentClick = (url) => {
        navigate(url) 
    }   
    useEffect(()=>{
        (async ()=>{
            await dispatch(getCrmNotes(appointmentHistory?.customer?.id))
            // console.log("calenderDetails",customer_id)
        })() 
    },[])
    const { pathname } = useLocation();     
    const { user_id } = useParams();
    const redirect = (to) => {
        navigate(to);
    };
    console.log("data comming from",appointmentHistory)
  
    return (
        <Box>
              {appointmentLoading ? (
               <Waveloading fullSize />
            ) : (
            appointmentHistory?.length > 0 ?
                <Timeline
                    sx={{
                        p: "0", 
                        my: "0",
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        }, 
                    }}
                    position="right"
                >
                    {appointmentHistory?.length > 0 &&
                        appointmentHistory.map((appointment, index) => (
                            <TimelineItem sx={{ minHeight: "200px", }} key={index}>
                                <TimelineSeparator sx={{ display: { xs: 'none', sm: 'flex' }, }}>
                                    <TimelineConnector
                                        sx={{
                                            background: "#2F80ED",
                                            width: "16px",
                                            borderRadius: "10px",
                                            border: "6px solid #fff",  
                                            maxHeight: "50px",
                                            mb: "-10px",
                                            borderTop: index === 0 ? "6px solid #fff" : "0",
                                            borderTopLeftRadius: index === 0 ? '10px' : "0",
                                            borderTopRightRadius: index === 0 ? '10px' : "0",
                                        }}
                                    />
                                    <TimelineDot
                                        variant="outlined" 
                                        sx={{
                                            mx: "auto",
                                            my: "0",
                                            borderWidth: "5px",
                                            borderColor: getBorderColor(appointment?.status),
                                            zIndex: "10",
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            height: "75px",
                                            width: "90px",
                                            mt: "-8px",
                                            background: "#fff",
                                            borderRadius: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            if (appointment?.id) {
                                                handleAppointmentClick(`/crm/appointment/details/${appointment.id}`);
                                            }
                                            onShowParent();
                                        }}

                                    >
                                        <Typography sx={{ fontSize: "13px" }}>Booking No</Typography>
                                        <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                                            {appointment?.id}
                                        </Typography>
                                    </Box>
                                    <TimelineConnector
                                        sx={{
                                            background: "#2F80ED",
                                            width: "16px",
                                            borderRadius: "10px",
                                            border: "6px solid #fff",
                                            borderBottomLeftRadius: "0",
                                            borderBottomRightRadius: "0",
                                            borderBottom: '0',
                                            mt: "-7px",
                                        }}
                                    />
                                </TimelineSeparator>
                                <TimelineContent sx={{pl: {xs:"0px",sm:"32px"}, pb:{xs:"8px",sm:"32px"}, pt: 0, pr: 0}}>
                                    <Box
                                        sx={{
                                            p: {xs:"12px",sm:"24px"},

                                            background: "#fff",
                                            height: "100%",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <Grid
                                            container
                                            lg={12}
                                            sx={{
                                                display: "flex",
                                                gap: "5px",
                                                rowGap: '10px',
                                                alignItems: "center",
                                                mt: "34px",
                                                mb: { sm: '34px', xs: '5px' }, 
                                                flexWrap: "wrap",
                                            }}
                                        >

                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt:2 }}>
                                                <Grid container sx={{ display:'flex', gap:'4px' }} >
                                                       {/* {appointment?.status !== 'Void' && appointment?.status !== 'Canceled' && appointment?.status !== 'Completed' && */}
                                       <Grid item lg={3.9} xl={2} sm={5.8} md={5.8} xs={5.8}>
                                                        <CustomButtoncolor
                                                            sx={{ crmButton }}
                                                            mainColor={"#09ABF1"}
                                                            size="md"
                                                            val="Book Now"             
                                                            handleClick={() => {
                                                                localStorage.setItem("url", pathname);
                                                                redirect(
                                                                    `/crm/add-appointment/user/${appointment?.customer?.id}`
                                                                );
                                                            }}
                                                            style={crmButton}
                                                        />
                                        </Grid>
                                        {(appointment?.status === 'Pending' ) && appointment?.date &&
                        <Grid item lg={1.7} xl={1.2} sm={3.8} md={2.8} xs={5.8}>

                            <AppointmentStaffStatus    
                               isAppointment
                                label={"Confirm"}
                                value={'Confirmed'}
                                mainColor={"#323681"}
                                style={buttonStyle}
                                // fetchAppointment={fetchAppointment}
                                appointment={appointment}
                                fwidth={'100%'}
                                fsize={'13px'}  
                            />
                        </Grid>
                    }
                
               
 
                                                   

                                                    {(appointment?.status === 'Pending' || appointment?.status === 'Confirmed' || appointment?.status === 'No Show') && appointment?.date &&
                                                        <Grid item lg={3.9} xl={2} sm={5.9} md={5.9} xs={5.8}>

                                                            <AppointmentStaffStatus     
                                                                isAppointment
                                                                label={"Check-In"} 
                                                                value={'CheckIn'} 
                                                                style={stylebtn}
                                                                //   fetchAppointment={fetchAppointment}
                                                                appointment={appointment}
                                                              

                                                                fwidth={'100%'}   
                                                                fsize={'13px'} 
                                                            />
                                                        </Grid>
                                                    }
                                                    {appointment?.status === 'CheckIn' && appointment?.date &&
                                                        <Grid item lg={3.9} xl={2} sm={5.9} md={5.9} xs={5.8}>
                                                            <AppointmentStaffStatus
                                                            isAppointment
                                                                label={"Check-Out"}
                                                                value={'CheckOut'}
                                                                mainColor={"#219653"}
                                                                //   fetchAppointment={fetchAppointment} 
                                                                appointment={appointment}
                                                                style={stylebtn}
                                                                fwidth={'100%'}
                                                                fsize={'13px'}
                                                            />
                                                        </Grid>
                                                    }

                                                        {appointment?.status !== 'Void' && appointment?.status !== 'Canceled' && appointment?.status !== 'Completed' &&
                                                            <Grid item  xl={3} lg={4.2} sm={5.9} md={5.9} xs={5.8}>
                                                                <CustomButtoncolor
                                                                    mainColor={"#000080"}
                                                                    handleClick={() => handleEditReschedule(navigate, appointment)}
                                                                    //   style={{borderRadius: "3.9px", padding: '10px 15px'}}
                                                                    val={"Edit Appointment"}
                                                                    style={stylebtn}
                                                                    fwidth={'100%'}
                                                                    fsize={'13px'}
                                                                    paddin={"10px 5px !important"}
                                                                />
                                                            </Grid>
                                                        }
                                                        <Grid item lg={3.9} xl={2} sm={5.8} md={5.9} xs={5.8}>
                                                            {/* <CustomButtoncolor   
                                                                handleClick={() => handleAppointmentClick(`/crm/customer-relationship-management/customer/notes/${appointment?.customer?.id}`)}
                                                                mainColor={"#A533B8"}
                                                                style={stylebtn}   
                                                                val={"Notes"}
                                                                fwidth={'100%'}
                                                                fsize={'13px'}  
                                                            /> */}         

                                                             <NoteModal  customer_id={appointment?.customer?.id} />   
                                                        </Grid> 
                                                        {appointment?.status !== 'Void' && appointment?.status !== 'Canceled' && appointment?.status !== 'Completed' &&
                <Grid item lg={3.9} xl={2} sm={5.9} md={5.9} xs={5.8}>
                 <AppointmentReschedule appointment={appointment} fwidth={'100%'}  fsize={'13px'}/>
                </Grid>
                }   
                                                        <Grid item lg={3.9} xl={2} sm={5.8} md={5.9} xs={5.8}>
                                                            <CustomButtoncolor
                                                                handleClick={() => handleAppointmentClick(`/crm/appointment/attachments/${appointment?.id}`)}
                                                                mainColor={"#BB641F"}
                                                                style={stylebtn}
                                                                val={"Attachments"} 
                                                                fwidth={'100%'}
                                                                fsize={'13px'}
                                                            /> 
                                                        </Grid>
                                                        {appointment?.status !== 'Void' && appointment?.status !== 'Canceled' &&  appointment?.status !== 'Completed' && appointment?.date &&
                                                            <Grid item lg={3.9} xl={2} sm={5.8} md={5.9} xs={5.8}>
                                                                <AppointmentStaffStatus
                                                                isAppointment
                                                                    label={"Cancel/Refund"}
                                                                    value={'Canceled'}
                                                                    mainColor={"#EB5757"}
                                                                    //   fetchAppointment={fetchAppointment}
                                                                    appointment={appointment}
                                                                    style={stylebtn}
                                                                    fwidth={'100%'}
                                                                    fsize={'13px'}
                                                                />
                                                            </Grid>
                                                        }


                                                        {/* {appointment?.status !== 'Completed' && appointment?.date && <AppointmentStaffStatus
                      label={"Refund"}
                      value={'Refund'}
                      mainColor={"#EB5757"} 
                      appointment={appointment} 
                  />} */}
                                                        <Grid item lg={3.9} xl={2} sm={5.8} md={3.9} xs={5.8}>
                                                            <TreatmentFormButton fwidth={'100%'} data={appointment}  fsize={'17px'}/>
                                                        </Grid>

                                                        {appointment?.status !== 'Completed' && appointment?.status !== 'No Show' && appointment?.status !== 'Canceled' &&
                                                            <Grid item lg={3.9}  xl={2} md={3.9} xs={5.8} sm={5.8} >
                                                                <AppointmentStaffStatus
                                                                isAppointment
                                                                    label={"No Show"}
                                                                    value={'No Show'}
                                                                    // fetchCalenderBookings={fetchCalenderBookings}
                                                                    mainColor={"#9A9797"}
                                                                    appointment={appointment}
                                                                    
                                                                    fwidth={'100%'}
                                                                />
                                                            </Grid>
                                                        }

                                                        {/* <Grid item xl={2} lg={3} sm={3.8} md={2.8} xs={5.8}>
                <CustomButtoncolor
                      handleClick={() => handleAppointmentClick(`/crm/appointment/history/${appointment?.id}`)}
                      mainColor={"#323681"}
                      style={stylebtn}
                      val={"Booking History"}
                      fwidth={'100%'}
                    //   fsize={'11px'}
                      paddin={"10px 5px !important"}
                  /> */}
                                                        {/* </Grid> */}
                                                        {/* <Grid item lg={1.5} xl={2} sm={3.8} md={2.8} xs={5.8}>
                <CustomButtoncolor
                      handleClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                      mainColor={"#000000"}
                      style={stylebtn}
                      val={"Book Details"}
                      fwidth={'100%'}
                      fsize={'13px'}
                  />
                </Grid> */}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* <Typography
                                                variant="body2"
                                                color="initial"
                                                sx={{
                                                    color: "#56CCF2",
                                                    fontSize: '16px',
                                                    fontFamily: 'helvetica-lt-std-bold'
                                                }}
                                            >
                                                {appointment?.provider?.name}
                                            </Typography> */}

                                            <Grid
                                                container
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between"
                                                }}
                                            >
                                                <Grid item md={12} xl={3.5}>
                                                    <Box sx={{ borderRight: { xs: "0px", sm: "2px dashed #9A9797" }, pl: { xs: 0, sm: 0 } }}>
                                                        <Typography
                                                            variant="body2"
                                                            color="initial"
                                                            sx={{
                                                                color: "#000", textDecoration: 'underline'
                                                                ,
                                                            }
                                                            }
                                                        >
                                                            Appointment Date:
                                                        </Typography>
                                                        <Typography
                                                            variant="h4"    
                                                            color="initial"  
                                                            sx={{
                                                                mt: 1,
                                                                fontFamily: "helvetica-lt-std-bold",
                                                                fontSize: '2.0rem',
                                                                color: appointment?.date ? "#000" : '#CB3838',
                                                            }}
                                                        >
                                                            {appointment?.date ?
                                                                moment(appointment?.date).format("dddd MMMM D, YYYY")
                                                                : 'Not Set'
                                                            }
                                                        </Typography>
                                                        {appointment?.date && appointment?.end_time && <Typography
                                                            variant="h5"
                                                            color="initial"
                                                            sx={{ fontFamily: "helvetica-lt-std-bold" }}
                                                        >
                                                            {appointment?.start_time} -{" "}
                                                            {appointment?.end_time}

                                                        </Typography>}
                                                        <Box sx={{ display: "flex", mt: 1 }}>
                                                            <Typography variant="body2" color="initial">
                                                                Created On:
                                                            </Typography>
                                                            <Typography variant="body2" color="initial">
                                                                {moment(appointment?.created_at).format("D MMM, YYYY")} {" "}
                                                                ({moment(appointment?.created_at).format("hh:mm A")})
                                                            </Typography>
                                                        </Box>

                                                        <Box sx={{ display: "flex", mt: 1 }}>
                                                            <Typography variant="body2" color="initial">
                                                                Created By:
                                                            </Typography>
                                                            <Typography variant="body2" color="initial">
                                                                {appointment?.created_by}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: "flex", mt: 1 }}>
                                                            <Typography variant="body2" color="initial">
                                                                Reference Clinic:
                                                            </Typography>
                                                            <Typography variant="body2" color="initial">
                                                                {appointment?.reference_clinic_name}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Grid>


                                                <Grid item xs={12} xl={8} sx={{ mt: { xs: 4, xl: 0 } }}>
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        <Grid item xs={12} sm={6} >
                                                            <Box>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="initial"
                                                                    sx={{
                                                                        color: "#000",
                                                                        textDecoration: 'underline',
                                                                        fontSize: '16px',
                                                                        fontFamily: 'helvetica-lt-std-bold'
                                                                    }}
                                                                >
                                                                    Booking Details:
                                                                </Typography>
                                                                <Box sx={{ display: "flex", mt: 2, cursor: 'pointer' }}
                                                                    onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                                                                >
                                                                    <Typography variant="body2" color="initial">
                                                                        Booking ID:
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="initial"
                                                                        sx={{ ml: 1 }}
                                                                        style={{ color: '#2F80ED' }}

                                                                    >
                                                                        #{appointment?.id}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ display: "flex" }}>
                                                                    <Typography variant="body2" color="initial">
                                                                        {appointment?.invoices?.invoices_methods?.length > 1 ? "Invoice ID:" : "Invoice ID"}
                                                                    </Typography>
                                                                    <Box sx={{ display: 'flex', cursor: 'pointer' }}
                                                                        onClick={() => handleAppointmentClick(`/crm/invoices/${appointment?.id}`)}
                                                                    >
                                                                        {appointment?.invoices?.invoices_methods?.length > 0 &&
                                                                            <Typography variant="body2" color="initial" style={{ color: '#2F80ED' }}

                                                                                sx={{ ml: 1 }}>
                                                                                {appointment?.invoices?.invoices_methods[0]?.id}
                                                                            </Typography>
                                                                        }
                                                                        {/*{appointmentDetails?.invoices?.invoices_methods?.length > 0 && appointmentDetails?.invoices?.invoices_methods?.map((item, index) => (*/}
                                                                        {/*    <Typography variant="body2" color="initial" sx={{ml: 1}} key={index}>*/}
                                                                        {/*      {index > 0 ? ", " : ""}{item?.id}*/}
                                                                        {/*    </Typography>*/}
                                                                        {/*))}*/}
                                                                    </Box>
                                                                </Box>
                                                                {/*
                                                    <Box sx={{display: "flex", mt: 2,  alignItems:'center'}}>
                                                        <Typography variant="body2" color="initial">
                                                            Booking Status:
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"


                                                                sx={{
                                                                    background: appointment?.status_color,
                                                                    p:'3px 12px',
                                                                    borderRadius:'13px',
                                                                    color:'#fff',
                                                                    ml: 1,
                                                                    display:'flex',
                                                                    alignItems:'center',
                                                                    height:'fit-content',


                                                                  }}



                                                        >
                                                            {appointment?.status}
                                                        </Typography>
                                                    </Box>
*/}
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12} sm={5.9}>
                                                            <Box>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="initial"
                                                                    sx={{
                                                                        color: "#000",
                                                                        textDecoration: 'underline',
                                                                        fontSize: '16px',
                                                                        fontFamily: 'helvetica-lt-std-bold'
                                                                    }}
                                                                >
                                                                    Payment Details:
                                                                </Typography>
                                                                <Box sx={{ display: "flex", mt: 2, alignItems: 'center' }}>
                                                                    <Typography variant="body2" color="initial">
                                                                        Payment Status:
                                                                    </Typography>
                                                                    <Typography variant="body2" color="initial" sx={{
                                                                        ml: 1,
                                                                        background: appointment?.payment_status === 'Paid' ? "#1C7E46" : '#C64949',
                                                                        borderRadius: '13px',
                                                                        color: '#fff', padding: "2px 12px", height: "fit-content", display: 'flex', alignItems: 'center'
                                                                    }}>
                                                                        {appointment?.payment_status}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ display: "flex" }}>
                                                                    <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                                                        Transaction Id
                                                                    </Typography>
                                                                    <Typography variant="body2" color="initial" sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}>
                                                                        {appointment?.invoices?.transaction_id ? appointment?.invoices?.transaction_id : 'N/A'}

                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ display: "flex" }}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="initial"
                                                                        sx={{
                                                                            fontFamily: 'helvetica-lt-std-bold',
                                                                        }}
                                                                    >
                                                                        Grand Total
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="initial"
                                                                        sx={{
                                                                            ml: 1,
                                                                            fontFamily: 'helvetica-lt-std-bold',
                                                                        }}
                                                                    >
                                                                        ${appointment?.grand_total}
                                                                    </Typography>
                                                                </Box>
                                                                <Box sx={{ display: "flex", mt: 2, alignItems: 'center' }}>
                                                                    <Typography variant="body2" color="initial">
                                                                        Booking Status:
                                                                    </Typography>
                                                                    <Typography variant="body2" color="initial" sx={{
                                                                        ml: 1,
                                                                        background: appointment?.status_color,
                                                                        borderRadius: '13.1px',
                                                                        color: '#fff', padding: "2px 12px", height: "fit-content", display: 'flex', alignItems: 'center'
                                                                    }}>
                                                                        {appointment?.status}
                                                                    </Typography>
                                                                </Box>
                                                                {/*<Box sx={{display: "flex", mt: 2}}>*/}
                                                                {/*  <Typography variant="body2" color="initial">*/}
                                                                {/*    Future Payment*/}
                                                                {/*  </Typography>*/}
                                                                {/*  <Typography variant="body2" color="initial" sx={{ml: 1}}>*/}
                                                                {/*    $2566*/}
                                                                {/*  </Typography>*/}
                                                                {/*</Box>*/}
                                                            </Box>
                                                            {
                                                                appointment?.plan?.id && appointment?.current_session && <Box
                                                                    sx={{
                                                                        ...boxwrapper,
                                                                        height: 'max-content',
                                                                        borderColor: appointment?.status_color || 'black',
                                                                        cursor: 'pointer',
                                                                        width: '120px',
                                                                        mt: 2
                                                                    }}
                                                                    onClick={() => handleAppointmentClick(`/crm/appointment/details/${appointment?.id}`)}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            ...box,
                                                                            height: 'max-content',
                                                                            my: '5px',

                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                ...content,
                                                                                background: appointment?.status_color || 'black',
                                                                                p: '1px 12px',
                                                                                borderRadius: '13px',
                                                                                color: '#fff',
                                                                            }}>
                                                                            Session No
                                                                        </Typography>
                                                                        <Typography
                                                                            variant="h6"
                                                                            sx={{
                                                                                color: appointment?.status_color || 'black',
                                                                            }}
                                                                        >
                                                                            {appointment?.current_session} / {appointment?.plan?.no_of_bookings}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            }
                                                        </Grid>
                                                    </Box>

                                                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', mt: { xs: 3, xl: 0 } }}>

                                                        <Grid item xs={12} xl={2.9}>
                                                            <Typography variant="body2" color="initial" sx={{ color: "#000", textDecoration: 'underline', fontWeight: 600, mb: 1 }}>
                                                                Notes
                                                            </Typography>
                                                            <Typography variant="body2" color="initial">
                                                                Customer Notes
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={12} xl={5.9}>
                                                            {appointment?.notes?.Customer?.length > 0 ?
                                                                appointment?.notes?.Customer.map((staff, staffIndex) => (
                                                                    <Box key={staffIndex} sx={{ mt: 1 }}>
                                                                        <Typography variant="body2" color="initial" sx={{ mt: 1 }}>
                                                                            <Typography as={"span"} sx={{ fontFamily: "helvetica-lt-std-bold" }}>
                                                                                [{moment(staff?.created_at).format('h:mm A')},
                                                                                {moment(staff?.created_at).format('YYYY-MM-D')}] {" "}
                                                                                {staff?.staff?.name}:
                                                                            </Typography>
                                                                            {staff?.description}
                                                                        </Typography>
                                                                    </Box>
                                                                ))
                                                                :
                                                                <Box sx={{ marginTop: "32px" }}>
                                                                    <Typography variant="body2" color="initial">
                                                                        <span style={{ fontFamily: "helvetica-lt-std-bold" }}></span>
                                                                        None
                           
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>

                                                        <Grid item xs={12} xl={2.9}>
                                                            <Box sx={{ display: "flex", mt: '25px' }}>
                                                                <Typography variant="body2" color="initial">
                                                                    Staff Notes
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid xs={12} xl={5.9}>
                                                            {appointment?.notes?.Staff?.length > 0 ?
                                                                appointment?.notes?.Staff.map((staff, staffIndex) => (
                                                                    <Box key={staffIndex} sx={{ mt: 1 }}>
                                                                        <Typography variant="body2" color="initial" sx={{ mt: 1 }}>
                                                                            <Typography as={"span"} sx={{ fontFamily: "helvetica-lt-std-bold" }}>
                                                                                [{moment(staff?.created_at).format('h:mm A')},
                                                                                {moment(staff?.created_at).format('YYYY-MM-D')}] {" "}
                                                                                {staff?.staff?.name}:
                                                                            </Typography>
                                                                            {staff?.description}
                                                                        </Typography>
                                                                    </Box>
                                                                ))
                                                                :
                                                                <Box sx={{ marginTop: "30px" }}>
                                                                    <Typography variant="body2" color="initial">
                                                                        <span style={{ fontFamily: "helvetica-lt-std-bold" }}></span>
                                                                        None
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                        </Grid>
                                                    </Grid> */}

                                                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>

                                                        <Grid item xs={12} xl={2.9}>
                                                            <Box sx={{ display: "flex", mt: '30px' }}>
                                                                <Typography variant="body2" color="initial">
                                                                    Medical/Staff Notes
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid xs={12} xl={5.9}>
                                                            <Box sx={{ mt: 1 }}>
                                                                {appointment?.notes?.Medical?.length > 0 ?
                                                                    appointment?.notes?.Medical.map((medical, medicalIndex) => (
                                                                        <Box key={medicalIndex}>
                                                                            <Typography variant="body2" color="initial" sx={{ mt: 1 }}>
                                                                                <Typography as={"span"} sx={{ fontFamily: "helvetica-lt-std-bold" }}>
                                                                                    [{moment(medical?.created_at).format('h:mm A')},
                                                                                    {moment(medical?.created_at).format('YYYY-MM-D')}] {" "}
                                                                                    {medical?.staff?.name}:
                                                                                </Typography>
                                                                                {medical?.description}
                                                                            </Typography>
                                                                        </Box>
                                                                    ))
                                                                    :
                                                                    <Box sx={{ marginTop: "32px" }}>
                                                                        <Typography variant="body2" color="initial">
                                                                            <span style={{ fontFamily: "helvetica-lt-std-bold" }}></span>
                                                                            None
                                                                        </Typography>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    {appointment?.plan && (
                                                        <>
                                                            <Grid container sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="initial"
                                                                    sx={{
                                                                        color: "#000",
                                                                        textDecoration: 'underline',
                                                                        fontSize: '16px',
                                                                        fontFamily: 'helvetica-lt-std-bold'
                                                                    }}
                                                                >
                                                                    Plan Name:
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="initial"
                                                                    sx={{
                                                                        color: "#000",
                                                                        fontSize: '16px',
                                                                        textAlign: 'right'
                                                                    }}
                                                                >
                                                                    {appointment?.plan?.title}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )}

                                                    {appointment?.plan && (
                                                        <>
                                                            <Grid container sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="initial"
                                                                    sx={{
                                                                        color: "#000",
                                                                        textDecoration: 'underline',
                                                                        fontSize: '16px',
                                                                        fontFamily: 'helvetica-lt-std-bold'
                                                                    }}
                                                                >
                                                                    Plan Name:
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="initial"
                                                                    sx={{
                                                                        color: "#000",
                                                                        fontSize: '16px',
                                                                        textAlign: 'right'
                                                                    }}
                                                                >
                                                                    {appointment?.plan?.title}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    <Box sx={{ mt: 4 }}>
                                                        <Typography
                                                            variant="body2"
                                                            color="initial"
                                                            sx={{
                                                                color: "#000",
                                                                textDecoration: 'underline',
                                                                fontSize: '16px',
                                                                fontFamily: 'helvetica-lt-std-bold'
                                                            }}
                                                        >
                                                            Service Details:
                                                        </Typography>
                                                        {appointment?.services?.length > 0 && appointment?.services.map((service, key) => (
                                                            <Box key={key}>
                                                                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="initial"
                                                                    >
                                                                        {service?.name}
                                                                        {appointment?.type === "Booking"
                                                                            ? ''
                                                                            : appointment?.type === "Consultation"
                                                                                ? " (In Person Consultation)"
                                                                                : " (Virtual)"}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="initial"
                                                                        sx={{ml: 1 }}
                                                                    >
                                                                        ${service?.booking_cost}
                                                                    </Typography>

                                                                </Box>
                                                            </Box>
                                                        ))}
                                                        <Box sx={{ display: "flex", justifyContent: 'flex-end', mt: 2, borderTop: "1px solid lightgray", pt: 1 }}>
                                                            <Typography
                                                                variant="body2"
                                                                color="initial"
                                                                sx={{
                                                                    fontFamily: 'helvetica-lt-std-bold',
                                                                }}
                                                            >
                                                                Total
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="initial"
                                                                sx={{
                                                                    ml: 1,
                                                                    fontFamily: 'helvetica-lt-std-bold',
                                                                }}>
                                                                ${appointment?.services_total_cost}
                                                            </Typography>
                                                        </Box>
                                                    </Box>


                                                </Grid>



                                            </Grid>

                                            {/* </Grid> */}

                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        ))
                    }
                </Timeline> :
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontFamily: "helvetica-lt-std-bold",
                    }}
                >
                    No Appointment List
                </Typography>
            )}
        </Box>
    );
};

export default Appointment;