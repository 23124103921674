// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pb-footer {
  margin: 16px 0 0 0;
}
.pb-footer .pf-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0 0;
  font-size: 16px;
}
.pb-footer .pf-heading p {
  margin: 0 5px 0 0;
}
.pb-footer .pf-heading a {
  color: #375997;
  font-size: 16px;
  text-decoration: none;
}
.pb-footer .pf-heading a:hover {
  color: #108da3;
}
.pb-footer .pf-links {
  display: flex;
  justify-content: center;
  margin: 16px 0 0 0;
  text-align: center;
}
.pb-footer .pf-links a {
  color: #375997;
  font-size: 16px;
  text-decoration: none;
}
.pb-footer .pf-links a:hover {
  color: #108da3;
}`, "",{"version":3,"sources":["webpack://./src/components/public-footer/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AAEJ;AADI;EACE,iBAAA;AAGN;AADI;EACE,cAAA;EACA,eAAA;EACA,qBAAA;AAGN;AAFM;EACE,cAAA;AAIR;AAAE;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;AAEJ;AADI;EACE,cAAA;EACA,eAAA;EACA,qBAAA;AAGN;AAFM;EACE,cAAA;AAIR","sourcesContent":[".pb-footer {\n  margin: 16px 0 0 0;\n  .pf-heading {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 16px 0 0 0;\n    font-size: 16px;\n    p {\n      margin: 0 5px 0 0;\n    }\n    a {\n      color: #375997;\n      font-size: 16px;\n      text-decoration: none;\n      &:hover {\n        color: #108da3;\n      }\n    }\n  }\n  .pf-links {\n    display: flex;\n    justify-content: center;\n    margin: 16px 0 0 0;\n    text-align: center;\n    a {\n      color: #375997;\n      font-size: 16px;\n      text-decoration: none;\n      &:hover {\n        color: #108da3;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
