import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiHeaders} from "../../lib/apiHeader";
import axios from "axios";
import apis from "../../lib/apis/apis";
import toast from "react-hot-toast";


export const getAllRoles = createAsyncThunk('getAllRoles', async (data,{dispatch}) => {
    try {

        const response = await axios.get(apis.get_all_roles+`page=1&limit=100`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})


// Referal_point

export const getReferralPoint = createAsyncThunk('getReferralPoint', async (data,{dispatch}) => {
    try {

        const response = await axios.get(apis.Referal_point, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

// Referal_point_Updated
export const updateReferralPoint = createAsyncThunk('updateReferralPoint', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_ReferalPoint, {...data},{
            headers: apiHeaders()
        })
 
        if (response?.data?.success==='true'){
            await dispatch(getReferralPoint())    
            return  response.data
        }else {          
            return response?.data 
        }      
    }catch (err){      
        console.log(err)
          
        if (err?.response?.status!==200){       
            toast.error(err?.message,{duration:4000})            
        }        
        if (err?.response?.status!==200){
            return err?.response?.data
        }    
    }            
 })
     
export const storeRole = createAsyncThunk('storeRole', async (data) => {
   try {
       const response = await axios.post(apis.store_role, {...data},{
           headers: apiHeaders()
       })
       return  response?.data

   }catch (err){
       if (err?.response?.status!==200){
           toast.error(err?.message,{duration:4000})
       }
       if (err?.response?.status!==200){

        return {     
            data : [],     
            errors:err?.response?.data?.errors
        }     
       }
   }
})                
export const getRoleByID = createAsyncThunk('getRoleByID', async (id) => {
   try {
       const response = await axios.get(apis.get_role_by_id+id,{
           headers: apiHeaders()
       })
       return  response?.data

   }catch (err){
       if (err?.response?.status!==200){
        return {
            data : [],
            errors:err?.response?.data?.errors
        }
       }
   }
})

export const updateRole = createAsyncThunk('updateRole', async (data,{dispatch}) => {
   try {
       const response = await axios.post(apis.update_role, {...data},{
           headers: apiHeaders()
       })

       if (response?.data?.success==='true'){
           await dispatch(getAllRoles())
           return  response.data
       }else {
           return response?.data
       }
   }catch (err){
       console.log(err)

       if (err?.response?.status!==200){
           toast.error(err?.message,{duration:4000})
       }
       if (err?.response?.status!==200){
           return err?.response?.data
       }
   }
})     


     


export const deleteRole = createAsyncThunk('deleteRole', async (id,{dispatch}) => {
   try {
       const response = await axios.get(apis.delete_role+id,{
           headers: apiHeaders()
       })

       if (response?.data?.success ==='true'){
         await dispatch(getAllRoles())
           toast.success("Role Deleted Successfully",{duration:3000})

       }else {
           console.log(response?.data)
       }

   }catch (err){
       console.log(err)
       if (err?.response?.status!==200){
           toast.error(err?.message,{duration:4000})
       }
   }
})

export const roleSlice = createSlice({
    name: 'roleSlice',
    initialState: {
        roles: [],
        refralpoint:[],
        meta: {},
        links: {},
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllRoles.fulfilled, (state, action) => {
            state.roles = action.payload
        })
        builder.addCase(getReferralPoint.fulfilled, (state, action) => {
            state.refralpoint = action.payload
        })

      
    }
})


export default roleSlice.reducer