import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./privateRoutes";
import PublicRoutes from "./publicRoutes";
import Login from "../pages/login";
import Register from "../pages/register";
import Verification from "../pages/verification";
import ResendOtp from "../pages/verification/resendOTP";
import ForgetPassword from "../pages/verification/forgetPassword";
import Chat from "../pages/crm/chat/chat";
import GeneralReport from "../pages/crm/report/general-report";
import WalletReport from "../pages/crm/report/walletReport";
import Appointment from "../pages/crm/appointment/appointment";
import ResetPassword from "../pages/verification/resetPassword";
import ProvidersRoutes from "./providersRoutes";
import UserRoutes from "./userRoutes";
import StaffRoutes from "./staffRoutes";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserToken } from "../store/auth";
import Roles from "../pages/configuration/roles/roles";
import Permissions from "../pages/configuration/permissions/permissions";
import Menus from "../pages/configuration/menus/menus";
import PaymentMethods from "../pages/configuration/payments-methods/paymentMethods";
import Languages from "../pages/configuration/languages/languages";
import Clinics from "../pages/configuration/clinics/clinics";
import AddRole from "../pages/configuration/roles/addRole";
import AddPermissions from "../pages/configuration/permissions/addPermissions";
import NotFound from "../components/not-found/notFound";
import AddMenus from "../pages/configuration/menus/addMenus";
import AddPaymentsMethods from "../pages/configuration/payments-methods/addPaymentsMethods";
import AddLanguages from "../pages/configuration/languages/addLanguages";
import AddClinics from "../pages/configuration/clinics/addClinics";
import Loader from "../components/loader/loader";
import CRMCalender from "../pages/crm/calender/crmCalender";
import Dashboard from "../pages/crm/dashboard/dashboard";
import Staff from "../pages/staff";
import Review from "../pages/review/review";
import AddAppointment from "../pages/crm/appointment/add-appointment/addAppointment";
import Test from "../components/invoice/Test";
import MyAccount from "../pages/user/my-account/myAccount";
import Milestone from "../pages/user/milestone/milestone";
import UserPassword from "../pages/user/user-password/userPassword";
import DefaultLocation from "../pages/user/default-location/defaultLocation";
import NotificationAlerts from "../pages/user/notification-alerts/notificationAlerts";
import UserWallet from "../pages/user/user-wallet/userWallet";
import UserMembership from "../pages/user/user-membership/userMembership";
import UserBooking from "../pages/user/user-bookings/userBooking";
import ReferShare from "../pages/user/refer-share/referShare";
import Support from "../pages/user/support/support";
import BookingWidget from "../pages/booking-widget/bookingWidget";
import Membership from "../pages/configuration/membership/membership";
import AddMembership from "../pages/configuration/membership/addMembership";   
import Invoices from "../pages/crm/pos/invoices/Invoices";
import Pos from "../pages/crm/pos/Pos";
import CRMPage from "../pages/crm/crmpage/CRMPage";
import SaleReport from "../pages/crm/report/SaleReport";
import EodReport from "../pages/crm/report/EODreport";
import ActivityLog from "../pages/crm/activity-log/activiy-log";
// import UserManual from "../pages/crm/user-manual/UserManual"
import MemberReport from "../pages/crm/report/MemberReport";
import PlanBookingWidget from "../pages/booking-widget/plan/planBookingWidget";
import StaffReport from "../pages/crm/report/StaffSale";
import Categorysale from "../pages/crm/report/CategorySale";  
import CallLogs from "../pages/crm/call-logs/callLogs";
import AppointmentDetailsLayout from "../pages/crm/appointment/appointment-details/appointmentDetailsLayout";
import AddNewCustomer from "../pages/crm/crmpage/addNewCustomer";
import CrmCustomerDetails from "../pages/crm/crmpage/crmCustomerDetail/crmCustomerDetails";
import CrmStaffAttendance from "../pages/crm/attendance/staffAttendance";
import Attendance from "../pages/crm/report/Attendance";
import AppointmentCheckout from "../pages/crm/appointment/add-appointment/AppointmentCheckout";
import AppointmentConfirmation from "../pages/crm/appointment/add-appointment/AppointmentConfirmation";
import TreatmentPlane from "../pages/user/treatment-plan/TreatmentPlan";
import AddNewInvoice from "../pages/crm/pos/invoices/addNewInvoice";
import NotificationTemplateLayout from "../pages/configuration/notification-templates/notificationTemplateLayout";
import Treatment from "../pages/crm/call-logs/Treatment";
import AppointmentCallLog from "../pages/crm/call-logs/Appointment";
import InventoryProduct from "../pages/crm/inventory/inventory-product";
import AddProduct from "../pages/crm/inventory/add-product";
import Purchase from "../pages/crm/inventory/purchase";
import InvConsume from "../pages/crm/inventory/inv-consume";
import ChartForm from "../pages/user/chart-form/ChartForm";
import Home from "../pages/home/Home";
import Gift from "../pages/gift-success/Gift";
import GiftOrder from "../pages/gift-success/GiftOrder";
import UserAccount from "../pages/user/user-account/UserAccount";
import Account from "../pages/account/account";
import CrmLogin from "../pages/crm-login/Crm-login";
import ClinicLocation from "../pages/home/ClinicLocation";
import SubCategories from "../pages/home/SubCategories";
import Waveloading from "../pages/loading/Waveloading";
import Promopage from "../pages/home/Promopage"
import ReferalPoint from "../pages/configuration/referal-point-setting/ReferalPoint"
import GHLWebhook from "../pages/configuration/webhook/GHLWebhook";
import Addghl from "../pages/configuration/webhook/Addghl";
import MembershipList from "../pages/crm/membership/membership-list";
import UserManual from "../pages/configuration/clinic-settings/user-manual/UserManual";
import Usermanual from "../pages/crm/user-manual/UserManual";
import AddManual from "../pages/configuration/clinic-settings/user-manual/AddManual";
import Inbox from "../pages/user/chat/Chat";
import GiftCard from "../pages/configuration/clinic-settings/gift-Cards/GiftCard";
import SucessCard from "../pages/gift-success/Sucess-Card";
import GiftCardReport from "../pages/crm/report/GiftCardReport";


const RoutesList = () => {
    let urls = JSON.parse(localStorage.getItem('recentUrls')) || [];

    // Add the current URL to the array
    const currentUrl = window.location.href;
    urls.unshift(currentUrl);
    
    // Keep only the last 5 URLs
    urls = urls.slice(0, 2);
    
    // Save the updated array in local storage
    localStorage.setItem('recentUrls', JSON.stringify(urls));
    
    // Retrieve the updated array from local storage
    urls = JSON.parse(localStorage.getItem('recentUrls')) || [];
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.authStore);
  const { loading } = authStore;  
  useEffect(() => {
    dispatch(fetchUserToken());
  }, [dispatch]);
  return loading ? (
      <Loader fullSize />
  ) : (
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<></>} />
          <Route path="/admin/configuration/roles" element={<Roles />} />
          <Route path="/admin/configuration/usermanual" element={<UserManual />} />
          <Route path="/admin/configuration/add-role" element={<AddRole />} />
          <Route path="/admin/configuration/edit-role/:role_id" element={<AddRole />}/>

          <Route path="/admin/configuration/all-membership-plans" element={<Membership />} />
          <Route path="/admin/configuration/add-membership" element={<AddMembership />} />
          <Route path="/admin/configuration/edit-membership/:membership_id" element={<AddMembership />}/>

          <Route
              path="/admin/configuration/permissions"
              element={<Permissions />}
          />
          
          <Route
              path="/admin/configuration/webhook"
              element={<GHLWebhook />}
          />
          <Route
              path="/admin/configuration/refralpoints"
              element={<ReferalPoint />}  
          />   
    
          <Route   
              path="/admin/configuration/add-permissions"   
              element={<AddPermissions />}
          />
          <Route
              path="/admin/configuration/edit-permissions/:permission_id"      
              element={<AddPermissions />}
          />
          <Route path="/admin/configuration/menus" element={<Menus />} />
          <Route path="/admin/configuration/add-menus" element={<AddMenus />} />
          <Route path="/admin/configuration/add-manual" element={<AddManual />} />
         
          <Route
              path="/admin/configuration/edit-menus/:menu_id"
              element={<AddMenus />}
          />
           <Route
              path="/admin/configuration/edit-ghl/:ghl_id"  
              element={<Addghl/>}
          />
            <Route
              path="/admin/configuration/edit-manual/:manual_id"  
              element={<AddManual />}
          />
           <Route path="/admin/configuration/add-ghl" element={<Addghl />} />

          <Route
              path="/admin/configuration/payment-methods"
              element={<PaymentMethods />}
          />
          <Route
              path="/admin/configuration/add-payment-methods"
              element={<AddPaymentsMethods />}
          />
          <Route
              path="/admin/configuration/edit-payment-methods/:payment_method_id"
              element={<AddPaymentsMethods />}
          />
          <Route path="/admin/configuration/languages" element={<Languages />} />
          <Route
              path="/admin/configuration/add-language"
              element={<AddLanguages />}
          />
          <Route
              path="/admin/configuration/edit-language/:languages_id"
              element={<AddLanguages />}
          />
          <Route
              path="/admin/configuration/notification-templates/customer-signup/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/customer-created-from-crm/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/membership-signup/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/membership-upgraded/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/appointment-booked/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/appointment-rescheduled/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/appointment-canceled/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/reminder-for-appointment-before/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/reminder-for-appointment-day/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/check-in/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/receipt-generated/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/ask-for-review/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/customer-no-show/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/clinic-cancel-membership/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route
              path="/admin/configuration/notification-templates/credit-card-decline/:event_id"
              element={<NotificationTemplateLayout />}
          />
          <Route path="/admin/configuration/clinics" element={<Clinics />} />
          <Route
              path="/admin/configuration/add-clinic"
              element={<AddClinics />}
          />
          <Route
              path="/admin/configuration/edit-clinic/:clinic_id"
              element={<AddClinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/business-details`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/contact-details`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/business-hours`}
              element={<Clinics />}
          />

          <Route
              path={`/admin/configuration/clinic/:clinic_id/plan`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/add-plan`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/add-promo`}
              element={<Clinics />}
          />
             <Route
              path={`/admin/configuration/clinic/:clinic_id/add-gift`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/edit-plan/:plan_id`}
              element={<Clinics />}
          />

          <Route
              path={`/admin/configuration/clinic/:clinic_id/services`}
              element={<Clinics />}
          />
          
          <Route
              path={`/admin/configuration/clinic/:clinic_id/extraservices`} 
              element={<Clinics />}
          />
           
         
          <Route
              path={`/admin/configuration/clinic/:clinic_id/add-service`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/edit-service/:service_id`}
              element={<Clinics />}
          />

          <Route
              path={`/admin/configuration/clinic/:clinic_id/staff`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/add-staff`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/scheduler`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/edit-staff/:staff_id`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/hours/:staff_id`}
              element={<Clinics />}
          />

          <Route
              path={`/admin/configuration/clinic/:clinic_id/treatment-group`}
              element={<Clinics />}
          />  
          <Route
              path={`/admin/configuration/clinic/:clinic_id/add-treatment-group`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/edit-treatment-group/:category_id`}
              element={<Clinics />}
          />

          <Route
              path={`/admin/configuration/clinic/:clinic_id/treatment-form`}
              element={<Clinics />}
          />
            <Route
              path={`/admin/configuration/clinic/:clinic_id/promo`}
              element={<Clinics />}
          />
            <Route
              path={`/admin/configuration/clinic/:clinic_id/gift`}
              element={<Clinics />}
          />

          <Route
              path={`/admin/configuration/clinic/:clinic_id/add-treatment-form`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/edit-treatment-form/:treatment_form_id`}    
              element={<Clinics />}
          />

          <Route
              path={`/admin/configuration/clinic/:clinic_id/treatments`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/add-treatment/`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/edit-treatment/:treatment_id`}
              element={<Clinics />}
          />

          <Route path={`/admin/configuration/clinic/:clinic_id/rooms`} element={<Clinics />} />
          <Route path={`/admin/configuration/clinic/:clinic_id/extraservices`} element={<Clinics />} />
          <Route path={`/admin/configuration/clinic/:clinic_id/add-room`} element={<Clinics />} />
          <Route path={`/admin/configuration/clinic/:clinic_id/edit-room/:room_id`} element={<Clinics />} />

          <Route path={`/admin/configuration/clinic/:clinic_id/booking-settings`} element={<Clinics />} />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/twilio-config`}
              element={<Clinics />}     
          />      
          <Route
              path={`/admin/configuration/clinic/:clinic_id/update-password`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/business-radius`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/calender-settings`}
              element={<Clinics />}
          />
           <Route
              path={`/admin/configuration/clinic/:clinic_id/social-info`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/service-type`}
              element={<Clinics />}
          />
          <Route
              path={`/admin/configuration/clinic/:clinic_id/location`}
              element={<Clinics />}
          />   
        </Route>      
        <Route element={<ProvidersRoutes />}> 
      
          <Route exact path="/crm/dashboard" element={<Dashboard />} />
          <Route exact path="/crm/location" element={<ClinicLocation />} />  
        <Route exact path="/crm/home" element={<Home />} />  
        {/* <Route exact path="/crm/gift" element={<Gift />} />   */}

        <Route exact path="/crm/categories/:Slug/:clinicId" element={<SubCategories />} />
        <Route exact path="/crm/promo" element={<Promopage />} />
        <Route exact path="/crm/gift" element={<GiftCard />} />
          <Route path="/crm/chat" element={<Chat />} />

          <Route path="/crm/calender" element={<CRMCalender />} />
          <Route path="/crm/general-report" element={<GeneralReport />} />      
          <Route path="/crm/gift-card-report" element={<GiftCardReport />} />  
          <Route path="/crm/member-report" element={<MemberReport />} />
          <Route path="/crm/eod-report" element={<EodReport/>} />
          <Route path="/crm/activity-log" element={<ActivityLog/>} />
          <Route path="/crm/user-manual" element={<Usermanual />} />
          <Route path="/crm/attendance-report" element={<Attendance />} />
          <Route path="/crm/sale-report" element={<SaleReport />} />       
          <Route path="/crm/staff-sale-report" element={<StaffReport />} />
          <Route path="/crm/category-sale-report" element={<Categorysale/>} />    
          <Route path="/crm/wallet-report" element={<WalletReport/>} />
          {/*<Route path="/crm/reviews" element={<Review />} />*/}
          <Route path="/crm/treatment-call-logs" element={<Treatment />} />  
          <Route path="/crm/appointment-call-logs" element={<AppointmentCallLog />} />
          <Route path="/pos/payment-methods" element={<Appointment />} />
          <Route path="" element={<Appointment />} />
          <Route path="/crm/appointments" element={<Appointment />} />
          <Route path="/crm/add-appointment" element={<AddAppointment />} />
          <Route path="/crm/appointment/reschedule/edit/:appointment_id" element={<AddAppointment />} />
          <Route path="/crm/add-appointment/user/:user_id" element={<AddAppointment />} />
          <Route path="/crm/appointment/confirmation/:appointment_id" element={<AppointmentConfirmation />} />
          <Route path="/crm/appointment/gift/:giftcard_id" element={<Gift />} />
          <Route path="/crm/appointment/update/confirmation/:appointment_id" element={<AppointmentConfirmation />} />
          <Route path="/crm/appointment/invoice/edit/:appointment_id" element={<AppointmentCheckout />} />
          <Route path="/crm/appointment/checkout/:appointment_id" element={<AppointmentCheckout />} />
          <Route path="/crm/appointment/details/:appointment_id" element={<AppointmentDetailsLayout />} />
          <Route path="/crm/appointment/history/:appointment_id" element={<AppointmentDetailsLayout />} />
          <Route path="/crm/appointment/notes/:appointment_id" element={<AppointmentDetailsLayout />} />
          <Route path="/crm/appointment/attachments/:appointment_id" element={<AppointmentDetailsLayout />} />
          <Route path="/crm/appointment/gallery/:appointment_id" element={<AppointmentDetailsLayout />} />
          <Route path="/crm/appointment/reminders/:appointment_id" element={<AppointmentDetailsLayout />} />
          <Route path="/crm/appointment/submitted-forms/:appointment_id" element={<AppointmentDetailsLayout />} />
          <Route path="/crm/invoices/:booking_id" element={<Invoices />} />
          <Route path="/crm/point-of-sale" element={<Pos/>} />
          <Route path="/crm/point-of-sale/new-invoice" element={<AddNewInvoice/>} />
          <Route path="/crm/customer-relationship-management" element={<CRMPage/>} />
          <Route path="/crm/customer-relationship-management/add-customer" element={<AddNewCustomer/>} />
          <Route path="/crm/customer-relationship-management/customer/profile-info/:user_id" element={<CrmCustomerDetails />} />
          <Route path="/crm/customer-relationship-management/customer/history/:user_id" element={<CrmCustomerDetails />} />
          <Route path="/crm/customer-relationship-management/customer/notes/:user_id" element={<CrmCustomerDetails />} />
          <Route path="/crm/customer-relationship-management/customer/attachments/:user_id" element={<CrmCustomerDetails />} />
          <Route path="/crm/customer-relationship-management/customer/gallery/:user_id" element={<CrmCustomerDetails />} />
          <Route path="/crm/customer-relationship-management/customer/reminders/:user_id" element={<CrmCustomerDetails />} />
          <Route path="/crm/customer-relationship-management/customer/submitted-forms/:user_id" element={<CrmCustomerDetails />} />
          <Route path="/crm/Staff-attendance" element={<CrmStaffAttendance />} />
          <Route path="/crm/inventory/product" element={<InventoryProduct />} />
          <Route path="/crm/inventory/add-product" element={<AddProduct />} />
          <Route path="/crm/inventory/product/brand" element={<AddProduct />} />

          <Route path="/crm/inventory/product/inventory" element={<AddProduct />} />
          <Route path="/crm/inventory/product/pricing" element={<AddProduct />} />
          <Route path="/crm/inventory/product/basic-info" element={<AddProduct />} />
          <Route path="/crm/inventory/product/product-image" element={<AddProduct />} />
          <Route path="/crm/inventory/product/pricing/:product_id" element={<AddProduct />} />
          <Route path="/crm/inventory/product/inventory/:product_id" element={<AddProduct />} />
          <Route path="/crm/inventory/product/basic-info/:product_id" element={<AddProduct />} />
          <Route path="/crm/inventory/product/product-image/:product_id" element={<AddProduct />} />
          <Route path="/crm/inventory/product/category" element={<AddProduct />} />
          <Route path="/crm/inventory/product/vendor" element={<AddProduct />} />
          <Route path="/crm/inventory/purchase" element={<Purchase />} />
          <Route path="/crm/inventory/inv-consume" element={<InvConsume />} />
          <Route path="/crm/membership" element={<MembershipList />} />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/business-details`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/contact-details`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/business-hours`}
              element={<Clinics />}
          />

          <Route
              path={`/crm/configuration/clinic/:clinic_id/plan`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/add-plan`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/edit-plan/:plan_id`}
              element={<Clinics />}
          />

          <Route
              path={`/crm/configuration/clinic/:clinic_id/services`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/events`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/add-service`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/edit-service/:service_id`}
              element={<Clinics />}
          />

          <Route
              path={`/crm/configuration/clinic/:clinic_id/staff`}
              element={<Clinics />}
          />
           <Route
              path={`/crm/configuration/clinic/:clinic_id/add-events`}
              element={<Clinics />}
          />
          <Route
                path={`/crm/configuration/clinic/:clinic_id/edit-events/:service_id`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/add-staff`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/scheduler`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/edit-staff/:staff_id`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/hours/:staff_id`}
              element={<Clinics />}
          />

          <Route
              path={`/crm/configuration/clinic/:clinic_id/treatment-group`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/add-treatment-group`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/edit-treatment-group/:category_id`}
              element={<Clinics />}
          />

          <Route
              path={`/crm/configuration/clinic/:clinic_id/treatment-form`}
              element={<Clinics />}
          />
                    <Route
              path={`/crm/configuration/clinic/:clinic_id/promo`}
              element={<Clinics />}
          />
            <Route
              path={`/crm/configuration/clinic/:clinic_id/gift`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/add-treatment-form`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/edit-treatment-form/:treatment_form_id`}
              element={<Clinics />}
          />

          <Route
              path={`/crm/configuration/clinic/:clinic_id/treatments`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/add-treatment/`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/add-promo/`}
              element={<Clinics />}
          />
            <Route
              path={`/crm/configuration/clinic/:clinic_id/add-gift/`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/edit-treatment/:treatment_id`}
              element={<Clinics />}
          />
 
          <Route path={`/crm/configuration/clinic/:clinic_id/rooms`} element={<Clinics />} />
          <Route path={`/crm/configuration/clinic/:clinic_id/extraservices`} element={<Clinics />} />
          <Route path={`/crm/configuration/clinic/:clinic_id/add-room`} element={<Clinics />} />
          <Route path={`/crm/configuration/clinic/:clinic_id/edit-room/:room_id`} element={<Clinics />} />
          <Route path={`/crm/configuration/clinic/:clinic_id/edit-promo/:room_id`} element={<Clinics />} />
          <Route path={`/crm/configuration/clinic/:clinic_id/edit-gift/:room_id`} element={<Clinics />} />
          <Route path={`/crm/configuration/clinic/:clinic_id/booking-settings`} element={<Clinics />} />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/twilio-config`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/update-password`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/business-radius`} 
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/calender-settings`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/social-info`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/service-type`}
              element={<Clinics />}
          />
          <Route
              path={`/crm/configuration/clinic/:clinic_id/location`}
              element={<Clinics />}
          />
        </Route>
        <Route element={<UserRoutes />}>
          <Route exact path="/user/my-account" element={<MyAccount />}   />
          <Route exact path="/user/user-account" element={<UserAccount />}   />
          <Route exact path="/user/inbox" element={<Inbox />}   />
          <Route exact path="/user/password" element={<UserPassword />} />
          <Route exact path="/user/default-spa-location" element={<DefaultLocation />} />
          <Route exact path="/user/notification" element={<NotificationAlerts />} />
          <Route exact path="/user/wallet" element={<UserWallet />} />
          <Route exact path="/user/membership" element={<UserMembership />} />
          <Route exact path="/user/bookings" element={<UserBooking />} />
          <Route exact path="/user/milestone" element={<Milestone />} />
          <Route exact path="/user/appointment/invoice" element={<UserBooking />} />
          <Route exact path="/user/refer/share" element={<ReferShare />} />
          <Route exact path="/user/support" element={<Support />} />
          <Route exact path="/user/plans/listing" element={<TreatmentPlane />} />
        </Route>
        <Route element={<StaffRoutes />}>
          <Route exact path="/staff" element={<Staff />} />
        </Route>
        <Route element={<PublicRoutes />}>      
          <Route exact path="/login" element={<Login  showFooter={false} />} />
          <Route path="/register/" element={<Register />} />
          <Route path="/loading" element={<Waveloading/>} />  
          <Route path="/register/:referral_code" element={<Register />} />   
          <Route path="/test" element={<Test />} />
          <Route path="/request-account" element={<Account />} />
          <Route exact path="/resend-otp" element={<ResendOtp />} />
          <Route exact path="/forget-password" element={<ForgetPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route path="/verification" element={<Verification />} />
        <Route exact path="/location" element={<ClinicLocation />} />  
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/gift-order" element={<GiftOrder />} />
        <Route exact path="/gift-sucess" element={<SucessCard />} />
        <Route exact path="/gift/:giftcard_id" element={<Gift />} />
        <Route exact path="/categories/:Slug/:clinicId" element={<SubCategories />} />
        <Route exact path="/crm-login" element={<CrmLogin />} />
        <Route exact path="/promo" element={<Promopage />} />
        <Route exact path="/gift" element={<GiftCard/>} />
        <Route exact  path="/user/appointment/:service_type/services/:category_slug/:treatment_slug/:location_id" element={<BookingWidget />} />
        <Route exact  path="/user/appointment/:service_type/services/:category_slug/:treatment_slug" element={<BookingWidget />}/>
        <Route exact  path="/user/appointment/book/plan/:plan_slug/:location_id" element={<PlanBookingWidget />} />
        <Route exact  path="/user/appointment/book/plan/:plan_slug/" element={<PlanBookingWidget />} />
        <Route exact path="/form/:customer_id/:provider_id/:slug" element={<ChartForm />} />
        <Route exact path="/crm/:customer_id/form/:customer_id/:provider_id/:slug" element={<ChartForm />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
  );
}; 

export default RoutesList;
