import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import FormInput from "../../../../components/form/formInput";
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Container,
  Box,
  Checkbox,
  ListItemText,
  Input,
  FormHelperText,
} from "@mui/material";

import Autocomplete from "@mui/material/Autocomplete";
import { getAllClinicsTreatmentGroup } from "../../../../store/clinics/clinicSettings/clinic-treatment-group";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getPromocodeId, storePromo, updatePromoCode } from "../../../../store/clinics/clinicSettings/clinic-promo";
import Waveloading from "../../../loading/Waveloading";
import toast from "react-hot-toast";
import { getgiftcardId, storeGiftCard, updategiftcard } from "../../../../store/clinics/clinicSettings/gift-cards";

const top100Films = ["Percentage", "Fixed"];

export const inputStyle = {
  background: "#EDF2F7",
  borderRadius: "4px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #EDF2F7",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#EDF2F7",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#EDF2F7",
      outline: "none",
    },
  },
};
export const labelStyle = {
  fontSize: "16px",
  color: "#27272e!important",
  letterSpacing: ".3px",
  textTransform: "capitalize",
}; 
export const selectStyle = {
  background: "#EDF2F7",
  borderRadius: "4px",
  marginTop: "7px",
  marginRight: "5px",
  marginLeft: "5px",
  height: "44px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",

    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
}; 

const AddGifts = () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [endDate, setEndDate] = useState("");
  const [discounttype, setDiscounttype] = useState("");
  const [age, setAge] = React.useState([]);
  const [inputValue, setInputValue] = useState('');
  const [status, setStatus] = useState([]);
  const [discountValue, setDiscountValue] = useState(null);    
  const dispatch = useDispatch(); 
  const [errors, setErrors] = useState({
    code: false,
    title: false,
    endDate: false,
    // age: false,
    // discountValue: false, 
    // discounttype:false
  });
  
  const user = useSelector(state => state?.authStore?.user)   
  const navigate = useNavigate()
  const reportsStore = useSelector((state) => state?.reportsStore);
  const { CategoryService = [] } = reportsStore;
  const params = useParams();
  const [selectedValues, setSelectedValues] = useState([]);

 

   console.log("prams",params)
   const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
  
    // Validate if input is empty or contains only numbers and commas
    const isEmpty = value.trim() === '';
    const isValid = /^[0-9,]*$/.test(value);
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      prices: isEmpty ? true : !isValid,
    }));
  };
  // console.log("data", clinicTreatmentGroupStore?.treatmentGroups);  
  // Function to generate random code
  const generateRandomCode = () => {
    const characters = "0123456789"; // Only numeric characters
    const minLength = 12; // Minimum length of the code
  
    let randomCode = "";
    while (randomCode.length < minLength) {
      randomCode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
  
    // Update the state with the generated code (assuming you're using React hooks)
    setCode(randomCode);
  };
  useEffect(() => {
    (async () => {  
      setLoading(true)
      // await dispatch(getAllClinicsTreatmentGroup(params?.clinic_id));
      setLoading(false)
    })();
  }, [dispatch]);
  const handleChange = (event) => {  
    setStatus(event.target.value);
  };
  const handleSubmit = async () => {
    // Validate mandatory fields
    const newErrors = {
      code: !code,
      title: !title,
      endDate: !endDate,
      prices: errors.prices || !inputValue.trim(), // Check if prices field is valid
    };

    if (Object.values(newErrors).some((error) => error)) {
      setErrors(newErrors);
      return;
    }

    const numbersArray = inputValue.split(',').map(item => item.trim());

    const payload = {
      provider_id: params?.clinic_id,
      title: title,
      code: code,
      expiry_date: endDate,
      id: params?.room_id || "",
      status: status,
      prices: numbersArray,
    };

    setLoading(true);

    let res;
    if (params?.room_id) {
      res = await dispatch(updategiftcard(payload));
    } else {
      res = await dispatch(storeGiftCard(payload));
    }

    if (res?.payload?.errors) {
      const { errors } = res?.payload;
      toast.error("Error occurred while saving");
    } else if (res?.payload?.success === "true") {
      toast.success(`Gift Card ${params?.room_id ? "updated" : "Stored"} Successfully`, { duration: 3000 });
      navigate(`/${user?.role_id === 2 ? "crm" : "admin"}/configuration/clinic/${params?.clinic_id}/gift`);
    }

    setLoading(false);
  };
  
  console.log("data", CategoryService);
  // getPromocodeId  
  useEffect(() => {   
    (async () => {
      if (params?.room_id) {
        setLoading(true);
        let res = await dispatch(getgiftcardId(params?.room_id));
        console.log("data comes",res)
        if (res?.payload?.data?.id) { 
          setCode(res.payload.data.code || ''); 
          setTitle(res.payload.data.title || '');   
          // setDiscountValue(res.payload.data.discount_type || '');
          // setDiscounttype(res.payload.data.discount_value || ''); 
          setStatus(res.payload.data.status|| '');
          setEndDate(res.payload.data.expiry_date || '');
          if (Array.isArray(res.payload.data.prices)) {
            const pricesArray = res.payload.data.prices.map(priceObj => priceObj.price);
            setInputValue(pricesArray.join(', '));
          }
         
        }
        setLoading(false);    
      }
    })();
  }, [ dispatch]); 
  return (
    
    <Box sx={{ sm:'20px', xs:'2px' }}>  
   { loading ? <Waveloading/> :     
  <>
  <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;', p: { sm: 5, xs: 1 }, m: 2, borderRadius: '12px' }}>
    <Grid container sx={{ justifyContent: "space-between", pb: "0" }}>
    <Grid item lg={9.9} md={12} sm={12} xs={12}>
  <FormControl fullWidth sx={{ mb: "16px" }}>
    <Grid container>
      <Grid item lg={5.9} md={12} sm={12} xs={12}>
        <FormLabel sx={{ ...labelStyle, mt: "10px" }}>
          Gift Card
        </FormLabel>
      </Grid>
      <Grid item lg={5.9} md={12} sm={12} xs={12}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            ...labelStyle,
            float: "right",
            background: "#09ABF1",
            boxShadow: "none",
            color: '#fff',
            mb: 1,
            "&:hover": {
              backgroundColor: "#09ABF1",
              boxShadow: "none",
            },
          }}
          onClick={() => {
            generateRandomCode();
            setErrors((prev) => ({ ...prev, code: false })); // Clear the error when generating code
          }}
        >
          Generate Random Gift Card
        </Button>
      </Grid>
    </Grid>
    <TextField
      sx={inputStyle}
      value={code}
      onChange={(e) => {
        setCode(e.target.value);
        setErrors((prev) => ({ ...prev, code: false }));
      }}
      error={errors.code}
    />
    {errors.code && (
      <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
        Discount code is required
      </FormHelperText>
    )}
  </FormControl>
</Grid>

    </Grid>
  </Box>

  <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;', p: { sm: 5, xs: 1 }, m: 2, borderRadius: '12px' }}>
    <Grid container>
      <Grid item lg={10} md={5.9} sm={12} xs={12} sx={{ mb: "16px" }}>
        <FormControl fullWidth>
          <FormLabel sx={{ ...labelStyle, mt: "10px" }}>Title</FormLabel>
          <TextField
            sx={inputStyle}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setErrors((prev) => ({ ...prev, title: false }));
            }}
            error={errors.title}
          />
          {errors.title && <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
            Title is required
          </FormHelperText>}
        </FormControl>

    
      </Grid>
    </Grid>

    <Grid container>
      <Grid item lg={4.9} md={5.9} sm={12} xs={12}>
        <FormControl fullWidth sx={{ mb: "16px" }}>
          <FormLabel sx={labelStyle}>End Date</FormLabel>
          <TextField
            type="date"
            sx={{ ...inputStyle, mt: "15px" }}
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
              setErrors((prev) => ({ ...prev, endDate: false }));
            }}
            error={errors.endDate}
          />
          {errors.endDate && <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
            End Date is required
          </FormHelperText>}
        </FormControl>
      </Grid>

      <Grid item lg={4.9} md={5.9} sm={12} xs={12} sx={{ marginLeft: {
        sm: "0",  // 0 margin on small screens and down
        lg: "15px"  // 15px margin on large screens
    } }}>
                <FormControl fullWidth sx={{mb: "16px" }}>
                  <FormLabel >Prices</FormLabel>    
      <TextField
      value={inputValue}
      sx={{ ...inputStyle, mt: "15px" }}
      onChange={handleInputChange} 
      placeholder="Enter numbers separated by commas"
      error={errors.prices} 
    
       />

{ errors.prices  ? inputValue.trim() === ""?  <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
                price is required
  </FormHelperText> :  <FormHelperText sx={{ color: '#CB3838', width: 'max-content', mx: 0 }}>
  Only numbers separated by commas are allowed
  </FormHelperText> :""}
                </FormControl>
              </Grid>
    </Grid>

    <Grid container>
      <Grid item lg={8.9} md={12} sm={12} xs={12} sx={{ mt: "16px" }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {params?.room_id ? 'Update Gift Cards' : 'Add Gift Card'}
        </Button>
      </Grid>
    </Grid>
  </Box>
</>

} 
    </Box>
    
  );
};

export default AddGifts;
   