import React from 'react';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getAppointmentDetails } from '../../store/crm/appointment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../crm-dashboard/style';

const MobileViewLayout = ({ walletHistory }) => {
    const [appointmentDetails, setAppointmentDetail] = useState(null)
    const [loading, setLoading] = useState(null)
    const classes = useStyles();
    const dispatch = useDispatch()
  
  
    const handleAppointmentDetail = async (booking_id) => {
      setLoading(true)
      let {payload} = await dispatch(getAppointmentDetails(booking_id))
      setLoading(false)
      if (payload?.id){
        setAppointmentDetail(payload)
      }else {    
        setAppointmentDetail(null)
      } 
    }   
  
  return (
    <Box
      sx={{
        display: {
          md:"block",
            sm: 'block', 
            lg: 'none', 
            xl:"none" 
          },
       
        background: "#fff",
        borderRadius: "8px",
        minHeight: "100px",
        p: { sm: '24px', xs: '12px' },
        px: { sm: '24px', xs: '0px' }
      }}
    >
      {walletHistory?.history?.length > 0 ? (
        walletHistory?.history.map((history, index) => {
          const detailsWithColor = history?.details.replace(
            /Booking #\s*(\d+)/g,
            'Booking #<span style="color: #09ABF1">$1</span>'
          ).replace(
            /Invoice #\s*(\d+)/g,
            'Invoice #<span style="color:#09ABF1">$1</span>'
          );

          return (
            <Grid
              key={index}
              container
              sx={{
                flexDirection: 'column', // Display variables and values in a column
                pb: 2,
                borderTop: index !== 0 ? "1px solid #C4C4C4" : '',
              }}
            >
              <Grid item>
                <Typography variant="body2" sx={{ color: "#4F4F4F" ,textDecoration:'underline', fontFamily: 'helvetica-lt-std-bold'}}>
                  Date:
                </Typography>
                <Typography variant="body1" sx={{  }}>
                  {history?.created_at}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body2" sx={{ color: "#4F4F4F",mt:1, textDecoration:'underline', fontFamily: 'helvetica-lt-std-bold' }}>
                  Description:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    
                    cursor: history?.booking_id ? 'pointer' : 'default',
                    "& span": {
                      background: "transparent",
                    },
                    "& span[style*='background-color']": {
                      borderRadius: "3px",
                      padding: "2px 4px",
                    },
                  }}
                  onClick={() =>
                    history?.booking_id && handleAppointmentDetail(history?.booking_id)
                  }
                  dangerouslySetInnerHTML={{ __html: detailsWithColor }}
                />
              </Grid>

              <Grid item>
                <Typography variant="body2" sx={{ color: "#4F4F4F" ,mt:1,textDecoration:'underline', fontFamily: 'helvetica-lt-std-bold'}}>
                  Balance:
                </Typography>
                <Typography variant="body1" sx={{}}>
                  {history?.type.toLowerCase() === 'credit' ? "+" : (history?.type.toLowerCase() === 'overwrite' ? "" : "-")}
                  {history.wallet_point}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="body2" sx={{ color: "#4F4F4F",mt:1,textDecoration:'underline',  fontFamily: 'helvetica-lt-std-bold' }}>
                  Current:
                </Typography>
                <Typography variant="body1" sx={{  }}>
                  {history.after_point}
                </Typography>
              </Grid>
            </Grid>
          );
        })
      ) : (
        <Grid container sx={{ display: "flex", pb: 2 }}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ textAlign: 'center' }}>
              None
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default MobileViewLayout;
