import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {Box, FormControl, Grid, Typography} from "@mui/material";
import SimpleInput from "../../../../components/form/simpleInput";
import CustomButton from "../../../../components/custom-button/customButton";
import Loader from "../../../../components/loader/loader";
import CardTable from "../../../../components/card-table/Cardtabel";
import {getAllClinicsPlan, updateplan} from "../../../../store/clinics/clinicSettings/clinic-plan";
import BackendPagination from '../../../../components/pagination/backendPagination';
import Waveloading from '../../../loading/Waveloading';


const Plan = () => {
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const clinicPlansStore = useSelector(state => state?.clinicPlansStore);
    const user = useSelector(state => state?.authStore?.user)
    const {currentPage} = clinicPlansStore
console.log("id",clinicPlansStore?.plan)

    useEffect(() => {

      (async () => {
        if (!clinicPlansStore?.plans?.length) {
          setLoading(true);
        }
        await dispatch(getAllClinicsPlan(params?.clinic_id));
        setLoading(false);
      })();
    }, [dispatch, params?.clinic_id]);
  
    const refreshData = async () => {
      setLoading(true);
      try {
        await dispatch(getAllClinicsPlan(params?.clinic_id));
         
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      setLoading(false); 
  };
    const handleEdit = (row) => {
      navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/edit-plan/${row?.id}`);
    };
  
    const handleClick = () => {
      if (params?.clinic_id) {
        navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/add-plan`);
      }
    };
    const fetchPageData = async(page) => {
      await dispatch(updateplan({ currentPage: page }))
      await refreshData() 

  }
  
    return (
      <Grid container p={'20px'}>
        <Grid item xs={12} sx={{ mb: '16px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', '@media (max-width: 420px)':{display:"block" }, }}>
          <FormControl sx={{ mr: '10px' }}>
            <SimpleInput search={search} setSearch={setSearch} />
          </FormControl>
          <CustomButton val={'Add Bundle'} mrt={"10px"} size={'large'} handleClick={handleClick} />
        </Grid>
<Grid container>


        <Grid item xs={12} >
        {loading ? <Waveloading /> :
            <CardTable
                search={search}
                handleEdit={handleEdit}
                type={'plan'}
                cardData={clinicPlansStore?.plans?.data}
            />
          }
      
     
    
        </Grid>
        <Grid item xs={12} style={{marginTop:"10px" }} >
      {clinicPlansStore?.plans?.meta?.last_page > 1 &&
            <BackendPagination
                count={clinicPlansStore?.plans?.meta?.last_page}
                currentPage={currentPage}
                handleOnChange={fetchPageData}
            />
        }
      </Grid>
      
      </Grid>

      </Grid>
    ); 
  };
  
  export default Plan;
  