import React, {useEffect, useState} from 'react';
import UserLayout from "../index";
import {useDispatch, useSelector} from "react-redux";
import {
    getAllActiveClinicsLocations,
    updateUserLocation
} from "../../../store/user/default-location";
import {
    Avatar,
    Box, Button,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import Loader from "../../../components/loader/loader";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import {MySwal} from "../../../services/utills";
import SimpleInput from "../../../components/form/simpleInput";
import {escapeRegExp} from "../../../components/table";
import Footer from '../../home/Footer';

const DefaultLocation = () => {
    const [loading, setLoading] = useState(false)
    const [locationList, setLocationList] = useState([])
    const [checked, setChecked] = useState(null);
    const [search, setSearch] = useState('');

    const handleToggle = (value) => () => {

        setChecked(value);
    };


    const dispatch = useDispatch()

    const userDefaultLocationStore = useSelector(state => state?.userDefaultLocationStore?.activeClinics)
    const authStore = useSelector(state => state?.authStore)
    const {user} = authStore


   const handleSaveLocation = async () => {
       setLoading(true)
    console.log("location",checked)
       if (checked?.id) {
           let finalData = {"provider_id": checked?.id}
           let res = await dispatch(updateUserLocation(finalData))
           localStorage.setItem('selected', JSON.stringify(checked));
           if (res?.payload?.success === 'true'){
               setLoading(false)
               await MySwal.fire({
                   icon: 'success',
                   title: 'Congratulations',
                   showConfirmButton:false, 
                   html:<Box sx={{mb: 4}}>
                       <Typography sx={{}}>
                           Your default spa location has been changed.
                       </Typography>
                       <Button
                           sx={{
                               mt:3,
                               backgroundColor: "#fff",
                               minWidth: "200px",
                               height: '62px',
                               border: "1px solid #666666",
                               borderRadius: '10px',
                               fontSize: '20px',
                               color: "#666666",
                               "&:hover": {
                                   backgroundColor: "#fff",
                                   border: "1px solid #666666",
                               },
                           }}
                           onClick={() => MySwal.close()}
                       >
                           Ok
                       </Button>
                   </Box>
               })
           }
           if (res?.payload?.errors) {
               const {errors} = res?.payload
           }
       }


       setLoading(false)
   }

    useEffect(() => {
        if (user?.id && user?.defaultProvider?.id) {
                let provider = {...user?.defaultProvider}
                setChecked(provider)
        }
    }, [user, user?.id])


    useEffect(() => {
        (async () => {
            if (!userDefaultLocationStore?.length) {
                setLoading(true)
                await dispatch(getAllActiveClinicsLocations())
                setLoading(false)
            }
        })()

    }, [dispatch])
    useEffect(() => {
        (async () => {
            if (userDefaultLocationStore?.length) {
                console.log("location",userDefaultLocationStore)
              setLocationList(userDefaultLocationStore)
                setSearch('')       
            }       
        })()

    }, [userDefaultLocationStore])

 

    return (
        <UserLayout>
            <Grid container sx={{ pb: '120px'}}>
                {loading ? <Loader fullSize/> : ""}

                <Grid item xs={12}>  
                    <Typography
                        sx={{
                            fontSize: "20px",
                            fontWeight:'600',
                            color: '#27272e',
                            letterSpacing: '.3px',
                            marginBottom: '16px'
                        }}>
                            Your Default Spa Location
                        </Typography>   
                </Grid>
                <Grid item xs={12} lg={8} xl={7}>
                    <FormControl fullWidth sx={{}}>
                        <SimpleInput
                            search={search}
                            setSearch={setSearch}
                            handleChange={(val)=>{
                                setSearch(val)
                                if (val!=='' || val?.length){
                                    const searchRegex = new RegExp(escapeRegExp(search), 'i')
                                    let filteredRows
                                    let addressFilter
                                    filteredRows = userDefaultLocationStore.filter(row => {
                                        return Object.keys(row).some(field => {
                                            return field === "name" && searchRegex.test(row[field].toString())
                                        })
                                    })
    
                                    if (filteredRows?.length> 0 ){ 
                                        setLocationList(filteredRows)
                                    } else {
                                        addressFilter = userDefaultLocationStore.filter(row => { 
                                            if (row?.address) {   
                                                let address = row?.address
                                                return Object.keys(address).some(f => {
                                                    return address[f] !== null && searchRegex.test(address[f].toString())
                                                })
                                            }
                                        })
                                        if (addressFilter?.length > 0) {
                                            setLocationList(addressFilter)   
                                        } else {
                                            setLocationList([])                      
                                        }
                                    }
                                }else {
                                    setLocationList(userDefaultLocationStore)
                                }
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={8} xl={7}>
                    <List sx={{width: '100%', bgcolor: 'background.paper',mt:'10px'}}>
                        {locationList?.length > 0 ? locationList?.map((item, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    mt:'10px',
                                    background: item?.id === checked?.id ? 'rgb(230, 218, 209)' :'#fff',
                                    p:0,   
                                    borderRadius:'5px',
                                    color: item?.id === checked?.id ? '#000' :'#000'  
                            }}
                                secondaryAction={   
                                    <IconButton 
                                        sx={{".Mui-checked":{
                                            color: item?.id === checked?.id ? '#626262 !important' :'#000'
                                        }}} onClick={handleToggle(item)} edge="end">

                                        <Checkbox
                                            checked={item?.id === checked?.id}
                                            disableRipple
                                        />
                                    </IconButton>
                                }
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(item)} dense>
                                    <ListItemAvatar>
                                        <Avatar alt={item?.name} sx={{textTransform:'uppercase'}}>{item?.name?.charAt(0) + item?.name?.charAt(1)}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item?.name}    
                                        secondary={
                                            item?.address?.address ? <React.Fragment>
                                               <Box as={'span'} sx={{display:'block'}}>  
                                                   <Typography
                                                       as={'span'}
                                                       sx={{
                                                           display:'block',
                                                           color: item?.id === checked?.id ? '#626262' :'#626262'
                                                       }}
                                                       variant="body2"
                                                   >
                                                       {item?.address?.address}
                                                   </Typography>
                                                   <Typography
                                                       as={'span'}
                                                       sx={{
                                                           display:'block',
                                                           color: item?.id === checked?.id ? '#626262' :'#626262'
                                                       }}
                                                       variant="body2"
                                                   >
                                                       {item?.address?.state},{item?.address?.postal_code}
                                                   </Typography>
                                               </Box>   
                                            </React.Fragment> : null
                                        }
                                    />

                                </ListItemButton>
                            </ListItem>
                        )): <ListItem sx={{textAlign:'center',mt:'20px',justifyContent:'center'}}>
                            Not Found
                        </ListItem>
                        }
                    </List>
                </Grid>
                {locationList?.length > 0 && <Grid item xs={12} lg={8} xl={7}
                       sx={{display: 'flex', justifyContent: 'end', mt: 2}}
                >
                    <Box>
                        <CustomButton handleClick={() => handleSaveLocation()} style={{ background:'#E6DAD1', border:'1px solid #E6DAD1', color:'#000' }}  val={'Update Spa Location'}/>
                    </Box>
                </Grid>}
            </Grid>

            <Box style={{marginLeft:'calc(-50vw + 50%)', marginRight:'calc(-50vw + 50%)' }}>
            <Footer />
</Box>
        </UserLayout>
    );
}

export default DefaultLocation;