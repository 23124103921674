import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {useFormik} from "formik";
import Loader from "../../../../components/loader/loader";
import {Box, Button, Grid, Typography} from "@mui/material";
import FormInput from "../../../../components/form/formInput";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {storeClinicCalender} from "../../../../store/clinics/clinicSettings/clinic-calender";
import CustomButton from "../../../../components/custom-button/customButton";
import {MySwal} from "../../../../services/utills";
import {confirmationButton} from "../../../crm/call-logs/Appointment";

const Calender = () => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)



    const validationSchema = Yup.object({
        client_id: Yup.string().required("Client id is Required"),
        client_secret: Yup.string().required("Client Secret is Required"),

    });
    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            client_id: '',
            client_secret: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {

            if (clinicData?.id){
                setLoading(true)
                let res = await  dispatch(storeClinicCalender({provider_id:clinicData?.id,...data}))
                if (res?.payload){
                    console.log(res?.payload)
                }
                setLoading(false)
            }
        },
    });

    useEffect(()=>{
        if (clinicData?.id && clinicData?.calender_setting?.length){
            try {
                const calenderData = clinicData?.calender_setting[0]
                setFieldValue('client_id',calenderData?.client_id || '')
                setFieldValue('client_secret',calenderData?.client_secret || '')
            }catch (e) {

            }
        }
    },[clinicData,clinicData?.id])
    const handleGoogleConnect = () => {
        if (clinicData?.id) {
            // Construct the URL with the clinicData.id as a query parameter
            const url = `https://api.3dlifestyle.ca/auth/google/${clinicData.id}`;
            // Open the URL in a new tab
            window.open(url, "_blank");
        } else {
            console.log("Clinic ID is not available");
        }
    }
    const handleCopyLink = async () => {
        const url = `https://api.3dlifestyle.ca/google/callback/${clinicData.id}`;

        navigator.clipboard.writeText(url);
        let timerInterval;
        await MySwal.fire({
            icon: 'success',
            title: 'URL Copied Successfully',
            showConfirmButton: false,
            html: (
                <Box sx={{mb: 2}}>
                    <Typography>
                        I will close in <b></b> milliseconds.
                    </Typography>
                    <Button
                        sx={{...confirmationButton, mt: "16px"}}
                        onClick={() => MySwal.close()}
                    >
                        Ok. Close It,
                    </Button>
                </Box>
            ),
            timer: 4000,
            timerProgressBar: true,
            didOpen: () => {
                const b = MySwal.getHtmlContainer().querySelector('b');
                timerInterval = setInterval(() => {
                    b.textContent = MySwal.getTimerLeft();
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        });
        // You can add more code here
    };


    return (
        loading ? <Loader/> :
            <form onSubmit={handleSubmit}>
                <Grid container p={'20px'} sx={{justifyContent:'space-between'}}>
                    <Grid item xs={12} sx={{display:'flex', justifyContent:'right', marginBottom: '20px'}}>
                        <CustomButton
                            size={'large'}
                            sx={{ marginRight: '5px'}}
                            mainColor={'#1b7f1d'}
                            subColor={'#fff'}
                            handleClick={handleCopyLink}
                            val={"Copy Redirect Url"}
                        />
                        <CustomButton
                            mainColor={'#ED1E45'}
                            val={'Connect With Google'}
                            handleClick={handleGoogleConnect}
                        />

                    </Grid>
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"client_id"}
                            label={"Client id"}
                            placeholder={'Type  your Client id'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} lg={5.5}>
                        <FormInput
                            name={"client_secret"}
                            label={"Client Secret"}
                            placeholder={'Type  your Client Secret'}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end'}}>
                        <SubmitButton val={"Update Calender Settings"}/>
                    </Grid>
                </Grid>
            </form>
    );
};

export default Calender;