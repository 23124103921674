import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiHeaders } from "../../lib/apiHeader";
import axios from "axios";
import apis from "../../lib/apis/apis";
import toast from "react-hot-toast";

export const getAllNotificationEvent = createAsyncThunk(
  "getAllNotificationEvent",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get(apis.get_all_notification_event, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      }
      if (response?.data?.success === false) {
        return {
          data: response.data,
          errors: true,
        };
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        return {
          data: [],
          errors: err?.response?.data?.errors,
        };
      }
    }
  }
);
export const storeNotificationEvent = createAsyncThunk('storeNotificationEvent', async (data) => {
  try {
    const response = await axios.post(apis.store_notification_event, {...data},{
      headers: apiHeaders()
    })
    return  response?.data

  }catch (err){

    if (err?.response?.status!==200){

      return {
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})

export const updateNotificationEvent = createAsyncThunk('updateNotificationEvent', async (data,{dispatch}) => {
  try {
    const response = await axios.post(apis.update_notification_event, {...data},{
      headers: apiHeaders()
    })

    if (response?.data?.success==='true'){
      await dispatch(getAllNotificationEvent())
      return  response.data
    }else {
      return response?.data
    }
  }catch (err){
    console.log(err)
    if (err?.response?.status!==200){
      return {
        data :err?.response,
        errors:true
      }
    }
  }
})
export const deleteNotificationEvent = createAsyncThunk('deleteNotificationEvent', async (id,{dispatch}) => {
  try {
    const response = await axios.get(apis.delete_notification_event+id,{
      headers: apiHeaders()
    })

    if (response?.data?.success ==='true'){
      await dispatch(getAllNotificationEvent())
      toast.success("Notification Event Deleted Successfully",{duration:3000})

    }else {
      console.log(response?.data)
    }

  }catch (err){
    console.log(err)
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})
    }
  }
})

export const getNotificationEventByID = createAsyncThunk('getNotificationEventByID', async (id) => {
  try {
    const response = await axios.get(apis.get_notification_event_by_id+id,{
      headers: apiHeaders()
    })
    return  response?.data

  }catch (err){
    if (err?.response?.status!==200){
      return {
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})
export const notificationEventSlice = createSlice({
  name: "notificationEventSlice",
  initialState: {
    notificationEvents: [],
    meta: {},
    links: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllNotificationEvent.fulfilled, (state, action) => {
      state.notificationEvents = action.payload;
      state.meta = action.payload.meta;
      state.links = action.payload.links;
    });
  },
});

export default notificationEventSlice.reducer;
