import React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import { useMediaQuery } from '@mui/material';







const CrmMonthView = ({startDate,setOpenDrawer,monthViewData,handleDateClick}) => {
    const monthStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const monthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    const daysInMonth = monthEnd.getDate();
    const firstDayOfWeek = monthStart.getDay() === 0 ? 7 : monthStart.getDay();
    // const lastDayOfWeek = monthEnd.getDay();


    const weeks = [];
    let days = [];

    // Add empty cells for days before the first day of the month
    for (let i = 1;i < firstDayOfWeek; i++) {
        days.push(<TableCell key={`empty-${i}`}>
            <Box sx={{
                height: {sm:'120px', xs:'auto'},
                width: {sm:'159px', xs:'25px'},
            }}>
            </Box>
        </TableCell>);
    }




    const isSmallScreen = useMediaQuery('(max-width: 600px)');
  
    // Function to render the abbreviated day names
    const renderDayName = (dayName) => {
      if (isSmallScreen) {
        switch (dayName) {
          case 'Monday':
            return 'M';
          case 'Tuesday':
            return 'T';
          case 'Wednesday':
            return 'W';
          case 'Thursday':
            return 'T';
          case 'Friday':
            return 'F';
          case 'Saturday':
            return 'S';
          case 'Sunday':
            return 'S';
          default:
            return dayName;
        }
      }
      return dayName;
    };




    // Object.entries(monthViewData)?.length > 0 &&
    // Object.entries(monthViewData).forEach(([key, appointments]) => {
    //     let i = moment(key)?.date()
    //     days.push(<TableCell key={i}>
    //         <Box sx={{
    //             height: '120px',
    //             width: '120px',
    //             display:'flex',
    //             flexDirection:'column',
    //             justifyContent:'space-between'
    //         }}>
    //             <Box>
    //                 {i}
    //             </Box>
    //             {appointments?.length > 0 &&
    //                 <Box
    //                     sx={{
    //                         display:"flex",
    //                         gap:'10px',
    //                         alignItems:'center',
    //                         flexWrap:'wrap',
    //                         cursor:'pointer',
    //                     }}
    //                     onClick={()=>setOpenDrawer(true)}
    //                 >
    //                     {
    //                         appointments.map((appointment,appointmentIndex)=>(
    //                             <Box
    //                                 key={appointmentIndex}
    //                                 sx={{
    //                                     height:'10px',
    //                                     width:'10px' ,
    //                                     borderRadius: '2px',
    //                                     background:appointment?.status_color,
    //                                 }}
    //                             >
    //                             </Box>
    //                         ))
    //                     }
    //                 </Box>
    //             }
    //                 {/*<Box sx={{display:"flex",gap:'10px',alignItems:'center',flexWrap:'wrap',cursor:'pointer'}} onClick={()=>setOpenDrawer(true)}>*/}
    //                 {/*    <Box sx={{height:'10px',width:'10px' ,borderRadius: '2px',background:'#219653'}}> </Box>*/}
    //                 {/*    <Box sx={{height:'10px',width:'10px',borderRadius: '2px' ,background:'#323681'}}> </Box>*/}
    //                 {/*    <Box sx={{height:'10px',width:'10px' ,borderRadius: '2px',background:'#EB5757'}}> </Box>*/}
    //                 {/*</Box>*/}
    //         </Box>
    //     </TableCell>);
    //
    //     console.log((i-1 + firstDayOfWeek) % 7 , 0)
    //     if ((i-1 + firstDayOfWeek) % 7 === 0) {
    //         weeks.push(<TableRow key={`week-${i}`}>{days}</TableRow>);
    //         days = [];
    //     }
    // })

    for (let i = 0; i < daysInMonth; i++) {
        const  date =   new Date(startDate.getFullYear(), startDate.getMonth(), i+1);
        let appointments = monthViewData[moment(date).format('YYYY-MM-DD')]

        days.push(<TableCell key={i}>
            <Box sx={{
                height: {sm:'120px', xs:'auto'},
                width: {sm:'159px', xs:'25px'},
                display:'flex',
                flexDirection:'column',
                justifyContent:'space-between'
            }}>
                <Box
                    sx={{
                        pb:"5px",
                        cursor:'pointer',
                    }}
                    onClick={()=>handleDateClick(date)}
                >
                    {i + 1}
                </Box>
                {appointments?.length > 0 &&
                    <Box
                        sx={{
                            display:"flex",
                            gap:{sm:'10px', xs:'5px'},
                            mt:{sm:0, xs:2},
                            alignItems:'end',
                            flexWrap:'wrap',
                            cursor:'pointer',
                            height:{sm:'50px', xs:'auto'}

                        }}
                        onClick={()=>setOpenDrawer(appointments)}
                    >
                        {
                            appointments.map((appointment,appointmentIndex)=>(
                                <Box
                                    key={appointmentIndex}
                                    sx={{
                                        height:{sm:'10px', xs:'5px'},
                                        width:{sm:'10px', xs:'5px'} ,
                                        borderRadius: '2px',
                                        background:appointment?.status_color,
                                    }}
                                >
                                </Box>
                            ))
                        }
                    </Box>
                }
            </Box>
        </TableCell>);

        if ((i + firstDayOfWeek) % 7 === 0) {
            weeks.push(<TableRow key={`week-${i}`}>{days}</TableRow>);
            days = [];
        }

    }

    if (days.length !== 0) {
        const emptyCellsCount = 7 - days.length;
        for (let i = 0; i < emptyCellsCount; i++) {
            days.push(
                <TableCell key={`empty-end-${i}`}>
                    <Box sx={{height: {sm:'120px', xs:'auto'},
                width: {sm:'159px', xs:'25px'},}}> </Box>
                </TableCell>
            );
        }

        // Add the last row to the weeks array
        weeks.push(<TableRow key={`week-end`}>{days}</TableRow>);
    }


    return (





        
        <TableContainer component={Paper} sx={{boxShadow: 'none',}}>
            <Table
                aria-label="simple table"
                sx={{
                    tableLayout:{sm:'unset', xs:'fixed'},
                    boxShadow: 'none',
                    "td , th": {
                        border: "1px solid #E0E0E0",
                        verticalAlign:"top",
                        padding:'8px'
                      
                    },
            }}>
                <TableHead>
                    <TableRow>
                    <TableCell sx={{ background: '#fff' }}>
        {renderDayName('Monday')}
      </TableCell>
      <TableCell sx={{ background: '#fff', }}>
        {renderDayName('Tuesday')}
      </TableCell>
      <TableCell sx={{ background: '#fff', }}>
        {renderDayName('Wednesday')}
      </TableCell>
      <TableCell sx={{ background: '#fff', }}>
        {renderDayName('Thursday')}
      </TableCell>
      <TableCell sx={{ background: '#fff', }}>
        {renderDayName('Friday')}
      </TableCell>
      <TableCell sx={{ background: '#fff', }}>
        {renderDayName('Saturday')}
      </TableCell>
      <TableCell sx={{ background: '#fff', }}>
        {renderDayName('Sunday')}
      </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {weeks}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default CrmMonthView;