import React, {useEffect, useState} from 'react';
import Loader from "../../../../components/loader/loader";
import {Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import FormInput, {labelStyle} from "../../../../components/form/formInput";
import CustomButton from "../../../../components/custom-button/customButton";
import SubmitButton from "../../../../components/custom-button/submitButton";
import * as yup from "yup";
import {useFormik} from "formik";
import {
    getClinicsTreatmentFormByID,
    storeClinicsTreatmentForm,
    updateClinicsTreatmentForm
} from "../../../../store/clinics/clinicSettings/clinic-treatment-form";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import FormAutoComplete from "../../../../components/form/FormAutoComplete";
import RichTextField from "../../../../components/rich-textfield/RichTextField";
import Waveloading from '../../../loading/Waveloading';


const options =[
    'Customer',
    'Service',
    'Consent Form',
    'Questionnaire',
]
const AddTreatmentForm = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const user = useSelector(state => state?.authStore?.user)


    const validationSchema = yup.object({
        name: yup.string().min(3, "Name is Too Short").required('Role name is required'),
        status: yup.string().required('Status is required'),
        type: yup.string().typeError("Type is required").required('Type is required'),
        description: yup.string().typeError("Description is required").required('Dype is required'),
    });


    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            name: '',
            status: 'In-Active',
            type: null,
            description:''
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)

            let res
            if (params?.treatment_form_id) {
                res = await dispatch(updateClinicsTreatmentForm({...data,provider_id:params?.clinic_id}))
            } else {
                res = await dispatch(storeClinicsTreatmentForm({...data,provider_id:params?.clinic_id}))
            }
            if (res?.payload?.errors) {
                const {errors} = res?.payload
                console.log(errors)
                if (errors?.name) {
                    setFieldTouched("name", true)
                    setFieldError("name", errors?.name)
                }

            } else if (res?.payload?.success === "true") {
                toast.success(`Treatment Form ${params?.treatment_form_id ? "updated" : "Stored"} Successfully`, {duration: 3000})
                navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/treatment-form`)
            }

            setLoading(false)
        },
    });


    useEffect(() => {
        (async () => {
            if (params?.treatment_form_id) {
                setLoading(true)
                let res = await dispatch(getClinicsTreatmentFormByID(params?.treatment_form_id))
                if (res?.payload?.data?.id) {
                    await setFieldValue("name", res?.payload?.data?.name)
                    await setFieldValue('status', res?.payload?.data?.status || 'In-Active')
                    await setFieldValue('type', res?.payload?.data?.type || '')
                    await setFieldValue('description', res?.payload?.data?.description || '')
                    await setFieldValue('id', res?.payload?.data?.id)
                }
                setLoading(false)
            }

        })()
    }, [params?.treatment_form_id])
    return (loading ?  <Waveloading /> :
        <Grid container sx={{ p:{sm:'40px',xs:1 } }}>
            <Grid item lg={8} xs={12}>
                <form onSubmit={handleSubmit}>
                    <FormInput
                        name={"name"}
                        label={"Name"}
                        placeholder={'Type  Treatment Form'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />

                    <FormAutoComplete
                        name={"type"}
                        options={options}
                        values={values}
                        multiple={false}
                        errors={errors}
                        touched={touched}
                        label={'Business type'}
                        setFieldValue={setFieldValue}
                    />

                    <RichTextField
                        values={values}
                        errors={errors}
                        name={"description"}
                        touched={touched}
                        handleChange={handleChange}
                        label={'Description'}
                    />

                    <FormControl sx={{mb: '16px'}}>
                        <FormLabel sx={labelStyle}>Status</FormLabel>
                        <RadioGroup
                            row
                            name="status"
                            value={values?.status}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="In-Active" control={<Radio/>} label="InActive"/>
                            <FormControlLabel value="Active" control={<Radio/>} label="Active"/>
                        </RadioGroup>
                    </FormControl>

                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box sx={{mr: '16px'}}>
                            <CustomButton
                                mainColor={'#ED1E45'}
                                val={'Cancel'}
                                handleClick={() => {
                                    navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/treatment-form`)
                                }}
                            />
                        </Box>
                        <SubmitButton val={params?.treatment_form_id ? 'Update Treatment Form' : 'Add Treatment Form'}/>
                    </Box>
                </form>
            </Grid>
        </Grid>
    )
};

export default AddTreatmentForm;