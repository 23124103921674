import React, { useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Card,
    Container,
    Divider,
    Link as MuiLink,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import Typography from "@mui/material/Typography";
import moment from "moment";
import Button from "@mui/material/Button";
import useStyles from "../../../booking-widget/styles";
import { useDispatch } from "react-redux";
import { getAppointmentDetails } from "../../../../store/crm/appointment";
import Loader from "../../../../components/loader/loader";

import 'add-to-calendar-button';
import { atcb_action } from "add-to-calendar-button";
import AppointmentReschedule from "../../../../components/card-appointment/AppointmentReschedule";
import ribbon from "../../../../assets/images/ribbon.svg";
import redRibbon from "../../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../../assets/images/yellow-badg.svg";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
    box,
    content,
    heading,
    ribbonwrapper,
    textul,
    wallet
} from "../../../../components/card-appointment/CardAppointment";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import Waveloading from '../../../loading/Waveloading';


const AppointmentConfirmation = () => {
    const [loading, setLoading] = useState(false)
    const [appointmentDetails, setAppointmentDetails] = useState(null)

    const classes = useStyles();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { appointment_id } = useParams()

    const [reloadTrigger, setReloadTrigger] = useState(false);

    // useEffect to listen for changes in reloadTrigger
    useEffect(() => {
        if (reloadTrigger) {
            window.location.reload();
        }
    }, [reloadTrigger]);

    useEffect(() => {
        (async () => {
            if (appointment_id && appointment_id !== undefined) {
                await fetchAppointment()

            }
        })()
    }, [appointment_id])

    const fetchAppointment = async () => {
        setLoading(true)
        let { payload } = await dispatch(getAppointmentDetails(appointment_id))
        if (payload?.id) {

            setAppointmentDetails(payload)

        }
        setLoading(false)
    }
    const handleNavigate = (to) => {
        navigate(to)
    }
    const triggerReload = () => {
        fetchAppointment()
        setReloadTrigger(true);
        // Call fetchAppointment or any other logic if needed
    };
    const handleAddCalender = () => {
        if (appointmentDetails?.id) {

            let config = {
                name: appointmentDetails?.service_name + " at " + appointmentDetails?.provider?.name,
                // description: "Check out the maybe easiest way to include Add to Calendar Buttons to your web projects:[br]→ [url]https://add-to-calendar-button.com/",
                startDate: appointmentDetails?.date,
                startTime: appointmentDetails?.twenty_four_hours_start_time,
                endTime: appointmentDetails?.twenty_four_hours_end_time,
                location: appointmentDetails?.provider?.address?.address,
                options: ["Google"],
                timeZone: "America/Los_Angeles",
            }
            const button = document.getElementById('my-default-button');
            atcb_action(config, button)
        }
    }

    console.log("appoitment store", appointmentDetails)
    return (
        <Box>
            {loading ? <Waveloading /> :
                <Container maxWidth="md">
                    <Typography
                        variant="h4"
                        color="initial"
                        sx={{
                            fontFamily: "helvetica-lt-std-bold",
                            textAlign: "center",
                        }}
                    >
                        Appointment Confirmation!
                    </Typography>
                    <Typography
                        variant="h6"
                        color="initial"
                        sx={{
                            fontFamily: "helvetica-lt-std-roman",
                            color: "#000",
                            mt: 1,
                            textAlign: 'center'
                        }}
                    >
                        We are looking forward to seeing you on{" "}
                        {
                            appointmentDetails?.date ?
                                <Typography as={'span'}>
                                    {moment(appointmentDetails?.date).format('MMMM DD')} at {appointmentDetails?.start_time}
                                </Typography> :
                                <Typography
                                    sx={{
                                        color: '#F25022'
                                    }}
                                    as={'span'}
                                >
                                    Time Not Set
                                </Typography>
                        }
                    </Typography>
                    <Card
                        sx={{
                            mt: 3,
                        }}
                        className={classes.card}
                    >
                        <Container
                            sx={{
                                p: '24px',
                                display: 'flex',
                                justifyContent: 'space-around',
                                flexWrap: { xs: 'wrap', sm: 'nowrap' }
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    flexBasis: { xs: '100%', sm: 'auto' }
                                }}
                            >
                                <Box>
                                    <Typography sx={heading}>Customer Name:</Typography>
                                    <Typography sx={{ ...textul, textTransform: 'capitalize' }}>
                                        {appointmentDetails?.customer?.name}
                                    </Typography>
                                </Box>
                                {appointmentDetails?.customer?.is_membered === 1 && <Box sx={ribbonwrapper}>
                                    <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                    <Typography style={{ color: "#219653" }}>
                                        {appointmentDetails?.provider?.name} Member
                                    </Typography>
                                </Box>}
                                <Box>
                                    <MuiLink href="#" color="inherit">
                                        Member ID {appointmentDetails?.customer?.id}
                                    </MuiLink>
                                </Box>
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Divider
                                    orientation="vertical"
                                    style={{ borderColor: "#9A9797", height: "100%", width: "2px" }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    flexBasis: { xs: '100%', sm: 'auto' },
                                    mt: { xs: 3, sm: 0 }
                                }}
                            >
                                <Box sx={{ ...wallet, mt: '0' }}>
                                    <Typography sx={heading}>Wallet:</Typography>
                                    <Typography sx={content}>${appointmentDetails?.customer?.wallet_point}</Typography>
                                </Box>
                                <Box sx={{ ...wallet, mt: '0' }}>
                                    <Typography sx={heading}>Phone:</Typography>
                                    <Typography sx={content}>{appointmentDetails?.customer?.phone}</Typography>
                                </Box>
                                <Box sx={{ ...wallet, mt: '0' }}>
                                    <Typography sx={heading}>Email:</Typography>
                                    <Typography sx={content}>{appointmentDetails?.customer?.email}</Typography>
                                </Box>
                            </Box>
                        </Container>
                    </Card>
                    <Card
                        sx={{
                            textAlign: "center",
                            mt: 3,
                        }}
                        className={classes.card}
                    >
                        <Container maxWidth="sm" sx={{ p: '24px' }}>
                            <Box
                                sx={{
                                    border: "1px solid #2F80ED",
                                    p: '20px',
                                    borderRadius: "8px",
                                    width: 'max-content',
                                    mb: "16px",
                                    cursor: 'pointer',
                                    mx: "auto",
                                }}
                                onClick={() => navigate(`/crm/appointment/details/${appointmentDetails?.id}`)}
                            >
                                <Typography sx={content} style={{ color: '#2F80ED' }}>Booking Ref</Typography>
                                <Typography variant="h6" style={{ color: '#2F80ED' }}>{appointmentDetails?.id}</Typography>
                            </Box>
                            <Box
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                    color: 'black',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "center",
                                }}
                            >
                                <Avatar
                                    sx={{
                                        background: "#006AFF",
                                        textTransform: 'uppercase',
                                        height: '60px',
                                        width: '60px',
                                    }}
                                    src={appointmentDetails?.provider?.profile_image}
                                    alt={appointmentDetails?.provider?.name.charAt(0) || "3"}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        ml: '16px',
                                        fontFamily: 'helvetica-lt-std-bold',
                                        fontSize: '24px',
                                    }}
                                >
                                    {appointmentDetails?.provider?.name || "Select Location"}
                                </Typography>
                            </Box>
                            {appointmentDetails?.staff?.name ?
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 3,
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            color="initial"
                                            className={classes.roundedName}
                                        >
                                            {appointmentDetails?.staff?.name.charAt(0)}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            color="initial"
                                            sx={{ fontFamily: "helvetica-lt-std-bold" , fontWeight:'300' }}
                                        >
                                            {appointmentDetails?.staff?.name}
                                        </Typography>
                                    </Box>
                                </>
                                :
                                <>
                                    <Typography
                                        variant="h7"
                                        color="initial"
                                        sx={{ fontFamily: "helvetica-lt-std-bold", }}
                                    >
                                        No Preference On Staff
                                    </Typography>
                                </>
                            }
                            <Box sx={{ mt: 4 }}>
                                <Typography
                                    variant="h6"
                                    color="initial"
                                    sx={{
                                        fontFamily: "helvetica-lt-std-bold",
                                        color: appointmentDetails?.date ? '#000' : '#F25022',
                                    }}
                                >
                                    {appointmentDetails?.date ?
                                        moment(appointmentDetails?.date).format("dddd, MMMM D, YYYY")
                                        : "Date and Time Not Set"
                                    }

                                </Typography>

                                {appointmentDetails?.date ?
                                    <Typography
                                        variant="h6"
                                        color="initial"
                                        sx={{
                                            fontFamily: "helvetica-lt-std-bold",
                                            mt: "5px",
                                        }}
                                    >
                                        {appointmentDetails?.start_time} - {appointmentDetails?.end_time}
                                    </Typography> : ""
                                }


{appointmentDetails?.services && appointmentDetails.services.length > 0 ? (
    <div>

        {appointmentDetails.services.map((service, index) => (
            <div key={index}>

                <Typography variant="h6"
                    color="initial"
                    sx={{
                        fontFamily: "helvetica-lt-std-bold",
                        mt: "5px",
                        fontSize:'25px',
                        
                    }}>


                    {service.name}
                </Typography>
            </div>
        ))}
    </div>
) : (
    <div>No services found</div>
)}
                                <Typography
                                    variant="h4"
                                    color="initial"
                                    sx={{
                                        fontFamily: "helvetica-lt-std-bold",
                                        mt: "5px",
                                        fontSize:'20px'
                                    }}
                                >
                                    
                                    
                                    {appointmentDetails?.service_name}
                                    {appointmentDetails?.type === "Booking"
                                        ? ''
                                        : appointmentDetails?.type === "Consultation"
                                            ? " (In Person Consultation)"
                                            : " (Virtual)"
                                    }
                                </Typography>

                                {/* {
  Array.isArray(appointmentDetails?.services?.staff) && appointmentDetails?.services?.staff.length > 0 ? (
    appointmentDetails?.services?.staff.map((item, index) => (
      <Box key={index} style={{display: "flex", alignItems: "center",marginTop:"6px"}}>
        <AccountCircleIcon  style={{fontSize:"44px"}}/>
        <Typography
          variant="h4"
          color="initial"
          sx={{  fontFamily: "helvetica-lt-std-bold", }}
        >
          {item?.name}

        </Typography>
      </Box>
    ))
  ) : (

    <Typography
      variant="h4"
      color="initial"
      sx={{  fontFamily: "helvetica-lt-std-bold", }}
    >
No Preference , Any staff
    </Typography>

  )
} */}
                            </Box>
                            {/*{appointmentDetails?.services?.length > 0 && appointmentDetails?.services.map((item,index)=>(*/}
                            {/*    <Typography*/}
                            {/*        key={index}*/}
                            {/*        variant="h6"*/}
                            {/*        color="initial"*/}
                            {/*        sx={{*/}
                            {/*            mt: 2,*/}
                            {/*            fontFamily: "helvetica-lt-std-roman",*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        {item?.name}*/}
                            {/*    </Typography>*/}
                            {/*))}*/}

                            <Box sx={{ mt: 3 }}>
                                {appointmentDetails?.provider?.address?.address && <Typography
                                    variant="h6"
                                    color="initial"
                                    className={classes.grayColor}
                                >
                                    {appointmentDetails?.provider?.address?.address}
                                </Typography>}

                                {appointmentDetails?.provider?.phone && <Typography
                                    variant="h6"
                                    color="initial"
                                    className={classes.grayColor}
                                >
                                    {appointmentDetails?.provider?.phone}
                                </Typography>}
                            </Box>
                            {/*<Box sx={{ mt: 2 }}>*/}
                            {/*    <Button*/}
                            {/*        onClick={handleAddCalender}*/}
                            {/*        variant="contained"*/}
                            {/*        id={"my-default-button'"}*/}
                            {/*        className={classes.buttonStyle}*/}
                            {/*        sx={{*/}
                            {/*            py:'10px',*/}
                            {/*            background: "#006AFF",*/}
                            {/*            fontSize:{xs:'15px', md:'20px'}*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        Add on Calender*/}
                            {/*    </Button>*/}
                            {/*</Box>*/}

                            {/* Relevent Links */}

                            <AppointmentReschedule
                                appointment={appointmentDetails}
                                isConfirm={true}
                                triggerReload={triggerReload}
                                fetchAppointment={fetchAppointment}
                            />
                            <Box sx={{
                                mt: "5px",
                                // pb:{xs:3, md:6}   
                            }}>
                                <Typography
                                    variant="h6"
                                    color="initial"
                                    className={classes.blueColor}
                                    onClick={() => handleNavigate('/crm/add-appointment')}
                                >
                                    Book New Appointment
                                </Typography>
                            </Box>
                        </Container>
                        <Box></Box>
                    </Card>
                    {appointmentDetails?.plan?.id && <Card
                        sx={{
                            mt: 3,
                        }}
                        className={classes.card}
                    >
                        <Container
                            sx={{
                                p: '24px',
                            }}
                        >
                            <TableContainer
                                sx={{
                                    mt: "16px",
                                }}
                            >
                                <Table
                                    size="small"
                                    aria-label="a dense table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Session No</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Date & Time</TableCell>
                                            <TableCell>Booking Ref</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {appointmentDetails?.related_appointments?.length > 0 &&
                                            appointmentDetails?.related_appointments.map((row, rowIndex) => (
                                                <TableRow
                                                    key={rowIndex}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell scope="row">
                                                        <Box
                                                            sx={{
                                                                width: "max-content",
                                                                borderRadius: "8px",
                                                                p: '5px 10px',
                                                                border: `1px solid ${row?.status_color || 'black'}`,
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                navigate(`/crm/appointment/confirmation/${row?.id}`)
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: row?.status_color || 'black',
                                                                }}
                                                            >
                                                                Session No {row.current_session}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{ color: row?.status_color }}
                                                        >
                                                            {row.status}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            sx={{
                                                                color: row?.date ? '#000' : '#F25022',
                                                            }}
                                                        >
                                                            {row?.date ? moment(row?.date).format('MMMM DD, YYYY') : 'Not Set'}
                                                        </Typography>
                                                        <Typography>
                                                            {row?.date ? row?.start_time + " - " + row?.end_time : ''}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box
                                                            sx={{
                                                                width: "max-content",
                                                                borderRadius: "8px",
                                                                p: '5px 10px',
                                                                border: `1px solid ${row?.status_color || 'black'}`,
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                navigate(`/crm/appointment/confirmation/${row?.id}`)
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: row?.status_color || 'black',
                                                                }}
                                                            >
                                                                Booking Ref {row?.id}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Container>
                    </Card>
                    }
                </Container>
            }
        </Box>
    );
};

export default AppointmentConfirmation;