import React, {useState} from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { AiOutlineSearch } from "react-icons/ai";
import {escapeRegExp} from "../table";

const SearchField = ({ setFilterData ,totalData,setPage,isAppointment,isServices,allServicesCategories}) => {
    const [search, setSearch] = useState('')

    const handleChange = (e) => {
        const {value} = e.target
        setSearch(value)
        if (value!=='' || value?.length){
            const searchRegex = new RegExp(escapeRegExp(value), 'i')
            let filteredRows

            if (isServices){
                filteredRows = []
                    allServicesCategories?.length &&
                    allServicesCategories.forEach((serviceCategory)=>{
                        let result =[]
                        result = serviceCategory?.services?.length &&
                        serviceCategory?.services?.filter((row) => {
                            return searchRegex.test(row?.name.toString())
                        })
                        filteredRows = [...result,...filteredRows]
                    })
            }else if (isAppointment){
                filteredRows = totalData?.length && totalData.filter((row) => {
                    return searchRegex.test(row?.customer?.name.toString())
                })
            }else {
                filteredRows = totalData?.length && totalData.filter(row => {
                    return Object.keys(row).some(field => {
                        return row[field] !== null && searchRegex.test(row[field].toString())
                    })
                })
            }

            if (filteredRows?.length> 0){
                if (setPage){
                    setPage(1)
                }
                setFilterData(filteredRows)
            }else {
                setFilterData([])
            }
        }else {
            setFilterData(totalData)
        }
    }
    return (
        <TextField
            name="search"
            value={search}
            sx={{
                background: "#FFFFFF",
                border: "1px solid #4F4F4F",
                borderRadius: "4px",
                ".MuiOutlinedInput-notchedOutline": {
                    borderWidth: "0!important"
                },
                ".MuiInputBase-input": {
                    borderColor: "#4F4F4F",
                    height: "10px",
                    minHeight: "unset",
                    "&:focus": {
                        borderColor: "#EDF2F7",
                        outline: "none",
                        input: {
                            height: "11px",
                        },
                    },
                },
            }}
            onChange={handleChange}
            placeholder="Search by name"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton>
                            <AiOutlineSearch style={{fontSize: "1.5rem"}}/>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default SearchField;
