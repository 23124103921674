import { Box, CardMedia, Slide, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Login from '../login';
import ChatIcon from '@mui/icons-material/Chat';
import homeoutline from '../../assets/images/homeoutline.png'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import booking from '../../assets/images/Vector (Stroke).png'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import menu from '../../assets/images/menu - home.png'
import menu from '../../assets/images/homeIcon.png'
import plan from '../../assets/images/wallet2.png'
import profile from '../../assets/images/user.png'

import { useState } from 'react';
const Footer = ({zIndexContact,SelectedLocation}) => {

  const [activeButton, setActiveButton] = useState( localStorage.getItem('activeButton') || 'home');
  const [loginOpen, setOpenLogin] = useState(false);
    const authStore = useSelector((state) => state?.authStore);
    const navigate = useNavigate();
    const location = useLocation();

    const selectedLocation = location?.state?.selectedClinic;
    const selectedlocation = location?.state?.selectedlocation;
// console.log("location in footer",selectedLocation,selectedlocation)
    const { user } = authStore;
    const shouldRedirectToLogin = !user?.id || user?.role_id !== 3;
    const getActiveButtonFromPath = (path) => {
      if (path.includes('/user/bookings')) return 'booking';
      if (path.includes('/user/membership')) return 'member';
      if (path.includes('/user/my-account')) return 'profile';
      
      // ... add other paths here
      return 'home'; // default active button
    };
    useEffect(() => {
      // Update the active button state when the location changes
      const activeButton = getActiveButtonFromPath(location.pathname);
      setActiveButton(activeButton);
      localStorage.setItem('activeButton', activeButton);
    }, [location]);
const handleBoxClick = () => {  
  setActiveButton('booking');   
  if (shouldRedirectToLogin) {            
    setOpenLogin(true)
  } else {
    
    navigate('/user/bookings');
  }
  setActiveButton('booking');
  localStorage.setItem('activeButton', 'booking');
};
const handleplan = () => {
  setActiveButton('member');
  if (shouldRedirectToLogin) {
    setOpenLogin(true)
  } else {
   
    navigate('/user/membership');
  }
  setActiveButton('member');
  localStorage.setItem('activeButton', 'member');
};
const handleHome = () => {
  setActiveButton('home');
  if (shouldRedirectToLogin) {
    setOpenLogin(true)
  } else {
    console.log("location in footer",selectedLocation,SelectedLocation)
    navigate('/home', { state: { selectedLocation: selectedLocation ||SelectedLocation } });;
  }
  setActiveButton('home');
  localStorage.setItem('activeButton', 'home');
 

};
const handleprofile = () => {
 
  if (shouldRedirectToLogin) {
    setOpenLogin(true)
   
  } else {
    // Navigate to the booking page
    navigate('/user/my-account');
   
  }
  setActiveButton('profile');
  localStorage.setItem('activeButton', 'profile');
};
  return (
    <Box sx={{ display:{sm:'none', xs:'block'} }}>
     <Box sx={{ position:'fixed',bottom:'0',left: 0, right: 0, width: '100vw', zIndex:zIndexContact}}>
      <Box sx={{ position:'fixed',bottom:'0',left: 0, right: 0, width: '100vw', display:'flex',justifyContent:'space-between', backgroundColor:'#fff',zIndex:'999', boxShadow:'0px -18.000001907348633px 37px 0px rgba(0, 0, 0, 0.5)',borderRadius:'11px', width:'100vw',margin:'0 auto', p:{xs:'10px 15px 20px 15px',sm:'10px 30px 30px 30px',
    },'@media (max-width:340px)':{
     p:"10px 5px 10px 5px"
   }
    }} className="footer-wrappper">
      <Box  onClick={handleHome} sx={{backgroundColor:activeButton === 'home' ? 'rgba(239, 230, 224, 1)' : 'transparent',borderRadius:'7px',p:'8px',display:'flex',alignItems:'center',flexDirection:'column',mr:{xs:'8px',sm:'0px'},'@media (max-width:340px)':{
                          marginLeft:"6px",
                          p:" 8px 5px",
                        } }}>
         <CardMedia
                  sx={{ width:{xs:'20px',sm:'30px',} }}
                  component="img"
                  alt="Descriptive alt text"
                  image={homeoutline} 
                />
         <Typography variant="body1" sx={{ fontWeight:'300',fontSize:{xs:'12px',sm:'18px'}, mt:'10px', color:'rgba(30, 30, 30, 1)', }}>
            Home
            </Typography>
         </Box>
        
        <Box  onClick={handleBoxClick}  
         sx={{   backgroundColor:
      activeButton === 'booking' ? 'rgba(239, 230, 224, 1)' : 'transparent',
       borderRadius:'7px',p:'8px',display:'flex',alignItems:'center',flexDirection:'column' ,mt:'5px'}}>
      <CardMedia
               sx={{ width:{xs:'18px',sm:'26px',}  }}
               component="img"
               alt="Descriptive alt text"  
               image={booking} 
             />
      <Typography variant="body1" sx={{ fontWeight:'300',fontSize:{xs:'12px',sm:'18px'},mt:'10px', color:'rgba(30, 30, 30, 1)', }}>
      Bookings
         </Typography>
      </Box>
            
      <Box
          onClick={handleHome}
          sx={{
            borderRadius: "7px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CardMedia
            sx={{
              width: { xs: "80%", sm: "100%" },
              mt: { xs: "-30px", sm: "-40px" },
              
              '@media (max-width:310px)':{
                width:'45px',
                position:'relative',
                right:'1px'
              },
            }}
            component="img"
            alt="Descriptive alt text"
            image={menu}
          />
        </Box>
     
     
      <Box  onClick={handleplan} sx={{ backgroundColor:
      activeButton === 'member' ? 'rgba(239, 230, 224, 1)' : 'transparent', borderRadius:'7px',p:'8px',display:'flex',alignItems:'center',flexDirection:'column' }}>
      <CardMedia
               sx={{ width:{xs:'20px',sm:'30px',} }}
               component="img"
               alt="Descriptive alt text"
               image={plan} 
             />
             {/* <ChatIcon sx={{ width:{xs:'20px',sm:'30px', color:'#a0a0a0'} }}/> */}
      <Typography variant="body1" sx={{ fontWeight:'300',fontSize:{xs:'12px',sm:'18px'},mt:'10px', color:'rgba(30, 30, 30, 1)', }}>
         Member
         </Typography>
      </Box> 
              
     
     
     
        <Box onClick={handleprofile} sx={{ backgroundColor:activeButton === 'profile' ? 'rgba(239, 230, 224, 1)' : 'transparent', borderRadius:'7px',p:'8px',display:'flex',alignItems:'center',flexDirection:'column' ,ml:{xs:'5px',sm:'0px'} ,'@media (max-width:340px)':{
       ml:'0px',
     },'@media (max-width:319px)':{
      p:" 8px 5px",
      mr:'6px'
     } }}>
        <CardMedia
                 sx={{ width:{xs:'20px',sm:'30px'} }}
                 component="img"
                 alt="Descriptive alt text"
                 image={profile} 
               />
        <Typography variant="body1" sx={{ fontWeight:'300',fontSize:{xs:'12px',sm:'18px'},mt:'10px', color:'rgba(30, 30, 30, 1)',
             }}>
           Account
           </Typography>
        </Box>
      </Box>

        {loginOpen ? (
        <Box
          sx={{
            position: "fixed",
            left: "0",
            right: "0",
            bottom: "0",
            top: 0,
            zIndex: "1111111111111!important",
          }}
        >
          <Slide top>
            <Login loginOpen={loginOpen} setOpenLogin={setOpenLogin}  showFooter={true} />
          </Slide>
        </Box>
      ) : null}
    
        
        
      </Box>
    </Box>
  )
}

export default Footer
