import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
      // Table
      tableHead:{
        background: '#D8F3FF',
        borderRadius: '8.4769px'
      },
      tableLink:{
        color: "#2F80ED",
        textDecoration: "underline",
      },
      focusedInput: {
        borderColor: 'blue',
        // boxShadow: '0 0 5px blue',
        // Other styles that Material-UI applies on focus
      },

}));
export default useStyles;