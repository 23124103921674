import React, {useEffect} from 'react';
import * as Yup from "yup";
import moment from "moment/moment";
import {useFormik} from "formik";
import {formatCreditCardNumber, formatCVC, formatExpiryDate, MySwal} from "../../../../../services/utills";
import Box from "@mui/material/Box";
import {Button, Card, FormHelperText, Grid, TextField, Typography} from "@mui/material";
import Payment from "payment";
import visa from "../../../../../assets/icon/visa.png";
import successIcon from "../../../../../assets/icon/checkmark-circle.png";

const confirmation = async () => {
    let confirm = await MySwal.fire({
        icon: 'question',
        title: 'Confirmation',
        showConfirmButton: false,
        html: <Box sx={{mb: 4}}>
            <Typography sx={{}}>
                Are you sure? you want to use this card?
            </Typography>
            <Box sx={{mt: 3,}}>
                <Button
                    sx={{
                        backgroundColor: "#fff",
                        minWidth: "max-content",
                        border: "1px solid #666666",
                        borderRadius: '10px',
                        fontSize: '16px',
                        px: '20px',
                        mx: '10px',
                        color: "#666666",
                        "&:hover": {
                            backgroundColor: "#fff",
                            border: "1px solid #666666",
                        },
                    }}
                    onClick={() => MySwal.clickConfirm()}
                >
                    Yes
                </Button>
                <Button
                    sx={{
                        backgroundColor: "#fff",
                        minWidth: "max-content",
                        border: "1px solid #666666",
                        borderRadius: '10px',
                        px: '10px',
                        mx: '10px',
                        fontSize: '16px',
                        color: "#666666",
                        "&:hover": {
                            backgroundColor: "#fff",
                            border: "1px solid #666666",
                        },
                    }}
                    onClick={() => MySwal.close()}
                >
                    Cancel
                </Button>
            </Box>
        </Box>
    })
    return !!confirm?.isConfirmed
}

const UserCardsList = ({paymentMethod,handleSquare,handleBrainTree,totalDue,userCards,setUseCard}) => {

    const validationSchema = Yup.object({
        amount: Yup.string().required('Amount is required'),
        token: Yup.string().required('Please Choose a Card'),
    });
    const {
        values,
        handleSubmit,
        setFieldValue,
        resetForm,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            token: '',

            amount: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {

            if (await confirmation()) {
                if (paymentMethod?.payment_gateway === 'Square'){
                    handleSquare(data?.token, data?.amount, paymentMethod)
                }else {
                    handleBrainTree(data, paymentMethod)
                }
                resetForm()
                setUseCard(false)
            }
        },
    });

    const handleInputChange = ({target}) => {
        const {name, value} = target
        if (name === 'amount') {
            // Check if the value is either empty or a valid decimal number
            if (value === '' || /^\d*\.?\d*$/.test(value)) {
                setFieldValue('amount', value);
            }
    else if (value === '') {
                setFieldValue('amount', value);
            }
        }
    }
    useEffect(() => {
        if (!values?.amount) {
            setFieldValue('amount', totalDue);
        }
    }, [totalDue])

    return (
        <form onSubmit={handleSubmit}>
            <Grid container>

                <Grid item xs={12} sx={{mb: '24px'}}>
                    {userCards?.length > 0 && userCards.map((item, index) => (
                        <Card
                            key={index}
                            sx={{
                                mt: 2,
                                border: `0.8px solid ${values?.token === (item?.token || item?.id) ? "#128D64" : "#C4C4C4"}`,
                                borderRadius: "4px",
                                boxShadow: "none",
                                position: "relative",
                                overflow: "unset",
                                cursor: 'pointer'
                            }}
                            onClick={()=>setFieldValue("token",item?.token || item?.id)}
                        >
                            <Box
                                sx={{display: "flex", alignItems: "center", width: '100%', px: '70px'}}
                            >
                                <img
                                    src={visa}
                                    width="50px"
                                    style={{marginLeft: 2}}
                                    alt=""
                                />
                                <Typography
                                    variant="h5"
                                    color="initial"
                                    sx={{
                                        ml: 3,
                                        fontSize: "16px",
                                        "@media (max-width:500px)": {
                                            marginLeft: "5px",
                                            textAlign: "center",
                                            fontSize: "15px",
                                        },
                                    }}
                                >
                                    {item?.cardType || item?.card_brand}-{item?.last4 || item?.last_4}
                                </Typography>
                                {values?.token === (item?.token || item?.id)&& <Box sx={{ml:"50px"}}>
                                    <img
                                        src={successIcon}
                                        alt=""
                                    />
                                </Box>}
                            </Box>
                        </Card>
                    ))}
                    {errors.token && touched.token &&
                        <FormHelperText sx={{color: '#CB3838', width: 'max-content', mx: 0}}>
                            {errors.token}
                        </FormHelperText>
                    }
                </Grid>
                <Grid item xs={12} sx={{mb: '24px'}}>
                    <Box>
                        <TextField
                            fullWidth
                            value={values?.amount}
                            onChange={handleInputChange}
                            name="amount"
                            variant="outlined"
                            label="Amount"
                            sx={{marginRight: "10px"}}
                        />
                        {errors.amount && touched.amount &&
                            <FormHelperText sx={{color: '#CB3838', width: 'max-content', mx: 0}}>
                                {errors.amount}
                            </FormHelperText>
                        }
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant={"contained"}
                        type={"submit"}
                        sx={{backgroundColor: '#006AFF', height: '48px'}}
                    >
                        Confirm
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default UserCardsList;