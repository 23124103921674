import React, {Component} from 'react';
import SignaturePad from 'react-signature-canvas'
import styles from './signature.css'
import {Box, Button} from "@mui/material";


class UserSignature extends Component {

    sigPad =  {}
    clear = () => {
        this.sigPad.clear()
    }
    trim = () => {
        this.props.setSignatuer(this.sigPad.getTrimmedCanvas().toDataURL('image/png'))
        this.props.setOpen()

    }

    render() {
        return (
            <Box
                sx={{
                    "canvas":{
                        border:'2px solid #F6F6F6',
                        borderRadius:"4px",
                        width:'100%',
                        height:'300px',
                        
                    }
                }}
            >
                <SignaturePad
                    canvasProps={{
                        // width: "500px",
                        // height:'300px',
                        // className: styles.sigPad,
                    }}
                    ref={(ref) => {
                        this.sigPad = ref
                    }}/>
                <Box
                    sx={{
                        mt:'20px',
                        display:'flex',
                        alignItems:"center",
                        justifyContent:"flex-end",
                    }}
                >
                    <Button
                        variant={'outlined'}
                        onClick={this.clear}
                        color={'error'}
                        sx={{
                            mr:'10px'
                        }}
                    >
                        Clear
                    </Button>
                    <Button variant={'outlined'} onClick={this.trim}>
                        Use This Signature
                    </Button>
                </Box>
            </Box>
        )
    }
}

export default UserSignature;