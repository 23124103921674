import React, {useEffect, useState,useRef, forwardRef} from 'react';
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, Typography
} from "@mui/material";
import moment from "moment/moment";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {MenuProps} from "../../calender/crmCalender";
import {
    convertTimeToMinutes,
    generateYearsToday,
    getEndTime,
    getTimeSlotsArray,
    monthsArray, MySwal, successConfirmation
} from "../../../../services/utills";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {scrollCSS} from "../../../../App";



const boxStyle ={
    height:'40px',
    width:'40px',
    // border:"1px solid #006AFF",
    display:'flex',
    alignItems:"center",
    borderRadius:'4px',
    justifyContent:'center',
    // color:'#006AFF',
    cursor:'pointer',
    "&:hover": {
        // background:"#006AFF",
        // color:'#fff'
    }
}
const disableStyle ={
    opacity: 0.5,
    color:'#D5D9DC',

    border:"1px solid #D5D9DC",
    "&:hover": {
        cursor: 'not-allowed'
    }
}
const dateStyle ={
    p:"10px 0",
    fontSize:'14px',
    fontWeight:'bold',
    height: '40px',
    width: '40px',
    mx: 'auto',
}

const AppointmentTime = forwardRef(({selectedServices, getAppointmentHoliday, selectedTime, setSelectedTime, selectedStaff, getAppointmentSchedule, selectedFinalDate, executeScroll }, ref) => {
        const currentData = new Date();

        // Initialize startDate with selectedFinalDate if it exists, otherwise use current date
        const [startDate, setStartDate] = useState(selectedFinalDate ? new Date(selectedFinalDate) : currentData);

        useEffect(() => {
            // Update startDate whenever selectedFinalDate changes
            if (selectedFinalDate) {
                setStartDate(new Date(selectedFinalDate));
            }
        }, [selectedFinalDate]);

        const isCurrentMonth = (currentData.getMonth() === startDate.getMonth()) && (currentData.getFullYear() === startDate.getFullYear());
        const monthStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        const monthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        const daysInMonth = monthEnd.getDate();
        let firstDayOfWeek = monthStart.getDay();

        firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1; // Adjust for Monday start
        const lastDayOfWeek = monthEnd.getDay();

        const bookAppointmentStore = useSelector(state => state?.bookAppointmentStore);
        const { appointmentHolidays = [], appointmentSchedule = [] } = bookAppointmentStore;

        const scrollRef = useRef(null); // Create the ref

        // Function to execute scrolling
        const executeScrolldate = () => {
            setTimeout(() => {
                if (scrollRef.current) {
                    scrollRef.current.scrollIntoView({ behavior: "smooth" });
                }
            }, 500);
        };

        const weeks = [];
        let days = [];

        for (let i = 0; i < firstDayOfWeek; i++) {
            days.push(
                <TableCell key={`empty-${i}`} sx={{ p: "0" }}>
                    <Box sx={{ width: '50px' }}>{''}</Box>
                </TableCell>
            );
        }

        for (let i = 1; i <= daysInMonth; i++) {
            const isExist = appointmentHolidays?.length > 0 && appointmentHolidays.some((item) => parseFloat(item) === parseFloat(i));
            const formattedDate = moment(new Date(startDate.getFullYear(), startDate.getMonth(), i)).format('YYYY-MM-DD');
            const isHoliday = appointmentHolidays?.length > 0 && appointmentHolidays?.includes(formattedDate);
            const isPast = isCurrentMonth && i < currentData.getDate();
            const isDisabledDate = isPast || isHoliday;

            days.push(
                <TableCell key={i} sx={{ p: "0" }}>
                    <Box
                        sx={{
                            ...dateStyle,
                            cursor: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? 'default' : 'pointer',
                            color: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? "#8D8D8D" : i === moment(selectedFinalDate)?.date() && startDate.getMonth() === moment(selectedFinalDate).month() && startDate.getFullYear() === moment(selectedFinalDate).year() ? '#006AFF' : '#000',
                            pointerEvents: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? "none!important" : 'auto',
                            textDecoration: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? "line-through" : 'none',
                        }}
                        onClick={() => {
                            const date = moment(new Date(startDate.getFullYear(), startDate.getMonth(), i)).format('YYYY-MM-DD');
                            getAppointmentSchedule(date);
                            executeScrolldate();
                        }}
                    >
                        {i}
                    </Box>
                </TableCell>
            );

            if ((i + firstDayOfWeek) % 7 === 0 || i === daysInMonth) {
                weeks.push(<TableRow key={`week-${i}`}>{days}</TableRow>);
                days = [];
            }
        }

        for (let i = (lastDayOfWeek + 1) % 7; i < 6; i++) {
            days.push(
                <TableCell key={`empty-${i}`} sx={{ p: "0" }}>
                    <Box sx={{ width: '50px' }}>{''}</Box>
                </TableCell>
            );
        }

        const isDisabled = (month) => {
            const today = new Date();
            const year = today.getFullYear();
            const currentMonth = today.getMonth();
            const currentYear = startDate.getFullYear();
            if (year === currentYear) {
                return month < currentMonth;
            } else {
                return false;
            }
        };

        const handleSelectedTime = async (slotsArray, startTime) => {
            let totalTime = 0;
            selectedServices.forEach((item) => {
                let time = convertTimeToMinutes(item?.time_duration);
                totalTime += time;
            });

            let endTime = getEndTime(startTime, totalTime);
            let getArraySlots = getTimeSlotsArray(startTime, endTime, slotsArray);
            let lastIndex = getArraySlots[0];

            if (slotsArray?.includes(lastIndex)) {
                let object = {
                    start_time: startTime,
                    end_time: endTime,
                    slots: getArraySlots,
                };

                setSelectedTime(object);
                executeScroll(); // Scrolls to the reschedule button in the parent component

            } else {
                setSelectedTime(null);
                await MySwal.fire({
                    icon: 'error',
                    title: 'Invalid Time Slots.',
                    showConfirmButton: true,
                    timer: 5000,
                });
            }
        };

        return (
            <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Grid item xs={12} sx={{overflowX:{xs:'auto',sm:'unset'} }}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                p: {xs:'0',sm:'12px'},
                                justifyContent: 'space-between'
                            }}>
                            <Box sx={{display: 'flex', gap: '10px',ml:{xs:'10px',sm:'0'}}}>
                                <FormControl sx={{minWidth: 90}}>
                                    <Select
                                        size={"small"}
                                        value={startDate?.getMonth()}
                                        MenuProps={MenuProps}
                                        onChange={(e) => {
                                            const end = new Date(startDate);
                                            end.setMonth(e?.target?.value);
                                            setStartDate(end);
                                            getAppointmentHoliday(end, selectedStaff)
                                        }}
                                        sx={{
                                            background: '#EDF2F7',
                                            ".MuiOutlinedInput-notchedOutline": {
                                                borderWidth: "0!important"
                                            },
                                            "svg": {
                                                fontSize: '34px',
                                            }
                                        }}
                                    >
                                        {monthsArray?.length > 0 && monthsArray?.map((item, index) => (
                                            <MenuItem
                                                disabled={isDisabled(item?.key)}
                                                value={item?.key}
                                                key={index}
                                            >
                                                {item?.name}
                                            </MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl sx={{minWidth: 90}}>
                                    <Select
                                        size={"small"}
                                        value={startDate?.getFullYear()}
                                        MenuProps={MenuProps}
                                        onChange={(e) => {
                                            const end = new Date(startDate);
                                            end.setFullYear(e?.target?.value);
                                            setStartDate(end);
                                            getAppointmentHoliday(end, selectedStaff)
                                        }}
                                        sx={{
                                            background: '#EDF2F7',
                                            ".MuiOutlinedInput-notchedOutline": {
                                                borderWidth: "0!important"
                                            },
                                            "svg": {
                                                fontSize: '34px',
                                            }
                                        }}
                                    >
                                        {generateYearsToday(10)?.length > 0 &&
                                            generateYearsToday(10)?.map((item, index) => (
                                                <MenuItem value={item} key={index}>{item}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{display: 'flex', gap: '10px'}}>

                                <Box sx={isCurrentMonth ? {...boxStyle, ...disableStyle} : {...boxStyle}} onClick={() => {
                                    if (!isCurrentMonth) {
                                        const end = new Date(startDate);
                                        end.setMonth(end.getMonth() - 1);
                                        setStartDate(end);
                                        getAppointmentHoliday(end, selectedStaff)
                                    }
                                }}>
                                    <KeyboardArrowLeftIcon sx={{fontSize: '32px'}}/>
                                </Box>
                                <Box sx={boxStyle} onClick={() => {
                                    const end = new Date(startDate);
                                    end.setMonth(end.getMonth() + 1);
                                    setStartDate(end);
                                    getAppointmentHoliday(end, selectedStaff)
                                }}>
                                    <KeyboardArrowRightIcon sx={{fontSize: '32px'}}/>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Table aria-label="simple table" sx={{
                                boxShadow: 'none', "td , th": {
                                    border: 'none',
                                    textAlign: 'center',
                                },
                                "th": {
                                    fontWeight: 'bold'
                                },
                                "td": {
                                    py: "4px"
                                }
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Mo</TableCell>
                                        <TableCell>Tu</TableCell>
                                        <TableCell>We</TableCell>
                                        <TableCell>Th</TableCell>
                                        <TableCell>Fr</TableCell>
                                        <TableCell>Sa</TableCell>
                                        <TableCell>Su</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {weeks}
                                    <TableRow sx={{
                                        "td": {
                                            py: "5px!important"
                                        }
                                    }}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Grid>
                <Grid ref={ref}  item xs={12} sx={{display: "flex", flexDirection: 'column',}}>
                    <Box sx={{height: '64px', display: 'flex', alignItems: 'center'}}>
                        {selectedFinalDate ?
                            <Typography sx={{fontSize: '20px',fontFamily: "helvetica-lt-std-bold",}}>
                                {moment(selectedFinalDate).format('dddd, Do MMMM, YYYY')}
                            </Typography> : ""
                        }
                    </Box>
                    <Box


                        sx={{
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                        {appointmentSchedule?.success && selectedFinalDate &&
                            <Box ref={scrollRef}>
                                <Typography sx={{
                                    mt: '16px',
                                    mb: "8px",
                                    fontSize: '16px',
                                    fontFamily: "helvetica-lt-std-bold",
                                }}>
                                    Morning
                                </Typography>
                                <Box sx={{ '@media (max-width:600px)':{
                                        display:'flex ',
                                        justifyContent:'center',
                                        flexWrap:'wrap'
                                    }, }}>
                                    {appointmentSchedule?.morningTimeSlots?.length > 0 ?
                                        appointmentSchedule?.morningTimeSlots.map((item, index) => (
                                            <Button
                                                key={index}
                                                variant={"outlined"}
                                                sx={{
                                                    color: selectedTime?.slots[0] === item ? '#006AFF' : '#fff',
                                                    backgroundColor: selectedTime?.slots[0] === item ? "#fff" : '#006AFF',
                                                    border: '1px solid #006AFF',
                                                    fontWeight: 'bold',
                                                    minWidth: {xs:'100px',sm:'140px'},
                                                    mr: '10px',
                                                    mb: '10px',
                                                    "&:hover": {
                                                        color: selectedTime?.slots[0] === item ? '#006AFF' : '#fff',
                                                        backgroundColor: selectedTime?.slots[0] === item ? "#fff" : '#006AFF',
                                                    }
                                                }}
                                                onClick={() => {
                                                    handleSelectedTime(appointmentSchedule?.slots, item);
                                                }}
                                            >
                                                {selectedTime?.slots[0] === item ? "✔" : ''} {item}
                                            </Button>

                                        ))
                                        : <Button
                                            disabled
                                            variant={"contained"}
                                            sx={{
                                                backgroundColor: '#fff',
                                                color: '#006AFF',
                                                fontFamily: "helvetica-lt-std-bold",
                                            }}
                                        >
                                            All Booked
                                        </Button>
                                    }
                                </Box>
                                <Typography
                                    sx={{
                                        mt: '16px',
                                        mb: "8px",
                                        fontSize: '16px',
                                        fontFamily: "helvetica-lt-std-bold",
                                    }}
                                >
                                    Afternoon
                                </Typography>
                                <Box sx={{ '@media (max-width:600px)':{
                                        display:'flex ',
                                        justifyContent:'center',
                                        flexWrap:'wrap'
                                    }, }}>
                                    {appointmentSchedule?.afternoonTimeSlots?.length > 0 ?
                                        appointmentSchedule?.afternoonTimeSlots.map((item, index) => (
                                            <Button
                                                key={index}
                                                variant={"outlined"}
                                                sx={{
                                                    color: selectedTime?.slots[0] === item ? '#006AFF' : '#fff',
                                                    backgroundColor: selectedTime?.slots[0] === item ? "#fff" : '#006AFF',
                                                    border: '1px solid #006AFF',
                                                    fontWeight: 'bold',
                                                    minWidth: {xs:'100px',sm:'140px'},
                                                    mr: '10px',
                                                    mb: '10px',
                                                    "&:hover": {
                                                        color: selectedTime?.slots[0] === item ? '#006AFF' : '#fff',
                                                        backgroundColor: selectedTime?.slots[0] === item ? "#fff" : '#006AFF',
                                                    }
                                                }}
                                                onClick={() => {
                                                    handleSelectedTime(appointmentSchedule?.slots, item);
                                                }}
                                            >
                                                {selectedTime?.slots[0] === item ? "✔" : ''} {item}
                                            </Button>
                                        ))
                                        : <Button
                                            disabled
                                            variant={"contained"}
                                            sx={{
                                                backgroundColor: '#fff',
                                                color: '#006AFF',
                                                fontFamily: "helvetica-lt-std-bold",
                                            }}
                                        >
                                            All Booked
                                        </Button>
                                    }
                                </Box>
                                <Typography
                                    sx={{
                                        mt: '16px',
                                        mb: "8px",
                                        fontSize: '16px',
                                        fontFamily: "helvetica-lt-std-bold",
                                    }}
                                >
                                    Evening
                                </Typography>
                                <Box sx={{ '@media (max-width:600px)':{
                                        display:'flex ',
                                        justifyContent:'center',
                                        flexWrap:'wrap'
                                    }, }}>
                                    {appointmentSchedule?.eveningTimeSlots?.length > 0 ?
                                        appointmentSchedule?.eveningTimeSlots.map((item, index) => (
                                            <Button
                                                key={index}
                                                variant={"outlined"}
                                                sx={{
                                                    color: selectedTime?.slots[0] === item ? '#006AFF' : '#fff',
                                                    backgroundColor: selectedTime?.slots[0] === item ? "#fff" : '#006AFF',
                                                    border: '1px solid #006AFF',
                                                    fontWeight: 'bold',
                                                    minWidth: {xs:'100px',sm:'140px'},
                                                    mr: '10px',
                                                    mb: '10px',
                                                    "&:hover": {
                                                        color: selectedTime?.slots[0] === item ? '#006AFF' : '#fff',
                                                        backgroundColor: selectedTime?.slots[0] === item ? "#fff" : '#006AFF',
                                                    }
                                                }}
                                                onClick={() => {
                                                    handleSelectedTime(appointmentSchedule?.slots, item);
                                                }}
                                            >
                                                {selectedTime?.slots[0] === item ? "✔" : ''} {item}
                                            </Button>
                                        ))
                                        : <Button
                                            disabled
                                            variant={"contained"}
                                            sx={{
                                                backgroundColor: '#fff',
                                                color: '#006AFF',
                                                fontFamily: "helvetica-lt-std-bold",
                                            }}
                                        >
                                            All Booked
                                        </Button>
                                    }
                                </Box>
                            </Box>}
                    </Box>
                </Grid>
            </Grid>
        );
    }
)

export default AppointmentTime;

