import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import {
  Box, Button, Checkbox, Divider, FormControlLabel,
  IconButton,
  TablePagination,
  Tooltip,
  Typography, Grid, Switch,
} from "@mui/material";

import {
  MdDeleteOutline,
  MdModeEditOutline,
  MdOutlineMail,
} from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { GrSchedule } from "react-icons/gr";
import {apiHeaders} from "../../lib/apiHeader"
import {confirmation, handleDelete, MySwal, successConfirmation} from "../../services/utills";
import {useDispatch, useSelector} from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../loader/loader";
import { escapeRegExp } from "../table";
import { makeStyles } from "@mui/styles";
import {scrollCSS} from "../../App";
import noImage from '../../assets/images/no-image-icon.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {confirmationButton} from "../../pages/crm/call-logs/Appointment";
import Waveloading from "../../pages/loading/Waveloading";
import { UpdateServices, updateClinicsServices } from "../../store/clinics/clinicSettings/clinics-services";

const useStyles = makeStyles((theme) => ({
  iconTextWrapper: {
    marginTop: "8px",
    display: "flex",
    alignItems: "center",
    fontSize:'16px',
    gap: "1px",
  },
  service: {
    color: "rgb(0 0 0 / 87%)", // red color for service
  },
  staff: {
    color: "#09ABF1", // blue color for staff
  },
  typochange: (props) => ({
    color: props.type === "service" ? "rgb(0 0 0 / 87%)" : "#09ABF1",
  }),
  regularprice: {
    borderRight: "1px solid  #bfbfbf",
    padding: "0.5rem 0",
    gap:'2px',
    textAlign: "center",
  },

  serviceswrapper: {
    display: "flex",

  },
  groupwrapper: {
    marginTop: "10px",
    display: "flex",
    justifyContent:'space-between'
  },
  groups: {
    display: "flex",
    gap:"1px",
    alignItems: "center",
  },
  timer: {
    padding: "0.5rem",
    gap: '2px',
    textAlign: "center",
  },
}));

const CardTable = ({ cardData, handleEdit, handleSchedule,search, type ,onDataUpdate}) => {

  const classes = useStyles();
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showFullText, setShowFullText] = useState(false);

  const [checked, setChecked] = useState( 1); 


  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

console.log("data",cardData?.data )
  const dispatch = useDispatch();
  const params = useParams();

  const clinicData = useSelector(state => state?.clinicStore?.clinicByID)


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteClick = async (item) => {
    if (await confirmation()) {
      setLoading(true);
      let finalData = {
        id: item?.id,
      }
      if (params?.clinic_id) {
        finalData['provider_id'] = params?.clinic_id
      }
      await handleDelete(dispatch, type, finalData);
      setLoading(false);
    }
  };

  useEffect(() => {
    setFilterData(cardData);
  }, [cardData]);
  
  useEffect(() => {
    if (search !== "" || search?.length) {
      const searchRegex = new RegExp(escapeRegExp(search), "i");
      let filteredRows;
  
     
      filteredRows = cardData?.filter((row) => {
        return Object.keys(row).some((field) => {
          return row[field] !== null && searchRegex.test(row[field].toString());
        });
      });
  
      if (filteredRows?.length > 0) {
        setFilterData(filteredRows);
      } else {
        setFilterData([]);
      }
    } else {
      setFilterData(cardData);
    }
  }, [search, cardData]);
  

  const handleCopyUrl = async (item) => {

    let finalUrl = window?.location?.origin.replace('zoey', 'app');
    if (type === "service"){
      finalUrl = `https://api.3dlifestyle.ca/book/${item?.service_prefix}/${item?.uuid}`
    }
    if (type === 'plan'){
      finalUrl = finalUrl+ `/user/appointment/book/plan/${item?.slug}`
    }
    const virtualConsultation = async (finalUrl, clinicData, item) => {
      navigator.clipboard.writeText(`${finalUrl}/VC`);
      let timerInterval;
      await MySwal.fire({
        icon: 'success',
        title: 'URL Copied Successfully',
        showConfirmButton: false,
        html: (
            <Box sx={{ mb: 2 }}>
              <Typography>
                I will close in <b></b> milliseconds.
              </Typography>
              <Button
                  sx={{ ...confirmationButton, mt: "16px" }}
                  onClick={() => MySwal.close()}
              >
                Ok. Close It,
              </Button>
            </Box> 
        ),
        timer: 4000,
        timerProgressBar: true,
        didOpen: () => {
          const b = MySwal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = MySwal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    };
    const booking = async (finalUrl, clinicData, item) => {
      navigator.clipboard.writeText(`${finalUrl}/BK`);
      let timerInterval;
      await MySwal.fire({
        icon: 'success',
        title: 'URL Copied Successfully',
        showConfirmButton: false,
        html: (
            <Box sx={{ mb: 2 }}>
              <Typography>
                I will close in <b></b> milliseconds.
              </Typography>
              <Button
                  sx={{ ...confirmationButton, mt: "16px" }}
                  onClick={() => MySwal.close()}
              >
                Ok. Close It,
              </Button>
            </Box>
        ),
        timer: 4000,
        timerProgressBar: true,
        didOpen: () => {
          const b = MySwal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = MySwal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    };
    const InPersonConsultation = async (finalUrl, clinicData, item) => {
      navigator.clipboard.writeText(`${finalUrl}/IPC`);
      let timerInterval;
      await MySwal.fire({
        icon: 'success',
        title: 'URL Copied Successfully',
        showConfirmButton: false,
        html: (
            <Box sx={{ mb: 2 }}>
              <Typography>
                I will close in <b></b> milliseconds.
              </Typography>
              <Button
                  sx={{ ...confirmationButton, mt: "16px" }}
                  onClick={() => MySwal.close()}
              >
                Ok. Close It,
              </Button>
            </Box>
        ),
        timer: 4000,
        timerProgressBar: true,
        didOpen: () => {
          const b = MySwal.getHtmlContainer().querySelector('b');
          timerInterval = setInterval(() => {
            b.textContent = MySwal.getTimerLeft();
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    };

    let timerInterval
    let confirm = await MySwal.fire({
      icon: 'question',
      title: 'Copy URL',
      showConfirmButton: false,
      html: <Box sx={{mb: "16px"}}>

        <Box sx={{mt: 3,}}>


        {/*  <Button
              sx={confirmationButton}
              onClick={() => MySwal.clickDeny()}
          >
            Copy With Location.
          </Button>*/}
          <Button
              sx={confirmationButton}
              onClick={() => {
                booking(finalUrl, clinicData, item);
              }}
          >
            Booking Service.
          </Button>
          <Button
              sx={confirmationButton}
              onClick={() => {
                InPersonConsultation(finalUrl, clinicData, item);
              }}
          >
            In Person Consultation.
          </Button>
        </Box>
        <Box sx={{mt: 3,}}>

          <Button
              sx={confirmationButton}
              onClick={() => {
                virtualConsultation(finalUrl, clinicData, item);
              }}
          >
           Virtual Consultation Link.
          </Button>
        </Box>
        <Box sx={{mt: 3,}}>

        <Button
              sx={{...confirmationButton,mt:'10px'}}
              onClick={() => MySwal.close()}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    })

    if (!!confirm?.isConfirmed && finalUrl ) {
      navigator.clipboard.writeText(`${finalUrl}`);
      await MySwal.fire({
        icon: 'success',
        title: 'URL Copied Successfully',
        showConfirmButton: false,
        html:<Box sx={{mb: 2}}>
          <Typography>
            I will close in <b></b> milliseconds.
          </Typography>
          <Button
              sx={{...confirmationButton,mt:"16px"}}
              onClick={() => MySwal.close()}
          >
            Ok. Close It,
          </Button>
        </Box>,
        timer: 4000,
        timerProgressBar: true,
        didOpen: () => {
          const b = MySwal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = MySwal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })

    }
    else if (!!confirm?.isDenied && finalUrl){
      navigator.clipboard.writeText(finalUrl+`/${clinicData?.id}`)

      await MySwal.fire({
        icon: 'success',
        title: 'URL Copied Successfully With Location',
        showConfirmButton: false,
        html:<Box sx={{mb: 2}}>
          <Typography>
            I will close in <b></b> milliseconds.
          </Typography>
          <Button
              sx={{...confirmationButton,mt:"16px"}}
              onClick={() => MySwal.close()}
          >
            Ok. Close It,
          </Button>
        </Box>,
        timer: 4000,
        timerProgressBar: true,
        didOpen: () => {
          const b = MySwal.getHtmlContainer().querySelecto0r('b')
          timerInterval = setInterval(() => {
            b.textContent = MySwal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      })
    }

  }


  



 
  const [expandedItems, setExpandedItems] = useState({});

  // Helper function to determine if the text exceeds five words
  const exceedsWordLimit = (text, limit = 7) => {
    const words = text.split(' ');
    return words.length > limit;
  };

  const toggleExpand = (index) => {
    setExpandedItems(prevExpandedItems => ({
      ...prevExpandedItems,
      [index]: !prevExpandedItems[index]
    }));
  };
  
  return (
      
          <Box sx={{ ...scrollCSS}}>
            {loading ? <Waveloading fullSize/> : <>
            <Grid container>
            <Box sx={{alignItems: "flex-start", display: 'flex', flexWrap: 'wrap', justifyContent:'space-between',rowGap:'15px', width:'6+100%'}}>
            {Array.isArray(filterData) && filterData.map((item, index) => {
     
     const name = item?.name || item?.title;
     const nameExceedsLimit = exceedsWordLimit(name);
     const isExpanded = expandedItems[index];

      return (
                    <Grid item xs={12} md={5.9}>
                              <Box key={index} component={Paper} sx={{height: '250px',  '@media (max-width: 1600px) ': {height:'auto', flexDirection:'column'}, width: '100%', display: 'flex',backgroundColor:'#edf2f7'}}>
                        <Box sx={{height: '250px',  '@media (max-width: 1600px) ': {height:'270px', width:'100%'}, width:"100%"}}>
                          <img src={item?.image || item?.profile_image || noImage} alt={'image'} height={"100%"} 
                               width={"100%"}/>
                        </Box>
                        <Box sx={{width: '100%', padding: '15px', pt:(type ==='service' || type ==='plan') ? '0' :"15px"}}>
                          <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                          >
                          
                            {type === "plan" &&
                                <Typography
                                    sx={{
                                      fontSize: '16px',
                                      color: '#27272E',
                                      width:'150px',
                                    }}
                                    component="div"
                                >
                                  Sessions : {item?.no_of_bookings}
                                </Typography>
                            }

                            <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  width:'100%'
                                }}
                            >
                              {type !== 'staff' && item.status === 'Active' && <IconButton
                                  onClick={() => handleCopyUrl(item)}
                              >
                                <ContentCopyIcon/>
                              </IconButton>}
                              {type === "staff" && <Tooltip title="Add Staff work schedule">
                                <IconButton
                                    size="small"
                                    onClick={() => handleSchedule(item)}
                                >
                                  <GrSchedule
                                      color={"#09ABF1"}
                                      fontSize={"20px"}
                                  />
                                </IconButton>
                              </Tooltip>
                              }
                              <Tooltip title="Edit">
                                <IconButton
                                    size="small"
                                    onClick={() => handleEdit(item)}
                                >
                                  <MdModeEditOutline
                                      color={"#12B104"}
                                      fontSize={"20px"}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                    size="small"
                                    onClick={() => handleDeleteClick(item)}
                                >
                                  <MdDeleteOutline
                                      color={"#E53E31"}
                                      fontSize={"20px"}
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                          {type === 'staff' &&
                              <Typography sx={{fontSize: '20px', mt: '16px', color: '#27272E'}} component="div">
                                {item?.name || item?.title}
                              </Typography>
                          }
                          {(type === "plan" || type === 'service') &&
                              <Box
                                  sx={{
                                  
                                    mt: '8px',
                                    
                                  }}
                              >
                                 <Typography
                sx={{
                  fontSize: '18px',
                  color: '#27272E',
                  textTransform: 'capitalize',
                  cursor: nameExceedsLimit ? 'pointer' : 'default',
                  '&:hover': {
                    textDecoration: nameExceedsLimit ? 'underline' : 'none',
                  }
                }}
                component="div"
                onClick={() => nameExceedsLimit && toggleExpand(index)}
              >
                {isExpanded || !nameExceedsLimit ? name : `${name.split(' ').slice(0, 5).join(' ')}...`}
              </Typography>
                              </Box>}
                          {type === "service" || type === 'plan' ? (
                              <>
                                <Box sx={{
                                  display: 'flex',
                                  height: '70px',
                                  p: '10px 0',
                                  textAlign: 'center',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}>
                                  <Box>
                                    <Typography variant="body2" sx={{mb: "5px"}}>
                                      Regular Price
                                    </Typography>
                                    <Typography sx={{lineHeight: '1'}}>{item?.regular_price}</Typography>
                                  </Box>
                                  <Divider orientation={"vertical"}/>
                                  <Box>
                                    <Typography variant="body2" sx={{mb: "5px"}}>
                                      Member's Price
                                    </Typography>
                                    <Typography sx={{lineHeight: '1'}}>{item?.member_price}</Typography>
                                  </Box>
                                  <Divider orientation={"vertical"}/>
                                  {type === "service" && <Box>
                                    <Typography sx={{mb: "5px"}} variant="body2">Time</Typography>
                                    <Typography sx={{lineHeight: '1'}}>{item?.time_duration}</Typography>
                                  </Box>}
                                </Box>
                              

                                {/*{type === "service" && <Box className={classes.groupwrapper} sx={{*/}
                                {/*  mt:"0"*/}
                                {/*}}>*/}
                                {/*  <div className={classes.groups}>*/}
                                {/*    <Typography variant="h6">Group:</Typography>*/}
                                {/*    <Typography>232</Typography>*/}
                                {/*  </div>*/}
                                {/*  <div className={classes.groups}>*/}
                                {/*    <Typography variant="h6">SKU:</Typography>*/}
                                {/*    <Typography>232</Typography>*/}
                                {/*  </div>*/}   
                                {/*</Box>}*/}
                              </>   
                          ) : (
                              <>
                                <div className={classes.iconTextWrapper}>
                                  <MdOutlineMail style={{fontSize: "1.3rem"}}/>
                                  <Typography>
                                    {item?.email}
                                  </Typography>
                                </div>

                                <div className={classes.iconTextWrapper}>
                                  <FiPhone style={{fontSize: "1.3rem"}}/>
                                  <Typography variant="body1" color="text.secondary">
                                    {item?.phone}
                                  </Typography>
                                </div>
                              </>
                          )}
                        </Box>
                      </Box>
                    </Grid>
              
              );
            })}
                  {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={(filterData ?? []).length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          


            </Box>

            
            </Grid> 
            </>}
          


          
          </Box>
          

  );
}

export default CardTable;
