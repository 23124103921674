import { Box, Container, IconButton, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AppointmentDetails from '../../pages/crm/appointment/appointment-details/appointmentDetails'
import { useDispatch, useSelector } from 'react-redux'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { scrollCSS } from '../../App';
import { getAppointmentDetails } from '../../store/crm/appointment';
import Waveloading from '../../pages/loading/Waveloading';
import { Padding } from '@mui/icons-material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '750px',
  background: "#fff",
  padding: '27px 2px',
  borderRadius:'6px',
  '@media (max-width: 550px)': {
    width: '95%',
  },
  gap:"5px",
};

  const contentStyle = {
    background: "#fff",
    minHeight: '100px',
    maxHeight: '600px',  
    borderRadius: '8px',
    p: { sm: '12px', xs: '8px' },
    overflow: 'auto'   ,
    // ...scrollCSS,
};
const AppointmentDetailsmodal = ({booking_id}) => {
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
      setOpen(true);
      if (booking_id && !open) { // Check if modal is not already open
          setLoading(true);
          dispatch(getAppointmentDetails(booking_id))
              .finally(() => setLoading(false));
      }
  };
    const [loading , setLoading]=useState(false)
    const dispatch = useDispatch()
    const handleClose = () => setOpen(false)
    const crmAppointmentStore = useSelector(
        (state) => state?.crmAppointmentStore
      );
 const { appointmentDetails = [] } = crmAppointmentStore
 useEffect(() => {
  // Ensuring the modal is open before making the API call
  if (open && booking_id) {
      setLoading(true);
      dispatch(getAppointmentDetails(booking_id))
          .finally(() => setLoading(false));
  }
}, [booking_id, open, dispatch]);  
  return (
   <>
    <Typography onClick={handleOpen}  style={{color: '#2F80ED',  cursor:"pointer",textDecoration: 'underline' }}>
      {booking_id}
    </Typography>
    <Modal
     style={{}} 
       open={open}
       onClose={handleClose}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >
       <Container maxWidth="xl">
       {loading ? <Waveloading fullSize/>:''}
        <Box style={style} sx={{ width:{lg:'800px',sm:'90%', xs:'95%'} }}>
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <IconButton  
                            size="small"
                            onClick={() => setOpen(false)}
                            style={{ color: "red" }} 
                        >
                           
                            <CloseRoundedIcon  style={{fontSize: '2rem' }} /> 
                        </IconButton>
                    </Box>
                    
        
           <Box sx={contentStyle} style={{...scrollCSS}}>  

 <AppointmentDetails appointmentDetails={appointmentDetails} />
            </Box>
       

        </Box> 
        </Container>

     </Modal> 
   </>
  )
}

export default AppointmentDetailsmodal