import React, {Fragment, useEffect, useRef, useState} from 'react';
import {styled, useTheme} from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {
    Autocomplete, Avatar, Box, Button, Card, CardContent, Checkbox, Collapse, Divider,
    FormControl, FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid, MenuItem, Radio, Step, StepLabel, Stepper, Table, TableBody,
    TableCell,
    TableRow,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import {tableCellClasses} from "@mui/material/TableCell";
import {useDispatch, useSelector} from "react-redux";

import {
    getAllExistingUser, getAllExistingUserCards,
     getCrmAllPlans, getCrmAppointmentHolidays,
     getServicesCategory,
     updateCrmFilters,

} from "../../../../store/crm/appointment/book-appointment";
import {getProviderStaff} from "../../../../store/crm/staff";
import * as yup from "yup";
import * as Yup from "yup";
import {useFormik} from "formik";
import {findGuestUser} from "../../../../store/booking-widget";
import {convertTimeToMinutes, successConfirmation} from "../../../../services/utills";
import toast from "react-hot-toast";
import {inputStyle, labelStyle} from "../../../../components/form/formInput";
import SearchField from "../../../../components/form/SearchField";
import TableContainer from "@mui/material/TableContainer";
import ribbon from "../../../../assets/images/ribbon.svg";
import redRibbon from "../../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../../assets/images/yellow-badg.svg";
import CustomPagination from "../../../../components/pagination/pagination";
import SubmitButton from "../../../../components/custom-button/submitButton";
import CustomButton from "../../../../components/custom-button/customButton";
import moment from "moment/moment";
import CustomBreadcrumbs from "../../../../components/breadcrumbs/breadcrumbs";
import Loader from "../../../../components/loader/loader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import StepperWrapper from "../../../../components/steps/StepperWrapper";
import InvoiceTime from "./invoiceTime";
import InvoicePayment from "./InvoicePayment";
import PlanInvoiceTime from "./planInvoiceTime";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import CustomModal from "../../../../components/modal/customModal";
import AddServices from "../../../configuration/clinic-settings/services/AddService";
import Waveloading from '../../../loading/Waveloading';
import BackendSearchField from '../../../../components/pagination/backendSearch';
import BackendPagination from '../../../../components/pagination/backendPagination';


export const contenttime={  
    fontSize:'20px',
    fontFamily:'Helvetica',
    fontWeight:"400",
    paddingTop:'3px',
    padding:'20px 0px'
}
export const booking={
    '@media (max-width: 1200px)': {
        marginLeft:'auto'

    },
    '@media (max-width: 480px)': {

        marginLeft:'0px'

    }

}
export const timehead={
    fontSize:'20px',
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:"700",

}


export const content={

    fontSize:'16px',
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:"400",
    '@media (max-width: 1024px)': {
        fontSize:'18px',
        fontFamily:'helvetica-lt-std-roman',
        fontWeight:"400",
    }
}

export const customernote={
    fontSize:'20px',
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:"400",
    padding:'15px 0px'

}
export const customerside={
    display:'flex',justifyContent:"space-between",alignItems:"center",
    '@media (max-width: 480px)': {
        display:'flex',
        flexDirection:'column'

    }


}
export const wrappercard={
    display:'flex',alignItems:"stretch",
    '@media (max-width: 786px)': {
        display:'flex',
        flexDirection:'row'

    },


    '@media (max-width: 480px)': {
        display:'flex',
        flexDirection:'column'

    }
}

export function removeCard(selectedServices, finalData) {
    return selectedServices.filter((item) => item?.id !== finalData?.id);
  }
const customerTypes =[
    {name:'New Customer' ,key:"new_customer"},
    // {name:'Walk In Customer' ,key:"walk_in_customer"},
    {name:'Existing Customer' ,key:"existing_customer"},
]

const steps = [
    {
        title: 'Select Customer',
    },
    {
        title: 'Select Service',
    },
    // {
    //     title: 'Select Times',
    // }
]




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: '16px',
        paddingTop:'3px',
        paddingBottom:'3px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AddNewInvoice = () => {
    const [activeType, setActiveType] = useState("existing_customer");
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)

    
    const top100Films = [
        { label: "Massage Therapy", year: 1994 },
        { label: "Face & Body Contouring", year: 1972 },
        { label: "Laser Hair Removals", year: 1974 },
        { label: "Medical Grade Facials", year: 2008 },
      ];

    /******************** Step 1 States ***********************/
    const [selectedUser, setSelectedUser] = useState({});
    const [filteredUser, setFilteredUser] = useState([]);
    const [userCards, setUserCards] = useState([]);
 
    /******************** Step 2 States ***********************/
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [addExtraService, setAddExtraService] = useState(false);
    const [bookingType, setBookingType] = useState("")
    const [selectedServices, setSelectedServices] = useState([])
    const serviceCategoryContainerRef = useRef(null);


    /******************** Step 3 States ***********************/
   
    const [selectedStaff, setSelectedStaff] = useState(null);
    const [selectedFinalDate, setSelectedFinalDate] = useState(null);


    /******************** Pagination States ***********************/
    const [page, setPage] = useState(1);
    const [servicePage, setServicePage] = useState(1);
    const serviceItemsPerPage = 10
    const itemsPerPage = 10

    /************************  Plan ****************************/
    const [selectedPlanTime, setSelectedPlanTime] = useState([])
    const [sessionActiveStep, setSessionActiveStep] = useState(0);
    const existingCustomerContainerRef = useRef(null);
    const [scrollToExistingCustomer, setScrollToExistingCustomer] = useState(false)
    const maxSession = bookingType === 'plan' &&  selectedServices[0]?.no_of_bookings ?  parseInt(selectedServices[0]?.no_of_bookings): 0;

    const navigate = useNavigate();
    const {pathname,state} = useLocation();
    const theme = useTheme();
    const dispatch = useDispatch()
    const bookAppointmentStore = useSelector((state) => state?.bookAppointmentStore)
    const crmStaffStore = useSelector(state => state?.crmStaffStore)
    const authStore = useSelector(state => state.authStore)

    const {existingUsers = [], servicesCategory = [], crmAllPlans = [],currentPage, search} = bookAppointmentStore
    const {crmStaffs = []} = crmStaffStore

    const fetchPageData = async (page) => {
        await dispatch(updateCrmFilters({currentPage: page}))
        await fetchCrmCustomer()
    }
    const handleSearch = async (val) => {
        await dispatch(updateCrmFilters({search: val}))
    }

    const mainDivRef = useRef(null);
    useEffect(() => {
        const handleWindowResize = () => {
    
          if (window.innerWidth >= 768) {
            setBookingType('service');
          } else {
            setBookingType(''); 
          }
        };
    
      
        handleWindowResize();
  
        window.addEventListener('resize', handleWindowResize);
    
       
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);
    useEffect(() => {
 (async () => {
            await dispatch(getAllExistingUser())
            dispatch(getProviderStaff())
            dispatch(getServicesCategory())
            // dispatch(getCrmAllPlans())
        })()
    }, [])
useEffect(()=>{
if(state?.user){
    console.log("state------",state)
setActiveType('new_customer')
setActiveStep(1)
setSelectedUser({...state?.user,id:state?.user?.customer_id})
}
},[])
    useEffect(() => {
        setFilteredUser(existingUsers|| [])
    }, [existingUsers])
    const fetchCrmCustomer = async () => {
        setLoading(true)
        await dispatch(getAllExistingUser())
        setLoading(false)
    }

    const handleServiceAdded = (category) => {
        console.log("selected id",category)
        setAddExtraService(false);
        setBookingType('service');
       
        handleGetServices(category,true)
        
    };   
    // Define validation schema for your form fields
    const validationSchema = yup.object({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        phone: Yup.string().required('Phone number is required'),
        medical_notes: Yup.string(),
        staff_notes: Yup.string(),
        note_by: Yup.mixed(),
    });
    const validationUserSchema = yup.object({
        medical_notes: Yup.string(),
        staff_notes: Yup.string(),
        note_by: Yup.mixed(),
    });

    const {values, resetForm, setFieldTouched,setFieldError,handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            medical_notes: '',
            staff_notes: '',
            note_by: null,
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            if (data?.medical_notes!=='' || data?.staff_notes !==''){
                if (data?.note_by === null){
                    await setFieldTouched(true)
                    setFieldError('note_by',"Please Select A Staff")
                    return;
                }
            }
            if (data?.note_by !==null ){
                if (data?.medical_notes === '' && data?.staff_notes ===''){
                    await setFieldTouched(true)
                    setFieldError('medical_notes',"Please Add Medical or Staff Notes")
                    return;
                }
            }

            setLoading(true)
            let res = await dispatch(findGuestUser({email: data?.email}))
            setLoading(false)

            if (res?.payload?.success === 'true') {
                await successConfirmation('Warning', 'User Already Exist with this Email', 'warning')
            } else if (!res?.payload?.success) {
                handleNext()
            }

        },
    });
    const {
        values:userValues,
        resetForm:userResetForm,
        setFieldTouched:setUserFieldTouched,
        setFieldError:setUserFieldError,
        handleSubmit:handleUserSubmit,
        setFieldValue:setUserFieldValue,
        handleChange:userHandleChange,
        touched:userTouched,
        errors:userErrors
    } = useFormik({
        initialValues: {
            medical_notes: '',
            staff_notes: '',
            note_by: null,
        },
        validationSchema: validationUserSchema,
        onSubmit: async (data) => {

            if (data?.medical_notes!=='' || data?.staff_notes !==''){
                if (data?.note_by === null){
                    setUserFieldTouched(true)
                    setUserFieldError('note_by',"Please Select A Staff")
                    return;
                }
            }
            if (data?.note_by !==null ){
                if (data?.medical_notes === '' && data?.staff_notes ===''){
                    setUserFieldTouched(true)
                    setUserFieldError('medical_notes',"Please Add Medical or Staff Notes")
                    return;
                }
            }

            await handleSelectedUser(selectedUser,true)

        },
    });
    const scrollToServiceCategoryContainer = () => {
        if (serviceCategoryContainerRef.current) {
          serviceCategoryContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
      
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        if (activeStep === steps.length - 1) {
            toast.success('Form Submitted');
        }
    }
    const handleExistingCustomerCheck = (isChecked) => {
        console.log('handleExistingCustomerCheck called. isChecked:', isChecked);
        if (isChecked) {
          console.log('Scrolling into view...');
          if (existingCustomerContainerRef.current) {
            existingCustomerContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            console.log('Scrolling executed.');
          } else {
            console.log('existingCustomerContainerRef is null or undefined.');
          }
        }
      };  
      
    const UserForm = () => (
        <Grid container sx={customerside} style={{marginTop: '15px'}}>
            <Grid item xs={12} md={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                <FormControl fullWidth sx={{mb: "16px"}}>
                    <FormLabel sx={labelStyle}>Name</FormLabel>
                    <TextField
                        fullWidth
                        name="name"
                        value={values?.name}
                        sx={inputStyle}
                        onChange={handleChange}
                        placeholder="Name"
                    />
                    {errors?.name && touched?.name && <FormHelperText sx={{
                        color: '#CB3838',
                        width: 'max-content',
                        mx: 0
                    }}>{errors?.name}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} md={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                <FormControl fullWidth sx={{mb: "16px"}}>
                    <FormLabel sx={labelStyle}>Email</FormLabel>
                    <TextField
                        fullWidth
                        name="email"
                        onChange={handleChange}
                        value={values?.email}
                        sx={inputStyle}
                        placeholder="Email"
                    />
                    {errors?.email && touched?.email && <FormHelperText sx={{
                        color: '#CB3838',
                        width: 'max-content',
                        mx: 0
                    }}>{errors?.email}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} md={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                <FormControl fullWidth sx={{mb: "16px"}}>
                    <FormLabel sx={labelStyle}>Phone No</FormLabel>
                    <TextField
                        fullWidth
                        name="phone"
                        onChange={handleChange}
                        value={values?.phone}
                        sx={inputStyle}
                        placeholder="Phone No"
                    />
                    {errors?.phone && touched?.phone && <FormHelperText sx={{
                        color: '#CB3838',
                        width: 'max-content',
                        mx: 0
                    }}>{errors?.phone}</FormHelperText>}
                </FormControl>
            </Grid>

            <Grid xs={12} item sx={{ width:{xs:"100%",sm:'auto'}}}>
                <Typography sx={customernote}>
                    Add Notes
                </Typography>
            </Grid>

            <Grid item xs={12} sm={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                <FormControl fullWidth sx={{mb: "16px"}}>
                    <FormLabel sx={labelStyle} style={{marginBottom: 10}}>Medical Notes</FormLabel>
                    <TextField
                        fullWidth
                        name="medical_notes"
                        sx={inputStyle}
                        onChange={handleChange}
                        value={values?.medical_notes}
                        placeholder="Medical Notes"
                        multiline
                        rows={4}
                    />
                    {errors?.medical_notes && touched?.medical_notes && <FormHelperText sx={{
                        color: '#CB3838',
                        width: 'max-content',
                        mx: 0
                    }}>{errors?.medical_notes}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={5.5} style={{paddingTop: 10}} sx={{ width:{xs:"100%",sm:'auto'}}}>
                <FormControl fullWidth sx={{mb: "16px"}}>
                    <FormLabel sx={labelStyle} style={{marginBottom: 10}}>Staff Notes</FormLabel>
                    <TextField
                        fullWidth
                        style={{mt: 4}}
                        onChange={handleChange}
                        value={values?.staff_notes}
                        name="staff_notes"
                        sx={inputStyle}
                        placeholder="Staff Notes"
                        multiline
                        rows={4}
                    />
                    {errors?.staff_notes && touched?.staff_notes && <FormHelperText sx={{
                        color: '#CB3838',
                        width: 'max-content',
                        mx: 0
                    }}>{errors?.staff_notes}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid
                item
                xs={12}
                sm={5.5}
                style={{paddingTop: 10}}
                sx={{ width:{xs:"100%",sm:'auto'}}}
            >
                <FormControl fullWidth>
                    <FormLabel sx={{...labelStyle}}>Note By</FormLabel>
                    <Autocomplete
                        options={crmStaffs}
                        value={values?.note_by}
                        disableCloseOnSelect={false}
                        isOptionEqualToValue={(option, value) => {
                            return option?.id === value?.id
                        }}
                        getOptionLabel={(option) => option?.name || ''}
                        renderInput={(params) => (
                            <TextField {...params} placeholder={'Select Staff'} sx={inputStyle}/>
                        )}
                        onChange={(event, newValue) => {
                            setFieldValue('note_by', newValue);
                        }}
                        renderOption={(props, option, {selected}) => (
                            <MenuItem  {...props} key={option?.id} >
                                <Checkbox
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option?.name}
                            </MenuItem>
                        )}
                    />
                    {errors?.note_by && touched?.note_by && <FormHelperText sx={{
                        color: '#CB3838',
                        width: 'max-content',
                        mx: 0
                    }}>{errors?.note_by}</FormHelperText>}
                </FormControl>
            </Grid>
        </Grid>
    )
    const handleSelectData = (data) => {
        let finalData = {...data, "service_quantity": 1, booking_cost: null};
    
        if (bookingType === 'plan') {
            setSelectedServices([finalData]);
            if (mainDivRef.current) {
                mainDivRef.current.scrollIntoView({ behavior: 'smooth' });
              }
        } else {
            if (selectedServices?.length > 0) {
                if (isExist(selectedServices, finalData)) {
                    const finalRes = removeCard(selectedServices, finalData);
                    setSelectedServices(finalRes);
                } else {
                    setSelectedServices([...selectedServices, finalData]);
                    if (mainDivRef.current) {
                        mainDivRef.current.scrollIntoView({ behavior: 'smooth' });
                      }
                }
            } else {
                setSelectedServices([finalData]);
                if (mainDivRef.current) {
                    mainDivRef.current.scrollIntoView({ behavior: 'smooth' });
                  }
            } 
        }
    };
    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            handleSaveTwo(); // Call handleSaveTwo when checkbox is checked
        }
    };
    const extraserviceRef =useRef(null);

    const handleSelectedUser =async (user,isNext)=>{

        if (userCards?.length){
            if (isNext){
                handleNext()
            }
        }else {

            setLoading(true)
            let {payload} = await dispatch(getAllExistingUserCards(user?.id))

            if (payload?.data?.id) {
                if (payload?.data?.payment_method_id === 4) {
                    setUserCards(payload?.data?.cards || [])
                } else {
                    setUserCards(payload?.data?.cards || [])
                }
            } else {
                setUserCards([])
                if (extraserviceRef.current) {
                    extraserviceRef.current.scrollIntoView({ behavior: 'smooth' });
                    console.log('Scrolling executed. on existing ');
                  }
              
            }
            setLoading(false)
            if (isNext){
                handleNext()
            }
        }
    }

    const getStepContent = step => {
        switch (step) {
            case 0:
                return (
                    <Fragment key={step}>
                        <Grid item xs={12} sx={customerside} style={{paddingTop: '11px!important'}}>
                            <Typography sx={content}>Select customer from the list below</Typography>
                            <FormControl sx={{mr: "10px", ml: "10px"}}>
                            <BackendSearchField
                                        search={search}
                                        handleSearch={handleSearch}
                                        fetchData={async () => {
                                            await dispatch(updateCrmFilters({currentPage: 1}))
                                            await fetchCrmCustomer()
                                        }}
                                    />  
                            </FormControl>
                        </Grid>
                        <Grid container ref={existingCustomerContainerRef}>
                            <Grid item xs={12}>
                                {/* {existingUsers?.data.length > 0 ?  */}
                                {existingUsers && existingUsers?.data && existingUsers?.data.length > 0 ? ( 
                                    <form onSubmit={handleUserSubmit}>
                                    <Box>
                                        <TableContainer>
                                            <Table aria-label="customized table" sx={{mt: '10px'}}>
                                                <TableBody>
                                                    {
                                                        existingUsers?.data.slice((page - 1) * itemsPerPage, page * itemsPerPage)?.map((user, index) => (selectedUser?.id ? selectedUser?.id === user?.id : true) && (
                                                            <Tooltip
                                                                key={index}
                                                                title={user?.is_membered === 1? user?.provider_name:''}
                                                            >
                                                            <StyledTableRow
                                                                sx={{
                                                                    cursor:'pointer',
                                                                    my:"4px",
                                                                    "&:hover": {
                                                                        background:"#F6FAFD"
                                                                    }
                                                                }}
                                                                onClick={()=>{
                                                                    if (selectedUser?.id) {
                                                                        setSelectedUser({})
                                                                        setUserCards([])
                                                                    } else {
                                                                        setSelectedUser(user)
                                                                    }
                                                                }}
                                                            >
                                                                <StyledTableCell sx={{width: '70px', pl: '0'}}>
                                                                    <Checkbox
                                                                        checked={selectedUser?.id === user?.id}
                                                                        onChange={(e) => {
                                                                            if (e?.target?.checked) {
                                                                                setSelectedUser(user)
                                                                            } else {
                                                                                setSelectedUser({})
                                                                                setUserCards([])
                                                                            }
                                                                        }}
                                                                    />
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    sx={{
                                                                        pl: 0,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display:"flex",alignItems:"center",
                                                                        }}
                                                                    >
                                                                    {user?.is_batch === 1 &&
                                                                        <Box sx={{mr:'10px'}}>
                                                                            <img
                                                                                style={{marginTop: '4px'}}
                                                                                src={user.customer_batch.includes("Expire/Cancel")
                                                                                    ? redRibbon
                                                                                    : user.customer_batch.includes("Declined")
                                                                                        ? yellowRibbon
                                                                                        : ribbon}
                                                                                alt={'member'}
                                                                            />
                                                                        </Box>
                                                                    }
                                                                   <Typography>
                                                                       {user?.name}
                                                                   </Typography>
                                                                    </Box>
                                                                </StyledTableCell>
                                                                <StyledTableCell>{user?.phone}</StyledTableCell>
                                                                <StyledTableCell>{user?.email}</StyledTableCell>
                                                            </StyledTableRow>
                                                            </Tooltip>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    
                                    <div style={{marginTop:"15px"}}>
 {

(!selectedUser?.id && existingUsers?.meta?.last_page > 1) &&
 <BackendPagination
     count={filteredUser?.meta?.last_page}
     currentPage={currentPage}
     handleOnChange={fetchPageData}
 />
}

                                        </div>
                                       
                                            {selectedUser?.id && <Grid container sx={customerside}>
                                                <Grid xs={12} item>
                                                    <Typography style={customernote} sx={{ width:{xs:"100%",sm:'auto'}}}>
                                                        Add Notes
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={5.5} sx={{ width:{xs:"100%",sm:'auto'}}}>
                                                    <FormControl fullWidth sx={{mb: "16px"}}>
                                                        <FormLabel sx={labelStyle} style={{marginBottom: 10}}>
                                                            Medical Notes
                                                        </FormLabel>
                                                        <TextField
                                                            fullWidth
                                                            name={'medical_notes'}
                                                            sx={inputStyle}
                                                            onChange={userHandleChange}
                                                            value={userValues?.medical_notes}
                                                            placeholder="Medical Notes"
                                                            multiline
                                                            rows={4}
                                                        />
                                                        {userErrors?.medical_notes && userTouched?.medical_notes &&
                                                            <FormHelperText sx={{
                                                                color: '#CB3838',
                                                                width: 'max-content',
                                                                mx: 0
                                                            }}>{userErrors?.medical_notes}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={5.5} style={{paddingTop: 10}} sx={{ width:{xs:"100%",sm:'auto'}}}>
                                                    <FormControl fullWidth sx={{mb: "16px"}}>
                                                        <FormLabel sx={labelStyle} style={{marginBottom: 10}}>
                                                            Staff Notes
                                                        </FormLabel>
                                                        <TextField
                                                            fullWidth
                                                            name={'staff_notes'}
                                                            style={{mt: 4}}
                                                            onChange={userHandleChange}
                                                            value={userValues?.staff_notes}
                                                            sx={inputStyle}
                                                            placeholder="Staff Notes"
                                                            multiline
                                                            rows={4}
                                                        />
                                                        {userErrors?.staff_notes && userTouched?.staff_notes && <FormHelperText sx={{
                                                            color: '#CB3838',
                                                            width: 'max-content',
                                                            mx: 0
                                                        }}>{userErrors?.staff_notes}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={5.5}
                                                    style={{paddingTop: 10}}
                                                    sx={{ width:{xs:"100%",sm:'auto'}}}
                                                >
                                                    <FormControl fullWidth>
                                                        <FormLabel sx={{...labelStyle}}>Note By</FormLabel>
                                                        <Autocomplete
                                                            options={crmStaffs}
                                                            value={userValues?.note_by}
                                                            disableCloseOnSelect={false}
                                                            isOptionEqualToValue={(option, value) => {
                                                                return option?.id === value?.id
                                                            }}
                                                            getOptionLabel={(option) => option?.name || ''}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder={'Select Staff'}
                                                                    sx={inputStyle}
                                                                />
                                                            )}
                                                            onChange={(event, newValue) => {
                                                                setUserFieldValue('note_by',newValue);
                                                            }}
                                                            renderOption={(props, option, {selected}) => (
                                                                <MenuItem  {...props} key={option?.id} >
                                                                    <Checkbox
                                                                        style={{marginRight: 8}}
                                                                        checked={selected}
                                                                    />
                                                                    {option?.name}
                                                                </MenuItem>
                                                            )}
                                                        />
                                                        {userErrors?.note_by && userTouched?.note_by && <FormHelperText sx={{
                                                            color: '#CB3838',
                                                            width: 'max-content',
                                                            mx: 0
                                                        }}>{userErrors?.note_by}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            }
                                            {selectedUser?.id && (
                                                <Box sx={{display: 'flex', justifyContent: 'center', mt: '16px'}}>
                                                    <SubmitButton mainColor={'#27AE60'} subColor={'#fff'} style={{width:"200px"}} size={'large'} val={'Continue '}/>
                                                </Box>
                                            )}
                                    </Box>
                                    </form>
                                    ) : (
                                    <Typography sx={{textAlign: 'center', mt: '20px'}}>
                                        No Users
                                    </Typography>
                                    )}
                            </Grid>
                        </Grid>
                    </Fragment>
                )
            case 1: 
                return (
                    <Fragment key={step}>
                        {selectedCategory === "extra" &&
                            <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Grid item xs={12} lg={5.5}>
                                    <FormControl fullWidth sx={{mb: "16px"}}>
                                        <FormLabel style={{fontsize: '6px'}}>Add Service Name</FormLabel>
                                        <TextField
                                            fullWidth
                                            name=""
                                            sx={inputStyle}
                                            placeholder=""
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={5.5}>
                                    <FormControl fullWidth sx={{mb: "16px"}}>
                                        <FormLabel style={{fontsize: '10px'}}>Time</FormLabel>
                                        <TextField
                                            fullWidth
                                            name="Time"
                                            sx={inputStyle}
                                            placeholder=""
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={5.5}>
                                    <FormControl fullWidth sx={{mb: "16px"}}>
                                        <FormLabel style={{fontsize: '10px'}}>Price</FormLabel>
                                        <TextField
                                            fullWidth
                                            label=''
                                            sx={inputStyle}
                                            placeholder=""
                                        />   
 
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={5.5}>
                                    <FormControl fullWidth sx={{mb: "16px"}}>
                                        <FormLabel style={{fontsize: '10px'}}>Discount</FormLabel>
                                        <TextField
                                            fullWidth
                                            label=''
                                            sx={inputStyle}            
                                            placeholder=""
                                        />
                                    </FormControl>
                                </Grid>  
                            </Grid>
                        }
                        <Grid container>
                            {bookingType !== '' && <Grid item xs={12} sx={customerside}>
                                <Typography sx={content}>
                                    Select service from the menu below
                                </Typography>
                                <FormControl sx={{mr: "10px", ml: "10px"}}>
                                    <SearchField
                                        totalData={bookingType === 'plan' ? crmAllPlans : selectedCategory?.services}
                                        setFilterData={setFilteredData}
                                        setPage={setServicePage}
                                        isServices={bookingType !== 'plan'} // Assuming crmAllPlans is not services data
                                        allServicesCategories={servicesCategory}
                                    />  
                                </FormControl>
                            </Grid>
                            }
                            {(bookingType === "") &&
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{my: '24px', textAlign: 'center', fontFamily: "helvetica-lt-std-bold"}}>
                                        Choose Booking Type
                                    </Typography>  
                                </Grid>
                            }
                            {(bookingType === "service") && selectedCategory === '' && !filteredData?.length &&
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{my: '24px', textAlign: 'center', fontFamily: "helvetica-lt-std-bold"}}>
                                        {selectedCategory === '' && "Select Events Category"}
                                    </Typography>
                                </Grid>
                            }   
                            <Grid item xs={12} ref={serviceCategoryContainerRef}>
                                <Grid container sx={wrappercard} style={{marginTop: '20px',justifyContent:"space-around", gap: {lg:'20px', md:'10px', xs:'5px'},alignItems:"center"}}>
                                    {
                                        filteredData?.length > 0 && filteredData?.slice((servicePage - 1) * serviceItemsPerPage, servicePage * serviceItemsPerPage)
                                            ?.map((service, index) => (
                                                <Grid
                                                    key={index}
                                                    item
                                                    lg={3.8}             
                                                    sm={5.8}
                                                    xs={12}
                                                    sx={{ cursor: 'pointer', mt:2}}
                                                    onClick={() => handleSelectData(service)}
                                                >
                                                    <Checkbox
                                                        checked={isExist(selectedServices, service)}
                                                        onChange={handleCheckboxChange}  
                                                        sx={{                      
                                                            top: "3px",
                                                            zIndex: '1'
                                                        }}
                                                    />
                                                    <Card sx={{mt: '-40px', height: '100%'}}>
                                                        <Avatar
                                                            src={service?.image}
                                                            sx={{  
                                                                width: "100%",
                                                                height: "125px",
                                                            }}
                                                            variant={'rounded'}
                                                        />
                                                        <Box
                                                            sx={{
                                                                p: '8px',
                                                                // display: "flex",
                                                                // flexDirection: "column",
                                                                // justifyContent: "space-between",
                                                                // height: "50%",
                                                            }}>
                                                            <Typography style={{
                                                                fontSize: '13.7px',
                                                                fontWeight: '700',
                                                                fontFamily: 'helvetica-lt-std-bold',  
                                                                textAlign: 'center'  
                                                            }}>
                                                                {service?.name || service?.plan_name}
                                                            </Typography>
                                                            <Box
                                                                sx={{display: 'flex', justifyContent: 'center', mt: '8px'}}>
                                                                <div style={{textAlign: 'center'}}>
                                                                    <Typography sx={{fontSize: '12px'}}>
                                                                        Regular Price
                                                                    </Typography>
                                                                    <Typography style={{
                                                                        fontSize: '17px',
                                                                        fontWeight: '700',
                                                                        fontFamily: 'helvetica-lt-std-bold',
                                                                        color: selectedUser?.is_membered !== 1 ? '#04B4FF' : '#000'
                                                                            
                                                                    }}>
                                                                        ${service?.regular_price}
                                                                    </Typography>
                                                                </div>
                                                                <Box sx={{p: '2px 10px'}}>
                                                                    <Divider     
                                                                        orientation='vertical'
                                                                        style={{
                                                                            borderColor: '#8D8D8D',
                                                                        }}
                                                                    />
                                                                </Box>
                                                                <div style={{textAlign: 'center'}}>
                                                                    <Typography sx={{fontSize: '12px'}}>
                                                                        Member’s Price
                                                                    </Typography>
                                                                    <Typography style={{
                                                                        fontSize: '17px',
                                                                        fontWeight: '700',
                                                                        fontFamily: 'helvetica-lt-std-bold',
                                                                        color: selectedUser?.is_membered === 1 ? '#04B4FF' : '#000'
                                                                    }}>
                                                                        ${service?.member_price}
                                                                    </Typography>
                                                                </div>
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ))
                                    }
                                    {filteredData?.length > serviceItemsPerPage &&
                                        <Grid item xs={12}
                                              sx={{display: 'flex', justifyContent: 'center', mt: '40px'}}>
                                            <CustomPagination
                                                totalData={filteredData}
                                                page={servicePage}
                                                setPage={setServicePage}
                                                itemsPerPage={serviceItemsPerPage}/>    
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between', mt: '24px'}} ref={mainDivRef}>
                                <Box>
                                   <CustomButton
                                        size={'large'}
                                        mainColor={'#EB5757'}  
                                        subColor={'#fff'}
                                        handleClick={handleBack}  
                                        val={"Back"} 
                                    />
                                </Box>
                                {/* { selectedServices?.length > 0 &&
                                    <CustomButton size={'large'} mainColor={'#27AE60'} subColor={'#fff'} style={{width:"200px"}} handleClick={handleSaveTwo} val={'Continue '}/>
                                } */}
                            </Grid>
                        </Grid> 
                    </Fragment>
                )
            // case 2:
            //     return (
            //         <Fragment>
            //             <Grid container >
            //                 <Grid item xs={12}>
            //                     <Typography sx={contenttime}>Select date from available slots</Typography>
            //                     <Box sx={{display:"flex",justifyContent:'space-between',width:'100%'}}>
            //                         <Typography sx={timehead}>Treatment Slot: {getTreatmentTime()} Minutes</Typography>
            //                             <Box sx={{ display: 'flex', alignItems: 'center'}}>
            //                                 {selectedFinalDate ?
            //                                     <Typography sx={{fontSize: '20px',fontFamily: "helvetica-lt-std-bold",}}>
            //                                         {moment(selectedFinalDate).format('dddd, Do MMMM, YYYY')}
            //                                     </Typography> : ""
            //                                 }    
            //                             </Box>
            //                     </Box>     
            //                 </Grid>
            //                 {bookingType === 'plan' && <Grid item xs={12}>
            //                     <Typography
            //                         sx={{fontSize: '20px', textAlign: 'center', fontFamily: "helvetica-lt-std-bold",}}>
            //                         Session: {sessionActiveStep + 1}
            //                     </Typography>
            //                 </Grid>}
            //                 <Grid xs={12} item sx={{mt: "24px",overflow:'auto'}}>
            //                     { bookingType === 'plan' ?  
            //                     <Box>
            //                         <PlanInvoiceTime
            //                             activeSession={sessionActiveStep}
            //                             selectedServices={selectedServices[0]?.planService}
            //                             selectedStaff={selectedStaff}
            //                             selectedAllPlanTime={selectedPlanTime}
            //                             selectedTime={selectedPlanTime}
            //                             setSelectedTime={setSelectedPlanTime}
            //                             selectedFinalDate={getSelectedDate(sessionActiveStep)}
            //                             getAppointmentSchedule={getPlanAppointmentSchedule}
            //                             getAppointmentHoliday={getAppointmentHoliday}
            //                         />
            //                         {maxSession > 1 &&
            //                             <Box sx={{my:'16px'}}>
            //                                 <MobileStepper
            //                                     variant="text"
            //                                     steps={maxSession}
            //                                     position="static"
            //                                     activeStep={sessionActiveStep}
            //                                     nextButton={
            //                                         <Button
            //                                             size="small"
            //                                             variant={'contained'}
            //                                             onClick={handleSessionNext}
            //                                             disabled={sessionActiveStep === maxSession - 1}
            //                                         >
            //                                             <Box sx={{mt:'2px'}}>Next Session</Box>
            //                                             {theme.direction === 'rtl' ? (
            //                                                 <KeyboardArrowLeft/>
            //                                             ) : (
            //                                                 <KeyboardArrowRight/>
            //                                             )}
            //                                         </Button>
            //                                     }
            //                                     backButton={
            //                                         <Button
            //                                             size="samll"
            //                                             variant={'contained'}
            //                                             onClick={handleSessionBack}
            //                                             disabled={sessionActiveStep === 0}
            //                                         >
            //                                             {theme.direction === 'rtl' ? (
            //                                                 <KeyboardArrowRight/>
            //                                             ) : (
            //                                                 <KeyboardArrowLeft/>
            //                                             )}
            //                                             <Box sx={{mt:'2px'}}>Previous Session</Box>
            //                                         </Button>
            //                                     }
            //                                 />
            //                             </Box>
            //                         }
            //                     </Box>
            //                     :
            //                     <InvoiceTime
            //                         selectedStaff={selectedStaff}
            //                         setSelectedFinalDate={setSelectedFinalDate}
            //                         selectedFinalDate={selectedFinalDate}
            //                         getAppointmentSchedule={getAppointmentSchedule}
            //                         getAppointmentHoliday={getAppointmentHoliday}
            //                     />
                          
            //                     }
            //                 </Grid>
            //                 <Grid item xs={12}
            //                       sx={{display: 'flex', justifyContent: 'space-between', marginTop: '24px'}}>
            //                     <Box>
            //                         <CustomButton      
            //                             size={'large'}
            //                             mainColor={'#EB5757'}
            //                             subColor={'#fff'}
            //                             handleClick={handleBack}
            //                             val={"Back"}
            //                         />
            //                     </Box>
            //                     {/*<CustomButton size={'large'} val={'Continue'}/>*/}
            //                 </Grid>
            //             </Grid>

            //         </Fragment>
            //     )
            // default:
            //     return 'Unknown Step'
        }
    }

    /**********************   Plan *********************************/


    const getPlanAppointmentSchedule = async (date,stepId) => {

        if (date){
            let finalDate = [
                {
                    stepId:stepId,
                    date:date,
                }
            ]
            if (selectedPlanTime?.length > 0){
                let filterDate =  selectedPlanTime.filter((item)=>item?.stepId !== stepId)
                setSelectedPlanTime([...filterDate,...finalDate])
            }else {
                setSelectedPlanTime([...finalDate])
            }


        }

        let finalDate = moment(date).format('YYYY-MM-DD')
        setSelectedFinalDate(finalDate)
    }

    const getSelectedDate = (stepId) => {

        let filtered = selectedPlanTime.filter((item)=>item?.stepId === stepId)

        if (filtered?.length > 0 ){
            let obj = filtered[0]
            let day = moment(obj?.date).format('DD')
            let month = moment(obj?.date).format('MM')
            let year = moment(obj?.date).format('YYYY')
            return {
                day: parseInt(day),
                month: parseInt(month),
                year:parseInt(year),
            }
        }else {
            return {day:0,month: 13,year:1111}
        }
    }
    const handleSessionNext = () => {
        setSessionActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleSessionBack = () => {
        setSessionActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getTreatmentTime = () => {
        if (bookingType === 'plan') {
            let totalTime = 0
            selectedServices[0]?.planService?.forEach((item) => {
                let time = convertTimeToMinutes(item?.time_duration)
                totalTime = totalTime + time
            })

            return totalTime
        } else {
            let totalTime = 0
            selectedServices.forEach((item) => {
                let time = convertTimeToMinutes(item?.time_duration)
                totalTime = totalTime + time
            })

            return totalTime
        }
    }
    const datepageRef =useRef(null)
    const handleSaveTwo = async () => {

        let newDate = new Date()
        handleNext()
            setLoading(true)
            await getAppointmentHoliday(newDate)
            setSelectedFinalDate(moment(newDate).format('YYYY-MM-DD'))
            await getAppointmentSchedule(newDate)

            setLoading(false)
            setTimeout(() => {
                if (datepageRef.current) {
                    datepageRef.current.scrollIntoView({ behavior: "smooth" });
                }
            }, 500);

    }
    const getAppointmentHoliday = async (date, staff) => {
        setLoading(true)
        let finalDate = moment(date).format('YYYY-MM-DD')
         if (finalDate) {
            let finalData = {
                date: finalDate
            }
            if (staff?.id) {
                finalData['staff_id'] = staff?.id
            }
          await dispatch(getCrmAppointmentHolidays(finalData))
        }

        setLoading(false)


    }
    const dateRef = useRef(null);
    const getAppointmentSchedule = async (date) => {
        let finalDate = moment(date).format('YYYY-MM-DD')
        setSelectedFinalDate(finalDate)
        setTimeout(() => {
            if (dateRef.current) {
                dateRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }, 500);
    }

    const isExist = (arr, foundItem) => {

        if (arr?.length) {
            return arr.some((item) => bookingType === 'plan' ? foundItem?.plan_id === item?.plan_id : foundItem?.id === item?.id)
        } else {
            return false
        }

    }
    const renderContent = () => {
        if (activeType === 'new_customer' && activeStep === 0) {
            return (
                <Fragment>
                    <form onSubmit={handleSubmit}>
                        <Grid ref={existingCustomerContainerRef}>
                            <Grid item xs={12} style={customerside} sx={{ width:{xs:"100%",sm:'auto'}}}>
                                <Typography sx={content}>Add a New Customer</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {UserForm()}
                            </Grid>
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', mt: '24px'}}>
                                <SubmitButton val={"Continue"} mainColor={'#27AE60'} subColor={'#fff'} style={{width:"200px"}}/>
                            </Grid>
                        </Grid>
                    </form>
                </Fragment>
            )
        } else if (activeType === 'walk_in_customer' && activeStep === 0) {
            return (
                <Fragment>
                    <form onSubmit={handleSubmit}>
                        <Grid>
                            <Grid item xs={12} sx={customerside}>
                                <Typography sx={content}>Add a New Walk In Customer</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {UserForm()}
                            </Grid>
                            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', mt: '24px'}}>
                                <SubmitButton val={"Continue"} size={"large"}/>
                            </Grid>
                        </Grid>
                    </form>
                </Fragment>
            )
        }
        return (
            <Grid container>
                {getStepContent(activeStep)}
            </Grid>
        )
    }

    // const handleGetServices = async (cate) => {
    //     setSelectedCategory(cate)
    //     setFilteredData(cate?.services)

    // }
    const handleGetServices = async (category, fromServiceAdded = false) => {
        console.log("modal off call", category);
        setSelectedCategory(category); 
    
       
        // setSelectedCategoryId(category?.category_id);
    
       
        setFilteredData(category?.services);
    
       
        if (fromServiceAdded && category?.services?.length > 0) {
       
            setTimeout(() => {
                const lastService = category.services[category.services.length - 1];
                handleSelectData(lastService);
            }, 0);
        }
    };
    const handleBookingTypeChange = (type) => {
        if (type === bookingType) {
            setBookingType('')
            setFilteredData([])
            setSelectedCategory('')
        } else {
            setBookingType(type)
            if (type === 'plan') {
                setFilteredData(crmAllPlans)
            } else {
                setFilteredData([])
                setSelectedCategory('')
            }
        }
        setSelectedServices([])
    }


    return (
        <Box>
            <Grid container>
                <Grid item xs={12} sx={{display: 'flex',flexDirection:{xs:'column',sm:'row'}, alignItems: 'center',justifyContent:'space-between',flexWrap:{xs:'wrap',sm:'nowrap'}}}>
                    <CustomBreadcrumbs
                        back={'/crm/point-of-sale'}
                        backLabel={'Point of Sale (POS)'}
                        name={ "New Invoice" || null}
                    />
                    <CustomButton   
                        val={"All Invoices"}
                        size={"large"}
                        // style={{ width:'100%' }}
                         handleClick={() => {
                            localStorage.setItem('url', pathname)
                            navigate(`/crm/point-of-sale`)
                        }}
                    />
                </Grid>
            </Grid>
            {loading ? <Waveloading fullSize/> : ''}
            <Grid container style={{display: 'flex', justifyContent: 'space-between',}}>

                {(activeStep === 0 || activeStep === 1) && (
                    <Grid item xs={12} lg={2} md={2.3} sm={2.8} style={{marginTop: '41px'}} ref={extraserviceRef}>
                        {activeStep === 0 && (
                            <Box>
                                <FormControl sx={{mb: '16px'}}>
                                    <FormLabel sx={{...labelStyle}}>Customer Selection</FormLabel>
                                    {customerTypes.map((item, index) => (
                                      <FormControlLabel
                                      key={index}
                                      control={
 <Checkbox
  checked={item?.key === activeType}
  onChange={(e) => {
    console.log('Checkbox clicked. isChecked:',activeType );
    setActiveStep(0);
    setActiveType(item?.key);
    setUserCards([]);
    setSelectedUser({});
    resetForm();
    handleExistingCustomerCheck(e.target.checked)
  }}
/>

                                      }
                                      label={item?.name}
                                    />
                                    
                                    ))}
                                </FormControl>
                            </Box> 
                        )}
                        {activeStep === 1 && (
                            <Box>
                                <FormControl sx={{mb: '16px', width:{lg:'auto', xs:'100%'}}}>
                                    <CustomButton
                                        handleClick={() => setAddExtraService(true)}
                                        mainColor={'#27AE60'}
                                        val={"Add Extra Service"}
                                    />

                                    <CustomModal
                                        open={addExtraService}
                                        setOpen={()=>setAddExtraService(false)}
                                        customWidth={"800px"}
                                    >
                                        <AddServices  onClose={handleServiceAdded} clinicId={authStore?.user?.id}/>
                                    </CustomModal>
                                    <Box sx={{  display:{lg:'none',sm:'none', xs:'block'},mt:2, backgroundColor:"white", border:"1px solid silver", borderRadius:"5px" }}>
                                    <Autocomplete
  disablePortal
  id="service-category-selector"
  options={servicesCategory}
  getOptionLabel={(option) => option.category_name}
  sx={{ width: '100%' }}
  renderInput={(params) => <TextField {...params} label="Service Categories:" />}
  onChange={(event, newValue) => {
    if (newValue) {
      handleBookingTypeChange('service');
      handleGetServices(newValue); 
      scrollToServiceCategoryContainer();
    }
  }}
/>
                                    </Box>
                                    <Box sx={{  display:{lg:'none',sm:'none', xs:'block'},mt:2, backgroundColor:"white", border:"1px solid silver", borderRadius:"5px" }}>
                                    <Autocomplete
  disablePortal
  id="plan-selector"
  options={crmAllPlans} 
  getOptionLabel={(option) => option.plan_name} 
  sx={{ width: '100%' }}
  renderInput={(params) => <TextField {...params} label="Bundle:" />}
  onChange={(event, newValue) => {
    if (newValue) {
    
      handleBookingTypeChange('plan');
     
      scrollToServiceCategoryContainer();
    }
  }}
/>
 
                                    </Box>

                                    <List
                                        sx={{
                                            mt: '24px',
                                            width: "100%",
                                            display:{lg:'block', sm:'block', xs:'none'},
                                            ".MuiListItemIcon-root ": {
                                                minWidth: "37px",
                                            },
                                        }}   
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton
                                            sx={{
                                                py: '0',
                                            }}
                                            onClick={() => handleBookingTypeChange('service')}
                                        >
                                            <ListItemText primary="Service Categories:"/>
                                            <Checkbox
                                                checked={bookingType === 'service'}
                                            />
                                        </ListItemButton>
                                        <Collapse in={bookingType === 'service'} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {servicesCategory?.length > 0 && servicesCategory.map((category, index) => (
                                                    category?.is_show === 1 && (
                                                    <ListItemButton
                                                        sx={{py: '0', pl: '30px'}}
                                                        key={index}
                                                        onClick={() => {
                                                            handleGetServices(category);
                                                            scrollToServiceCategoryContainer();
                                                          }}

                                                    >
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                checked={selectedCategory?.category_id === category?.category_id}
                                                            
                                                            />}
                                                            label={category?.category_name}
                                                        />
                                                    </ListItemButton>
                                                    )
                                                ))}
                                            </List>
                                        </Collapse>
                                    </List>
                                </FormControl>
                            </Box>
                        )}
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    lg={activeStep === 2 ? 7.8 : 5.8}
                    md={activeStep === 2 ? 12 : 9.6}
                    sm={activeStep === 2 ? 12 : 8.6}
                >
                    <Fragment>
                        <Card sx={{mt: 4, pt: 5}}>
                            <StepperWrapper>
                                <Stepper activeStep={activeStep} alternativeLabel>
                                    {steps.map((step, index) => {
                                        return (
                                            <Step key={index}>
                                                <StepLabel
                                                    onClick={()=> {
                                                    }}
                                                    sx={{cursor: 'pointer', }}
                                                >
                                                    {step?.title}
                                                </StepLabel>
                                            </Step>
                                        )
                                    })}
                                </Stepper>
                            </StepperWrapper>
                            <CardContent>{renderContent()}</CardContent>
                        </Card>
                    </Fragment>
                </Grid>
                <Grid  sx={booking} item xs={12} lg={4} sm={8.6} md={9.6}>
                    <InvoicePayment
                        selectedFinalDate={selectedFinalDate}
                        setSelectedServices={setSelectedServices}
                        selectedServices={selectedServices}
                        selectedStaff={selectedStaff}
                        selectedUser={selectedUser}
                        newUser={values}
                        userCards={userCards}
                        selectedUserNotes={userValues}
                        bookingType={bookingType}
                        selectedPlanTime={selectedPlanTime}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddNewInvoice;