import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../lib/apiHeader";
import apis from "../../../lib/apis/apis";

export const storeUserUpdatePassword = createAsyncThunk('storeUserUpdatePassword', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.user_change_password, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {
            console.log( response?.data?.errors)

            return response?.data
        } else if (response?.data?.success === "true") {

            // await dispatch(getClinicByID(data?.id))
            toast.success(`Password Updated Successfully`, {duration: 3000})
            return response?.data
        }

    }catch (err){
        if (err?.response?.data?.errors){
            return err?.response?.data
        }
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})


export const userUpdatePasswordSlice = createSlice({
    name: "userUpdatePasswordSlice",
    initialState: {
        servicesTypes: [],
    },
    reducers: {},

});

export default userUpdatePasswordSlice.reducer;
