import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiHeaders } from "../../lib/apiHeader";
import axios from "axios";
import apis from "../../lib/apis/apis";
import toast from "react-hot-toast";

export const getAllMenus = createAsyncThunk(
  "getAllMenus",
  async (data, { dispatch }) => {
    try {   
      const response = await axios.get(apis.get_all_menu, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      }
      if (response?.data?.success === false) {       
        return {
          data: response.data,
          errors: true,
        }; 
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        return {
          data: [],  
          errors: err?.response?.data?.errors,
        };      
      }    
    }
  }  
)   

// get_GHL_Webhook

export const getGhlWebhook = createAsyncThunk( "getGhlWebhook",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get(apis.get_GHL_Webhook, {
        headers: apiHeaders(),
      });
  
      if (response?.data?.success === "true") {   
        return response?.data?.data     
      }   
      if (response?.data?.success === false) {
        return { 
          data: response.data,
          errors: true,
        };
      }
    } catch (err) {  
      if (err?.response?.status !== 200) {
        return {   
          data: [],      
          errors: err?.response?.data?.errors,   
        };
      }
    }
  }
)

// Store_GHL         
  
export const storeMenu = createAsyncThunk('storeMenu', async (data) => {
  try {
    const response = await axios.post(apis.store_menu, {...data},{
      headers: apiHeaders()
    })
    return  response?.data      
  }catch (err){    
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})   
    }
    if (err?.response?.status!==200){    

      return {     
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})    

export const storeGHL= createAsyncThunk('storeGHL', async (data) => {
  try {
    const response = await axios.post(apis.Store_GHL, {...data},{
      headers: apiHeaders()
    })
    return  response?.data  
 
  }catch (err){
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})
    }
    if (err?.response?.status!==200){
      return {
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})

export const updateMenu = createAsyncThunk('updateMenu', async (data,{dispatch}) => {
  try {
    const response = await axios.post(apis.update_menu, {...data},{   
      headers: apiHeaders()   
    })

    if (response?.data?.success==='true'){
      await dispatch(getAllMenus())      

      return  response.data   
    }else {   
      return response?.data
    }
  }catch (err){   
    console.log(err)  
    if (err?.response?.status!==200){
      return {
        data :err?.response,
        errors:true  
      }       
    }
  }
})

export const updateghl = createAsyncThunk('updateghl', async (data,{dispatch}) => {
  try {
    const response = await axios.post(apis.update_ghl, {...data},{   
      headers: apiHeaders()   
    })  
  
    if (response?.data?.success==='true'){
      await dispatch(getGhlWebhook())      

      return  response.data   
    }else {   
      return response?.data
    }  
  }catch (err){   
    console.log(err)  
    if (err?.response?.status!==200){
      return {
        data :err?.response,   
        errors:true  
      }       
    }
  }
})

// delete_ghl
export const deleteMenu = createAsyncThunk('deleteMenu', async (id,{dispatch}) => {
  try {
    const response = await axios.get(apis.delete_menu+id,{
      headers: apiHeaders()
    })

    if (response?.data?.success ==='true'){
      await dispatch(getAllMenus())
      toast.success("Menu Deleted Successfully",{duration:3000})

    }else {
      console.log(response?.data)
    }

  }catch (err){
    console.log(err)
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})
    }
  }
})

export const deleteghl= createAsyncThunk('deleteghl', async (id,{dispatch}) => {
  try {
    const response = await axios.get(apis.delete_ghl+id,{
      headers: apiHeaders()
    })

    if (response?.data?.success ==='true'){
      await dispatch(getGhlWebhook())
      toast.success("Ghl Deleted Successfully",{duration:3000})

    }else {
      console.log(response?.data)
    }

  }catch (err){
    console.log(err)
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})
    }
  }
})
export const getMenuByID = createAsyncThunk('getMenuByID', async (id) => {
  try {
    const response = await axios.get(apis.get_menu_by_id+id,{
      headers: apiHeaders()
    })
    return  response?.data

  }catch (err){
    if (err?.response?.status!==200){
      return {
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})


// get_ghl_by_id


export const getGhlByID = createAsyncThunk('getGhlByID', async (id) => {
  try {
    const response = await axios.get(apis.get_ghl_by_id+id,{
      headers: apiHeaders()
    })
    return  response?.data

  }catch (err){
    if (err?.response?.status!==200){
      return {
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})
export const menuSlice = createSlice({
  name: "menuSlice",
  initialState: {
    menus: [],
    meta: {},
    links: {},  
    GHLWebhook:[],
  },
  reducers: {},
  extraReducers: (builder) => {    
    builder.addCase(getAllMenus.fulfilled, (state, action) => {
      state.menus = action.payload;
      state.meta = action.payload.meta;
      state.links = action.payload.links;
    });
    builder.addCase(getGhlWebhook.fulfilled, (state, action)  => {
      state.GHLWebhook = action.payload;
      state.meta = action.payload.meta;   
      state.links = action.payload.links;
    });  
    // 
  },
});   

export default menuSlice.reducer;
