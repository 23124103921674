import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CustomButtoncolor from '../custom-button/customColorButton';
// import { sendReminder } from '../../store/crm/pos';
import { sendReminder } from '../../store/crm/pos';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
// import {inputStyle, labelStyle} from "../../components/form/formInput";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 480px)': {
      width: "95%",
    },
  };
  const wrapper={
    alignItems:"center",
  }
  const heading={
    fontSize:"18px",
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'400',
    textAlign:"center",
    width:'100%'

  }
  export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}

const Remindermodal = ({ customer_id, booking_id }) => {
  const dispatch = useDispatch();
  // console.log("id get",customerId, bookingId )
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState("");
    const validate = (values) => {
      const errors = {};
  
      if (!values.message) {
        errors.message = "Message is required";
      }
  
      return errors;
    };
  
    const formik = useFormik({
      initialValues: {
        message: "",
      },
      validate,
      onSubmit: (values) => {
        handleSendReminder(values.message);
      },
    });

    const handleSendReminder = async () => {
      try {
        const payload = {
          customer_id: customer_id,
          booking_id: booking_id,
          message: formik.values.message
        };
        dispatch(sendReminder(payload));
        toast.success('Send invoice successfully'); // Display success toast
        handleClose();
      } catch (error) {
        console.log('Error sending reminder', error);
      }
    
       
    };
  
  return (
    <>  
<CustomButtoncolor
  handleClick={handleOpen}
  mainColor={"#333333"}
  size="large" 
  val='Send Reminder'
 style={{width: "100%", borderRadius: "6px!important"}}
     />
    <Modal
        style={{}}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={1} sx={wrapper}>
            <Grid item lg={12} style={{ display: "contents" }}>
              <Typography
                id="modal-modal-title"
                sx={heading}
              >
                Send Reminder
              </Typography>
            </Grid>
            <Box style={{ marginTop: '26px', display: 'flex', width: "100%", padding: "0px 0px 0px 19px" }}>
              <Grid item lg={9} md={9} sm={9}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    name="message"
                    sx={inputStyle}
                    placeholder="Message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormControl>
                {formik.touched.message && formik.errors.message && (
    <Typography color="error">{formik.errors.message}</Typography>
  )}
              </Grid>
              <Grid item lg={3} md={3} sm={3}>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    height: "50px",
                    width: "92px",
                    backgroundColor: 'linear-gradient(180deg, #72D5FF -43.75%, #0496FF 86.52%)',
                    borderRadius: '0px 8px 8px 0px'
                  }}
                  onClick={formik.handleSubmit} 
                >
                  Send
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Modal>
   </>
  )
}

export default Remindermodal;

