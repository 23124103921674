import UserLayout from "../../user";
import React, { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import Box from "@mui/material/Box";
import { Grid, Typography, Button, FormControl } from "@mui/material";
import TimelineContent from "@mui/lab/TimelineContent";
import moment from "moment";
import { useNavigate } from "react-router";
import Divider from "@mui/material/Divider";

import { useDispatch, useSelector } from "react-redux";
import Waveloading from "../../loading/Waveloading";
import DateRange from "../../../components/date-range/date-range";
import { ActivityLogData, updateActivityLogFilters } from "../../../store/crm/activity-log";
import { getAppointmentDetails } from "../../../store/crm/appointment";
import AppointmentDetails from "../appointment/appointment-details/appointmentDetails";
import CustomModal from "../../../components/modal/customModal";

const ActiviyLog = () => { 
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate()  
 
  const [appointmentDetails, setAppointmentDetail] = useState(null)
  const ActivityLogStore = useSelector(state => state?.ActivityLogStore)
  const dispatch = useDispatch()
    const {
        ActivityLogs=[],
        treatmentStartTime=null,
        treatmentEndTime=null,
       
    } = ActivityLogStore
  useEffect(() => {
    (async () => {
        await fetchTreatmentCallLogs()   
    })();
}, []);    

const fetchTreatmentCallLogs = async () => {
    setLoading(true);
    await dispatch(ActivityLogData());
    setLoading(false);
} 
const handleAppointmentDetail = async (booking_id) => {
  setLoading(true)
  let {payload} = await dispatch(getAppointmentDetails(booking_id))
  setLoading(false)
  if (payload?.id){ 
    setAppointmentDetail(payload)
  }else {    
    setAppointmentDetail(null)
  } 
}  
const handleCellClick = (itemId) => {
        
    navigate(`/crm/customer-relationship-management`, {  
      state: { value: itemId, },
    })   
}; 
  
const processedActivity =  ActivityLogs?.map(item => ({
  ...item,
  activity: item.activity    
      .replace(/Booking #\s*(\d+)/g, 'Booking #<span style="color: #09ABF1">$1</span>')
      .replace(/Invoice #\s*(\d+)/g, 'Invoice #<span style="color:#09ABF1">$1</span>'),
  description: item.description
      .replace(/Booking #\s*(\d+)/g, 'Booking #<span style="color: #09ABF1">$1</span>')
      .replace(/Invoice #\s*(\d+)/g, 'Invoice #<span style="color:#09ABF1">$1</span>'),
})); 
return ( 
  <Box>
  <Box sx={{ mb: 2 }}>
        <FormControl style={{width: "100%"}}>  
        <DateRange
        wid={"30%"} 
        smWidths={"100%"}
        color={'white'}
        setStartDate={async (val)=>{
          await dispatch(updateActivityLogFilters({startTime: val}))
      }}  
      startDate={treatmentStartTime}
      setEndDate={async (val)=>{
          await dispatch(updateActivityLogFilters({endTime: val}))
          if(val) {
              await fetchTreatmentCallLogs()
          }     
      }}
      endDate={treatmentEndTime}
     />
  </FormControl>   
        </Box>         
      {loading? <Waveloading fullSize/> :""}
  <Timeline
      sx={{
          p: "0",
          my: "0",
          [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
          },
      }}
      position="right"
  >
      {processedActivity.length === 0 ? (
          <Typography
              sx={{
                  textAlign: 'center',
                  fontFamily: "helvetica-lt-std-bold",
              }}
          >
              No Activity Found
          </Typography>
      ) : (
          processedActivity.map((item, index) => (
              <TimelineItem sx={{ minHeight: "200px" }} key={index}>
                  <TimelineSeparator sx={{ display: { xs: 'none', sm: 'flex' } }}>
                      <TimelineConnector
                          sx={{
                              background: "#2F80ED",
                              width: "16px",
                              borderRadius: "10px",
                              border: "6px solid #fff",      
                              maxHeight: "50px",
                              mb: "-10px",
                              borderTop: "6px solid #fff",
                              borderTopLeftRadius: '10px',
                              borderTopRightRadius: '10px',
                          }}
                      />  
                      <TimelineDot
                          variant="outlined"
                          sx={{
                              mx: "auto",
                              my: "0",
                              borderWidth: "5px",
                              borderColor: '#2F80ED',
                              zIndex: "10",
                          }}
                      />
                      <TimelineConnector
                          sx={{
                              background: "#2F80ED",
                              width: "16px",
                              borderRadius: "10px",
                              border: "6px solid #fff",
                              borderBottomLeftRadius: "0",
                              borderBottomRightRadius: "0",
                              borderBottom: '0',
                              mt: "-7px",
                          }}
                      />
                  </TimelineSeparator>
                  <TimelineContent sx={{ pl: { xs: "0px", sm: "32px" }, pb: { xs: "8px", sm: "32px" }, pt: 0, pr: 0 }}>
                      <Box
                          sx={{
                              p: { xs: "12px", sm: "24px" },
                              background: "#fff",
                              height: "100%",
                              borderRadius: "10px",
                          }}
                      >
                          <Grid container style={{ display: "flex", alignItems: "right" }}>
                              <Grid item lg={4} style={{ marginTop: '10px' }}>
                                  <Typography variant="h6">
                                      {moment(item.created_at).format("dddd MMMM D, YYYY")}
                                  </Typography>
                                  <Typography variant="subtitle1">
                                      {moment(item.created_at).format("hh:mm A")}
                                  </Typography>
                                  <Typography
                                      sx={{ fontWeight: 'bold' }}    
                                      variant="body1"
                                      dangerouslySetInnerHTML={{ __html: item.activity }} 
                                  />
                              </Grid>
                              <Divider sx={{ height: 100, m: '10px', borderColor: 'blue' }} orientation="vertical" />
                              <Grid item lg={7}>
                                  <Typography   onClick={() => item?.booking_id && handleAppointmentDetail(item?.booking_id)} sx={{ marginTop: '15px' }} variant="body1"
                                      dangerouslySetInnerHTML={{ __html: item.description }}
                                  />   
                                  <Box sx={{ display: "flex" }}>
                                      <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                          Customer Name:

                                      </Typography> 
                                      <Typography variant="body2" sx={{ ml: 1 , cursor:"pointer", color:"#09ABF1",textDecoration: "underline" }} onClick={ () =>handleCellClick(item?.user_name)}>
                                          {item?.user_name}
                                      </Typography> 
         </Box>    
                                  {item?.booking_id ? (
                                      <>
                                          <Box sx={{ display: "flex" }}>
                                              <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                                  Date:
                                              </Typography>
                                              <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                                                  {item?.date}
                                              </Typography>
                                          </Box>   
                                          <Box sx={{ display: "flex" }}>
                                              <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                                  Time:

                                              </Typography>  

                                              <Typography variant="body2" color="initial" sx={{ ml: 1}}>
                                                  {item?.time}
                                              </Typography>     
                                          </Box>  
                                          <Box sx={{ display: "flex" }}>
                                              <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                                  Service Name:
                                              </Typography>
                                              <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                                                  {item?.service_name}
                                              </Typography>
                                          </Box>

                                      </>
                                  ) : null}
                                  {item?.note ? (
                                      <>
                                          <Box sx={{ display: "flex" }}>
                                              <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                                  Note:
                                              </Typography>
                                              <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                                                  {item?.note}
                                              </Typography>
                                          </Box>
                                      </>
                                  ) : null}
                              </Grid> 
                          </Grid>
                      </Box>
                  </TimelineContent>
              </TimelineItem>
          ))
      )}
  </Timeline>
  <CustomModal
        customWidth={"1000px"}
        open={appointmentDetails !== null}
        setOpen={() => setAppointmentDetail(null)} 
    >
      <Box>
        <AppointmentDetails appointmentDetails={appointmentDetails}/>
      </Box>
    </CustomModal>
</Box>   
);
}
export default ActiviyLog;