import React, {useEffect, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar'
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Chip from '@mui/material/Chip'
import Badge from '@mui/material/Badge'
import Drawer from '@mui/material/Drawer'
import MuiAvatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import InputAdornment from '@mui/material/InputAdornment'
import CloseIcon from '@mui/icons-material/Close';
import {Avatar} from "@mui/material";
// import { getAllCRMCustomer } from '../../store/crm/crm';
import { useSelector } from 'react-redux';
import { getUserDetails } from '../../../store/crm/chat-module';
// import { selectChat } from '../../store/crm/chat';
// import { GetMessages, ReadMessages } from '../../store/crm/chat-module';


const ScrollWrapper = ({ children, hidden }) => {
    if (hidden) {
        return <Box sx={{ height: '100%', overflow: 'auto' }}>{children}</Box>;
    } else {
        return <PerfectScrollbar options={{ wheelPropagation: false }}>{children}</PerfectScrollbar>;
    }
};

const SidebarLeft = (props) => {
    const {
        store,
        hidden,
        mdAbove,
        dispatch,
        statusObj,
        userStatus,
        selectChat,
        getInitials,
        sidebarWidth,
        setUserStatus,
        leftSidebarOpen,
        removeSelectedChat,
        formatDateToMonthShort,
        handleLeftSidebarToggle,
    } = props;

    const [active, setActive] = useState(null);
    const crmStore = useSelector((state) => state?.crmStore);

    const handleChatClick = (id) => {
        console.log("Selected chat ID:", id);
        dispatch(getUserDetails());
        setActive(id);

        if (!mdAbove) {
            handleLeftSidebarToggle();
        }
    };

    useEffect(() => {
        return () => {
            setActive(null);
            dispatch(removeSelectedChat());
        };
    }, [dispatch]);

    const renderChat = () => {
        const chat = store.userProfile; // Since now it's a single chat object
console.log("chat",store.userProfile)
        if (chat && chat.id) {
            const activeCondition = active !== null && active === chat.user_id;

            return (
                <ListItem disablePadding sx={{ '&:not(:last-child)': { mb: 1.5 } }}>
                    <ListItemButton
                        disableRipple
                        onClick={() => handleChatClick(chat.id)}
                        sx={{
                            px: 2.5,
                            py: 1,
                            width: '100%',
                            borderRadius: 1,
                            alignItems: 'flex-start',
                            backgroundColor: theme => (activeCondition ? `${theme.palette.primary.main} !important` : ''),
                        }}
                    >
                        <ListItemAvatar sx={{ m: 0 }}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent={
                                    <Box
                                        component="span"
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: `${statusObj[chat.status]}.main`,
                                            boxShadow: theme =>
                                                `0 0 0 2px ${
                                                    !activeCondition ? theme.palette.background.paper : theme.palette.common.white
                                                }`,
                                        }}
                                    />
                                }
                            >
                                {chat.profile_image ? (
                                    <MuiAvatar
                                        src={chat.profile_image}
                                        alt={chat.name}
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            border: theme => (activeCondition ? `2px solid ${theme.palette.common.white}` : ''),
                                        }}
                                    />
                                ) : (
                                    <Avatar
                                        color={chat.avatarColor}
                                        skin={activeCondition ? 'light-static' : 'light'}
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            fontSize: '1rem',
                                            border: theme => (activeCondition ? `2px solid ${theme.palette.common.white}` : ''),
                                        }}
                                    >
                                        {getInitials(chat.name)}
                                    </Avatar>
                                )}
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{
                                my: 0,
                                ml: 4,
                                mr: 1.5,
                                '& .MuiTypography-root': { ...(activeCondition ? { color: 'common.white' } : {}) },
                            }}
                            primary={
                                <Typography noWrap sx={{ ...(!activeCondition ? { color: 'text.secondary' } : {}) }}>
                                    {chat.name}
                                </Typography>
                            }
                            secondary={
                                <Typography
                                    noWrap
                                    variant="body2"
                                    sx={{ color: !activeCondition ? theme => theme.palette.text.disabled : {} }}
                                >
                                    {chat.lastMessage ? chat.lastMessage.message : 'No messages yet'}
                                </Typography>
                            }
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <Typography sx={{ whiteSpace: 'nowrap', color: activeCondition ? 'common.white' : 'text.disabled' }}>
                                {chat.lastMessage
                                    ? formatDateToMonthShort(chat.lastMessage.time, true)
                                    : new Date().toLocaleDateString()}
                            </Typography>
                            {chat.unread_count && chat.unread_count > 0 ? (
                                <Chip
                                    color="error"
                                    label={chat.unread_count}
                                    sx={{
                                        mt: 0.5,
                                        height: 18,
                                        fontWeight: 600,
                                        fontSize: '0.75rem',
                                        '& .MuiChip-label': { pt: 0.25, px: 1.655 },
                                    }}
                                />
                            ) : null}
                        </Box>
                    </ListItemButton>
                </ListItem>
            );
        } else {
            return (
                <ListItem>
                    <Typography sx={{ color: 'text.secondary' }}>No Chat Found</Typography>
                </ListItem>
            );
        }
    };

    return (
        <Box>
            <Drawer
                open={leftSidebarOpen}
                onClose={handleLeftSidebarToggle}
                variant={mdAbove ? 'permanent' : 'temporary'}
                ModalProps={{
                    disablePortal: true,
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    zIndex: 7,
                    height: '100%',
                    display: 'block',
                    position: mdAbove ? 'static' : 'absolute',
                    '& .MuiDrawer-paper': {
                        boxShadow: 'none',
                        overflow: 'hidden',
                        width: sidebarWidth,
                        position: mdAbove ? 'static' : 'absolute',
                        borderTopLeftRadius: theme => theme.shape.borderRadius,
                        borderBottomLeftRadius: theme => theme.shape.borderRadius,
                    },
                    '& > .MuiBackdrop-root': {
                        borderRadius: 1,
                        position: 'absolute',
                        zIndex: theme => theme.zIndex.drawer - 1,
                    },
                }}
            >
                <Box sx={{ height: `calc(100% - 4.0625rem)` }}>
                    <ScrollWrapper hidden={hidden}>
                        <Box sx={{ p: 3 }}>
                            <Typography variant="h6" sx={{ ml: 2, mb: 3, color: 'primary.main' }}>
                                Chats
                            </Typography>
                            <List sx={{ mb: 3, p: 0 }}>{renderChat()}</List>
                        </Box>
                    </ScrollWrapper>
                </Box>
            </Drawer>
        </Box>
    );
};

export default SidebarLeft;