import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import ConfigurationLayout from "../configurationLayout";
import Loader from "../../../components/loader/loader";
import FormInput, {labelStyle} from "../../../components/form/formInput";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import * as yup from "yup";
import {useFormik} from "formik";
import {getPermissionByID, storePermission, updatePermission} from "../../../store/permissions";
import FromDropdown from "../../../components/form/fromDropdown";
import {getAllMenus} from "../../../store/menus";
import SubmitButton from "../../../components/custom-button/submitButton";
import {useParams} from "react-router-dom";
import CustomButton from "../../../components/custom-button/customButton";

const AddPermissions = () => {
    const [loading,setLoading] =useState(false)
    const dispatch =useDispatch()
    const navigate =useNavigate()
    const params =useParams()

    const allMenu = useSelector(state => state?.menuStore?.menus)

    const validationSchema = yup.object({
        name: yup.string().min(3,"Name is Too Short").required('Role name is required'),
        status: yup.string().required('Status is required'),
        menu_id: yup.number().typeError('Menu is Required').required('Menu is Required'),
    });




    const {values,handleSubmit,setFieldValue,handleChange,touched,errors} = useFormik({
        initialValues: {
            name: '',
            status: 'In-Active',
            menu_id: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data,{setFieldTouched,setFieldError}) => {
            setLoading(true)

            let res
            if (params?.permission_id){
                res = await dispatch(updatePermission(data))
            }else {
                res = await dispatch(storePermission(data))
            }

            if (res?.payload?.errors){
                const {errors} = res?.payload
                console.log(errors)
                if (errors?.name){
                    setFieldTouched("name",true)
                    setFieldError("name",errors?.name)
                }

            }else if (res?.payload?.success ==="true"){
                if (params?.permission_id){
                    toast.success("Permission Updated Successfully" ,{duration:3000})
                }else {
                    toast.success("Permission Stored Successfully" ,{duration:3000})
                }
                navigate('/admin/configuration/permissions')
            }

            setLoading(false)
        },
    });

    useEffect(() => {
        (async () => {
            setLoading(true)
            await dispatch(getAllMenus())
            setLoading(false)
        })()
    }, [dispatch])

    useEffect(()=>{
        (async  ()=>{
            if (params?.permission_id){
                setLoading(true)
                let res =await  dispatch(getPermissionByID(params?.permission_id))
                if (res?.payload?.data?.id){
                    await setFieldValue("name", res?.payload?.data?.name)
                    await setFieldValue('status', res?.payload?.data?.status || 'In-Active')
                    await setFieldValue('menu_id', res?.payload?.data?.menu_id || '')
                    await setFieldValue('id', res?.payload?.data?.id)
                }
                setLoading(false)
            }

        })()
    },[params?.permission_id])
    return (
        <ConfigurationLayout>
            <Box sx={{height:"calc(100vh - 125px)",  backgroundColor:"white"}}>
                {loading ? <Loader/> :
                    <Grid container p={3}>
                        <Grid item xs={12} sm={6} lg={6}>
                            <form onSubmit={handleSubmit}>
                                <FormInput
                                    name={"name"}
                                    label={"Name"}
                                    placeholder={'Type  role name'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />

                                <FormControl sx={{mb: '16px'}}>
                                    <FormLabel sx={labelStyle}>Status</FormLabel>
                                    <RadioGroup
                                        row
                                        name="status"
                                        value={values?.status}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="In-Active" control={<Radio/>} label="InActive"/>
                                        <FormControlLabel value="Active" control={<Radio/>} label="Active"/>
                                    </RadioGroup>
                                </FormControl>
                                <FromDropdown
                                    name={"menu_id"}
                                    label={"Select Menu"}
                                    errors={errors}
                                    options={allMenu}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                                <Box sx={{display:'flex',alignItems:'center'}}>
                                    <Box sx={{mr:'16px'}}>
                                        <CustomButton
                                            mainColor={'#ED1E45'}
                                            val={'Cancel'}
                                            handleClick={()=>{navigate('/admin/configuration/permissions')}}
                                        />
                                    </Box>
                                <SubmitButton
                                    val={params?.permission_id ?"Update Permission" :"Add Permission"}
                                />
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                }
            </Box>
        </ConfigurationLayout>
    );
};

export default AddPermissions;