import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    main:{
      
        marginTop:'25px'
    },
    roundedName:{
        borderRadius:'50%',
        background:'#1565c0',
        width:'50px',
        height:'50px',
        color:'white',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginRight:'10px',
        fontFamily:'helvetica-lt-std-bold'

    },
    buttonStyle:{
        width: "100%",
        height: "40px",
        fontSize: "20px",
        padding: "5px",
        textTransform: "capitalize",
        marginTop:'15px'
    },
    grayColor:{
        color:'#B0A4A4',

    },
    blueColor:{
        color:'#1565c0',
        marginTop:'5px',
        fontFamily: "helvetica-lt-std-bold",
        cursor:'pointer'
    },
    card:{
        boxShadow:'none',
        border:'0.8px solid #C4C4C4',
    },
    

    
    search: {
        '& .MuiOutlinedInput-root': {
          borderRadius:'20px',
          height:'35px',
        },
        '& .MuiInputLabel-root.Mui-focused': {  // Corrected this line
          marginTop: '0px'
        }
      },
      selectParent: {
        '& .MuiOutlinedInput-root': {
          borderRadius: '20px',
          height: '35px',
        },
        '& .MuiInputBase-input': {
          marginTop: '-12px',
          padding: '3px 4px 7px 15px'
        },
        '& .MuiInputLabel-root.Mui-focused': {  // Corrected this line
          marginTop: '0px'
        }
      },
     
      
        mainDiv: {
          padding: '0 8px',  // Adjust as per your requirement. This gives 10px to the left and right.
          // ... any other styles for the div if you have them
        },
      welcomeText: {
          background:'rgba(230, 218, 209, 1)',
          padding:'15px',
          borderRadius:'8px',
          marginTop:'15px'
      },


}));
export default useStyles;
