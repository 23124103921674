import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment/moment";
import {
    convertTimeToMinutes, generateYearsToday,
    getEndTime,
    getTimeSlotsArray, monthsArray,
    MySwal,
    successConfirmation
} from "../../../../services/utills";
import {MenuProps} from "../../calender/crmCalender";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {dateStyle,boxStyle,disableStyle} from "../../appointment/add-appointment/appointmentPlanSessionTime";

const PlanInvoiceTime = (
    {
        selectedServices,
        getAppointmentHoliday,
        selectedTime,
        selectedAllPlanTime,
        setSelectedTime,
        selectedStaff,
        getAppointmentSchedule,
        selectedFinalDate,
        activeSession,
        executeScroll,
    }) => {
    let currentData = new Date()

    const [startDate, setStartDate] = useState(currentData)
    const isCurrentMonth = (currentData.getMonth() === startDate.getMonth()) && (currentData.getFullYear() === startDate.getFullYear())
    const monthStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const monthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    const daysInMonth = monthEnd.getDate();
    const firstDayOfWeek = monthStart.getDay();
    const lastDayOfWeek = monthEnd.getDay();

    const bookAppointmentStore = useSelector(state => state?.bookAppointmentStore)
    const {appointmentHolidays = [], appointmentSchedule = []} = bookAppointmentStore



    const weeks = [];
    let days = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
        days.push(
            <TableCell key={`empty-${i}`} sx={{p: "0"}}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }


    for (let i = 1; i <= daysInMonth; i++) {
        const isExist = appointmentHolidays?.length > 0 && appointmentHolidays.some((item) => parseFloat(item) === parseFloat(i))

        days.push(
            <TableCell key={i} sx={{p: "0"}}>
                <Box
                    sx={{
                        ...dateStyle,
                        cursor: ( isExist) ? 'default' : 'pointer',
                        color: ( isExist) ? "#8D8D8D" : i === selectedFinalDate?.day && (startDate.getMonth() + 1) === selectedFinalDate?.month && startDate.getFullYear() === selectedFinalDate?.year ? '#006AFF' : '#000',
                        pointerEvents: (isExist) ? "none!important" : 'auto',
                        textDecoration: (isExist) ? "line-through" : 'none',
                    }}
                    onClick={() => {
                        const date = moment(new Date(startDate.getFullYear(), startDate.getMonth(), i)).format('YYYY-MM-DD')
                        getAppointmentSchedule(date,activeSession)
                    }}
                >
                    {i}
                </Box>
            </TableCell>
        );

        if ((i + firstDayOfWeek) % 7 === 0 || i === daysInMonth) {
            weeks.push(<TableRow key={`week-${i}`}>{days}</TableRow>);
            days = [];
        }
    }
    for (let i = (lastDayOfWeek + 1) % 7; i < 6; i++) {
        days.push(
            <TableCell key={`empty-${i}`} sx={{p: "0"}}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }
    const isDisabled = (month) => {
        const today = new Date();
        const year = today.getFullYear();
        const currentMonth = today.getMonth();
        const currentYear = startDate.getFullYear();
        if (year === currentYear) {
            return month < currentMonth;
        } else {
            return false
        }
    }

    const handleSelectedTime = async (slotsArray,startTime,stepId) => {
        let filteredDate = selectedAllPlanTime.filter((item)=>item?.stepId !== stepId)

        let totalTime = 0
        selectedServices?.forEach((item) => {
            let time = convertTimeToMinutes(item?.time_duration)
            totalTime = totalTime + time
        })

        let endTime = getEndTime(startTime,totalTime || 0)
        let getArraySlots = getTimeSlotsArray(startTime,endTime,slotsArray)
        let lastIndex = getArraySlots[getArraySlots?.length - 1]


        if (appointmentSchedule?.slots.includes(lastIndex)){
            let data ={}
            selectedAllPlanTime.forEach((item)=>{
                if (item?.stepId === stepId){
                    data['stepId'] = item?.stepId
                    data['date'] = item?.date
                    data['start_time'] =startTime
                    data['end_time'] = endTime
                    data['slots'] = getArraySlots
                }
            })

            setSelectedTime([data,...filteredDate])

            executeScroll();
        }else {
            let data ={}
            selectedAllPlanTime.forEach((item)=>{
                if (item?.stepId === stepId){
                    data['stepId'] = item?.stepId
                    data['date'] = item?.date
                }
            })
            await MySwal.fire({
                // position: 'top-end',
                icon: 'error',
                // text: 'Something went wrong!',
                title: 'Invalid Time Slots.',
                showConfirmButton: true,
                timer: 5000,
            })
            setSelectedTime([...filteredDate])
        }

    }

    const isTimeSelected = (array ,stepId) => {
        return array?.some((item) => item?.stepId === stepId && item?.date)
    }

    return (
        <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Grid item xs={12}>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            p: '12px',
                            justifyContent: 'space-between'
                        }}>
                        <Box sx={{display: 'flex', gap: '10px'}}>
                            <FormControl sx={{minWidth: 90}}>
                                <Select
                                    size={"small"}
                                    value={startDate?.getMonth()}
                                    MenuProps={MenuProps}
                                    onChange={(e) => {
                                        const end = new Date(startDate);
                                        end.setMonth(e?.target?.value);
                                        setStartDate(end);
                                        getAppointmentHoliday(end, selectedStaff)
                                    }}
                                    sx={{
                                        background: '#EDF2F7',
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: "0!important"
                                        },
                                        "svg": {
                                            fontSize: '34px',
                                        }
                                    }}
                                >
                                    {monthsArray?.length > 0 && monthsArray?.map((item, index) => (
                                        <MenuItem
                                            // disabled={isDisabled(item?.key)}
                                            value={item?.key}
                                            key={index}
                                        >
                                            {item?.name}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl sx={{minWidth: 90}}>
                                <Select
                                    size={"small"}
                                    value={startDate?.getFullYear()}
                                    MenuProps={MenuProps}
                                    onChange={(e) => {
                                        const end = new Date(startDate);
                                        end.setFullYear(e?.target?.value);
                                        setStartDate(end);
                                        getAppointmentHoliday(end, selectedStaff)
                                    }}
                                    sx={{
                                        background: '#EDF2F7',
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: "0!important"
                                        },
                                        "svg": {
                                            fontSize: '34px',
                                        }
                                    }}
                                >
                                    {generateYearsToday(10)?.length > 0 &&
                                        generateYearsToday(10)?.map((item, index) => (
                                            <MenuItem value={item} key={index}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{display: 'flex', gap: '10px'}}>
                            <Box sx={ {...boxStyle}} onClick={() => {
                                const end = new Date(startDate);
                                end.setMonth(end.getMonth() - 1);
                                setStartDate(end);
                                getAppointmentHoliday(end, selectedStaff)
                            }}>
                                <KeyboardArrowLeftIcon sx={{fontSize: '32px'}}/>
                            </Box>
                            <Box sx={boxStyle} onClick={() => {
                                const end = new Date(startDate);
                                end.setMonth(end.getMonth() + 1);
                                setStartDate(end);
                                getAppointmentHoliday(end, selectedStaff)
                            }}>
                                <KeyboardArrowRightIcon sx={{fontSize: '32px'}}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Table aria-label="simple table" sx={{
                            boxShadow: 'none', "td , th": {
                                border: 'none',
                                textAlign: 'center',
                            },
                            "th": {
                                fontWeight: 'bold'
                            },
                            "td": {
                                py: "4px"
                            }
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Su</TableCell>
                                    <TableCell>Mo</TableCell>
                                    <TableCell>Tu</TableCell>
                                    <TableCell>We</TableCell>
                                    <TableCell>Th</TableCell>
                                    <TableCell>Fr</TableCell>
                                    <TableCell>Sa</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {weeks}
                                <TableRow sx={{
                                    "td": {
                                        py: "5px!important"
                                    }
                                }}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default PlanInvoiceTime;