import React, {useEffect, useState} from 'react';
import {FormControlLabel, Grid, Switch, Typography} from "@mui/material"; 
import SubmitButton from "../../../../components/custom-button/submitButton";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {useFormik} from "formik";
import FormInput from "../../../../components/form/formInput";
import {storeClinicSocialInfo} from "../../../../store/clinics/clinicSettings/social-info";
import Loader from "../../../../components/loader/loader";

const SocialInfo = () => {
    const [loading,setLoading] = useState(false)
    const [emailAllowedSwitch, setEmailAllowedSwitch] = useState(false);  
    const [smsAllowedSwitch, setSmsAllowedSwitch] = useState(false); 
    const  [pushAllowed,setPushAllowedSwitch] = useState(false); 
    const dispatch = useDispatch();

    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)
    const handleEmailAllowedSwitchChange = (event, checked) => {
        setFieldValue('emailAllowed', checked);
      };

      const handleSmsAllowedSwitchChange = (event, checked) => {
        setFieldValue('smsAllowed', checked);
      };
      const handlePushAllowedSwitchChange = (event, checked) => {
        setFieldValue('pushAllowed', checked);
      };


    const validationSchema = Yup.object({
        linkedIn: Yup.string().required("LinkedIn is Required"),
        twitter: Yup.string().required("Twitter is Required"),
        instagram: Yup.string().required("Instagram is Required"),
        facebook: Yup.string().required("Facebook is Required"),
    });
    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            facebook: '',
            instagram: '',
            linkedIn: '',
            twitter: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, { setFieldTouched, setFieldError }) => {
            if (clinicData?.id){
                setLoading(true)
                let res = await  dispatch(storeClinicSocialInfo({id:clinicData?.id,  facebook: data.facebook,
                    instagram: data.instagram,
                    linkedIn: data.linkedIn,
                    twitter: data.twitter,
                    is_email_allowed: values.emailAllowed ? 1 : 0,
                    is_sms_allowed:values.smsAllowed ? 1 : 0,
                    is_push_allowed:values.pushAllowed ? 1 : 0}))
                if (res?.payload){
                    console.log(res?.payload)
                }
                setLoading(false)
console.log ("respons",res?.payload)
            }  

        },
    });
    useEffect(()=>{
        if (clinicData?.id){
            setFieldValue('instagram',clinicData?.instagram || '')
            setFieldValue('facebook',clinicData?.facebook || '')
            setFieldValue('twitter',clinicData?.twitter || '')
            setFieldValue('linkedIn',clinicData?.linkedIn || "")
            // setSmsAllowed(clinicData?.is_sms_allowed)
            setFieldValue('smsAllowed', clinicData?.is_sms_allowed )
            setFieldValue('pushAllowed', clinicData?.is_push_allowed )
            setFieldValue('emailAllowed', clinicData?.is_email_allowed )

        }
    },[clinicData,clinicData?.id])
    console.log("data",clinicData)

    return (
        loading ? <Loader/> :
        <form onSubmit={handleSubmit}>
            <Grid container p={'20px'} sx={{justifyContent:'space-between'}}>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"instagram"}
                        label={"Instagram"}
                        placeholder={'Type  your instagram'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"facebook"}
                        label={"Facebook"}
                        placeholder={'Type  your facebook'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"twitter"}
                        label={"Twitter"}
                        placeholder={'Type  your twitter'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"linkedIn"}
                        label={"LinkedIn"}
                        placeholder={'Type  your linkedIn'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography style={{fontSize:'20px',fontWeight:"400"}}>Update Setting info</Typography>
                   
                </Grid>
 <Grid item xs={12} lg={5.5} style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
    <Typography>Email Allowed</Typography>
    <Switch
    checked={values.emailAllowed}
    onChange={(event) => handleEmailAllowedSwitchChange(event, event.target.checked)}
  />

</Grid>
<Grid item xs={12} lg={5.5}>

</Grid>
<Grid item xs={12}lg={5.5} style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
    <Typography>Sms Allowed</Typography>
    <Switch  checked={values.smsAllowed}
    onChange={(event) => handleSmsAllowedSwitchChange(event, event.target.checked)} />  
</Grid>
<Grid item xs={12} lg={5.5}>

</Grid>
<Grid item xs={12} lg={5.5} style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
    <Typography>Push Allowed</Typography>
    <Switch checked={values.pushAllowed}
    onChange={(event) => handlePushAllowedSwitchChange(event, event.target.checked)} />     
</Grid>
                <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end'}}>
                    <SubmitButton val={"Update Social Info"}/>
                </Grid>
            </Grid>
        </form>
    );
};

export default SocialInfo;