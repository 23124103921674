import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { apiHeaders } from "../../../../lib/apiHeader";
import apis from "../../../../lib/apis/apis";
import { getClinicByID } from "../../index";

export const storeTwilioConfig = createAsyncThunk(
  "storeTwilioConfig",
  async (data, { dispatch }) => {
    try {
      const response = await axios.post(
        apis.clinics_twilio_configuration,
        { ...data },
        {
          headers: apiHeaders(),
        }
      );

      if (response?.data?.errors) {
        return response?.data;
      } else if (response?.data?.success === "true") {
        await dispatch(getClinicByID(data?.provider_id))
        toast.success(`Twilio Settings Updated`, { duration: 3000 });
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        toast.error(err?.message, { duration: 4000 });
      }
    }
  }
);

export const clinicTwilioConfig = createSlice({
  name: "clinicTwilioConfig",
  initialState: {
    servicesTypes: [],
  },
  reducers: {},
  extraReducers: (builder) => {},
});

export default clinicTwilioConfig.reducer;
