import React, {useEffect, useState} from 'react';
import moment from "moment";

const TimeCounter = ({initialMilliseconds}) => {
    const [elapsedTime, setElapsedTime] = useState(initialMilliseconds || 0);


    useEffect(()=>{
        setElapsedTime(initialMilliseconds)
    },[initialMilliseconds])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setElapsedTime((prevElapsedTime) => prevElapsedTime + 1000);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    function formatTime(ms) {
        const duration = moment.duration(ms);
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        const formattedHours = hours < 10 ? '0' + hours : hours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    return (
        <div>
            <div>{formatTime(elapsedTime)}</div>
        </div>
    );
};

export default TimeCounter;