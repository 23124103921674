import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {apiHeaders} from "../../../lib/apiHeader";
import apis from "../../../lib/apis/apis";
import toast from "react-hot-toast";

export const getUserTreatmentplan = createAsyncThunk('getUserTreatmentplan', async (data) => {
    try {
        const response = await axios.get(apis.treatment_plan+`${data && data!=='All' ?`?status=${data}`:""}`,{
            headers: apiHeaders()
        })

        if (response?.data?.success === 'true'){
            return  response?.data?.data
        }else {
            return []
        }



    }catch (err){

        console.log(err?.response)
        return []
    }
})

export const updateTreatmentPlan = createAsyncThunk('updateTreatmentPlan', async (data) => {
    return data
})
export const updatePlanFilters = createAsyncThunk('updateCrmFilters', async (data) => {
    return data
})
export const userTreatmentPlantSlice = createSlice({
    name: 'userTreatmentPlantSlice',
    initialState: {
        userPlan: [],
        pageLimit:10,
        filterBy:'All',
        currentPage:1,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getUserTreatmentplan.fulfilled, (state, action) => {
            state.userPlan = action.payload || []
        })
    }
})


export default userTreatmentPlantSlice.reducer