import React from "react";
import "./styles.scss";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

export const inputStyle = {
  background: "#EDF2F7",
  borderRadius: "4px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important"
  },
  ".MuiInputBase-input": {
    borderColor: " #EDF2F7",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#EDF2F7",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#EDF2F7",
      outline: "none",
    },
  },
};
export const labelStyle = {
  fontSize: "16px",
  color: "#27272e",
  letterSpacing: ".3px",
};

const countryCodes = [
  {
    code: "+92",
    name: "PK",
  },
  {
    code: "+1",
    name: "CA",
  },
  // add more country codes as needed
];

const FormInputPhone = ({
  name,
  label,
  placeholder,
  values,
  errors,
  touched,
  handleChange,
}) => {
  const handleCountryCodeChange = (event) => {
    const newValues = { ...values };
    const newCountryCode = event.target.value;
    if (newCountryCode !== newValues.countryCode) {
      newValues.countryCode = newCountryCode;
      handleChange({
        target: { name: "countryCode", value: newCountryCode },
      });
       handleChange({
        target: { name: [name], value: "" },
      });

    }
  };

  const handleMobileChange = (event) => {
    const {value} = event?.target
    handleChange({ target: { name: [name], value: value } });
  };

  return (
    <FormControl fullWidth sx={{ mb: "16px" }}>
      <FormLabel
        sx={{ fontSize: "16px", color: "#27272e", letterSpacing: ".3px" }}
      >
        {label}
      </FormLabel>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <Select
          value={values.countryCode}
          onChange={handleCountryCodeChange}
          name="countryCode"
          sx={{ ...inputStyle, marginRight: "3px", height: "44px" }}
        >
          {countryCodes.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {country.name} ({country.code})
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          variant="outlined"
          name={name}
          sx={inputStyle}
          placeholder={placeholder}
          value={values[name]}
          onChange={handleMobileChange}
          error={touched[name] && Boolean(errors[name])}
        />
      </div>
      {errors[name] && touched[name] && (
        <FormHelperText sx={{ color: "#CB3838", width: "max-content", mx: 0 }}>
          {errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormInputPhone;
