import React from 'react';
import {Card, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
const StatisticCard = ({title,color,value,isAmount,onClick}) => {
    return (
        <Card  sx={{borderRadius:'10px',height:'100%',display:'flex',flexDirection:'column',justifyContent:'space-between',cursor:onClick?'pointer':''}}>
            <CardHeader
                title={title}
                titleTypographyProps={{ sx: { lineHeight: '2rem !important', color:'#6B7280',letterSpacing: '0.15px !important' } }}
                // action={
                //     <IconButton size='small' aria-label='settings' className='card-more-options'>
                //         <MoreVertIcon />
                //     </IconButton>
                // }
            />
            <CardContent>
                <Typography sx={{fontSize:'44px', color:color,textAlign:"center",  '@media (min-width:1200px) and (max-width: 1330px) ': {
       fontSize:"35px",
    }}}>
                    {isAmount && "$"}{value}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default StatisticCard;