import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router";
import Hidden from "@mui/material/Hidden";
import { ListItemIcon } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";


import {getClinicByID} from "../../store/clinics/index";
import { Collapse } from "@mui/material";
import {useLocation, useParams} from "react-router-dom";
import {
    clinicsRoutes,
    clinicConfigurationRoutes, clinicsAddEdit, clinicCrmConfigurationRoutes, clinicsCrmRoutes, clinicsCrmAddEdit,
} from "../../routes/clinicsRoutes";
import { HiOutlineInbox } from "react-icons/hi";
import Slide from "react-reveal/Slide";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

const ConfigurationDrawer = ({ clinicId }) => {  
  const [mobileOpen, setMobileOpen] = React.useState(false);




  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleListItemClick = () => {  
    handleDrawerToggle();
  };
  const [open, setOpen] =useState(0);
    const [loading, setLoading] =useState(false);

    const navigate = useNavigate();
    const params = useParams()
    const dispatch = useDispatch()
    const location = useLocation();
    const {pathname} = location;


    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)
    const user = useSelector((state) => state?.authStore?.user);

    const clinicsFinalRoutes = user?.role_id === 2   ?  clinicsCrmRoutes  : clinicsRoutes
    const clinicFinalConfigurationRoutes = user?.role_id === 2   ?  clinicCrmConfigurationRoutes  : clinicConfigurationRoutes
    const clinicsFinalAddEdit = user?.role_id === 2   ?  clinicsCrmAddEdit  : clinicsAddEdit


    useEffect(() => {
        clinicFinalConfigurationRoutes.map((item) => {
            if (pathname.includes(item?.active)) {
                setOpen(1);
            }
        });
    }, [pathname]);

    const handleButtonClick = (val) => {
        navigate(val);
    };
    const handleClick = (val) => {
        setOpen(val === open ? 0 : val);
    };

    useEffect(()=>{
       if (clinicData?.id){
           setLoading(false)
       }else {
           setLoading(true)
           if (params?.clinic_id && !clinicData?.id){
               dispatch(getClinicByID(params?.clinic_id))
           }
       }

    },[clinicData,clinicData?.id])

  return (
    <>
      <Hidden mdUp implementation="css">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          
            // border: '1px solid #000', 
            borderRadius: '4px', 
            padding: '4px', 
            width:"61px"    
          }}
          variant="outlined"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
      
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <Box
          sx={{
            display: 'block',
            maxHeight: "100vh",
            minHeight: '50vh',
            overflowY: "auto",
            background: '#fff',
            width: '250px',
            p: '0',
            borderRadius: '7px',
          }}
        >
           <Box>
           <List
                        sx={{
                            width: "100%",
                            px: "0px",
                            ".MuiListItemIcon-root ": {
                                minWidth: "37px",
                            },
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton
                            onClick={() => handleClick(1)}
                            sx={{
                                borderBottom: "1px solid #dee2e6",
                            }}
                        >
                            <ListItemIcon> <HiOutlineInbox/> </ListItemIcon>
                            <ListItemText primary="Configuration"/>
                            {open === 1 ? <MdExpandLess/> : <MdExpandMore/>}
                        </ListItemButton>
                        <Collapse in={open === 1} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {clinicFinalConfigurationRoutes.map((item, index) => (
                                    <ListItemButton
                                        key={index}
                                        onClick={() => {
                                            handleButtonClick(item?.url + clinicId + item?.active);
                                            handleListItemClick();
                                        }
                                        }
                                        sx={{
                                            // borderRadius:'7px',
                                            pr: "  7px",
                                            pl: "  30px",
                                            borderBottom: "1px solid #dee2e6",
                                            backgroundColor: pathname.includes(item?.active)
                                                ? "#C9EDFC"
                                                : "#fff",
                                            "&:hover": {
                                                backgroundColor: "#C9EDFC",
                                            },
                                        }}
                                    >
                                        <ListItemText primary={item?.name}/>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Collapse>

                        {clinicsFinalRoutes.map((item, index) => (
                            <ListItemButton
                                key={index}
                                onClick={() => {
                                    handleButtonClick(item?.url + clinicId + item?.active);
                                    handleListItemClick();
                                    setOpen(0);
                                }}
                                sx={{
                                    // borderRadius:'7px',
                                    py: "7px",
                                    borderBottom: "1px solid #dee2e6",
                                    backgroundColor: pathname.includes(item?.active)
                                        ? "#C9EDFC"
                                        : "#fff",
                                    "&:hover": {
                                        backgroundColor: "#C9EDFC",
                                    },
                                }}
                            >
                                <ListItemText primary={item?.name}/>
                            </ListItemButton>
                        ))}
                    </List>
          </Box>
        </Box>
      </Drawer>
      </Hidden>
    </>
  );
};

export default ConfigurationDrawer;
