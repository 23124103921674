import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import Loader from "../../../../components/loader/loader";
import {FormControl, Grid, Typography} from "@mui/material";
import SimpleInput from "../../../../components/form/simpleInput";
import CustomButton from "../../../../components/custom-button/customButton";
import CustomTable from "../../../../components/table";
import {getAllClinicsRooms, getRooms, updateroom} from "../../../../store/clinics/clinicSettings/clinic-rooms";
import {useParams} from "react-router-dom";
import BackendPagination from '../../../../components/pagination/backendPagination';
import Waveloading from '../../../loading/Waveloading';
const Rooms = () => {
    const [loading, setLoading] = useState(false);
    const [search,setSearch] = useState('')

    const params =useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clinicRoomsStore = useSelector(state => state?.clinicRoomsStore)
    const user = useSelector(state => state?.authStore?.user)
    const {currentPage} = clinicRoomsStore


    useEffect(()=>{
        (async ()=>{
            if (!clinicRoomsStore?.rooms?.length){
                setLoading(true)
            }
            await dispatch(getRooms(params?.clinic_id))
            setLoading(false)
        })()
    },[dispatch,clinicRoomsStore?.rooms?.length,params?.clinic_id])
    const refreshData = async () => {
        setLoading(true);
        try {
            await dispatch(getRooms(params?.clinic_id))
           
        } catch (error) {
            console.error('Error fetching data:', error);
           
        }
        setLoading(false); 
    };
    


    const roomHead= [
        {value:"Name",key:'name'},  
        {value:"Room No",key:'room_no'},
        {value:"Status",key:'status'},
        {value:"Action",key:'action'}  
    ]

    const handleEdit = (row, isEdit) => {
        if (isEdit) {
            navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/edit-room/${row?.id}`)
        }
    }  

    const handleClick =()=>{
        if (params?.clinic_id){
            navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/add-room`)
        }
    }
    const fetchPageData = async(page) => {
        await dispatch(updateroom({currentPage:page}))
        await refreshData()
    }

    return (
        <Grid container p={'20px'} >
            <Grid item xs={12} sx={{mb:'16px',display:'flex',justifyContent:'flex-end',alignItems:'center',width:'100%',  '@media (max-width: 450px)': {display:"block"},}}>
                <FormControl sx={{mr: "10px"}}>
                    <SimpleInput  
                        search={search}
                        setSearch={setSearch}
                    />
                </FormControl>
                <CustomButton val={'Add Room'} mrt={"10px"} size={'large'} handleClick={handleClick}/>
            </Grid>     
            <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                {loading ? <Waveloading />:
                    clinicRoomsStore?.rooms?.length > 0 ?
                    <CustomTable
                        headRow={roomHead}
                        tableData={clinicRoomsStore?.rooms}
                        search={search}
                        isStatus={true}
                        handleEdit={handleEdit}
                        type={'room'}
                    />
                    :
                        <Typography sx={{textAlign:'center',mt:'30px'}}>
                            No rooms available
                        </Typography>                }

            </Grid>
            <Grid item xs={12} style={{marginTop:"10px"}} >
        {clinicRoomsStore?.rooms?.meta?.last_page > 1 &&
            <BackendPagination
                count={clinicRoomsStore?.rooms?.meta?.last_page}
                currentPage={currentPage} 
                handleOnChange={fetchPageData}
            />
        }
    </Grid>

        </Grid>
    );
};

export default Rooms;