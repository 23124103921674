import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../../lib/apiHeader";
import toast from "react-hot-toast";

/***************************** Crm Reminder **********************************/
export const getCrmReminder = createAsyncThunk("getCrmReminder", async (id, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_all_customer_reminders+id, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data;
            }
            if (response?.data?.success === false) {

                return [];
            }
        } catch (err) {

            return  []
        }
    }
);
export const storeCrmReminder = createAsyncThunk('storeCrmReminder', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_customer_reminder, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.success === 'true'){
            await dispatch(getCrmReminder(data?.customer_id))
        }
        return  response?.data

    }catch (err){
        return err?.response?.data?.errors

    }
})

/***************************** Crm Notes **********************************/

export const getCrmNotes = createAsyncThunk("getCrmNotes", async (id, { dispatch }) => {
        try {
            const response = await axios.get(apis.get_customer_all_notes+id, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data;
            }
            if (response?.data?.success === false) {

                return [];
            }
        } catch (err) {

            return  []
        }
    }
);
export const storeCrmNotes = createAsyncThunk('storeCrmNotes', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_customer_notes, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.success === 'true'){
            await dispatch(getCrmNotes(data?.customer_id))
        }
        return  response?.data

    }catch (err){
        return err?.response?.data?.errors

    }
})

/***************************** Crm Attachment **********************************/


export const getCrmAttachments = createAsyncThunk("getCrmAttachments", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_all_customer_attachment+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const deleteCrmAttachment = createAsyncThunk("deleteCrmAttachment", async (data, { dispatch }) => {
        try {
            const response = await axios.get(apis.delete_customer_attachment+data?.id, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                dispatch(getCrmAttachments(data?.customer_id))
                return response?.data?.data;
            }else {

                return [];
            }
        } catch (err) {

            return  []
        }
    }
);

export const storeCrmAttachment = createAsyncThunk('storeCrmAttachment', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_customer_attachment, data?.data,{
            headers: apiHeadersImage()
        })

        if (response?.data?.success === 'true'){
            await dispatch(getCrmAttachments(data?.customer_id))
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})


/******************* Crm Milestone **********************/
export const getCrmCustomerMilestone = createAsyncThunk("getCrmCustomerMilestone", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_all_customer_milestone+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});
export const addCrmCustomerMileStone = createAsyncThunk("addCrmCustomerMileStone", async (data, { dispatch }) => {
    try {
        const response = await axios.post(apis.store_customer_milestone, {image_id:data?.id
        }, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            dispatch(getCrmCustomerMilestone(data?.customer_id))
            return response?.data?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});

/******************* Gallery **********************/
export const getCrmCustomerGallery = createAsyncThunk("getCrmCustomerGallery", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_all_customer_gallery+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const storeCrmCustomerGallery = createAsyncThunk('storeCrmCustomerGallery', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_customer_gallery, data?.data,{
            headers: apiHeadersImage()
        })

        if (response?.data?.success === 'true'){
            await dispatch(getCrmCustomerGallery(data?.customer_id))
        }
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const deleteCrmCustomerGallery = createAsyncThunk("deleteCrmCustomerGallery", async (data, { dispatch }) => {
    try {
        const response = await axios.get(apis.delete_customer_gallery+data?.id, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});
export const deleteCrmCustomerMilestone = createAsyncThunk("deleteCrmCustomerGallerydeleteCrmCustomerMilestone", async (data, { dispatch }) => {
    try {
        const response = await axios.get(apis.delete_customer_milestone+data?.id, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});
export const deleteCrmCustomerGalleryFile = createAsyncThunk("deleteCrmCustomerGalleryFile", async (data, { dispatch }) => {
    try {
        const response = await axios.get(apis.delete_customer_gallery_file+data?.id, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data;
        }else {
            return [];
        }
    } catch (err) {
        return  []
    }
});



export const crmDetailsSlice = createSlice({
    name: "crmDetailsSlice",
    initialState: {
        reminders:[],
        notes:[],
        attachments:[],
        customerGalleries:[],
        milestones:[],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCrmReminder.fulfilled, (state, action) => {
            state.reminders = action.payload ;
        });
        builder.addCase(getCrmNotes.fulfilled, (state, action) => {
            state.notes = action.payload ;
        });

        builder.addCase(getCrmAttachments.fulfilled, (state, action) => {
            state.attachments = action.payload ;
        });
        builder.addCase(getCrmCustomerGallery.fulfilled, (state, action) => {
            state.customerGalleries = action.payload ;
        });
        builder.addCase(getCrmCustomerMilestone.fulfilled, (state, action) => {
            state.milestones = action.payload ;
        });


    },
});

export default crmDetailsSlice.reducer;