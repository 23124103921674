import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../../lib/apiHeader";


export const getAllClinicsGroupCategory = createAsyncThunk('getAllClinicsGroupCategory', async (provider_id,{dispatch}) => {
    try {

        const response = await axios.get(apis.get_clinics_treatment_group+`${provider_id}?page=1&limit=100`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})



export const storeClinicsGroupCategory = createAsyncThunk('storeClinicsGroupCategory', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_clinics_treatment_group, {...data},{
            headers: apiHeadersImage(),
        })
        if (response?.data?.success==='true') {
            await dispatch(getAllClinicsGroupCategory(data?.provider_id))
        }
            return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const getClinicsGroupCategoryByID = createAsyncThunk('getClinicsTreatmentByGroupID', async (id) => {
    try {
        const response = await axios.get(apis.get_clinics_treatment_group_by_id+id,{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

export const updateClinicsGroupCategory = createAsyncThunk('updateClinicsGroupCategory', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_clinics_treatment_group, {...data},{
            headers: apiHeadersImage()

        })

        if (response?.data?.success==='true'){
            await dispatch(getAllClinicsGroupCategory(data?.provider_id))

            return  response.data
        }else {
            return response?.data
        }
    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){
            return {
                data :err?.response,
                errors:true
            }
        }
    }
})
export const deleteClinicsGroupCategory = createAsyncThunk('deleteClinicsGroupCategory', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_clinics_treatment_group+data?.id,{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllClinicsGroupCategory(data?.provider_id))
            toast.success("Group / Treatment  Deleted Successfully",{duration:3000})

        }else {
        }

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})

export const clinicsGroupCategorySlice = createSlice({
    name: 'clinicsGroupCategorySlice',
    initialState: {
        groupCategory: [],
        meta: {},
        links: {},
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllClinicsGroupCategory.fulfilled, (state, action) => {
            state.groupCategory = action.payload.data
            state.meta = action.payload.meta
            state.links = action.payload.links
        })
    }
})


export default clinicsGroupCategorySlice.reducer