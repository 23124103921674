
import React, {useEffect, useState} from 'react';
import {FormControl, Grid} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import CustomTable from "../../../components/table";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getAllMenus, getGhlWebhook} from "../../../store/menus";
import ConfigurationLayout from "../configurationLayout";
import Loader from "../../../components/loader/loader"; 
import SimpleInput from "../../../components/form/simpleInput";
import TableData from '../../../components/table/TableData';
import AddWebHook from '../../../components/modal/AddWebHook';
import GhlData from '../../../components/table/GhlTabel';

const GHLWebhook = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editModalInitialValues, setEditModalInitialValues] = useState({});
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    
    // const handleEdit = (row, isEdit) => {
    //   if (isEdit) {
    //     setEditModalInitialValues(row)
    //     setIsEditModalOpen(true)
    //   }     
    // }

    const handleClose = () => {
      setIsEditModalOpen(false);
      setIsAddModalOpen(false)  
    }    
     
    const dispatch = useDispatch()  
    const navigate = useNavigate()
  
    const menuStore = useSelector(state => state?.menuStore)    

    useEffect(() => {     
        (async () => {
            if (!menuStore?.GHLWebhook.length){
                setLoading(true)      
            }                    
            await dispatch(getGhlWebhook())    
            setLoading(false)     
        })()        
    }, [dispatch]) 
    console.log("GHLWEBHOOK data",menuStore?.GHLWebhook) 
          
    const handleClick = () => {
        navigate('/admin/configuration/add-ghl')
    }
    
    const roleHead = [  
        {value: "Provider Name", key: 'provider_id'},
        {value: "Webhook Url", key: 'url'},
        {value: "Action", key: 'action'}
    ]
            
    const handleEdit = (row, isEdit) => {
        if (isEdit) {
            navigate(`/admin/configuration/edit-ghl/${row?.id}`)
        } 
    }
       
  return (   
    <ConfigurationLayout>
            <Grid container p={'20px'}>
        <Grid item xs={12} sx={{ 
                    mb: '16px',
                    display: 'flex',  
                    justifyContent: 'flex-end',       
                    alignItems: 'center',
                    width: '100%',     
                    flexWrap:'wrap',
                    '@media (max-width: 410px)' :{   
                        display:'flex',   
                        justifyContent:'flex-end'     
                       },   
     }}>  

<FormControl sx={{mr: "5px"}}>     
<SimpleInput   
search={search}            
setSearch={setSearch}         
    />           
</FormControl>   

 <CustomButton val={'Add Ghl'} mrt={"10px"} size={'large'} handleClick={handleClick}/>         
    </Grid>   
                <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                    {loading ? <Loader/> :                                                  
                        <GhlData           
                            headRow={roleHead}        
                            isStatus={true}       
                            // tableData={data}                                            
                            type={"ghl"}               
                            tableData={menuStore?.GHLWebhook?.data}    
                            search={search}    
                            handleEdit={handleEdit} 
                        />            
                    }               
 
                </Grid>  
            </Grid>  
        </ConfigurationLayout>     
  )
}    
   
export default GHLWebhook