import React from 'react';
import {FormControl, FormHelperText, FormLabel} from "@mui/material";
import {labelStyle} from "./formInput";
import {IOSSwitch} from "../switch/IOSSwitch";
import Box from "@mui/material/Box";

const FormStatusInput = ({label,values,row,errors,touched,name,setFieldValue,handleAutoSelect}) => {
    return (
        <Box sx={{mb: '16px', }}>
            <FormControl sx={{flexDirection: row ? 'row' : 'column', alignItems: row ? 'center' : 'unset'}}>
                {label && <FormLabel sx={labelStyle}>{label}</FormLabel>}
                <IOSSwitch
                    sx={{m: 1}}
                    name={name}
                    checked={values[name] === "Active" || values[name] === 1}
                    onChange={(e) => {
                        const {checked} = e.target
                        if (name === 'status') {
                            setFieldValue("status", checked ? "Active" : "In-Active")
                        } else {
                            setFieldValue(name, checked ? 1 : 0)
                        }
                        if (checked && handleAutoSelect) {
                            handleAutoSelect()
                        }
                    }}
                />
            </FormControl>
            {errors[name] && touched[name] &&
                <FormHelperText sx={{color: '#CB3838', width: 'max-content',mt:'-5px' ,mx: 0}}>{errors[name]}</FormHelperText>}
        </Box>
    );
}

export default FormStatusInput;