import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../../lib/apiHeader";
import apis from "../../../../lib/apis/apis";
import {getClinicByID} from "../../index";

export const storeClinicSocialInfo = createAsyncThunk('storeClinicSocialInfo', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.clinics_social_info, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.errors) {
            return response?.data
          
        } else if (response?.data?.success === "true") {
            await dispatch(getClinicByID(data?.id))
            toast.success(`Social Info Updated Successfully`, {duration: 3000})
            console.log("dadta",response?.data)
        }

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})


export const clinicSocialInfoSlice = createSlice({
    name: "clinicSocialInfoSlice",
    initialState: {
        servicesTypes: [],
    },
    reducers: {},
    extraReducers: (builder) => {

    },
});

export default clinicSocialInfoSlice.reducer;
