import { Box, Typography } from '@mui/material'
import React from 'react'
import './loading.css'
const loaderCss = {
  width: '100%', // full width
  height: '100vh', // full height
  top: "0",
  bottom: "0",
  left: "0",
  backdropFilter: 'blur(2px)',
}

const defaultCss = {
  position: "fixed", // changed to fixed
  display: 'flex',
  justifyContent: 'center',
  zIndex: '99999999999999999',
  background: 'transparent',
}


const Waveloading = ({fullSize}) => {
  return (
    <Box  sx={fullSize ? {...defaultCss,...loaderCss }: {}}>


<div class="center" >
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  </div>
  </Box>
  
  )
}

export default Waveloading
