import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../lib/apis/apis"
import {apiHeaders, apiHeadersImage} from "../../lib/apiHeader";
import toast from "react-hot-toast";
import { useParams } from "react-router";



export const getAllClinic = createAsyncThunk("getAllClinic", async (data, { dispatch }) => {
    try {
        const response = await axios.get(apis.get_clinic, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data;
        }
        if (response?.data?.success === false) {

            return [];
        }
    } catch (err) {

        return  []
    }
}
);
// Subscription_Plan
export const getsubscriptionPlan = createAsyncThunk("getsubscriptionPlan", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.Subscription_Plan+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});
// promos id 
export const Servicepromo = createAsyncThunk("Servicepromo", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.PromoService+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});


// level 2

export const AllClinicServices = createAsyncThunk("AllClinicServices", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.All_Services+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});
// level 3
export const SubCategory = createAsyncThunk("SubCategory", async (payload, { dispatch }) => {
    
    try {
        const { provider_id, Slug} = payload; 
        const url = `${apis.Sub_Category}/${Slug}/${provider_id}`;
        const response = await axios.get(url, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
           
        }
    } catch (err) {
        return  []
    }
});
// Service_Plan
export const ServicesPlan = createAsyncThunk("ServicesPlan", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.Service_Plan+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});

//  Events
export const AllServices = createAsyncThunk("AllServices", async (payload, { dispatch }) => {
    try {
        const { provider_id, group, treatment } = payload; 

        const url = `${apis.Services}?group=${group}&treatment=${treatment}&provider_id=${provider_id}`;

        const response = await axios.get(url, {
            headers: apiHeaders(),
        });


        if (response?.data?.success === "true") {
            return response?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return [];
    }
});



export const AllStaff = createAsyncThunk("AllStaff", async (id, { dispatch }) => {
    try {
        const response = await axios.get(apis.Staff+id, {
            headers: apiHeaders(),
        });
        if (response?.data?.success === "true") {
            return response?.data?.data;
        }
        if (response?.data?.success === false) {
            return [];
        }
    } catch (err) {
        return  []
    }
});

export const landingPageSlice = createSlice({
    name: "landingPageSlice",
    initialState: {     
        clinic:[],
        SubscriptionPlan:[],
        Services:[],
        ServicePlan:[],
        staff:[],
        subcategory:[],
       ServiceData:[],
       Promo:[],

    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllClinic.fulfilled, (state, action) => {
            state.clinic = action.payload ;
        });
    
        builder.addCase(Servicepromo.fulfilled, (state, action) => {
            state.Promo= action.payload ;
        });  
    
        builder.addCase(getsubscriptionPlan.fulfilled, (state, action) => {
            state.SubscriptionPlan= action.payload ;
        });  
       
        builder.addCase(AllClinicServices.fulfilled, (state, action) => {
            state.Services= action.payload ;
        }); 
        
        builder.addCase(ServicesPlan.fulfilled, (state, action) => {
            state.ServicePlan= action.payload ;
        });
        builder.addCase(AllStaff.fulfilled, (state, action) => {
            state.staff= action.payload ;
        });
        builder.addCase(SubCategory.fulfilled, (state, action) => {
            state.subcategory= action.payload ;
        });
        builder.addCase(AllServices.fulfilled, (state, action) => {
            state.ServiceData= action.payload ;
        });
     
   
      
    


    },
});

export default landingPageSlice.reducer;