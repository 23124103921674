
export const invoicesClasses = {
     wrapper : {
        background: 'white',
        padding: '15px 28px',
        borderTop: "12px solid  #72D5FF",
        borderBottom: "3px solid  #72D5FF",
        borderRadius: "13px"

    },
     logoWrap : {

        display: 'flex',
        justifyContent: "space-between", alignItems: 'center'
    },
     heading : {
        fontFamily: 'helvetica-lt-std-bold',
        fontWeight: '700!important',
        fontSize: '39px!important'
    },
     content : {
        fontFamily: 'helvetica-lt-std-light',
        fontWeight: '400!important',
        fontSize: '18px!important', marginBottom: '3px'
    },
     subHeading : {
        marginBottom: '3px',
        fontFamily: 'helvetica-lt-std-bold',
        fontWeight: '700!important',
        fontSize: '18px!important'
    },

     contentWrapper : {
        marginTop: '40px',
        display: 'flex',
        justifyContent: "space-between",
        '@media (max-width: 768px)': {

            flexDirection: 'column',
            // alignItems: 'center',
        }

    },
     tableWrapper : {

        display: 'flex',
        justifyContent: "space-between"

    },
     subFooter : {
        margin: '0 auto',
        width: "50%",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 768px)': {
            width: '80%',
            flexDirection: 'column',
            alignItems: 'center',
        },
        '@media (max-width: 1265px)': {
            width: '80%',
            alignItems: 'center',
            justifyContent:'center'
        }
    }
}