import React, {useEffect, useState} from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {Autocomplete, TextField, Grid, FormLabel, FormControl, FormHelperText} from "@mui/material";
import FormInput from "../../../../components/form/formInput";
import {useFormik} from "formik";
import * as yup from "yup";
import * as Yup from "yup";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {useDispatch, useSelector} from "react-redux";
import {
    storeClinicLocation, updateClinicLocation
} from "../../../../store/clinics/clinicSettings/business-location";
import Loader from "../../../../components/loader/loader";

const PlacesAutoComplete = ({handleLocationSelect}) => {
    const [loading,setLoading] = useState(false)
    const dispatch =useDispatch()
    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)


    const {
        ready,
        value,
        suggestions: { data},
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete();

    const validationSchema = yup.object({
        address: yup.string().typeError("Address Required").required('Address is required'),
        city: yup.string().required('City is required'),
        country: Yup.string().required("Country is Required"),
        longitude: Yup.string().required("Country is Required"),
        latitude: Yup.string().required("Country is Required"),
        postal_code: Yup.string().required("Country is Required"),
        state: Yup.string().required("State is Required"),
        // suite: Yup.string().required("Suite is Required"),

    });

    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            address: '',
            city: "",
            country: '',
            latitude: '',
            longitude: "",
            postal_code: '',
            state: '',
            suite: '',

        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {
            if (clinicData?.id){
                setLoading(true)
                if (clinicData?.location?.length > 0){
                    let res = await dispatch(updateClinicLocation({"provider_id": clinicData?.id, ...data}))
                    if (res?.payload) {
                        console.log(res?.payload)
                    }
                }else {
                    let res = await dispatch(storeClinicLocation({"provider_id": clinicData?.id, ...data}))
                    if (res?.payload) {
                        console.log(res?.payload)
                    }
                }
                setLoading(false)
            }
        },
    });
    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const handleSelect = (description) => {
        setValue(description, false);
        clearSuggestions();

        getGeocode({address: description})
            .then((results) => {
                const {address_components} = results[0]
                for (const component of address_components) {
                    const componentType = component.types[0];
                    switch (componentType) {
                        case "postal_code": {
                            setFieldValue("postal_code",component.long_name)
                            break;
                        }
                        case "postal_code_suffix": {
                            console.log(`${component.long_name}`)
                            break;
                        }
                        case "locality":
                            setFieldValue("city",component.long_name)
                            break;
                        case "administrative_area_level_1": {
                            setFieldValue("state",component.long_name)
                            break;
                        }
                        case "country":
                            setFieldValue('country',component.long_name)
                            break;
                    }
                }
                let { lat, lng } =getLatLng(results[0])
                handleLocationSelect({lat, lng}, description)
                setFieldValue('latitude',lat)
                setFieldValue('longitude',lng)
            })
            .catch((error) => {
                console.log('😱 Error: ', error);
            });
    };

    useEffect(()=>{
        if (clinicData?.id){
            if (clinicData?.id && clinicData?.location?.length > 0){
                    try {
                        let location = clinicData?.location[0]
                        handleSelect(location?.address);
                        setFieldValue('address',location?.address || "")
                        setFieldValue('city',location?.city ||'')
                        setFieldValue('country',location?.country ||'')
                        setFieldValue('longitude',location?.longitude ||'')
                        setFieldValue('latitude',location?.latitude ||'')
                        setFieldValue('postal_code',location?.postal_code ||'')
                        setFieldValue('state',location?.state ||'')
                        setFieldValue('suite',location?.suite ||'')
                        setFieldValue('id',location?.id ||'')
                    }catch (e) {
                        console.log(e)
                    }
            }
        }
    },[clinicData,clinicData?.id])

    return (
       loading ?<Loader/>:
        <form onSubmit={handleSubmit}>
            <Grid container padding={'20px'} sx={{justifyContent: 'space-between', width: "100%"}}>
                <Grid item xs={12} lg={5.5}>
                    <FormControl fullWidth sx={{mb: "16px"}}>
                        <FormLabel sx={{fontSize: "16px", color: '#27272e', letterSpacing: '.3px'}}>Address</FormLabel>
                        <Autocomplete
                            freeSolo
                            value={value}
                            options={data.map((option) => option.description)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={handleInput}
                                    disabled={!ready}
                                    value={value}
                                    placeholder={'Type your address'}
                                    InputProps={{
                                        ...params.InputProps
                                    }}
                                    sx={{
                                        background: "#EDF2F7",
                                        borderRadius: '4px',
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: "0!important"
                                        },
                                        ".MuiInputBase-input": {
                                            borderColor: " #EDF2F7",
                                            height: "11px",
                                            minHeight: 'unset',
                                            "&:focus": {
                                                borderColor: "#EDF2F7",
                                                outline: "none",
                                                "input": {
                                                    height: '11px'
                                                }
                                            },
                                            "&:hover": {
                                                borderColor: "#EDF2F7",
                                                outline: "none",
                                            }
                                        }
                                    }}
                                />
                            )}
                            onInputChange={(event, newInputValue) => {
                                setValue(newInputValue);
                                if (newInputValue===''){
                                    setFieldValue('address', "")
                                }
                            }}
                            onChange={(event, newValue) => {
                                setFieldValue('address', newValue)
                                handleSelect(newValue);
                            }}
                        />
                        {errors?.address && touched?.address && <FormHelperText
                            sx={{color: '#CB3838', width: 'max-content', mx: 0}}>{errors?.address}</FormHelperText>}

                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"suite"}
                        label={"Suite"}
                        placeholder={'Type  Suite'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"city"}
                        label={"City"}
                        placeholder={'Type  City '}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"state"}
                        label={"State"}
                        placeholder={'Type  State name'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"country"}
                        label={"Country"}
                        placeholder={'Type  Country  name'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"postal_code"}
                        label={"Postal Code"}
                        placeholder={'Type  Postal code name'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"latitude"}
                        label={"Latitude"}
                        placeholder={'Type  latitude'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormInput
                        name={"longitude"}
                        label={"Longitude"}
                        placeholder={'Type  longitude'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}
                      sx={{mt: "16px", display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <SubmitButton
                        val={"Add Location"}
                    />
                </Grid>
            </Grid>
        </form>

    );
};





export default  (PlacesAutoComplete);