import React, { useEffect, useState } from "react";
import UserLayout from "..";
import MilestoneCategroy from "../../../assets/images/milestone.jpg";
import MilestoneChild from "../../../assets/images/milestone1.jpg";
import { Box, Typography, Grid, Avatar,IconButton } from "@mui/material";
// import getCrmCustomerMilestone from "../../../ store/crm/crm/crm-details"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCrmCustomerMilestone } from "../../../store/crm/crm/crm-details";
import { getMileStone } from "../../../store/auth";
import Footer from "../../home/Footer";
import CustomModal from "../../../components/modal/customModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import moment from "moment";
// store/crm/crm/crm-details
const Milestone = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [attachmentDetails, setAttachmentDetails] = useState(null)
  const [selectedData, setSelectedData] = useState(null);
  const [modalImage,setModalImage]=useState(null)     
        
  const handleAppointmentClick = (item) => {
    if (selectedAppointment === item) {
      setSelectedAppointment(null); 
    } else {
      setSelectedAppointment(item);
    }

  };
  const handleDataClick = (item) => {
    if (selectedData === item) {
      setSelectedData(null); 
    } else {
      setSelectedData(item);
    }
  };
  const handleSelectedGallery = (item) => {

    if (attachmentDetails?.id){
        setAttachmentDetails(attachmentDetails?.id === item?.id  ? null : item)
    }else {
        setAttachmentDetails(item)
    }
}
     // user 
  const authStore =useSelector(state => state?.authStore)
  const {user,MileStone} = authStore

  const  dispatch = useDispatch()
 


  useEffect(() => {
      (async () => {
          await dispatch(getMileStone(user.id))
      })()   
  }, [])     
  return  (
    <UserLayout>
    {
         (MileStone?.appointments?.length > 0 || MileStone?.data?.length > 0) ? (
            <>
             {
               MileStone?.appointments?.length  > 0 ?
                        MileStone?.appointments.map((item) =>  (
                                <Grid
                                    item
                                    xs={12}
                                    key={item?.id}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            background: '#F2F2F2',
                                            borderRadius: "4px",
                                            cursor:'pointer',
                                        }}
                                        // onClick={() => handleDataClick(item)}
                                        onClick={() => handleSelectedGallery(item)}

                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'helvetica-lt-std-bold',
                                                ml: "10px",
                                                textTransform:'capitalize',
                                            }}
                                        >
                                            {item?.tag_name}
                                        </Typography>

                                        <IconButton
                                            onClick={() => handleSelectedGallery(item)}
                                        >
                                            {attachmentDetails?.id === item?.id ?  <ArrowDropDownIcon/>:<ArrowDropUpIcon/>}

                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mt: '16px',
                                                display: "flex",
                                                gap: '16px',
                                                flexWrap:'wrap',
                                            }}
                                        >
                                            {
                                                item?.files?.length > 0 ?
                                                    item?.files.map((file, index) => (
                                                       <Box>
                                                        <Avatar
                                                            key={index}
                                                            src={file?.file}
                                                            onClick={()=>setModalImage(file?.file)}
                                                            sx={{
                                                                height: '180px',
                                                                width: '180px',
                                                            }}
                                                            variant="rounded"
                                                        />
                                                           <Box
                                                               sx={{
                                                                   display:'flex',
                                                                   justifyContent:'space-between'
                                                               }}
                                                           >
                                                               <Typography>
                                                               {moment(file?.created_at).format("YYYY-MM-DD")}
                                                               </Typography>
                                                             
                                                           </Box>
                                                       </Box>
                                                    ))
                                                    :
                                                    <Avatar
                                                        sx={{
                                                            height: '180px',
                                                            width: '180px',
                                                        }}
                                                        variant="rounded"
                                                    />
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                            :
                            null
                    }
                     {
               MileStone?.data?.length  > 0 ?
                        MileStone?.data.map((item) =>  (
                                <Grid
                                    item
                                    xs={12}
                                    key={item?.id}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            background: '#F2F2F2',
                                            borderRadius: "4px",
                                            cursor:'pointer',
                                        }}
                                        // onClick={() => handleDataClick(item)}
                                        onClick={() => handleSelectedGallery(item)}

                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'helvetica-lt-std-bold',
                                                ml: "10px",
                                                textTransform:'capitalize',
                                            }}
                                        >
                                            {item?.tag_name}
                                        </Typography>

                                        <IconButton
                                            onClick={() => handleSelectedGallery(item)}
                                        >
                                            {attachmentDetails?.id === item?.id ?  <ArrowDropDownIcon/>:<ArrowDropUpIcon/>}

                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mt: '16px',
                                                display: "flex",
                                                gap: '16px',
                                                flexWrap:'wrap',
                                            }}
                                        >
                                            {
                                                item?.files?.length > 0 ?
                                                    item?.files.map((file, index) => (
                                                       <Box>
                                                        <Avatar
                                                            key={index}
                                                            src={file?.file}
                                                            onClick={()=>setModalImage(file?.file)}
                                                            sx={{
                                                                height: '180px',
                                                                width: '180px',
                                                            }}
                                                            variant="rounded"
                                                        />
                                                           <Box
                                                               sx={{
                                                                   display:'flex',
                                                                   justifyContent:'space-between'
                                                               }}
                                                           >
                                                               <Typography>
                                                               {moment(file?.created_at).format("YYYY-MM-DD")}
                                                               </Typography>
                                                             
                                                           </Box>
                                                       </Box>
                                                    ))
                                                    :
                                                    <Avatar
                                                        sx={{
                                                            height: '180px',
                                                            width: '180px',
                                                        }}
                                                        variant="rounded"
                                                    />
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                            :
                          null
                    }

            </> 
         ): (
            <Grid xs={12} item>
              <Typography sx={{ textAlign: 'center' }}>No data available</Typography>
            </Grid>
          )
        }  
         

        <CustomModal
                customWidth={"1000px"}
                open={modalImage!==null}
                setOpen={() => setModalImage(null)}
            >
                <Box>

            <Avatar
                 src={modalImage}
                // onClick={()=>handleMoveMilestone(file?.id)}
                sx={{
                    cursor:'pointer',
                    height: '100%',
                    width: '100%',
                }}
                variant="rounded"
            />
            </Box>
            </CustomModal>
<Box style={{marginLeft:'calc(-50vw + 50%)', marginRight:'calc(-50vw + 50%)' }}>
            <Footer />
</Box>
    </UserLayout>
  );
};

export default Milestone;
