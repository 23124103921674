import React, {useState,useRef } from 'react';
import CustomButtoncolor from "../custom-button/customColorButton";
import {Box, Button, Checkbox, FormControlLabel, Typography} from "@mui/material";
import CustomModal from "../modal/customModal";
import moment from "moment/moment";
import {
    getCrmAppointmentHolidays,
    getCrmAppointmentSchedule,
    rescheduleAppointment
} from "../../store/crm/appointment/book-appointment";
import {useDispatch} from "react-redux";
import Loader from "../loader/loader";
import AppointmentTime from "../../pages/crm/appointment/add-appointment/appointmentTime";
import {MySwal, successConfirmation} from "../../services/utills";
import {mySwalButton} from "./CardAppointment";
import CustomButton from "../custom-button/customButton";
import {getAllAppointment, getAppointmentDetails, getAppointmentHistory, getAppointmentcalender} from "../../store/crm/appointment";
import Waveloading from '../../pages/loading/Waveloading';
import { Navigate, useNavigate } from 'react-router';
import { getCRMCustomerBookingsDetails } from '../../store/crm/crm';

const AppointmentReschedule = ({ triggerReload,appointment,isConfirm,isRequested,isMouseEnter,setIsMouseEnter,fetchAppointment, fwidth,onRescheduleSuccess}) => {
    const [rescheduleModal, setRescheduleModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedFinalDate,setSelectedFinalDate] = useState(null)
    const [selectedTime,setSelectedTime] = useState(null) 
    const navigate = useNavigate()
    const [apiValue, setApiValue] = useState(1); 
    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setApiValue(checked ? 1 : 0);
      };
 
    const dispatch = useDispatch()  
    const getAppointmentHoliday = async (date, staff) => {
        setLoading(true) 
        let finalDate = moment(date).format('YYYY-MM-DD')
        // setSelectedFinalDate(finalDate)
        if (finalDate) {
            let finalData = {
                date: finalDate
            }
          /*  if (staff?.id) {
                finalData['staff_id'] = staff?.id
            }*/
            await dispatch(getCrmAppointmentHolidays(finalData))
        }

        setLoading(false)      

    }
    const getAppointmentSchedule = async (date) => {

        setLoading(true)
        let serviceIds = []
        appointment?.services.forEach((item) => {
            serviceIds.push(item?.id)
        })


        let finalDate = moment(date).format('YYYY-MM-DD')

        setSelectedFinalDate(finalDate)
        setSelectedTime(null)
        let finalData = {
            date: finalDate,
            service_ids: serviceIds
        }

        await dispatch(getCrmAppointmentSchedule(finalData))
  
        setLoading(false)           
       
    }    
 
    const handleReschedule = async () => {
        let confirm = await MySwal.fire({
            icon: 'question',   
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>  
                    Are you sure? You want to Reschedule this appointment .
                </Typography>
                <Box sx={{mt: 3,}}> 
                    <Button
                        sx={{...mySwalButton}}
                        onClick={() => MySwal.clickConfirm()}   
                    >
                        Yes
                    </Button>
                    <Button
                        sx={{...mySwalButton}}
                        onClick={() => MySwal.close()}   
                    >
                        No
                    </Button>
                </Box>
             </Box>   
        })
        if (!!confirm?.isConfirmed) {
            if (appointment?.date && appointment?.end_time && appointment?.start_time) {
                let givenDate = moment(appointment?.date);
                let today = moment(new Date());
/*
                if (givenDate.isSameOrAfter(today, 'day')) {
*/
                    setLoading(true)
                    await getAppointmentHoliday(appointment?.date, appointment?.staff)
                    await getAppointmentSchedule(appointment?.date)
                    setSelectedFinalDate(appointment?.date)
                    setLoading(false)
                    setRescheduleModal(true)
              /*  } else {
                    await successConfirmation("Warning", 'Booking date is over Due.', 'warning')
                }*/
            }else {
                setRescheduleModal(true)
            }
        }
    }
    const handelRescheduleClick = async () => {
        setRescheduleModal(false)
        setLoading(true)
        let finalData = {
            booking_id:appointment?.id,
            date:selectedFinalDate,
            start_time:selectedTime?.start_time,
            end_time:selectedTime?.end_time,
            is_email_send:apiValue,
        }
        let {payload} = await dispatch(rescheduleAppointment(finalData))

        if (payload?.success === "true"){
            await dispatch(getAppointmentcalender(appointment?.id))
            await dispatch(getAppointmentDetails(appointment?.id))
            await dispatch (getAppointmentHistory({
                user_id:appointment?.customer?.id,type:'Appointments'
            }))
            await dispatch (getCRMCustomerBookingsDetails({
                id:appointment?.customer?.id,
                type:'Appointments'
            }))

            await dispatch(getAllAppointment({start_date:"null",end_date:"null"}))
            // if (fetchAppointment){  
            //     await fetchAppointment()
            // }
         
            setLoading(false)  
            await successConfirmation("Success","Appointment Reschedule Successfully")
             if(triggerReload){
                triggerReload()
             }
         
            // navigate(`/crm/appointment/confirmation/${appointment?.id}`)
          
            setRescheduleModal(false)       
            if (onRescheduleSuccess) {
                onRescheduleSuccess();
              }
        }else {
            setLoading(false)
        }
    }
    const handleTimeSelect = (slotsArray, startTime) => {
        // Implement your logic for handling time slot selection here
        
        // Scroll to the Reschedule Appointment button
        // if (rescheduleButtonRef.current) {
        //   rescheduleButtonRef.current.scrollIntoView({ behavior: 'smooth' });
        // }
      };
       
      const rescheduleButtonRef = useRef(null);

      // Function to execute scrolling to the reschedule button
      const executeScroll = () => {
        setTimeout(() => {
            rescheduleButtonRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 500);
      };


      

    return (
        <Box>
               
            { appointment?.id && <CustomModal
                customWidth={"60%"}
                open={rescheduleModal}
                setOpen={setRescheduleModal}   
            >
                 {/* {loading ? <Waveloading fullSize/>:''} */}
               <Box sx={{display:"flex",justifyContent:'space-between',mb:'10px',mt:'10px'}}>
                   <Typography
                       sx={{textAlign:'center',fontSize:'20px',mt:"-6px",fontFamily:'helvetica-lt-std-bold'}}
                   >
                       Reschedule Appointment
                   </Typography>
                   { appointment?.date && 
                       <Typography
                           sx={{
                               textAlign: 'center',
                               fontSize: '20px', 
                               mt: "-6px",
                               fontFamily: 'helvetica-lt-std-bold',
                           }}  
                       >
                       Current Booking Date : {moment(appointment?.date).format('dddd, Do MMMM, YYYY')}
                   </Typography>
                   }

               </Box>
                <AppointmentTime  
                    selectedFinalDate={selectedFinalDate}
                    setSelectedFinalDate={setSelectedFinalDate}
                    selectedTime={selectedTime}   
                    selectedStaff={appointment?.staff || null}
                    setSelectedTime={setSelectedTime} 
                    selectedServices={appointment?.services}
                    getAppointmentHoliday={getAppointmentHoliday}
                    getAppointmentSchedule={getAppointmentSchedule}
                    handleTimeSelect={handleTimeSelect}
                    executeScroll={executeScroll} 
                  

                />
                  <Box sx={{display:"flex",gap:"3",marginLeft:"19px"}}>
                                              <Box>
                                              <FormControlLabel
                                                            // key={index}
                                                            // value={method?.name}
                                                            size="small"      
                                                            control={
                                                                <Checkbox  
                                                                checked={apiValue === 1}
                                                                onChange={handleCheckboxChange}
                                                                />
                                                            }         
                                                            label={"Send Notification"}  
                                                            sx={{   
                                                               '& .MuiSvgIcon-root': {  
                                                                    fontSize: 20,  
                                                                      },
                                                            }}
                                                        />  
                                            </Box>
                                            </Box>
                {
                    selectedTime?.start_time &&<Box  ref={rescheduleButtonRef} sx={{display:'flex',justifyContent:"center",mt:'24px'}}>
                    <CustomButton    handleClick={handelRescheduleClick} val={'Reschedule Appointment'} />
                </Box>
                }
                
              
            </CustomModal>}
            {loading ? <Waveloading fullSize/>:''}
            {isRequested ?
                <Button
                    variant="outlined"
                    color="info"
                    onMouseEnter={() => {
                        setIsMouseEnter(true)
                    }}
                    onMouseLeave={() => {
                        setIsMouseEnter(false)
                    }}
                    onClick={() => isMouseEnter && appointment?.id  && handleReschedule()}
                    sx={{
                        height:'100%'
                    }}
                >
                    Reschedule
                </Button>
                : isConfirm ?
                <Box sx={{ mt:4, }}>
                    <Typography
                        variant="h6"
                        color="initial"
                        sx={{
                            color:'#1565c0',
                            marginTop:'5px',
                            fontFamily: "helvetica-lt-std-bold",
                            cursor:'pointer',
                        }}
                        onClick={() =>appointment?.id  && handleReschedule()}
                    >
                        Reschedule appointment
                    </Typography>
                </Box>
                :
            <CustomButtoncolor
                mainColor={"#ff5907"}
                handleClick={() =>appointment?.id  && handleReschedule()}
                style={{borderRadius: "4px", padding: '10px 15px', width: fwidth}}
                val={"Reschedule"}
            />
            }

        </Box>
    );
};

export default AppointmentReschedule;