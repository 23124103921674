import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import {storeClinicBusinessHours} from "../../../../store/clinics/clinicSettings/business-hours";
import * as yup from "yup";
import {parseTime, timeSlots} from "../../../../services/utills";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    TextField
} from "@mui/material";
import {inputStyle, labelStyle} from "../../../../components/form/formInput";
import {IOSSwitch} from "../../../../components/switch/IOSSwitch";
import Autocomplete from "@mui/material/Autocomplete";
import Loader from "../../../../components/loader/loader";

const Hours = () => {
    const [loading,setLoading] = useState(false)
    const  clinicsBusinessHoursStore = useSelector(state => state.clinicsBusinessHoursStore)

    const {businessHours} = clinicsBusinessHoursStore


    const dispatch = useDispatch()
    const params = useParams()

    const validationSchema = yup.object({
        hours: yup.array().of(
            yup.object().shape({
                is_off: yup.boolean().required("Is day off is required"),
                start_time: yup
                    .date()
                    .transform((value, originalValue) =>
                        originalValue ?  parseTime(originalValue) : null
                    ),
                // .required("Start time is required").typeError("Start time is required"),
                end_time: yup
                    .date()
                    .transform((value, originalValue) =>
                        originalValue ? parseTime(originalValue) : null
                    )
                    // .required("End time is required").typeError("End time is required")
                    .min(yup.ref("start_time"), "End time must be after start time"),
            })
        ),
    });
    const {values, handleSubmit, setFieldValue,resetForm, handleChange, touched, errors} = useFormik({
        initialValues: {
            hours: [
                {day: 'Sunday', is_off: true, start_time: "", end_time: "",no_of_slot:0},
                {day: 'Monday', is_off: true, start_time: "", end_time: "",no_of_slot:0},
                {day: 'Tuesday', is_off: true, start_time: "", end_time: "",no_of_slot:0},
                {day: 'Wednesday', is_off: true, start_time: "", end_time: "",no_of_slot:0},
                {day: 'Thursday', is_off: true, start_time: "", end_time: "",no_of_slot:0},
                {day: 'Friday', is_off: true, start_time: "", end_time: "",no_of_slot:0},
                {day: 'Saturday', is_off: true, start_time: "", end_time: "",no_of_slot:0},
            ],
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {

            setLoading(true)
            let res = await dispatch(storeClinicBusinessHours({
                hours: data?.hours,
                provider_id: params?.clinic_id,
            }))

            if (res?.payload?.errors) {
                const {errors} = res?.payload
                console.log(errors)
            }
            setLoading(false)
        },
    })
    useEffect(()=>{

        if (businessHours?.length){
            values?.hours.map((destinationObject,index) => {
                const sourceObject = businessHours.find((sourceObject) => sourceObject.day === destinationObject.day);

                if (sourceObject) {
                    setFieldValue(`hours.${index}.start_time`, sourceObject?.start_time || "")
                    setFieldValue(`hours.${index}.end_time`,  sourceObject?.end_time || "")
                    setFieldValue(`hours.${index}.is_off`,  sourceObject?.is_off === 1 ? true : false)
                    setFieldValue(`hours.${index}.no_of_slot`,  sourceObject?.no_of_slot || 0)
                }
            });
        }
    },[businessHours,businessHours?.length])

    return (
        <form onSubmit={handleSubmit}>
            {loading ? <Loader fullSize/> : ""}
            {values?.hours.map((item, index) => (
                <Grid key={index} container
                      sx={{alignItems: 'center', width: "100%", py: 2}}>
                    <Grid item xs={6} lg={2} md={2} sm={2}>
                        <FormLabel sx={labelStyle}>{item?.day}</FormLabel>
                    </Grid>
                    <Grid item xs={6} lg={2} md={2} sm={2}>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    name={`hours[${index}].is_off`}
                                    sx={{m: 1}}
                                    checked={!item?.is_off}
                                    onChange={(e)=>{
                                        handleChange({
                                            target: { name: `hours[${index}].is_off`, value: !e.target?.checked },
                                        })
                                    }}
                                />
                            }
                        />
                    </Grid>
                    {!item?.is_off && <Grid item xs={12} lg={8} >
                        <Grid container justifyContent={'space-around'}>
                            <Grid item xs={5}>
                                <Autocomplete
                                    name={`hours[${index}].start_time`}
                                    options={timeSlots}
                                    getOptionLabel={(option) => option}
                                    value={item?.start_time}
                                    onChange={(event, value) => {
                                        setFieldValue(`hours.${index}.start_time`, value || "")
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder={'Open At'} sx={inputStyle}/>
                                    )}
                                    getOptionDisabled={(option) => option === item?.end_time}
                                />
                                {errors.hours?.[index]?.start_time && touched.hours?.[index]?.start_time &&
                                <FormHelperText sx={{
                                    color: '#CB3838',
                                    width: 'max-content',
                                    mx: 0
                                }}>{errors.hours?.[index]?.start_time}</FormHelperText>}

                            </Grid>
                            <Grid item xs={0.4} sx={{p:'20px 0'}}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={5}>
                                <Autocomplete
                                    name={`hours[${index}].end_time`}
                                    options={timeSlots}
                                    getOptionLabel={(option) => option}
                                    value={item?.end_time}
                                    onChange={(event, value) =>
                                        setFieldValue(`hours.${index}.end_time`, value || "")
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder={'Close At'} sx={inputStyle}/>
                                    )}
                                    getOptionDisabled={(option) => option === item?.start_time}
                                />
                                {errors.hours?.[index]?.end_time && touched.hours?.[index]?.end_time && <FormHelperText
                                    sx={{
                                        color: '#CB3838',
                                        width: 'max-content',
                                        mx: 0
                                    }}>{errors.hours?.[index]?.end_time}</FormHelperText>}

                            </Grid>
                            <Grid item xs={1}>
                                <FormControl fullWidth sx={{}}>
                                    <TextField
                                        fullWidth
                                        name={`hours[${index}].no_of_slot`}
                                        type={"number"}
                                        InputProps={{
                                            inputProps: {min: 0}
                                        }}
                                        sx={{...inputStyle}}
                                        placeholder={"type days"}
                                        value={item?.no_of_slot}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>}
                </Grid>
            ))}
            <Grid item xs={12} sx={{display: 'flex', mt: 2, justifyContent: "flex-end"}}>
                <Button
                    sx={{
                        backgroundColor: '#09ABF1',
                        borderColor: '#09ABF1',
                        color: '#fff',
                        ml: 3,
                        height: '44px',
                        "&:hover": {
                            color: '#09ABF1',
                            borderColor: '#09ABF1',
                        }
                    }}
                    type={"submit"}
                    variant={'outlined'}
                >
                    Save Clinic Hours
                </Button>
            </Grid>
        </form>
    );
};

export default Hours;