import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid, IconButton,
  Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NotificationAlert } from "../../store/crm/call-log";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import NotificationsIcon from "@mui/icons-material/Notifications";
import moment from "moment/moment";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
const Notification = ({Notifications}) => {
  const [active, setActive] = useState(null)
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  console.log("notification",Notifications)

  const remainClose = (event) => {
    event.stopPropagation();
    // Perform any other actions related to the button click here
  };
  // const callLogStore = useSelector(state => state?.callLogStore)
  // const {Notification=[]} = callLogStore
  // 
//   useEffect(() => {
//     (async () => {
//         // setLoading(true)
//         await dispatch(NotificationAlert())
//         // setLoading(false)


//     })()
// }, [])
// console.log(" notification",Notification)

  return (
    <>
      <Box sx={{ padding: "40px 20px",  }}>
        <Typography variant="h5"  sx={{fontFamily:'helvetica-lt-std-bold'}}>
          Notification / Alerts 
        </Typography>


        <Grid
          item
          xs={12}
          sx={{
            mt: 5,
            fontWeight: "bold",
            background: "#fff",
            borderBottom: "1px solid rgb(226, 232, 240)",
          }}
        >
           {Notifications?.length > 0 ? (Notifications.map((reminder,index) => (
                   <Paper key={index} sx={{ mt: '20px',display: 'flex', width: '100%', py: '24px', pr: "14px", pl: '24px'}}>
                     <Box>
                       <NotificationsIcon sx={{color: active === reminder?.id ? '#09ABF1' : '#828282', fontSize: '30px'}}/>
                     </Box>
                     <Box sx={{flexGrow: '1', ml: '24px'}}>
                       <Typography sx={{fontSize: '16px', color: '#000'}}>
                         Reminder on {moment(reminder?.date).format('MMMM D, YYYY')}
                       </Typography>
                       <Typography sx={{fontFamily: "HelveticaLTStd-Obl", color: '#828282', fontSize: "14px"}}>
                         Created by {reminder?.staff?.name} on {moment(reminder?.created_at).format('MMMM D, YYYY')}
                       </Typography>
                       {reminder?.id === active && <Box sx={{mt: '24px'}}>
                         <Typography>
                           Notes: {reminder?.note}
                         </Typography>
                       </Box>}
                     </Box>
                     <Box>
                       <IconButton sx={{mt:'-15px'}} onClick={()=>setActive(active === null || active !== reminder?.id ? reminder?.id :  null)}>
                         {reminder?.id === active ?
                             <ArrowDropUpRoundedIcon sx={{color: active === reminder?.id ? '#09ABF1' : '#828282', fontSize: '35px'}}/>
                             :
                             <ArrowDropDownRoundedIcon sx={{color: active === reminder?.id? '#09ABF1' : '#828282', fontSize: '35px'}}/>
                         }
                       </IconButton>
                     </Box>
                   </Paper>
               ))
          ) : ( 
            <Typography> here is no NotificationAlert</Typography>
            )}

          {/* <Accordion
            onChange={handleAccordionChange}
            sx={{
              boxShadow: "0px 0px 11px -4px rgba(81, 65, 65, 0.25)",
              borderRadius: "6px",
              mt:2,
              background:'#E5E5E5'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              sx={{
                ".MuiAccordionSummary-content": {
                  justifyContent: "space-between",
                },
              }}
            >
              <Box sx={{ display: "flex",  flexDirection:'column' }}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "helvetica-lt-std-roman",
                }}
              >
                Staff Notes
              </Typography>
              <Typography variant="body1" sx={{ color:'rgb(9, 171, 241)', textDecoration:'underline', mt:1 }}>
              Sophie James
            </Typography>
              </Box>
              <Box sx={{ mr: "10px", display: "flex", flexWrap: "wrap", flexDirection:'column' }}>
              <Typography variant="body2">Date</Typography>
              <Typography variant="body1" sx={{ mt:1 }}>26- May</Typography>
            </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                
                sx={{  fontFamily: "helvetica-lt-std-bold" }}
              >
                Description:
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "18px", fontFamily: "helvetica-lt-std-light" }}
              >
                Description here is placed please check
              </Typography>
            </AccordionDetails>
          </Accordion> */}
        </Grid>
      </Box>


      {/* <Box sx={{ padding: "20px 20px" }}>
        <Typography variant="h5"  sx={{fontFamily:'helvetica-lt-std-bold'}}>
        Upcoming Member Birthday Alerts
        </Typography>

        <Grid
          item
          xs={12}
          sx={{
            mt: 5,
            px:2
          }}
        >
          <Box sx={{ display:'flex', justifyContent:'space-between' }}>
            <Typography variant="h6" sx={{ color:'rgb(9, 171, 241)', textDecoration:'underline' }}>
              Sophie James
            </Typography>
            <Typography variant="body1" >
              29 May
            </Typography>
          </Box>
        </Grid>
      </Box> */}
    </>
  );
};

export default Notification;
