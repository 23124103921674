

export const cradDiv ={
    position: "relative",
    " @media (max-width:600px)": {
        "& img": {
            height: "250px",
        },
        "& Button": {
            width: "80%",
            fontSize: "13px",
            fontWeight: "600",
            top: "39%",
        },
        "& h4": {
            fontSize: "15px",
            fontWeight: "600",
            top: "48%",
            marginTop:'25px'
        },
    },
    " @media (max-width:319px)": {
        "& Button": {
            width: "82%",
            fontSize: "13px",
            fontWeight: "600",
            top: "39%",
            left:"8%"
        },
    }
}

export const upgradeButton ={
    " @media (max-width:1650px)": {fontSize: "15px"},
    position: "absolute",
    top: "28%",
    left: "13%",
    fontSize: "20px",
    fontWeight: "700",
    width: "75%",
    background: '#09ABF1',
    fontFamily:'helvetica-lt-std-bold',
}

export const cardDescription = {
    position: "absolute",
    top: "40%",
    left: "15%",
    right: "15%",
    my: "12px",
    color: "white",
    fontSize: "17px",
    fontWeight: "700",
    textAlign: "center",
    fontFamily:'helvetica-lt-std-bold',
}
export const overlayDiv = {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
};
export const gradient = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
};
