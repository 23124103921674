import React, {useEffect, useState} from "react";
import { EditorState,convertToRaw,  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import  './styles.css'

import {Box, FormHelperText, TextField} from "@mui/material";
import {scrollCSS} from "../../App";
import {inputStyle} from "../form/formInput";

const RichTextField = ({name,errors,touched,handleChange,label,values  }) => {
    const [active, setActive] = useState('rich')
    const [editorState, setEditorState] = useState(EditorState.createEmpty());



    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const markup = draftToHtml(rawContentState);
        handleChange({
            target: { name: name, value: markup },
        })
    };
    const boxStyle = {
        ml: '8px',
        borderTop: '1px solid #EDF2F7',
        borderLeft: '1px solid #EDF2F7',
        borderRight: '1px solid #EDF2F7',
        p: '7px',
        cursor: 'pointer',
    }
    const handleClick = (val) => {
        setActive(val)
    }
    return (
        <Box sx={{mb: '16px'}}>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Box sx={{...boxStyle,background:active === 'text' ? "#EDF2F7" :''}} onClick={() => handleClick("text")}>
                    Text
                </Box>
                <Box sx={{...boxStyle,background:active === 'rich' ? "#EDF2F7" :''}} onClick={() => handleClick("rich")}>
                    Rich Text
                </Box>
                <Box sx={{...boxStyle ,background:active === 'visual' ? "#EDF2F7" :''}} onClick={() => handleClick("visual")}>
                    Visual
                </Box>
            </Box>
            <Box sx={{maxHeight:'400px',...scrollCSS,overflow:'auto'}}>
                {
                    active === "rich" && <Editor
                        editorState={editorState || values[name]}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName="editor-wrapper"
                        editorClassName="editor-content"
                    />
                }
                {
                    active === "visual" &&
                    <Box
                        sx={{
                            backgroundColor:'#EDF2F7',
                            p:"10px 20px",
                            minHeight:'200px'
                    }}
                        className="preview"
                    >
                        <div dangerouslySetInnerHTML={{ __html: values[name] }} />
                    </Box>
                }
                {
                    active === "text" &&
                    <TextField
                        fullWidth
                        value={values[name]}
                        minRows={5}
                        multiline
                        sx={{...inputStyle}}
                        onChange={(e) => handleChange({
                            target: { name: name, value: e?.target?.value },
                        })}
                        placeholder="Enter Description"

                    />
                }
            </Box>
            {errors[name] && touched[name] && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors[name]}</FormHelperText>}
        </Box>
    );
};

export default RichTextField