import React from 'react';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

const AppointmentDrawer = ({ tabData, appointment_id, pathname, redirect }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleListItemClick = () => {
    handleDrawerToggle();
  };

  return (
    <Hidden lgUp implementation="css">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"  
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <Box
          sx={{
            display: 'block',
            maxHeight: "100vh",
            minHeight: '50vh',
            overflowY: "auto",
            background: '#fff',
            width: '250px',
            p: '0',
            borderRadius: '7px',
          }}
        >
          <List
            sx={{
              width: "100%",
              p: "0",
              ".MuiListItemIcon-root ": {
                minWidth: "37px",
              },
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {tabData?.map((item, index) => (
              <ListItemButton
                key={index}
                onClick={() => {
                  redirect(item?.url + appointment_id);
                  handleListItemClick(); 
                }}
                sx={{
                  borderBottom: '1px solid #E8E8E8',
                  py: "16px",
                  pl: '24px',
                  "span": {
                    fontFamily: pathname.includes(item?.url) ? "helvetica-lt-std-bold" : "helvetica-lt-std-roman",
                    fontSize: '19px',
                  },
                  "&:hover": {
                    "span": {
                      fontFamily: "helvetica-lt-std-bold",
                    }
                  },
                }}
              >
                {<ListItemText primary={item?.title} />}
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Drawer>
    </Hidden>
  );
};

export default AppointmentDrawer;
