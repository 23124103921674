import React, {useEffect, useState} from 'react';
import CustomButton from "../../../components/custom-button/customButton";
import {Box, Grid,  useMediaQuery} from "@mui/material";
import toast from "react-hot-toast";
import ConfigurationLayout from "../configurationLayout";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import Loader from "../../../components/loader/loader";
import SubmitButton from "../../../components/custom-button/submitButton";
import FormInput from "../../../components/form/formInput";
import * as yup from "yup";
import {useFormik} from "formik";
import {getClinicByID, storeClinic, updateClinic} from "../../../store/clinics";
import * as Yup from "yup";
import {useTheme} from "@mui/material/styles";
import FormPasswordInput from "../../../components/form/formPasswordInput";

const AddClinics = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const theme = useTheme()


    const hiddenLG = useMediaQuery(theme.breakpoints.down('lg'))


    const validationSchema = yup.object({
        name: yup.string().min(3, "Clinic name is Too Short").required('Clinic  name is required'),
        email: Yup.string().email("Invalid email address").required("Email Is Required"),
        password: Yup.string()
            .min(8, 'Password must be 8 characters long')
        /*    .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')*/
            // .matches(/[^\w]/, 'Password requires a symbol')
            .required("Password is Required"),
    });


    const {values, handleSubmit,unregisterField, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            name: '',
            email: "",
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)

            let res
            if (params?.clinic_id) {
                delete data["password"]
                res = await dispatch(updateClinic(data))
            } else {
                res = await dispatch(storeClinic(data))
            }
            if (res?.payload?.errors) {
                const {errors} = res?.payload
                console.log(errors)
                if (errors?.name) {
                    setFieldTouched("name", true)
                    setFieldError("name", errors?.name)
                }
                if (errors?.email) {
                    setFieldTouched("email", true)
                    setFieldError("email", errors?.email)
                }

            } else if (res?.payload?.success === "true") {
                toast.success(`Clinic ${params?.clinic_id ? "updated" : "Stored"} Successfully`, {duration: 3000})
                navigate('/admin/configuration/clinics')
            }

            setLoading(false)
        },
    });



    useEffect(() => {
        (async () => {
            if (params?.clinic_id) {
                setLoading(true)
                let res = await dispatch(getClinicByID(params?.clinic_id))
                if (res?.payload?.data?.id) {
                    unregisterField('password')
                    await setFieldValue("name", res?.payload?.data?.name)
                    await setFieldValue('email', res?.payload?.data?.email)
                    await setFieldValue('id', res?.payload?.data?.id)
                    await setFieldValue('password', "Slack123#")
                }
                setLoading(false)
            }

        })()
    }, [params?.clinic_id])

    return (
        <ConfigurationLayout>
            <Box sx={{height: "calc(100vh - 125px)", backgroundColor:"white"}}>
                {loading ? <Loader/> :
                    <form onSubmit={handleSubmit}>
                        <Grid container p={3} sx={{justifyContent: 'space-between', width: hiddenLG ? "100%" : '70%'}}>
                            <Grid item xs={12} md={5}>
                                <FormInput
                                    name={"name"}
                                    label={"Name"}
                                    placeholder={'Type  menu name'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <FormInput
                                    name={"email"}
                                    label={"Email"}
                                    placeholder={'Type your e-mail'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            {!params?.clinic_id && <Grid item xs={12} md={5}>
                                <FormPasswordInput
                                    name={"password"}
                                    label={"Password"}
                                    placeholder={'Type your password'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>}


                            <Grid item xs={12} sx={{mt:5,display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <Box sx={{mr: '16px'}}>
                                    <CustomButton
                                        mainColor={'#ED1E45'}
                                        val={'Cancel'}
                                        handleClick={() => {
                                            navigate('/admin/configuration/clinics')
                                        }}
                                    />
                                </Box>
                                <SubmitButton
                                    val={params?.clinic_id ? "Update Clinic" :"Add Clinic"}
                                />
                            </Grid>
                        </Grid>
                    </form>
                }
            </Box>
        </ConfigurationLayout>
    );
};

export default AddClinics;