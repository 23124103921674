import React from "react";

import SubmitButton from "../../../../components/custom-button/submitButton";
import FormInput from "../../../../components/form/formInput";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import {   useFormik } from "formik";
import   {
  storeTwilioConfig,
} from "../../../../store/clinics/clinicSettings/twilioConfig";

import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useParams } from "react-router";
import { useEffect } from "react";
import FormInputPhone from "../../../../components/form/formInputPhone";

const TwilioConfig = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const clinicData = useSelector((state) => state?.clinicStore?.clinicByID);

  const validationSchema = Yup.object({
   /* account_id: Yup.string().required("Account ID is required"),
    auth_token: Yup.string().required("Auth Token is required"),
    auth_api_key: Yup.string().required("Auth API Key is required"),*/
    phone: Yup.string().when('countryCode', {
      is: '+92',
      then: Yup.string()
          .matches(/^[1-9][0-9]\d{8}$/, 'Invalid pakistani phone number')
          .required('Phone number is required'),
      otherwise: Yup.string()
          .matches(/^(\d{3}-?)?\d{3}-?\d{4}$/, 'Invalid canada phone number')
          .required('Phone number is required')
    }),
    countryCode: Yup.string().required("Country code is required"),
  });
  const { values, handleSubmit, setFieldValue, handleChange, touched, errors } =
    useFormik({
      initialValues: {
      /*  account_id: "",
        auth_token: "",
        auth_api_key: "",*/
        phone: "",
        countryCode: "+92",
      },
      validationSchema: validationSchema,
      validateOnChange:true,
      onSubmit: async (values, { setFieldTouched, setFieldError }) => {
        try {
          const data = {
        /*    account_id: values.account_id,
            auth_token: values.auth_token,
            auth_api_key: values.auth_api_key,*/
            phone: values?.countryCode+values.phone,
          };
          const resultAction = await dispatch(
            storeTwilioConfig({
              ...data,
              provider_id: params?.clinic_id,
            })
          );
          const response = unwrapResult(resultAction);
          console.log("response", response);
          // Handle success
        } catch (error) {
          console.log("error", error);
          // Handle error
        }
      },
    });

  useEffect(() => {
    if (clinicData?.id && clinicData?.twillio_configuration?.length > 0) {
      try {
        let data = clinicData?.twillio_configuration[0];
        setFieldValue("account_id", data?.account_id || null);
        setFieldValue("auth_token", data?.auth_token || null);
        setFieldValue("auth_api_key", data?.auth_api_key || null);
        if (data?.phone.includes("+1")){
          setFieldValue("phone",data?.phone.replace(/^\+1/, ''))
          setFieldValue('countryCode','+1')
        }
        if (data?.phone.includes("+92")){
          setFieldValue("phone",data?.phone.replace(/^\+92/, ''))
          setFieldValue('countryCode','+92')
        }
      } catch (e) {}
    }
  }, [clinicData, clinicData?.id]);
  return (
    <form onSubmit={handleSubmit}>
      <Grid container p={"20px"} sx={{ justifyContent: "space-between" }}>
     {/*   <Grid item xs={12} lg={5.5}>
          <FormInput
            name={"account_id"}
            label={"Account Id"}
            placeholder={"Type your Account Id"}
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <FormInput
            name={"auth_token"}
            label={"Auth Token"}
            placeholder={"Type Auth Token"}
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={5.5}>
          <FormInput
            name={"auth_api_key"}
            label={"Auth Api Key"}
            placeholder={"Type Auth Api Key "}
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />
        </Grid>*/}
        <Grid item xs={12} lg={5.5}>
           <FormInputPhone
            name={"phone"}
            label={"Mobile"}
            placeholder={values?.countryCode ==='+1' ? "xxx-xxx-xxxx" : "3xxxxxxxxx"}
            errors={errors}
            touched={touched}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
          <div style={{ marginRight: "10px" }}>
            <SubmitButton val={"cancel"} />
          </div>

          <SubmitButton val={"save"} />
        </Grid>
      </Grid>
    </form>
  );
};

export default TwilioConfig;
