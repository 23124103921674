import {Box, Button, Grid, Typography} from "@mui/material";
import React from "react";
import client from "../../assets/images/client.png";
import { makeStyles } from "@mui/styles";
import { AiFillStar } from "react-icons/ai";
import { BsFillHandThumbsUpFill, BsStarHalf } from "react-icons/bs";


const useStyles = makeStyles((theme) => ({
  iconTextWrapper: {
    gap: "4px",
  },
  innercontainer: {
    display: "flex",
    gap: "4px",
  },
  outercontainer: {
    margin: "15px",
    padding: "20px",
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 4px 19px -2px rgba(189, 189, 189, 0.25)",
    // boxShadow: "#12B104",
    borderRadius: "10px",
  },
  review: {
    display: "flex",
    alignItems: "center",
    gap: '10px',
  },
  bottomdiv: {
    marginTop:'12px',
    display: "flex",
    alignItems: "start",
    gap: '16px',
  },
  btn: {
    marginTop:'12px',
    display: "flex",
    gap: '4px',
  },
  ratingdiv: {
    display: "flex",
    flexDirection: "column",
    gap:'2px',
  },
  Response: {
    display: "flex",
    alignItems: "baseline",

    gap: '46px',
  },
  Responsewrapper: {
    display: "flex",
    flexDirection: "column",
  },
}));

const data = [
  {
    id: 1,
    guestAccount: "Guest Account",
    name: "Grace Elizebeth ",
    location: "Etobicoke",
    rating: 4.5,
    date: "3 months ago",
    review:
      "Great first experience with this clinic! In a nice location and easy to find parking. My injector was very knowledgeable and took into account what my goals were during the consultation. We didn’t end up using all the filler product I bought and she offered to save it for another time. Will definitely be coming here again!",
    commentCount: 1,
    response: {
      author: "Response by Narmin",
      date: "3 months ago",
      message:
        "Thank you so much John, we look forward to seeing you soon! We are beyond happy you are satisfied with the staff and the treatment!",
    },
  },
  {
    id: 2,
    guestAccount: "Guest Account 2",
    name: "Jane Smith",
    location: "Mississauga",
    rating: 3.5,
    date: "6 months ago",
    review:
      "The staff was friendly but the service was not great. I had to wait for a long time even though I had an appointment. The results of the treatment were not as expected.",
    commentCount: 2,
    response: {
      author: "Response by Emily",
      date: "6 months ago",
      message:
        "We're sorry to hear that your experience did not meet your expectations, Jane. Please contact us to discuss how we can make it right.",
    },
  },
  {
    id: 3,
    guestAccount: "Guest Account 2",
    name: "Jane Smith",
    location: "Mississauga",
    rating: 3.5,
    date: "6 months ago",
    review:
      "The staff was friendly but the service was not great. I had to wait for a long time even though I had an appointment. The results of the treatment were not as expected.",
    commentCount: 2,
    response: {
      author: "Response by Emily",
      date: "6 months ago",
      message:
        "We're sorry to hear that your experience did not meet your expectations, Jane. Please contact us to discuss how we can make it right.",
    },
  },
  {
    id: 4,
    guestAccount: "Guest Account 2",
    name: "Jane Smith",
    location: "Mississauga",
    rating: 3.5,
    date: "6 months ago",
    review:
      "The staff was friendly but the service was not great. I had to wait for a long time even though I had an appointment. The results of the treatment were not as expected.",
    commentCount: 2,
    response: {
      author: "Response by Emily",
      date: "6 months ago",
      message:
        "We're sorry to hear that your experience did not meet your expectations, Jane. Please contact us to discuss how we can make it right.",
    },
  },
];

const CardReview = () => {
  const classes = useStyles();
  return (
    <>
      {data.map((item,index) => (
        <React.Fragment key={index}>
        <Grid container className={classes.outercontainer}>
          <Grid item lg={4} className={classes.innercontainer}>
            <div>
              <img src={client} alt={'images'} />
            </div>
            <div className={classes.iconTextWrapper}>
              <Typography
                variant="body1"
                style={{ color: "#2F80ED", marginBottom: "5px" }}
              >
                {item.guestAccount}
              </Typography>
              <Typography style={{ fontWeight:"700",fontFamily:'helvetica-lt-std-light'}}> {item.name} </Typography>
              <Typography style={{ fontSize: "14px", color: "#404040" }}>
                {item.location}
              </Typography>
            </div>
          </Grid>

          <Grid item lg={8} className={classes.ratingdiv}>
            <Box className={classes.review}>
              <div>
                {[...Array(Math.floor(item.rating))].map((_, i) => (
                  <AiFillStar
                    key={i}
                    style={{ color: "#F2994A", fontSize: "1.5rem" }}
                  />
                ))}
                {item.rating % 1 !== 0 && (
                  <BsStarHalf
                    style={{ color: "#F2994A", fontSize: "1.5rem" }}
                  />
                )}
              </div>
              <div>
                <Typography style={{ fontSize: "14px", color: "#404040" }}>
                  3 months ago
                </Typography>
              </div>
            </Box>
            <Box>
              <Typography style={{ fontsize: "18px",fontFamily:'helvetica-lt-std-light',fontWeight:'400' }}>
                Great first experience with this clinic! In a nice location and
                easy to find parking. My injector was very knowledgeable and
                took into account what my goals were during the consultation. We
                didn’t end up using all the filler product I bought and she
                offered to save it for another time. Will definitely be coming
                here again!
              </Typography>
            </Box>
            <Box className={classes.btn}>
              <Button
                variant="outlined"
                style={{ color: "#219653", borderColor: "#219653" }}
              >
                Replied
              </Button>
              <Button
                variant="outlined"
                style={{ color: "#000000", borderColor: "#000000" }}
              >
                Direct Message
              </Button>
            </Box>
            <Box className={classes.bottomdiv}>
              <div>
                <BsFillHandThumbsUpFill />
              </div>
              <div>
                <Typography>1</Typography>
              </div>
              <div>
                <Typography style={{ fontSize: "14px" }}>
                  Public Comment
                </Typography>
              </div>
              <div className={classes.Responsewrapper}>
                <div className={classes.Response}>
                  <Typography style={{ fontWeight: 300 }}>
                    Response by Narmin
                  </Typography>
                  <Typography style={{ fontSize: "14px", color: "#404040" }}>
                    3 months ago
                  </Typography>
                </div>
                <div>
                  <Typography style={{ fontSize: "14px" ,fontFamily:'helvetica-lt-std-light'}}>
                    Thank you so much Grace, we look forward to seeing you soon!
                    We are beyond happy you are satisfied with the staff and the
                    treatment!
                  </Typography>
                </div>
                <div className={classes.btn}>
                  <Typography style={{ color: "#4F4F4F", fontWeight: 700 }}>
                    Edit
                  </Typography>
                  <Typography style={{ color: "#4F4F4F", fontWeight: 700 }}>
                    Delete
                  </Typography>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

export default CardReview;
