import React from 'react';
import './styles.scss';
import {FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";

export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "11px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '11px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
}
export const labelStyle ={
    fontSize:"16px",color:'#27272e!important',letterSpacing:'.3px',textTransform:'capitalize'
}
const FormInput = ({name,label,isAuto,mb,disabled,placeholder,values,errors,touched,handleChange,multiline,rows,}) => {
    return (
        <FormControl fullWidth sx={{mb:mb || "16px"}}>
            <FormLabel sx={labelStyle}>{label}</FormLabel>
            <TextField
                fullWidth
                disabled={!!disabled}
                name={name}
                sx={inputStyle}
                inputProps={{
                    autoComplete:  isAuto? 'on' : 'new-password',
                    form: {
                        autoComplete:  isAuto? 'on' : 'off',
                    },
                }}
                multiline={!!multiline}
                minRows={rows}
                maxRows={rows}
                placeholder={placeholder}
                value={values[name]}
                onChange={handleChange}
                error={touched[name] && Boolean(errors[name])}
            />
            {errors[name] && touched[name] && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors[name]}</FormHelperText>}
        </FormControl>
    );
}

export default FormInput;