import React, { useState } from 'react';
import { Checkbox, Modal, Box, Grid, Typography, Button, FormControlLabel } from '@mui/material';
import CustomButtoncolor from '../custom-button/customColorButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 398,
  height:"200px",
  bgcolor: 'background.paper',
  p: '43px 12px',
  borderRadius: '6px',
  '@media (max-width: 480px)': {
    width: "95%",
  },
};
const wrapper = {
  alignItems: 'center',
};
const heading = {
  fontSize: '18px',
  fontFamily: 'helvetica-lt-std-roman',
  fontWeight: '400',
  textAlign: 'center',
  width: '100%',
};
const paragraph = {
  fontSize: '16px',
  fontFamily: 'helvetica-lt-std-roman',
  fontWeight: '400',
  textAlign: 'center',
  width: '100%',
  
};

const ConfirmationModal = ({ isOpen, onClose , handleClick,handleRowVisibility}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleModalClose = () => {
    onClose();
    setIsChecked(false);
  };

  const handleConfirmClick = () => {
    handleClick();
    handleModalClose();
    setIsChecked(false);
  };

  return (
      <>
        <FormControlLabel
            control={
              <Checkbox checked={isChecked} onChange={handleCheckboxChange} name="no" size="small"/>
            }
            label="Yes"
        />


        <Modal
            open={isChecked} // Use isChecked instead of isOpen
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container spacing={1} sx={wrapper}>
              <Grid item lg={12} style={{display: 'contents'}}>
                <Typography id="modal-modal-title" sx={heading}>
                  Confirmed Appointment
                </Typography>
              </Grid>
              <Grid item lg={12} sx={paragraph} style={{marginTop: "5px"}}>
                <Typography>Are you Sure you want to cofirmed ?</Typography>
              </Grid>
              <Grid item lg={12} style={{display: "flex", justifyContent: "center", marginTop: "17px"}} gap={'5px'}>
                <CustomButtoncolor
                    mainColor={"#333333"}
                    size="md"
                    val='No'
                    handleClick={handleModalClose}
                />
                <CustomButtoncolor
                    mainColor={"#333333"}
                    size="md"
                    val='Yes'
                    handleClick={handleConfirmClick}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </>
  );
}

export default ConfirmationModal;
