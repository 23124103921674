                import React, { useEffect } from 'react';
import RoutesList from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material/styles";
import { store } from "./store";
import "./index.scss";
import Pusher from "pusher-js";
import { Toaster } from "react-hot-toast";
import Theme from "./theme/theme";
export const scrollCSS = {
    "&::-webkit-scrollbar": {
      width: "10px",
      height: "10px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#fff"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      background: "rgba(0, 0, 0, 0.2)",
      boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
    }
  };
  
  


const App = () => {
    useEffect(() => {
        // Initialize Pusher
        const pusher = new Pusher('5e9dcc6e9cd79dc83685', { cluster: 'mt1' });

        // Subscribe to the specified channel
        const deploymentChannel  = pusher.subscribe('new.deployment');

        // Bind to the 'subscription succeeded' event
        deploymentChannel.bind('pusher:subscription_succeeded', () => {
            console.log('Successfully subscribed to the new.deployment channel.');
        });

        // Bind to the custom event
        deploymentChannel.bind("App\\Events\\NewDeploymentEvent", (data) => {
            console.log('NewDeploymentEvent received:', data);
            window.location.reload(); // Reload the browser upon receiving the event
        });
        // Subscribe to the 'remove.session' channel
        const sessionChannel = pusher.subscribe('remove.session');

        // Bind to the custom event on 'remove.session' channel
        sessionChannel.bind("App\\Events\\RemoveSessionEvent", (data) => {
            console.log('RemoveSessionEvent received:', data);

            // Perform logout or session removal logic here
            // For example, clearing local storage and redirecting to login
            localStorage.clear();
            window.location.href = '/login'; // Adjust as needed
        });

        // Cleanup function to unbind all events and unsubscribe from the channel when the component unmounts
        return () => {
            deploymentChannel.unbind_all();
            deploymentChannel.unsubscribe();
            sessionChannel.unbind_all();
            sessionChannel.unsubscribe();
        };
    }, []);
    return (
      <React.StrictMode>
        <Router>
            <Provider store={store}>
                <StyledEngineProvider injectFirst>
                    <Toaster
                        position={"top-right"}
                        toastOptions={{className: "react-hot-toast"}}
                    />
                    <Theme>
                        <RoutesList/>
                    </Theme>
                </StyledEngineProvider>
            </Provider>
        </Router>
        </React.StrictMode>
    );
};

export default App;
