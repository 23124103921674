import {Box, FormHelperText, TextField, Typography} from "@mui/material";
import * as Yup from "yup";
import React from "react";
import CustomButtonColor from "../../../components/custom-button/customColorButton";
import Payment from 'payment'
import {useFormik} from "formik";
import SubmitButton from "../../../components/custom-button/submitButton";
import {useDispatch} from "react-redux";
import { CreditCard, PaymentForm} from 'react-square-web-payments-sdk';
import {storeUserCreateCard} from "../../../store/user/user-cards";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  successConfirmation
} from "../../../services/utills";
import toast from "react-hot-toast";


const styles = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '450px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  gap:"20px",
  '@media (max-width:500px)':{
    width:'90%'
  },
  '@media (max-width:319px)':{
    p:'32px 5px',
    width:'95%'
  }

};

const AddUserCard = ({user,active,setLoading,setIsOpen,type,setAddPayment,handlePayment,cardStores}) => {
  // const [active, setActive] = useState(false)


  const dispatch = useDispatch()

  const validationSchema = Yup.object({
    number: Yup.string().required('Card number is required'),
    expirationDate: Yup.string().required('Expiry date is required'),
    cvv: Yup.string().required('CVC is required'),
  });

  const {
    values,
    handleSubmit,
    setFieldTouched,
    setFieldError,
    setFieldValue,
    resetForm,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      number: '',
      expirationDate: '',
      cvv: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (data, {}) => {

      setLoading(true);
      let res = await dispatch(storeUserCreateCard({...data, provider_id: user?.defaultProvider?.id}));

      if (res?.payload.success === 'true') {
        if(type === 'membership'){
         await handlePayment(res?.payload?.data)
        }else {
          toast.success(`Card Added Successfully`, {duration: 3000})
          await successConfirmation('Card Added Successfully')
        }
        handleClose()
        resetForm()
      }
      if (res?.payload?.errors) {
        let errors = res?.payload?.errors;

        if (errors?.number) {
          await setFieldTouched('number', true);
          setFieldError('number', "Invalid Card Number");
        }
        if (errors?.expirationDate) {
          await setFieldTouched('expirationDate', true);
          setFieldError('expirationDate', "Invalid expiry date");
        }
        if (errors?.cvv) {
          await setFieldTouched('cvv', true);
          setFieldError('cvv', "Invalid CVC");
        }
      }
      setLoading(false)
    },
  });

  const handleClose = () => {
    if (setIsOpen){
      setIsOpen(false);
    }
  };


  const handleInputChange = ({target}) => {
    const {name, value} = target
    if (name === 'number') {
      let res = formatCreditCardNumber(value, Payment)
      setFieldValue('number', res)
      // handleChange({target:{name:'number',value:res}})
    } else if (name === 'expirationDate') {
      let res = formatExpirationDate(value)
      setFieldValue('expirationDate', res)
    } else if (name === 'cvv') {
      let res = formatCVC(value, values?.number, Payment)
      setFieldValue('cvv', res)

    }
  }

  return (

      <Box sx={styles} >
        <Box style={{display: 'flex', justifyContent: "space-between"}}>
          <Typography variant="h6" component="h2">
            Setup Your Payment.
          </Typography>
          {/*<Box style={{alignItems: 'center'}}>*/}
          {/*  <img src={paymentLogoCard} width={'30%'} alt={"image"}/>*/}
          {/*  <img src={paymentLogo} alt={"image"}/>*/}
          {/*</Box>*/}
        </Box>
        <Typography sx={{mt: 2, mb: '30px'}}>
          Your membership starts as soon as you set up payment.
        </Typography>

        {active?.payment_gateway === 'Square' ?
            <Box>
              <PaymentForm
                  applicationId={active?.application_key}
                  locationId={active?.merchant_id}
                  cardTokenizeResponseReceived={async (token,) => {
                    let res = await dispatch(storeUserCreateCard({
                      "provider_id": active?.provider_id,
                      "nonce": token?.token
                    }));
                    if (res?.payload?.success === 'true') {

                      if(type === 'membership'){
                        await handlePayment(res?.payload?.data)
                      }else {

                        toast.success(`Card Added Successfully`, {duration: 3000})
                        handleClose()
                        await successConfirmation('Card Added Successfully')

                      }
                    }
                  }}
              >
                <CreditCard
                    buttonProps={{
                      css: {
                        marginTop: '15px',
                        fontSize: "14px",
                        backgroundColor: "#1AA2FF",
                        border: '1px solid #1AA2FF',
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#1AA2FF",
                        },
                        '@media (max-width:500px)':{
                          width:'100%'
                        }
                      }
                    }}
                >
                  Continue
                </CreditCard>
              </PaymentForm>
            </Box>
            :
            <form onSubmit={handleSubmit}>
              <Box>
                <TextField
                    value={values.number}
                    onChange={handleInputChange}
                    label="Card Number"
                    name="number"
                    variant="outlined"
                    placeholder='0000 0000 0000 0000'
                    sx={{width: '100%'}}
                />
                {errors.number && touched.number && <FormHelperText
                    sx={{color: '#CB3838', width: 'max-content', mx: 0}}>{errors.number}</FormHelperText>}
              </Box>
              <Box style={{display: "flex", justifyContent: "space-between", marginTop: '30px'}}>
                <Box>
                  <TextField
                      value={values?.expirationDate}
                      onChange={handleInputChange}
                      name="expirationDate"
                      variant="outlined"
                      placeholder='MM/YY'
                      inputProps={{maxLength: '5'}}
                      label="Expiry Date"
                      sx={{marginRight: "10px"}}
                  />
                  {errors.expirationDate && touched.expirationDate &&
                      <FormHelperText sx={{color: '#CB3838', width: 'max-content', mx: 0}}>
                        {errors.expirationDate}
                      </FormHelperText>
                  }
                </Box>
                <Box>
                  <TextField
                      value={values?.cvv}
                      onChange={handleInputChange}
                      name="cvv"
                      variant="outlined"
                      label="CVC"
                      sx={{marginRight: "10px"}}
                  />
                  {errors.cvv && touched.cvv &&
                      <FormHelperText sx={{color: '#CB3838', width: 'max-content', mx: 0}}>
                        {errors.cvv}
                      </FormHelperText>
                  }
                </Box>
              </Box>
              <Box style={{display: "flex", gap: '10px', marginTop: "30px"}}>
                <CustomButtonColor
                    size={"large"}
                    mainColor={"#666666"}
                    handleClick={()=>handleClose()}
                    style={{
                      width: "50%",
                      height: "50px",
                      mainColor: ""
                    }}
                    val={"cancel"}
                />
                <SubmitButton style={{width: "50%", height: "50px", mainColor: ""}} size={'large'} val={'Continue'}/>
              </Box>
            </form>
        }
       {type === "membership" && cardStores?.length > 0 ? (
  <Typography onClick={() => setAddPayment(true)} sx={{ cursor: "pointer", color: "#006AFF", textAlign: 'center', mt: '16px', "&:hover": { textDecoration: 'underline' } }}>
    Use Existing Cards
  </Typography>
) : null}

      </Box>
  );
};

export default AddUserCard;
