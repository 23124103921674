import React, {useEffect, useState} from 'react';
import { FormControl, Grid} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import CustomTable from "../../../components/table";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getAllPermission} from "../../../store/permissions";
import ConfigurationLayout from "../configurationLayout";
import Loader from "../../../components/loader/loader";
import SimpleInput from "../../../components/form/simpleInput";

const Permissions = () => {
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('')

    const permissionStore = useSelector(state => state?.permissionStore)

    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        (async () => {
            if (!permissionStore?.permissions?.length){
                setLoading(true)
            }
            await dispatch(getAllPermission())
            setLoading(false)
        })()
    }, [dispatch,permissionStore?.permissions?.length])
console.log("data",permissionStore)

    const handleClick = () => {
        navigate('/admin/configuration/add-permissions')
    }

    const roleHead = [
        {value: "Permission", key: 'name'},
        {value: "Menu", key: 'menu_name'},
        {value: "Status", key: 'status'},
        {value: "Action", key: 'action'}
    ]

    const handleEdit = (row, isEdit) => {
        if (isEdit) {
            navigate(`/admin/configuration/edit-permissions/${row?.id}`)
        }
    }

    return (
        <ConfigurationLayout>
            <Grid container p={'20px'}>
                <Grid item xs={12} sx={{
                    mb: '16px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    flexWrap:'wrap',
                    '@media (max-width: 450px)' :{
                        display:'flex',
                        justifyContent:'flex-start'
                       },
                }}>
                    <FormControl sx={{mr: "10px"}}>
                       <SimpleInput
                           search={search}
                           setSearch={setSearch}
                       />
                    </FormControl>
                    <CustomButton val={'Add Permission'} mrt={"10px"} size={'large'} handleClick={handleClick}/>
                </Grid>
                <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                    {loading ?<Loader/> : <CustomTable
                        headRow={roleHead}
                        tableData={permissionStore?.permissions}
                        search={search}
                        isStatus={true}
                        type={"permission"}
                        handleEdit={handleEdit}
                    />}
                </Grid>
            </Grid>
        </ConfigurationLayout>
    );
};

export default Permissions;