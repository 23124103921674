import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {Box, Grid,  useMediaQuery} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import {useDispatch,  } from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";


import ConfigurationLayout from "../configurationLayout";
import Loader from "../../../components/loader/loader";
import FormInput from "../../../components/form/formInput";
import SubmitButton from "../../../components/custom-button/submitButton";
import {useTheme} from "@mui/material/styles"
import {getMenuByID, storeMenu, updateMenu} from "../../../store/menus";
import FormStatusInput from "../../../components/form/FormStatusInput";

const AddMenus = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const theme = useTheme()


    const hiddenLG = useMediaQuery(theme.breakpoints.down('lg'))

    const validationSchema = yup.object({
        name: yup.string().min(3, "Name is Too Short").required('Role name is required'),
        key: yup.string().min(2, "Key is Too Short").required('Key is required'),
        icon: yup.string().min(3, "Icon Name is Too Short").required('Icon Name is required'),
        status: yup.string().required('Status is required'),
        url: yup.string()
            .matches( 
                /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
                'Enter correct url!'
            ).required('URL is Required'),
    });


    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            name: '',
            key:'',
            icon:'',
            status: 'In-Active',
            url: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)

            let res
            if (params?.menu_id) {
                res = await dispatch(updateMenu(data))
            } else {
                res = await dispatch(storeMenu(data))
            }    

            if (res?.payload?.errors) {
                const {errors} = res?.payload   
                console.log(errors)
                if (errors?.name) {
                    setFieldTouched("name", true)
                    setFieldError("name", errors?.name)
                }

            } else if (res?.payload?.success === "true") {
                toast.success(`Menu ${params?.menu_id ? "updated" :"stored"} successfully`, {duration: 3000})
                navigate('/admin/configuration/menus')
            }

            setLoading(false)
        },
    });



    useEffect(() => {
        (async () => {
            if (params?.menu_id) {
                setLoading(true) 
                let res = await dispatch(getMenuByID(params?.menu_id))
                if (res?.payload?.data?.id) {  
                    await setFieldValue("name", res?.payload?.data?.name)
                    await setFieldValue('status', res?.payload?.data?.status || 'In-Active')
                    await setFieldValue('key', res?.payload?.data?.key || '')
                    await setFieldValue('url', res?.payload?.data?.url || '')
                    await setFieldValue('icon', res?.payload?.data?.icon || '')
                    await setFieldValue('id', res?.payload?.data?.id)
                }
                setLoading(false)
            }

        })()
    }, [params?.menu_id])

    return (
        <ConfigurationLayout>
            <Box sx={{height: "calc(100vh - 125px)", backgroundColor:"white"}}>
                {loading ? <Loader/> :
                    <form onSubmit={handleSubmit}>
                        <Grid  container p={3} sx={{justifyContent:'space-between',width: hiddenLG ? "100%" :'70%'}}>
                            <Grid item xs={12} md={5}>
                                <FormInput
                                    name={"name"}
                                    label={"Name"}
                                    placeholder={'Type  menu name'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <FormInput
                                    name={"key"}
                                    label={"Key"}
                                    placeholder={'Type  menu key'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <FormInput
                                    name={"url"}
                                    label={"URL"}
                                    placeholder={'Type  menu url'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <FormInput
                                    name={"icon"}
                                    label={"Icon"}
                                    placeholder={'Type  icon'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <FormStatusInput
                                    name={"status"}
                                    errors={errors}
                                    label={"Status"}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    handleChange={handleChange}
                                />

                            </Grid>

                            <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                <Box sx={{mr: '16px'}}>
                                    <CustomButton
                                        mainColor={'#ED1E45'}
                                        val={'Cancel'}
                                        handleClick={() => {
                                            navigate('/admin/configuration/menus')
                                        }}
                                    />
                                </Box>
                                <SubmitButton
                                    val={params?.menu_id ?"Update Menu" :"Add Menu"}
                                />
                            </Grid>
                        </Grid>
                    </form>
                }
            </Box>
        </ConfigurationLayout>
    );
};

export default AddMenus;