import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {Box, Grid,} from "@mui/material";
import CustomButton from "../../../components/custom-button/customButton";
import ConfigurationLayout from "../configurationLayout";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import Loader from "../../../components/loader/loader";
import SubmitButton from "../../../components/custom-button/submitButton";
import FormInput from "../../../components/form/formInput";
import * as yup from "yup";
import {useFormik} from "formik";
import {getLanguageByID, storeLanguages, updateLanguages} from "../../../store/languages";
import FormStatusInput from "../../../components/form/FormStatusInput";

const AddLanguages = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()


    const validationSchema = yup.object({
        name: yup.string().min(3,"Name is Too Short").required('Role name is required'),
        status: yup.string().required('Status is required'),
    });


    const {values,handleSubmit,setFieldValue,handleChange,touched,errors} = useFormik({
        initialValues: {
            name: '',
            status: 'In-Active',
        },
        validationSchema: validationSchema,
        onSubmit: async (data,{setFieldTouched,setFieldError}) => {
            setLoading(true)

            let res
            if (params?.languages_id){
                res = await dispatch(updateLanguages(data))
            }else {
                res = await dispatch(storeLanguages(data))
            }
            if (res?.payload?.errors) {
                const {errors} = res?.payload
                console.log(errors)
                if (errors?.name) {
                    setFieldTouched("name", true)
                    setFieldError("name", errors?.name)
                }

            } else if (res?.payload?.success === "true") {
                toast.success(`Languages ${params?.languages_id ?"updated" :"Stored"} Successfully`, {duration: 3000})
                navigate('/admin/configuration/languages')
            }

            setLoading(false)
        },
    });


    useEffect(()=>{
        (async  ()=>{
            if (params?.languages_id){
                setLoading(true)
                let res =await  dispatch(getLanguageByID(params?.languages_id))
                if (res?.payload?.data?.id){
                    await setFieldValue("name", res?.payload?.data?.name)
                    await setFieldValue('status', res?.payload?.data?.status || 'In-Active')
                    await setFieldValue('id', res?.payload?.data?.id)
                }
                setLoading(false)
            }

        })()
    },[params?.languages_id])
    return (
        <ConfigurationLayout>
            <Box sx={{height: "calc(100vh - 125px)", backgroundColor:"white"}}>
                {loading ?  <Box
        sx={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            height: "100vh" 
        }}
    >
        <Loader />
    </Box> :
                    <Grid container p={3}>
                        <Grid item xs={12} sm={6} lg={6}>
                            <form onSubmit={handleSubmit}>
                                <FormInput
                                    name={"name"}
                                    label={"Name"}
                                    placeholder={'Type  language name'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />

                                <FormStatusInput
                                    name={"status"}
                                    errors={errors}
                                    label={"Status"}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    handleChange={handleChange}
                                />

                                <Box sx={{display:'flex',alignItems:'center'}}>
                                    <Box sx={{mr:'16px'}}>
                                        <CustomButton
                                            mainColor={'#ED1E45'}
                                            val={'Cancel'}
                                            handleClick={()=>{navigate('/admin/configuration/languages')}}
                                        />
                                    </Box>
                                    <SubmitButton val={`${params?.languages_id ? "Update":"Add"} Language`}/>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                }
            </Box>
        </ConfigurationLayout>
    );
};

export default AddLanguages;