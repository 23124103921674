import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import {apiHeaders} from "../../../../lib/apiHeader";
import apis from "../../../../lib/apis/apis";
import {getClinicByID} from "../../index";

export const getClinicServiceTypes = createAsyncThunk("getClinicServiceTypes", async (data,  ) => {
        try {
            const response = await axios.get(apis.get_service_type, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }
            if (response?.data?.success === false) {
                console.log(response)
                return {};
            }
        } catch (err) {
            if (err?.response?.status !== 200) {
                return {
                    data: [],
                    errors: err?.response?.data?.errors,
                };
            }
        }
    }
);
export const storeClinicServiceType = createAsyncThunk('storeClinicServiceType', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_clinics_service_type, {...data},{
            headers: apiHeaders()
        })
        if (response?.data?.errors) {
            console.log( response?.data?.errors)

        } else if (response?.data?.success === "true") {
            await dispatch(getClinicByID(data?.provider_id))
            toast.success(` Services Types Updated Successfully`, {duration: 3000})
        }

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }

    }
})


export const clinicServiceTypes = createSlice({
    name: "clinicServiceTypes",
    initialState: {
        servicesTypes: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getClinicServiceTypes.fulfilled, (state, action) => {
            state.servicesTypes = action.payload ||[] ;

        });
    },
});

export default clinicServiceTypes.reducer;
