import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {IconButton, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import moment from 'moment'
import {getCrmReminder} from "../../../../../store/crm/crm/crm-details";

const CrmReminder = () => {
    const [active, setActive] = useState(null)


    const crmDetailsStore = useSelector(state => state?.crmDetailsStore)
    const {reminders = []} = crmDetailsStore

    const dispatch = useDispatch()
    const {user_id} = useParams()
console.log(" remainders",reminders)

    useEffect(() => {
        (async () => {
            await dispatch(getCrmReminder(user_id))
        })()
    }, [])

    return (
        <Box sx={{background: "#fff", minHeight: '100px', borderRadius: "8px",p:{sm:'24px', xs:'8px'}}}>
            <Typography sx={{
                fontFamily: 'helvetica-lt-std-bold',
                fontSize: '20px',
                fontStyle: "normal",
                fontWeight: 700,
            }}>
                Upcoming Reminders
            </Typography>
            <Box>
            <Typography style={{marginTop:"20px",fontWeight:600,fontFamily: 'helvetica-lt-std-bold'}}>Crm </Typography>
            {reminders?.data?.length > 0 ?
                    reminders?.data.map((reminder,index)=>(
                        <Paper key={index} sx={{ mt: '20px',display: 'flex', width: '100%', py: '24px', pr: "14px", pl: '24px'}}>
                            <Box>
                                <NotificationsIcon sx={{color: active === reminder?.id ? '#09ABF1' : '#828282', fontSize: '30px'}}/>
                            </Box>
                            <Box sx={{flexGrow: '1', ml: '24px'}}>
                                <Typography sx={{fontSize: '16px', color: '#000'}}>
                                    Reminder on {moment(reminder?.date).format('MMMM D, YYYY')}
                                </Typography>
                                <Typography sx={{fontFamily: "HelveticaLTStd-Obl", color: '#828282', fontSize: "14px"}}>
                                    Created by {reminder?.staff?.name} on {moment(reminder?.created_at).format('MMMM D, YYYY')}
                                </Typography>
                                {reminder?.id === active && <Box sx={{mt: '24px'}}>
                                    <Typography>
                                        Notes: {reminder?.note}
                                    </Typography>
                                </Box>}
                            </Box>
                            <Box>
                                <IconButton sx={{mt:'-15px'}} onClick={()=>setActive(active === null || active !== reminder?.id ? reminder?.id :  null)}>
                                    {reminder?.id === active ?
                                        <ArrowDropUpRoundedIcon sx={{color: active === reminder?.id ? '#09ABF1' : '#828282', fontSize: '35px'}}/>
                                        :
                                    <ArrowDropDownRoundedIcon sx={{color: active === reminder?.id? '#09ABF1' : '#828282', fontSize: '35px'}}/>
                                    }
                                </IconButton>
                            </Box>
                        </Paper>
                    ))
                    : "none"
                } 
 <Typography style={{marginTop:"20px",fontWeight:600,fontFamily: 'helvetica-lt-std-bold'}}>Appointment</Typography>
                  {reminders?.appointment?.length > 0 ?
                    reminders?.appointment.map((reminder,index)=>(
                        <Paper key={index} sx={{ mt: '20px',display: 'flex', width: '100%', py: '24px', pr: "14px", pl: '24px'}}>
                           
                            <Box>
                                <NotificationsIcon sx={{color: active === reminder?.id ? '#09ABF1' : '#828282', fontSize: '30px'}}/>
                            </Box>
                            <Box sx={{flexGrow: '1', ml: '24px'}}>
                                <Typography sx={{fontSize: '16px', color: '#000'}}>
                                    Reminder on {moment(reminder?.date).format('MMMM D, YYYY')}
                                </Typography>
                                <Typography sx={{fontFamily: "HelveticaLTStd-Obl", color: '#828282', fontSize: "14px"}}>
                                    Created by {reminder?.staff?.name} on {moment(reminder?.created_at).format('MMMM D, YYYY')}
                                </Typography>
                                {reminder?.id === active && <Box sx={{mt: '24px'}}>
                                    <Typography>
                                        Notes: {reminder?.note}
                                    </Typography>
                                </Box>}
                            </Box>
                            <Box>
                                <IconButton sx={{mt:'-15px'}} onClick={()=>setActive(active === null || active !== reminder?.id ? reminder?.id :  null)}>
                                    {reminder?.id === active ?
                                        <ArrowDropUpRoundedIcon sx={{color: active === reminder?.id ? '#09ABF1' : '#828282', fontSize: '35px'}}/>
                                        :
                                    <ArrowDropDownRoundedIcon sx={{color: active === reminder?.id? '#09ABF1' : '#828282', fontSize: '35px'}}/>
                                    }
                                </IconButton>
                            </Box>
                        </Paper>
                    ))
                    : "none"
                }
            </Box>
        </Box>
    );
}

export default CrmReminder;