import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import { apiHeaders } from "../../../lib/apiHeader";


export const getAllDashboardDetails = createAsyncThunk(
  "getAllDashboardDetails",
  async (params) => {
    try {
      const { start_date, end_date } = params;
      const response = await axios.get(`${apis.crm_dashboard}?start_date=${start_date}&end_date=${end_date}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);
export const getMemberOverview = createAsyncThunk(
  "getMemberOverview",
  async (params) => {
    try {
      const { start_date, end_date } = params;
      const response = await axios.get(`${apis.crm_Memberoverview}?start_date=${start_date}&end_date=${end_date}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);
// 
export const getMemberReportsDashboard = createAsyncThunk(
  "getMemberReportsDashboard",
  async (params) => {
    try {
      const { start_date, end_date } = params;
      const response = await axios.get(`${apis.Weekly_reports_dashboard}?start_date=${start_date}&end_date=${end_date}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);
export const getMemberReportsMonthly = createAsyncThunk(
  "getMemberReportsMonthly",
  async (params) => {
    try {
      const { year, month} = params;
      const response = await axios.get(`${apis.Monthly_reports_dashboard}?year=${year}&month=${month}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);
export const DailyMemberReportsDashboard = createAsyncThunk( "DailyMemberReportsDashboard",
  async (date) => {
    try {
      const response = await axios.get(`${apis.Daily_reports_dashboard}?date=${date}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  } 
);


export const salesHourReportDashboard = createAsyncThunk(   
  "salesHourReportDashboard",
  async (date) => {
    try {
      const response = await axios.get(`${apis.salesHour_reports_dashboard}?date=${date}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);
// 
export const EventReportDashboard = createAsyncThunk(   
  "EventReportDashboard",
  async (date) => {
    try {
      const response = await axios.get(apis.event_reports, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

export const getWeeklyHours = createAsyncThunk(
  "getWeeklyHours",
  async (params) => {
    try {
      const { start_date, end_date } = params;
      const response = await axios.get(`${apis.salesHour_reports_Weekly}?start_date=${start_date}&end_date=${end_date}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

// 
export const getSalesMonthly = createAsyncThunk(
  "getSalesMonthly",
  async (params) => {
    try {
      const { year, month} = params;
      const response = await axios.get(`${apis.salesHour_Monthly}?year=${year}&month=${month}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }   
);

export const monthlyInsightReportDashboard = createAsyncThunk(   
  "monthlyInsightReportDashboard",      
  async (params) => {
    try { 
      const { year, month} = params;
      const response = await axios.get(`${apis.monthlyinsight_report_dashboard}?year=${year}&month=${month}`, {
        headers: apiHeaders(),
      });    
         
      if (response?.data?.success === "true") {        
        return response?.data?.data;
      } else {   
        return [];      
      }
    } catch (err) {   
      return [];  
    }   
  }
);
export const AllDashboard = createAsyncThunk(
  "AllDashboard",
  async () => {
    try {
      const response = await axios.get(apis.All_Dashboard, {
        headers: apiHeaders(),      
      });   

      if (response?.data?.success === "true") {       
        return response?.data?.data;   
      } else {
        return [];
      }    
    } catch (err) {
      return [];         
    }
  }
);

export const  categoryReportDashboard = createAsyncThunk(" categoryReportDashboard",
  async (params) => {
    try {
      const { start_date, end_date } = params;
      const response = await axios.get(`${apis.category_report_dashboard}?start_date=${start_date}&end_date=${end_date}`, {
        headers: apiHeaders(),  
      });

      if (response?.data?.success === "true") {
        return response?.data; 
      } else {  
        return [];   
      }  
    } catch (err) {
      return [];
    }
  }
);

export const  categoryReport = createAsyncThunk(" categoryReport",
  async (params) => {
    try {  
      const { start_date, end_date } = params;
      const response = await axios.get(`${apis.category_report_dashboard}?start_date=${start_date}&end_date=${end_date}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data; 
      } else {   
        return [];
      }
    } catch (err) {  
      return [];
    }
  }
);








// 

export const consultatinDashboard = createAsyncThunk(
  "consultatinDashboard",
  async (date) => {
    try {
      const response = await axios.get(`${apis. Daily_consultation}?date=${date}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);
export const weeklyconsultation = createAsyncThunk(
  "weeklyconsultation",
  async (params) => {
    try {
      const { start_date, end_date } = params;
      const response = await axios.get(`${apis.weekly_consultation}?start_date=${start_date}&end_date=${end_date}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

export const monthlyconsultation = createAsyncThunk(
  "monthlyconsultation",
  async (params) => {
    try {
      const { year, month} = params;
      const response = await axios.get(`${apis.Monthly_consultation}?year=${year}&month=${month}`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
);

export const crmDashboardSlice = createSlice({
  name: "crmDashboardSlice",
  initialState: {
    dashboard: [],
    dashboardMember:[],
    members_report: undefined,
    members_report_Daily: undefined,
    members_report_Monthly: undefined,
    sale_hour_report: undefined,
    monthly_insight: undefined,
    category_report: {},
    consultatio_report: undefined,
    consultatio_report_weekly: undefined,
    consultatio_report_monthly: undefined,
    loading: false,
    sale_hour_report_Monthly:undefined,
    members_report_loading: false,
    members_report_Daily_loading: false,
    members_report_Monthly_loading: false,
    sale_hour_report_loading: false,
    monthly_insight_loading: false,
    category_report_loading: false,
    consultatio_report_loading: false,
    week_hour_report:false, 
    alldashboard:[],
    Event_report:[],
    Event_report_loading:false,
    category_report_dashboard :{}

  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDashboardDetails.fulfilled, (state, action) => {
        state.dashboard = action.payload;
        state.loading = false;
      })
      .addCase(getAllDashboardDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllDashboardDetails.rejected, (state, action) => {
        state.loading = false;
      })



      .addCase(EventReportDashboard.fulfilled , (state, action) => { 
        state.Event_report = action.payload;
        state.Event_report_loading = false;  
      })
      .addCase( EventReportDashboard.pending, (state, action) => {
        state.Event_report_loading = true;
      })
      .addCase(EventReportDashboard.rejected, (state, action) => {
        state.Event_report_loading = false; 
      })

.addCase(AllDashboard.fulfilled, (state, action) => {
  state.alldashboard = action.payload;
  state.loading = false;
})
.addCase(AllDashboard.pending, (state, action) => {
  state.loading = true;
})
.addCase(AllDashboard.rejected, (state, action) => {
  state.loading = false;
})

.addCase(getMemberOverview.fulfilled, (state, action) => {
  state.dashboardMember = action.payload;
  state.loading = false;
})
.addCase(getMemberOverview.pending, (state, action) => {
  state.loading = true;
})
.addCase(getMemberOverview.rejected, (state, action) => {
  state.loading = false;
})
      
      .addCase(DailyMemberReportsDashboard.fulfilled, (state, action) => {
        state.members_report_Daily = action.payload;
        state.members_report_Daily_loading = false;
      })
      .addCase(DailyMemberReportsDashboard.pending, (state, action) => {
        state.members_report_Daily_loading = true;
      })
      .addCase(DailyMemberReportsDashboard.rejected, (state, action) => {
        state.members_report_Daily_loading = false;
      })
      // getMemberReportsMonthly  
      .addCase(getMemberReportsMonthly.fulfilled, (state, action) => {
        state.members_report_Monthly = action.payload;
        state.members_report_loading = false;
      })
      .addCase(getMemberReportsMonthly.pending, (state, action) => {  
        state.members_report_loading = true;
      })
      .addCase(getMemberReportsMonthly.rejected, (state, action) => {
        state.members_report_loading = false;
      })
      // 

      .addCase(getMemberReportsDashboard.fulfilled, (state, action) => {
        state.members_report = action.payload;
        state.members_report_loading = false;
      })  
      .addCase(getMemberReportsDashboard.pending, (state, action) => {
        state.members_report_loading = true;
      })
      .addCase(getMemberReportsDashboard.rejected, (state, action) => {
        state.members_report_loading = false; 
      })
      .addCase(salesHourReportDashboard.fulfilled, (state, action) => {
        state.sale_hour_report = action.payload;
        state.sale_hour_report_loading = false;
      })
      .addCase(salesHourReportDashboard.pending, (state, action) => {
        state.sale_hour_report_loading = true; 
      })
      .addCase(salesHourReportDashboard.rejected, (state, action) => {
        state.sale_hour_report_loading = false;
      })  
      //    
     


      // 
      .addCase(getSalesMonthly.fulfilled, (state, action) => {
        state.sale_hour_report_Monthly = action.payload;
        state.sale_hour_report_loading = false;
      })
      .addCase(getSalesMonthly.pending, (state, action) => {
        state.sale_hour_report_loading = true;
      })
      .addCase(getSalesMonthly.rejected, (state, action) => {
        state.sale_hour_report_loading = false;  
      })

      // 
      .addCase(getWeeklyHours.fulfilled, (state, action) => {
        state.week_hour_report = action.payload;
        state.sale_hour_report_loading = false;
      })
      .addCase(getWeeklyHours.pending, (state, action) => {
        state.sale_hour_report_loading = true;
      })
      .addCase(getWeeklyHours.rejected, (state, action) => {
        state.sale_hour_report_loading = false;
      })

// 
      .addCase(monthlyInsightReportDashboard.fulfilled, (state, action) => {
        state.monthly_insight = action.payload;
        state.monthly_insight_loading = false;
      })
      .addCase(monthlyInsightReportDashboard.pending, (state, action) => {
        state.monthly_insight_loading = true;
      })
      .addCase(monthlyInsightReportDashboard.rejected, (state, action) => {
        state.monthly_insight_loading = false;
      }) 
      // issue
      .addCase(categoryReportDashboard.fulfilled, (state, action) => {
        state.category_report = action.payload;
        state.category_report_loading = false;
      })


      .addCase(categoryReportDashboard.pending, (state, action) => {
        state.category_report_loading = true;
      })
      .addCase(categoryReportDashboard.rejected, (state, action) => {
        state.category_report_loading = false;
      })

      .addCase(weeklyconsultation.fulfilled, (state, action) => {
        state.consultatio_report_weekly = action.payload;
        state.consultatio_report_loading = false;
      })
      .addCase(weeklyconsultation.pending, (state, action) => {
        state.consultatio_report_loading = true;
      })
      .addCase(weeklyconsultation.rejected, (state, action) => {
        state.consultatio_report_loading = false;
      })
      .addCase(categoryReport.fulfilled, (state, action) => {
        state.category_report_dashboard = action.payload;
        state.category_report_loading = false;
      })


      .addCase(categoryReport.pending, (state, action) => {
        state.category_report_loading = true;
      })
      .addCase(categoryReport.rejected, (state, action) => {
        state.category_report_loading = false;
      })


      // 
      .addCase(consultatinDashboard.fulfilled, (state, action) => {
        state.consultatio_report = action.payload;
        state.consultatio_report_loading = false;
      })
      .addCase(consultatinDashboard.pending, (state, action) => {
        state.consultatio_report_loading = true;
      })  
      .addCase(consultatinDashboard.rejected, (state, action) => {
        state.consultatio_report_loading = false;
      })

      .addCase(monthlyconsultation.fulfilled , (state, action) => { 
        state.consultatio_report_monthly = action.payload;
        state.consultatio_report_loading = false;  
      })
      .addCase( monthlyconsultation.pending, (state, action) => {
        state.consultatio_report_loading = true;
      })
      .addCase(monthlyconsultation.rejected, (state, action) => {
        state.consultatio_report_loading = false;
      });
      // EventReportDashboard
    
  },
});

export default crmDashboardSlice.reducer;
