import React, { Component } from 'react';
import {Map, Marker, GoogleApiWrapper, InfoWindow} from 'google-maps-react';
import {GOOGLE_API} from "../../../../config";
import {Box} from "@mui/material";
import PlacesAutoComplete from "./palcesAutoComplete";

class MapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {
                name:'3D Lifestyle Medical Aesthetics & Wellness Spa Etobicoke'
            },
            address: '',
            mapCenter: {lat: 43.6008386, lng: -79.50371489999999}
        };
    }

    handleChange = address => {
        this.setState({address});
    };

    handleSelect = (latLng,address) => {
        this.setState({
            address,
            mapCenter: latLng,
            selectedPlace: {name: address, position: latLng}
        })
    };

    onMarkerEnter = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };
    onMarkerLeave = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: false
        });
    };

    onMapClicked = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    render() {
        const {google} = this.props;
        const {address, selectedPlace, mapCenter} = this.state;

        return (
            <Box
                sx={{height:'calc(100vh  - 200px)'}}
            >
                <Box sx={{height:'330px'}}>
                    <Box style={{width: "100%", position: 'relative'}}>
                        <Map
                            google={google}
                            zoom={14}
                            onClick={this.onMapClicked}
                            style={{
                                width: '100%', height: '300px', "div": {
                                    position: 'relative'
                                }
                            }}
                            center={mapCenter}
                            initialCenter={mapCenter}
                        >
                            <Marker
                                position={selectedPlace.position}
                                onMouseover={this.onMarkerEnter}
                                onMouseout={this.onMarkerLeave}
                                name={selectedPlace.name}
                            />
                            <InfoWindow
                                marker={this.state.activeMarker}
                                visible={this.state.showingInfoWindow}
                            >
                                <div>
                                    <h3>{selectedPlace.name}</h3>
                                </div>
                            </InfoWindow>
                        </Map>
                    </Box>

                </Box>
                <Box>
                    <PlacesAutoComplete handleLocationSelect={this.handleSelect}/>
                </Box>
            </Box>
        );
    }
}

export default GoogleApiWrapper({
    apiKey:GOOGLE_API
})(MapContainer)
