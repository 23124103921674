import React, { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { Button, FormControl, Grid } from "@mui/material";
import CardReview from "../../components/card-review/CardReview";
import SearchField from "../../components/form/SearchField";
import SearchFieldGoogle from "../../components/form/SearchFieldGoogle";

const Review = () => {
  // const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("");
  return (
    <>
      {/* <ConfigurationLayout> */}
      <Grid container p={"20px"}>
        <Grid
          item
          xs={12}
          sx={{
            mb: "16px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <FormControl sx={{ mr: "4px", width: "10%" }}>
            <SearchFieldGoogle search={search} setSearch={setSearch} />
          </FormControl>
          <FormControl sx={{ mr: "10px" }}>
            <SearchField search={search} setSearch={setSearch} />
          </FormControl>
          <Button
            size="lg"
            variant="outlined"
            style={{
              backgroundColor: "#FFFFFF",
              borderColor: "#333333",
              color: "#333333",
              height: "88%",
            }}
          >
            <AiOutlineMenu />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CardReview />
        </Grid>
      </Grid>
      {/* </ConfigurationLayout> */}
    </>
  );
};

export default Review;
