import { Box, Typography, Grid, TableCell, TablePagination, TableRow, TableBody, TableHead, Table, TableContainer, Paper, Button, TableFooter } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TotalMemberNamount from './totalMemberNamount';
import useStyles from "./style";
import { scrollCSS } from "../../App";
import DateRange from '../date-range/date-range';
import { useNavigate } from 'react-router';
import moment from 'moment';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const CategorySale = ({ category,fetchData }) => {

  const [localStartDate, setLocalStartDate] = useState(new Date());
  const [localEndDate, setLocalEndDate] = useState(new Date());

  const navigate = useNavigate();

  const setCurrentMonthDates = () => {
    const now = new Date();
    const startOfWeek = moment().startOf('isoWeek').toDate();
    const endOfWeek = moment().endOf('isoWeek').toDate();
    setLocalStartDate(startOfWeek);
    setLocalEndDate(endOfWeek );   
  };    

  useEffect(() => { 
    setCurrentMonthDates();
  }, []);
  
  const handleDateChange =  async (start, end) => {
    setLocalStartDate(start);
    setLocalEndDate(end);
    const response = await fetchData(start,end);
   
  };
 
 
console.log("data",category?.data)
  const classes = useStyles();  
     
  console.log("data fetch",category)
  const handleCellClick = (itemId) => {
    navigate("/crm/category-sale-report", {  
      state: { value: itemId, startDate: localStartDate, endDate: localEndDate, valuestatus:"CheckOut" },
    })   
};   
  
  return (
    <Box sx={{ padding: "0px 10px", mt: 2 }}>  
      <Box sx={{ position: 'relative', borderRadius: "8px", }}>
        {/* Table */}
        <Typography variant="h5" sx={{ fontFamily: 'helvetica-lt-std-bold', padding: '20px 20px' }}>
          Category Sale 
        </Typography> 
        <Box style={{ display:"flex", justifyContent: "space-between" ,alignItems:"baseline"}}>

       
    

<Box     
sx={{
   minWidth: { xs: '50%', sm: '220px' },  
    background:"#dee2e6",  
    height:"20px",   
    marginRight:"15px",  }}>
<DateRange   
  color="#EDF2F7"
setStartDate={setLocalStartDate}
setEndDate={setLocalEndDate}
startDate={localStartDate}
endDate={localEndDate}    
onChange={handleDateChange}   
  
/></Box>  
<Box style={{marginTop:"40px"}}>   
{/* <Button  variant="contained" onClick={ handleButtonClick} >
Fetch Report
</Button> */}
</Box>  
{/*  tabel */}   
     </Box>  
     <TableContainer      
      component={Paper}      
       sx={{ mt: 5, maxHeight: "650px",  }}    
                >
                  <Table sx={{'@media (max-width: 370px)' :{ marginTop:"20px"},}} aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: "700",
                            borderBottom: 0,
                          }}
                        >
                          Category Name
                        </TableCell>
                        <TableCell  
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}  
                >
                Revenue Earned
                </TableCell>
             
                <TableCell
                  align="left"
                  sx={{       
                    fontWeight: "700",
                    borderBottom: 0,
                  }}
                >
 Revenue Earned Count  
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                  Forecast  
                </TableCell>
                <TableCell
                  align="left"
                  sx={{       
                    fontWeight: "700",
                    borderBottom: 0,     
                  }}
                >
         Forcasted Count
                </TableCell>   

                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                Percentage
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
         Revenue Trend 
                </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                       Array.isArray(category?.data) ? (
                        category.data.map((item, index) => (
                            <TableRow
                              key={index}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              className={classes.odd}
                              onClick={() => handleCellClick(item?.id)}
                            >
                              <TableCell
                                component="th"
                                scope="row"     
                                className={classes.tableLink}
                                sx={{ cursor: 'pointer' }}
                              >
                                {item.title ?? "N/A"}
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                  }}
                                >
                                $  {item.total_revenue_earned?? "N/A"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                  }}
                                >
                                  {item.revenue_earned_count?? "N/A"}
                                </Typography>
                              </TableCell>   
                              <TableCell align="left">
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                  }}
                                >
                                 $ {item.forecast ?? "N/A"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                  }}
                                >
                                  {item.forecast_count ?? "N/A"}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">   
                                <Typography variant="body1" color="initial">
                                  {item.percentage_revenue_earned ??"N/A"}%
                                </Typography>  
                              </TableCell>   
                              <TableCell align="left">
    {item.revenue_trend && item.revenue_trend !== "N/A" ? (
    < Box style={{display:"flex"}}>
      <Box style={{display:"flex"}}>
      {"$"} 
      {item.revenue_trend}
     
      </Box> 
     
      {item.revenue_trend > 0 ? (
        <ArrowUpwardIcon style={{ color: 'green' }}/>
      ) : (    
        <ArrowDownwardIcon style={{ color: 'red' }} />
      )}
    </Box>
  ) : "N/A" /* Only show N/A if there is no trend data or it's exactly "N/A" */}
</TableCell>



                            </TableRow>
                          )) ) : (
                            <TableRow>
                              <TableCell
                                colSpan={7}
                                align="center"
                                sx={{ height: '300px' }}
                              >
                                Category data not found
                              </TableCell>
                            </TableRow>
                          )            
                       }

  </TableBody>

  <TableFooter className={classes.tableHead} sx={{
      position: "sticky",
      bottom: 0,  
    
    }}>
      <TableRow sx={{}}>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 ,fontSize:'15px',fontFamily: 'helvetica-lt-std-bold'}}>
          Total    
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 ,fontSize:'15px',fontFamily: 'helvetica-lt-std-bold'}}>
          
     $   {category?.totals?.total_revenue_earned_sum}
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 ,fontSize:'15px',fontFamily: 'helvetica-lt-std-bold'}}>   
        {category?.totals?.total_revenue_earned_count}  
      
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 ,fontSize:'15px',fontFamily: 'helvetica-lt-std-bold'}}> 
       $ {category?.totals?.total_forecast_sum}
       
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 ,fontSize:'15px',fontFamily: 'helvetica-lt-std-bold'}}>
        {category?.totals?.total_forecast_count}  
        </TableCell>

        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 ,fontSize:'15px',fontFamily: 'helvetica-lt-std-bold'}}>
          100%
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0 ,fontSize:'15px',fontFamily: 'helvetica-lt-std-bold'}}>
          
        </TableCell>
      </TableRow>
    </TableFooter> 
                  </Table>
                </TableContainer>
           
            


        {/* <TablePagination   
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count=""  
        rowsPerPage=""
        page=""     
        onPageChange=""
        onRowsPerPageChange=""
      /> */}


      </Box>
      <Box />
    </Box>
  );
};

export default CategorySale;
