import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useDispatch, useSelector} from "react-redux";
import  {getAppointmentNotes} from "../../../../../store/crm/appointment/notes";
import {Link, useParams} from "react-router-dom";
import {Typography} from "@mui/material";
import moment from "moment";
import {scrollCSS} from "../../../../../App";

const AppointmentNotes = () => {
    const [active, setActive] = useState(0)

    const crmAppointmentNotesStore = useSelector(state => state?.crmAppointmentNotesStore)
    const {notes=[]} = crmAppointmentNotesStore

    const dispatch = useDispatch()
    const {appointment_id} = useParams()


    useEffect(()=>{
        (async ()=>{
            await dispatch(getAppointmentNotes(appointment_id))
        })()
    },[])

    const handleChange = (event, newValue) => {
        setActive(newValue)
    }
     console.log("notes",notes)

    return (
        <Box sx={{background:"#fff",minHeight:'100px',borderRadius: "8px",p:{sm:'24px', xs:'8px'}}}>
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',  
                width: '100%',
                
            }}>
                <Tabs
                    value={active}
                    onChange={handleChange}
                    variant="scrollable" 
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                        ".MuiTabs-indicator": {
                            background: '#000000'
                        },
                        ".Mui-selected": {
                            fontWeight: '600',
                            color: '#000!important',
                        }
                    }}
                >
                    <Tab
                        sx={{
                            fontFamily:active === 0 ? "helvetica-lt-std-bold":"helvetica-lt-std-roman",
                            fontSize:'18px',
                            color: '#000',
                            textTransform: "capitalize",
                        }}
                        label={"Medical/Staff Notes"}
                        key={0}
                    />
                          <Tab
                        sx={{
                            fontFamily:active === 1 ? "helvetica-lt-std-bold":"helvetica-lt-std-roman",
                            fontSize:'18px',
                            color: '#000',
                            textTransform: "capitalize",
                        }}
                        label={"Customer Notes"}
                        key={1}
                    />
                </Tabs>   
              
            </Box>
            <Box
               sx={{
                py:'24px',
                overflowY:'auto',
                maxHeight:'500px',
                ...scrollCSS,
            }}
            >
                {active ===0 && <Box>
                    {notes?.Medical?.length > 0 ?
                        notes?.Medical.map((note,index)=>(
                            <Box
                                key={index}
                                sx={{
                                    py:'24px',
                                    px:'16px',
                                    borderTop:index>0 ?'1px solid #9D9D9D':'unset',
                                }}>
                                <Typography sx={{fontSize:'16px'}}>
                                    <Typography
                                        as={'span'}
                                        sx={{
                                            fontSize:'16px',fontFamily:"helvetica-world-bold",
                                            mr:'3px',
                                            fontWeight:600,
                                        }}
                                    >      
                                        [{note?.date}]  {" "}
                                        {note?.staff?.name}:
                                    </Typography>
                                    {note?.description}  
                                </Typography>
                            </Box> 
                        ))
                        : <Box sx={{textAlign:'center'}}>Empty Notes</Box>
                    }
                      
                </Box>
                }

                {active ===1 && <Box>
                    {notes?.Customer?.length > 0 ?
                        notes?.Customer.map((note,index)=>(
                            <Box
                                key={index}
                                sx={{
                                    py:'24px',
                                    px:'16px',
                                    borderTop:index>0 ?'1px solid #9D9D9D':'unset',
                                }}>
                                    <Typography>Appointment </Typography> 
                                
                                <Typography sx={{fontSize:'16px'}}>
                                    <Typography
                                        as={'span'}
                                        sx={{
                                            fontSize:'16px',fontFamily:"helvetica-world-bold",
                                            mr:'3px',
                                            fontWeight:600,
                                        }}
                                    >      
                                        [{note?.date}] 
                                        {note?.staff?.name} ((
    <Link to={`/crm/appointment/details/${note?.booking_id}`} style={{ color: 'inherit', cursor:"pointer" }}>
      {note?.booking_id}
    </Link>
    ) ) :
                                    </Typography>
                                    {note?.description}  
                                </Typography>
                            </Box> 
                        ))
                        : "Empty Notes"
                    } 
                </Box>}

 
             
            </Box>
        </Box>
    );
};

export default AppointmentNotes;