import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders} from "../../../../lib/apiHeader";

export const getAllClinicsTreatmentForm = createAsyncThunk('getAllClinicsTreatmentForm', async (provider_id,{dispatch,getState}) => {
    const { search, pageLimit, currentPage } = getState().clinicTreatmentGroupStore; 
    try {

        const response = await axios.get(apis.get_clinics_treatment_form+`${provider_id}?page=1&limit=1000`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data ? response.data.data : response.data;
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

// treatment_form
export const getTreatmentForm = createAsyncThunk('getTreatmentForm', async (provider_id,{dispatch,getState}) => {
    const { search, pageLimit, currentPage } = getState().clinicTreatmentGroupStore; 
    try {

        const response = await axios.get(apis.treatment_form+`${provider_id}&page=${currentPage}&limit=${pageLimit}`, {
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            return response?.data?.data
        }
        if (response?.data?.success === false) {
            return {
                data:  response.data,
                errors: true
            }
        }

    } catch (err) {
        if (err?.response?.status !== 200) {
            return {
                data: [],
                errors: err?.response?.data?.errors
            }
        }
    }
})

export const storeClinicsTreatmentForm = createAsyncThunk('storeClinicsTreatmentForm', async (data) => {
    try {
        const response = await axios.post(apis.store_clinics_treatment_form, {...data},{
            headers: apiHeaders()
        })
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){

            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})
export const getClinicsTreatmentFormByID = createAsyncThunk('getClinicsTreatmentByFormID', async (id) => {
    try {
        const response = await axios.get(apis.get_clinics_treatment_form_by_id+id,{
            headers: apiHeaders()
        })
        console.log(response?.data)
        return  response?.data

    }catch (err){
        if (err?.response?.status!==200){
            return {
                data : [],
                errors:err?.response?.data?.errors
            }
        }
    }
})

export const updateClinicsTreatmentForm = createAsyncThunk('updateClinicsTreatmentForm', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.update_clinics_treatment_form, {...data},{
            headers: apiHeaders()
        })

        if (response?.data?.success==='true'){
            await dispatch(getAllClinicsTreatmentForm(data?.provider_id))

            return  response.data
        }else {
            return response?.data
        }
    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
        if (err?.response?.status!==200){
            return {
                data :err?.response,
                errors:true
            }
        }
    }
})
export const deleteClinicsTreatmentForm = createAsyncThunk('deleteClinicsTreatmentForm', async (data,{dispatch}) => {
    try {
        const response = await axios.get(apis.delete_clinics_treatment_form+data?.id,{
            headers: apiHeaders()
        })

        if (response?.data?.success ==='true'){
            await dispatch(getAllClinicsTreatmentForm(data?.provider_id))
            toast.success("Clinic Treatment Form Deleted Successfully",{duration:3000})

        }else {
            console.log(response?.data)
        }

    }catch (err){
        console.log(err)
        if (err?.response?.status!==200){
            toast.error(err?.message,{duration:4000})
        }
    }
})
export const updatetreatmentformFilter = createAsyncThunk('updatetreatmentgroupFilter', async (data) => {
    return data
  })

export const clinicsTreatmentFormSlice = createSlice({
    name: 'clinicsTreatmentFormSlice',
    initialState: {
        treatmentForms: [],
        meta: {},
        links: {},
        pageLimit:10,
        currentPage:1,
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(getAllClinicsTreatmentForm.fulfilled, (state, action) => {
            state.treatmentForms = action.payload
            // state.meta = action.payload.meta
            // state.links = action.payload.links
            // state.currentPage = action.payload?.meta?.current_page;
        })

        builder.addCase(getTreatmentForm.fulfilled, (state, action) => {
            state.treatmentForms = action.payload
            state.meta = action.payload.meta
            state.links = action.payload.links
            state.currentPage = action.payload?.meta?.current_page;
        })
        builder.addCase(updatetreatmentformFilter.fulfilled, (state, action) => {
            const { search, pageLimit, currentPage } = action.payload; 
            if (pageLimit){  
                state.pageLimit = pageLimit ;
            } 
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
           
        });
    }
})


export default clinicsTreatmentFormSlice.reducer