import React, {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../components/loader/loader";
import {Box, Button, Grid, Typography} from "@mui/material";
import FormInput from "../../../components/form/formInput";
import SubmitButton from "../../../components/custom-button/submitButton";
import CustomButton from "../../../components/custom-button/customButton";
import LockIcon from '@mui/icons-material/Lock';
import UserLayout from "../index";
import { storeUserUpdatePassword } from "../../../store/user/update-password";
import { toast } from "react-hot-toast";
import {MySwal, successConfirmation} from "../../../services/utills";
import Footer from "../../home/Footer";
const UserPassword = () => {
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch();
    const authStore = useSelector(state => state?.authStore)
    const {user} = authStore


    const validationSchema = Yup.object({
        confirm_password: Yup.string()
            .min(8, 'Password must be 8 characters long')
       /*     .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')*/
            .oneOf([Yup.ref('new_password'), null], 'Confirm password must match')
            .required("Confirm Password is Required"),
        new_password: Yup.string()
            .min(8, 'Password must be 8 characters long')
     /*       .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')*/
            .required("Password is Required"),
        old_password: Yup.string().required("Password is Required"),
    });
    const {values,resetForm, handleSubmit, setFieldTouched, setFieldError, handleChange, touched, errors} = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {}) => {

            setLoading(true)
            let res = await dispatch(storeUserUpdatePassword({id: user?.id, ...data}))
            if (res?.payload?.success === 'true'){
                resetForm()
                await successConfirmation('Password Updated Successfully')
            }

            if (res?.payload?.errors) {
                let errors = res?.payload?.errors
                if (typeof errors === 'string') {
                    if (errors.includes('Old password')) {
                        await setFieldTouched("old_password", true)
                        setFieldError("old_password", errors)
                    } else {
                        toast.error(errors, {duration: 3000})
                    }
                } else if (errors?.old_password) {
                    await setFieldTouched("old_password", true)
                    setFieldError("old_password", errors?.old_password)
                } else if (errors?.new_password) {
                    await setFieldTouched("new_password", true)
                    setFieldError("new_password", errors?.new_password)
                } else if (errors?.confirm_password) {
                    await setFieldTouched("confirm_password", true)
                    setFieldError("confirm_password", errors?.confirm_password)
                }

            }
            setLoading(false)
        },
    });



    return (
        <form onSubmit={handleSubmit}>
            <UserLayout>
                {loading ? <Loader fullSize/> : ""}

                <Grid container sx={{mt: 3}}>
                    <Grid item lg={8}>
                        <Typography variant="h5" color="initial" sx={{display: 'flex', alignItems: 'center', fontFamily:'helvetica-lt-std-light'}} >
                            <LockIcon/>
                            Change Password
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}

                >
                    <Grid item xs={12} lg={4} sx={{mt: 3}}>
                        <FormInput
                            name={"old_password"}
                            label={"Current Password"}
                            placeholder={"Current Password"}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item lg={4} xs={12}>
                        <FormInput
                            name={"new_password"}

                            label={"New Password"}
                            placeholder={"xxxxxxxxxxx"}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <FormInput
                            name={"confirm_password"}
                            label={"Confirm Password"}
                            placeholder={"xxxxxxxxxxx"}
                            errors={errors}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
                <Grid container>

                <Grid item lg={8}
                      sx={{display: 'flex', justifyContent: 'end', mt: 2}}
                >
                    <Box sx={{mr: "16px"}}>
                        <CustomButton
                            mainColor={"#ED1E45"}
                            val={"Cancel"}
                            // handleClick={() => {
                            //     navigate(
                            //         `/admin/configuration/clinic/${params?.clinic_id}/staff`
                            //     );
                            // }}
                        />
                    </Box>
                    <SubmitButton val={user?.id ? "Save" : "Update"}/>
                </Grid>

            </Grid>
            <Box sx={
                {
                    display:'flex',
                    mt:2,
                    pb:{sm:'0', xs:'120px'}
                }
            }>
            <Typography variant="body1" color="initial" sx={{ fontWeight:'bold', mr:1 }}>
                Note:
            </Typography>
                <Typography sx={{ fontSize:{xs:"15px",sm:"16px"} }} variant="body1" color="initial">
                Enter same password in both fields. Use an uppercase letter and a number for stronger password.
                </Typography>
            </Box>

        {/* <Grid container p={'20px'} sx={{justifyContent:'space-between'}}>
            <Grid item xs={12} lg={5.5}>
                <FormPasswordInput
                    name={"new_password"}
                    label={"New Password"}
                    placeholder={'Type new  password'}
                    errors={errors}
                    touched={touched}
                    values={values}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} lg={5.5}>
                <FormPasswordInput
                    name={"confirm_password"}
                    label={"Confirm Password"}
                    placeholder={'Type confirm password'}
                    errors={errors}
                    touched={touched}
                    values={values}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} lg={5.5}>
                <FormPasswordInput
                    name={"old_password"}
                    label={"Old Password"}
                    placeholder={'Type old password'}
                    errors={errors}
                    touched={touched}
                    values={values}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end'}}>
                <SubmitButton val={"Update"}/>
            </Grid>
        </Grid> */}
        <Box style={{marginLeft:'calc(-50vw + 50%)', marginRight:'calc(-50vw + 50%)' }}>
            <Footer />
</Box>
        </UserLayout>
    </form>
  
    );
};

export default UserPassword;
