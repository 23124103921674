import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material';

const MonthYearFilter = ({ setSelectedYear, setSelectedMonth, initialYear, initialMonth,onMonthYearChange }) => {
  const [localSelectedMonth, setLocalSelectedMonth] = useState(initialMonth);
  const [localSelectedYear, setLocalSelectedYear] = useState(initialYear);

  useEffect(() => {
    setLocalSelectedMonth(initialMonth);
    setLocalSelectedYear(initialYear);
  }, [initialMonth, initialYear]);

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    setLocalSelectedMonth(newMonth);
    onMonthYearChange(localSelectedYear, newMonth); // Call the new prop function
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setLocalSelectedYear(newYear);
    onMonthYearChange(newYear, localSelectedMonth); // Call the new prop function
  };


  const monthNames = ["January", "February", "March", "April", "May", "June",   
                      "July", "August", "September", "October", "November", "December"];
  const currentYear = new Date().getFullYear();   
  const years = Array.from({ length: 10 }, (_, i) => currentYear - i);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Month</InputLabel>
          <Select value={localSelectedMonth} onChange={handleMonthChange} label="Month">
            {monthNames.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Year</InputLabel>
          <Select value={localSelectedYear} onChange={handleYearChange} label="Year">
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default MonthYearFilter;
