import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {
    Button,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import {
    generateYears,
    generateYearsToday,
    monthsArray,
} from "../../../../services/utills";
import {MenuProps} from "../../calender/crmCalender";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";





const boxStyle ={
    height:'40px',
    width:'40px',
    // border:"1px solid #006AFF",
    display:'flex',
    alignItems:"center",
    borderRadius:'4px',
    justifyContent:'center',
    // color:'#006AFF',
    cursor:'pointer',
    "&:hover": {
        // background:"#006AFF",
        // color:'#fff'
    }
}
const disableStyle ={
    opacity: 0.5,
    color:'#D5D9DC',

    border:"1px solid #D5D9DC",
    "&:hover": {
        cursor: 'not-allowed'
    }
}
const dateStyle ={
    p:"10px 0",
    fontSize:'14px',
    fontWeight:'bold',
    height: '40px',
    width: '40px',
    mx: 'auto',
}

const InvoiceTime = ({getAppointmentHoliday,selectedStaff,getAppointmentSchedule,selectedFinalDate}) => {
    let currentData = new Date()

    const [startDate, setStartDate] = useState(currentData)
    const isCurrentMonth = (currentData.getMonth() === startDate.getMonth()) && (currentData.getFullYear() === startDate.getFullYear())
    const monthStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const monthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    const daysInMonth = monthEnd.getDate();
    // const firstDayOfWeek = monthStart.getDay();
    // const lastDayOfWeek = monthEnd.getDay();

    let firstDayOfWeek = monthStart.getDay();

    firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1; // Adjust for Monday start
    const lastDayOfWeek = monthEnd.getDay();


    const bookAppointmentStore = useSelector(state => state?.bookAppointmentStore)
    const {appointmentHolidays = [], appointmentSchedule = []} = bookAppointmentStore




    const weeks = [];
    let days = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
        days.push(
            <TableCell key={`empty-${i}`} sx={{p: "0"}}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }


    for (let i = 1; i <= daysInMonth; i++) {
        const isExist = appointmentHolidays?.length > 0 && appointmentHolidays.some((item) => parseFloat(item) === parseFloat(i))
        // Check if this formatted date is in the list of holidays
        const formattedDate = moment(new Date(startDate.getFullYear(), startDate.getMonth(), i)).format('YYYY-MM-DD');
        const isHoliday = appointmentHolidays?.length > 0 && appointmentHolidays?.includes(formattedDate);
        const isPast = isCurrentMonth && i < currentData.getDate();
        const isDisabledDate = isPast || isHoliday;
        days.push(
            <TableCell key={i} sx={{p: "0"}}>
                <Box
                    sx={{
                        ...dateStyle,
                        cursor: ( isExist || isDisabledDate) ? 'default' : 'pointer',
                        color: ( isExist || isDisabledDate) ? "#8D8D8D" : i === moment(selectedFinalDate)?.date() && startDate.getMonth() === moment(selectedFinalDate).month() && startDate.getFullYear() === moment(selectedFinalDate).year() ? '#006AFF' : '#000',
                        pointerEvents: ( isExist || isDisabledDate) ? "none!important" : 'auto',
                        textDecoration: ( isExist || isDisabledDate) ? "line-through" : 'none',
                    }}
                    onClick={() => {
                        const date = moment(new Date(startDate.getFullYear(), startDate.getMonth(), i)).format('YYYY-MM-DD')
                        getAppointmentSchedule(date)
                    }}
                >
                    {i}
                </Box>
            </TableCell>
        );

        if ((i + firstDayOfWeek) % 7 === 0 || i === daysInMonth) {
            weeks.push(<TableRow key={`week-${i}`}>{days}</TableRow>);
            days = [];
        }
    }
    for (let i = (lastDayOfWeek + 1) % 7; i < 6; i++) {
        days.push(
            <TableCell key={`empty-${i}`} sx={{p: "0"}}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }
    const isDisabled = (month) => {
        const today = new Date();
        const year = today.getFullYear();
        const currentMonth = today.getMonth();
        const currentYear = startDate.getFullYear();
        if (year === currentYear) {
            return month < currentMonth;
        } else {
            return false
        }
    }



    return (
        <Grid container sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Grid item xs={12}>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            p: '12px',
                            justifyContent: 'space-between'
                        }}>
                        <Box sx={{display: 'flex', gap: '10px'}}>
                            <FormControl sx={{minWidth: 90}}>
                                <Select
                                    size={"small"}
                                    value={startDate?.getMonth()}
                                    MenuProps={MenuProps}
                                    onChange={(e) => {
                                        const end = new Date(startDate);
                                        end.setMonth(e?.target?.value);
                                        setStartDate(end);
                                        getAppointmentHoliday(end, selectedStaff)
                                    }}
                                    sx={{
                                        background: '#EDF2F7',
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: "0!important"
                                        },
                                        "svg": {
                                            fontSize: '34px',
                                        }
                                    }}
                                >
                                    {monthsArray?.length > 0 && monthsArray?.map((item, index) => (
                                        <MenuItem
                                            // disabled={isDisabled(item?.key)}
                                            value={item?.key}
                                            key={index}
                                        >
                                            {item?.name}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl sx={{minWidth: 90}}>
                                <Select
                                    size={"small"}
                                    value={startDate?.getFullYear()}
                                    MenuProps={MenuProps}
                                    onChange={(e) => {
                                        const end = new Date(startDate);
                                        end.setFullYear(e?.target?.value);
                                        setStartDate(end);
                                        getAppointmentHoliday(end, selectedStaff)
                                    }}
                                    sx={{
                                        background: '#EDF2F7',
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: "0!important"
                                        },
                                        "svg": {
                                            fontSize: '34px',
                                        }
                                    }}
                                >
                                    {generateYears(10)?.length > 0 &&
                                        generateYears(10)?.map((item, index) => (
                                            <MenuItem value={item} key={index}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{display: 'flex', gap: '10px'}}>
                            <Box
                                sx={ {...boxStyle}}
                                onClick={() => {
                                    const end = new Date(startDate);
                                    end.setMonth(end.getMonth() - 1);
                                    setStartDate(end);
                                    getAppointmentHoliday(end, selectedStaff)
                                }}
                            >
                                <KeyboardArrowLeftIcon sx={{fontSize: '32px'}}/>
                            </Box>
                            <Box sx={boxStyle} onClick={() => {
                                const end = new Date(startDate);
                                end.setMonth(end.getMonth() + 1);
                                setStartDate(end);
                                getAppointmentHoliday(end, selectedStaff)
                            }}>
                                <KeyboardArrowRightIcon sx={{fontSize: '32px'}}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Table aria-label="simple table" sx={{
                            boxShadow: 'none', "td , th": {
                                border: 'none',
                                textAlign: 'center',
                            },
                            "th": {
                                fontWeight: 'bold'
                            },
                            "td": {
                                py: "4px"
                            }
                        }}>
                            <TableHead>
                                <TableRow>
                                    
                                    <TableCell>Mo</TableCell>
                                    <TableCell>Tu</TableCell>
                                    <TableCell>We</TableCell>
                                    <TableCell>Th</TableCell>
                                    <TableCell>Fr</TableCell>
                                    <TableCell>Sa</TableCell>
                                    <TableCell>Su</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {weeks}
                                <TableRow sx={{
                                    "td": {
                                        py: "5px!important"
                                    }
                                }}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default InvoiceTime;