import React, {useEffect, useState} from 'react';
import {Form, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {Button, Checkbox, FormControl, FormLabel, Grid, IconButton, Input, InputAdornment, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import CustomButton from "../../../../components/custom-button/customButton";
import {getClinicsEvents, updateEventsFilter} from "../../../../store/clinics/clinicSettings/clinics-events";
import SearchIcon from '@mui/icons-material/Search';
import CardTable from "../../../../components/card-table/card-table";
import { getAllClinicsTreatmentGroup } from '../../../../store/clinics/clinicSettings/clinic-treatment-group';
import Waveloading from '../../../loading/Waveloading';
import BackendSearchField from '../../../../components/pagination/backendSearch';
import BackendPagination from '../../../../components/pagination/backendPagination';


const Events = () => {

    const [loading, setLoading] = useState(false);
    // const [search,setSearch] = useState('')

    const params =useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // ,search
    const [selectedBookingTypes, setSelectedBookingTypes] = useState([]);
    const clinicEventsStore = useSelector(state => state?.clinicEventsStore)
    const {search,services,pageLimit,currentPage} = clinicEventsStore

    console.log("current page",services)

    const clinicTreatmentGroupStore = useSelector(state => state?.clinicTreatmentGroupStore)
    const user = useSelector(state => state?.authStore?.user)
   
    const handleBookingTypeChange = (event) => {
      setSelectedBookingTypes(event.target.value);
    };  
        
    const handleButtonClick = async () => {
      setLoading(true);
       
      if (selectedBookingTypes.length > 0) {
        console.log("API call with selectedBookingTypes:", selectedBookingTypes);
        await dispatch(getClinicsEvents({ provider_id: params?.clinic_id, category_id: selectedBookingTypes }));
      } else {
        console.log("Please select at least one option before making a call");
       
      }
   
      setLoading(false);          
    }; 
    console.log("isue",clinicTreatmentGroupStore?.treatmentGroups)

      useEffect(()=>{   
        (async ()=>{
            if (!clinicTreatmentGroupStore?.treatmentGroups?.length){
                setLoading(true)
            }
            await dispatch(getAllClinicsTreatmentGroup(params?.clinic_id))
            setLoading(false)
        })()
    },[dispatch,clinicTreatmentGroupStore?.treatmentGroups?.length,params?.clinic_id])
    console.log("id",params?.clinic_id)
    useEffect(() => {
      (async () => {
        setLoading(true);
    

          console.log("Calling with clinic ID:", params?.clinic_id);
          await dispatch(getClinicsEvents({ provider_id: params?.clinic_id }));

    
        setLoading(false);  
      })();
    }, [dispatch, params?.clinic_id]);
       
   
    const refreshData = async () => {
      setLoading(true);
      try {
        if (selectedBookingTypes.length > 0) {
          console.log("API call with selectedBookingTypes:", selectedBookingTypes);
          await dispatch(getClinicsEvents({ provider_id: params?.clinic_id, category_id: selectedBookingTypes }));
        } else{

          await dispatch(getClinicsEvents({ provider_id: params?.clinic_id }));
        }
         
      } catch (error) {
          console.error('Error fetching data:', error);
         
      }
      setLoading(false); 
  };



    const handleEdit = (row) => {
            navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/edit-events/${row?.id}`)

    }     

    const handleClick =()=>{
        if (params?.clinic_id){
            navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/add-events`)
        }
    }  
    const handleSearch = async (val) => {
      await dispatch(updateEventsFilter({search:val}))
  }
   
  const fetchPageData = async(page) => { 
    if (selectedBookingTypes.length > 0) {         
      console.log("API call with selectedBookingTypes:", selectedBookingTypes);
      await dispatch(updateEventsFilter({currentPage:page,provider_id: params?.clinic_id, category_id: selectedBookingTypes }));
    } 
    await dispatch(updateEventsFilter({currentPage:page,provider_id: params?.clinic_id}))
    await refreshData()
}

    return (
    <Grid container p={'20px'}>
<Grid item xs={12} sx={{mb:'16px',display:'flex',justifyContent:'flex-end',alignItems:'center',width:'100%', flexWrap:'wrap'}}>
<FormControl sx={{ minWidth: { xs: "100%", sm: "220px" }, mr: { xs: 2, sm: 1 }, mb: { xs: 2, sm: 0 } }}>
  <Select
    multiple  
    value={selectedBookingTypes}  
    
    onChange={handleBookingTypeChange}
    renderValue={(selected) => {
      if (selected.length === 0) {
        return <em>Select treatment/Groups</em>;
      } else if (selected.length <= 3) {
        return selected.map((id) => {
          const type = clinicTreatmentGroupStore?.treatmentGroups?.find((type) => type.id === id) || { title: '' };
          return type.title;
        }).join(', ');
      } else {
        const selectedTitles = selected.slice(0, 3).map((id) => {
          const type = clinicTreatmentGroupStore?.treatmentGroups?.find((type) => type.id === id) || { title: '' };
          return type.title;
        }).join(', ');
        return `${selectedTitles}, ...`;  
      } 
    }}
    
    displayEmpty
    input={<Input sx={{ mr: { xs: 2, sm: 1 } }} endAdornment={
      <InputAdornment position="end">
        <IconButton onClick={handleButtonClick} sx={{ borderRadius:"6px", color: 'white', backgroundColor: '#09ABF1', '&:hover': { backgroundColor: 'darkblue' } }}>
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    } />}
    sx={{ '& .MuiSelect-select': { padding: '10px 14px' } }}
  >
     {Array.isArray(clinicTreatmentGroupStore?.treatmentGroups) ? clinicTreatmentGroupStore.treatmentGroups.map((type, index) => (
      <MenuItem key={index}  value={type.id}>
        <Checkbox checked={selectedBookingTypes.indexOf(type.id) > -1} />
        <ListItemText primary={type.title} />
      </MenuItem>
    )) : <MenuItem disabled>Loading...</MenuItem>}
  </Select>
</FormControl>
      
  
  {/* <Button onClick={handleButtonClick} variant="contained" color="primary">
 search
  </Button> */}
      
          <FormControl sx={{mr: "10px",  mb: { xs: 2, sm: 0}}}>   
          <BackendSearchField
                            search={search}
                            handleSearch={handleSearch}   
                            fetchData={async ()=>{
                                await dispatch(updateEventsFilter({currentPage:1}))
                                await refreshData()
                            }}
                        />
          </FormControl>
          <CustomButton sx={{}} jrt={{  }} val={'Add Events'} size={'large'} handleClick={handleClick}/>
        </Grid>   
      
        <Grid container spacing={2}>
        <Grid item xs={12} >
  {loading ? <Waveloading/> : 
    <CardTable
    columnServices={"column"}
    autoServices={"500px"}
      search={""}
      handleEdit={handleEdit}
      type={'service'}   
      cardData={services}
      onDataUpdate={refreshData} 
    />  
  }    

<Grid item xs={12} style={{marginTop:"10px"}} >
        {services?.meta?.last_page > 1 &&  
            <BackendPagination
                count={services?.meta?.last_page}
                currentPage={currentPage}
                handleOnChange={fetchPageData}
            />
        }
    </Grid>
    </Grid>
</Grid>  


      </Grid>
      
    );
};

export default Events;