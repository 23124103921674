import {
    Button,
    Card,
    Grid,
    Typography,
    Container, Tooltip,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import wallet from "../../../../src/assets/images/wallet.png";
import cart from "../../../../src/assets/images/cart.png";
import TelegramIcon from "@mui/icons-material/Telegram";
import FormControl from "@mui/material/FormControl";
import copyIcon from "../../../assets/icon/copy-icon.png";
import instagram from "../../../assets/icon/Instagram.png";
import facebook from "../../../assets/icon/Facebook.png";
import whatsapp from "../../../assets/icon/WhatsApp.png";
import snapchat from "../../../assets/icon/Snapchat.png";
import tiktok from "../../../assets/icon/TikTok.png";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import UserLayout from "../index";
import refer from "../../../assets/icon/refer.png";
import {useDispatch, useSelector} from "react-redux";
import {getUserDetails} from "../../../store/auth";
import toast from "react-hot-toast";
import Footer from "../../home/Footer";

const ReferShare = () => {
    const [copy, setCopy] = useState(false)
    const dispatch = useDispatch()
    const overlineContainer = {
        position: "relative",
        textAlign: "center",
    };

    const authStore = useSelector(state => state?.authStore)
    const {user} = authStore

    useEffect(() => {
        (async () => {
            if (!user?.id) {
                await dispatch(getUserDetails())
            }
        })()
    }, [user])

    return (
        <UserLayout>
          <Box sx={{ pb: { sm: 0, xs: '120px' } }}>
    <Container maxWidth="lg" sx={{ ml: "0px", mt: 3 }}>
        <Typography variant="h5" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
            Refer and Share
        </Typography>
        <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item lg={8} xs={12}>
                <Box
                    sx={{
                        border: "1px solid #C4C4C4",
                        borderRadius: "6px",
                        p: 2,
                        flexWrap: 'wrap',
                        marginBottom: "20px", // Adjust margin to create space from the next section
                        position: "sticky", // Keep this section sticky
                        top: "20px", // Adjust as per your design
                        zIndex: 999, // Ensure it stays above other elements
                    }}
                >
                    <Grid container>
                        <Grid item lg={9} xs={12} xl={10}>
                            <Typography
                                variant="h6"
                                color="initial"
                                sx={{
                                    color: "#2F80ED",
                                    fontSize: { md: "22px", xs: '16px' },
                                    wordBreak: 'break-all',
                                    fontFamily: 'helvetica-lt-std-roman',
                                }}
                            >
                                {window?.location?.origin + `/register/${user?.referral_code}`}
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={12} xl={2}>
                            <Tooltip title={copy ? 'Copied' : "Copy"} arrow>
                                <Typography
                                    variant="body1"
                                    color="initial"
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        textDecoration: "underline",
                                        color: "#aa2167",
                                        fontWeight:'bold',
                                        cursor: "pointer",
                                        justifyContent: { lg: 'end', sm: 'start', xs: 'start' },
                                        fontSize: { md: "22px", xs: '16px' }, fontFamily: 'helvetica-lt-std-roman',
                                    }}
                                    onClick={() => {
                                        setCopy(true)
                                        navigator.clipboard.writeText(window?.location?.origin + `/register/${user?.referral_code}`)
                                        toast.success("Copied", { duration: 3000 })
                                    }}
                                >
                                    <img src={copyIcon} alt="" style={{
                                        marginRight: "7px",
                                        "@media (max-width:360px)": { marginTop: '5px' },
                                    }} />
                                    Copy link
                                </Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Typography
                        variant="h5"
                        color="initial"
                        sx={{
                            marginTop: "20px",
                            fontFamily: 'helvetica-lt-std-bold',
                            fontSize: { md: "22px", xs: '16px' },
                            fontWeight: '600'
                        }}
                    >
                        Copy the link and share with your family and friends
                    </Typography>
                </Box>
           
            </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12} lg={4}>
                <Card
                    sx={{
                        boxShadow: "1px 1px 9px #D8F3FF;",
                        height: "246px",
                        p: "25px",
                        textAlign: "center",
                        borderRadius: "14px",
                        "& img": { fontSize: "36px", my: "10px", colors: "#04B4FF" },
                    }}
                >
                    <img src={wallet} width="36px" alt="" />
                    <Typography
                        variant="h6"
                        sx={{
                            "@media (max-width:600px)": {
                                fontSize: { md: "21px", xs: '16px' },
                                fontFamily: 'helvetica-lt-std-roman',
                            },
                        }}
                    >
                        Refer your friends and family members to earn credits that get
                        directly added to your account.
                    </Typography>
                </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Card
                    sx={{
                        boxShadow: "1px 1px 9px #D8F3FF;",
                        height: "246px",
                        p: "25px",
                        textAlign: "center",
                        borderRadius: "14px",
                        "& img": { fontSize: "36px", my: "10px", colors: "#04B4FF" },
                    }}
                >
                    <img src={cart} width="36px" alt="" />
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: { md: "21px", xs: '16px' }, fontFamily: 'helvetica-lt-std-roman',
                        }}
                    >
                        Redeem your credit when you book any appointment or purchase
                        any product.
                    </Typography>
                </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Box
                    sx={{
                        position: "relative",
                        "@media (max-width:1199px)": { display: "none" },
                    }}
                >
                    <img
                        src={refer}
                        style={{ position: "absolute", left: "-100px", top: "-55px" }}
                        alt=""
                    />
                </Box>
            </Grid>
        </Grid>

        <Box sx={overlineContainer}>
            <div
                style={{
                    position: "absolute",
                    top: "60%",
                    left: "0",
                    right: 0,
                    borderTop: "1px dashed #8D8D8D",
                }}
            ></div>
        </Box>
    </Container>
    <Box style={{marginLeft:'calc(-50vw + 50%)', marginRight:'calc(-50vw + 50%)' }}>
            <Footer />
</Box>
</Box>

           
        </UserLayout>
    );
}

export default ReferShare;
