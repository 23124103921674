// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pb-container {
  background-color: #edf2f7;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 500px) {
  .pb-container {
    background-color: #edf2f7;
    min-height: auto;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: start;
    padding-top: 20px;
    justify-content: center;
    overflow-y: scroll;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EAEE;IACA,yBAAA;IACA,gBAAA;IACA,aAAA;IACA,WAAA;IACA,aAAA;IACA,kBAAA;IACA,iBAAA;IACA,uBAAA;IACA,kBAAA;EAAA;AACF","sourcesContent":[".pb-container {\n  background-color: #edf2f7;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items:center;\n  justify-content: center;\n}\n\n@media (max-width:500px) {\n\n  .pb-container {\n  background-color: #edf2f7;\n  min-height: auto;\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  align-items: start;\n  padding-top: 20px;\n  justify-content: center;\n  overflow-y: scroll;\n}\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
