import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import toast from "react-hot-toast";
import apis from "../../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../../lib/apiHeader";
export const  getClinicsEvents = createAsyncThunk('getClinicsEvents', async ({ provider_id, category_id } , { getState, dispatch }) => {
    const { search, pageLimit, currentPage } = getState().clinicEventsStore;
    try {

      let baseUrl = apis.get_clinic_events + `${provider_id}`;
      let queryString = `&page=${currentPage}&limit=${pageLimit}`;

      if (search !== '') {
        queryString += `&search=${encodeURIComponent(search)}`;
      }

      if (Array.isArray(category_id)) {
        category_id.forEach((value, index) => {
          queryString += `&category_ids[${index}]=${encodeURIComponent(value)}`;
        });
      } else if (category_id) {
        queryString += `&category_ids=${encodeURIComponent(category_id)}`;
      }

      let url = baseUrl + queryString;

      const response = await axios.get(url, {
        headers: apiHeaders()
      });

      if (response?.data?.success === 'true') {
        return response?.data?.data;
      } else if (response?.data?.success === false) {
        return {
          data: response.data,
          errors: true
        };
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        return {
          data: [],
          errors: err?.response?.data?.errors
        };
      }
    }
  });



export const updateEventsFilter = createAsyncThunk('updateEventsFilter', async (data) => {
    return data
})
export const clinicsEventsSlice = createSlice({
    name: 'clinicsEventsSlice',
    initialState: {
        services: [], 
        meta: {},
        links: {},
        search:'', 
        pageLimit:10,
        currentPage:1,
    },
    reducers: {
    }, 
    extraReducers: builder => {

      builder.addCase(getClinicsEvents.fulfilled, (state, action) => {
        state.services = action.payload
        state.meta = action.payload.meta
        state.links = action.payload.links
        state.currentPage = action.payload?.meta?.current_page;

    })

        // getClinicsServices
        builder.addCase(updateEventsFilter.fulfilled, (state, action) => {
            const { search, pageLimit, currentPage } = action.payload; 
            if (pageLimit){
                state.pageLimit = pageLimit ;
            } 
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
            
           
        });
    }
})


export default clinicsEventsSlice.reducer