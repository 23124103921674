import React, { useState } from "react";
import ComingSoon from "../../../components/coming-soon";
import UserLayout from "../../user";
import archive from "../../../assets/images/archive-outline.png";
import archiveBlue from "../../../assets/images/archive-blue.png";
import { scrollCSS } from "../../../App";
import {
  Box,
  MenuItem,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../components/loader/loader";
import {
  FormControl,
  FormLabel,
  Grid,
  Typography,
  Container,
  Button,
} from "@mui/material";
import SubmitButton from "../../../components/custom-button/submitButton";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateUserAccount } from "../../../store/user/my-account";
import useStyles from "./style";
import FormDateInput from "../../../components/form/FormDateInput";
import folder from "../../../assets/images/folder.png";
import DateRange from "../../../components/date-range/date-range";
import moment from "moment";
import momentTz from 'moment-timezone';
import { getEODReports } from "../../../store/crm/reports";
import Waveloading from "../../loading/Waveloading";
import { getProviderStaff } from "../../../store/crm/staff";

export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

export const eodInput = {
  background: "#EDF2F7",
  borderRadius: "4px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiInputBase-input": {
    borderColor: " #EDF2F7",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#EDF2F7",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#EDF2F7",
      outline: "none",
    },
  },
};
export const selectStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginTop: "7px",
  marginRight: "5px",
  marginLeft: "5px",
  height: "44px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",

    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};

const EodReport = () => {
  let timeZone = localStorage.getItem("timeZone");
  const classes = useStyles();
  const currentDate = momentTz.tz(timeZone).format('YYYY-MM-DD');  // const [startDate, setStartDate] = useState(null);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dataFetched, setDataFetched] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const reportsStore = useSelector(state => state?.reportsStore)
  const { EODReports = [], } = reportsStore
  const [staff, setStaff] = React.useState('');
  const crmStaffStore = useSelector(state => state?.crmStaffStore)
  const {crmStaffs = []} = crmStaffStore
 
  useEffect(() => {
    dispatch(getProviderStaff());
  }, []);
  const handleChanges = (event) => {
    setStaff(event.target.value); 
  };
  const validationSchema = Yup.object().shape({
    //date: Yup.date().nullable().required("Start Date is required"),
    // end_date: Yup.date().required("End Date is required"),
  });

  const {
    values,
    handleSubmit, 
    setFieldTouched,
    setFieldError,
    setFieldValue,
    handleChange,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      name: "",
      profile_image: null,
      email: "",
      phone: "",
      countryCode: "+1",
      city: "",
      state: "",
      country: "",
      date_of_birth: "",
      address: "",
      postal_code: "",
      gender: "",
      bio: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(data).forEach(function (key, index) {
        if (key === "profile_image") {
          if (typeof data[key] !== "string") {
            formData.append(key, data[key]);
          }
        } else if (key === "countryCode" || key === "phone") {
          formData.append(key, data["countryCode"] + data["phone"]);
        } else {
          formData.append(key, data[key]);
        }
      });
      let res = await dispatch(updateUserAccount(formData));

      if (res?.payload) {
        if (res?.payload?.data?.success === "true") {
          toast.success("User Updated Successfully");
        }
        if (res?.payload?.errors) {
          const errors = res?.payload?.errors;
          try {
            if (Object.keys(errors)?.length > 0) {
              Object.keys(errors).forEach(function (key, index) {
                setFieldTouched(key, true);
                setFieldError(key, errors[key]);
              });
            }
          } catch (e) { }
        }
      }
      setLoading(false);
    },
  });

  function createData(name, customerName, email, carbs, protein, difference) {
    return { name, customerName, email, carbs, protein, difference };
  }
  const formik = useFormik({
    initialValues: {
      date: "",

    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        date: values.date ? moment(values.date).format('YYYY-MM-DD') : null,
        // end_date: values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : null,
        staff_id :staff,
      };
      try {
        setLoading(true);
        await dispatch(getEODReports(payload));
        setLoading(false);
        setDataFetched(true);
      } catch (error) {
        console.error(error);
      }
    },
  });
 
  return (
    <>
      <Container maxWidth="xl" sx={{ ml: 0, p: "0 !important" }}>
      <form onSubmit={formik.handleSubmit}>
     <Grid container sx={{ mt: 3 }}>
    <Grid
      item
      lg={12} 
      sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
    >
      <FormControl
        sx={{
          minWidth: { xs: "100%", sm: "220px" },
          mt: { xs: 2, sm: 1 },
        }}
      >
        <FormLabel>Select Date</FormLabel>
        <FormControl
          sx={{
            width: { xs: "100%", sm: "unset", md: "unset" },
            mt: { xs: 2, sm: 1 },
          }}
        >
          <TextField
            variant="outlined"
            name="date" 
            sx={inputStyle}
            type="date"
            value={formik.values.date || currentDate} // Use current date as default value
            onChange={(e) => {
              formik.handleChange(e);
              setSelectedDate(e.target.value); // Update the selectedDate state
            }}
          />
        </FormControl>
        {formik.touched.date && !formik.values.date && (
          <div style={{ color: "red" }}></div>
        )}
      </FormControl>
      <FormControl    
                sx={{    
                  minWidth: { xs: "100%", sm: "220px" },  
                  maxWidth: { xs: "100%", sm: "220px" },   
                  mt: { xs: 2, sm: 1 },  
                }}
              >     
                <FormLabel>Staff</FormLabel>  
                <Select
                  value={staff}             
                  onChange={handleChanges}
                  displayEmpty
                  sx={selectStyle}
                >
                   {crmStaffs?.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>     
                  ))}   
                </Select>
              </FormControl> 
      <Box sx={{ mt:5, ml: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          Fetch Report
        </Button>
      </Box>
    </Grid> 
  </Grid>
</form>

 {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            mt: 5,
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { md: "row", sm: "row", xs: "column" },
          }}
        >
          <Typography variant="h6" color="initial">
            Showing Results for : <span> {selectedDate}</span>
          </Typography>
          {/* <Box sx={{ mt: { xs: 2, md: 0 } }}>
            <Button
              variant="outlined"
              color="inherit"
              className={classes.buttons}
              sx={{
                mt: { md: "0", xs: 2 },
                fontSize: { xs: "14px" },
                mr: { md: 2, xs: 0 },
              }}
            >
              <img src={folder} alt="" className={classes.buttonIcons} /> Old
              Reports
            </Button>
           
          </Box> */}
        </Box>
      </Container>
      {loading ? (
        <Waveloading />
      ) :   dataFetched ? (
<>

      <Container maxWidth="xl" sx={{ ml: 0, p: "0 !important", mt: 4 }}>
        <UserLayout>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item lg={3} md={6} xs={12} sm={6}>
              <Typography
                variant="h6"
                color="initial"
                sx={{ mt: { md: "0", xs: 1 } }}
              >
                End Of Day Report Summary:
              </Typography>
            </Grid>
            <Grid item lg={3} md={6} xs={12} sm={6}>
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  color: "#2F80ED",
                  mt: { md: "0", xs: 1 },
                  fontSize: { xs: "16px" },
                }}
              >
               {selectedDate}
              </Typography>
            </Grid>
            <Grid item lg={6} style={{display:"flex",justifyContent:"end"}}>
              {/* <Typography
                variant="body1"
                color="initial"
                className={classes.archiveText}
                sx={{ mt: { md: "0", xs: 1 }, fontSize: { xs: "16px" } ,border:"1px solid #2F80ED" }}
              >
                <img src={archiveBlue} alt="" style={{ marginRight: "10px" }} />
                Archive Report
              </Typography> */}
               {/* <Button
              variant="outlined"
              color="inherit"   
              className={classes.buttons}
              sx={{
                mt: { md: "0", xs: 2 },
                fontSize: { xs: "14px" },
                mr: { md: 2, xs: 0 },
              }}
            >
              <img src={archive} alt="" className={classes.buttonIcons} />
              Archived Reports
            </Button> */}
            </Grid>
          </Grid>

          {/* EOD Report */}
        
                  <Grid container sx={{ mt: 3 }}>
                    <Grid item lg={3} md={6} xs={12} sm={6}>
                      <Box>
                        <Typography
                          variant="h5"
                          color="initial"
                          sx={{ mt: { md: "0", xs: 2 } }}
                        >
                         {EODReports?.counts?.total_bookings}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          className={classes.eodText}
                        >
                          Total Appointments:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12} sm={6}>
                      <Box>
                        <Typography
                          variant="h5"
                          color="initial"
                          sx={{ mt: { md: "0", xs: 2 } }}
                        >
                           {EODReports?.counts?.total_completed_bookings}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          className={classes.eodText}
                        > 
                          Completed Appointments:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12} sm={6}>
                      <Box>
                        <Typography
                          variant="h5"
                          color="initial"
                          sx={{ mt: { md: "0", xs: 2 } }}
                        >    {EODReports?.counts?.total_cancelled_bookings}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          className={classes.eodText}
                        >
                          Cancelled Appointments:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12} sm={6}>
                      <Box>
                        <Typography
                          variant="h5"
                           color="initial"
                          sx={{ mt: { md: "0", xs: 2 } }}
                        >
                          {EODReports?.counts?.total_no_show_bookings}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          className={classes.eodText}
                        >
                          No Show:
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: 3 }}>
                    <Grid item lg={3} md={6} xs={12} sm={6}>
                      <Box>
                        <Typography
                          variant="h5"
                          color="initial"
                          sx={{ mt: { md: "0", xs: 2 } }}
                        >
                        {EODReports?.counts?.new_members_count}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          className={classes.eodText}
                        >
                          Members Signed Up:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12} sm={6}>
                      <Box>
                        <Typography
                          variant="h5"
                          color="initial"
                          sx={{ mt: { md: "0", xs: 2 } }}
                        >
              {EODReports?.counts?.new_memberships_amount}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          className={classes.eodText}
                        >
                          New Memberships Amount:
                        </Typography>
                      </Box>
                    </Grid>   
                    <Grid item lg={3} md={6} xs={12} sm={6}>
                      <Box>
                        <Typography
                          variant="h5"
                          color="initial"
                          sx={{ mt: { md: "0", xs: 2 } }}
                        >
                               {EODReports?.counts?.total_cancelled_bookings}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          className={classes.eodText} 
                        >
                          Memberships Cancelled:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12} sm={6}>
                      <Box>
                        <Typography
                          variant="h5"
                          color="initial"
                          sx={{ mt: { md: "0", xs: 2 } }}
                        >
                         {EODReports?.counts?.total_membership_renewals}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="initial"
                          className={classes.eodText}
                        >
                          Membership Renewals:
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
          {/* Table */}
          <TableContainer
            component={Paper}
            sx={{
              mt: 5, boxShadow: "none", maxHeight: "650px", overflowY: "auto",
              ...scrollCSS,
            }}
          >
            <Table
              sx={{

              }}
              aria-label="simple table"
            >
              <TableHead className={classes.tableHead}>
                <TableRow sx={{}}>
                  <TableCell
                    align="left"
                    sx={{
                      fontWeight: "700",
                      borderBottom: 0,
                    }}
                  >
                    Collected Amount
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "700", borderBottom: 0 }}
                  >
                    No. of Receipts
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "700", borderBottom: 0 }}
                  >
                    Total Amount
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "700", borderBottom: 0 }}
                  >
                    System Generated
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontWeight: "700", borderBottom: 0 }}
                  >
                    
                  </TableCell>
                  {/* <TableCell
                    align="left"
                    sx={{ fontWeight: "700", borderBottom: 0 }}
                  >
                    Difference
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
               
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableLink}
                    >
                
                External CC
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
      <TextField
        type="text"
        variant="outlined"
        name={"email"}
        sx={eodInput}
        value={values?.email}
        onChange={handleChange}
      />
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
                    <TextField
        type="text"
        variant="outlined"
        name={"email"}
        sx={eodInput}
        value={values?.email}
        onChange={handleChange}
      />
                    </TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.external_cc?.counts}</TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.external_cc?.total}</TableCell>
                  
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableLink}
                    >
                
                Cash
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
     
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
                    <TextField
        type="text"
        variant="outlined"
        name={"email"}
        sx={eodInput}
        value={values?.email}
        onChange={handleChange}
      />
                    </TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.cash?.counts}</TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.cash?.total}</TableCell>
                  
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableLink}
                    > 
                     eTransfer
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
     
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
                    <TextField
        type="text"
        variant="outlined"
        name={"email"}
        sx={eodInput}
        value={values?.email}
        onChange={handleChange}
      />
                    </TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.e_transfer?.counts}</TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.e_transfer?.total}</TableCell>
                  
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableLink}
                    >
                
                Wallet Points Consumed
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
     
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
                   
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.wallet_points_consumed}</TableCell>
                  
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableLink}
                    >
                
                ONLINE Braintree/Square
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
     
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
                   
                    </TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.braintree?.count}</TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.braintree?.total}</TableCell>
                  
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableLink}
                    >
                
                 BT New Memberships Amount
                   </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
     
                    </TableCell>*
                    <TableCell align="left" className={classes.tableLink}>
                    
                    </TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.new_membership_amount?.count|| 0}</TableCell>                 <TableCell align="left">{EODReports?.total_collected_data?.new_membership_amount?.total||0}</TableCell>
                  
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                    <TableCell
                      component="th"  
                       scope="row" 
                      className={classes.tableLink}
                     >
                
                Membership Renewals Amount
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
     
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>    
                              
                    </TableCell>             
                    <TableCell align="left">{EODReports?.total_collected_data?.membership_renewal_amount?.count || 0}</TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.membership_renewal_amount?.total|| 0}</TableCell>
                  
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                   <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableLink}
                    >
                
                Tips Amount  
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
     
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
                   
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.tips}</TableCell>
                  
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableLink}
                    >
                
                Total (without Wallet )
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
     
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
                   
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.total_with_out_wallet
}</TableCell>
                  
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    className={classes.odd}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableLink}
                    >     
                         
                     Total Amount +Members
                    </TableCell>  
                    <TableCell align="left" className={classes.   tableLink}>
     
                    </TableCell>
                    <TableCell align="left" className={classes.tableLink}>
                      
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">{EODReports?.total_collected_data?.total_with_membership}</TableCell>
                  
                  </TableRow>
                 
              </TableBody>    
            </Table>
          </TableContainer>      
         
        </UserLayout> 
      </Container>  
      </>) : ""}
     </>

    // <ComingSoon/>
  );
};

export default EodReport;
  