import React, { useEffect, useState } from "react";
import UserLayout from "../index";
import Box from "@mui/material/Box";
import { FormControl, Grid, Menu, MenuItem, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Button from "@mui/material/Button";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { useDispatch, useSelector } from "react-redux";
import {
  getBookingStatus,
  getUserBookings,
  storeUserInvoice,
  updateBookingFilters,
} from "../../../store/booking-widget";
import UserInvoices from "./user-invoices/userInvoices";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/loader/loader";
import BackendSearchField from "../../../components/pagination/backendSearch";
import CustomModal from "../../../components/modal/customModal";
import RescheduleCalender from "../../booking-widget/rescheduleCalender";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {
  bookingButton,
  statusStyles,
} from "../../../components/custom-button/buttonStyles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import BackendPagination from "../../../components/pagination/backendPagination";
import Paper from "@mui/material/Paper";
import { customConfirmation } from "../../../services/utills";
import { styled } from "@mui/material/styles";
import FilterInput from "../../../components/form/filterInput";
import CalenderAppointmentDetail from "../../crm/calender/calenderAppointmentDetail";
import BookingDetails from "./BookingDetails";
import Footer from "../../home/Footer";
import moment from "moment/moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F5F5F5",
    color: "#666666",
    fontSize: "16px",
    fontWeight: 700,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const UserBooking = () => {
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rescheduleData, setRescheduleData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [openAppointmentDetail, setOpenAppointmentDetail] = useState(null);
  const [showModal, setShowModal] = useState(true); 

  const bookingWidgetStore = useSelector((state) => state?.bookingWidgetStore);
  const {
    userInvoice,
    userBookings,
    bookingStatus,
    filterBy,
    pageLimit,                        
    currentPage,
    search,
  } = bookingWidgetStore;
              
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const { pathname } = useLocation();      
  const location = useLocation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = async (data) => {
    await dispatch(storeUserInvoice(data));
    navigate("/user/appointment/invoice");
  };

  const handleFilter = async (val) => {
    await dispatch(updateBookingFilters({ filterBy: val, currentPage: 1 }));
    handleClose();
    await fetchAllBookings();
  };

  const handleRescheduleClose = () => {
    setRescheduleData(null);
  };

  useEffect(() => {
    if (pathname === "/user/appointment/invoice") {
      if (!userInvoice?.id) {
        navigate("/user/bookings");
      }
    }
  }, [pathname]); 
                         
  useEffect(() => {
    (async () => {
      if (pathname === "/user/bookings") {
        await fetchAllBookings();
        dispatch(getBookingStatus());
      }
    })();
  }, [pathname]);

  const fetchPageData = async (page) => {
    await dispatch(updateBookingFilters({ currentPage: page }));
    await fetchAllBookings();
  };

  const handleLimitChange = async (val) => {
    await dispatch(updateBookingFilters({ pageLimit: val, currentPage: 1 }));   
    await fetchAllBookings();
  };
  const handleSearch = async (val) => {
    await dispatch(updateBookingFilters({ search: val }));   
  };

  const fetchAllBookings = async () => {
    setLoading(true);
    await dispatch(getUserBookings());
    setLoading(false);
  };
 
  const handleRescheduleAppointment = async (item) => {
   
    if ( 
      await customConfirmation(
        "Reschedule Appointment",
        " Would you like to reschedule?",                                                          
        "Yes, Reschedule",   
        "Cancel" 
      )    
    ) {
      setRescheduleData(item);    
      // setShowRescheduleModal(true);  
      // let res = await dispatch(cancelUserBooking())     
    }
  } 
 
  useEffect(() => {  
    const queryParams = new URLSearchParams(location.search)  
    const urlBookingId = queryParams.get('booking_id')    
    console.log("Booking ID from URL:", urlBookingId)
    if (urlBookingId) { 
        const itemToReschedule = userBookings?.data?.find(item => item.id.toString() === urlBookingId);
        console.log("Item to Reschedule:", itemToReschedule)
        if (itemToReschedule && itemToReschedule.status !== "CheckIn" && itemToReschedule.status !== "Cancel") {

            setRescheduleData(itemToReschedule)
            setShowModal(false);    
        }          
    }  
  }, [location, userBookings?.data]);  
  

  return userInvoice?.id && pathname === "/user/appointment/invoice" ? (         
    <Box>
      <UserInvoices invoiceDetails={userInvoice} />  
    </Box>
  ) : (
    <UserLayout>
      {loading ? <Loader fullSize /> : ""}
      <Box>  
        <CustomModal
          customWidth={"1000px"}
          open={openAppointmentDetail !== null}
          setOpen={() => setOpenAppointmentDetail(null)}  
        >
          <BookingDetails appointment_id={openAppointmentDetail} />
        </CustomModal>
        <Box                              
          sx={{
            display: "flex", 
            justifyContent: "space-between",    
            mb: "16px",
            flexWrap: "wrap",  
          }}
        >
          <Box
            sx={{ 
              display: "flex",
              alignItems: "center",
              flexWrap: { xs: "wrap", sm: "no" },
            }}
          >    
            <FilterInput
              isBorder
              limit={pageLimit}
              setLimit={handleLimitChange}   
            />  
            <Typography sx={{ fontSize: "14px", color: "#404040", ml:{xs:2,lg:0} }}>
              Showing {userBookings?.meta?.from} to {userBookings?.meta?.to} of{" "}
              {userBookings?.meta?.total} entries
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <FormControl
              fullWidth
              sx={{
                mr: "10px",
                width: { xs: "100%", sm: "300px" },
                m: { xs: "10px 0", lg: "0" },
              }}
            >
              <BackendSearchField               
                search={search}
                placeholder={'Search by service name'}
                handleSearch={handleSearch}
                bcolor={"#f4ede3"}   
                iconcolor={"#000"}
                bordColor={"1px solid #000"}
                fetchData={async () => {
                  await dispatch(updateBookingFilters({ currentPage: 1 }));
                  await fetchAllBookings();
                }}
              />
            </FormControl>  
            <Button
              variant="outlined"  
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{  
                p: "8px 15px !important",
                minWidth: "max-content",
                color: "black",    
                borderColor: "black",
                ml: { xs: 0, sm: 2 },
                "&:hover": {
                  color: "black",
                  borderColor: "black", 
                },
              }}
              startIcon={<FilterAltIcon sx={{ color: "#828282" }} />} 
            >
              Sort By    
            </Button>   
            <Menu   
              id="basic-menu"                    
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {bookingStatus?.length > 0 ? (   
                bookingStatus.map((item, index) => ( 
                  <MenuItem
                    key={index}
                    sx={{
                      background: item === filterBy ? "#09ABF1" : "",
                    }}
                    onClick={() => handleFilter(item)}
                  >
                    {item}
                  </MenuItem> 
                ))
              ) : ( 
                <MenuItem onClick={handleClose}>Empty</MenuItem>
              )}
            </Menu>
          </Box>       

        </Box>        
        <Paper 
          sx={{
            ".css-16c50h-MuiInputBase-root-MuiTablePagination-select": {
              lineHeight: "1.1375em",
            },    
            display: { xs: "none", sm: "block" },
          }}
        >
          <CustomModal
            customWidth={"700px"}
            setOpen={() => handleRescheduleClose()}
            open={!!rescheduleData?.id}  
          >      
            <Box>                               
              <RescheduleCalender
                type={true}
                loading={loading} 
                handleClose={handleRescheduleClose}
                setLoading={setLoading}                                                  
                rescheduleData={rescheduleData}   
              />   
            </Box>
          </CustomModal>
          <CustomModal  
            open={isSuccess}
            setOpen={setIsSuccess}
            customWidth={"680px"}
          >
            <Box sx={{ p: "16px" }}>
              <Typography>
                Your appointment has been cancelled successfully!
              </Typography>      
            </Box>
          </CustomModal>
          <TableContainer sx={{ minHeight: "calc(100vh - 311px)" }}>    
            {loading === true && <Loader fullSize />}     
            <Table stickyHeader aria-label="customized table"> 
              <TableHead>          
                <TableRow> 
                  <StyledTableCell  
                    align={"center"}    
                    sx={{
                      minWidth: "100px",
                      fontSize: "16px",
                      fontFamily: "helvetica-lt-std-bold",
                    }}
                  >
                    ID
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      minWidth: "250px",
                      fontSize: "16px",
                      fontFamily: "helvetica-lt-std-bold",
                    }}
                  >
                    Service
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      minWidth: "160px",
                      fontSize: "16px",
                      fontFamily: "helvetica-lt-std-bold",
                    }}
                  >
                    Payment Status
                  </StyledTableCell>   
                  <StyledTableCell
                    sx={{
                      minWidth: "160px",
                      fontSize: "16px",
                      fontFamily: "helvetica-lt-std-bold",
                    }}
                  >
                    Status
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      minWidth: "200px",
                      fontSize: "16px",
                      fontFamily: "helvetica-lt-std-bold",
                    }}
                  >
                    Date / Time
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      fontSize: "16px",
                      fontFamily: "helvetica-lt-std-bold",
                    }}
                  >
                    Amount
                  </StyledTableCell>   
                  <StyledTableCell
                    sx={{
                      pl: "50px", 
                      fontSize: "16px",
                      fontFamily: "helvetica-lt-std-bold",
                    }}
                  >
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {userBookings?.data?.length > 0 ? (   
                <TableBody>   
                  {userBookings?.data.map((item, index) => (   
                    <StyledTableRow
                      sx={{
                        "&:hover": {
                          background: "#F7FDFF",
                        },
                      }}
                      key={index}
                    >
                      <StyledTableCell
                        align={"center"}   
                        component="th"
                        scope="row"   
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenAppointmentDetail(item?.id)}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "helvetica-lt-std-roman",
                          }}
                        >
                          {item?.id}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"  
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenAppointmentDetail(item?.id)}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "helvetica-lt-std-roman",
                          }}
                        >
                          {item?.service_name}
                        </Typography>  
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "helvetica-lt-std-roman",
                          }}
                        >
                            {item?.type === "Consultation"
                                ? " (In Person Consultation)"
                                : item?.type === "Virtual Consultation"
                                    ? " (Virtual)"
                                    : ''}
                                {(item?.provider?.name) ? `(${item.provider.name})` : ''}

                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"  
                        sx={{
                          cursor: "pointer",
                        }}   
                        onClick={() => setOpenAppointmentDetail(item?.id)}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "helvetica-lt-std-roman",
                            background:
                              item?.payment_status === "Paid" || item?.payment_status === "Pre Paid"
                                ? "#27AE60"
                                : "#EB5757",
                            color: "#fff",
                            padding: "2px 12px",
                            borderRadius: "13px",
                            width: "fit-content",
                          }}
                        >
                          {item?.payment_status}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenAppointmentDetail(item?.id)}
                      >
                        <Typography
                          sx={{
                            ...statusStyles,
                            color: "#fff",
                            background: item?.status_color || "#DFDFDF",
                            fontSize: "16px",
                            fontFamily: "helvetica-lt-std-roman",
                            width: "fit-content",
                          }}
                        >
                          {item?.status}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th" 
                        scope="row"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenAppointmentDetail(item?.id)}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "helvetica-lt-std-roman",
                          }}
                        >
                            {moment(item?.date).format("dddd, MMMM D, YYYY")}
                        </Typography>
                        <Typography   
                          sx={{
                            fontSize: "16px",
                            fontFamily: "helvetica-lt-std-roman",
                          }}
                        >
                          {item?.start_time} - {item?.end_time}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          cursor: "pointer",
                          textAlign: "end",
                        }}
                        component="th"
                        scope="row"
                        onClick={() => setOpenAppointmentDetail(item?.id)}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: "16px",
                            fontFamily: "helvetica-lt-std-bold",
                          }}
                        >
                          {item?.cost || 0}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#828282",
                            fontSize: "14px",
                            fontFamily: "helvetica-lt-std-roman",
                          }}
                        >
                          CAD
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          pl: "50px",
                          minWidth: "100px",
                        }}
                      >
                        {item?.status === "Completed" ? (
                          <Button
                            onClick={() => handleEdit(item)}
                            sx={bookingButton}
                          >
                            view Invoice                                
                          </Button>
                        ) : (
                          <Box sx={{ display: "flex", gap: "10px" }}>
                              {( item?.status !== "CheckIn" && item?.status !== "Cancel") && (
                                  <Button 
                                      sx={bookingButton}
                                      onClick={() => handleRescheduleAppointment(item)}
                                  >
                                      Reschedule
                                  </Button>
                              )}

                          </Box>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow sx={{ height: "300px", textAlign: "center" }}>
                    <TableCell
                      colSpan={7}
                      align={"center"}
                      sx={{
                        fontWeight: "bold",
                        borderBottom: "none",
                        fontSize: "20px",
                      }}
                    >
                        No appointments are available at the moment.
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer> 
          {userBookings?.meta?.last_page > 1 && (
            <Box
              sx={{
                p: "20px",
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <BackendPagination
                count={userBookings?.meta?.last_page}
                currentPage={currentPage}
                handleOnChange={fetchPageData} 
              />
            </Box>
          )}
        </Paper> 
      </Box>   

      {userBookings?.data?.length > 0 ? (
        <Grid 
          container 
          sx={{        
            mt: 3, 
            mb: 3, 
            borderRadius: "8px",
            pb: 9, 
            display: { xs: "block", sm: "none" },        
          }}
        >   
          {userBookings?.data.map((item, index) => (
            <Grid 
              container     
              sx={{
                mt: 3,  
                mb: 3,  
                backgroundColor: item?.status === "Completed" && item.payment_status === "Paid"
                    ? "#f4ede3"
                    : "#f4ede3",    
                borderRadius: "8px",
                p: 3,                             
                display: { xs: "block", sm: "none" },
              }} 
              onClick={() => setOpenAppointmentDetail(item?.id)}
            >
              <Grid item>       
                <Box
                  sx={{ 
                    display: "flex",   
                    justifyContent: "space-between",  
                    alignItems: "center",
                  }}
                >      
                  <Box sx={{}}>  
                    <Typography  
                    sx={{
                      fontSize: "15px", 
                      width: "fit-content",    
                      fontFamily: "helvetica-lt-std-bold",
                      color: "#000",
                      marginBottom:1,
                    }}
                    
                    >
                        Appointment Status:
                      </Typography> 
                    <Typography
                      sx={{
                        fontSize: "13px", 
                        width: "fit-content",    
                        fontFamily: "helvetica-lt-std-roman",
                        background: item?.status_color,
                        color: "#fff",
                        padding: "2px 12px",
                        borderRadius: "13px",
                      }}
                    >      
                      {item?.status}   
                    </Typography>
                    <Typography
                      paragraph
                      sx={{
                        color: "#333",  
                        fontFamily: "helvetica-lt-std-roman",
                        fontSize: "14px",
                        display: "block", 
                        mt: "15px",
                      }} 
                    >                          
                      {item?.service_name} 
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "5px",
                      border: " 1px solid #D8F3FF",
                      background: "#FFF",
                      p: "5px 15px",
                      textAlign: "center",
                      marginLeft: "5px",                   
                    }}
                  >
                    <Typography 
                      paragraph
                      sx={{
                        color: "#464646",
                        fontFamily: "helvetica-lt-std-roman",
                        fontSize: "8px",
                        display: "block",
                        m: "0px",
                      }}
                    >
                      booking no:
                    </Typography> 
                    <Typography
                      variant="h2"
                      sx={{
                        color: "#18A0FB", 
                        fontFamily: "helvetica-lt-std-roman",
                        fontSize: "24px", 
                        display: "block",
                        fontWeight: "700",
                      }}
                    >   
                      {item?.id}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ 
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                 

                  <Box sx={{}}>
                  <Typography  
                    sx={{
                      fontSize: "15px", 
                      width: "fit-content",    
                      fontFamily: "helvetica-lt-std-bold",
                      color: "#000",
                      marginBottom:1,
                    }}
                    
                    >
                        Payment Status:
                      </Typography> 
                    <Typography  
                      sx={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        width: "fit-content",
                        background:
                          item?.payment_status === "Paid"
                            ? "#27AE60"
                            : "#EB5757",
                        color: "white",
                        padding: "2px 12px",
                        borderRadius: "13px",
                      }}   
                    >
                      {item?.payment_status}
                    </Typography>
                  </Box>
                </Box>
                <Box 
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{}}> 
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontFamily: "helvetica-lt-std-roman",
                      }} 
                    >
                  <CalendarMonthIcon sx={{fontSize:'20px'}}/>    {item?.date}
                    </Typography>
                    <Typography
                      sx={{  
                        fontSize: "16px",
                        fontFamily: "helvetica-lt-std-roman",
                      }}
                    >         
                    <AccessTimeIcon sx={{fontSize:'20px'}}/>  {item?.start_time} - {item?.end_time}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography  
                      paragraph
                      sx={{
                        color: "#000",
                        fontFamily: "helvetica-lt-std-roman",
                        fontSize: "16px",
                        fontWeight: "700",
                        mb: "0px",  
                      }}     
                    >
                      {item?.cost || 0}
                    </Typography>
                    <Typography
                      paragraph
                      sx={{
                        color: "#828282",
                        fontFamily: "helvetica-lt-std-roman",
                        fontSize: "14px",
                        letterSpacing: "0.7px",
                        textAlign: "end", 
                      }} 
                    >
                      CAD
                    </Typography> 
                  </Box>
                </Box>
                <Box
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item?.status === "Completed" ? (
                    <Button onClick={() => handleEdit(item)} sx={bookingButton}>
                      view Invoice
                    </Button>
                  ) : (
                    showModal && (
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      {( item?.status !== "CheckIn" &&
                        item?.status !== "Cancel") && (
                        <Button
                          sx={bookingButton}
                          onClick={(e) => {
                            e.stopPropagation(); 
                            handleRescheduleAppointment(item);
                          }}
                        >
                          Reschedule
                        </Button>   
                      )}
                    </Box>)  
                  )}
                </Box>
              </Grid> 
            </Grid>
          ))}


        </Grid> 
      ) : ( 
       <Typography 
        variant="body2"
        color="initial"
     
      >
      
      </Typography> 
    )} 
 
      <Box
        style={{
          marginLeft: "calc(-50vw + 50%)",
          marginRight: "calc(-50vw + 50%)",                   
        }}
      >
        <Footer />
      </Box>                
    </UserLayout>
  );
};

export default UserBooking;
