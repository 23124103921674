import React, { useState } from 'react';
import {Box, Paper, Drawer, Typography} from "@mui/material";
import close from '../../../assets/icon/close.png'
import moment from "moment";
import {scrollCSS} from "../../../App";
const CalenderDrawer = (props) => {
    const {
        appointmentLists,
        setOpen,
        setOpenAppointmentDetail,
    } = props

    const [expanded, setExpanded] = useState(false);

    const handleToggleExpand = () => {
      setExpanded(!expanded);
    };
    const MAX_LENGTH = 30;
console.log("data calendar",  appointmentLists)
    const handleDrawerClose = () => {
        console.log('Testing')
        setOpen(false)
    }
    return (
        <Drawer   
            ModalProps={{
                BackdropProps: {
                    invisible: true,
                },
            }}
            variant="temporary"
            open={appointmentLists !== null}
            anchor={"right"}
            onClose={handleDrawerClose}
            sx={{
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    top: "69px",
                    width: {
                        xs: '80%',
                        sm: "400px",
                    },
                    ...scrollCSS,
                    "&::-webkit-scrollbar": {
                        width: "10px",
                        height: "10px"
                      },
                      "&::-webkit-scrollbar-track": {
                        background: "#fff"
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "4px",
                        background: "rgba(0, 0, 0, 0.2)",
                        boxShadow: "inset 1px 1px 0px rgba(0, 0, 0, 0.1), inset 0px -1px 0px rgba(0, 0, 0, 0.07)"
                      }
                },
            }}
        >
            <Box
                sx={{
                    p: "16px",
                }}
            >
                <Box
                    sx={{
                        width: 'max-content',
                        p: '5px',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "space-between",
                        cursor: "pointer",
                        "&:hover":{
                            opacity:'0.7'
                        }
                    }}
                    component={Paper}
                    elevation={3}
                    onClick={handleDrawerClose}
                >
                    <img src={close} alt={'close'}/>
                </Box>
                <Box sx={{mt: '24px'}}>
                    <Typography
                        sx={{
                            color: "#4F4F4F",
                            fontSize: '18px',
                            fontWeight: '300',
                        }}
                    >   
                        Appointment for
                    </Typography>
                    <Typography
                        sx={{
                            color: "#000000",
                            fontSize: '25px',  
                            fontWeight: '400',
                            mt: '8px', 
                        }}
                    >
                        {appointmentLists?.length > 0 && moment(appointmentLists[0]?.date).format("dddd, MMMM DD, YYYY")}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        height: '100%',
                        mt: '24px',
                    }}
                >

                    {appointmentLists?.length > 0 && appointmentLists.map((appointment, index) => (
                        <Box
                            key={index}
                            sx={{
                                borderBottom: '1px solid #BDBDBD',
                                pb: "16px",
                            }}
                        >
                            <Box

                                sx={{  
                                    display: 'flex',
                                    mt: '10px',
                                    cursor: 'pointer'
                                }}   
                                onClick={() => {   
                                    setOpen(false);
                                    setOpenAppointmentDetail(appointment?.id);
                                }}
                            >
                                <Box sx={{mt: '6px'}}>
                                    <Box
                                        sx={{
                                            background: appointment?.status_color,
                                            p: '6px',
                                            borderRadius: '2px',
                                            mr: '7px',
                                        }}
                                    > </Box>  
                                </Box>
                                <Box
                                    sx={{
                                        ml: "8px",    
                                    }}
                                >
                                    <Typography 
                                        sx={{
                                            color: "#000000",
                                            fontSize: '17px',
                                            fontWeight: '700',
                                            textTransform:'capitalize',
                                        }}
                                    >
                                        {appointment?.customer?.name}
                                    </Typography>
                                    <Typography
                                         sx={{
                                            color: "#000000",
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            overflowWrap:'anywhere'
                                        }}
                                    >
                                        {appointment?.service_name ? `(${appointment.service_name}${
                                            appointment.type === "Booking"
                                                ? ''
                                                : appointment?.type === "Consultation"
                                                    ? " (In Person Consultation)"
                                                    : " (Virtual)"
                                        })` : ""}
                                    </Typography>
                                    {appointment?.appointment_customer_note && typeof appointment.appointment_customer_note === 'object' && appointment.appointment_customer_note !== null && (
    <Box>
        <Typography   sx={{
                                            color: "#000000",
                                            // fontSize: '16px', 
                                            fontWeight: '800',    
                                            overflowWrap:'anywhere'  
                                        }}>Appointment Notes:</Typography>
        <Typography
        // sx={content}
          onClick={appointment.appointment_customer_note?.description.length > MAX_LENGTH ? handleToggleExpand : null}
        style={{
          cursor: appointment.appointment_customer_note?.description.length > MAX_LENGTH ? 'pointer' : 'default',
          overflow: 'hidden',
          textOverflow: 'ellipsis', 
          whiteSpace: expanded ? 'normal' : 'nowrap',     
          display: 'inline-block',
          maxWidth: '100%',
        }}
      >   
        {expanded
          ? appointment.appointment_customer_note?.description
          : appointment.appointment_customer_note?.description.slice(0, MAX_LENGTH) + (appointment.appointment_customer_note?.description.length > MAX_LENGTH ? '...' : '')}
      </Typography>
    </Box>
)}
                                    <Typography 
                                             sx={{     
                                            color: "#000000",
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            overflowWrap:'anywhere'  
                                        }}
                                    >
                                        {appointment?.customer?.email}

                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#000000",
                                            fontSize: '16px',
                                            fontWeight: '400',
                                        }}
                                    > 
                                        {appointment?.customer?.phone}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: "#000000",
                                            fontSize: '16px',
                                            fontWeight: '400',
                                        }}
                                    >
                                        {appointment?.start_time} - {appointment?.end_time}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Drawer>
    );
}

export default CalenderDrawer;