import React from 'react';
import {Autocomplete, Box, Checkbox, FormControl, FormHelperText, Grid, MenuItem, TextField} from "@mui/material";
import {countryList} from "./contries";
import * as Yup from "yup";
import {useFormik} from "formik";
import {findGuestUser} from "../../store/booking-widget";
import {bookingInput} from "./bookingContact";
import {useDispatch} from "react-redux";

const ContactForm = ({handleSubmitForm,bookingNow,handleNext,isPaid,setRegisterData}) => {

    const dispatch = useDispatch()


    const validationSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email address").required("Email is required"),
        last_name: Yup.string().required("Last name is required"),
        first_name: Yup.string().required("First name is required"),
        countryCode: Yup.mixed().required('Please select a country code').typeError('Please select a country code'),
        phoneNumber: Yup.string().test('isValidPhoneNumber', 'Please enter a valid phone number', async function (value) {
            const countryCode = this.parent?.countryCode;
            if (!value || !countryCode) {
                return true;
            }
            try {
                let ers = await countryCode.validationSchema.validate({phoneNumber: value});
                return true;
            } catch (error) {
                return error;
            }
        }).required("Phone number is required"),
    });


    const {
        errors,
        setFieldValue,
        setFieldTouched,
        values,
        setFieldError,
        touched,
        handleChange,
        handleSubmit
    } = useFormik({
        initialValues: {
            countryCode: '+1',
            phoneNumber: '',
            email: "",
            first_name: "",
            last_name: "",
            description: "",
        },
        validationSchema,
        onSubmit: async (data) => {
            let finalData = {
                name: data?.first_name + " " + data?.last_name,
                email: data?.email,
                phone: data?.countryCode?.phoneCode ?? data?.countryCode + data?.phoneNumber,
                social_login: 0,
                is_guest: 1,
                postal_code: "+1",
                password: "3Dlifestyle!",
            }
           await setRegisterData(finalData)
            if (isPaid && isPaid > 0){
                handleNext()
            }else {
                await handleSubmitForm(finalData)
            }

        },
    });

    

    return (
        <form onSubmit={handleSubmit}>
           <Grid container sx={{justifyContent: "space-between"}}>
               <Grid item xs={12} md={5.7} sx={{mt: '16px',}}>
                   <FormControl fullWidth>
                       <Autocomplete
                           options={countryList}
                           value={countryList.find(country => country.countryName === "Canada") || null}
                           sx={{
                               ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                                   height: "15px",
                               }
                           }}
                           disableCloseOnSelect={false}
                           isOptionEqualToValue={(option, value) => {
                               return option?.countryName === value?.countryName
                           }}
                           getOptionLabel={(option) => option?.countryName || ''}
                           renderInput={(params) => {
                               const {inputProps, helperText, error, ...rest} = params;
                               return (
                                   <TextField  {...rest} inputProps={{...inputProps,readOnly: true}} placeholder={"Select"}/>
                               )
                           }}

                           onChange={(event, newValue) => {
                            console.log("🚀 ~ file: ContactForm.js:102 ~ ContactForm ~ newValue:", newValue)
                            
                               setFieldValue("countryCode", newValue);
                           }}
                           renderOption={(props, option, {selected}) => (
                               <MenuItem  {...props} >
                                   <Checkbox
                                       style={{marginRight: 8}}
                                       checked={selected}
                                   />
                                   {option?.countryName}
                               </MenuItem>
                           )}
                       />
                       {errors?.countryCode && touched?.countryCode && (
                           <FormHelperText sx={{color: "#CB3838", width: "max-content", mx: 0}}>
                               {errors?.countryCode}
                           </FormHelperText>
                       )}
                   </FormControl>

               </Grid>
               <Grid item xs={12} md={5.7} sx={{mt: '16px',}}>
                   <FormControl fullWidth>
                       <Box style={{display: "flex", alignItems: "flex-end"}}>
                           <Box sx={{
                               p: '10.9px 16px',
                               border: '1px solid #C4C4C4',
                               borderRight: '0',
                               borderBottomLeftRadius: '4px',
                               borderTopLeftRadius: '4px',
                           }}>
                               {values?.countryCode?.phoneCode || "+1"}
                           </Box>
                           <TextField
                               fullWidth
                               /*disabled={!values?.countryCode}*/
                               variant="outlined"
                               name={"phoneNumber"}
                               sx={{
                                   ...bookingInput,
                                   ".MuiInputBase-root ": {
                                       borderBottomLeftRadius: '0px',
                                       borderTopLeftRadius: '0px',
                                   }
                               }}
                               placeholder={"000 0000 000"}
                               value={values?.phoneNumber}
                               onChange={handleChange}
                               inputProps={{
                                   maxLength: 10,
                               }}
                           />
                       </Box>
                       {errors?.phoneNumber && touched?.phoneNumber && (
                           <FormHelperText sx={{color: "#CB3838", width: "max-content", mx: 0}}>
                               {errors?.phoneNumber}
                           </FormHelperText>
                       )}
                   </FormControl>
               </Grid>
           </Grid>
           <Grid container sx={{my: "8px"}}>
               <Grid item xs={12}>
                   <FormControl fullWidth>
                       <TextField
                           fullWidth
                           variant="outlined"
                           name={"email"}
                           sx={bookingInput}
                           placeholder={"Email"}
                           value={values?.email}
                           // onBlur={(e)=>handleEmailBlur(e.target?.value)}
                           onChange={handleChange}
                       />
                       {errors?.email && touched?.email && (
                           <FormHelperText sx={{color: "#CB3838", width: "max-content", mx: 0}}>
                               {errors?.email}
                           </FormHelperText>
                       )}
                   </FormControl>
               </Grid>
           </Grid>
           <Grid container sx={{justifyContent: "space-between"}}>
               <Grid item xs={12} md={5.7}>
                   <FormControl fullWidth>
                       <TextField
                           fullWidth
                           variant="outlined"
                           name={"first_name"}
                           sx={bookingInput}
                           placeholder={"First name"}
                           value={values?.first_name}
                           onChange={handleChange}
                       />
                       {errors?.first_name && touched?.first_name && (
                           <FormHelperText sx={{color: "#CB3838", width: "max-content", mx: 0}}>
                               {errors?.first_name}
                           </FormHelperText>
                       )}
                   </FormControl>
               </Grid>
               <Grid item xs={12} md={5.7}>
                   <FormControl fullWidth>
                       <TextField
                           fullWidth
                           variant="outlined"
                           name={"last_name"}
                           sx={bookingInput}
                           placeholder={"Last name"}
                           value={values?.last_name}
                           onChange={handleChange}
                       />
                       {errors?.last_name && touched?.last_name && (
                           <FormHelperText sx={{color: "#CB3838", width: "max-content", mx: 0}}>
                               {errors?.last_name}
                           </FormHelperText>
                       )}
                   </FormControl>
               </Grid>
           </Grid>
           <Grid container sx={{mt: "8px"}}>
               <Grid item xs={12}>
                   <FormControl fullWidth>
                       <TextField
                           fullWidth
                           variant="outlined"
                           name={"description"}
                           sx={bookingInput}
                           placeholder={"Appointment notes (optional)"}
                           value={values?.description}
                           onChange={handleChange}
                           minRows={4}
                           multiline
                       />
                   </FormControl>
               </Grid>
           </Grid>
            {bookingNow()}
        </form>
    );
};

export default ContactForm;