import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid, MenuItem,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState,useEffect } from "react";
import DateRange from "../../../components/date-range/date-range";
import useStyles from "./style";
import {useDispatch, useSelector} from "react-redux";
import {getAllConsumeInvoice, getAllConsumeInvoiceSearch, getAllProducts} from "../../../store/crm/inventory";
import Loader from "../../../components/loader/loader";
import moment from "moment";
import Waveloading from "../../loading/Waveloading";


const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
];


const InvoiceConsume = () => {
  const [startDate, setStartDate] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(false)


  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const scrollTop = window?.pageYOffset || document.documentElement.scrollTop;
    setIsSticky(scrollTop !== 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const inventoryStore = useSelector(state => state?.inventoryStore)
  const {
    invoiceConsume = [],
    products = [],
  } = inventoryStore

  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      setLoading(true)
      await dispatch(getAllConsumeInvoice())
      setLoading(false)
      await dispatch(getAllProducts())
    })()
  }, [])

  const handleSearch = async () => {
    setLoading(true)
    await dispatch(getAllConsumeInvoiceSearch({
      start:startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      end:endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      product: selectedProduct}))
    setLoading(false)
  }

  const handleReset =async () => {
    setLoading(true)
    setStartDate(null)
    setEndDate(null)
    setSelectedProduct(null)
    await dispatch(getAllConsumeInvoice())
    setLoading(false)
  }
  return (
      <Box
          sx={{   
            minHeight: "calc(100vh - 109px)",
            background: "#fff",
            padding: {xs:"10px",sm:"24px"},
            borderRadius: "7px",
          }}
      >
        {/*<Typography variant="h5" color="initial" sx={{mt: 5}}>*/}
        {/*  Consumed Invoice List*/}
        {/*</Typography>*/}
        {/*<Grid*/}
        {/*    container*/}
        {/*    sx={{*/}
        {/*      display: "flex",*/}
        {/*      justifyContent: "space-between",*/}
        {/*      alignItems: "center",*/}
        {/*    }}*/}
        {/*>*/}
        {/*  <Grid item lg={3.9} md={5.5} sm={5.9} xs={12}>*/}
        {/*    <Autocomplete*/}
        {/*        sx={{mt: 2}}*/}
        {/*        disablePortal*/}
        {/*        id="combo-box-demo"*/}
        {/*        options={top100Films}*/}
        {/*        renderInput={(params) => <TextField {...params} label="Product"/>}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*  <Grid item lg={3.9} md={5.5} sm={5.9} xs={12}>*/}
        {/*    <TextField*/}
        {/*        id="outlined-basic"*/}
        {/*        label="Quantity"*/}
        {/*        variant="outlined"*/}
        {/*        sx={{width: "100%", mt: 2}}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*  <Grid item lg={3.9} md={5.5} xs={5.9} sx={{mt: 2}}>*/}
        {/*    <FormControlLabel*/}
        {/*        control={<Switch defaultChecked/>}*/}
        {/*        label="Status"*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<Grid container sx={{display: "flex", justifyContent: "space-between"}}>*/}
        {/*  <Grid item lg={3.9} md={5.5} sm={5.9} xs={12}>*/}
        {/*    <TextField*/}
        {/*        id="outlined-basic"*/}
        {/*        label="Price"*/}
        {/*        variant="outlined"*/}
        {/*        sx={{width: "100%", mt: 2}}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*  <Grid item lg={3.9} md={5.5} sm={5.9} xs={12}>*/}
        {/*    <TextField*/}
        {/*        id="outlined-basic"*/}
        {/*        label="Note"*/}
        {/*        variant="outlined"*/}
        {/*        sx={{width: "100%", mt: 2}}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*  <Grid item lg={3.9} md={5.5} sm={5.9} xs={12}>*/}
        {/*    <Autocomplete*/}
        {/*        sx={{mt: 2}}*/}
        {/*        disablePortal*/}
        {/*        id="combo-box-demo"*/}
        {/*        options={top100Films}*/}
        {/*        renderInput={(params) => <TextField {...params} label="Supplier Name"/>}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
        {/*<Button variant="outlined" color="primary" sx={{mt: 4}}>*/}
        {/*  Save*/}
        {/*</Button>*/}
        <Grid
            container
            sx={{display: "flex", justifyContent: "space-between", alignItems: 'center', mt: 5}}
        >
          <Grid item lg={4.9} md={4.9} sm={5.9} xs={12}>
            <FormControl
                sx={{
                  // mt: {xs: 2, sm: 1, lg: "15px"},
                  width:'100%',
                }}
            >
              <FormLabel>Select Date Range</FormLabel>
              <Box
                  sx={{
                    border: '1px solid #C4C4C4',
                    borderRadius: '4px',
                    height:'56px',
                  }}
              >
                <DateRange
                    setStartDate={setStartDate}
                    startDate={startDate}
                    setEndDate={setEndDate}
                    endDate={endDate}
                />
              </Box>
            </FormControl>
          </Grid>
          <Grid item lg={4.9} md={4.9} sm={5.9} xs={12}>
            <Autocomplete
                sx={{
                  mt: 3
                }}
                options={products}
                value={selectedProduct}
                disableCloseOnSelect={false}
                isOptionEqualToValue={(option, value) => {
                  return option?.id === value?.id
                }}
                getOptionLabel={(option) => option?.name || ''}
                renderInput={(params) => {
                  const {inputProps, helperText, error, ...rest} = params;
                  return (
                      <TextField
                          {...rest}
                          inputProps={{...inputProps}}
                          label="Product Name"
                      />
                  )
                }}
                onChange={(event, newValue) => {
                  setSelectedProduct( newValue);
                }}
                renderOption={(props, option, {selected}) => (
                    <MenuItem  {...props} key={option?.id}>
                      <Checkbox
                          style={{marginRight: 8}}
                          checked={selected}
                      />
                      {option?.name}
                    </MenuItem>
                )}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={5.9} xs={12} sx={{mt:"12px"}}>
            {/* <Button variant="outlined" color="error" sx={{ mr:1}} onClick={()=>handleReset()}>
              Clear Search
            </Button> */}
            <Button variant="outlined" color="primary" sx={{ }} onClick={()=>handleSearch()}>
              Search
            </Button>
          </Grid>
        </Grid>
        <Typography variant="h5" color="initial" sx={{mt: 5}}>
          Purchase List
        </Typography>
        <TableContainer component={Paper} sx={{mt: 5, maxHeight: "650px"}}>
          {loading ? <Waveloading/> :
              <Table sx={{}} aria-label="simple table">
                <TableHead className={`${classes.tableHead}${isSticky ? ` stickyTop` : ''}`}>
                  <TableRow>
                    <TableCell
                        align="left"
                        sx={{
                          fontWeight: "700",
                          borderBottom: 0,
                        }}
                    >
                      <Checkbox/>
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{
                          fontWeight: "700",
                          borderBottom: 0,
                        }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{
                          fontWeight: "700",
                          borderBottom: 0,
                        }}
                    >
                      Booking Id
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{
                          fontWeight: "700",
                          borderBottom: 0,
                        }}
                    >
                      Service Name
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{fontWeight: "700", borderBottom: 0}}
                    >
                      Product Name
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{fontWeight: "700", borderBottom: 0}}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                        align="left"
                        sx={{fontWeight: "700", borderBottom: 0}}
                    >
                      Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    invoiceConsume?.length > 0 ?
                        invoiceConsume?.map((row, index) =>  (
                            <TableRow
                                key={index}
                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                className={classes.odd}
                            >
                              <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableLink}
                                  sx={{cursor: "pointer"}}
                              >
                                <Checkbox/>
                              </TableCell>
                              <TableCell
                                  component="th"
                                  scope="row"
                                  className={classes.tableLink}
                                  sx={{cursor: "pointer"}}
                              >
                                {row?.id}
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="body1">{row?.booking_id}</Typography>
                              </TableCell>
                              <TableCell align="left">
                                <Typography variant="body1">
                                  {row?.service_name}
                                </Typography>
                              </TableCell>
                              <TableCell align="left">{row?.product?.name}</TableCell>
                              <TableCell align="left">{row?.quantity}</TableCell>
                              <TableCell align="left">
                                {row?.created_at}
                              </TableCell>
                            </TableRow>
                        )) :
                        <TableRow>
                          <TableCell
                              colSpan={'7'}
                              align={'center'}
                              sx={{
                                height: '300px',
                              }}
                          >
                            No Invoice Consume List Available
                          </TableCell>
                        </TableRow>
                  }
                </TableBody>
              </Table>
          }
        </TableContainer>
      </Box>
  );
};

export default InvoiceConsume;
