import React, { useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import Box from "@mui/material/Box";
import { Button, Grid, Typography } from "@mui/material";
import TimelineContent from "@mui/lab/TimelineContent";
import useStyles from "./style";
import { MySwal, getBorderColor, successConfirmation } from "../../../../../services/utills";
import moment from "moment";
import { useNavigate } from "react-router";
import { resendInvoice } from "../../../../../store/crm/pos";
import { useDispatch } from "react-redux";
import {
    mySwalButton,
    ribbonwrapper,
  } from "../../../../../components/card-appointment/CardAppointment";
import CustomButtoncolor from "../../../../../components/custom-button/customColorButton";
import Waveloading from "../../../../loading/Waveloading";

const Invoices = ({ appointmentInvoices }) => {
    const classes = useStyles();     
    const [loading, setLoading] = useState(false);

    console.log("invoice data ", appointmentInvoices)
    const navigate = useNavigate()
    const dispatch = useDispatch();  
    const handleAppointmentClick = (url) => {
        navigate(url)
    }
    const handleResendInvoice = async (id) => {
        let confirm = await MySwal.fire({
          icon: "question",
          title: "Resend Invoice",
          showConfirmButton: false,
          html: (
            <Box sx={{ mb: 4 }}>
              <Typography sx={{}}>
                Are you sure? You want to Resend Invoice of this appointment .
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Button
                  sx={{ ...mySwalButton }}
                  onClick={() => MySwal.clickConfirm()}
                >
                  Yes
                </Button>
                <Button sx={{ ...mySwalButton }} onClick={() => MySwal.close()}> 
                  Cancel
                </Button>
              </Box>
            </Box>
          ),
        });
        if (!!confirm?.isConfirmed) {
          setLoading(true)
          let res = await dispatch(resendInvoice({booking_id:id}))
          if (res?.payload?.success === "true") {
            await successConfirmation('Invoice Resend Successfully')
          }
          setLoading(false)
        }
      };
    return (
        <Box>
            {appointmentInvoices?.length > 0 ?
                <Timeline
                    sx={{
                        p: "0",
                        my: "0",
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,  
                        },
                    }}
                    position="right"
                >
                    {appointmentInvoices?.length > 0 &&
                        appointmentInvoices.map((invoice, index) => (
                            <TimelineItem key={index}>
                                <TimelineSeparator sx={{ display:{xs:'none',sm:'flex'}, }}>
                                    <TimelineConnector
                                        sx={{
                                            background: "#2F80ED",
                                            width: "16px",
                                            borderRadius: "10px",
                                            border: "6px solid #fff",
                                            maxHeight: "50px",
                                            mb: "-10px",
                                            borderTop: index === 0 ? "6px solid #fff" : "0",
                                            borderTopLeftRadius: index === 0 ? '10px' : "0",
                                            borderTopRightRadius: index === 0 ? '10px' : "0",
                                        }}
                                    />
                                    <TimelineDot
                                        variant="outlined"
                                        sx={{
                                            mx: "auto",
                                            my: "0",
                                            borderWidth: "5px",
                                            borderColor: getBorderColor(invoice?.status),
                                            zIndex: "10",
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            height: "75px",
                                            width: "90px",
                                            mt: "-8px",
                                            background: "#fff",
                                            borderRadius: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => handleAppointmentClick(`/crm/appointment/details/${invoice?.booking_id}`)}
                                    >
                                        <Typography sx={{ fontSize: "13px" }}>Booking No</Typography>
                                        <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                                            {invoice?.booking_id}
                                        </Typography>
                                    </Box>
                                    <TimelineConnector
                                        sx={{
                                            background: "#2F80ED",
                                            width: "16px",
                                            borderRadius: "10px",
                                            border: "6px solid #fff",
                                            borderBottomLeftRadius: "0",
                                            borderBottomRightRadius: "0",
                                            mt: "-7px",
                                            borderBottom: '0',
                                        }}
                                    />
                                </TimelineSeparator>
                                <TimelineContent sx={{ pl: {xs:"0px",sm:"32px"}, pb: {xs:"8px",sm:"32px"}, pt: 0, pr: 0 }}>
                                    <Box
                                        sx={{
                                            p: {xs:"12px",sm:"24px"},
                                            background: "#fff",
                                            height: "100%",
                                            borderRadius: "10px",
                                        }}
                                    >
                                        <Box style={{display:"flex",alignItems:"center"}}>
                                            <Box >

                                          
                                        <Typography
                                                         variant="body2"
                                                        color="initial"
                                                        sx={{
                                                            color: "#56CCF2",
                                                            fontSize: '16px',
                                                            fontFamily: 'helvetica-lt-std-bold'
                                                        }}
                                                    >
                                                       {invoice?.provider_name}
                                                    </Typography>
                                                    </Box>
                                                    { invoice?.payment_status=== "Paid" && (
                        <div style={{marginLeft:"40px" , marginBottom: "2%" }}>
                          <CustomButtoncolor
                              handleClick={() => handleResendInvoice(invoice?.booking_id)}
                              mainColor="#56CCF2"
                              size="large"
                              val="Resend Invoice"
                              style={{ width: "100%", borderRadius: "6px!important" }} // Note: Using !important is generally not recommended
                          />
                        </div>
                    )}
                                        </Box>
                                        <Box style={{display:"flex",alignItems:"right",marginTop:"1px"}}>


                                            <Typography
                                                variant="body2"
                                                color="initial"
                                                sx={{
                                                    color: "#000",
                                                    fontSize: '18px',
                                                    fontFamily: 'helvetica-lt-std-bold'
                                                }}
                                            >
                                                Reference Clinic:
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="initial"
                                                sx={{
                                                    ml: 1,
                                                    color: "#56CCF2",
                                                    fontSize: '16px',
                                                    fontFamily: 'helvetica-lt-std-bold'
                                                }}
                                            >
                                                {invoice?.reference_clinic_name}
                                            </Typography>
                                        </Box>

                                        {loading ? <Waveloading fullSize /> : ""}
                                        <Grid container sx={{ display: "flex" }}>
                                            <Grid item xs={12} xl={3.4}>
                                                <Box>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ color: "#000", textDecoration:'underline' }}
                                                    >
                                                        Appointment Date:
                                                    </Typography>
                                                    <Typography
                                                        variant="h4"
                                                        color="initial"
                                                        sx={{ mt: 1, fontFamily: "helvetica-lt-std-bold" }}
                                                    >
                                                        {moment(invoice?.booking_date).format("dddd MMMM D, YYYY")}

                                                    </Typography>
                                                    <Typography
                                                        variant="h5"
                                                        color="initial"
                                                        sx={{ fontFamily: "helvetica-lt-std-bold" }}
                                                    >
                                                        {invoice?.start_time} -{" "}
                                                        {invoice?.end_time}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} xl={8} sx={{ borderBottom: "1px solid #C4C4C4" }}>
                                                <Box>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ color: "#000", textDecoration:'underline' }}
                                                    >
                                                        Service Invoice:
                                                    </Typography>
                                                </Box>
                                                {invoice?.services?.map((service, serviceIndex) => (
                                                    <Box sx={{ width: '100%' }} key={serviceIndex}>
                                                        <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', my: "5px" }}>
                                                            <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                                                                {service?.name} (x{1+service?.extra_quantity})
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="initial"
                                                                sx={{ ml: 1, fontFamily: "helvetica-lt-std-bold" }}
                                                            >
                                                                ${service?.booking_cost}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{ display:'flex',justifyContent:{xs:'space-between',xl:'normal'},flexWrap:"nowrap" }}>
                                            <Grid lg={3.4} item>
                                                <Typography
                                                    variant="body2"
                                                    color="initial"
                                                    sx={{ color: "#000", textDecoration:'underline', mt:{sm:4, xs:2}, fontSize: '16px', fontFamily: 'helvetica-lt-std-bold' }}
                                                >
                                                    Booking Details:
                                                </Typography>

                                                <Box sx={{ display: "flex", mt: 2,cursor: 'pointer',
                                                }}
                                                     onClick={() => handleAppointmentClick(`/crm/appointment/details/${invoice?.booking_id}`)}

                                                >
                                                    <Typography variant="body2" color="initial">
                                                        Booking id:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        style={{ color: '#2F80ED' }}

                                                        sx={{ ml: 1 }}
                                                    >
                                                        # {invoice?.booking_id}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: "flex" }}>
                                                    <Typography variant="body2" color="initial">
                                                        {/*{appointmentDetails?.invoices?.invoices_methods?.length > 1 ? "Ref IDs" : "Ref ID"}:*/}
                                                        Invoice ID:
                                                    </Typography>
                                                    <Box sx={{ display: 'flex',cursor: 'pointer' }}
                                                         onClick={() => handleAppointmentClick(`/crm/invoices/${invoice?.booking_id}`)}
                                                    >
                                                        {invoice?.invoices_methods?.length > 0 &&
                                                            <Typography variant="body2" color="initial" sx={{ ml: 1 }}
                                                                        style={{ color: '#2F80ED' }}>
                                                                {invoice?.invoices_methods[0]?.id}
                                                            </Typography>
                                                        }
                                                        {/*{appointmentDetails?.invoices?.invoices_methods?.length > 0 && appointmentDetails?.invoices?.invoices_methods?.map((item, index) => (*/}
                                                        {/*    <Typography variant="body2" color="initial" sx={{ml: 1}} key={index}>*/}
                                                        {/*      {index > 0 ? ", " : ""}{item?.id}*/}
                                                        {/*    </Typography>*/}
                                                        {/*))}*/}
                                                    </Box>
                                                </Box>
                                                {/*<Box sx={{display: "flex"}}>*/}
                                                {/*    <Typography variant="body2" color="initial">*/}
                                                {/*        {invoice?.invoices_methods?.length > 1 ?"Ref IDs":"Ref ID"}:*/}
                                                {/*    </Typography>*/}
                                                {/*    <Box sx={{display:'flex'}}>*/}
                                                {/*        {invoice?.invoices_methods?.length> 0 && invoice?.invoices_methods?.map((item,index)=>(*/}
                                                {/*            <Typography variant="body2" color="initial" sx={{ml: 1}} key={index} >*/}
                                                {/*                {index>0?", " :""}{item?.id}*/}
                                                {/*            </Typography>*/}
                                                {/*        ))}*/}
                                                {/*    </Box>*/}
                                                {/*</Box>*/}

                                                {/*<Box sx={{display: "flex", mt: 1}}>*/}
                                                {/*    <Typography variant="body2" color="initial">*/}
                                                {/*        Booking Status:*/}
                                                {/*    </Typography>*/}
                                                {/*    <Typography*/}
                                                {/*        variant="body2"*/}
                                                {/*        color="initial"*/}
                                                {/*        sx={{ml: 1}}*/}
                                                {/*    >*/}
                                                {/*        Pending*/}
                                                {/*    </Typography>*/}
                                                {/*</Box>*/}
                                            </Grid>
                                            <Grid lg={8} item className={classes.invoicesBottom}>
                                                <Box sx={{ display: "flex", mt: 2 }}>
                                                    <Typography variant="body2" color="initial">
                                                        Sub Total:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ ml: 1 }}
                                                    >
                                                        ${invoice?.total}
                                                    </Typography>
                                                </Box>

                                                <Box sx={{ display: "flex", mt: 1 }}>
                                                    <Typography variant="body2" color="initial">
                                                        Discount:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ ml: 1 }}
                                                    >
                                                         ${invoice?.discount}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", mt: 2 }}>
                                                    <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                                                        Amount:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}
                                                    >
                                                        ${invoice?.amount_after_add_discount}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", mt: 1 }}>
                                                    <Typography variant="body2" color="initial">
                                                        Tax:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ ml: 1 }}
                                                    >
                                                        ${invoice?.tax}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", mt: 1 }}>
                                                    <Typography variant="body2" color="initial">
                                                        Tip:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ ml: 1 }}
                                                    >
                                                        ${invoice?.tip}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        mt: 1,
                                                        width: '100%',
                                                        justifyContent: 'flex-end',
                                                        borderBottom: "1px solid #C4C4C4",
                                                        paddingBottom: '10px',
                                                    }} >
                                                    <Typography sx={{ fontFamily: 'helvetica-lt-std-bold' }} variant="body2" color="initial">
                                                        Grand Total:
                                                    </Typography>
                                                   <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}
                                                    >
                                                        ${invoice?.amount_after_tip_and_tax}
                                                    </Typography>
                                                </Box>
                                                {invoice?.invoices_methods?.map((method, methodIndex) => (
                                                    <Box key={methodIndex} sx={{ display: "flex", mt: 1 }}>
                                                        <Typography variant="body2" color="initial">
                                                            {method?.payment_methods}:
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            color="initial"
                                                            sx={{ ml: 1 }}
                                                        >
                                                            {method?.payment_methods !== 'Future Payment' && "-"}  ${method?.amount}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                                <Box sx={{ display: "flex", justifyContent: 'end', mt: 1 }}>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{ fontFamily: "helvetica-lt-std-bold" }}>
                                                        Due Amount:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="initial"
                                                        sx={{
                                                            ml: 1,
                                                            fontFamily: "helvetica-lt-std-bold",
                                                        }}
                                                    >
                                                        ${invoice?.due_amount}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                        </Grid>

                                        <Grid container>
                                            <Grid item lg={3.4}>
                                                <Box sx={{ display: "flex", mt: 2 }}>
                                                    <Typography variant="body2" color="initial" sx={{ color: '#000', textDecoration:'underline', fontSize: '16px', fontFamily: 'helvetica-lt-std-bold' }}>
                                                        Payment Details:
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: "flex", mt: 1 }}>
                                                    <Typography variant="body2" color="initial">
                                                        Payment Status:
                                                    </Typography>
                                                    <Typography
                                                        variant="body2" 
                                                        sx={{
                                                            ml: 1,
                                                            background: invoice?.payment_status === 'Paid' || invoice?.payment_status === 'Pre Paid' ? "#1C7E46" : '#C64949',
                                                            borderRadius: '13px',
                                                            color: '#fff', padding: "2px 12px",
                                                            height:"fit-content",display:'flex',alignItems:'center'
                                                        }}
                                                    >
                                                        {invoice?.payment_status}
                                                    </Typography>
                                                </Box>
             <Box sx={{ display: "flex" ,}}>
                <Typography variant="body2" color="initial" sx={{ fontFamily: 'helvetica-lt-std-bold' }}>
                  Transaction Id
                </Typography>
                <Typography variant="body2" color="initial" sx={{ ml: 1, fontFamily: 'helvetica-lt-std-bold' }}>
                {invoice?.transaction_id ? invoice?.transaction_id : 'N/A'}

                </Typography>
              </Box>
             
                                            </Grid>
                                            <Grid item lg={8}>

                                            </Grid>
                                            
                                        <Grid  item lg={12}>
                                        <Box sx={{ display: "flex",gap:2 }}>
              {invoice?.invoices_methods?.[0]?.payment_methods === "Finance Company" && (
    <Box style={{ display: "flex" }}>
      <Typography>Institution Name:</Typography>
      {invoice?.institution_name && (
        <Typography >{invoice?.institution_name} ,</Typography>
      )}
    </Box>
  )}
  {invoice?.invoices_methods?.[0]?.payment_methods === "Finance Company" && (
    <Box style={{ display: "flex" }}>
      <Typography >Approval Code:</Typography>
      {invoice?.approval_code && (
        <Typography >{invoice?.approval_code} ,</Typography>
      )}
    </Box>
  )}
  {invoice?.invoices_methods?.[0]?.payment_methods === "Finance Company" && (
    <Box style={{ display: "flex" }}>
      <Typography >Approval Date:</Typography>
      {invoice?.approval_date && (
        <Typography >{invoice?.approval_date}</Typography>
      )}
    </Box>
  )}
              </Box> 
                                        </Grid>
                                        </Grid>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        ))
                    }
                </Timeline> :
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontFamily: "helvetica-lt-std-bold",
                    }}
                >
                    No Invoices
                </Typography>
            }
        </Box>
    );
}

export default Invoices;
