import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import TotalMemberNamount from "./totalMemberNamount";
import { Input } from "@mui/icons-material";
export const inputStyle = {
  background: "#fff",
  borderRadius: "4px",
  width:"60%",
  '@media (max-width: 1600px) ': {
    width:'100%'
 },
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },

  ".MuiInputBase-input": {
    borderColor: " #fff",
    height: "11px",
    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};


const MemberReport = (props) => {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

  const {
    membershipTitle,
    memberSignup,
    memberSignupTotal,
    memberShipAmountTitle,
    memberShipAmountTotal,
    memberShipRenewel,
    memberShipRenewelTotal,
    totalRenewals,
    renewalsTotal,
    totalDicline,
    totalDiclineTotal,
    totalCancellations,
    totalCancellationsTotal,
    total69Member,
    total69MemberAmount,
    total99Member,
    total99MemberAmount,
    total149Member,
  total149MemberAmount,
  onClick,fetchData

  } = props;
  // const handleButtonClick = async () => {
  //   // Call fetchData with the selected date
  //   await fetchData(selectedDate);
  // };
  const handleDateChange = async (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    // Immediately call fetchData with the new selected date
    await fetchData(newDate);
  };
  const color =
    membershipTitle === "Daily Membership"
      ? "#000" // Black
      : "#000"; // Default color

  const iconStyle =
    membershipTitle === "Daily Membership"
      ? "rgb(102 106 147)"
      : "#F44336"; // Default color

  const background =
    membershipTitle === "Daily Membership"
      ? "#E5E7FD"
      : "#FFF7F8"; // Default color


      

  return (
    <Box sx={{cursor:'pointer'}} >
      <Box
        sx={{

          padding: {lg:'40px', xs:'20px 10px'},
          position: "relative",
          borderRadius: "8px",
          overflow: "hidden",
          background: background,
          height:'auto',
          '@media (min-width:1600px)': {padding:'20px 10px', height:'600px'}
        }}
      >
        <Box>
        <Typography
          variant="h5"
          sx={{ fontFamily: "helvetica-lt-std-bold", color: color, mb:3 }}
        >  
          {membershipTitle}  
        </Typography>
        <Box sx={{ display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:{lg:'center', xs:'start'}, '@media (min-width:1600px)': {flexDirection:'row'} , height:"100px" }}>

        <TextField
            variant="outlined"
            name="date" 
            sx={inputStyle}
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
          />
     


     {/* <Button sx={{height:'fit-content', '@media (min-width:1600px)': {marginTop:'0'}, mt:2}} variant="contained" onClick={handleButtonClick}>Fetch report</Button> */}
        </Box>


        </Box>
       
<Box>



        <Grid container sx={{ mt: 3 }}>
          <Grid item lg={6} sm={6} xs={6}>
            <Box>
              <Typography variant="h6" sx={{ color }}>
                {memberSignup}
              </Typography>
              <Typography  variant="body1" sx={{ color, fontSize:"20px" }}>
                {memberSignupTotal}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} sm={6} xs={6}>
            <Box>
              <Typography variant="h6" sx={{ color }}>
                {memberShipAmountTitle}
              </Typography>
              <Typography variant="body1" sx={{ color, fontSize:"20px"}}>
               $ {memberShipAmountTotal}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item lg={6} sm={6} xs={6}>
            <Box>
              <Typography variant="h6" sx={{ color }}>
                {memberShipRenewel}
              </Typography>
              <Typography variant="body1" sx={{ color, fontSize:"20px" ,}}>
                {memberShipRenewelTotal}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} sm={6} xs={6}>
            <Box>
              <Typography variant="h6" sx={{ color }}>
                {totalRenewals}
              </Typography>
              <Typography variant="body1" sx={{ color, fontSize:"20px" }}>
               $ {renewalsTotal}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }}>
          <Grid item lg={6} sm={6} xs={6}>
            <Box>
              <Typography variant="h6" sx={{ color }}>
                {totalDicline}
              </Typography>
              <Typography variant="body1" sx={{ color, fontSize:"20px"  }}>
                {totalDiclineTotal}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={6} sm={6} xs={6}>
            <Box> 
              <Typography variant="h6" sx={{ color}}>   
                {totalCancellations}
              </Typography>  
              <Typography variant="body1" sx={{ color, fontSize:"20px" }}>
                {totalCancellationsTotal}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        </Box>
        <Box>
          <TotalMemberNamount
            color={color}
            total69MemberTitle={"Total $69 Plan Member"}
            total69Member={total69Member}
            total69MemberAmount={total69MemberAmount}
            total99MemberTitle={"Total $199 Plan Member"}
            total99Member={total99Member}
            total99MemberAmount={total99MemberAmount}
            total149MemberTitle={"Total $149 Plan Member"}
            total149Member={total149Member}
            total149MemberAmount={total149MemberAmount}
          />
        </Box>

        <Box sx={{ position: "absolute", bottom: "-26px", right: "-24px" }}>
          <svg
            id="check-circle"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            style={{ width: "96px", color: iconStyle }}
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </Box>
      </Box>
      <Box />
    </Box>
  );
};

export default MemberReport;
