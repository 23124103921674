import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiHeaders } from "../../lib/apiHeader";
import axios from "axios";
import apis from "../../lib/apis/apis";
import toast from "react-hot-toast";

export const getAllPermission = createAsyncThunk("getAllPermission", async (data, { dispatch }) => {
    try {
      const response = await axios.get(apis.get_all_permission+`page=1&limit=100`, {
        headers: apiHeaders(),
      });

      if (response?.data?.success === "true") {
        return response?.data?.data;
      }
      if (response?.data?.success === false) {
        return [];
      }
    } catch (err) {
      if (err?.response?.status !== 200) {
        if (err?.response?.data?.message){
          toast.error(err?.response?.data?.message,{duration:3000})
        }
      }
    }
  }
);

export const storePermission = createAsyncThunk("storePermission", async (data) => {
  try {
    const response = await axios.post(
      apis.store_permission,
      { ...data },
      {
        headers: apiHeaders(),
      }
    );
    if (response?.data?.success) {
      return response.data;
    }
    if (response?.data?.success === false) {
      return response?.data;
    }
  } catch (err) {
    if (err?.response?.status !== 200) {
      return {
        data: [],
        errors: err?.response?.data?.errors,
      };
    }
  }
});

export const updatePermission = createAsyncThunk("updatePermission", async (data,{dispatch}) => {
  try {
    const response = await axios.post(
      apis.update_permission,
      { ...data },
      {
        headers: apiHeaders(),
      }
    );

    if (response?.data?.success==='true'){
      await dispatch(getAllPermission())

      return  response.data
    }else {
      return response?.data
    }

  } catch (err) {
    console.log(err)
    if (err?.response?.status!==200){
      return {
        data :err?.response,
        errors:true
      }
    }
  }
});


export const getPermissionByID = createAsyncThunk('getPermissionByID', async (id) => {
  try {
    const response = await axios.get(apis.get_permission_by_id+id,{
      headers: apiHeaders()
    })
    return  response?.data

  }catch (err){
    if (err?.response?.status!==200){
      return {
        data : [],
        errors:err?.response?.data?.errors
      }
    }
  }
})
export const deletePermission = createAsyncThunk("deletePermission", async (id,{dispatch}) => {
  try {
    const response = await axios.get(
      apis.delete_permission+id,
      {
        headers: apiHeaders(),
      }
    );
    if (response?.data?.success ==='true'){
      await dispatch(getAllPermission())
      toast.success("Permission Deleted Successfully",{duration:3000})

    }else {
      console.log(response?.data)
    }

  }catch (err){
    console.log(err)
    if (err?.response?.status!==200){
      toast.error(err?.message,{duration:4000})
    }
  }
});

export const permissionSlice = createSlice({
  name: "permissionSlice",
  initialState: {
    permissions: [],
    meta: {},
    links: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllPermission.fulfilled, (state, action) => {
      state.permissions = action?.payload ||[];
      state.meta = action?.payload?.meta || {};
      state.links = action?.payload?.links || {};
    });
  },
});

export default permissionSlice.reducer;
