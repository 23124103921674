import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";

import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {scrollCSS} from "../../App";
import {addWhiteShade} from "../../services/utills";

export function MonthView({ startDate,data,setStaffData }) {
    const monthStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const monthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    const daysInMonth = monthEnd.getDate();
    const firstDayOfWeek = monthStart.getDay() === 0 ? 7 : monthStart.getDay();

    const lastDayOfWeek = monthEnd.getDay();


    const weeks = [];
    let days = [];
  

    // Add empty cells for days before the first day of the month
    for (let i = 1; i < firstDayOfWeek; i++) {
        days.push(<TableCell key={`empty-${i}`}>
            <Box sx={{
                minHeight: '120px',
                width: '170px',

            }}>

            </Box>
        </TableCell>);
    }

    const defaultColor= "#f2f2f2"
    let cellData =(item)=> {

        // const modifiedColor = addWhiteShade(item?.color);
        const modifiedColor = item?.color ? addWhiteShade(item?.color) : defaultColor;

        return item?.checkin && item?.checkout && (
            <Box key={item?.id} sx={{
                backgroundColor: modifiedColor,
                color: item?.color,
                mt: '5px',
                display: 'flex',
                p: '5px 10px',
                borderRadius: '5px',
                justifyContent: 'space-between'
            }}>
                <Box>
                    <Box component={Typography} sx={{fontSize: '10px', fontWeight: "bold"}}>{item?.name}</Box>
                    <Box sx={{fontSize: '10px'}} component={Typography}>{item?.checkin}- {item?.checkout}</Box>
                </Box>

                <Box sx={{display: 'block', }}>
                    <Box sx={{
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        px: '5px',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                         onClick={()=>{setStaffData(item)}}
                    >
                        <CreateIcon sx={{fontSize: '11px'}}/>
                        <Box sx={{fontSize: '11px', ml: "4px"}}>edit</Box>
                    </Box>
                    {/*<Box sx={{*/}
                    {/*    backgroundColor: '#fff',*/}
                    {/*    px: '5px',*/}
                    {/*    mt: '5px',*/}
                    {/*    borderRadius: '10px',*/}
                    {/*    display: 'flex',*/}
                    {/*    justifyContent: 'center',*/}
                    {/*    alignItems: 'center',*/}
                    {/*    cursor: 'pointer'*/}
                    {/*}}>*/}
                    {/*    <DeleteOutlineIcon sx={{fontSize: '11px'}}/>*/}
                    {/*    <Box sx={{fontSize: '11px', ml: "4px"}}>delete</Box>*/}
                    {/*</Box>*/}
                </Box>
            </Box>
        )
    }
    // Add cells for each day of the month


    for (let i=0;i<data?.length;i++){


        days.push(<TableCell key={i}>
            <Box sx={{
                height: '150px',
                width: '170px',
                overflowY: 'auto',
                ...scrollCSS,
                "&::-webkit-scrollbar ": {
                    width: "0px",
                },
            }}>
                <Box>
                    {i+1}
                </Box>
                {data[i]?.staffs?.length && data[i]?.staffs.map((item) => (cellData( item)))}
            </Box>
        </TableCell>);

        if ((i + firstDayOfWeek) % 7 === 0 ) {
            weeks.push(<TableRow key={`week-${i}`}>{days}</TableRow>);
            days = [];
        }

    }


    if (days.length !== 0) {
        const emptyCellsCount = 7 - days.length;
        for (let i = 0; i < emptyCellsCount; i++) {
            days.push(
                <TableCell key={`empty-end-${i}`}>
                    <Box sx={{height: '120px', width: '120px'}}>
                    </Box>
                </TableCell>
            );
        }

        weeks.push(<TableRow key={`week-end`}></TableRow>);
    }



    return (
        <TableContainer component={Paper} sx={{boxShadow: 'none',}}>
            <Table aria-label="simple table" sx={{
                boxShadow: 'none', "td , th": {
                    border: "1px solid #E0E0E0",
                   
                   
                }
            }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{background: '#fff'}}>Monday</TableCell>
                        <TableCell sx={{background: '#fff'}}>Tuesday</TableCell>
                        <TableCell sx={{background: '#fff'}}>Wednesday</TableCell>
                        <TableCell sx={{background: '#fff'}}>Thursday</TableCell>
                        <TableCell sx={{background: '#fff'}}>Friday</TableCell>
                        <TableCell sx={{background: '#fff'}}>Saturday</TableCell>
                        <TableCell sx={{background: '#fff'}}>Sunday</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {weeks}
                </TableBody>
            </Table>
        </TableContainer>
    );
}