import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DateRange from "../date-range/date-range";
import { useNavigate } from "react-router";
import moment from "moment";
import {useSelector} from "react-redux";

const WeeklyInsights = (props,onDatesChange) => {
  const [localStartDate, setLocalStartDate] = useState(new Date());
  const [localEndDate, setLocalEndDate] = useState(new Date());

  const authStore = useSelector((state) => state?.authStore);
  const { user } = authStore;

  const setCurrentMonthDates = () => {
    const now = new Date();
   
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    setLocalStartDate(startOfMonth);
    setLocalEndDate(endOfMonth);
  } 

  useEffect(() => { 
    setCurrentMonthDates(); 
  }, []);

  const handleDateChange = async (start, end) => {
    setLocalStartDate(start);
    setLocalEndDate(end);
    const response = await props.fetchData(start, end);
  };
  // const handleButtonClick = async () => {

     
     
  //   };
    // 
    const navigate = useNavigate(); 
    const handleClick = () => {
      navigate('/crm/member-report', { state: { 
        value: 'DeclinedMembers', 
        startDate: localStartDate, 
        endDate: localEndDate 
      } });
      
    };
    const handleNewuser = () => {       
      navigate('/crm/member-report', { state: { 
        value: 'AllMember', 
        startDate: localStartDate, 
        endDate: localEndDate 
      } });
       
    };
    const handleCancle= () => {
      navigate('/crm/member-report', { state: { 
        value: 'CancelledMembers', 
        startDate: localStartDate, 
        endDate: localEndDate 
      } });
       
    };
    const handleActive= () => {
      navigate('/crm/member-report', { state: { 
        value: 'AllMember', 
        startDate: moment(user?.created_at).format('YYYY-MM-DD'),
        endDate: localEndDate 
      } });
      
    };
  const {
    newMemberTitle,
    newMembersTotal,
    declinedTitle,
    declinedTotal,
    activeTitle,
    activeTotal,
    memberCancelationTotal,
    memberCancelationTitle,
  } = props;

  return (
    <Box sx={{ padding: {lg:"60px 20px", sm:'20px 5px'} }}>
      <Typography variant="h5" sx={{ fontFamily:'helvetica-lt-std-bold', ml:1,  mt:{xs:3}, }}>Members Overview</Typography>
      <Box sx={{marginLeft:"9px" , mt:{xs:3}, display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:{lg:'center', xs:'start'}, '@media (min-width:1600px)': {flexDirection:'row'},}}>

<DateRange
 widthDash="100%"
 smWidth="70%"
  color="#EDF2F7"
setStartDate={setLocalStartDate}
setEndDate={setLocalEndDate}
startDate={localStartDate}
endDate={localEndDate}
onChange={handleDateChange} 
/>
   {/* <Button sx={{height:"fit-content", '@media (min-width:1600px)': {marginTop:'0'}, mt:2, width:'fit-content'}} variant="contained" onClick={handleButtonClick}>
  Fetch Report
</Button> */}
</Box>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}  
      >
        <Grid
          item
          lg={5.9}
          sm={ 5.9 } xs={12}
          sx={{ background: "#E8F5E9", p: 8, borderRadius: "16px", mt:{xl:0,lg:2, sm:2, xs:2} }}
        >
          <Box align="center" sx={{cursor:"pointer"}} onClick={handleNewuser} >
            <Typography variant="h3" sx={{ color: "#283593", fontFamily:'helvetica-lt-std-bold' }}>
                {newMembersTotal}
            </Typography>
            <Typography variant="body1" sx={{ color: "#283593", fontFamily:'helvetica-lt-std-bold' }}>
               {newMemberTitle}   
            </Typography>   
          </Box>
        </Grid>
        <Grid
          item
          lg={5.9}
          sm={ 5.9 } xs={12}
          sx={{ background: "#E8F5E9", p: 8, borderRadius: "16px", mt:{xl:0,lg:2, sm:2, xs:2} }}
        >
          <Box align="center" sx={{cursor:"pointer",}} onClick={handleActive}>
            <Typography variant="h3" sx={{ color: "#2E7D32", fontFamily:'helvetica-lt-std-bold' }}>
               {activeTotal}
            </Typography>
            <Typography variant="body1" sx={{ color: "#2E7D32", fontFamily:'helvetica-lt-std-bold' }}>
               {activeTitle}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
      >
        <Grid
          item
          lg={5.9}
          sm={ 5.9 } xs={12}
          sx={{ background: "#E8EAF6", p: 8, borderRadius: "16px", mt:{xl:0,lg:2, sm:2, xs:2} }}
        >
          <Box align="center" onClick={handleClick}>
            <Typography variant="h3" sx={{ color: "#FFC300", fontFamily:'helvetica-lt-std-bold' }}>
             {declinedTotal}
            </Typography>
            <Typography variant="body1" sx={{ color: "#FFC300", fontFamily:'helvetica-lt-std-bold' }}>
            {declinedTitle}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          lg={5.9}
          sm={ 5.9 } xs={12}
          sx={{ background: "#FFF7F8", p: 8, borderRadius: "16px", mt:{xl:0,lg:2, sm:2, xs:2} }}
        >
          <Box align="center" style={{cursor:"pointer"}} onClick={handleCancle}>
            <Typography variant="h3" sx={{ color: "#F44336", fontFamily:'helvetica-lt-std-bold' }}>
              {memberCancelationTotal}
            </Typography>
            <Typography variant="body1" sx={{ color: "#F44336", fontFamily:'helvetica-lt-std-bold' }}>
               {memberCancelationTitle}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WeeklyInsights;
