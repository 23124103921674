import React, {useState} from 'react';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {scrollCSS} from "../../../App";
import {Typography} from "@mui/material";
import moment from "moment/moment";

const ViewNotes = ({notes}) => {
    const [active, setActive] = useState(0)
    const handleChange = (event, newValue) => {
        setActive(newValue)
    }

    return (
        <Box sx={{background:"#fff",minHeight:'100px',borderRadius: "8px"}}>
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
                width: 'max-content',
            }}>
                <Tabs
                    value={active}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                        ".MuiTabs-indicator": {
                            background: '#000000'
                        },
                        ".Mui-selected": {
                            fontWeight: '600',
                            color: '#000!important',
                        }
                    }}
                >
                    <Tab
                        sx={{
                            fontFamily:active === 0 ? "helvetica-lt-std-bold":"helvetica-lt-std-roman",
                            fontSize:'18px',
                            color: '#000',
                            textTransform: "capitalize",
                        }}
                        label={"Medical Notes "}
                        key={0}
                    />
                    <Tab
                        sx={{
                            fontFamily:active === 1 ? "helvetica-lt-std-bold":"helvetica-lt-std-roman",
                            fontSize:'18px',
                            color: '#000',
                            textTransform: "capitalize",
                        }}
                        label={"Staff Notes"}
                        key={1}/>
                </Tabs>
            </Box>
            <Box
                sx={{
                    py:'24px',
                    overflowY:'auto',
                    maxHeight:'500px',
                    ...scrollCSS,
                }}
            >
                {active ===0 && <Box>
                    {notes?.Medical?.length > 0 ?
                        notes?.Medical.map((note,index)=>(
                            <Box
                                key={index}
                                sx={{
                                    py:'24px',
                                    px:'16px',
                                    borderTop:index>0 ?'1px solid #9D9D9D':'unset',
                                }}>
                                <Typography sx={{fontSize:'16px'}}>
                                    <Typography
                                        as={'span'}
                                        sx={{
                                            fontSize:'16px',fontFamily:"helvetica-world-bold",
                                            mr:'3px',
                                            fontWeight:600,
                                        }}
                                    >
                                        [{moment(note?.created_at).format('h:mm A')},
                                        {moment(note?.created_at).format('YYYY-MM-D')}]  {" "}
                                        {note?.staff?.name}:
                                    </Typography>
                                    {note?.description}
                                </Typography>
                            </Box>
                        ))
                        : <Box sx={{textAlign:'center'}}>Empty Notes</Box>
                    }
                </Box>
                }
                {active ===1 && <Box>
                    {notes?.Staff?.length > 0 ?
                        notes?.Staff.map((note,index)=>(
                            <Box
                                key={index}
                                sx={{
                                    py:'24px',
                                    px:'16px',
                                    borderTop:index>0 ?'1px solid #9D9D9D':'unset',
                                }}>
                                <Typography sx={{fontSize:'16px'}}>
                                    <Typography
                                        as={'span'}
                                        sx={{
                                            fontSize:'16px',fontFamily:"helvetica-world-bold",
                                            mr:'3px',
                                            fontWeight:600,
                                        }}
                                    >
                                        [{moment(note?.created_at).format('h:mm A')},
                                        {moment(note?.created_at).format('YYYY-MM-D')}]  {" "}
                                        {note?.staff?.name}:
                                    </Typography>
                                    {note?.description}
                                </Typography>
                            </Box>
                        ))
                        :<Box sx={{textAlign:'center'}}>Empty Notes</Box>
                    }
                </Box>}
            </Box>

        </Box>
    );
};

export default ViewNotes;