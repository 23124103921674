import { Box, Typography, Grid, TableCell, TablePagination, TableRow, TableBody, TableHead, Table, TableContainer, Paper, Button, TableFooter } from '@mui/material';
import React, { useEffect, useState } from 'react'
import TotalMemberNamount from './totalMemberNamount';
import useStyles from "./style";
import { scrollCSS } from "../../App";
import DateRange from '../date-range/date-range';
import { useNavigate } from 'react-router';
import moment from 'moment';

const Category = ({ category,fetchData }) => {
  const [localStartDate, setLocalStartDate] = useState(new Date());
  const [localEndDate, setLocalEndDate] = useState(new Date());

  const navigate = useNavigate();

  const setCurrentMonthDates = () => {
    const now = new Date();
    const startOfLastWeek = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
    const endOfLastWeek = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
  
    setLocalStartDate(startOfLastWeek);
    setLocalEndDate(endOfLastWeek);   
  };    

  // useEffect(() => { 
  //   setCurrentMonthDates();
  // }, []);  
  // const handleDateChange =  async (start, end) => {
  //   setLocalStartDate(start);
  //   setLocalEndDate(end);
  //   const response = await fetchData(start,end);
  //   // if(onDatesChange) { 
  //   //   onDatesChange(start, end);
  //   // }
  // };
  //  const handleButtonClick = async () => {
  //   const response = await fetchData(localStartDate, localEndDate);
  
  // };     
 
console.log("data",category?.data)
  const classes = useStyles();  
     
  console.log("data fetch",category)
  useEffect(() => {  
    setCurrentMonthDates();
  }, []);

  const handleDateChange = async (start, end) => {
    setLocalStartDate(start);
    setLocalEndDate(end);
    await fetchData(start, end);
  };

  const handleCellClick = (itemId) => {
    navigate("/crm/category-sale-report", {  
      state: { value: itemId, startDate: localStartDate, endDate: localEndDate, valuestatus: "CheckOut" },
    });   
  };   

  return (
    <>
     <Box sx={{ padding: "0px 10px", mt: 2 }}>
      <Box sx={{ position: 'relative', borderRadius: "8px", }}>
    <Typography variant="h5" sx={{ fontFamily: 'helvetica-lt-std-bold', padding: '20px 20px' }}>
          For Comparison
        </Typography>   
<Box   
sx={{  
   minWidth: { xs: '50%', sm: '220px' },
    background:"#dee2e6",      
    height:"20px",
    marginRight:"15px",
    marginTop:"20px"  }}>

<DateRange  
  color="#EDF2F7"
setStartDate={setLocalStartDate}
setEndDate={setLocalEndDate}
startDate={localStartDate}
endDate={localEndDate}    
onChange={handleDateChange}   
  
/></Box>
<Box style={{marginTop:"40px"}}>
{/* <Button  variant="contained" onClick={handleButtonClick} >



Fetch Report
</Button> */}
</Box>
     

<TableContainer
                  component={Paper}
                  sx={{ mt: 5, maxHeight: "650px" }}
                >
                  <Table sx={{'@media (max-width: 370px)' :{ marginTop:"20px"},}} aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: "700",
                            borderBottom: 0,
                          }}
                        >
                          Category Name
                        </TableCell>
                        <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                Revenue Earned
                </TableCell>
             
             
             
          

                <TableCell
                  align="left"
                  sx={{ fontWeight: "700", borderBottom: 0 }}
                >
                Percentage
                </TableCell>
              
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {
                         Array.isArray(category?.data) ? (
                          category.data.map((item, index) => (
                            <TableRow
                              key={index}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              className={classes.odd}
                              onClick={() => handleCellClick(item?.id)}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.tableLink}
                                sx={{ cursor: 'pointer' }}
                              >
                                {item.title ?? "N/A"}
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  sx={{   
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#00BD06",
                                  }}
                                >
                                $  {item.total_revenue_earned?? "N/A"}
                                </Typography>
                              </TableCell>
                            
                              <TableCell align="left">
                                <Typography variant="body1" color="initial">
                                  {item.percentage_revenue_earned ??"N/A"}  % 
                                </Typography>
                              </TableCell>
                           
                            </TableRow>
                         )) ) : (
                          <TableRow>
                            <TableCell
                              colSpan={7}
                              align="center"
                              sx={{ height: '300px' }}
                            >
                              Category data not found
                            </TableCell>
                          </TableRow>     
                         )     
                       }

                    </TableBody>
  <TableFooter className={classes.tableHead} sx={{
      position: "sticky",
      bottom: 0,
    
    }}>
      <TableRow sx={{}}>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0, fontSize:'15px',fontFamily: 'helvetica-lt-std-bold' }}>
          Total
        </TableCell>
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0, fontSize:'15px',fontFamily: 'helvetica-lt-std-bold' }}>
       $ {category?.totals?.total_revenue_earned_sum}  
        </TableCell>
       
        <TableCell align="left" sx={{ fontWeight: "700", borderBottom: 0, fontSize:'15px',fontFamily: 'helvetica-lt-std-bold' }}> 
        
          {/* {category?.totals?.total_revenue_earned_count} */}
          100%
        </TableCell>
      
      </TableRow>
    </TableFooter> 
                  </Table>
                </TableContainer>
     


        {/* <TablePagination   
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count=""
        rowsPerPage=""
        page=""   
        onPageChange=""
        onRowsPerPageChange=""
      /> */}


      </Box>
      <Box />
    </Box>
    </>
   
  );
};

export default Category;
