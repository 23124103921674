import {
  Box,
  Menu,
  Divider,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Typography,
  Avatar,
  Button,
  Modal,
  TextareaAutosize,
  Select,
  InputLabel,
  Checkbox,
  ListItemText,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useRef } from "react";
import CustomButton from "../../../components/custom-button/customButton";
import { useState } from "react";
import CustomButtoncolor from "../../../components/custom-button/customColorButton";
import Loader from "../../../components/loader/loader";
import { useDispatch, useSelector } from "react-redux";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WalletIcon from '@mui/icons-material/Wallet';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from "react";
import {
  getAllCRMCustomer,
  getAllCRMSubscription,
  updateCrmFilters,
} from "../../../store/crm/crm";

import Vector from "../../../assets/images/Vector.png";
import FilterInput from "../../../components/form/filterInput";
import { vectorWrapper } from "../pos/posStyles";
import BackendSearchField from "../../../components/pagination/backendSearch";
import BackendPagination from "../../../components/pagination/backendPagination";
import SetEmailModal from "../../../components/modal/SetEmailModal";
import MakeMemberModal from "./crmMembership/MakeMemberModal";
import SetWalletModal from "../../../components/modal/SetWallet";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import ribbon from "../../../assets/images/ribbon.svg";
import redRibbon from "../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../assets/images/yellow-badg.svg";
import { ribbonwrapper } from "../../../components/card-appointment/CardAppointment";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import TreatmentFormButton from "../../../components/card-appointment/treatmentForm";
import BuyWallet from "../../../components/modal/BuyWallet";
import { getSubscriptionCancle } from "../../../store/user/chart-form";
import { MySwal } from "../../../services/utills";
import { mySwalButton } from "../../../components/card-appointment/CardAppointment";
import Waveloading from "../../loading/Waveloading";
import AddUserCard from "../../user/user-wallet/addUserCard";
import { getUserDetails } from "../../../store/auth";
import MangeCardModal from "./crmMembership/MangeCard";
import WarningModal from "../../../components/modal/WarningModal";
import SendSmsModal from "../../../components/modal/SendSms";
import TreatmentTab from "../../../components/modal/TreatmentTab";
export const headerwraper = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  '@media (max-width: 1400px) ': {flexWrap:"wrap"},
  "@media (max-width: 768px)": {
    //    flexDirection:'row',
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  "@media (max-width: 468px)": {
    flexDirection: "column",
    alignItems: "center",
  },
};
export const contentwrap = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  flexWrap:'wrap',
  "@media (max-width: 768px)": {
    // flexDirection:'column',
  },
};
export const btnwrap = {
  mb: "16px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  width: "100%",
  '@media (min-width:900px) and (max-width: 1400px) ': { marginTop:'10px',},
  "@media (max-width: 425px)": {
    justifyContent: "center",
    gap: "6px",
    flexWrap: { sm: "nowrap", xs: "wrap" },
    marginTop:'10px',
    // alignItems:'center'
  },
};
export const contentwrape = {
  // width:'20%',
  "@media (max-width:480px)": {
    width: "100%",
    alignItems: "center",
  },
};

export const outercontainer = {
  margin: "10px 0",
  borderRadius: "10px",
  display: "flex",
  marginTop: "-10px",
  justifyContent: "space-between",
  "@media (max-width: 1024px)": {
    display: "flex",
    flexDirection: "row",
    // flexWrap:'wrap' ,
  },

  "@media (max-width: 786px)": {
    display: "flex",
    // flexDirection:"column",
    flexWrap: "wrap",
    marginTop: "-15px",
    
  },
};
export const wrapper = {
  marginTop: "1%",

  display: "flex",
};
export const subheading = {
  fontsize: "16px",
  fontWeight: "400",
  fontFamily: "helvetica-lt-std-light",
  color: "#4F4F4F",
};
export const heading = {
  fontsize: "16px",
  color: "#4F4F4F",
  fontFamily: "helvetica-lt-std-roman",
  "@media (max-width: 420px)": {
     fontSize:'14px',
  },
};
export const content = {
  fontsize: "17px",
  fontWeight: "400",
  fontFamily: "helvetica-lt-std-roman",
  color: "#000000",
  "@media (max-width: 420px)": {
    fontSize:'14px',
 },
};
export const contentstatus = {
  fontSize: "16px!important",
  fontWeight: "400",
  fontFamily: "helvetica-lt-std-roman",
  color: "#000000",
  "@media (max-width: 420px)": {
    fontSize: "13px !important",
  },
};
export const wallet = {
  display: "flex",
  marginTop: "10px",
  gap: "6px",
  "@media (max-width: 900px)": {
    marginTop: "5%",
  },
  "@media (max-width: 600px)": {
    marginTop: "10px",
  },
};
export const walletwrapper = {

  width: "22%",
  "@media (max-width: 1024px)": {
    width: "50%",
  },
  "@media (max-width: 600px)": {
    dispaly:'flex',
    flexDirection:'column',
  },
};
export const statuswrapper = {   
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "start",
  mt: 4,
  gap: "6px",
  "@media (max-width: 1024px)": {
    width: "50%",
    // gap:'20px',
  },
  "@media (max-width: 786px)": {
    display: "flex",

    flexWrap: "wrap",
  },
  "@media (max-width:600px)": {
    width: "100%",
    alignItems: "center",
    margin: "20px 0px",
    fontSize: "13px",
  },
};
export const Status = {
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  "@media (max-width: 420px)": {
    fontSize: "13px !important",
  },
};
export const btnwrapper = {
  gap: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 1024px)": {
    width: "50%",
  },
  "@media (max-width:480px)": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
};

export const statusinnerwrapper = {
  display: "flex",
  gap: "10px",
  justifyContent: "center",
  "@media (max-width: 420px)": {
    display: "flex",
    //    flexDirection:"column",
    justifyContent: "center",
  },
};
export const linedesign = {
  borderColor: "#9A9797",
  width: "2px",
  "@media (max-width: 600px)": {
    display: "none",
  },  
};
export const wrapperdesign = {
  borderColor: "#9A9797",
  width: "2px",
  height: "67%",
};
export const topheading = {
  fontsize: "25px",
  color: "#333333",   
  fontWeight: "700px",
  fontFamily: "helvetica-lt-std-bold",
};

export const btndesign = {
  width: "max-content",
  borderRadius: "6px!important",
  fontSize: "17px", 
  "@media (max-width: 470px)": {
    width: "100%",
  },
};

export const crmButton = {
  padding: "7px 15px",
  fontSize: "18px",
  borderRadius: "6px",
  width: "100%",
  minWidth: "max-content",
  "@media (max-width: 480px)": {
    width: "fit-content",
    fontSize: "14px",
    padding: "5px",
  },
};

export const outercontainermain = {
  backgroundColor: "#FFFFFF",
  boxShadow: "4px 4px 19px -2px rgba(189, 189, 189, 0.25)",
  borderRadius: "10px",
  margin: "15px 0",
  padding: "24px 34px",
  "@media (max-width: 420px)": {
    padding: "20px 16px",
  },
  "@media (max-width: 319px)": {
    padding: "20px 10px",
  },
};

export const selectStyle = {
  background: "#fff",
  borderRadius: "4px",
  marginTop: "7px",
  marginRight: "5px",
  marginLeft: "5px",
  height: "44px",
  ".MuiOutlinedInput-notchedOutline": {
    borderWidth: "0!important",
  },
  ".MuiSelect-select": {
    borderColor: " #fff",

    minHeight: "unset",
    "&:focus": {
      borderColor: "#fff",
      outline: "none",
      input: {
        height: "11px",
      },
    },
    "&:hover": {
      borderColor: "#fff",
      outline: "none",
    },
  },
};
const crmFilters = [
  { name: "All", value: "All" },
  { name: "Newest/Latest", value: "Latest" },
  { name: "Oldest", value: "Oldest" },
  { name: "Ascending (A-Z)", value: "Ascending" },
  { name: "Descending (Z-A)", value: "Descending" },
  { name: "Most Recent Booking", value: "MostRecentBooking" },  
  { name: "Least Recent Booking", value: "LeastRecentBooking" },
  { name: "Wallet Highest", value: "WalletHighest" },
  { name: "Wallet Lowest", value: "WalletLowest" },
];


const CustomerBookingFilters = [
  { name: "All Customer", value: "AllCustomer" },
  { name: "Active Members", value: "ActiveMembers" },
  { name: "Decline Members", value: "DeclineMembers" },
  { name: "Cancel Members", value: "CancelMembers" },
 
];
  
const CRMPage = () => {
  const [loading, setLoading] = useState(false); 
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();  
  const {state} = useLocation()
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedBookingType, setSelectedBookingType] = useState("");
  // const location = useLocation()
  // const [selectedBookingType, setSelectedBookingType] = useState("");      
  const authStore = useSelector((state) => state?.authStore);
  const crmStore = useSelector((state) => state?.crmStore);
  const { user } = authStore;
  const provider = user?.defaultProvider;
  const {
    allCrmCustomer = {},
    order_by,
    pageLimit,
    currentPage,
    search,
    customer_type
  } = crmStore;
  const [activeProviderPayment, setActiveProviderPayment] = useState(null);
  const [providerPayment, setProviderPayment] = useState(null);
  const searchPerformedRef = useRef(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
   
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  // Function to handle booking type change for multiple selections
  const handleBookingTypeChange = async (event) => {
    // 'event.target.value' will be an array for multiple select   
    setSelectedBookingType(event.target.value);
    await dispatch(updateCrmFilters({ customer_type: selectedBookingType, currentPage: 1 }));
    handleClose();
    await fetchCrmCustomer(); 
  };
  if (!location.state) {
    console.log('No state passed in location');  
} else {
    console.log('Received state:', location.state.value);
}

  useEffect(() => {    
    (async () => { 
      if (!user?.id) {  
        await dispatch(getUserDetails());
      }    
      if (user?.id && user?.defaultProvider) {
        let defaultProvider = user?.defaultProvider;
  
        let res =
          defaultProvider?.provider_payment?.length > 0 && 
          defaultProvider?.provider_payment.filter(
            (item) => item?.payment_gateway === "Square" && item?.status === 1
          );
        if (res?.length) {
          setActiveProviderPayment(res[0]);
        }    
        setProviderPayment(defaultProvider?.provider_payment || []);
      }
    })();
  }, [user]);   
  // api integration
  // useEffect(() => {
  //   (async () => {
  //     await fetchCrmCustomer();
  //     dispatch(getAllCRMSubscription());
  //   })();
  // }, []);
  const handleSearch = async (val) => {
    await dispatch(updateCrmFilters({ search: val }));
  };

  const fetchCrmCustomer = async () => {
    setLoading(true);
    await dispatch(getAllCRMCustomer());
    setLoading(false)

  };
  const locationStateValue = state?.value;




  useEffect(() => {
    (async () => {
        if(locationStateValue)
       console.log(" this one call")

       const params = {

        search:locationStateValue,
                currentPage:1
              };
         await dispatch(updateCrmFilters(params))
         fetchCrmCustomer();
    })()
}, [state])
console.log("location get search",locationStateValue)
  const handleFilter = async (value) => {
    await dispatch(updateCrmFilters({ order_by: value, currentPage: 1 }));
    handleClose();
    await fetchCrmCustomer();  
  };   
  
  const handlecustomerfilter = async (value) => {
    await dispatch(updateCrmFilters({  customer_type: value, currentPage: 1 }));
    handleClose();
    await fetchCrmCustomer(); 
  };                                                                          
  const scrollRef = useRef(null); // Create the ref

      const executeScroll = () => {
        setTimeout(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }, 500); // Delay of 500ms
    
    }
       
  const handelCancle = async (customer) => {
    console.log("issue fixed",customer)
    if (customer?.is_member_ship_button_allow === 0) {
      toggleWarningModal(); // Show the warning modal if the condition is met  
    } else {   
  
    let reason = ''; // Variable to store the reason for cancellation
  
    let confirm = await MySwal.fire({
      icon: "question",
      title: "Confirmation",
      showConfirmButton: false,
      html: (
        <Box sx={{ mb: 4 }}>
          <Typography>
            Please enter the reason for cancelling the Membership:
          </Typography>
          <TextareaAutosize
            minRows={3}
            style={{ width: '100%', margin: '10px 0' }} 
            placeholder="Enter reason here..."
            onChange={(e) => reason = e.target.value}
          />
          <Box sx={{ mt: 3 }}>
            <Button
              sx={{ ...mySwalButton }}
              onClick={() => {
                if (reason.trim() === '') {
                  MySwal.showValidationMessage('Please enter a reason for cancellation.'); // Show popup for entering the reason
                } else {
                  MySwal.clickConfirm();
                }
              }}
            >
              Yes
            </Button>
            <Button sx={{ ...mySwalButton }} onClick={() => MySwal.close()}>
              No
            </Button>
          </Box>
        </Box>
      ),
      preConfirm: () => reason, // This will be passed to the value below if confirmed
    });
  
    if (confirm?.isConfirmed && confirm.value.trim() !== '')  {
      const payload = { reason: confirm.value }; // Include the reason in the payload
      await dispatch(getSubscriptionCancle(customer?.customer_id , payload));
      handleClose();
      await fetchCrmCustomer();
    } else {
      setRescheduleModal(true);   
    }
 
  }
  };                            
  const toggleWarningModal = () => setShowWarningModal(!showWarningModal);         
 
  const fetchPageData = async (page) => {
    await dispatch(updateCrmFilters({ currentPage: page }));
    await fetchCrmCustomer();
  };
  
  const handleLimitChange = async (val) => {
    await dispatch(updateCrmFilters({ pageLimit: val, currentPage: 1 }));
    await fetchCrmCustomer();
  };
  const reFetchData = async () => {  
    await fetchCrmCustomer();       
  };  
 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };    
  const handleClose = () => {
    setAnchorEl(null);
  };
  const redirect = (to, state) => {
    navigate(to, state);  
  }; 

  const clearfun = async() => {
    setLoading(true);
    await dispatch(updateCrmFilters({ customer_type: "AllCustomer",order_by:"All", search:""}));
    await fetchCrmCustomer();
    setLoading(false)

  }
  const handleSearchIconClick = async() => {
    console.log("value",selectedBookingType)
    // Trigger your API call here
    await dispatch(updateCrmFilters({ customer_type: selectedBookingType, currentPage: 1 }));
    handleClose(); 
    await fetchCrmCustomer();    
  };
  useEffect(() => { 
  
    console.log("Location state value:", locationStateValue);

    const performSearch = async () => {
      if (locationStateValue) {
        console.log("Triggering search for new value:", locationStateValue);      
        await dispatch(updateCrmFilters({ search: locationStateValue }));
        await fetchCrmCustomer();
        dispatch(getAllCRMSubscription());
        searchPerformedRef.current = true; 
      }else{
        await fetchCrmCustomer();
        dispatch(getAllCRMSubscription());

      }
    };
   

    performSearch();
  }, [locationStateValue, dispatch]);           


  return (
    <>
      <Box>
        <Box sx={headerwraper} ref={scrollRef}>
          {loading ? <Waveloading fullSize /> : ""}   
          <Box style={contentwrap} sx={{ my: { sm: 2, md: 0 } }}>
            <FilterInput limit={pageLimit} setLimit={handleLimitChange} />
        
    <FormControl sx={{ m: 1, minWidth: 120,margin:'0 auto' }}>  
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"   
        value={customer_type}    
        label="Customer Type"           
        onChange={(event) => handlecustomerfilter(event.target.value)}      
        sx={{    
          background:"#ffff",
          width: "200px",                    
          '& .MuiSvgIcon-root': { 
            color: "#666666", 
          },                                           
          '&.Mui-focused': {          
            outline: 'none',
            borderColor: 'transparent',        
          },      
     
          // To also target the fieldset element for removal of the outline
          '& .MuiOutlinedInput-notchedOutline': {   
            border: 'none',        
          },                   
        }}           
      >
        {CustomerBookingFilters.map((item, index) => (                   
          <MenuItem        
            key={index}    
            value={item.value}  
            sx={{                                   
              py: "7px",
              my: "3px",
              background: item.value === order_by ? "#09ABF1!important" : "#fff",    
              color: item.value === order_by ? "#fff" : "#000",
              "&:hover": {
                background: "#09ABF1",            
                color: "#fff",      
              },        
            }}  
          >       
            {item.name}
          </MenuItem>    
        ))}   
      </Select>      
    </FormControl>
    <FormControl sx={{ m: 1, minWidth: 120, margin:'0 auto'}}>  
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"   
        value={order_by}    
        label="Sort by"           
        onChange={(event) => handleFilter(event.target.value)}    
        sx={{   
          '@media (max-width: 450px)': {marginTop:'10px'}, 
          background:"#ffff", 
          width: "200px",                    
          '& .MuiSvgIcon-root': { 
            color: "#666666", 
          },                                           
          '&.Mui-focused': {          
            outline: 'none',
            borderColor: 'transparent',      
          },      
       
          // To also target the fieldset element for removal of the outline
          '& .MuiOutlinedInput-notchedOutline': {   
            border: 'none',        
          },                   
        }}           
      >
        {crmFilters.map((item, index) => (                  
          <MenuItem        
            key={index}    
            value={item.value}
            sx={{
              py: "7px",
              my: "3px",
              background: item.value === order_by ? "#09ABF1!important" : "#fff",    
              color: item.value === order_by ? "#fff" : "#000",
              "&:hover": {
                background: "#09ABF1",               
                color: "#fff",      
              },        
            }}  
          >     
            {item.name}
          </MenuItem>    
        ))}    
      </Select>      
    </FormControl>
    <Button   sx={{color:"white",background:"#d40909" ,height:"53px",'&:hover': {
      background: "white", 
      color: "#d40909", 
      border: "1px solid #d40909" 
    }}}  variant="contained" onClick={clearfun}>Clear</Button>
          </Box>        
          <Box>   
            <Grid container>          
              <Grid item xs={12} sx={btnwrap}>            
                <CustomButton    
                  style={{ width: "85%"}}  
                  handleClick={() =>
                    redirect(
                      "/crm/customer-relationship-management/add-customer"
                    )
                  }
                  val={"Add New Contact"}
                  size={"large"}
                />
        <FormControl   
                  sx={{
                    mr: { sm: "10px", xs: "5px" },  
                    ml: { sm: "10px", xs: "5px" },
                    mt: { sm: "0px", xs: "15px" },
                    mb: { sm: "0px", xs: "15px" },
                    width: { sm: "100%", xs: "75%" },
                  }}
                >
<BackendSearchField
  search={search}
  handleSearch={handleSearch}  
  fetchData={async () => {      
    await dispatch(updateCrmFilters({ currentPage: 1 }));
    await fetchCrmCustomer();
  }}     
/>   
                </FormControl>
                {/* <Box 
                  variant="outlined"
                  sx={vectorWrapper}
                  onClick={handleClick}
                >
                  <img src={Vector} alt="" />
                </Box> */}
                {/* <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      width: "250px",
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >  
                  <MenuItem
                    disabled
                    sx={{ opacity: "unset!important", py: "10px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#666666",
                        textTransform: "uppercase",
                      }}
                    >
                      Sort Data:
                    </Typography>
                  </MenuItem>
                  {crmFilters.map((item, index) => (
                    <MenuItem
                      key={index}
                      sx={{    
                        py: "7px",
                        my: "3px",
                        background:
                          item?.value === filterBy
                            ? "#09ABF1!important"
                            : "#fff",  
                        color: item?.value === filterBy ? "#fff" : "#000",
                        "&:hover": {
                          background: "#09ABF1",
                          color: "#fff",
                        },
                      }}
                      onClick={() => handleFilter(item?.value)}
                    >
                      {item?.name}
                    </MenuItem>
                  ))}
                </Menu> */}
              </Grid>
            </Grid>
          </Box>
        </Box>  
        {allCrmCustomer?.data?.length > 0 ? (
          allCrmCustomer.data.map((customer, index) => (
            <Box key={index} sx={outercontainermain}>
             <Box >
  {customer?.customer_batch && (
    <>
      <Box style={{ display: 'flex', alignItems: 'center' }}>   
        {customer?.customer_batch.includes("Expire/Cancel") && (
          <img
            style={{ marginTop: "4px" }}
            src={redRibbon}
            alt={"member"}
          />
        )}
        {customer?.customer_batch.includes("Declined") && (
          <img    
            style={{ marginTop: "4px" }}
            src={yellowRibbon}          
            alt={"member"}          
          />
        )}
        {!customer?.customer_batch.includes("Expire/Cancel") &&
         !customer?.customer_batch.includes("Declined") && 
         customer?.customer_batch !== "New User" && (
          <img
            style={{ marginTop: "4px" }}
            src={ribbon}
            alt={"member"}
          />
        )}
        <Typography style={{
          color: customer?.customer_batch.includes("Expire/Cancel") ? "#FF0000" :
                customer?.customer_batch.includes("Declined") ? "#dcd90a" :
                customer?.customer_batch !== "New User" ? "#219653" : "#09ABF1",
          marginLeft: customer?.customer_batch.includes("Expire/Cancel") ||
                      customer?.customer_batch.includes("Declined") ||
                      customer?.customer_batch !== "New User" ? "8px" : "0",
        }}>
          {customer?.customer_batch}
        </Typography>
      </Box>
      <Box
  style={{               
    color: customer?.customer_batch.includes("Expire/Cancel") ? "#FF0000" :
          customer?.customer_batch.includes("Declined") ? "#dcd90a" :  
          "#219653", 
  }}
>
  {/* {customer?.customer_batch === "ActiveMembers" && (
    <div>Start Date: {customer?.member_start_date}</div>
  )} */}
  {customer?.customer_batch.includes("Declined") && (
    <div>
      Start Date: {customer?.member_start_date}, Decline Date: {customer?.updated_date}
    </div>
  )}
  {customer?.customer_batch.includes("Expire/Cancel") && (
    <div>
      Start Date: {customer?.member_start_date}, Cancelled Date: {customer?.updated_date}
    </div>
  )}
   {!customer?.customer_batch.includes("Expire/Cancel") &&
   !customer?.customer_batch.includes("Declined") && 
   customer?.customer_batch !== "New User" && (
    <div>
      Start Date: {customer?.member_start_date}
    </div>
  )}
</Box>
    
    </>
  )}   
</Box>
              <Grid container style={outercontainer} sx={{ mt:{xs:'5px !important',sm:'-10px !important'} }}>
                <Grid item xl={2.4} lg={3.5} md={5} sm={12} xs={12} sx={contentwrape}>
                  <Box sx={{ marginTop: { xs: "0", sm: "0", md: "0" } ,display:'flex',flexDirection:{xs:"column",sm:"column"} }}>
                    {/*<Typography sx={subheading}>Customer Name:</Typography>*/}
                    <Box sx={{ display:{xs:'block',sm:'block'},mt:'4px',mb:'6px' }} >
                    <Box sx={wallet}>
                    <Typography sx={heading}><WalletIcon />:</Typography>
                    <Typography sx={content}>${customer.wallet}</Typography>
                  </Box>
                     
                      {/*<Box*/}
                      {/*    sx={{*/}
                      {/*        display:'flex',*/}
                      {/*        alignItem:'center',*/}
                      {/*        cursor:'pointer',*/}
                      {/*    }}*/}
                      {/*>*/}
                      {/*    <InsertDriveFileIcon sx={{color:'#2F80ED'}}/>*/}
                      {/*    <Typography sx={{color: '#2F80ED'}}>Client Forms</Typography>*/}
                      {/*</Box>*/}
                    </Box>

                    <Avatar
                      src={customer?.profile_image}
                      alt={customer.customer_name}
                      sx={{
                        height:{xs:'45px',sm:"120px"},
                        width: {xs:'50px',sm:"130px"},
                      }}
                      variant="rounded"
                    />
                      <Box>
                      <Typography
                          sx={{
                            ...topheading,
                            textTransform: "capitalize",
                            marginLeft: { xs: "15px", sm: "0px",},
                            mt:'10px'
                          }}
                      > 
                        {customer.customer_name}
                      </Typography>  
                 <Box sx={wallet}>
                   <Typography sx={heading}>  <PhoneIcon /></Typography>
                   <Typography sx={content}>{customer.phone}</Typography>
                 </Box>
                 <Box sx={wallet}>
                   <Typography sx={heading}><EmailIcon /></Typography>
                   <Typography style={content} sx={{ overflowWrap:'anywhere' }}>{customer.email}</Typography>
                 </Box>
                 </Box> 
                  </Box>
                </Grid>
                <Box>
                 
                </Box>
                <Box>  
                  <Divider orientation="vertical" sx={linedesign} />   
                </Box>   
                <Grid item xl={3} lg={4} md={6.5} sm={12} xs={12} sx={statuswrapper}>   
                  <Box sx={statusinnerwrapper}>    
                    <Box sx={Status}>
                      <Typography sx={contentstatus}>{customer.new}</Typography>    
                      <Typography sx={contentstatus}>New</Typography>   
                    </Box>
                    <Divider orientation="vertical" sx={wrapperdesign} />
                    <Box sx={Status}>   
                      <Typography sx={contentstatus}>
                        {customer.completed}{" "}     
                      </Typography>    
                      <Typography sx={contentstatus}>Completed</Typography> 
                    </Box>
                    <Divider orientation="vertical" sx={wrapperdesign} />
                    <Box sx={Status}>  
                      <Typography sx={contentstatus}>  
                        {customer.cancelled}{" "}
                      </Typography>
                      <Typography sx={contentstatus}>Cancelled</Typography>
                    </Box>
                    <Divider orientation="vertical" sx={wrapperdesign} />
                    <Box sx={Status}>
                      <Typography sx={contentstatus}>
                        {customer.no_show}
                      </Typography>
                      <Typography
                        sx={contentstatus}
                        style={{ textAlign: "center" }}
                      >
                        No show
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={statusinnerwrapper} style={{ marginTop: "3px" }}>
                    <Box sx={Status}>
                      <Typography sx={contentstatus}>Wallet Used</Typography>
                      <Typography sx={contentstatus}>
                        ${parseFloat(customer.wallet_used).toFixed(2)}
                      </Typography>
                    </Box>
                    <Divider orientation="vertical" sx={wrapperdesign} />
                    <Box sx={Status}>
                      <Typography sx={contentstatus}>Total Revenue</Typography>
                      <Typography sx={contentstatus}>       
                        ${customer?.total_revenue}
                      </Typography> 
                    </Box>
                  </Box>
                  {customer.outstanding_balance && customer.outstanding_balance > 0 && (
                      <Box sx={statusinnerwrapper} style={{ marginTop: "45px" }}>
                        <Box sx={Status}>
                          <Typography sx={contentstatus}>Outstanding Balance</Typography>
                          <Typography sx={contentstatus} style={{ color: "red" }}>
                            ${parseFloat(customer.outstanding_balance).toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>
                  )}

                </Grid>
                <Box>
                  <Divider orientation="vertical" sx={{ ...linedesign }} />
                </Box>
                <Grid
                  item
                  xl={6}
                  md={12}
                  sm={12}     
                  xs={12}  
                  sx={{ ...btnwrapper, mt: { xl: "0", sm: "20px" } }}  
                >   
                  <Grid container columnSpacing={1} rowSpacing={1}>
                    <Grid item xs={6} md={4} xl={3}>
                      <CustomButtoncolor
                        sx={{ crmButton }}
                        mainColor={"#09ABF1"}
                        size="md"  
                        val="Book Now"
                        handleClick={() => {
                          localStorage.setItem("url", pathname);
                          redirect(
                            `/crm/add-appointment/user/${customer?.customer_id}`
                          );
                        }}
                        style={crmButton}
                      />
                    </Grid>
                    <Grid item xs={6} md={4} xl={2}>
                      <CustomButtoncolor     
                        mainColor={"#333333"}
                        sx={{ ...btndesign, ...crmButton }}
                        size="md"
                        val="Edit"
                        style={crmButton}  
                        handleClick={() => {
                          if (customer && customer.customer_id) {
                            redirect(
                              `/crm/customer-relationship-management/customer/profile-info/${customer.customer_id}`
                            );
                          } else {
                            console.log("edit error");
                          }
                        }}
                      />
                    </Grid> 

                    <Grid item xs={12} md={4} xl={3.5}>
                      <CustomButtoncolor
                        mainColor={"#323681"}
                        size="md"
                        val="Booking History"
                        test={fetchCrmCustomer}
                        handleClick={() => {
                          if (customer && customer?.customer_id) {
                            console.log(
                              "Redirecting to history with customer ID:",
                              customer?.customer_id
                            );
                            redirect(
                              `/crm/customer-relationship-management/customer/history/${customer?.customer_id}`
                            );
                          } else {
                            console.log(
                              "Customer object or customer_id is undefined:",
                              customer 
                            );
                            console.log("edit error");
                          }
                        }}
                        style={crmButton}
                      />
                    </Grid>
                   
                   
                    <Grid item xs={6} md={4} xl={3.5}>
                      <SetEmailModal
                        styles={{ fontSize: "18px", }}
                        customer_id={customer?.customer_id}
                      />
                    </Grid>
                    <Grid item xs={6} md={4} xl={2.8}>
                      <SendSmsModal
                        styles={{ fontSize: "18px", }}
                        customer_id={customer?.customer_id} 
                      />
                    </Grid>
                    <Grid item xs={12} md={4} xl={2.5}>
        <Button variant="outlined"  style={{ width:'100%'}}>
          <a href={`tel:${customer?.phone}`} style={{ textDecoration: 'none', color: 'inherit',padding:'5px 13px' }}>
            Call  
            
          </a> 
        </Button>   
        </Grid>
                   
                    <Grid item xs={12} md={4} xl={4}>
                      <CustomButtoncolor

                        mainColor={"#A533B8"}
                        size="md"
                        val="Notes/Attachments"  
                        style={{ minWidth:'unset' ,...crmButton }}  
                        handleClick={() =>
                          redirect(
                            `/crm/customer-relationship-management/customer/notes/${customer?.customer_id}`
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4} xl={2.7}>
                      <CustomButtoncolor
                     
                        mainColor={"#A533B8"}
                        size="md"
                        val="Gallery"  
                        style={crmButton}
                        handleClick={() =>
                          redirect(
                            `/crm/customer-relationship-management/customer/gallery/${customer?.customer_id}`
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4} xl={3} >
                      <TreatmentFormButton
                        data={customer}
                        crmStyle={{
                          width: "100%",
                          fontSize: "18px",
                          padding: "7px 15px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} xl={5}>
                      <BuyWallet customer_id={customer?.customer_id} />
                      {/* <CustomButtoncolor
                                                mainColor={"#498aee"}
                                                size="md"
                                                val='Buy Wallet Points'
                                                style={crmButton}
                                                // handleClick={() => redirect(`/crm/customer-relationship-management/customer/notes/${customer?.customer_id}`)}
                                            /> */}
                    </Grid>
                    <Grid item xs={6} md={4} xl={4}>
                      <CustomButtoncolor
                        mainColor={"#f6a53c"}
                        size="md"
                        val="Add Invoice"
                        style={crmButton}
                        handleClick={() =>
                          redirect(`/crm/point-of-sale/new-invoice`, {
                            state: { user: customer },
                          })
                        }  
                      />
                    </Grid>
                 
                    <Grid item xs={6} md={4} xl={4}>
                      <SetWalletModal
                        styles={{ fontSize: "18px" ,}}
                        reFetchData={reFetchData}
                        customer_id={customer?.customer_id}
                      />
                    </Grid>
                   
                    {/* {
                      customer?.is_membered=== 1 ?( */}
                        <Grid item xs={12} md={4} xl={4}>  
                        <MangeCardModal 
                           styles={{ fontSize: "18px",'@media (max-width:319px)':{ fontSize:"14px !important"  } }}
                           reFetchData={reFetchData}
                           customer_id={customer?.customer_id}  
                           fsize={"18px"} />  
                        </Grid> 
                     
                      {/* )
                      :""

                    } */}
                    <Grid item xs={12} md={4} xl={4}>
                    <TreatmentTab customer_id={customer?.customer_id} />
                    </Grid>

                    {(user?.is_new === 0) && (
                        <Grid item xs={12} md={4} xl={6}>
                          <MakeMemberModal
                              styles={{
                                fontSize: "18px",
                                '@media (max-width:319px)': {
                                  fontSize: "14px !important"
                                }
                              }}
                              reFetchData={reFetchData}
                              customer_id={customer?.customer_id}
                              is_member_ship_button_allow={customer?.is_member_ship_button_allow}
                              fsize={"18px"}
                          />
                        </Grid>
                    )}
                    <>
                 
                    {(customer?.is_membered || customer?.is_declined) && (user?.is_new===0) ? (
                          <Grid item xs={12} md={4} xl={6}>
                            <CustomButtoncolor
                                mainColor="red"
                                size="md"
                                val="Cancel Membership"
                                style={crmButton}
                                handleClick={() => handelCancle(customer)}
                            />
                        </Grid>
                    ) : null}

{showWarningModal && <WarningModal />}
</>

                  </Grid>
                

                </Grid>
              </Grid>
            </Box>   
          ))
        ) : (
          <Box sx={{ width: "100%", mt: "30px" }}>
            <Typography sx={{ textAlign: "center" }}>Empty</Typography>
          </Box>
        )}
        {allCrmCustomer?.meta?.last_page > 1 && (
          <BackendPagination
            count={allCrmCustomer?.meta?.last_page}
            currentPage={currentPage}
            handleOnChange={fetchPageData}
            executeScroll={executeScroll}
          />
        )}
      </Box>
    </>
  );
};

export default CRMPage;
