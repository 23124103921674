import React, {useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import FormSlider from "../../../../components/form/formSlider";
import * as yup from "yup";
import {useFormik} from "formik";
import SubmitButton from "../../../../components/custom-button/submitButton";
import Loader from "../../../../components/loader/loader";
import {useDispatch, useSelector} from "react-redux";
import {storeClinicRadius} from "../../../../store/clinics/clinicSettings/clinic-radius";

const BusinessRadius = () => {
    const [loading,setLoading] = useState(false)
    const dispatch =useDispatch()
    const clinicData = useSelector(state => state?.clinicStore?.clinicByID)

    const validationSchema = yup.object({
        radius: yup.number().typeError("Radius is required").min(5, "Radius is Too Short").required('Radius is required'),
    });


    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            radius: 0,
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            if (clinicData?.id){
                setLoading(true)
                let res = await  dispatch(storeClinicRadius({provider_id:clinicData?.id,...data}))
                if (res?.payload){
                    console.log(res?.payload)
                }
                setLoading(false)
            }

        },
    });

    useEffect(()=>{
        if (clinicData?.id){
            setFieldValue('radius',clinicData?.radius || 0)

        }
    },[clinicData,clinicData?.id])
    return (
        loading ?<Loader/>: <form onSubmit={handleSubmit}>
           <Grid container p={'20px'}>
               <Grid item xs={7}>
                   <FormSlider
                       label={'Business Radius'}
                       name={'radius'}
                       errors={errors}
                       touched={touched}
                       values={values}
                       handleChange={handleChange}
                   />
               </Grid>
               <Grid item xs={7} sx={{display:'flex',justifyContent:'flex-end'}}>
                   <SubmitButton
                       val={'Save'}
                   />
               </Grid>
           </Grid>
       </form>
    );
};

export default BusinessRadius;