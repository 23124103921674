import React from 'react';
import Box from "@mui/material/Box";

const CrmLayout = ({children}) => {
    return (
        <Box sx={{ minHeight: 'calc(100vh - 109px)', background: "#fff", padding:'20px' ,borderRadius:'7px'," @media (max-width:500px)": { padding:'5px' }, }}>
            {children}
        </Box>
    );
};

export default CrmLayout;