import React from 'react';
import {FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";
import {inputStyle, labelStyle} from "./formInput";

const FormDateInput = ({name,label,placeholder,values,errors,touched,handleChange}) => {
    return (
        <FormControl fullWidth sx={{mb:"16px"}}>
            <FormLabel sx={labelStyle}>{label}</FormLabel>
            <TextField
                fullWidth
                name={name}
                sx={inputStyle}
                type={'date'}
                placeholder={placeholder}
                value={values[name]}
                onChange={handleChange}
                error={touched[name] && Boolean(errors[name])}
            />
            {errors[name] && touched[name] && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors[name]}</FormHelperText>}
        </FormControl>
    );
};

export default FormDateInput;