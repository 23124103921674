import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Modal, Radio, Select, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { RxCross1 } from "react-icons/rx";
import CustomButtoncolor from '../custom-button/customColorButton';
// import { sendReminder } from '../../store/crm/pos';
import { sendReminder } from '../../store/crm/pos';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useFormik } from 'formik';
import { getProviderStaff } from '../../store/crm/staff';
import { getAllClinics } from '../../store/clinics';
import { storeGHL } from '../../store/menus';
import { useParams } from 'react-router';
// import {inputStyle, labelStyle} from "../../components/form/formInput";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    p: '27px 12px',
    borderRadius:'6px',
    '@media (max-width: 480px)': {
      width: "95%",
    },
  };
  const wrapper={
    alignItems:"center",
  }
  const heading={ 
    fontSize:"18px",
    fontFamily:'helvetica-lt-std-roman',
    fontWeight:'400',
    textAlign:"center",
    width:'100%'

  }
  export const inputStyle = {
    background: "#EDF2F7",
    borderRadius: '4px',
    ".MuiOutlinedInput-notchedOutline": {
        borderWidth: "0!important"
    },
    ".MuiInputBase-input": {
        borderColor: " #EDF2F7",
        height: "19px",
        minHeight: 'unset',
        "&:focus": {
            borderColor: "#EDF2F7",
            outline: "none",
            "input": {
                height: '19px'
            }
        },
        "&:hover": {
            borderColor: "#EDF2F7",
            outline: "none",
        }
    }
} 

const AddWebHook = () => {
  const dispatch = useDispatch();
  // console.log("id get",customerId, bookingId )
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState("");
    const [staff, setStaff] = React.useState('');
    // clinics
    const params = useParams()
    const clinicStore = useSelector(state => state.clinicStore)
     console.log("clinic data", clinicStore?.clinics)
    const validate = (values) => {
      const errors = {};
      if (!values.message) {
        errors.message = "Message is required";
      }
      if (!values.staff) {
        errors.message = "Clinic is required";
      }
      return errors;
    };
  
    const handleChange = (event) => {
      setStaff(event.target.value)
    };
    const handleChangemessage = (event) => {
      setMessage(event.target.value)
    };
    // const formik = useFormik({
    //   initialValues: {
    //     message: "",
    //   },  
    //   validate, 
    //   onSubmit: (values) => {
    //     handleSendReminder(values.message);
    //   },
    // });
    useEffect(() => {
      if (!open) { 
      dispatch(getAllClinics());
      }
    }, [])
 
    const handleSendReminder = async () => {
      try {
        const payload = {
          provider_id: staff, // Corrected key name
          url: message
        };
        // Dispatch the action to send the reminder
        dispatch(storeGHL(payload));
        toast.success('Reminder sent successfully');
        handleClose();
      } catch (error) {
        console.log('Error sending reminder', error);
      }
    }
    
  
  return (      
    <>  
<CustomButtoncolor
  handleClick={handleOpen}
  mainColor={"#333333"}
  size="large"  
  val='Add'
  style={{
  width: "100%",  
  borderRadius: "6px!important"
}}      
     />
    <Modal
        style={{}}
        open={open}
        onClose={handleClose}  
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={1} sx={wrapper}>
            <Grid item lg={12} style={{ display: "contents" }}>
              <Typography    
                id="modal-modal-title"
                sx={heading}
              >
                Send Reminder
              </Typography>
            </Grid>
           
              <Grid item lg={12} md={6} sm={6}> 
               <FormControl    
                sx={{     
                  minWidth: "420px",
                   mt: { xs: 2, sm: 1 },  
                }}
              >     
                <FormLabel>Staff</FormLabel>  
                <Select    
                  value={staff}               
                  onChange={handleChange} 
                  displayEmpty 
                  // sx={selectStyle} 
                >
                  {clinicStore?.clinics?.data && clinicStore.clinics.data.map((service) => (
  <MenuItem key={service.id} value={service.id}>   
    {service.name}          
  </MenuItem>     
))}

                </Select> 
              </FormControl>   
              </Grid>
              <Grid item lg={12} md={6} sm={6}>
              <FormControl fullWidth>
                  <TextField
                    fullWidth   
                    name="message"         
                    variant='outlined'
                    // sx={inputStyle}  
                    placeholder="Message"   
                    value={message}    
                    onChange={handleChangemessage}
                    // onBlur={handleBlur}                  
                  />   
                </FormControl>          
{/* {formik.touched.message && formik.errors.message && 
                (<Typography color="error">{formik.errors.message}</Typography>)
                }   */}
                 
    </Grid>
    <Grid lg={12} style={{display:"flex",justifyContent:"flex-end",marginTop:"20px",spacing:"20px"}}>
      <div>
<Button 
variant="contained"
size="large"
color="error"
onClick={handleClose}
>
      Cancel
</Button>
      </div>
<div style={{marginLeft:"10px"}}>
<Button
variant="contained"
size="large"        
onClick={handleSendReminder}
>
  Update
</Button>
</div>



  </Grid>
          </Grid>
        </Box>
      </Modal>
   </>
  )
}

export default AddWebHook;

