import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { TableCell, Typography, Grid } from "@mui/material";
import moment from "moment";
import { scrollCSS } from "../../../../../App";
import { getCrmNotes } from "../../../../../store/crm/crm/crm-details";
import { getAppointmentDetails } from "../../../../../store/crm/appointment";
import Waveloading from '../../../../loading/Waveloading';
import AppointmentDetailsmodal from '../../../../../components/modal/AppointmentDetailsmodal';

const CrmNotes = () => {
    const [active, setActive] = useState(0)
    const [appointmentDetails, setAppointmentDetail] = useState(null)
    const [loading, setLoading] = useState(null)
    const crmDetailsStore = useSelector(state => state?.crmDetailsStore)
    const { notes = [] } = crmDetailsStore

    const dispatch = useDispatch()
    const { user_id } = useParams()


    useEffect(() => {
        (async () => {
            setLoading(true);
            await dispatch(getCrmNotes(user_id))
                .finally(() => setLoading(false));
        })()
    }, [])

    const handleChange = (event, newValue) => {
        setActive(newValue)
    }
    const handleAppointmentDetail = async (id) => {

        let { payload } = await dispatch(getAppointmentDetails(id))

        if (payload?.id) {
            setAppointmentDetail(payload)
        } else {
            setAppointmentDetail(null)
        }
    }
    return (
        <Box sx={{ background: "#fff", minHeight: '100px', borderRadius: "8px", p: { sm: '24px', xs: '8px' } }}>
            <Box sx={{
                borderBottom: 1,
                borderColor: 'divider',
                width: '100%',
                // overflowX: 'auto'

            }}>
                <Tabs
                    value={active}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{
                        ".MuiTabs-indicator": {
                            background: '#000000'
                        },
                        ".Mui-selected": {
                            fontWeight: '600',
                            color: '#000!important',
                        },
                        '@media (max-width: 600px)': { // Adjust based on your responsive design needs
                            width: 'auto',
                            display: 'flex',
                        },
                        '& .MuiTabs-scroller': {
                            display: 'flex', // Ensure that the scroller is always flex to align tabs correctly
                        },
                    }}
                >
                    <Tab
                        sx={{
                            fontFamily: active === 0 ? "helvetica-lt-std-bold" : "helvetica-lt-std-roman",
                            fontSize: '18px',
                            color: '#000',
                            textTransform: "capitalize",
                        }}
                        label={"Medical/Staff Notes "}
                        key={0}
                    />
                    <Tab
                        sx={{
                            fontFamily: active === 1 ? "helvetica-lt-std-bold" : "helvetica-lt-std-roman",
                            fontSize: '18px',
                            color: '#000',
                            textTransform: "capitalize",
                        }}
                        label={"Customer Notes"}
                        key={1} />
                </Tabs>
            </Box>
            {loading ? <Waveloading /> : ''}
            <Box
                sx={{
                    py: '24px',
                    overflowY: 'auto',
                    maxHeight: '500px',
                    maxWidth:'700px',
                    overflowX:'hidden',
                    ...scrollCSS,
                }}
            >
                {active === 0 && <Box>
                    {/* <Typography style={{marginTop:"20px",fontWeight:600,fontFamily: 'helvetica-lt-std-bold'}}>Zoey AI</Typography> */}
                    {notes?.data?.Medical?.length > 0 ?
                        notes?.data.Medical.map((note, index) => (
                            <Box
                                key={index}
                                sx={{
                                    py: '24px',
                                    px: '16px',
                                    borderTop: index > 0 ? '1px solid #9D9D9D' : 'unset',
                                }}>
                                <Typography sx={{ fontSize: '16px' }}>
                                    <Typography
                                        as={'span'}
                                        sx={{
                                            fontSize: '16px', fontFamily: "helvetica-world-bold",
                                            mr: '3px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        [ {note?.date} ]
                                        {note?.staff?.name}:
                                    </Typography>

                                    {note?.description}{note?.booking_id && (
                                        <span style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}>
                                            Booking #
                                            <span style={{ marginLeft: '8px' }}>
                                                <AppointmentDetailsmodal booking_id={note?.booking_id} />
                                            </span>
                                        </span>

                                    )}

                                </Typography>
                            </Box>
                        ))
                        : "none"
                    }
                    {/* <Typography style={{marginTop:"20px",fontWeight:600,fontFamily: 'helvetica-lt-std-bold'}}>Appointment</Typography>
                     {notes?.customer_appointments_notes?.Medical?.length > 0 ?
                        notes?.customer_appointments_notes?.Medical.map((note,index)=>(
                            <Box
                                key={index}
                                sx={{
                                    py:'24px',
                                    px:'16px',
                                    borderTop:index>0 ?'1px solid #9D9D9D':'unset',
                                }}>
                                <Typography sx={{fontSize:'16px'}}>
                                    <Typography
                                        as={'span'}
                                        sx={{
                                            fontSize:'16px',fontFamily:"helvetica-world-bold",
                                            mr:'3px',
                                            fontWeight:600,
                                        }}
                                    >
                                        [ {note?.date} ] 
                                        {note?.staff?.name}:
                                    </Typography>
                                    {note?.description} Booking #
                                    <Link to={`/crm/appointment/details/${note?.booking_id}`} style={{ color: 'inherit', cursor:"pointer" }}>
                                    {note?.booking_id}
                                    </Link>

                                    <span onClick={() => note?.booking_id && handleAppointmentDetail(note?.booking_id)}  style={{ color: '#2F80ED', cursor:  'pointer'  }}  >
                                        {note?.booking_id}
                                    </span>
                                </Typography>
                            </Box>  
                        ))
                        : "none"
                    } */}

                </Box>
                }
                {active === 1 && <Box>
                    {/* <Typography style={{marginTop:"20px",fontWeight:600,fontFamily: 'helvetica-lt-std-bold'}}>Appointment</Typography> */}
                    {notes?.customer_appointments_notes?.Customer?.length > 0 ?
                        notes?.customer_appointments_notes?.Customer.map((note, index) => (
                            <Box
                                key={index}
                                sx={{
                                    py: '24px',
                                    px: '16px',
                                    borderTop: index > 0 ? '1px solid #9D9D9D' : 'unset',
                                }}>
                                 <Typography sx={{fontSize:'16px', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                <span
                    sx={{
                        fontSize:'16px', fontFamily:"helvetica-world-bold",
                        fontWeight:600, marginRight: '8px'
                    }}
                >
                    [{note?.date}] {note?.staff?.name}
                </span>
                (
                    <AppointmentDetailsmodal booking_id={note?.booking_id} />
                )
                <span sx={{ marginLeft: '8px' }}>{note?.description}</span>
            </Typography>
                            </Box>
                        ))
                        : "none"
                    }
                </Box>}
            </Box>
        </Box>
    );
};

export default CrmNotes;