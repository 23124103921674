// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {data, reorderChats} from "./chatData";

// ** Fetch User Profile
export const fetchUserProfile = createAsyncThunk('appChat/fetchUserProfile', async () => {

    return data.profileUser
})

// ** Fetch Chats & Contacts
export const fetchChatsContacts = createAsyncThunk('appChat/fetchChatsContacts', async () => {
    const chatsContacts = data.chats.map(chat => {
        const contact = data.contacts.find(c => c.id === chat.userId)

        // @ts-ignore
        contact.chat = { id: chat.id, unseenMsgs: chat.unseenMsgs, lastMessage: chat.chat[chat.chat.length - 1] }

        return contact
    })   

    const contactsToShow = data.contacts.filter(co => {  
        return !data.chats.some(ch => {
            return co.id === ch.id
        })
    })

    const profileUserData = {
        id: data.profileUser.id,
        avatar: data.profileUser.avatar,
        fullName: data.profileUser.fullName,
        status: data.profileUser.status
    }
  
    return { chatsContacts, contacts: contactsToShow, profileUser: profileUserData }
})

// ** Select Chat
export const selectChat = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
    let userId = Number(id)
    const chat = data.chats.find(c => c.id === userId)
  
    const contact = data.contacts.find(c => c.id === userId)


    await dispatch(fetchChatsContacts())
 
    return { chat, contact }
})

// ** Send Msg
export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
    let activeChat = data.chats.find(chat => chat.id === obj.contact.id)  

    const newMessageData = {
        senderId: 11,
        time: new Date(),
        message: obj.message,
        feedback: {   
            isSent: true,
            isSeen: false,
            isDelivered: false
        }
    }

    // If there's new chat for user create one
    let isNewChat = false
    if (activeChat === undefined) {
        isNewChat = true
        data.chats.push({
            id: obj.contact.id,
            userId: obj.contact.id,
            unseenMsgs: 0,
            chat: [newMessageData]
        })
        activeChat = data.chats[data.chats.length - 1]
    } else {
        activeChat.chat.push(newMessageData)
    }
    const response = { newMessageData, id: obj.contact.id }

    // @ts-ignore
    if (isNewChat) { 
        response.chat = activeChat
    }
    reorderChats(
        data.chats,
        data.chats.findIndex(i => i.id === response.id),
        0
    )
    await dispatch(fetchChatsContacts())

    return response
})

export const appChatSlice = createSlice({
    name: 'appChat',
    initialState: {
        chats: null,
        contacts: null,
        userProfile: null,
        selectedChat: null
    },
    reducers: {
        removeSelectedChat: state => {
            state.selectedChat = null
        } 
    },
    extraReducers: builder => {
        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.userProfile = action.payload
        })
        builder.addCase(fetchChatsContacts.fulfilled, (state, action) => {
            state.contacts = action.payload.contacts
            state.chats = action.payload.chatsContacts
        })
        builder.addCase(selectChat.fulfilled, (state, action) => {
            state.selectedChat = action.payload
        })
    }
})

export const { removeSelectedChat } = appChatSlice.actions

export default appChatSlice.reducer
