import React, {useState} from 'react';
import {
    FormControl, FormHelperText,
    FormLabel,
    Grid, IconButton,
    TextField,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import {useFormik} from "formik";
import {dateOfBirthMonths, getMonthDays, successConfirmation} from "../../../services/utills";
import Loader from "../../../components/loader/loader";
import FormInput, {inputStyle, labelStyle} from "../../../components/form/formInput";
import FormInputPhone from "../../../components/form/formInputPhone";
import Autocomplete from "@mui/material/Autocomplete";
import SubmitButton from "../../../components/custom-button/submitButton";
import FormPasswordInput from "../../../components/form/formPasswordInput";
import CustomButton from "../../../components/custom-button/customButton";
import {useNavigate} from "react-router";
import ClearIcon from "@mui/icons-material/Clear";
import {storeCrmCustomer} from "../../../store/crm/crm";
import {useDispatch} from "react-redux";
import CustomBreadcrumbs from "../../../components/breadcrumbs/breadcrumbs";
import Waveloading from '../../loading/Waveloading';
const AddNewCustomer = () => {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),  
        email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
        phone: Yup.string().when("countryCode", {
            is: "+92",
            then: Yup.string()
                .matches(/^[1-9][0-9]\d{8}$/, "Invalid pakistani phone number")    
                .required("Phone number is required"),
            otherwise: Yup.string()
                .matches(/^(\d{3}-?)?\d{3}-?\d{4}$/, "Invalid canada phone number")
                .required("Phone number is required"),   
        }),   
countryCode: Yup.string(),
postal_code: Yup.string()              
    .matches(/^[A-Za-z0-9]+$/, 'Postal code must only contain numbers and letters')
    .length(6, 'Postal code must be exactly 6 characters'),
        state: Yup.string(),   
        country: Yup.string(), 
        city: Yup.string(),
        address: Yup.string(),
        birth_month: Yup.mixed(),
        birth_day: Yup.mixed(),
        password: Yup.string()
            .min(8, "Password must be 8 characters long")
            .required("Password is Required"),   
    });
    const {
        values,
        handleSubmit,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        handleChange,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            name: "",  
            email: "",
            phone: "",
            countryCode: "+1",
            password: "",
            city: "",
            state: "",
            country: "",
            birth_day: null,
            birth_month: null,
            address: "",
            postal_code: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (data) => {

            const {birth_day , birth_month,postal_code,address,country,state,city} =data
            if (birth_month !== null){
                if (birth_day === null){
                    await setFieldTouched('birth_day', true)
                    setFieldError('birth_day' ,"Birth Date is required")
                    return;
                }   
            }
            if (birth_day !== null){   
                if (birth_month === null){
                    await setFieldTouched('birth_month', true)
                    setFieldError('birth_month' ,"Birth Month is required")
                    return;
                }
            }   

            setLoading(true)
            let finalData = {
                name: data?.name,
                email: data?.email,
                phone: data?.countryCode+data?.phone,
                password: data?.password,
            }
            if (postal_code){
                finalData['postal_code'] = postal_code
            }
            if (address){
                finalData['address'] = address
            }
            if (country){
                finalData['country'] = country
            }
            if (state){
                finalData['state'] = state
            }
            if (city){
                finalData['city'] = city
            }

   
            let {payload} =await dispatch(storeCrmCustomer(finalData))

            setLoading(false)
            if (payload?.success === 'true'){
                await successConfirmation('Success','Congratulations a new customer has been added to your clinic.')
                navigate('/crm/customer-relationship-management')
            }else {
                const errors = payload?.data?.errors
                try {
                    if (Object.keys(errors)?.length > 0) {
                        Object.keys(errors).forEach(function (key) {
                            setFieldTouched(key, true);
                            setFieldError(key, errors[key]);
                        });
                    }   
                } catch (e) {}
            }

        },
    });

    return (
        <Box>
            {loading ? <Waveloading fullSize/> : ""}
            <Grid container>
                <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                    <CustomBreadcrumbs
                        back={'/crm/customer-relationship-management'}
                        backLabel={'Customer Relationship Management (CRM)'}
                        name={"New Customer" || null}
                    />

                </Grid>
            </Grid>
            <Box sx={{ minHeight: 'calc(100vh - 164px)', background: "#fff", padding:'20px' ,borderRadius:'7px',mt:'24px'}}>
                <form onSubmit={handleSubmit}>   
                    <Grid container sx={{}}>
                       <Grid item xs={12} sx={{mb:'24px'}}>
                           <Typography sx={{fontFamily:'helvetica-lt-std-bold',fontSize:'24px',textAlign:'center'}}>
                               Add New Customer
                           </Typography>
                       </Grid>
                        <Grid item lg={12} md={12} xs={12} xl={9.9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} lg={4}>   
                                    <FormInput  
                                        name={"name"}   
                                        label={"Full Name"}
                                        placeholder={"Type your name"}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange} 
                                    />   
                                </Grid>        
                                <Grid item xs={12} sm={6} lg={4}>
                                    <FormInputPhone
                                        name={"phone"} 
                                        label={"Contact Number"}
                                        placeholder={values?.countryCode === '+1' ? "xxx-xxx-xxxx" : "3xxxxxxxxx"}
                                        errors={errors}
                                        touched={touched} 
                                        values={values}
                                        handleChange={handleChange} 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} sx={{display: 'flex'}}>
                                    <FormInput
                                        name={"email"}
                                        label={"Email"}
                                        placeholder={"Email"}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormControl fullWidth>
                                        <FormLabel sx={labelStyle}>Date of Birth</FormLabel>
                                        <Box sx={{display: 'flex', alignItems: 'center', gap: '10px', width: '100%'}}>
                                            <Autocomplete
                                                fullWidth
                                                options={dateOfBirthMonths}
                                                getOptionLabel={(option) => option}
                                                value={values?.birth_month}
                                                disableClearable
                                                isOptionEqualToValue={(option, value) => option === value}
                                                onChange={(event, value) => {
                                                    setFieldValue('birth_month', value)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}  
                                                        placeholder={'Month'}
                                                        sx={inputStyle}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {values?.birth_month && (
                                                                        <IconButton
                                                                            sx={{p:'5px'}}
                                                                            onClick={()=>{
                                                                                setFieldValue('birth_month', null)
                                                                                setFieldValue('birth_day', null)
                                                                            }}
                                                                        >
                                                                            <ClearIcon sx={{fontSize:"16px"}}/>
                                                                        </IconButton>
                                                                    )}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Autocomplete
                                                fullWidth
                                                options={getMonthDays(values?.birth_month)}
                                                getOptionLabel={(option) => option.toString()}
                                                value={values?.birth_day}
                                                disableClearable
                                                isOptionEqualToValue={(option, value) => option.toString() === value.toString()}
                                                onChange={(event, value) => {
                                                    setFieldValue('birth_day', value)
                                                }}  
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder={'Date'} sx={inputStyle}/>   
                                                )}
                                            />
                                        </Box>
                                        {errors?.birth_day && touched?.birth_day && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors?.birth_day}</FormHelperText>}
                                        {errors?.birth_month && touched?.birth_month && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors?.birth_month}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormInput
                                        name={"address"}
                                        label={"Address"}
                                        placeholder={"Type your address"}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}   
                                    />
                                </Grid>  
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormInput
                                        name={"city"}
                                        label={"City"}
                                        placeholder={"City"}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                    />
                                </Grid>  

                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormInput
                                        name={"state"}
                                        label={"State"}
                                        placeholder={"State"}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormInput
                                        name={"country"}
                                        label={"Country"}
                                        placeholder={"Country"}
                                        errors={errors} 
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormInput
                                        name={"postal_code"}
                                        label={"Postal Code"}
                                        placeholder={"Postal Code"}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={4} sm={6} xs={12}>
                                    <FormPasswordInput
                                        name={"password"}
                                        label={"Set Password"}
                                        placeholder={"Type your password"}
                                        errors={errors}
                                        touched={touched}
                                        values={values}
                                        handleChange={handleChange}
                                    />
                                </Grid>  
                            </Grid>

                            <Box sx={{display: 'flex', justifyContent: 'end',marginTop:'24px'}}>
                                <Box sx={{mr:"16px"}}>
                                    <CustomButton
                                        mainColor={"#ED1E45"}
                                        val={'Cancel'}
                                        handleClick={()=>navigate('/crm/customer-relationship-management')}
                                    />
                                </Box>
                                <SubmitButton val={"Add New Customer"}/>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    );
}

export default AddNewCustomer;