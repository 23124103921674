import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Grid} from "@mui/material";
import FormPasswordInput from "../../../../components/form/formPasswordInput";
import SubmitButton from "../../../../components/custom-button/submitButton";
import Loader from "../../../../components/loader/loader";
import {storeClinicUpdatePassword} from "../../../../store/clinics/clinicSettings/update-password";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";

const UpdatePassword = () => {
    const [loading,setLoading] = useState(false)
    const dispatch = useDispatch();
    const params = useParams();


    const validationSchema = Yup.object({
        confirm_password: Yup.string()
            .min(8, 'Password must be 8 characters long')
         /*   .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')*/
            .oneOf([Yup.ref('new_password'), null], 'Confirm password must match')
            .required("Confirm Password is Required"),
        new_password: Yup.string()
            .min(8, 'Password must be 8 characters long')
          /*  .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')*/
            .required("Password is Required"),
        old_password: Yup.string().required("Password is Required"),
    });
    const {values, handleSubmit,setFieldTouched,setFieldError, handleChange, touched, errors} = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {}) => {

            setLoading(true)
            let res = await dispatch(storeClinicUpdatePassword({id: params?.clinic_id, ...data}))
            if (res?.payload?.errors) {
                let errors = res?.payload?.errors
                if (typeof errors === 'string') {
                    if (errors.includes('Old password')){
                        await setFieldTouched("old_password", true)
                        setFieldError("old_password", errors)
                    }else {
                        toast.error(errors ,{duration:3000})
                    }
                }else if (errors?.old_password) {
                        await setFieldTouched("old_password", true)
                        setFieldError("old_password", errors?.old_password)
                }else if (errors?.new_password) {
                       await setFieldTouched("new_password", true)
                        setFieldError("new_password", errors?.new_password)
                }else if (errors?.confirm_password) {
                       await setFieldTouched("confirm_password", true)
                        setFieldError("confirm_password", errors?.confirm_password)
                }

            }
            setLoading(false)
        },
    });


    return (
        <form onSubmit={handleSubmit}>
            {loading ? <Loader fullSize/>: ""}
            <Grid container p={'20px'} sx={{justifyContent:'space-between'}}>
                <Grid item xs={12} lg={5.5}>
                    <FormPasswordInput
                        name={"new_password"}
                        label={"New Password"}
                        placeholder={'Type new  password'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormPasswordInput
                        name={"confirm_password"}
                        label={"Confirm Password"}
                        placeholder={'Type confirm password'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={5.5}>
                    <FormPasswordInput
                        name={"old_password"}
                        label={"Old Password"}
                        placeholder={'Type old password'}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sx={{display:'flex',justifyContent:'flex-end'}}>
                    <SubmitButton val={"Update"}/>
                </Grid>
            </Grid>
        </form>
    );
};

export default UpdatePassword;