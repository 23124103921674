import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {Avatar, Button, Grid, IconButton, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {scrollCSS} from "../../../../../App";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {MySwal, successConfirmation} from "../../../../../services/utills";
import Loader from "../../../../../components/loader/loader";
import {deleteCrmAttachment, getCrmAttachments} from "../../../../../store/crm/crm/crm-details";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import moment from "moment";
import CSV from '../../../../../assets/images/csv.png'
import word from '../../../../../assets/images/word.png'
import PDF from '../../../../../assets/images/pdf.png'
import Waveloading from '../../../../loading/Waveloading';
import { deleteAppointmentAttachment, getAppointmentAttachments } from '../../../../../store/crm/appointment/attachment';

const CrmAttachments = () => {
    const [onHover, setOnHover] = useState(false)
    const [loading, setLoading] = useState(false)
    const [attachmentDetails, setAttachmentDetails] = useState(null)
    const [activeView, setActiveView] = useState( "card")

    const crmDetailsStore = useSelector(state => state?.crmDetailsStore)
    const {attachments=[]} = crmDetailsStore

    const dispatch = useDispatch()
    const {user_id} = useParams()

    useEffect(()=>{
        (async ()=>{
            await dispatch(getCrmAttachments(user_id))
        })()
    },[])
    // useEffect(()=>{
    //     (async ()=>{
    //         await dispatch(getAppointmentAttachments(user_id))
    //     })()
    // },[])


    const handleDelete = async (id) => { 

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? you want to remove this attachment.
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes,Delete It.
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box> 
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
           
            let res = await dispatch(deleteCrmAttachment({id:id,customer_id:user_id}))
            console.log("res",res)
            if (res?.type === "deleteCrmAttachment/fulfilled") {
                await successConfirmation('Appointment Attachment Deleted Successfully')
            }
            setLoading(false) 
        }
 
    }


    const handleDeleteAppointment = async (id) => {

        let confirm = await MySwal.fire({
            icon: 'question',
            title: 'Confirmation',
            showConfirmButton: false,
            html: <Box sx={{mb: 4}}>
                <Typography sx={{}}>
                    Are you sure? you want to remove this attachment.
                </Typography>
                <Box sx={{mt: 3,}}>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            fontSize: '16px',
                            px: '20px',
                            mx: '10px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.clickConfirm()}
                    >
                        Yes,Delete It.
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: "#fff",
                            minWidth: "max-content",
                            border: "1px solid #666666",
                            borderRadius: '10px',
                            px: '10px',
                            mx: '10px',
                            fontSize: '16px',
                            color: "#666666",
                            "&:hover": {
                                backgroundColor: "#fff",
                                border: "1px solid #666666",
                            },
                        }}
                        onClick={() => MySwal.close()}
                    >
                        Cancel
                    </Button>
                </Box> 
            </Box>
        })
        if (!!confirm?.isConfirmed) {
            setLoading(true)
           
            let res = await dispatch(deleteAppointmentAttachment({id:id,customer_id:user_id}))
            await dispatch(getCrmAttachments(user_id))
            
            console.log("res",res)
            if (res?.type === "deleteAppointmentAttachment/fulfilled") {
                await successConfirmation('Appointment Attachment Deleted Successfully')
            }
            setLoading(false) 
        }
 
    }

    const handleSelectedGallery = (attachment) => {

        if (attachmentDetails?.id){
            setAttachmentDetails(attachmentDetails?.id === attachment?.id  ? null : attachment)
            // setActiveView("card")
        }else {
            setAttachmentDetails(attachment)
            setActiveView("list")
        }
    }

    return (
        <Box
            sx={{
                background: "#fff",
                minHeight: '100px',
                borderRadius: "8px",
                p:{sm:'24px', xs:'8px'}
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: "center",
                    mb: "32px",
                }}
            >
                <Typography
                    sx={{
                        fontSize: '20px',
                    }}
                >
                    Attachments
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        gap: '2px',
                        alignItems: "center",
                    }}
                >
                    <IconButton
                        onClick={() => setActiveView("card")}
                    >
                        <DashboardIcon
                            sx={{color: activeView === 'card' ? '#09ABF1' : '#000'}}
                        />
                    </IconButton>
                    <IconButton
                        onClick={() => setActiveView("list")}
                    >
                        <ViewStreamIcon
                            sx={{color: activeView === 'list' ? '#09ABF1' : '#000'}}
                        />
                    </IconButton>
                </Box>
            </Box>

            {loading ? <Waveloading fullSize/> : ""}

            {activeView === 'list' &&
                <Grid
                    container
                    sx={{
                        gap: '20px',
                        overflowY: 'auto',
                        maxHeight: '500px',
                        ...scrollCSS,
                    }}
                >
      {  
       attachments?.data?.length > 0 ?
       attachments?.data.map((attachment) =>(
                                <Grid
                                    item
                                    xs={12}
                                    key={attachment?.id}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            background: '#F2F2F2',
                                            borderRadius: "4px",
                                            cursor:'pointer',
                                        }}
                                        onClick={() => handleSelectedGallery(attachment)}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'helvetica-lt-std-bold',
                                                ml: "10px",
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {attachment?.tag ? attachment?.tag?.tag_name : moment(attachment?.created_at).format("YYYY-MM-DD hh:mm A")}
                                        </Typography>

                                        <IconButton
                                            onClick={() => handleSelectedGallery(attachment)}
                                        >
                                            {attachmentDetails?.id === attachment?.id ?  <ArrowDropDownIcon/>:<ArrowDropUpIcon/>}

                                        </IconButton>
                                    </Box>
                                    {attachmentDetails?.id === attachment?.id && <Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mt: '16px',
                                                display: "flex",
                                                gap: '16px',
                                                flexWrap:'wrap',
                                            }}
                                        >
                                            {
                                                attachment?.files?.length > 0 ?
                                                    attachment?.files.map((file, index) => (
                                                        <div key={index} style={{ marginTop: '8px' }}>
                                                        <Avatar
                                                            key={index}
                                                            src={file?.type === 'pdf' && PDF || file?.type === 'word' && word || file?.type === 'csv' && CSV || file?.file}
                                                            onClick={()=>window.open(file?.file,'_blank')}
                                                            sx={{
                                                                cursor:'pointer',
                                                                height: '180px',
                                                                width: '180px',
                                                                border:'1px solid #E8E8E8',
                                                            }}
                                                            variant="rounded"
                                                        />
                                                        <Box sx={{
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                            mt: "8px"
                                                        }}>
                                                            {file?.file_name}
                                                        </Box>
                                                        </div>

                                                ))
                                                    :
                                                    <Avatar
                                                        sx={{
                                                            height: '100%',
                                                            width: '100%',
                                                        }}
                                                        variant="rounded"
                                                    />
                                            }
                                        </Box>

                                    {/*    <Box sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            mt: "8px"
                                        }}>
                                            {moment(attachment?.created_at).format("YYYY-MM-DD hh:mm A")}
                                        </Box>*/}
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            mt: "16px"
                                        }}>

                                            <Typography
                                                sx={{
                                                    fontFamily: 'helvetica-lt-std-bold',
                                                    background: '#F2F2F2',
                                                    mr: '10px',
                                                    pl: '5px',
                                                    pr: '3px',
                                                }}
                                            >
                                                Comments
                                            </Typography>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {attachment?.comment}
                                                </Typography>

                                               
                                            </Box>
                                            
                                            <Box style={{ width:"100%",display:'flex',justifyContent:'flex-end'}}>
                                            <Typography
                                                    sx={{
                                                        m:"10px",
                                                        mt:"10px",
                                                        width:'max-content',
                                                        cursor:'pointer',
                                                        fontSize: '14px',
                                                        background:'#CB3838',
                                                        color:'#fff',
                                                        p:'5px 10px',
                                                        borderRadius:'4px',
                                                    }}
                                                    onClick={()=>handleDelete(attachment?.id)}
                                                >
                                                    Delete
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                    }
                                </Grid>
                            ))
                            :
                           ""
                    }
                     <Grid xs={12} item>
                    <Typography style={{fontWeight:600}}>Appointment</Typography>
                    </Grid>
                    {   
       attachments?.appointment_attachment?.length > 0 ?
       attachments?.appointment_attachment
       .map((attachment) =>(
                                <Grid
                                    item
                                    xs={12}
                                    key={attachment?.id}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            background: '#F2F2F2',
                                            borderRadius: "4px",
                                            cursor:'pointer',
                                        }}
                                        onClick={() => handleSelectedGallery(attachment)}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'helvetica-lt-std-bold',
                                                ml: "10px",
                                                textTransform:'capitalize',
                                            }}
                                        >
                                            {attachment?.tag?.tag_name}
                                        </Typography>

                                        <IconButton
                                            onClick={() => handleSelectedGallery(attachment)}
                                        >
                                            {attachmentDetails?.id === attachment?.id ?  <ArrowDropDownIcon/>:<ArrowDropUpIcon/>}

                                        </IconButton>
                                    </Box>
                                    {attachmentDetails?.id === attachment?.id && <Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                mt: '16px',
                                                display: "flex",
                                                gap: '16px',
                                                flexWrap:'wrap',
                                            }}
                                        >
                                            {
                                                attachment?.files?.length > 0 ?
                                                    attachment?.files.map((file, index) => (
                                                        <Avatar
                                                            key={index}
                                                            src={file?.type === 'pdf' && PDF || file?.type === 'word' && word || file?.type === 'csv' && CSV || file?.file}
                                                            onClick={()=>window.open(file?.file,'_blank')}
                                                            sx={{
                                                                cursor:'pointer',
                                                                height: '180px',
                                                                width: '180px',
                                                                border:'1px solid #E8E8E8',
                                                            }}
                                                            variant="rounded"
                                                        />
                                                    ))
                                                    :
                                                    <Avatar
                                                        sx={{
                                                            height: '100%',
                                                            width: '100%',
                                                        }}
                                                        variant="rounded"
                                                    />
                                            }
                                        </Box>

                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            mt: "8px"
                                        }}>
                                            {moment(attachment?.created_at).format("YYYY-MM-DD hh:mm A")}
                                        </Box>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            mt: "16px"
                                        }}>

                                            <Typography
                                                sx={{
                                                    fontFamily: 'helvetica-lt-std-bold',
                                                    background: '#F2F2F2',
                                                    mr: '10px',
                                                    pl: '5px',
                                                    pr: '3px',
                                                }}
                                            >
                                                Comments
                                            </Typography>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {attachment?.comment}
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        mt:"10px",
                                                        width:'max-content',
                                                        cursor:'pointer',
                                                        fontSize: '14px',
                                                        background:'#CB3838',
                                                        color:'#fff',
                                                        p:'5px 10px',
                                                        borderRadius:'4px',
                                                    }}
                                                    onClick={()=>handleDelete(attachment?.id)}
                                                >
                                                    Delete
                                                </Typography>
                                            </Box>
                                        </Box>

                                    </Box>
                                    }
                                </Grid>
                            ))
                            :
                           ""
                    }


                </Grid>
            }
            {activeView === 'card' &&
                <Grid
                    container
                    sx={{
                        gap: '30px',
                        overflowY: 'auto',
                        maxHeight: '500px',
                        ...scrollCSS,
                    }}
                >
                    
                    {
                        attachments?.data?.length > 0 ?
                            attachments?.data.map((attachment) =>(
                                <Grid
                                    key={attachment?.id}
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => !onHover && handleSelectedGallery(attachment)}
                                >
                                    <IconButton
                                        sx={{
                                            fontSize: "15px",
                                            position: "relative",
                                            p: "5px",
                                            zIndex: '1',
                                            background: "#EB5757",
                                            border: '1px solid #EB5757',
                                            m: '5px',
                                            left: "9rem",
                                            top: "0px",
                                            "&:hover": {
                                                border: '1px solid #EB5757',
                                                background: '#fff',
                                                "svg": {
                                                    color: '#EB5757'
                                                }
                                            }
                                        }}
                                        onMouseEnter={() => {
                                            setOnHover(true)
                                        }}
                                        onMouseLeave={() => {
                                            setOnHover(false)
                                        }}
                                        onClick={() => onHover && handleDelete(attachment?.id)}
                                    >
                                        <DeleteOutlineIcon sx={{color: '#fff', fontSize: '20px'}}/>
                                    </IconButton>
                                    <Box
                                        sx={{
                                            height: '185px',
                                            mt: "-41px",
                                            width: '185px',
                                            p: '10px',
                                            border: '1px solid #BDBDBD',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {
                                            attachment?.files?.length > 0 ?
                                                attachment?.files.map((file, index) => index < 2 && (
                                                    <Avatar
                                                        key={index}
                                                        src={file?.type === 'pdf' && PDF || file?.type === 'word' && word || file?.type === 'csv' && CSV || file?.file}
                                                        sx={{
                                                            height: attachment?.files?.length > 1 ? '145px' : '100%',
                                                            width: attachment?.files?.length > 1 ? '145px' : '100%',
                                                            position: "relative",
                                                            top: index === 1 ? "-128px" : '',
                                                            left: index === 1 ? "17px" : '',
                                                        }}
                                                        variant="rounded"
                                                    />
                                                ))
                                                :
                                                <Avatar
                                                    sx={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                    variant="rounded"
                                                />
                                        }
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            mt: '16px',
                                            maxWidth: '185px',
                                            textTransform:'capitalize',
                                            fontFamily: 'helvetica-lt-std-bold',
                                        }}
                                    >
                                        {attachment?.tag?.tag_name}
                                    </Typography>
                                </Grid>
                            ))
                            :
                            <Grid xs={12} item>
                                <Typography sx={{textAlign: 'center'}}>None</Typography>
                            </Grid>
                    }
                    <Grid xs={12} item>
                    <Typography style={{fontWeight:600}}>Appointment</Typography>
                    </Grid>
                   
                     {
                        attachments?.appointment_attachment?.length > 0 ?
                            attachments?.appointment_attachment
                            .map((attachment) =>(
                                <Grid
                                    key={attachment?.id}
                                    sx={{cursor: 'pointer'}}
                                    onClick={() => !onHover && handleSelectedGallery(attachment)}
                                >
                                    <IconButton
                                        sx={{
                                            fontSize: "15px",
                                            position: "relative",
                                            p: "5px",
                                            zIndex: '1',
                                            background: "#EB5757",
                                            border: '1px solid #EB5757',
                                            m: '5px',
                                            left: "9rem",
                                            top: "0px",
                                            "&:hover": {
                                                border: '1px solid #EB5757',
                                                background: '#fff',
                                                "svg": {
                                                    color: '#EB5757'
                                                }
                                            }
                                        }}
                                        onMouseEnter={() => {
                                            setOnHover(true)
                                        }}
                                        onMouseLeave={() => {
                                            setOnHover(false)
                                        }}
                                        onClick={() => onHover && handleDeleteAppointment(attachment?.id)}
                                    >
                                        <DeleteOutlineIcon sx={{color: '#fff', fontSize: '20px'}}/>
                                    </IconButton>
                                    <Box
                                        sx={{
                                            height: '185px',
                                            mt: "-41px",
                                            width: '185px',
                                            p: '10px',
                                            border: '1px solid #BDBDBD',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {
                                            attachment?.files?.length > 0 ?
                                                attachment?.files.map((file, index) => index < 2 && (
                                                    <Avatar
                                                        key={index}
                                                        src={file?.type === 'pdf' && PDF || file?.type === 'word' && word || file?.type === 'csv' && CSV || file?.file}
                                                        sx={{
                                                            height: attachment?.files?.length > 1 ? '145px' : '100%',
                                                            width: attachment?.files?.length > 1 ? '145px' : '100%',
                                                            position: "relative",
                                                            top: index === 1 ? "-128px" : '',
                                                            left: index === 1 ? "17px" : '',
                                                        }}
                                                        variant="rounded"
                                                    />
                                                ))
                                                :
                                                <Avatar
                                                    sx={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                    variant="rounded"
                                                />
                                        }
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            mt: '16px',
                                            maxWidth: '185px',
                                            textTransform:'capitalize',
                                            fontFamily: 'helvetica-lt-std-bold',
                                        }}
                                    >
                                        {attachment?.tag?.tag_name}
                                    </Typography>
                                </Grid>
                            ))
                            :
                           ""
                    }
                </Grid>
            }
        </Box>
    );
};

export default CrmAttachments;