import React, { useEffect, useState } from 'react';
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Reduced dateArray to only include week options
const dateArray = [
    "This Week",
    "Last Week",
    "Custom Range",
];

const DateRangePicker = ({ setStartDate, setEndDate, startDate, endDate, color, style, widthMember, smWidth ,onChange}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [active, setActive] = useState('');
    const [isCustomDateRange, setIsCustomDateRange] = useState(false);
    const [calenderStartTime, setCalenderStartTime] = useState(moment().startOf('isoWeek').toDate()); // Start of this week
    const [calenderEndTime, setCalenderEndTime] = useState(moment().endOf('isoWeek').toDate()); // End of this week

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const triggerOnChange = (start, end) => {
        setStartDate(start);
        setEndDate(end);
        // Trigger the passed onChange function with the new start and end dates
        if (onChange) {
            onChange(start, end);
        }
    };

    const handleSelectDate = async (key) => {
        setActive(key);
        setIsCustomDateRange(false);

        let start, end;
        switch (key) {
            case 'This Week':
                start = moment().startOf('isoWeek');
                end = moment().endOf('isoWeek');
                break;
            case 'Last Week':
                start = moment().subtract(1, 'weeks').startOf('isoWeek');
                end = moment().subtract(1, 'weeks').endOf('isoWeek');
                break;
            case 'Custom Range':
               
                setIsCustomDateRange(true);
                break;
            default:
                handleClose();
                return;
        }
        triggerOnChange(start, end);

        if (key !== 'Custom Range') {
          
            handleClose();
        }
    };
    const onChangeCustomRange = (dates) => {
        const [start, end] = dates;
        if (start && end) { // Both start and end dates are selected
            const formattedStart = moment(start).format('YYYY-MM-DD');
            const formattedEnd = moment(end).format('YYYY-MM-DD');
            setCalenderStartTime(start);
            setCalenderEndTime(end);
            setIsCustomDateRange(false);
            triggerOnChange(formattedStart, formattedEnd);
            handleClose();
        } else if (start && !end) { // Only start date is selected
            const newEnd = moment(start).add(6, 'days').toDate();
            setCalenderStartTime(start);
            setCalenderEndTime(newEnd);
            setIsCustomDateRange(false);
            triggerOnChange(moment(start).format('YYYY-MM-DD'), moment(newEnd).format('YYYY-MM-DD'));
        } handleClose();
    };
    
    
    useEffect(() => {
        setStartDate(calenderStartTime);
        setEndDate(calenderEndTime); 
    }, []);
   

    const formatDate = date => {
        return moment(date).format('DD'); // Format to show only day
    };

    return (
        <Box   sx={{width:{lg:widthMember || "100%", md:'100%', sm:'100%', xs:'100%'}, '@media (min-width:1600px)': {width:smWidth},mr:{lg:0, xs:2}}}>
            <Box
                sx={{
                    width: "100%",
                    background: color || '#fff',
                    borderRadius: '4px',
                    minHeight: '44px',
                    cursor: 'pointer',
                    fontSize: "1rem",
                    p: '13px 16px',
                    fontFamily: 'helvetica-lt-std-roman',
                    '&:hover': {
                        background: "#f1f1f1",
                    },
                }}
                onClick={handleClick}
            >
                <Typography variant="body1">
                    {startDate && moment(startDate).format('MMMM DD, YYYY')} - {endDate && moment(endDate).format('MMMM DD, YYYY')}
                </Typography>
            </Box>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: "max-content",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {dateArray?.map((item, index) => (
                    <MenuItem
                        key={index}
                        sx={{
                            py: '7px',
                            width: "230px",
                            my: '3px',
                            background: item === active ? "#09ABF1!important" : '#fff',
                            color: item === active ? "#fff" : '#000',
                            "&:hover": {
                                background: "#09ABF1",
                                color: "#fff",
                            }
                        }}
                        onClick={() => handleSelectDate(item)}
                    >
                        {item}
                    </MenuItem>
                ))}
            </Menu>
            {isCustomDateRange && <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: "max-content",
                        height:"max-content",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        "ul":{
                            p:'0',
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                sx={{
                    display: "flex"
                }}
            >
               <DatePicker
                    selected={calenderStartTime}
                    onChange={onChangeCustomRange}
                    startDate={calenderStartTime}
                    endDate={calenderEndTime}
                    shouldCloseOnSelect={false}
                    selectsRange
                    inline
                    monthsShown={2}
                    disabledKeyboardNavigation
                  
                />
            </Menu>
            }
        </Box>
    );
};

export default DateRangePicker;
