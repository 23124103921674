import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";
import moment from "moment/moment";

export const ActivityLogData = createAsyncThunk('ActivityLogData', async (type,{getState}) => {
   const {
    treatmentStartTime,
    treatmentEndTime,
     

   } = getState()?.ActivityLogStore
    try {
        const payload = {
          
            start_date: moment(treatmentStartTime).format('YYYY-MM-DD'),
            end_date: moment(treatmentEndTime).format('YYYY-MM-DD'),
        };
      const response = await axios.post(apis.activity_log_data, payload, {
        headers: apiHeaders(),
      });

      if (response?.data?.success ==='true'){
          return response.data?.data;
      }else {
          return []
      }
    } catch (error) {
        return []
    }
});


export const updateActivityLogFilters = createAsyncThunk('updateActivityLogFilters', async (data) => {
    return data
})




  export const crmActivityLogSlice = createSlice({
    name: 'crmActivityLogSlice',
    initialState: {
       
   ActivityLogs:[],
   treatmentStartTime: moment().format('YYYY-MM-DD'),
   treatmentEndTime: moment().format('YYYY-MM-DD'),
   
  
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(ActivityLogData.fulfilled, (state, action) => {
            state.ActivityLogs = action.payload || [];
        });
        builder.addCase(updateActivityLogFilters.fulfilled, (state, action) => {
            const {filterBy, pageLimit, search, currentPage, type,startTime,endTime} = action?.payload
        
          
            if (startTime) {
               
                    state.treatmentStartTime = startTime;
            
            }
            if (endTime) {
              
                    state.treatmentEndTime = endTime;
                
            }
        });

       
    },
    
})
export default crmActivityLogSlice.reducer   