import React, {Fragment, useEffect, useState} from 'react';
import {MenuItem, Box, Typography, IconButton, Avatar, Menu, TextareaAutosize, Button, Select, Modal, Autocomplete, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import MuiAvatar from "@mui/material/Avatar";
import SearchIcon from '@mui/icons-material/Search';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from "@mui/material/Badge";
import ChatLog from "./chatLog";
import SendMsgForm from "./sendMsgForm";
import { clearSelectedChat, getChatdelete, getChatlist, getUserDetails } from '../../store/crm/chat-module';
import toast from 'react-hot-toast';
import Waveloading from '../../pages/loading/Waveloading';
import ClearIcon from '@mui/icons-material/Clear';
const ChatWrapperStartChat = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    borderRadius: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor:"#F1F5F9"
}))

const ChatContent = (props) => {
    const {
        store,
        hidden,
        SendMsg,
        mdAbove,
        dispatch, 
        statusObj,
        getInitials,
        sidebarWidth,
        userProfileRightOpen,
        // sendMessageViaWebSocket,
        handleLeftSidebarToggle,
        handleUserProfileRightSidebarToggle 
    } = props
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false); 
    const [filteredChat, setFilteredChat] = useState([])
    const open = Boolean(anchorEl)
    const [modalOpen, setModalOpen] = useState(false);
const [selectedUser, setSelectedUser] = useState('');
const [message, setMessage] = useState('');

    const handleOpenModal = () => setModalOpen(true);
const handleCloseModal = () => setModalOpen(false);
console.log("chat-list",store.Chatlist.length)
useEffect(() => {
    
    // Only call the function if store.Chatlist.length > 0, !selectedChat, and screen size is above 400px (mdAbove)
    if (store.Chatlist.length > 0 && !store.selectedChat ) {
        handleLeftSidebarToggle(); // Automatically open the sidebar
    }
  }, [store.Chatlist.length, store.selectedChat]);

const handleSendMessage = async () => { 
    setLoading(true);

    const newMessage = {
        user_id: selectedCustomer?.id,
        name: store.contacts.find(contact => contact.id === selectedUser)?.name || 'Unknown User',
        lastMessage: {
            message: message,
            time: new Date().toISOString()  
        },   
        unread_count: 0  
    };

    setFilteredChat(prevChats => [newMessage, ...prevChats]);

    await dispatch(SendMsg({ user_id: selectedCustomer?.id, message }));
    await dispatch(getChatlist());
    // await dispatch(getUserDetails()); 

    
    setMessage('');  
    setSelectedUser('');  
    setLoading(false);
    handleCloseModal(); 
};




const [errors, setErrors] = useState({});
const [selectedCustomer, setSelectedCustomer] = useState(null);
const handleCustomerChange = (event, newValue) => {
    setSelectedCustomer(newValue);     
  };
  const validateFields = () => {
    const newErrors = {};
    if (!selectedCustomer) {
      newErrors.selectedCustomer = 'Customer is required';
    }
    // if (!message) {
    //     newErrors.selectedCustomer = 'messsage is required';
    //   }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

console.log("userprofile",store.userProfile )

const handleSaveClick = () => {
    if (validateFields()) {
        handleSendMessage()
    } else {
      toast.error("Please fill all required fields.");
    }
  }
    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

  
    const handleClose = async () => {
        setAnchorEl(null);
        
    };
    const handleDelete = async () => {
        setAnchorEl(null);
        const selectedChat = store.selectedChat;
    
       
        if (selectedChat?.user_id) {
            const response = await dispatch(getChatdelete(selectedChat.user_id))

            if (response?.payload?.success) {
                toast.success(response?.payload?.message);
                dispatch(clearSelectedChat());
                dispatch(getChatlist());
           
            } else {
                toast.error('Failed to delete user');
            }
            
        }
    }
    const handleStartConversation = () => {
        if (!mdAbove) {
            handleLeftSidebarToggle() 
        }
    }
    console.log("selected chat",store.Messages)

    const renderContent = () => { 
        if (store) { 
            const selectedChat = store.selectedChat 
            const getChat = store.Messages 
            if (!selectedChat) {   
                return (  
                    <ChatWrapperStartChat
                        sx={{
                            // ...(mdAbove ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : {})
                        }}
                    > 
                        <MuiAvatar   
                            sx={{ 
                                mb: 5, 
                                pt: 8,
                                pb: 7,
                                px: 7.5,   
                                width: 110,
                                height: 110, 
                                backgroundColor: 'background.paper',
                                boxShadow: theme => theme.shadows[3] 
                            }}
                        >
                            <ChatBubbleOutlineOutlinedIcon sx={{ width: 50, height: 50, color: 'action.active' }} />
                        </MuiAvatar>
                        <Box
                            onClick={handleStartConversation} 
                            sx={{
                                px: 6, 
                                py: 2.25,   
                                borderRadius: 5,
                                backgroundColor: 'background.paper',
                                boxShadow: theme => theme.shadows[3], 
                                cursor: mdAbove ? 'default' : 'pointer',
                                transition: 'background-color 0.3s ease', 
                            
                                '&:hover': {
                                    backgroundColor: '#0E7BE5',  
                                    color:"white", 
                                }
                            }}
                        >
                            <Typography sx={{ fontWeight: 600 ,   '&:hover': {
                                  
                                }}}  onClick={handleOpenModal} >Start Conversation</Typography>
                        </Box>
                        {loading ? <Waveloading fullSize /> : ""}
               <Modal 
                open={modalOpen}
                onClose={handleCloseModal} 
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ 
                    position: 'absolute', top: '50%', left: '50%', 
                    transform: 'translate(-50%, -50%)', width: 400,  
                    bgcolor: 'background.paper', p: 4, boxShadow: 24, 
                    borderRadius: 2 
                }}>
                    
                    <Box sx={{display:"flex",alignItems:"center",justifyContent:'space-between'}}>
                    <Typography id="mzodal-title" variant="h6" component="h2">
                        Send Message        
                    </Typography>  
                    
            <ClearIcon sx={{cursor:"pointer"}} onClick={handleCloseModal} />
                    </Box>

                   
                           <Autocomplete  
                                      fullWidth
                                      options={store?.contacts || []}
                                      getOptionLabel={(user) => user.name}
                                      onChange={handleCustomerChange}
                                      renderInput={(params) => (
                                          <TextField
                                              {...params}
                                              label="Customer"
                                              margin="normal" 
                                              variant="outlined"
                                              error={!!errors.selectedCustomer}
                                              helperText={errors.selectedCustomer}
                                          />   
                                      )}
                                      renderOption={(props, user) => (
                                          <li {...props} key={user.id}>
                                            {user.name}
                                          </li>
                                      )}
                                  />       

                    <TextareaAutosize
                        minRows={4}  
                        placeholder="Type your message here..."
                        style={{ width: '100%', padding: 10, borderRadius: 5, borderColor: '#ccc' }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        // error={!!errors.message}
                        // helperText={errors.message} 
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2 }}
                        // onClick={handleSendMessage}
                        onClick={handleSaveClick}
                    >
                        Send Message 
                    </Button>
                </Box>
            </Modal>
                    </ChatWrapperStartChat>   
                )
            } else {
                return (  
                    <Box   
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                            height: '100%',
                            backgroundColor: "#fff"  ,
                            
                        }} 
                    >
                     <Box
                            sx={{
                                py: 1,
                                px: {sm:5, xs:3},
                                display: 'flex',
                                alignItems: 'center',   
                                justifyContent:{sm:'space-between' ,xs:'center'},
                                borderBottom: theme => `1px solid ${theme.palette.divider}`
                            }} 
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {mdAbove ? null : (
                                    <IconButton onClick={handleLeftSidebarToggle} sx={{ mr: 2 }}>
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                <Box     
                                    onClick={handleUserProfileRightSidebarToggle}
                                    sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                > 
                                    <Badge
                                        overlap='circular'
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        sx={{ mr: 4.5 }}
                                        badgeContent={
                                            <Box  
                                                component='span'
                                                sx={{ 
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    color: `${statusObj[selectedChat.status]}.main`,
                                                    boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`,
                                                    backgroundColor: `${statusObj[selectedChat.status]}.main`
                                                }}
                                            />
                                        }
                                    >
                                        {selectedChat.profile_image ? (
                                            <MuiAvatar
                                                src={selectedChat.profile_image}
                                                alt={selectedChat.name}
                                                sx={{ width: 40, height: 40 }}
                                            />
                                        ) : (
                                            <Avatar
                                                skin='light'
                                                color={'blue'}  
                                                sx={{ width: 40, height: 40, fontSize: '1rem' }}
                                            > 
                                                {getInitials(selectedChat.name)}
                                            </Avatar>
                                        )}
                                    </Badge>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography sx={{ color: 'text.secondary' }}>{selectedChat.name}</Typography>
                                        <Typography variant='body2' sx={{ color: 'text.disabled' }}>
                                            {selectedChat.email}
                                        </Typography> 
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}> 
                                {/*{mdAbove ? (*/} 
                                {/*    <Fragment>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <PhoneOutlinedIcon />*/} 
                                {/*        </IconButton>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <VideocamOutlinedIcon sx={{ fontSize: '1.5rem' }} />*/}
                                {/*        </IconButton>*/}
                                {/*        <IconButton size='small' sx={{ color: 'text.secondary' }}>*/}
                                {/*            <SearchIcon />*/}
                                {/*        </IconButton>*/}
                                {/*    </Fragment>*/}
                                {/*) : null}*/}
                                <IconButton size='small' onClick={handleClick} sx={{ color: 'text.secondary' }}>
                                    <MoreVertOutlinedIcon />
                                </IconButton>
                                <Menu
                                    open={open}
                                    sx={{ mt: 2 }}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{   
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right'
                                    }}
                                >
                                    <MenuItem onClick={handleDelete}>Delete Chat</MenuItem>
                                   
                                </Menu>
                            </Box>
                        </Box>

                        {/* {selectedChat && store.userProfile ? ( */}
                        {/* uxserContact: store.userProfile */} 
              
                        {getChat ?(
                            <ChatLog hidden={hidden}  data={{...selectedChat,chat:getChat, userContact: store.userProfile }} />
                        ) : null}
 
                        <SendMsgForm store={store}  dispatch={dispatch} SendMsg={SendMsg} />

                     
                    </Box>
                )   
            }
        } else {
            return null
        }
    }

    return renderContent()
};

export default ChatContent;