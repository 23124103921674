import { Box, Typography, Grid, TableCell, TablePagination, TableRow, TableBody, TableHead, Table, TableContainer, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'
import useStyles from "./style";
import { scrollCSS } from "../../App";
import MonthYearFilter from './MonthViewFilter';



const Consultationmonthly = (props) => {

  
  const {
    consultationTitle,
    consultationStats
   } = props;
   const currentMonthName = new Date().toLocaleString('default', { month: 'long' });
   const currentYear = new Date().getFullYear();
 
   const [selectedMonth, setSelectedMonth] = useState(currentMonthName);
   const [selectedYear, setSelectedYear] = useState(currentYear);
    const color =  
   consultationTitle === 'Monthly Consultation Stats'
     ? 'black' // Black
     : '#000'; // Default color


     const background =
      consultationTitle === 'Monthly Consultation Stats'
       ? '#FFF7F8' 
       : '#FFF7F8'; // Default color
  

       const classes = useStyles(props);
  
       function createData(newIntroduction, total, completed) {    
         return { newIntroduction, total, completed };  
       }   

       function createData2(exIntroduction, total, completed) {   
        return { exIntroduction, total, completed };
      }

       const rows = [
         createData("Virtual", consultationStats?.new_introduction_virtual_total, consultationStats?.new_introduction_virtual_completed, ),
         createData(    
           "In Person",
           consultationStats?.new_introduction_in_person_total,              
           consultationStats?.new_introduction_in_person_completed,      
         ),        
        
       ];
       const exeRows = [
        createData2("Virtual", consultationStats?.existing_introduction_virtual_total, consultationStats?.existing_introduction_virtual_completed, ),
        createData2(    
          "In Person",       
          consultationStats?.existing_introduction_in_person_total,
          consultationStats?.existing_introduction_in_person_completed,   
        ),
       
      ];
      function getMonthNumber(monthName) {
        const months = {
          January: 1,
          February: 2,
          March: 3,
          April: 4,
          May: 5,
          June: 6,
          July: 7,
          August: 8,
          September: 9,
          October: 10,
          November: 11,
          December: 12,
        }; 
      
        // Return the numeric value for the given monthName
        return months[monthName] || null;
      }
      
      // Usage example:
      // const monthName = "January";
      // const monthNumber = getMonthNumber(monthName);
    
    
       const handleButtonClick = async () => {
        const selectedMonthNumber = getMonthNumber(selectedMonth);
        const response = await props.fetchData(selectedYear,selectedMonthNumber, );
        // Process response or perform further actions
      };
      const handleMonthYearChange = (year, month) => {
        const monthNumber = getMonthNumber(month); // Assuming getMonthNumber is defined somewhere in this component
        props.fetchData(year, monthNumber); // Assuming fetchData is correctly implemented to call your API
      };
      // useEffect(() => {
      //   // Call fetchData when either selectedMonth or selectedYear changes
      //   handleButtonClick()
      // }, [selectedMonth, selectedYear]);

  return (
    <Box sx={{ padding: "40px 10px" }}>

        
    <Box sx={{ position:'relative', padding:'20px 0',  borderRadius: "8px", overflow:'hidden', background: background ,   height:'auto',
      '@media (min-width:1600px)': {padding:'20px 10px', height:'530px'} }}>  
      {/* Table */}
      <Typography variant="h5" sx={{ fontFamily:'helvetica-lt-std-bold', color:color, padding:'20px 20px' }}>
           {consultationTitle}       
        </Typography>     
        <MonthYearFilter
        initialYear={selectedYear}
        initialMonth={selectedMonth}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
        onMonthYearChange={handleMonthYearChange}
      />
      <TableContainer   
        component={Paper}
        sx={{
          mt: 5, 
          boxShadow: "none",
          maxHeight: "650px",
          overflowY: "auto",      
          ...scrollCSS,
        }}
      >
        <Table sx={{}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>  
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                  width:'52%',
                }}   
              >
                New Introduction
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0 }}
              >
                Total
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0 }}
              >
                Completed
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.newIntroduction}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.newIntroduction}  
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>  
                  {row.total}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>      
                  {row.completed}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>


        <Table sx={{}} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow sx={{}}>
              <TableCell
                align="left"
                sx={{
                  fontFamily:'helvetica-lt-std-bold',
                  fontSize:'18px',
                  borderBottom: 0,
                }}
              >
                Existing Introduction
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0 }}
              >
                Total
              </TableCell>
              <TableCell
                align="left"
                sx={{ fontFamily:'helvetica-lt-std-bold',fontSize:'18px', borderBottom: 0 }}
              >
                Completed
              </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {exeRows.map((row) => (
              <TableRow  
                key={row.exIntroduction}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={classes.oddProp}
              >
                <TableCell
                  component="th"  
                  scope="row"
                  className={classes.tableLink}
                >
                  {row.exIntroduction}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.total}
                </TableCell>
                <TableCell align="left" className={classes.tableLink}>
                  {row.completed}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

          
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count=""
        rowsPerPage=""
        page=""
        onPageChange=""
        onRowsPerPageChange=""
      /> */}


    </Box>
    <Box/>
</Box>
  )
}

export default Consultationmonthly