import React, { useEffect, useState } from 'react';
import { convertToRaw, EditorState, ContentState } from "draft-js";
import { Box, TextField } from "@mui/material";
import { scrollCSS } from "../../../../App";
import { inputStyle } from "../../../../components/form/formInput";
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react'; // Import TinyMCE

const MailTemplate = ({ setMailBody, mailBody, selectedValue, setSelectedValue, editorState, setEditorState }) => {
    const [active, setActive] = useState('rich');

    useEffect(() => {
        const contentState = mailBody?.length > 0
            ? ContentState.createFromText(mailBody)
            : ContentState.createFromText('');
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
    }, [mailBody, setEditorState]);

    const handleEditorChange = (content, editor) => {
        // Update the mailBody with content from TinyMCE only if it's different
        // This minimizes unnecessary updates that might cause the cursor issue
        if (content !== mailBody) {
            setMailBody(content);
        }
    };

    const boxStyle = {
        ml: '8px',
        borderTop: '1px solid #EDF2F7',
        borderLeft: '1px solid #EDF2F7',
        borderRight: '1px solid #EDF2F7',
        p: '7px',
        cursor: 'pointer',
    };

    const handleClick = (val) => {
        setActive(val);
    };

    return (
        <Box sx={{ mb: '16px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ ...boxStyle, background: active === 'text' ? "#EDF2F7" : '' }} onClick={() => handleClick("text")}>
                    Text
                </Box>
                <Box sx={{ ...boxStyle, background: active === 'rich' ? "#EDF2F7" : '' }} onClick={() => handleClick("rich")}>
                    Rich Text
                </Box>
                <Box sx={{ ...boxStyle, background: active === 'visual' ? "#EDF2F7" : '' }} onClick={() => handleClick("visual")}>
                    Visual
                </Box>
            </Box>
            <Box sx={{ maxHeight: '400px', ...scrollCSS, overflow: 'auto' }}>
                {active === "rich" && (
                    <TinyMCEEditor
                        apiKey='lv746vs7vxebvnr6ej81yzxijt7n26eej9omoranmkaotq78'
                        value={mailBody} // Changed from initialValue to value for controlled component behavior
                        onEditorChange={handleEditorChange}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help'
                        }}
                    />
                )}
                {active === "visual" && (
                    <Box
                        sx={{
                            backgroundColor: '#EDF2F7',
                            p: "10px 20px",
                            minHeight: '200px'
                        }}
                        className="preview"
                    >
                        <div dangerouslySetInnerHTML={{ __html: mailBody }} />
                    </Box>
                )}
                {active === "text" && (
                    <TextField
                        fullWidth
                        value={mailBody}
                        minRows={5}
                        multiline
                        sx={{ ...inputStyle }}
                        onChange={(e) => setMailBody(e.target.value)}
                        placeholder="Enter Description"
                    />
                )}
            </Box>
        </Box>
    );
};

export default MailTemplate;
