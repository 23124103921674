import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {useNavigate} from "react-router";

const BookingTimer = ({handleReset}) => {
    // Set the timer duration to 10 minutes (in milliseconds)
    const timerDuration = 10 * 60 * 1000;
    const navigate = useNavigate()

    // Initialize the timer state and start time
    const [timer, setTimer] = useState(timerDuration);
    const [startTime, setStartTime] = useState(Date.now());

    // Update the timer value every second
    useEffect(() => {
        const intervalId = setInterval(() => {
            // Calculate the elapsed time
            const elapsedTime = Date.now() - startTime;

            // Calculate the remaining time
            const remainingTime = Math.max(0, timerDuration - elapsedTime);

            // Update the timer state
            setTimer(remainingTime);

            // Stop the timer when the time is up
            if (remainingTime <= 0) {
                clearInterval(intervalId);
                handleReset('reset')
            }
        }, 1000);

        // Clear the interval when the component unmounts or the timer reaches zero
        return () => clearInterval(intervalId);
    }, [startTime, timerDuration]);

    // Convert the timer value to minutes and seconds
    const minutes = Math.floor(timer / 60000);
    const seconds = Math.floor((timer % 60000) / 1000);

    // Render the timer value
    return (
        <Box sx={{color:'#000',fontSize:'16px'}}>
            Appointment held for {minutes}:{seconds.toString().padStart(2, '0')}
        </Box>
    );
}

export default BookingTimer;