import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import './styles.css'
import {addWhiteShade, dayViewTimeSlots, getTimeSlotIndex} from "../../services/utills";
import {Avatar, Typography} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";


export function DayView({ data ,setStaffData }) {
    const [dayView, setDayView] = useState([])
    const [staffCount, setStaffCount] = useState(0)
    const {month_view} = data


    useEffect(() => {
        if (month_view?.length) {
            month_view.forEach((item) => {
                setDayView(item?.staffs)
                let count = 0
                item?.staffs?.length > 0 && item?.staffs.forEach((staff) => {
                    if (staff?.checkout && staff?.checkin && staff?.slots?.length) {
                        count = count + 1
                    }
                })

                setStaffCount(count)
            })
        }
    }, [month_view])




    const getStartIndex = (startTime) => getTimeSlotIndex(startTime,false);

    const getEndIndex = (endTime) => getTimeSlotIndex(endTime,true);

    const renderEmployeeTimeline = (rowData, index) => {

        const {color, checkin, checkout, name} = rowData
        const startTime = checkin
        const endTime = checkout
        const startIndex = getStartIndex(startTime);
        const endIndex = getEndIndex(endTime)
        // const modifiedColor = addWhiteShade(color);
        const defaultColor= "#f2f2f2"
        const modifiedColor = color ? addWhiteShade(color) : defaultColor;

        const style = {
            gridColumn: `${startIndex + 2} / span ${endIndex - startIndex}`,
            backgroundColor: modifiedColor,
            color: color,
            marginTop: "7px",
            marginBottom: "7px",
            padding: "5px",
            borderRadius: "5px",
            height: '90px',
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            gridRow: index + 2,
        };

        return (
            <React.Fragment key={index}>
                <Box style={style}>
                    <Box sx={{pl: '7px'}}>
                        <Box>
                            <Box component={Typography}
                                 sx={{fontWeight: "bold", textTransform: 'capitalize'}}>{name}</Box>
                            <Box component={Typography}>{startTime} - {endTime}</Box>
                        </Box>
                        <Box sx={{
                            backgroundColor: '#fff',
                            width: '70px',
                            borderRadius: '10px',
                            justifyContent: 'center',
                            display: 'flex',
                            padding: '3px 0',
                            mt: '10px',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                             onClick={()=>{setStaffData(rowData)}}
                        >
                            <CreateIcon sx={{fontSize: '11px'}}/>
                            <Box sx={{fontSize: '11px', ml: "7px"}}>edit</Box>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        );
    };

    const renderTimelineHeader = () =>
        dayViewTimeSlots.map((timeSlot, index) => (
            <Box
                className="time-slot"
                sx={{
                    height: '74px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRight: timeSlot?.length === index ? `1px solid #ddd` : ''
                }}
                key={timeSlot}
            >
                <Typography>
                    {timeSlot}
                </Typography>
            </Box>
        ));


    const renderTimelineBody = () => {
        return <>
            {staffCount > 0 && dayView?.length > 0 && dayView.map((item, index) => item?.slots?.length > 0 && (
                <React.Fragment key={index}>
                    {renderEmployeeTimeline(item, index)}
                </React.Fragment>
            ))}
        </>

    };


    return (
            <Box className="timeline">
            <Box className="timeline-header">
                <Box className="time-slot" sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Typography>Staff Name</Typography>
                </Box>
                {renderTimelineHeader()}
            </Box>
            <Box sx={{zIndex: '1111'}} className="timeline-body">{renderTimelineBody()}</Box>
            <Box
                sx={{
                    position: "relative",
                    top: `-${104 * staffCount}px`,
                }}
            >
                {staffCount > 0 && dayView?.length && dayView?.map((item, index) => {
                    return item?.slots?.length > 0 && item?.checkout && item?.checkin && (
                        <Box key={index}>
                            <Box sx={{
                                display: "grid",
                                gridTemplateColumns: "210px repeat(25, 1fr)",
                                "& > div": {
                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                    textAlign: "center",
                                    border: " 1px solid #ddd",
                                    padding: "20px 0 20px 20px",
                                    fontSize: "30px",
                                }
                            }}>
                                <Box sx={{height: '103px', display: 'flex', alignItems: 'center'}}>
                                    <Avatar alt="Remy Sharp" sx={{borderRadius: '7px'}} src={item?.profile_image}/>
                                    <Typography sx={{ml: '5px'}}>{item?.name}</Typography>
                                </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                                <Box className="item"> </Box>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    );
};



