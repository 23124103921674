import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders} from "../../../lib/apiHeader";
import moment from "moment/moment";

export const appointmentCallLogData = createAsyncThunk('appointmentCallLogData', async (type,{getState}) => {
   const {
       appointmentStartTime,
       appointmentEndTime,
       appointmentPageLimit,
       appointmentCurrentPage,

   } = getState()?.callLogStore
    try {
        const payload = {
            type: type || 'Appointment',
            start_date: moment(appointmentStartTime).format('YYYY-MM-DD'),
            end_date: moment(appointmentEndTime).format('YYYY-MM-DD'),
        };
      const response = await axios.post(apis.call_logs, payload, {
        headers: apiHeaders(),
      });

      if (response?.data?.success ==='true'){
          return response.data?.data;
      }else {
          return []
      }
    } catch (error) {
        return []
    }
});

export const appointmentConfirmedCallLogsData = createAsyncThunk('appointmentConfirmedCallLogs', async (data,{getState}) => {
    try {
        const {
            appointmentStartTime,
            appointmentEndTime,
            appointmentPageLimit,
            appointmentCurrentPage
        } = getState()?.callLogStore
        const payload = {
            type: 'Appointment',
            start_date: moment(appointmentStartTime).format('YYYY-MM-DD'),
            end_date: moment(appointmentEndTime).format('YYYY-MM-DD'),
        };
        const response = await axios.post(apis.Conirmed_Call_Log, payload, {
        headers: apiHeaders(),
      });
       return response.data?.data;
    } catch (error) {
        return []
    }
});


export const treatmentCallLogData = createAsyncThunk('treatmentCallLogData', async (data,{getState}) => {
    try {
        const {
            treatmentStartTime,
            treatmentEndTime,
            treatmentPageLimit,
            treatmentCurrentPage
        } = getState()?.callLogStore
        const payload = {
            type: 'Treatment',
            start_date: moment(treatmentStartTime).format('YYYY-MM-DD'),
            end_date: moment(treatmentEndTime).format('YYYY-MM-DD'),
        };
        const response = await axios.post(apis.call_logs, payload, {
            headers: apiHeaders(),
        });

        if (response?.data?.success ==='true'){
            return response.data?.data;
        }else {
            return []
        }
    } catch (error) {
        return []
    }
});

export const getTreatmentManagerCallLogsData = createAsyncThunk('getTreatmentManagerCallLogsData', async (data,{getState}) => {
    try {
        const {
            treatmentStartTime,
            treatmentEndTime,
            appointmentPageLimit,
            appointmentCurrentPage
        } = getState()?.callLogStore
        const payload = {
            type: 'Treatment',
            start_date: moment(treatmentStartTime).format('YYYY-MM-DD'),
            end_date: moment(treatmentEndTime).format('YYYY-MM-DD'),
        };
        const response = await axios.post(apis.Conirmed_Call_Log, payload, {
        headers: apiHeaders(),
      });
       return response.data?.data;
    } catch (error) {
        return []
    }
});

export const changeAppointmentConfirmStatus = createAsyncThunk('changeAppointmentConfirmStatus', async (payload) => {
    try {
      const response = await axios.post(apis.set_confirm_call_logs, payload, {
        headers: apiHeaders(),
      });

       return response.data;
    } catch (error) {
        return error?.response
    }
});
// 
export const NotificationAlert = createAsyncThunk("NotificationAlert", async (data, { getState }) => {

    try {
            const response = await axios.get(apis.notification_alert, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }else {

                return [];
            }
        } catch (err) {

            return  []
        }
    }
);

export const storeCallLogsNotes = createAsyncThunk('storeCallLogsNotes', async (data,{dispatch}) => {
    try {
        const response = await axios.post(apis.store_notes, {...data},{
            headers: apiHeaders()
        })

        return  response?.data

    }catch (err){
        return err?.response?.data?.errors

    }
})

export const TreatmentSatisfied = createAsyncThunk('TreatmentSatisfied', async (payload) => {
    try {
      const response = await axios.post(apis.Set_Satisfied, payload, {
        headers: apiHeaders(),
      });

       return response.data;
    } catch (error) {
        return error?.response
    }
});
export const updateCallLogFilters = createAsyncThunk('updateCallLogFilters', async (data) => {
    return data
})
  export const crmCallLogSlice = createSlice({
    name: 'crmCallLogSlice',
    initialState: {
        callLog: [],
        confirmedcallLog:[],
        SetSatisfied:[],
        Notification:[],
        appointmentCallLogs : [],
        appointmentConfirmedCallLogs : [],
        appointmentPageLimit:10,
        appointmentCurrentPage:1,
        appointmentSearch:'',
        appointmentStartTime:moment().add(1, 'day').format('YYYY-MM-DD'),
        appointmentEndTime:moment().add(1, 'day').format('YYYY-MM-DD'),

        treatmentCallLogs : [],
        treatmentManagerCallLogs : [],
        treatmentPageLimit:10,
        treatmentCurrentPage:1,
        treatmentSearch:'',
        treatmentStartTime:moment().subtract(1, 'day').format('YYYY-MM-DD'),
        treatmentEndTime:moment().subtract(1, 'day').format('YYYY-MM-DD'),
       
    },
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(appointmentCallLogData.fulfilled, (state, action) => {
            state.appointmentCallLogs = action.payload || [];
        });
        builder.addCase(appointmentConfirmedCallLogsData.fulfilled, (state, action) => {
            state.appointmentConfirmedCallLogs = action.payload || [];
        });
        // 
        builder.addCase(NotificationAlert.fulfilled, (state, action) => {
            state.Notification = action.payload || [];
        });
        builder.addCase(treatmentCallLogData.fulfilled, (state, action) => {
            state.treatmentCallLogs = action.payload || [];
        });
        builder.addCase(getTreatmentManagerCallLogsData.fulfilled, (state, action) => {
            state.treatmentManagerCallLogs = action.payload;
        });
        builder.addCase(updateCallLogFilters.fulfilled, (state, action) => {
            const {filterBy, pageLimit, search, currentPage, type,startTime,endTime} = action?.payload
            if (filterBy) {
                if (type === 'appointment') {
                    state.appointmentFilterBy = filterBy;
                } else {
                    state.treatmentFilterBy = filterBy;
                }
            }
            if (pageLimit) {
                if (type === 'appointment') {
                    state.appointmentPageLimit = pageLimit;
                } else {
                    state.treatmentpageLimit = pageLimit;
                }
            }
            if (search) {
                if (type === 'appointment') {
                    state.appointmentSearch = search === 'reset' ? '' : search;
                } else {
                    state.treatmentSearch = search === 'reset' ? '' : search;
                }
            }
            if (currentPage) {
                if (type === 'appointment') {
                    state.appointmentCurrentPage = currentPage;
                } else {
                    state.treatmentCurrentPage = currentPage;
                }
            }
            if (startTime) {
                if (type === 'appointment') {
                    state.appointmentStartTime = startTime;
                } else {
                    state.treatmentStartTime = startTime;
                }
            }
            if (endTime) {
                if (type === 'appointment') {
                    state.appointmentEndTime = endTime;
                } else {
                    state.treatmentEndTime = endTime;
                }
            }
        });
    },
})
export default crmCallLogSlice.reducer   