import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {Box, Grid,  useMediaQuery} from "@mui/material";
import CustomButton from "../../../../components/custom-button/customButton";
import {useDispatch,  } from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";
import ConfigurationLayout from "../../configurationLayout";
import Loader from "../../../../components/loader/loader";
import FormInput from "../../../../components/form/formInput";
import SubmitButton from "../../../../components/custom-button/submitButton";
import {useTheme} from "@mui/material/styles"
import {getMenuByID, storeMenu, updateMenu} from "../../../../store/menus";
import FormStatusInput from "../../../../components/form/FormStatusInput";
import { getManualByID, storeManual, updatemanual } from '../../../../store/clinics/clinicSettings/usermanual';

const AddManual = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const theme = useTheme();
    const hiddenLG = useMediaQuery(theme.breakpoints.down('lg'));

    const validationSchema = yup.object({
        title: yup.string().min(3, "Title is Too Short").required('Title is required'),
        order: yup.number().required('Order is required').positive().integer(),
        videoUrl: yup.string().url('Invalid URL format').required('Video URL is required'),
    });

    const { values, handleSubmit, setFieldValue, handleChange, touched, errors } = useFormik({
        initialValues: {
            title: '',
            order: '',
            video_url: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, { setFieldTouched, setFieldError }) => {
            setLoading(true);
            console.log("coming", data);
            const formData = {
                title: data.title,
                order: data.order,
                video_url:data.videoUrl,
                id:params?.manual_id
            };
            console.log("data", formData);
  
            let res;
            if (params?.manual_id) {
                res = await dispatch(updatemanual(formData, params?.manual_id));
            } else {
                res = await dispatch(storeManual(formData));
            }

            if (res?.payload?.errors) {  
                const { errors } = res?.payload;
                if (errors?.title) {
                    setFieldTouched("title", true); 
                    setFieldError("title", errors?.title);
                }
            } else if (res?.payload?.success === "true") {
                toast.success(`Manual ${params?.manual_id ? "updated" : "stored"} successfully`, { duration: 3000 });
                navigate('/admin/configuration/usermanual');
            }

            setLoading(false);
        },
    });

    useEffect(() => {
        (async () => {
            if (params?.manual_id) {
                setLoading(true);
                let res = await dispatch(getManualByID(params?.manual_id));
                if (res?.payload?.data?.id) {
                    await setFieldValue("title", res?.payload?.data?.title);
                    await setFieldValue("order", res?.payload?.data?.order);
                    await setFieldValue("videoUrl", res?.payload?.data?.video_url); // Assuming this is stored in API
                    setLoading(false);
                }
            }
        })();
    }, [params?.manual_id]);

    return (
        <ConfigurationLayout>
            <Box sx={{ height: "calc(100vh - 125px)", backgroundColor: "white" }}>
                {loading ? <Loader /> :
                    <form onSubmit={handleSubmit}>
                        <Grid container p={3} sx={{ justifyContent: 'space-between', width: hiddenLG ? "100%" : '70%' }}>
                            <Grid item xs={12} md={5}>
                                <FormInput
                                    name={"title"}
                                    label={"Title"}
                                    placeholder={'Type Title'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <FormInput
                                    name={"order"}
                                    label={"Order"}
                                    placeholder={'Type Order'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <FormInput
                                    name={"videoUrl"}
                                    label={"Video URL"}
                                    placeholder={'Type Video URL'}
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <Box sx={{ mr: '16px' }}>
                                    <CustomButton
                                        mainColor={'#ED1E45'}
                                        val={'Cancel'}
                                        handleClick={() => navigate('/admin/configuration/usermanual')}
                                    />
                                </Box>
                                <SubmitButton
                                    val={params?.manual_id ? "Update Manual" : "Add Manual"}
                                />
                            </Grid>
                        </Grid>
                    </form>
                }
            </Box>
        </ConfigurationLayout>
    );
};

export default AddManual;