import React, {useEffect, useState} from 'react';
import {
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSelector} from "react-redux";
import moment from "moment";
import {generateYears, generateYearsToday, monthsArray} from "../../services/utills";
import {MenuProps} from "../crm/calender/crmCalender";
  
export const boxStyle ={
    height:'40px',   
    width:'40px',
    border:"1px solid #E6DAD1",
    display:'flex',
    alignItems:"center",
    borderRadius:'4px',
    justifyContent:'center',
    color:'#000',
    cursor:'pointer',
    
      
    "&:hover": {
        background:"#E6DAD1",
        color:'#fff'
    }
    
}
export const disableStyle ={
    opacity: 0.5,
    color:'#D5D9DC',

    border:"1px solid #D5D9DC",
    "&:hover": {
        cursor: 'not-allowed'
    }
}
export const dateStyle ={
    p:"10px 0",
    fontSize:'14px',
    fontWeight:'bold',
    height: '40px',
    width: '40px',
    mx: 'auto',
    borderRadius:'50%',
    '@media (max-width: 300px) ': {
        height:'30px !important',   
        width:'30px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        fontSize:'12px',
     },
} 

const BookingCalender = ({handleSelectDate,getMonthHolidays,selectedStaff,handleDateReset}) => {
    const [selectedDate, setSelectedDate] = useState(0)
    let currentData = new Date()

    const [startDate, setStartDate] = useState(currentData)
    const isCurrentMonth = (currentData.getMonth() === startDate.getMonth()) && (currentData.getFullYear() === startDate.getFullYear())
    const monthStart = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const monthEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    const daysInMonth = monthEnd.getDate();
    const firstDayOfWeek = monthStart.getDay();
    const lastDayOfWeek = monthEnd.getDay();

    const bookingHolidays = useSelector(state => state?.bookingWidgetStore?.holidays)

    const weeks = [];
    let days = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
        days.push(
            <TableCell key={`empty-${i}`}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }

    for (let i = 1; i <= daysInMonth; i++) {
        const isExist = bookingHolidays?.length > 0 && bookingHolidays.some((item) => parseFloat(item) === parseFloat(i))
        // Check if this formatted date is in the list of holidays
        const formattedDate = moment(new Date(startDate.getFullYear(), startDate.getMonth(), i)).format('YYYY-MM-DD');
        const isHoliday = bookingHolidays?.length > 0 && bookingHolidays?.includes(formattedDate);
        const isPast = isCurrentMonth && i < currentData.getDate();
        const isDisabledDate = isPast || isHoliday;
        days.push(
            <TableCell key={i}>
                <Box
                    sx={{
                        ...dateStyle,
                        background: ((isCurrentMonth && i < currentData.getDate()) || isExist || (i !== selectedDate)) ? "#fff" : "#E6DAD1",
                        cursor: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? 'default' : 'pointer',
                        border: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? "#fff" : '1px solid #E6DAD1',
                        color: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? "#ADB1B5" : (i !== selectedDate) ? '#000' : '#000',
                        pointerEvents: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? "none" : 'auto',
                        "&:hover": {
                            background: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? "#fff" : "#E6DAD1",
                            color: ((isCurrentMonth && i < currentData.getDate()) || isExist || isDisabledDate) ? "#ADB1B5" : '#000'
                        }
                    }}
                    onClick={() => {
                        setSelectedDate(i)
                        const date = moment(new Date(startDate.getFullYear(), startDate.getMonth(), i)).format('YYYY-MM-DD')
                        handleSelectDate(date)
                    }}
                >
                    {i}
                </Box>
            </TableCell>
        );

        if ((i + firstDayOfWeek) % 7 === 0 || i === daysInMonth) {
            weeks.push(<TableRow key={`week-${i}`}>{days}</TableRow>);
            days = [];
        }
    }
    for (let i = (lastDayOfWeek + 1) % 7; i < 6; i++) {
        days.push(
            <TableCell key={`empty-${i}`}>
                <Box sx={{width: '50px'}}>{''}</Box>
            </TableCell>
        );
    }

    const isDisabled = (month) => {
        const today = new Date();
        const year = today.getFullYear();
        const currentMonth = today.getMonth();
        const currentYear = startDate.getFullYear();
        if (year === currentYear){
            return month < currentMonth;
        }else {
            return false
        }
    }
    return (
        <Box>
            <Box
                sx={{
                    borderTop: '1px solid #f4ede3',
                    borderLeft: '1px solid #f4ede3',
                    borderTopRightRadius: '4px',
                    borderTopLeftRadius: '4px',
                    borderRight: '1px solid #f4ede3',
                    display: 'flex',
                    p: '12px',
                    justifyContent: 'space-between',
                    flexWrap:'wrap'
                }}>

                <Box sx={isCurrentMonth ? {...boxStyle, ...disableStyle} : {...boxStyle}} onClick={() => {
                    if (!isCurrentMonth) {
                        handleDateReset()
                        setSelectedDate(0)
                        const end = new Date(startDate);
                        end.setMonth(end.getMonth() - 1);
                        setStartDate(end);
                        getMonthHolidays(end, selectedStaff)    
                    }
                }}>
                    <KeyboardArrowLeftIcon/>                                                             
                </Box>
                <Box sx={{display: 'flex',gap:'10px','@media (max-width:300px)':{margin:'6px 0'}}}>
                    <FormControl sx={{minWidth: 90}}>
                        <Select
                            size={"small"}
                            value={startDate?.getMonth()}
                            MenuProps={MenuProps}
                            onChange={(e) => {
                                const end = new Date(startDate);
                                end.setMonth(e?.target?.value);
                                setStartDate(end);
                                getMonthHolidays(end, selectedStaff)
                            }}
                            sx={{
                                background: '#E6DAD1',
                                color: '#000',
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "0!important"
                                },
                                "svg": {
                                    color: '#000',
                                    fontSize: '34px',
                                }
                            }}
                        >
                            {monthsArray?.length > 0 && monthsArray?.map((item, index) => (
                                <MenuItem disabled={isDisabled(item?.key)} value={item?.key} key={index}>{item?.name}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{minWidth: 90}}>
                        <Select
                            size={"small"}
                            value={startDate?.getFullYear()}
                            MenuProps={MenuProps}
                            onChange={(e) => {
                                const end = new Date(startDate);
                                end.setFullYear(e?.target?.value);
                                setStartDate(end);
                                getMonthHolidays(end, selectedStaff)
                            }}
                            sx={{
                                background: '#E6DAD1',
                                color: '#000',
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "0!important"
                                },
                                "svg": {
                                    color: '#000',
                                    fontSize: '34px',
                                }
                            }}
                        >
                            {generateYearsToday(10)?.length > 0 && generateYearsToday(10)?.map((item, index) => (
                                <MenuItem value={item} key={index}>{item}</MenuItem>
                            ))
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={boxStyle} onClick={() => {
                    handleDateReset()
                    setSelectedDate(0)

                    const end = new Date(startDate);
                    end.setMonth(end.getMonth() + 1);
                    setStartDate(end);
                    getMonthHolidays(end, selectedStaff)
                }}>
                    <KeyboardArrowRightIcon/>
                </Box>
            </Box>
            <Box sx={{
                border: '1px solid #D5D9DC',
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                 pb:5,
            }}>
                <Table style={{ tableLayout:'fixed' }} aria-label="simple table" sx={{
                    boxShadow: 'none', "td , th": {
                        border: 'none',
                        textAlign: 'center',
                    },
                    "th": {
                        fontWeight: 'bold'
                    },
                    "td": {
                        // py: "4px",
                        padding:"5px 0 !important",
                        margin:'0 !important'
                    }
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>S</TableCell>
                            <TableCell>M</TableCell>
                            <TableCell>T</TableCell>
                            <TableCell>W</TableCell>
                            <TableCell>T</TableCell>
                            <TableCell>F</TableCell>
                            <TableCell>S</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {weeks}
                        <TableRow sx={{
                            "td": {
                                py: "20px!important"
                            }
                        }}>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </Box>
    );
};

export default BookingCalender;