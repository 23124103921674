import React from 'react';
import {FormControl, FormHelperText, FormLabel, Slider} from "@mui/material";

const FormSlider = ({label,name,values,handleChange,touched,errors}) => {
    return (
        <FormControl fullWidth sx={{mb:"16px"}}>
            <FormLabel sx={{fontSize:"16px",color:'#27272e',letterSpacing:'.3px'}}>{label}</FormLabel>

            <Slider
                name={name}
                sx={{
                    "&.MuiSlider-root":{
                        height:"9px"
                    }
                }}
                value={values[name]}
                aria-label="Default"
                onChange={handleChange}
                valueLabelDisplay="auto"
            />
            <span>{values[name]} Miles</span>
            {errors[name] && touched[name] && <FormHelperText sx={{color: '#CB3838',width:'max-content',mx:0}}>{errors[name]}</FormHelperText>}

        </FormControl>

    );
};

export default FormSlider;