import React, { useState } from "react";
import PublicLayout from "../../layout/PublicLayout";
import "./styles.scss";
import PublicHeader from "../../components/public-header";
import PublicFooter from "../../components/public-footer";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import * as Yup from "yup";
import FormButton from "../../components/form/formButton";
import FormInput from "../../components/form/formInput";
import FormPasswordInput from "../../components/form/formPasswordInput";
import { loginUser } from "../../store/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import Loader from "../../components/loader/loader";
import {
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { successConfirmation } from "../../services/utills";
import Waveloading from "../loading/Waveloading";

import homeoutline from '../../assets/images/homeoutline.png';
import booking from "../../assets/images/Vector (Stroke).png";
import menu from '../../assets/images/homeIcon.png'


import plan from "../../assets/images/wallet2.png";
import profile from "../../assets/images/user.png";

const Login = ({ loginOpen, setOpenLogin, showFooter }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
    social_login: 0,
  };
  let lastVisitedUrl = JSON.parse(localStorage.getItem('recentUrls')) || [];
  console.log("Last visited Url");
  console.log(lastVisitedUrl[0]);
  const extractSubdomain = () => {
    const hostname = window.location.hostname;
    const hostnameParts = hostname.split('.');
    return hostnameParts.length > 2 ? hostnameParts[0] : null;
  };
  const subdomain = extractSubdomain();
  const handleHome = () => {
    navigate("/home");
  };  


  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),  
    password: Yup.string()
      .min(8, "Must be at least 8 characters")
      .required("Required"),
  });
  const user = useSelector((state) => state?.authStore?.user);
  console.log("user data", user)

  const onSubmit = async (data, { setSubmitting }) => {
    setLoading(true);
    let res = await dispatch(loginUser(data));
    setLoading(false);

    if (res?.payload?.success && res?.payload?.data?.id) {   
      localStorage.setItem("token", res?.payload?.data?.token);
        localStorage.setItem("timeZone", res?.payload?.data?.time_zone);
        let timeZone = localStorage.getItem("timeZone");
      console.log("res data", res?.payload?.data)
      if (loginOpen) {
        await successConfirmation("Success", "Logged Successfully");   
        setOpenLogin(false);
      } else {
        console.log("app working", subdomain);
        const mobileBreakpoint = 768;
        const screenWidth = window.innerWidth;

        if (subdomain === "app") {
          console.log("app works");
          const currentUrl = window.location.href;
          const encodedUrl = encodeURIComponent(currentUrl);
          localStorage.setItem("lastVisitedUrl", encodedUrl);
          console.log("after login url",encodedUrl)
          if (res?.payload?.data?.role_id === 3 && lastVisitedUrl[0] !== null) {
            const relativePath = lastVisitedUrl[0].replace(window.location.origin, "");
            if (relativePath.includes("/login")) {
              console.log("Relative have login")
              console.log(relativePath)

              navigate("/user/my-account");
            } else {  
              console.log("Relative have not login")
              console.log(relativePath)                      
              navigate(relativePath)
            }
          }else{
            if (screenWidth <= mobileBreakpoint) {
              navigate("/home");    
            } else {
              const fullUrl = `${res?.payload?.data.explore_services_url}/services/#services`;
              window.location.href = fullUrl;
            }
          }
        } else {
          const currentUrl = window.location.href;
          const encodedUrl = encodeURIComponent(currentUrl);
          localStorage.setItem("lastVisitedUrl", encodedUrl);
          console.log("after login url",encodedUrl)
          if (res?.payload?.data?.role_id === 3 && lastVisitedUrl[0] !== null) {
            const relativePath = lastVisitedUrl[0].replace(window.location.origin, "");
            if (relativePath.includes("/login")) {
              console.log("Relative have login")
              console.log(relativePath)

              navigate("/user/my-account");
            } else {  
              console.log("Relative have not login")
              console.log(relativePath)                      
              navigate(relativePath)
            }
          } else {
            navigate("/admin/configuration/roles")
          }   
        }

        toast.success("Logged Successfully", { duration: 3000 });
      }     
    } else if (res?.payload?.data?.success === false) { 
      toast.error(res?.payload?.data?.status, { duration: 3000 });
      if (res?.payload?.data?.data?.is_email_verified === false) {
        navigate("/verification");
      }
    }
};




  return (
    <PublicLayout>
      {loading ? (
        <Waveloading />
      ) : (
        <div className={"login-card"}>
          <PublicHeader
            setOpenLogin={setOpenLogin}
            heroText={"Welcome back!"}
            heroSubText={
              "Sign In if you already have an account at 3D lifestyle."
            }
            to={"/register"}
          />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={true}
          >
            {({
              values,
              isSubmitting,
              handleSubmit,
              errors,
              handleChange,
              touched,
            }) => {
              return (
                <>
                  <form onSubmit={handleSubmit}>
                    <FormInput
                      name={"email"}
                      label={"Email"}
                      placeholder={"Type your e-mail"}
                      errors={errors}
                      touched={touched}
                      isAuto={true}
                      values={values}
                      handleChange={handleChange}
                    />
                    <FormPasswordInput
                      name={"password"}
                      isAuto={true}
                      label={"Password"}
                      placeholder={"Type your password"}
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                    />
                    <Box
                      className={"forget-box"}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox checked={true} />}
                          label="Remember Me"
                          style={{ color: '#000' }}
                        />
                      </FormGroup>
                      <Link
                        to={"/forget-password"}
                        style={{ fontFamily: "helvetica-lt-std-roman" }}
                      >
                        Forget Password?
                      </Link>
                    </Box>
                    <FormButton
                      disable={loading || isSubmitting}
                      text={loading ? "Signing In..." : "Sign In "}
                    />
                  </form>
                </>

              );
            }}
          </Formik>
          {subdomain == "app" &&
            <PublicFooter
              heading={"Don't have an account?"}
              toText={"Register"}
              to={"/register"}
            />}
          {showFooter && (
            <Box
              sx={{
                display: { sm: "none", xs: "block" },
                marginLeft: "calc(-50vw + 50%)",
                marginRight: "calc(-50vw + 50%)",
              }}
            >
              {subdomain == "app" &&
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "0",
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    zIndex: "999",
                    // boxShadow: "0px -18.000001907348633px 37px 0px rgba(0, 0, 0, 0.5)",
                    borderRadius: "11px",

                    width: "100%",
                    p: { xs: "10px 15px 20px 15px", sm: "10px 30px 30px 30px" },
                    border: "1px solid lightgray",
                    "@media (max-width:340px)": {
                      p: "10px 5px 10px 5px",
                    },
                  }}
                  className="footer-wrappper"
                >
                  <Box
                    sx={{
                      backgroundColor: "rgba(239, 230, 224, 1)",
                      borderRadius: "7px",
                      p: "8px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      mr: { xs: "8px", sm: "0px" },
                      "@media (max-width:340px)": {
                        // marginLeft: "6px",
                        p: "5px",
                      },
                    }}
                  >
                    <CardMedia
                      sx={{ width: { xs: "20px", sm: "30px" } }}
                      component="img"
                      alt="Descriptive alt text"
                      image={homeoutline}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "300",
                        fontSize: { xs: "12px", sm: "18px" },
                        mt: "10px",
                        color: "rgba(30, 30, 30, 1)",
                      }}
                    >
                      Home
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "7px",
                      p: "8px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      mt: "5px",
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: { xs: "18px", sm: "26px" },
                        "&:hover": {
                          filter:
                            "invert(32%) sepia(69%) saturate(6027%) hue-rotate(355deg) brightness(97%) contrast(83%)",
                        },
                      }}
                      component="img"
                      alt="Descriptive alt text"
                      image={booking}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "300",
                        fontSize: { xs: "12px", sm: "18px" },
                        mt: "10px",
                        color: "rgba(30, 30, 30, 1)",
                      }}
                    >
                      Bookings
                    </Typography>
                  </Box>
                  <Box
                    onClick={handleHome}
                    sx={{
                      borderRadius: "7px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: { xs: "80%", sm: "100%" },
                        mt: { xs: "-40px", sm: "-40px" },
                        "@media (max-width:319px)": {
                          width: "80%",
                          position: "relative",
                          right: "5px",
                        },
                      }}
                      component="img"
                      alt="Descriptive alt text"
                      image={menu}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "7px",
                      p: "8px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CardMedia
                      sx={{ width: { xs: "20px", sm: "30px" } }}
                      component="img"
                      alt="Descriptive alt text"
                      image={plan}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "300",
                        fontSize: { xs: "12px", sm: "18px" },
                        mt: "10px",
                        color: "rgba(30, 30, 30, 1)",
                      }}
                    >
                      Member
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "7px",
                      p: "8px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      // ml: { xs: "5px", sm: "0px" },
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: {
                          xs: "20px", sm: "30px", '@media (max-width:340px)': {
                            p: "5px",
                            mr: '6px'
                          }
                        }
                      }}
                      component="img"
                      alt="Descriptive alt text"
                      image={profile}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "300",
                        fontSize: { xs: "12px", sm: "18px" },
                        mt: "10px",
                        color: "rgba(30, 30, 30, 1)",

                      }}
                    >
                      Profile
                    </Typography>
                  </Box>
                </Box>}
            </Box>
          )}
        </div>
      )}
    </PublicLayout>
  );
};

export default Login;
