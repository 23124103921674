import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import {apiHeaders, apiHeadersImage} from "../../../lib/apiHeader";


export const getAllCRMCustomer = createAsyncThunk("getAllCRMCustomer", async (data, { getState }) => {
    const {search,pageLimit,order_by,currentPage,customer_type} = getState()?.crmStore

   console.log("search",search)
let baseUrl = `${apis.get_all_customers}`;

// Initialize the parameters array
let params = [];

// Conditionally add customer_type if it has a value
if (customer_type) {
    params.push(`customer_type=${encodeURIComponent(customer_type)}`);
}

// Append the other parameters   
params.push(`order_by=${encodeURIComponent(order_by)}`);
params.push(`limit=${encodeURIComponent(pageLimit)}`);
params.push(`page=${encodeURIComponent(currentPage)}`);

// Join all parameters with '&' and append to baseUrl
baseUrl += "?" + params.join("&");

// Now you can use baseUrl for your request

    
    // Handle search parameter
    if (search) {
        baseUrl += `&search=${encodeURIComponent(search)}`;
    }
    

    try {
            // const response = await axios.get(apis.get_all_customers+`${filterBy}&limit=${pageLimit}&page=${currentPage}${search!=='' ? `&search=${search}`:'' }`, {
            //     headers: apiHeaders(),
            // });


            
    
    const response = await axios.get(baseUrl, {
        headers: apiHeaders(),
        // return response?.data?.data;
    });
    return response?.data?.data
    console.log(response.data); 

            // if (response?.data?.success === "true") {
            //     return response?.data?.data;
            // }
            // else {

            //     return {};
            // }  
        } catch (err) {

            return  {}
        }
    }
);



    // Handle customerType, check if it exists and append accordingly
    // if (customer_type) {
    //     if (Array.isArray(customer_type)) {
    //         // If customerType is an array, append each value
    //         customer_type.forEach((type, index) => {
    //             baseUrl += `?customer_type[${index}]=${encodeURIComponent(type)}`;
    //         });
    //     } else {
    //         // If customerType is a single value, append it directly
    //         baseUrl += `?customer_type=${encodeURIComponent(customer_type)}`;
    //     }
    // }
    
    // Append order_by, limit, and page parameters
   // Assuming apis.get_all_customers is defined somewhere in your code
export const getAllCRMSubscription = createAsyncThunk("getAllCRMSubscription", async (data, { getState }) => {

    try {
            const response = await axios.get(apis.crm_subscription_plans, {
                headers: apiHeaders(),
            });

            if (response?.data?.success === "true") {
                return response?.data?.data;
            }else {

                return [];
            }
        } catch (err) {

            return  []
        }
    }
);

export const createCrmCustomerSubscription = createAsyncThunk("createCrmCustomerSubscription", async (data) => {

        try {
            const response = await axios.post(apis.create_crm_subscription,{...data}, {
                headers: apiHeaders(),
            });

            return response?.data;

        } catch (err) {

            return  err?.response
        }
    }
);
export const updateCrmCustomerSubscription = createAsyncThunk("updateCrmCustomerSubscription", async (data) => {

        try {
            const response = await axios.post(apis.update_crm_subscription,{...data}, {
                headers: apiHeaders(),
            });


            return response?.data;

        } catch (err) {

            return  err?.response
        }
    }
);

export const getUserActiveSubscription = createAsyncThunk("getUserActiveSubscription", async (id, { dispatch }) => {
        try {
            const response = await axios.get(apis.active_subscription+id, {
                headers: apiHeaders(),
            });
            return response?.data
        } catch (err) {
            return  err?.response
        }
    }
);

export const getCRMCustomerDetails = createAsyncThunk("getCRMCustomerDetails", async (id) => {
    try {
        const response = await axios.get(apis.get_customer_by_id + id, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return response?.data?.data;
        } else {

            return [];
        }
    } catch (err) {

        return []
    }
});

export const getCRMCustomerBookingsDetails = createAsyncThunk("getCRMCustomerBookingsDetails", async (data) => {
    try {
        const response = await axios.get(apis.get_customer_booking + data?.id+`?type=${data?.type}`, {
            headers: apiHeaders(),
        });

        if (response?.data?.success === "true") {
            return {
                type:data?.type,
                data:response?.data?.data
            }
        } else {

            return [];
        }
    } catch (err) {

        return []
    }
});



export const storeCrmCustomer = createAsyncThunk("storeCrmCustomer", async (data) => {

    try {
            const response = await axios.post(apis.add_crm_customers,{...data}, {
                headers: apiHeaders(),
            });

            return response?.data;

        } catch (err) {

            return  err?.response
        }
    }
);

export const updateCrmCustomer = createAsyncThunk("updateCrmCustomer", async (data,{dispatch}) => {

    try {
            const response = await axios.post(apis.update_customer,data?.data, {
                headers: apiHeadersImage(),
            });

            if (response?.data?.success === 'true'){
                await dispatch(getCRMCustomerDetails(data?.id))
            }
            return response?.data;
        } catch (err) {

            return  err?.response
        }
    }
);

// Buy_Wallet

export const BuyWalletPoint = createAsyncThunk('BuyWalletPoint', async (payload) => {
    try {
      const response = await axios.post(apis.Buy_Wallet, payload, {
        headers: apiHeaders(),
      });
      if (response.data.success === 'true') {
        return response.data;
      } else {
        return {};
       
      }
    } catch (error) {
        return {};
    }
  });
export const setEmail = createAsyncThunk('setEmail', async (payload) => {
    try {
      const response = await axios.post(apis.set_email, payload, {
        headers: apiHeaders(),
      });
  
      if (response.data.success === 'true') {
        return response.data.data;
      } else {
        return {};
       
      }
    } catch (error) {
        return {};
    }
  });
//  


export const setSms = createAsyncThunk('setSms', async (payload) => {
    try {
      const response = await axios.post(apis.set_sms, payload, {
        headers: apiHeaders(),
      });
  
      if (response.data.success === 'true') {
        return response.data.data;
      } else {
        return {};
       
      }
    } catch (error) {
        return {};     
    }
  });

// 
export const RemoveAccount = createAsyncThunk('setEmail', async (payload) => {
    try {
      const response = await axios.post(apis.Account_Removal, payload, {
        headers: apiHeaders(),
      });
  
      if (response.data.success === 'true') {
        return response.data.data;
      } else {
        return {};
       
      }
    } catch (error) {
        return {};
    }
  });

  export const setWallet = createAsyncThunk('setWallet', async (payload) => {
    try {
      const response = await axios.post(apis.set_wallet, payload, {
        headers: apiHeaders(),
      });
  
       return response.data;
    } catch (error) {
        return error?.response
    }   
  });





export const updateCrmFilters = createAsyncThunk('updateCrmFilters', async (data) => {
    return data
})

export const crmSlice = createSlice({
    name: "crmSlice",
    initialState: {
        crmCustomerDetails:{},
        allCrmCustomer: {},
        crmSubscriptions: [],
        invoices:[],
        appointmentsHistory:[],
        treatments:[],
        walletHistory:{},
        search:'',
        pageLimit:10,
        order_by:'All',
        customer_type:"AllCustomer",
        currentPage:1,
        Remove:[],
        BuyWallet:{}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAllCRMCustomer.fulfilled, (state, action) => {
            state.allCrmCustomer = action.payload ;
        });
        builder.addCase(getAllCRMSubscription.fulfilled, (state, action) => {
            state.crmSubscriptions = action.payload ;
        });
        builder.addCase(getCRMCustomerDetails.fulfilled, (state, action) => {
            state.crmCustomerDetails = action.payload ;
        });
        
        builder.addCase(RemoveAccount.fulfilled, (state, action) => {
            state.Remove= action.payload ;
        });
        builder.addCase(getCRMCustomerBookingsDetails.fulfilled, (state, action) => {
            const {type,data} = action?.payload
console.log("type,data",type,data)
            if (type ==='Invoices'){
                state.invoices = data?.Invoices ;
            }
            if (type ==='Appointments'){
                state.appointmentsHistory = data?.appointments ;
            }
            if (type ==='Treatments'){
                state.treatments = data?.treatments ;
            }
            if (type ==='WalletHistory'){
                state.walletHistory = data?.WalletHistory;
            }
        });
        builder.addCase(updateCrmFilters.fulfilled, (state, action) => {
            const {order_by,pageLimit,search,currentPage,customer_type} = action?.payload
            if (order_by){
                state.order_by = order_by ;
            }
            if(customer_type)
            {
                state.customer_type= customer_type;
            }
            if (pageLimit){
                state.pageLimit = pageLimit ;
            }
            if (search){
                state.search = search === 'reset' ? '' : search ;
            }
            if (currentPage){
                state.currentPage = currentPage ;
            }
        });
    },
});

export default crmSlice.reducer;