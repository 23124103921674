export const whiteStyle = {
  backgroundColor: "#fff",
  width: "200px",
  height: "62px",
  border: "1px solid #666666",
  borderRadius: "10px",
  fontSize: "20px",
  color: "#666666",
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #666666",
  },
};

export const bookingButton = {
  backgroundColor: "#fff",
  border: "1px solid #4F4F4F",
  borderRadius: "4px",
  color: "#4F4F4F",
  width: "130px",
  height: "40px",
  fontSize: "14px",
  fontFamily: "helvetica-lt-std-roman",
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #4F4F4F",
  },
};
export const statusStyles = {
  // backgroundColor: "rgba(242, 153, 74, 0.15)",
  borderRadius: "17px",
  textAlign: "center",
  padding: "4px 8px",
  // color: "#F2994A",
};
