// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-card {
  background-color: #FFFFFF;
  max-width: 650px;
  width: 100%;
  padding: 70px;
  border-radius: 12px;
}
.login-card .forget-box {
  display: flex;
  margin: 16px 0;
}
.login-card .forget-box a {
  color: #375997;
  font-size: 16px;
  text-decoration: none;
}
.login-card .forget-box a:hover {
  color: #108DA3;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,cAAA;AACJ;AAAI;EACE,cAAA;EACA,eAAA;EACA,qBAAA;AAEN;AADM;EACE,cAAA;AAGR","sourcesContent":[".login-card{\n  background-color: #FFFFFF;\n  max-width: 650px;\n  width: 100%;\n  padding: 70px;\n  border-radius: 12px;\n\n  .forget-box{\n    display: flex;\n    margin:  16px 0;\n    a{\n      color: #375997;\n      font-size: 16px;\n      text-decoration: none;\n      &:hover{\n        color: #108DA3;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
