import React, {useEffect, useState} from 'react';
import UserLayout from "../index";
import { Typography, Grid, Card, Box, Button, colors } from "@mui/material";
import PaymentsIcon from "@mui/icons-material/Payments";
import ReplayIcon from "@mui/icons-material/Replay";
import membershipbanner from "../../../../src/assets/images/Membership.jpg";
import CustomModal from '../../../components/modal/customModal';
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {getUserDetails} from "../../../store/auth";
import {useDispatch, useSelector} from "react-redux";
import {
    customerCancelMembership,
    getAllUserMembership,
    getUserMembershipDetails
} from "../../../store/user/user-membership";
import {cardDescription, cradDiv, gradient, overlayDiv, upgradeButton} from "./styles";
import Loader from "../../../components/loader/loader";
import moment from "moment/moment";
import {getDaysBetweenDates} from "../../../services/utills";
import {getAllCards} from "../../../store/user/user-cards";
import UserMembershipPayment from "./userMembershipPayment";
import Footer from '../../home/Footer';
import { useLocation } from 'react-router';

const UserMembership = () => {
    const { state } = useLocation();
    const [isOpen, setIsOpen] = useState('')
    const [paymentModal, setPaymentModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [providerLocation, setProviderLocation] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);


    const dispatch = useDispatch()


    const authStore = useSelector(state => state?.authStore)
    const userMembershipStore = useSelector(state => state?.userMembershipStore)
    const cardStores = useSelector(state => state?.userCreateCardStore?.cards)
    const location = useLocation();
    const {userMembershipsList, userMembership} = userMembershipStore
    const {user} = authStore
    const { selectedClinic } = location.state || {};
    const handleIsOpen = (type) => {
        setIsOpen(type)
    }
    const handleClose = () => {  
        setIsOpen('')
        setSelectedPlan(null)
    }


    useEffect(() => { 
        (async () => {
            if (user?.id ) {
                await dispatch(getUserMembershipDetails())
                 await dispatch(getAllUserMembership(user.defaultProvider?.id || selectedClinic?.id));
                setLoading(false)
                dispatch(getAllCards())
            }
        })()
    }, [user])

    useEffect(() => {
        (async () => {
            if (!user?.id) {
                await dispatch(getUserDetails())
            }
            if (user?.id && user?.defaultProvider) {
                let location = user?.defaultProvider?.location
                if (location?.length > 0) {
                    setProviderLocation(location[0])
                }

            }
        })()
    }, [ ])


    const getSubscriptionType = (subscription) => {

        if (userMembership?.plan?.id){
            const selectedPlanPrice = userMembership?.plan?.plan_price
            const planPrice = subscription?.plan_price
            if (parseFloat(selectedPlanPrice) < parseFloat(planPrice)){
                return "Upgrade"
            }else {
                return "Downgrade"
            }
        }else {
            return  'Subscribe'
        }
    }
    const { plan } = state || {};

    useEffect(() => {
      if (plan) {
        handleClickPlan(plan);
      }
    }, [plan]); 

    const handleClickPlan = (item) => {

        let type = getSubscriptionType(item?.subscription_plan)
        setSelectedPlan(item)
        if (type === "Upgrade"){
            handleIsOpen(type)
        }else if (type === 'Downgrade'){
            handleIsOpen(type)
        }else if (type === 'Subscribe'){
            handleIsOpen(type)
        }
    }
    const handleCancel = async () => {
        setLoading(true)
        let res = await dispatch(customerCancelMembership(userMembership?.id))
        if(res?.payload?.success === 'true'){
            setIsOpen('cancelSuccess')
        }

        setLoading(false)
    }

    return (
        <UserLayout>
            {loading ? <Loader fullSize/> : ''}
            <Box sx={{ marginBottom:{sm:'0', xs:'120px'} }}>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        mt: 2,
                        " @media (max-width:600px)": {
                            "& h3": {
                                fontSize: "20px",
                            },
                        },
                    }}
                >
                    <Grid item xs={12} lg={6} xl={4}>
                        <Card
                            sx={{
                                height: "420px",
                                border: '0.8px solid #C4C4C4', borderRadius: '4px', boxShadow: 'none',
                                p: "25px",
                                " @media (max-width:600px)": {
                                    height: "280px",
                                    p: "20px",
                                    "& h3": {
                                        fontSize: "18px",
                                        fontWeight: "500",
                                    },

                                    "& Button": {
                                        fontSize: "10px",
                                        mt: "10px",
                                    },
                                },
                            }}
                        >
                            {providerLocation?.address && <Box sx={{mb: '16px',}}>
                                <Box sx={{display: "flex", alignItems: "center", mb: '10px'}}>
                                    <LocationOnOutlinedIcon/>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontSize: "18px",
                                            ml: "7px",
                                            "@media (max-width:600px)": {
                                                fontSize: "14px",
                                                mr: "0px",
                                            },
                                        }}
                                    >
                                        My Current Location:
                                    </Typography>
                                </Box>
                                <Typography sx={{fontWeight: 'bold', fontSize: '16px'}}>
                                    {providerLocation?.address}
                                </Typography>
                            </Box>
                            }

                            {userMembership?.plan?.id ?
                                <>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            "@media (max-width:600px)": {
                                                display: "block",
                                                flexDirection: "row",
                                                alignItems: "center",
                                            },
                                        }}
                                    >
                                        <Box sx={{display: 'flex'}}>
                                            <PaymentsIcon/>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: "18px",
                                                    ml: "10px",
                                                    "@media (max-width:600px)": {
                                                        fontSize: "14px",
                                                        mr: "0px",
                                                    },
                                                }}
                                            >
                                                My Membership Plan
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "30px",
                                                mt: "10px",
                                                mb: "5px",
                                            }}
                                            variant="h3"
                                        >
                                            {userMembership?.plan?.plan_title || "$69 Monthly Plan"}
                                        </Typography>
                                        <Button
                                            onClick={() => handleIsOpen('cancel')}
                                            sx={{
                                                fontSize: "12px",
                                                p: "5px 6px",
                                                color: "#EB2415",
                                                border: "1px solid",
                                                BorderColor: "#EB2415",
                                                mb: "20px",

                                            }}
                                        >
                                            Cancel Plan
                                        </Button>
                                    </Box>

                                    <Box>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: "18px",
                                                my: "10px",
                                                "@media (max-width:600px)": {
                                                    fontSize: "14px",
                                                },
                                                "& SVG": {
                                                    fontSize: "14px",
                                                },
                                            }}
                                        >
                                            <ReplayIcon sx={{fontSize: "18px"}}/> Renews In:
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "30px",
                                                mt: "10px",
                                                mb: "40px",
                                            }}
                                            variant="h3"
                                        >
                                            {getDaysBetweenDates(userMembership?.start_date, userMembership?.end_date)} Days
                                            ({moment(userMembership?.end_date).format('DD-MM-YYYY')})
                                        </Typography>
                                    </Box>
                                </> :
                                <Box>
                                    <Typography variant="h5" color="initial" sx={{fontWeight:'bold'}}>
                                        No active membership plan available
                                    </Typography>
                                </Box>
                            }
                        </Card>
                    </Grid>
                    {userMembershipsList?.length ? userMembershipsList.map((item, index) => (
                            parseFloat(item?.subscription_plan?.plan_price) !== 149 && (
                            <Grid item xs={12} lg={6} xl={4} key={index}>
                            <Card

                                sx={{
                                    height: "420px", boxShadow: 'none',
                                    " @media (max-width:600px)": {height: "250px"},
                                }}
                            >
                                <Box sx={cradDiv}>
                                    <div style={overlayDiv}>
                                        <Box sx={{objectFit: 'contain'}}>
                                            <img src={item?.subscription_plan?.plan_image || membershipbanner}
                                                 width={'100%'} height={'420px'}
                                                 alt={item?.plan_title} style={{ objectFit:"cover" }}/>
                                            <div style={gradient}></div>
                                        </Box>
                                    </div>
                                    {userMembership?.plan?.id && (parseFloat(userMembership?.plan?.plan_price) === parseFloat(item?.subscription_plan?.plan_price)) ?
                                        <Button
                                            // onClick={() => handleClickPlan(item)}
                                            variant="contained"
                                            sx={{...upgradeButton,background:'#00FA00',cursor:'default',"&:hover":{
                                                    background:'#00FA00'
                                                }}}
                                        >
                                            ${item?.subscription_plan?.plan_price} Plan Active
                                        </Button>
                                        :
                                        <Button
                                            onClick={() => handleClickPlan(item)}
                                            variant="contained"
                                            sx={{ ...upgradeButton, background:'#E6DAD1', color:'#000' }}
                                        >
                                            {getSubscriptionType(item?.subscription_plan)} to ${item?.subscription_plan?.plan_price} Plan
                                        </Button>
                                    }
                                    <Typography
                                        variant="h4"
                                        sx={cardDescription}
                                    >
                                        {item?.subscription_plan?.plan_description}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                        )

                        ))
                        : <Grid item xs={12} lg={6} xl={4}>
                            <Card
                                sx={{
                                    border: '0.8px solid #C4C4C4',
                                    p:"20px",
                                    height: "420px", boxShadow: 'none',
                                    " @media (max-width:600px)": {height: "250px"},
                                }}
                            >
                                <Box sx={cradDiv}>
                                    <Typography>
                                        We sorry currently we don't have any plan available. please contact to related
                                        Spa
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    }
                </Grid>
                <UserMembershipPayment
                    user={user}
                    open={paymentModal}
                    setParentIsOpen={setIsOpen}
                    setOpen={()=> {
                        setPaymentModal(false)
                        handleClose()
                    }}
                    cardStores={cardStores}
                    selectPlan={selectedPlan}
                    userMembership={userMembership}
                />
                {/* User Modal */}
                <CustomModal
                    customWidth={'700px'}
                    setOpen={() => handleClose()}
                    open={isOpen !== ''}>
                    <Typography variant={'h4'} sx={{textAlign: "center", fontWeight: '600'}}>
                        {isOpen === "cancel" ? "Cancel Membership" : ""}
                        {isOpen === "Upgrade" ? "Upgrade Membership" : ""}
                        {isOpen === "Downgrade" ? "Downgrade Membership" : ""}
                        {isOpen === "Subscribe" ? "Subscribe Membership" : ""}
                        {isOpen === "cancelSuccess" ? "Your request for cancellation has been sent successfully!" : ""}
                        {isOpen === "downgradeSuccess"||isOpen === "upgradeSuccess" ? "Congratulations" : ""}
                    </Typography>
                    <Typography variant={'h5'} sx={{textAlign: "center", mt: 2}}>
                        {isOpen === "cancel" ? " Are you sure you want to cancel your membership?" : ""}
                        {isOpen === "Upgrade" ? " Are you sure you want to upgrade your membership?" : ""}
                        {isOpen === "Subscribe" ? " Are you sure you want to Subscribe this membership?" : ""}
                        {isOpen === "Downgrade" ? "Are you sure you want to downgrade your membership?" : ""}
                    </Typography>
                    <Typography variant={'h5'} sx={{textAlign: "center", mt: 2,p:'15px 30px',mx:'20px',mb:'5px'}}>
                       {isOpen === "cancelSuccess" ? " You will receive a response within 24 hours " : ""}
                        {isOpen === "downgradeSuccess" ? "You have now been upgraded to $99 membership plan. This will" +
                            " give you access to All Medical Aesthetic treatments as well as All Therpuetic " +
                            "Wellness treatments at any 3D lifestyle medi-spa nationwide. " : ""}
                        {isOpen === "upgradeSuccess" ? `You have now been upgraded to $99 membership plan. This will
                         give you access to All Medical Aesthetic treatments as well as All Therpuetic Wellness
                          treatments at any 3D lifestyle medi-spa nationwide. ` : ""}
                    </Typography>

                    {
                        (isOpen === 'cancel' || isOpen === 'Upgrade' || isOpen === 'Subscribe' || isOpen === 'Downgrade' )?
                            <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', mt: 3, mb: 4}}>
                                <Button
                                    sx={{
                                        backgroundColor: "#fff",
                                        width: "200px",
                                        height: '62px',
                                        border: "1px solid #666666",
                                        borderRadius: '10px',
                                        fontSize: '20px',
                                        color: "#666666",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                    onClick={() => handleClose()}
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={async () => {
                                        if (isOpen === 'cancel') {
                                            await handleCancel()
                                            // setIsOpen('success')
                                        }else {
                                            setIsOpen('')
                                            setPaymentModal(true)
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: "#fff",
                                        width: "200px",
                                        border: "1px solid #666666",
                                        color: "#666666",
                                        borderRadius: '10px',
                                        fontSize: '20px',
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                            border: "1px solid #666666",
                                        },
                                    }}
                                >
                                    Yes
                                </Button>
                            </Box> : ""
                    }
                </CustomModal>

            </Box>

            <Box style={{marginLeft:'calc(-50vw + 50%)', marginRight:'calc(-50vw + 50%)' }}>
            <Footer />
</Box>
        </UserLayout>
    );
};

export default UserMembership;