import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
window.formatTimeDuration = (duration) => {
    const [hours, minutes] = duration.split(':').map(Number);

    if (hours === 0) {
        return `${minutes} min${minutes > 1 ? 's' : ''}`;
    } else if (hours === 1 && minutes === 0) {
        return '1 hr';
    } else {
        return `${hours} hr${hours > 1 ? 's' : ''} ${minutes > 0 ? `${minutes} min${minutes > 1 ? 's' : ''}` : ''}`.trim();
    }
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

