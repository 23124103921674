import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FormInput, {
  inputStyle,
  labelStyle,
} from "../../../../components/form/formInput";
import FormInputPhone from "../../../../components/form/formInputPhone";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import {
  dateOfBirthMonths,
  getMonthDays,
  successConfirmation,
} from "../../../../services/utills";
import ClearIcon from "@mui/icons-material/Clear";
import FormPasswordInput from "../../../../components/form/formPasswordInput";
import CustomButton from "../../../../components/custom-button/customButton";
import SubmitButton from "../../../../components/custom-button/submitButton";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { updateCrmCustomer } from "../../../../store/crm/crm";
import Image from "../../../../components/image/image";
import Loader from "../../../../components/loader/loader";
import { ribbonwrapper } from "../../../../components/card-appointment/CardAppointment";
import ribbon from "../../../../assets/images/ribbon.svg";
import redRibbon from "../../../../assets/images/red-badg.svg";
import yellowRibbon from "../../../../assets/images/yellow-badg.svg";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import RemovalAccountModal from "../../../../components/modal/AccountRemoval";
const CrmProfileInfo = ({ crmCustomerDetails }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string().when("countryCode", {
      is: "+92",
      then: Yup.string()
        .matches(/^[1-9][0-9]\d{8}$/, "Invalid pakistani phone number")
        .required("Phone number is required"),
      otherwise: Yup.string()
        .matches(/^(\d{3}-?)?\d{3}-?\d{4}$/, "Invalid canada phone number")
        .required("Phone number is required"),
    }),
    countryCode: Yup.string(),
    postal_code: Yup.string(),
    state: Yup.string(),
    country: Yup.string(),
    city: Yup.string(),
    suite: Yup.string(),
    address: Yup.string(),
    birth_month: Yup.mixed(),
    birth_day: Yup.mixed(),
    password: Yup.string().min(8, "Password must be 8 characters long"),
    /*    .matches(/[0-9]/, "Password requires a number")
        .matches(/[a-z]/, "Password requires a lowercase letter")
        .matches(/[A-Z]/, "Password requires an uppercase letter"),*/
    profile_image: Yup.mixed(),
  });
  const {
    values,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    handleChange,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      countryCode: "+1",
      password: "",
      city: "",
      state: "",
      country: "",
      suite: "",
      birth_day: null,
      profile_image: null,
      birth_month: null,
      address: "",
      postal_code: "",
      outstanding_balance: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      const { birth_day, birth_month } = data;
      if (birth_month !== null) {
        if (birth_day === null) {
          await setFieldTouched("birth_day", true);
          setFieldError("birth_day", "Birth Date is required");
          return;
        }
      }
      if (birth_day !== null) {
        if (birth_month === null) {
          await setFieldTouched("birth_month", true);
          setFieldError("birth_month", "Birth Month is required");
          return;
        }
      }

      setLoading(true);

      const formData = new FormData();

      Object.keys(data).forEach(function (key, index) {
        if (key === "profile_image") {
          if (typeof data[key] !== "string") {
            formData.append(key, data[key]);
          } else if (typeof data[key] === "string") {
            formData.append(key, data[key]);
          }
        } else if (key === "countryCode" || key === "phone") {
          formData.append(key, data["countryCode"] + data["phone"]);
        } else {
          formData.append(key, data[key]);
        }
      });

      let { payload } = await dispatch(
        updateCrmCustomer({ data: formData, id: data?.id })
      );

      setLoading(false);
      if (payload?.success === "true") {
        await successConfirmation("Success", "Updated Successfully");
        // navigate('/crm/customer-relationship-management')
      } else {
        const errors = payload?.data?.errors;
        console.log(errors);
        try {
          if (Object.keys(errors)?.length > 0) {
            Object.keys(errors).forEach(function (key) {
              setFieldTouched(key, true);
              setFieldError(key, errors[key]);
            });
          }
        } catch (e) {}
      }
    },
  });
  useEffect(() => {
    (() => {
      if (crmCustomerDetails?.customer_id) {
        setFieldValue("id", crmCustomerDetails?.customer_id);
        setFieldValue("name", crmCustomerDetails?.customer_name || "");
        setFieldValue(
          "profile_image",
          crmCustomerDetails?.profile_image || null
        );
        setFieldValue("postal_code", crmCustomerDetails?.postal_code || "");
        setFieldValue("outstanding_balance", crmCustomerDetails?.outstanding_balance || null);
        setFieldValue("email", crmCustomerDetails?.email || "");
        setFieldValue("city", crmCustomerDetails?.city || "");
        setFieldValue("state", crmCustomerDetails?.state || "");
        setFieldValue("suite", crmCustomerDetails?.suite || "");
        setFieldValue("country", crmCustomerDetails?.country || "");
        setFieldValue("address", crmCustomerDetails?.address || "");
        setFieldValue("gender", crmCustomerDetails?.gender || "");
        setFieldValue("birth_month", crmCustomerDetails?.birth_month || null);
        setFieldValue("birth_day", crmCustomerDetails?.birth_day || null);
        setFieldValue("bio", crmCustomerDetails?.bio || "");
        if (crmCustomerDetails?.phone) {
          // If the phone number includes the +92 prefix
          if (crmCustomerDetails.phone.includes("+92")) {
            setFieldValue("phone", crmCustomerDetails.phone.replace(/^\+92/, ""));
            setFieldValue("countryCode", "+92");
          }
          // If the phone number includes the +1 prefix
          else if (crmCustomerDetails.phone.includes("+1")) {
            setFieldValue("phone", crmCustomerDetails.phone.replace(/^\+1/, ""));
            setFieldValue("countryCode", "+1");
          }
          // If the phone number starts with '14', remove the leading '1'
          else if (crmCustomerDetails.phone.startsWith("14")) {
            setFieldValue("phone", crmCustomerDetails.phone.substring(1));
            setFieldValue("countryCode", "+1"); // Set countryCode based on your logic
          }
          else if (crmCustomerDetails.phone.startsWith("92")) {
            setFieldValue("phone", crmCustomerDetails.phone.substring(2));
            setFieldValue("countryCode", "+92"); // Set countryCode based on your logic
          }
              // For any other case, you might want to handle it differently
          // Here, I'm assuming you want to leave the phone as is and not set a country code
          else {
            // Set the phone value directly without modification
            setFieldValue("phone", crmCustomerDetails.phone);
            // Optionally, clear the countryCode or set it based on another logic
            setFieldValue("countryCode", "+1"); // Example: clearing the countryCode
          }
        }
        setFieldValue("email", crmCustomerDetails?.email || "");
      }
    })();
  }, [crmCustomerDetails?.customer_id]);

  return (
    <Box sx={{ minHeight: "300px" }}>
      {loading ? <Loader fullSize /> : ""}
      <form onSubmit={handleSubmit} autoComplete="off">
        <Box sx={{ display: "flex" }}>
          {crmCustomerDetails?.customer_batch && (
            crmCustomerDetails?.customer_batch !== "New User" ? (
            <>
              {crmCustomerDetails?.customer_batch?.includes("Expire/Cancel") ? (
                <div style={{display:"flex",alignItems:"start"}}>
                  <img
                    style={{ marginTop: "4px" }}
                    src={redRibbon}
                    alt={"member"}
                  />
                  <Typography style={{ color: "#FF0000" }}>
                    {crmCustomerDetails?.customer_batch}
                  </Typography>
                  </div>
              ) : (
                <>
                  {crmCustomerDetails?.customer_batch?.includes("Declined") ? (
                     <div style={{display:"flex",alignItems:"start"}}>
                      <img
                        style={{ marginTop: "4px" }}
                        src={yellowRibbon}
                        alt={"member"}
                      />
                      <Typography style={{  color: "#dcd90a"}}>
                        {crmCustomerDetails?.customer_batch}
                      </Typography>
                      </div>
                  ) : (
                    <div style={{display:"flex",alignItems:"start"}}>
                      <img
                        style={{ marginTop: "4px" }}
                        src={ribbon}
                        alt={"member"}
                      />
                      <Typography style={{ color: "#219653" }}>
                        {crmCustomerDetails?.customer_batch}
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Typography style={{ color: "#09ABF1" }}>
                {crmCustomerDetails?.customer_batch}
              </Typography>
            </>
          ))}
        </Box>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { md: "row", sm: "row-reverse" },
          }}
        >
          <Grid item xl={2.5} lg={6} md={6} sm={6} xs={12}>
            <Image
              name={"profile_image"}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              styles={{ width: "100%", maxWidth: "200px" }}
            />
          </Grid>
          <Grid item xl={2.9} lg={6} md={6} sm={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* <Box sx={{ display: "flex", mt: 2 }}>
                {crmCustomerDetails?.customer_batch !== "New User" ? (
                    <>
                      {crmCustomerDetails?.customer_batch?.includes("Expire/Cancel") ? (
                          <>
                            <img style={{ marginTop: '4px' }} src={redRibbon} alt={'member'} />
                            <Typography style={{ color: "#FF0000" }}>
                              {crmCustomerDetails?.customer_batch}
                            </Typography>
                          </>
                      ) : (
                          <>
                            {crmCustomerDetails?.customer_batch?.includes("Declined") ? (
                                <>
                                  <img style={{ marginTop: '4px' }} src={yellowRibbon} alt={'member'} />
                                  <Typography style={{ color: "#FFFF00" }}>
                                    {crmCustomerDetails?.customer_batch}
                                  </Typography>
                                </>
                            ) : (
                                <>
                                  <img style={{ marginTop: '4px' }} src={ribbon} alt={'member'} />
                                  <Typography style={{ color: "#219653" }}>
                                    {crmCustomerDetails?.customer_batch}
                                  </Typography>
                                </>
                            )}
                          </>
                      )}
                    </>
                ) : (
                    <>
                      <Typography style={{ color: "#09ABF1" }}>
                        {crmCustomerDetails?.customer_batch}
                      </Typography>
                    </>
                )}
                </Box> */}
              <Box sx={{ display: "flex", mt: 2 }}>
                <Typography
                  sx={{
                    width: "60",
                  }}
                  variant="body2"
                  color="initial"
                >
                  {crmCustomerDetails?.is_membered === 1
                    ? "Member Since:"
                    : "Customer Since:"}
                </Typography>
                <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                  {crmCustomerDetails?.member_since}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mt: 2 }}>
                <Typography
                  sx={{
                    width: "60",
                  }}
                  variant="body2"
                  color="initial"
                >
                  Wallet Balance:
                </Typography>
                <Typography variant="body2" color="initial" sx={{ ml: 1 }}>
                  ${crmCustomerDetails?.wallet}
                </Typography>
             
              </Box>
         
              {/* <Box sx={{ display: "flex", mt: 2,alignItems:"center" }}>
                <Typography variant="body2" color="initial">
                  Client Forms:
                </Typography>
                <Box
                    sx={{
                      ml:'6px',
                      display:"flex",
                      alignItems:'center',
                      cursor:'pointer',
                    }}
                >
                  <InsertDriveFileIcon sx={{color:'#2F80ED'}}/>
                  <Typography variant="body2" color="initial" sx={{ ml: "5px", color:'#2F80ED', textDecoration:'underline' }}>
                      Client Forms
                  </Typography>
                </Box>
              </Box> */}
            </Box>
          </Grid>
          <Grid item xl={6.4} lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                background: "#F2F2F2",
                display: "flex",
                p: { sm: 4, xs: 1 },
                width: { sm: "unset", xs: "100%" },
                borderRadius: "6px",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  borderRight: "0.603833px solid #9A9797",
                  flex: 1,
                  p: {sm: 0, xs:'3px' },
                }}
              >
                <Typography variant="body2" color="initial">
                  {crmCustomerDetails?.completed}
                </Typography>
                <Typography variant="body2" color="initial">
                  Completed
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  borderRight: "0.603833px solid #9A9797",
                  flex: 1,
                  p: {sm: 0, xs:'3px' },
                }}
              >
                <Typography variant="body2" color="initial">
                  {crmCustomerDetails?.cancelled}
                </Typography>
                <Typography variant="body2" color="initial">
                  Cancelled
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  borderRight: "0.603833px solid #9A9797",
                  flex: 1,
                  p: {sm: 0, xs:'3px' },
                }}
              >
                <Typography variant="body2" color="initial">
                  {crmCustomerDetails?.new}
                </Typography>
                <Typography variant="body2" color="initial">
                  New
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  flex: 1,
                  p: {sm: 0, xs:'3px' },
                }}
              >
                <Typography variant="body2" color="initial">
                  {crmCustomerDetails?.no_show}
                </Typography>
                <Typography variant="body2" color="initial">
                  No Show
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                background: "#F2F2F2",
                display: "flex",
                p: 4,
                pt: {sm:0, xs:2},
                borderRadius: "6px",
                flexWrap: "wrap",
                
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  borderRight: "0.603833px solid #9A9797",
                  flex: 1,
                  p: {sm: 0, xs:'3px' },
                  
                }}
              >
                <Typography variant="body2" color="initial">
                  ${parseFloat(crmCustomerDetails?.wallet_used).toFixed(2)}
                </Typography>
                <Typography variant="body2" color="initial">
                  Wallet Used
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  flex: 1,
                  p: {sm: 0, xs:'3px' },
                }}
              >
                <Typography variant="body2" color="initial">
                  ${parseFloat(crmCustomerDetails?.total_revenue).toFixed(2)}
                </Typography>
                <Typography variant="body2" color="initial">
                  Total Revenue
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{}}>
          <Grid item lg={12} md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4} sx={{ mt:{xs:3,sm:0} }}>
                <FormInput
                  name={"name"}
                  label={"Full Name"}
                  placeholder={"Type your name"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <FormInputPhone
                  name={"phone"}
                  label={"Contact Number"}
                  placeholder={
                    values?.countryCode === "+1" ? "xxx-xxx-xxxx" : "3xxxxxxxxx"
                  }
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} sx={{ display: "flex" }}>
                <FormControl fullWidth sx={{ mb: "16px" }}>
                  <FormLabel sx={labelStyle}>Email</FormLabel>
                  <TextField
                    fullWidth
                    name={"email"}
                    sx={inputStyle}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    placeholder={"Email"}
                    value={values?.email}
                    onChange={handleChange}
                  />
                  {errors?.email && touched?.email && (
                    <FormHelperText
                      sx={{ color: "#CB3838", width: "max-content", mx: 0 }}
                    >
                      {errors?.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormControl fullWidth>
                  <FormLabel sx={labelStyle}>Date of Birth</FormLabel>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      options={dateOfBirthMonths}
                      getOptionLabel={(option) => option}
                      value={values?.birth_month}
                      disableClearable
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(event, value) => {
                        setFieldValue("birth_month", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"Month"}
                          sx={{ ...inputStyle }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {values?.birth_month && (
                                  <IconButton
                                    sx={{ p: "5px" }}
                                    onClick={() => {
                                      setFieldValue("birth_month", null);
                                      setFieldValue("birth_day", null);
                                    }}
                                  >
                                    <ClearIcon sx={{ fontSize: "16px" }} />
                                  </IconButton>
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      ListboxComponent={(props) => (
                        <div
                          {...props}
                          style={{ maxHeight: 200, overflowY: "auto" }}
                        />
                      )}
                      style={{ maxHeight: 200 }}
                    />
                    <Autocomplete
                      fullWidth
                      options={getMonthDays(values?.birth_month)}
                      getOptionLabel={(option) => option.toString()}
                      value={values?.birth_day}
                      disableClearable
                      isOptionEqualToValue={(option, value) =>
                        option.toString() === value.toString()
                      }
                      onChange={(event, value) => {
                        setFieldValue("birth_day", value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                          placeholder={"Date"}
                          sx={inputStyle}
                        />
                      )}
                      ListboxComponent={(props) => (
                        <div
                          {...props}
                          style={{ maxHeight: 200, overflowY: "auto" }}
                        />
                      )}
                      style={{ maxHeight: 200 }}
                    />
                  </Box>
                  {errors?.birth_day && touched?.birth_day && (
                    <FormHelperText
                      sx={{
                        color: "#CB3838",
                        width: "max-content",
                        mx: 0,
                      }}
                    >
                      {errors?.birth_day}
                    </FormHelperText>
                  )}
                  {errors?.birth_month && touched?.birth_month && (
                    <FormHelperText
                      sx={{
                        color: "#CB3838",
                        width: "max-content",
                        mx: 0,
                      }}
                    >
                      {errors?.birth_month}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormInput
                  name={"address"}
                  label={"Address"}
                  placeholder={"Type your address"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormInput
                  name={"suite"}
                  label={"Apt/suite"}
                  placeholder={"Type your Apt"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormInput
                  name={"city"}
                  label={"City"}
                  placeholder={"City"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormInput
                  name={"state"}
                  label={"State"}
                  placeholder={"State"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormInput
                  name={"country"}
                  label={"Country"}
                  placeholder={"Country"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={12}>
                <FormInput
                  name={"postal_code"}
                  label={"Postal Code"}
                  placeholder={"Postal Code"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>

              <Grid item lg={4} md={6} xs={12}>
                <FormPasswordInput
                  name={"password"}
                  label={"Set Password"}
                  placeholder={"Type your password"}
                  errors={errors}
                  touched={touched}
                  values={values}
                  handleChange={handleChange}
                />
              </Grid>
                <Grid item lg={4} md={6} xs={12}>
                    <FormInput
                        name={"outstanding_balance"}
                        label={"Outstanding Balance"}
                        placeholder={"Outstanding Balance"}
                        errors={errors}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                    />
                </Grid>
            </Grid>

            <Box style={{ display: "flex", justifyContent: "space-between", mt: "24px" }}>
            <Box sx={{ display:'block' }}>
                <RemovalAccountModal />
                </Box>
              <Box sx={{ mr: "16px", display:'flex' }}>
                <CustomButton
                  mainColor={"#ED1E45"}
                  val={"Cancel"}
                  handleClick={() =>
                    navigate("/crm/customer-relationship-management")
                  }
                />
                <Box sx={{ ml:1 }}>

              <SubmitButton val={"Edit Customer"} />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CrmProfileInfo;
