import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apis from "../../../lib/apis/apis";
import { apiHeaders } from "../../../lib/apiHeader";
import toast from "react-hot-toast";

// // Thunk to fetch messages
// export const GetMessagess = createAsyncThunk(
//   'GetMessagess',
//   async (id, { rejectWithValue }) => {
//     try {
//       const response = await axios.get(apis.Get_Messagese_user + id, {
//         headers: apiHeaders(),
//       });
//       console.log("fetching data", response?.data);

//       if (response?.data?.success === "true") {
//         return response?.data.data;  // Return full data
//       } else {
//         return rejectWithValue('Failed to fetch chat data');
//       }
//     } catch (err) {
//       console.error("Error fetching chat:", err);
//       return rejectWithValue(err.response?.data?.message || 'Failed to fetch chat');
//     }
//   }
// );




export const getAllMessages = createAsyncThunk(
  "getAllMessages",
  async (id, { dispatch }) => {
    try {
      const response = await axios.get(apis.Get_Messagese_user + id, {
        headers: apiHeaders(),
      });

      console.log("fetch data", response?.data);

      if (response?.data?.data) {
        console.log("fetchting data", response?.data.data); 
        return response?.data;
      } else {
        console.log("Fetching data failed");
        return []; 
      }

    } catch (err) {
      console.error("Error fetching data:", err);
      return [];
    }
  }
);

// Thunk to send messages
export const SendMsgss = createAsyncThunk(
  'SendMsgss', 
  async (payload) => {
    try {
      const response = await axios.post(apis.Send_Messages_user, payload, {
        headers: apiHeaders(),
      });

      if (response.data.success === 'true') {
        return response.data;
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  }
);

// Thunk to get user details
export const getUserDetails = createAsyncThunk("me", async () => {
  try {
    const response = await axios.get(apis.me, {
      headers: apiHeaders(),
    });

    if (response?.data?.success === true) {
      return {
        data: response?.data?.data,
        token: true,
        loading: false,
      };
    } else {
      localStorage.removeItem('token');
      return {
        token: false,
        loading: false,
      };
    }
  } catch (err) {
    localStorage.removeItem('token');
    return {
      token: false,
      loading: false,
    };
  }
});
export const ReadMessageuser = createAsyncThunk('ReadMessageuser', async (id, { getState, rejectWithValue }) => {
  try {
      // Make the API request to get the selected chat
      const response = await axios.get(apis.Read_Message_user+id, {
          headers: apiHeaders(),
      });

      // Check if the response was successful
      if (response?.data?.success === "true") {
          console.log("Fetched data:", response?.data?.data);

          // Return the fetched chat data
          return response?.data?.data; 
      } else {
          // Handle case when the response is not successful
          return rejectWithValue('Failed to fetch chat data');
      }
  } catch (err) {
      console.error("Error fetching chat:", err);
      // Return the error as a rejected value
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch chat');
  }
});

// Chat Slice
export const UserChatSlice = createSlice({
  name: 'UserChatSlice',
  initialState: {
    Messages: [],
    usermessage:[],
    userProfile: {},
    logged: false,
    unreadCounter: 0,
    loading: true,
  },
  reducers: {
    ADD_MESSAGE_OPTIMISTICALLY: (state, action) => {
      state.Messages.push(action.payload);
  },
  },
  extraReducers: builder => {
    builder
    .addCase(getAllMessages.fulfilled, (state, action) => {
      
    console.log('Action Payload:', action.payload);
      state.Messages = action?.payload?.data|| []
      state.unreadCounter = action.payload?.unreadCounter || 0

    })
    // 
    .addCase(SendMsgss.fulfilled, (state, action) => {
      state.usermessage = action?.payload || []
    
    }) 
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userProfile = action?.payload?.data || {};
        state.logged = action?.payload?.token;
        state.loading = action?.payload?.loading;
      });
  },
});

export default UserChatSlice.reducer;
