import React from 'react';
import {Box, Typography} from "@mui/material";

const ComingSoon = () => {
    return (
        <Box
            sx={{
                display:'flex',
                justifyContent:'center'
                ,alignItems:"center",
                height:'100%',
                width:"100%",
                backgroundColor:"#fff",
                borderRadius:"7px"
            }}
        >
            <Typography variant='h5' sx={{ mb: 2.5, fontSize: '3.5rem !important' }}>
                We are launching soon 🚀
            </Typography>
        </Box>
    );
};

export default ComingSoon;