import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import * as yup from "yup";
import {useFormik} from "formik";
import toast from "react-hot-toast";
import {Box, Grid} from "@mui/material";
import Loader from "../../../components/loader/loader";
import FormInput from "../../../components/form/formInput";
import CustomButton from "../../../components/custom-button/customButton";
import SubmitButton from "../../../components/custom-button/submitButton";
import ConfigurationLayout from "../configurationLayout";
import * as Yup from "yup";
import {getMembershipByID, storeMembership, updateMembership} from "../../../store/super-admin/membership";
import Image from "../../../components/image/image";
import FormStatusInput from "../../../components/form/FormStatusInput";

const AddMembership = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const validationSchema = yup.object({
        plan_title: yup.string().min(3, "Membership plan title  is too short").required('Membership plan title is required'),
        plan_description: yup.string().min(10, "Description is too short").required('Description is required'),
        plan_price: Yup.string().matches( /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, "Only integer allowed").required('Plan price is required'),
        wallet_point: Yup.string().matches( /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, "Only integer allowed").required('Wallet point is required'),
        plan_image:Yup.mixed().required(),
    });


    const {values, handleSubmit, setFieldValue, handleChange, touched, errors} = useFormik({
        initialValues: {
            plan_title: '',
            plan_price: '',
            wallet_point: '',
            plan_image: null,
            status: "Inactive",
            plan_description: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
            setLoading(true)

            let res
            if (params?.membership_id) {
                let finalData = {
                    id: data?.id,
                    plan_title: data?.plan_title,
                    plan_price: data?.plan_price,
                    wallet_point: data?.wallet_point,
                    status: data?.status,
                    plan_image: data?.plan_image,
                    plan_description: data?.plan_description,
                }
                if (typeof data?.plan_image === 'string'){
                    delete finalData["plan_image"]
                }
                res = await dispatch(updateMembership(finalData))
            } else {
                res = await dispatch(storeMembership(data))
            }
            if (res?.payload?.errors) {
                const {errors} = res?.payload
                console.log(errors)
                if (errors?.plan_title) {
                    setFieldTouched("plan_title", true)
                    setFieldError("plan_title", errors?.plan_title)
                }
                if (errors?.plan_price) {
                    setFieldTouched("plan_price", true)
                    setFieldError("plan_price", errors?.plan_price)
                }
                if (errors?.wallet_point) {
                    setFieldTouched("wallet_point", true)
                    setFieldError("wallet_point", errors?.wallet_point)
                }
                if (errors?.plan_description) {
                    setFieldTouched("plan_description", true)
                    setFieldError("plan_description", errors?.plan_description)
                }
                if (errors?.plan_image) {
                    setFieldTouched("plan_image", true)
                    setFieldError("plan_image", errors?.plan_image)
                }

            } else if (res?.payload?.success === "true") {
                toast.success(`Membership ${params?.membership_id ? "updated" : "Stored"} Successfully`, {duration: 3000})
                navigate('/admin/configuration/all-membership-plans')
            }

            setLoading(false)
        },
    });


    useEffect(() => {
        (async () => {
            if (params?.membership_id) {
                setLoading(true)
                let res = await dispatch(getMembershipByID(params?.membership_id))
                if (res?.payload?.data?.id) {
                    let data =res?.payload?.data
                    await setFieldValue("plan_title", data?.plan_title)
                    await setFieldValue('plan_price', data?.plan_price )
                    await setFieldValue('wallet_point', data?.wallet_point )
                    await setFieldValue('plan_image', data?.plan_image || null)
                    await setFieldValue('plan_description', data?.plan_description )
                    await setFieldValue('status', data?.status || 'Inactive')
                    await setFieldValue('id', data?.id)
                }
                setLoading(false)
            }

        })()
    }, [params?.membership_id])

    return (
        <ConfigurationLayout>
            <Box sx={{height: "calc(100vh - 125px)", backgroundColor:"white"}} p={3}>
                {loading ?  <Box
        sx={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            height: "100vh" 
        }}
    >
        <Loader />
    </Box> : ""}
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={5.5}>
                            <Image
                                label={'Image'}
                                name={"plan_image"}
                                values={values}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{justifyContent: 'space-between'}}>
                        <Grid item xs={12} md={5.5}>
                            <FormInput
                                name={"plan_title"}
                                label={"Membership Plan Title"}
                                placeholder={'Type  membership name'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={5.5}>
                            <FormInput
                                name={"plan_price"}
                                label={"Membership Price"}
                                placeholder={'Type membership price'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{justifyContent: 'space-between'}}>
                        <Grid item xs={12} md={5.5}>
                            <FormInput
                                name={"wallet_point"}
                                label={"Wallet Point"}
                                placeholder={'Type wallet point'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={5.5}>
                            <FormStatusInput
                                name={"status"}
                                errors={errors}
                                label={"Status"}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormInput
                                name={"plan_description"}
                                label={"Membership Description"}
                                placeholder={'Type membership description'}
                                errors={errors}
                                multiline
                                rows={5}
                                touched={touched}
                                values={values}
                                handleChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: '34px'}}>
                                <Box sx={{mr: '16px'}}>
                                    <CustomButton
                                        mainColor={'#ED1E45'}
                                        val={'Cancel'}
                                        handleClick={() => {
                                            navigate('/admin/configuration/all-membership-plans')
                                        }}
                                    />
                                </Box>
                                <SubmitButton val={params?.membership_id ? 'Update Membership Plan' : 'Add Membership Plan'}/>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </ConfigurationLayout>
    );
};

export default AddMembership;