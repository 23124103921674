import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  TextField,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";

import Drawer from "@mui/material/Drawer";
import * as yup from "yup";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import FormInput, { inputStyle } from "../../../components/form/formInput";
import { getUserDetails } from "../../../store/auth";
import {
  closeUserSupport,
  getAllUserSupport,
  replyUserSupport,
  storeUserSupport,
} from "../../../store/user/support";
import moment from "moment";
import Loader from "../../../components/loader/loader";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {confirmation, successConfirmation} from "../../../services/utills";
import Footer from "../../home/Footer";



const Support = () => {
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTicket, setActiveTicket] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [reply, setReply] = useState("");
  const dispatch = useDispatch();

  const authStore = useSelector((state) => state?.authStore);
  const {user} = authStore;

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const userSupport = useSelector(
      (state) => state.userSupportStore.userSupport
  );

  useEffect(() => {
    (async () => {
      if (!user?.id) {
        await dispatch(getUserDetails());
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      await dispatch(getAllUserSupport());
    })();
  }, []);

  const validationSchema = yup.object({
    title: yup
        .string()
        .min(3, "Title  is too short")
        .required("Title is required"),
    description: yup
        .string()
        .min(10, "Description is too short")
        .required("Description is required"),
  });

  const {values, handleSubmit, resetForm, handleChange, touched, errors} =
      useFormik({
        initialValues: {
          title: "",
          description: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (data, {setFieldTouched, setFieldError}) => {
          setLoading(true);

          let res;
          res = await dispatch(
              storeUserSupport({...data, sender_id: user?.id})
          );

          if (res?.payload?.success === "true") {
            handleCloseDrawer();
            await successConfirmation('Operation Successfully',"Your Query have been added. We will reach you shortly")
            resetForm()
          }
          if (res?.payload?.errors) {
            const {errors} = res?.payload;
            if (errors?.plan_title) {
              setFieldTouched("plan_title", true);
              setFieldError("plan_title", errors?.plan_title);
            }
            if (errors?.plan_price) {
              setFieldTouched("plan_price", true);
              setFieldError("plan_price", errors?.plan_price);
            }
          }

          setLoading(false);
        },
      });

  const handleReply = async () => {
    if (reply) {
      setLoading(true);
      let finalData = {
        message: reply,
        user_id: activeTicket?.user_id,
        support_id: activeTicket?.id,
      };

      let res = await dispatch(replyUserSupport(finalData));
      if (res?.payload?.success === "true") {
        handleCloseDrawer();
        await successConfirmation('Reply Added Successfully')
        setReply('')
      }
      if (res?.payload?.errors) {
        const {errors} = res?.payload;
      }
      setLoading(false);
    }
  };
  const handleClose = async (item) => {
    if (await confirmation('',"Yes, Close it.")){

      setLoading(true);

      let finalData = {
        support_id: item?.id,
      };

      let res = await dispatch(closeUserSupport(finalData));

      if (res?.payload?.success === 'true'){
        await successConfirmation('Support Ticket Closed Successfully')
      }
      if (res?.payload?.errors) {
        const {errors} = res?.payload;
      }
      setLoading(false);
    }
  };

  const list = () => (
      <Box sx={{width:{xs:'280px',sm:'480px'}}} role="presentation">
        <Typography
            variant="h5"
            color="initial"
            sx={{textAlign: "right", p: 4, cursor: "pointer"}}
            onClick={() => handleCloseDrawer()}
        >
          x
        </Typography>
        <Typography
            variant="h5"
            color="initial"
            sx={{p: 4, textAlign: "center"}}
        >
          Support
        </Typography>
        {activeTicket?.id ? (
            <Box sx={{p: "24px"}}>
              <Typography>Title: {activeTicket?.title}</Typography>
              <FormControl fullWidth sx={{my: "24px"}}>
                <TextField
                    fullWidth
                    sx={inputStyle}
                    value={reply}
                    multiline
                    minRows={4}
                    onChange={(e) => setReply(e.target.value)}
                />
              </FormControl>

              <Button
                  onClick={() => handleReply()}
                  fullWidth
                  variant="contained"
                  sx={{ background:'#f4ede3 !important', color:'#000' }}
              >
                Reply
              </Button>
            </Box>
        ) : (
            <form onSubmit={handleSubmit}>
              <Grid container sx={{px: {xs:'15px',sm:'30px'}}}>
                <Grid item xs={12}>
                  <FormInput
                      name={"title"}
                      label={"Subject"}
                      placeholder={"Type  Subject"}
                      errors={errors}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInput
                      name={"description"}
                      label={"Description"}
                      placeholder={"Type  description"}
                      errors={errors}
                      multiline
                      rows={5}
                      touched={touched}
                      values={values}
                      handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{mt: "20px"}}>
                  <Button
                      type={"submit"}
                      fullWidth
                      variant="contained"
                      sx={{ background:'#f4ede3', color:'#000' }}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </form>
        )}
      </Box>
  );

  const handleCloseDrawer = () => {
    setActiveTicket(null);
    setOpenDrawer(false);
  };
  const handleOpenDrawer = (item) => {
    setActiveTicket(item);
    setOpenDrawer(true);
  };

  return (
    <>
      <Box sx={{ background:'#fff',  padding:{md:'35px', xs:'5px'} ,borderRadius:'7px', }}>
        <Box sx={{display: "flex",flexWrap:'wrap', justifyContent: "space-between"}}>
          {loading ? <Loader fullSize/> : ""}
          <Typography variant="h5" color="initial"
          sx={{ marginBottom:{xs:'10px', sm:'0px'} }}>
            Support
          </Typography>
          <React.Fragment>
            <Button
                variant="contained"
                sx={{ 
                  background:'#f4ede3',
                  color:'#000'
                 }}
                onClick={() => handleOpenDrawer()}
            >
              Add Support Ticket
            </Button>

            <Drawer
                anchor={"right"}
                open={openDrawer}
                onClose={() => handleCloseDrawer()}
               
            >
              {list()}
            </Drawer>
          </React.Fragment>
        </Box>

        </Box>

        {userSupport?.length > 0 &&
            userSupport.map((item, index) => (
                <Grid
                    key={index}
                    item
                    xs={12}
                    sx={{
                      mt: 5,
                      fontWeight: "bold",
                      background: "#fff",
                      borderBottom: "1px solid rgb(226, 232, 240)",
                    }}
                >
                  <Accordion
                      onChange={handleAccordionChange}
                      sx={{
                        boxShadow: "0px 0px 11px -4px rgba(81, 65, 65, 0.25)",
                        borderRadius: "6px",
                        
                      }}
                  >
                    <AccordionSummary
                        expandIcon={expanded ? <RemoveIcon/> : <AddIcon/>}
                        aria-controls="panel1a-content"
                        sx={{ ".MuiAccordionSummary-content": {justifyContent: 'space-between'}}}
                    >
                      <Typography
                          variant="h6"
                            sx={{
                              fontSize: "18px",
                              fontFamily: "helvetica-lt-std-roman",
                            }}
                        >
                          {item?.title}
                        </Typography>
                      {item?.status !=='closed'&&<Box sx={{mr:'10px'}}>
                          <Button size={'small'} variant={'contained'} sx={{mr: '10px',backgroundColor:'#f4ede3',color:'#000'}}
                                   onClick={() => handleOpenDrawer(item)}>Reply</Button>
                          <Button size={'small'} variant={'contained'} sx={{ backgroundColor:'#f4ede3',color:'#000' }} onClick={() => handleClose(item)}>Close</Button>
                        </Box>
                      }
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{display: 'flex'}}>
                        <Typography variant="body1" color="initial"
                                    sx={{fontFamily: "helvetica-lt-std-bold", marginRight: '10px'}}>
                          Status:
                        </Typography>  
                        <Typography
                            variant="body1"
                            sx={{fontFamily: "helvetica-lt-std-roman"}}
                        >
                          {item?.status}
                        </Typography>
                        <Typography variant="body1" color="initial"
                                    sx={{marginRight: '10px', fontFamily: "helvetica-lt-std-bold", ml: 4}}>
                          Created Date :
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{fontFamily: "helvetica-lt-std-roman"}}
                        >
                          {moment(item?.created_at).format("DD-MM-YYYY")}
                        </Typography>
                      </Box>

                      <Typography variant="body1" color="initial"
                                  sx={{mt: 2, fontFamily: "helvetica-lt-std-bold"}}>
                        Description:
                      </Typography>
                      <Typography
                          variant="body1"
                          sx={{fontSize: "18px", fontFamily: "helvetica-lt-std-light"}}
                      >
                        {item?.description}
                      </Typography>

         {item?.replies?.length > 0 && (
          <React.Fragment>
            <Typography variant="body1" color="initial"
                        sx={{mt: 2, fontFamily: "helvetica-lt-std-bold"}}>
              Replies:
            </Typography>
            {item.replies.map((reply, index) => (
              <Typography
                  key={index}
                  variant="body1"
                  sx={{fontSize: "18px", fontFamily: "helvetica-lt-std-light"}}
              >
                {reply?.message}
              </Typography>
            ))}
          </React.Fragment>
        )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
            ))}
<Box style={{marginLeft:'calc(-50vw + 50%)', marginRight:'calc(-50vw + 50%)' }}>
            <Footer />
</Box>
      </>
  );
};

export default Support;
