// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-card {
  background-color: #FFFFFF;
  max-width: 650px;
  width: 100%;
  padding: 70px;
  border-radius: 12px;
}

@media (max-width: 600px) {
  .register-card {
    background-color: #FFFFFF;
    max-width: 600px !important;
    width: 95% !important;
    padding: 20px !important;
    border-radius: 12px;
  }
  .login-card {
    background-color: #FFFFFF;
    max-width: 600px !important;
    width: 95% !important;
    padding: 20px !important;
    padding-bottom: 120px !important;
    border-radius: 12px;
  }
}
@media (max-width: 320px) {
  .login-card {
    padding-bottom: 30px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/register/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAGA;EACE;IACE,yBAAA;IACA,2BAAA;IACA,qBAAA;IACA,wBAAA;IACA,mBAAA;EAAF;EAGA;IACE,yBAAA;IACA,2BAAA;IACA,qBAAA;IACA,wBAAA;IACA,gCAAA;IACA,mBAAA;EADF;AACF;AAKA;EACE;IACE,+BAAA;EAHF;AACF","sourcesContent":[".register-card{\n  background-color: #FFFFFF;\n  max-width: 650px;\n  width: 100%;\n  padding: 70px;\n  border-radius: 12px;\n\n}\n\n@media (max-width:600px) {\n  .register-card{\n    background-color: #FFFFFF;\n    max-width: 600px !important;\n    width: 95% !important;\n    padding: 20px !important;\n    border-radius: 12px;\n  \n  }\n  .login-card{\n    background-color: #FFFFFF;\n    max-width: 600px !important;\n    width: 95% !important;\n    padding: 20px !important;\n    padding-bottom: 120px !important;\n    border-radius: 12px;\n  \n  }\n}\n\n@media (max-width:320px) {\n  .login-card{\n    padding-bottom: 30px !important;\n  \n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
