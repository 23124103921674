import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {FormControl, Grid} from "@mui/material";
import SimpleInput from "../../../../components/form/simpleInput";
import CustomButton from "../../../../components/custom-button/customButton";
import Loader from "../../../../components/loader/loader";
import CustomTable from "../../../../components/table";
import {getAllClinicsTreatmentGroup, getTreatmentGroup, updatetreatmentgroupFilter} from "../../../../store/clinics/clinicSettings/clinic-treatment-group";
import BackendPagination from '../../../../components/pagination/backendPagination';
import Waveloading from '../../../loading/Waveloading';

const TreatmentGroup = () => {
    const [loading, setLoading] = useState(false);
    const [search,setSearch] = useState('')

    const params =useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const clinicTreatmentGroupStore = useSelector(state => state?.clinicTreatmentGroupStore)
    const user = useSelector(state => state?.authStore?.user)

    const {currentPage} = clinicTreatmentGroupStore
    console.log("current page",currentPage)

    useEffect(()=>{      
        (async ()=>{  
            if (!clinicTreatmentGroupStore?.treatmentGroups?.length){
                setLoading(true)
            }
            await dispatch(getTreatmentGroup(params?.clinic_id))
            setLoading(false)
        })()
    },[dispatch,clinicTreatmentGroupStore?.treatmentGroups?.length,params?.clinic_id])



    const refreshData = async () => {
        setLoading(true);
        try {
            await  dispatch(getTreatmentGroup(params?.clinic_id))
           
        } catch (error) {
            console.error('Error fetching data:', error);
           
        }
        setLoading(false); 
    };
    const treatmentHead= [
        {value:"Name",key:'title'},
        {value:"File",key:'file_path', isImage: true },
        {value:"Group / Treatment",key:'parent',subKey:'title',isObject:true},
        {value:"Status",key:'status'},
        {value:"Action",key:'action'}
    ]

    const handleEdit = (row, isEdit) => {
        if (isEdit) {
            navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/edit-treatment-group/${row?.id}`)
        }
    }

    const handleClick =()=>{
        if (params?.clinic_id){
            navigate(`/${user?.role_id ===2 ?"crm" : "admin"}/configuration/clinic/${params?.clinic_id}/add-treatment-group`)
        }
    }
    
  const fetchPageData = async(page) => {
    await dispatch(updatetreatmentgroupFilter({ currentPage: page }))
    await refreshData()
}

    return (
        <Grid container p={'20px'} >
            <Grid item xs={12} sx={{mb:'16px',display:'flex',justifyContent:'flex-end',alignItems:'center',width:'100%' , '@media (max-width: 520px)':{display:"block" },}}>
                <FormControl sx={{mr: "10px"}}>
                    <SimpleInput
                        search={search}
                        setSearch={setSearch}
                    />
                </FormControl>
                <CustomButton val={'Add Group / Treatment'} mrt={"10px"} size={'large'} handleClick={handleClick}/>
            </Grid>
            <Grid item xs={12} sx={{ height: "calc(100vh - 225px)"}}>
                {loading ? <Waveloading /> : <CustomTable
                    headRow={treatmentHead}
                    tableData={clinicTreatmentGroupStore?.treatmentGroups}
                    search={search}
                    isStatus={true}  
                    handleEdit={handleEdit}
                    type={'treatmentGroup'}   
                />
                }
            </Grid>
            <Grid item xs={12} style={{marginTop:"10px",alignItems:"center"}} >
        {clinicTreatmentGroupStore?.treatmentGroups?.meta?.last_page > 1 &&
            <BackendPagination
                count={clinicTreatmentGroupStore?.treatmentGroups?.meta?.last_page}
                currentPage={currentPage}
                handleOnChange={fetchPageData}
            />
        }
    </Grid>
        </Grid>
    )
};

export default TreatmentGroup; 